import * as actionTypes from './actionsTypes';
import Constants from '../../constants/Constants';
import axios from 'axios';

var myHeaders = '';
var raw = '';
var requestOptions = '';

export const get_overview_data = (token) =>{
    return async dispatch =>{
        await axios.post(Constants.AGENT_LISTING_API_URL,{
            token: token},
            {headers: {
                Authorization: token 
            }
        })
        .then(data => {
            dispatch(overview_data(data.data));
        })
        .catch(error => console.log('error', error)); 
    };
}
export const overview_data = (data) => {
    return {
        type: actionTypes.GET_OVERVIEW_DATA,
        data: data.data,
        total_prop: data.total_prop,
        soldPropertyValues: data.soldPropertyValues,
        linkedUserCount: data.linkedCount,
        appointmentCount: data.appointmentCount,
        generalAppointmentCount: data.generalAppointmentCount,
        settings: data.settings
    };
}

export const get_overview_data_with_date = (fromDate, toDate, token) =>{
    return dispatch => {
        const url = Constants.AGENT_OVERVIEW_LISTING_API_URL;
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token);
        var raw = JSON.stringify(
        {
            "token": token,
            "from": fromDate,
            "to": toDate
        });
        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };
        fetch(url, requestOptions)
        .then(response => response.json())
        .then(data => {
            dispatch(overview_data_with_date(data));
        })
    }
}
export const overview_data_with_date = (data) =>{
    return{
        type: actionTypes.GET_OVERVIEW_DATA_WITH_DATE,
        data: data.data,
    }
}

export const get_property_chart = (appointmentFromDate, appointmentToDate, agent_list, token) => {
    return dispatch => {
        const prop_api_chart_url = Constants.PROPERTY_CHART;
        myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token);
        raw = JSON.stringify(
        {
            "from": appointmentFromDate,
            "to": appointmentToDate
        });
        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(prop_api_chart_url, requestOptions)
        .then(response => response.json())
        .then(data => {
            //deleting data of user whose tracking is off
            for(let i =0; i < agent_list.length; i++){
                if(Number(agent_list[i].tracking_prefrence) === Number(0) ){
                    delete data.data.agent_prop_cnt[0][agent_list[i].user_id];
                }
            }
            dispatch(property_chart(data));
        })
    }
}
export const property_chart = (data) => {
    return{
        type: actionTypes.GET_PROPERTY_CHART,
        property_labels: data.data.labels,
        property_agen_count: data.data.agent_prop_cnt,
        temp_chart_property_count : data.data.agent_prop_cnt
    }
}

export const get_property_sale_chart = (appointmentFromDate, appointmentToDate, type, agent_list, token) => {
    return dispatch => {
        const prop_api_chart_url = Constants.PROPERTY_CHART;
        myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token);
        raw = JSON.stringify(
        {
            "from": appointmentFromDate,
            "to": appointmentToDate,
            "type": type,
        });
        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(prop_api_chart_url, requestOptions)
        .then(response => response.json())
        .then(data => {
            //deleting data of user whose tracking is off
            for(let i =0; i < agent_list.length; i++){
                if(Number(agent_list[i].tracking_prefrence) === Number(0) ){
                    delete data.data.agent_prop_cnt[0][agent_list[i].user_id];
                }
            }
            dispatch(property_sale_chart(data, type));
        })
    }
}
export const property_sale_chart = (data, type) => {
    if( type === "sold" || type === "rented" || type === "rented_monthly" || type === "rented_once"){
        return{
            type: actionTypes.GET_PROPERTY_SOLD_CHART,
            property_sold_labels: data.data.labels,
            property_sold_agen_count: data.data.agent_prop_cnt,
            temp_chart_property_count_sold : data.data.agent_prop_cnt,
        }
    }else{
        return{
            type: actionTypes.GET_PROPERTY_SALE_CHART,
            property_sale_labels: data.data.labels,
            property_sale_agen_count: data.data.agent_prop_cnt,
            temp_chart_property_count_sale : data.data.agent_prop_cnt,
        }
    }
}

export const get_stockpipeline_chart = (appointmentFromDate, appointmentToDate, agent_list, token) => {
    return dispatch => {
        const sales_api_chart = Constants.STOCKPIPELINE_CHART;
        myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token);
        raw = JSON.stringify(
        {
            "from": appointmentFromDate,
            "to": appointmentToDate
        });
        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(sales_api_chart, requestOptions)
        .then(response => response.json())
        .then(data => {
            //deleting data of user whose tracking is off
            for(let i =0; i < agent_list.length; i++){
                if(Number(agent_list[i].tracking_prefrence) === Number(0) ){
                    delete data.data.stock_pipeline_cnt[0][agent_list[i].user_id];
                }
            }
            dispatch(stockpipeline_chart(data));
        })
    }
}
export const stockpipeline_chart = (data) =>{
    return{
        type: actionTypes.GET_STOCKPIPELINE_CHART,
        stockpipeline_labels: data.data.labels,
        stockpipeline_count: data.data.stock_pipeline_cnt,
        temp_stockpipeline_count: data.data.stock_pipeline_cnt
    }
}

export const get_appointment_chart = (appointmentFromDate, appointmentToDate, agent_list, token) =>{
    return dispatch =>{
        const appt_api_chart = Constants.APPOINTMENT_CHART;
        myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token);
        raw = JSON.stringify(
        {
            "from": appointmentFromDate,
            "to": appointmentToDate
        });
        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(appt_api_chart, requestOptions)
        .then(response => response.json())
        .then(data => {
            //deleting data of user whose tracking is off
            for(let i = 0; i < agent_list.length; i++){ 
                if(Number(agent_list[i].tracking_prefrence) === Number(0) ){ 
                    delete data.data.appt_cnt[0][agent_list[i].user_id];
                }
            }
            dispatch(appointment_chart(data));
        })
    }
}
export const appointment_chart = (data) => {
    return {
        type: actionTypes.GET_APPOINTMENT_CHART,
        appt_lables: data.data.labels,
        chart_appt_count: data.data.appt_cnt,
        temp_chart_appt_count: data.data.appt_cnt
    }
}

export const get_general_chart = (appointmentFromDate, appointmentToDate, agent_list, token) =>{
    return dispatch =>{
        const appt_api_chart = Constants.GENERAL_CHART;
        myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token);
        raw = JSON.stringify(
        {
            "from": appointmentFromDate,
            "to": appointmentToDate
        });
        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(appt_api_chart, requestOptions)
        .then(response => response.json())
        .then(data => {
            //deleting data of user whose tracking is off
            for(let i = 0; i < agent_list.length; i++){ 
                if(Number(agent_list[i].tracking_prefrence) === Number(0) ){ 
                    delete data.data.appt_cnt[0][agent_list[i].user_id];
                }
            }
            dispatch(general_chart(data));
        })
    }
}
export const general_chart = (data) => {
    return {
        type: actionTypes.GET_GENERAL_CHART,
        general_lables: data.data.labels,
        chart_general_count: data.data.appt_cnt,
        temp_chart_general_count: data.data.appt_cnt
    }
}


export const get_sales_chart = (appointmentFromDate, appointmentToDate, agent_list, token) => {
    return dispatch => {
        const sales_api_chart = Constants.SALES_CHART;
        myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token);
        raw = JSON.stringify(
        {
            "from": appointmentFromDate,
            "to": appointmentToDate
        });
        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(sales_api_chart, requestOptions)
        .then(response => response.json())
        .then(data => {
            //deleting data of user whose tracking is off
            for(let i =0; i < agent_list.length; i++){
                if(Number(agent_list[i].tracking_prefrence) === Number(0) ){
                    delete data.data.sales_cnt[0][agent_list[i].user_id];
                }
            }
            dispatch(sales_chart(data));
        })
    }
}
export const sales_chart = (data) =>{
    return{
        type: actionTypes.GET_SALES_CHART,
        sales_labels: data.data.labels,
        chart_sales_cnt: data.data.sales_cnt,
        temp_chart_sales_count: data.data.sales_cnt
    }
}

export const get_coldcalls_chart = (appointmentFromDate, appointmentToDate, agent_list, token) => {
    return dispatch => {
        const coldcall_chart = Constants.COLDCALL_CHART ;
        myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token);
        raw = JSON.stringify(
        {
            "token": token,
            "from": appointmentFromDate,
            "to": appointmentToDate
        });
        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(coldcall_chart, requestOptions)
        .then(response => response.json())
        .then(data => {
            //deleting data of user whose tracking is off
            for(let i =0; i < agent_list.length; i++){
                if(Number(agent_list[i].tracking_prefrence) === Number(0) ){
                    delete data.data.cold_call_cnt[0][agent_list[i].user_id];
                }
            }
            dispatch(coldcalls_chart(data));
        })
    }
}
export const coldcalls_chart = (data) =>{
    return{
        type: actionTypes.GET_COLDCALLS_CHART,
        coldcalls_labels: data.data.labels,
        coldcalls_count: data.data.cold_call_cnt,
        temp_coldcalls_count: data.data.cold_call_cnt
    }
}

export const get_revenue_chart = (appointmentFromDate, appointmentToDate, type, agent_list, token) => {
    return dispatch => {
        const revenue_api_chart = Constants.REVENUE_CHART;
        myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token);
        raw = JSON.stringify(
        {
            "from": appointmentFromDate,
            "to": appointmentToDate,
            "type": type
        });
        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(revenue_api_chart, requestOptions)
        .then(response => response.json())
        .then(data => {
            //deleting data of user whose tracking is off
            for(let i =0; i < agent_list.length; i++){
                if(Number(agent_list[i].tracking_prefrence) === Number(0) ){
                    delete data.data.revenue_cnt[0][agent_list[i].user_id];
                }
            }
            dispatch(revenue_chart(data));
        })
    }
}
export const revenue_chart = (data) =>{
    return{
        type: actionTypes.GET_REVENUE_CHART,
        revenue_labels: data.data.labels,
        currency: data.data.currency,
        chart_revenue_cnt: data.data.revenue_cnt,
        temp_chart_revenue_count: data.data.revenue_cnt,
    }
}

export const get_rental_revenue_chart = (appointmentFromDate, appointmentToDate, agent_list, token) => {
    return dispatch => {
        const revenue_api_chart = Constants.RENTAL_REVENUE_CHART;
        myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token);
        raw = JSON.stringify(
        {
            "from": appointmentFromDate,
            "to": appointmentToDate
        });
        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(revenue_api_chart, requestOptions)
        .then(response => response.json())
        .then(data => {
            //deleting data of user whose tracking is off
            for(let i =0; i < agent_list.length; i++){
                if(Number(agent_list[i].tracking_prefrence) === Number(0) ){
                    delete data.data.sales_cnt[0][agent_list[i].user_id];
                }
            }
            dispatch(rental_revenue_chart(data));
        })
    }
}
export const rental_revenue_chart = (data) =>{
    return{
        type: actionTypes.GET_RENTAL_REVENUE_CHART,
        rental_revenue_labels: data.data.labels,
        chart_rental_revenue_cnt: data.data.sales_cnt,
        temp_chart_rental_revenue_count: data.data.sales_cnt,
    }
}

export const get_profitability_chart = (appointmentFromDate, appointmentToDate, agent_list, token) => {
    return dispatch => {
        const profitability_chart_api = Constants.PROFITABILITY_CHART;
        myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token);
        raw = JSON.stringify(
        {
        "from": appointmentFromDate,
        "to": appointmentToDate
        });
        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(profitability_chart_api, requestOptions)
        .then(response => response.json())
        .then(data => {
            //deleting data of user whose tracking is off
            for(let i =0; i < agent_list.length; i++){
                if(Number(agent_list[i].tracking_prefrence) === Number(0) ){
                    delete data.data.profitability[0][agent_list[i].user_id];
                }
            }
            dispatch(profitability_chart(data));
        })
    }
}
export const profitability_chart = (data) =>{
    //removing null from the profitability chart
    var profit_chart = data.data.profitability;
    if (data.data.profitability) { // eslint-disable-next-line
        profit_chart.map(function (agent_id) { // eslint-disable-next-line
            Object.keys(agent_id).map(function (outerKey) { // eslint-disable-next-line
                profit_chart[0][outerKey].map(function(data, datakey){
                    if(data === null){
                        profit_chart[0][outerKey][datakey] = 0
                    }  
                })
            })
        })
    }
    return{
        type: actionTypes.GET_PROFITABILITY_CHART,
        profitability_labels: data.data.labels,
        profitability_chart: profit_chart,
        temp_chart_profitability_count: data.data.profitability,
    }
}

//toggle chart
export const toggle_property_chart = (agent_id) =>{
    return{
        type: actionTypes.TOGGLE_PROPERTY_CHART,
        agent_id: agent_id
    }
}
export const toggle_property_sale_chart = (agent_id) =>{
    return{
        type: actionTypes.TOGGLE_PROPERTY_SALE_CHART,
        agent_id: agent_id
    }
}
export const toggle_property_sold_chart = (agent_id) =>{
    return{
        type: actionTypes.TOGGLE_PROPERTY_SOLD_CHART,
        agent_id: agent_id
    }
}
export const toggle_stockpipeline_chart = (agent_id) =>{
    return{
        type: actionTypes.TOGGLE_STOCKPIPELINE_CHART,
        agent_id: agent_id
    }
}
export const toggle_appointment_chart = (agent_id) =>{
    return{
        type: actionTypes.TOGGLE_APPOINTMENT_CHART,
        agent_id: agent_id
    }
}
export const toggle_general_chart = (agent_id) =>{
    return{
        type: actionTypes.TOGGLE_GENERAL_CHART,
        agent_id: agent_id
    }
}
export const toggle_sales_chart = (agent_id) =>{
    return{
        type: actionTypes.TOGGLE_SALES_CHART,
        agent_id: agent_id
    }
}
export const toggle_coldcalls_chart = (agent_id) => {
    return{
        type: actionTypes.TOGGLE_COLDCALLS_CHART,
        agent_id: agent_id
    }
}
export const toggle_revenue_chart = (agent_id) =>{
    return{
        type: actionTypes.TOGGLE_REVENUE_CHART,
        agent_id: agent_id
    }
}
export const toggle_rental_revenue_chart = (agent_id) =>{
    return{
        type: actionTypes.TOGGLE_RENTAL_REVENUE_CHART,
        agent_id: agent_id
    }
}
export const toggle_profitability_chart = (agent_id) =>{
    return{
        type: actionTypes.TOGGLE_PROFITABILITY_CHART,
        agent_id: agent_id
    }
}

export const change_from_to_date = (from_date, to_date) =>{
    return{
        type: actionTypes.CHANGE_FROM_TO_DATE,
        page_from_date: from_date,
        page_to_date: to_date
    }
}
