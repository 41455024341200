import * as actionTypes from './actionsTypes';
import Constants from '../../constants/Constants';

export const authStart = (authData) => {
    return {
        type: actionTypes.AUTH_START
    };
}

export const authSuccess = (result) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: result.token,
        logo: result.office_logo,
        office_id: result.office_id,
        firstname: result.firstname
    };
}

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error.msg
    };
}

export const logout = ()=>{
    localStorage.removeItem('token');
    localStorage.removeItem('logo');
    localStorage.removeItem('office_id');
    localStorage.removeItem('firstname');
    localStorage.removeItem('lastActive');
    localStorage.removeItem('data');
    localStorage.removeItem('non_active_agent_buttons')
    localStorage.removeItem('subscription_plan_id');

    localStorage.removeItem('office_name');
    localStorage.removeItem('office_email');
    localStorage.removeItem('subscription_plan_id');
    localStorage.removeItem('totalAgentCount');
    return{
        type: actionTypes.AUTH_LOGOUT
    }
}

export const checkAuthTimeout = (expirationTime)=>{
    // return dispatch => {
    //     setTimeout(() => {
    //         dispatch(logout());
    //     },expirationTime); 
    // };
}

export const get_admin_auth = (data) =>{
    if(data){
        localStorage.clear();
        try {
            var auth_data = JSON.parse(data.toString());
        } catch (e) {
            return dispatch => {
                let error=[];
                error.msg = "Invalid Request"
                dispatch(authFail(error));
            }
        }

        if(!auth_data['token'] || !auth_data['hash']){
            return dispatch => {
                let error=[];
                error.msg = "Invalid Request"
                dispatch(authFail(error));
            }
        }
    }
    
    return dispatch => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify(
        {
            token: auth_data['token'],
            hash: auth_data['hash']
        });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(Constants.ADMINLOGIN, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.status ===  200 ){
                localStorage.setItem('token',result.token);
                localStorage.setItem('logo',result.office_logo);
                localStorage.setItem('office_id',result.office_id);
                localStorage.setItem('firstname',result.firstname);
                localStorage.setItem('office_name',result.office_name);
                localStorage.setItem('office_email',result.email);
                localStorage.setItem('subscription_plan_id', result.office_subscription ?  result.office_subscription.subscription_plan_id : null);
                localStorage.setItem("lastActive", new Date());

                let non_active_agent_buttons=[];
                non_active_agent_buttons.push(JSON.stringify([]));
                localStorage.setItem('non_active_agent_buttons',non_active_agent_buttons);
                
                dispatch(authSuccess(result));
                //dispatch(checkAuthTimeout(expiresIn));
            }else{
                dispatch(authFail(result));
            }
        })
    }
} 

export const auth = (email, password) =>{
    return dispatch =>{
        dispatch (authStart());
        if(email.trim() === '' || password.trim() ===''){
            let error=[];
            if(email.trim() === '' && password.trim() !== ''){
                error.msg = "Username Is Required"
                dispatch(authFail(error));
            }else if(password.trim() ===''&& email.trim() !== ''){ 
                error.msg = "Password Is Required"
                dispatch(authFail(error));
            }else{
                error.msg = "Username And Password Is Required"
                dispatch(authFail(error));
            }
        }else{
            const url= Constants.LOGIN_API_URL;
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var raw = JSON.stringify({"username":email,"password":password});
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            fetch(url, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result.status ===  200 ){
                    //console.log(result);
                    localStorage.setItem('token',result.token);
                    localStorage.setItem('logo',result.office_logo);
                    localStorage.setItem('office_id',result.office_id);
                    localStorage.setItem('firstname',result.firstname);
                    localStorage.setItem('office_name',result.office_name);
                    localStorage.setItem('office_email',result.email);
                    localStorage.setItem('subscription_plan_id', result.office_subscription ?  result.office_subscription.subscription_plan_id : null);
                    localStorage.setItem("lastActive", new Date());

                    let non_active_agent_buttons=[];
                    non_active_agent_buttons.push(JSON.stringify([]));
                    localStorage.setItem('non_active_agent_buttons',non_active_agent_buttons);
                    
                    dispatch(authSuccess(result));
                    //dispatch(checkAuthTimeout(expiresIn));
    
                }else{
                    dispatch(authFail(result));
                }
            })
            .catch(error => console.log('error', error));
        }
    };
}

export const update_expire = (lastActive) => {
    return dispatch =>{
        dispatch({
            type: actionTypes.UPDATE_EXPIRE,
            lastActive: lastActive
        })
    }
}

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if(!token){
            dispatch(logout());
        }else{
            const lastActive = new Date(localStorage.getItem('lastActive'));
            if (lastActive >= new Date()){
                dispatch(logout());
            }else{
                const result = {token: localStorage.token, 
                    office_logo: localStorage.logo,
                    office_id: localStorage.office_id,
                    firstname: localStorage.firstname
                }
                dispatch(authSuccess(result));
                //dispatch(checkAuthTimeout( (expirationDate.getTime() - new Date().getTime()) /*/ 1000*/ ));
            }
        }
    };
};