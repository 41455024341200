import React,{ Component } from "react";
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import Loader from '../../Loader';
import modal from '../../../CustomFiles/js/modal';
import Moment from 'react-moment';

//import ReactTooltip from 'react-tooltip';
import {Checkbox} from 'primereact/checkbox';
import { Icon } from '@iconify/react';
import bxCalendarEvent from '@iconify/icons-bx/bx-calendar-event';

let items = [];

class ContactList extends Component{
    constructor(props) {
        super(props);
        this.state = {
            selected_contact_id: null,
            selected_contact_name: null,
            selected_contact_mobile_no: null,
            selected_contact_email_id: null,
            load_more_loading: false,
            page : 1
        }

        this.handleSelectContact = this.handleSelectContact.bind(this);
        this.handleApplyContact = this.handleApplyContact.bind(this);
        this.onInputchange = this.onInputchange.bind(this);
        this.renderMoreBuyerLead = this.renderMoreBuyerLead.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }
    
    //clear modal data
    closeModal(){
        this.setState({
            selected_contact_id: null,
            selected_contact_name: null,
            selected_contact_email_id: null
        });
    }
    
    componentDidMount() {
        modal();
        this.props.resetContactSelect(this.closeModal);
    }
    
    get_userAuth = async (event) => {
        var token = localStorage.getItem('token');
        if(token && event.target.value){
            this.setState({address: ""});
            this.setState({display_card: "none"})
            await this.props.get_auth_data(event.target.value,token);
            this.props.get_all_agent_data_dd(this.props.authtoken)
            this.setState({display_card: "block"})
        }
    }
    
    createSelectItems = () => {
        items = [];
        if(this.props.agent_list){
            for (let i = 0; i < this.props.agent_list.length; i++) { 
                if(Number(this.props.agent_id) === Number(this.props.agent_list[i].userid)){
                    items.push(<option key={i} selected value={this.props.agent_list[i].userid}>{this.props.agent_list[i].firstname} {this.props.agent_list[i].lastname}</option>);   
                }else{
                    items.push(<option key={i} value={this.props.agent_list[i].userid}>{this.props.agent_list[i].firstname} {this.props.agent_list[i].lastname}</option>);   
                }
            }
           
            return items;
        }
    }    
    

    handleSelectContact = (id, first_name, last_name, mobile_no, email) =>{
        if(this.state.selected_contact_id === id){
            this.setState({
                selected_contact_id: null,
                selected_contact_name: null,
                selected_contact_mobile_no: null,
                selected_contact_email_id: null
            })
        }else{
            this.setState({
                selected_contact_id: id,
                selected_contact_name: first_name+" "+last_name,
                selected_contact_mobile_no: mobile_no,
                selected_contact_email_id: email 
            })
        }
        
    }

    handleApplyContact = () => {
        this.props.parentCallback(this.state.selected_contact_id, this.state.selected_contact_name, this.state.selected_contact_mobile_no, this.state.selected_contact_email_id)
    }

    getList(){
        var list = this.props.buyer_lead_data.map((data, key) => {
            return (
                <div key={key} id={data.id}>
                    <Checkbox style={{float:'left'}} inputId="contact_list_1" name="contact_list" checked={Number(this.state.selected_contact_id) === Number(data.id) ? true: false} value={this.state.selected_contact_id} onChange={() => this.handleSelectContact(data.id, data.contact_firstname, data.contact_lastname, data.contact_mobile_no, data.contact_email)}></Checkbox> &nbsp;&nbsp; 
                    <p className="fontSize13 fontWeight600 mb0 ml10 mr10" style={{display:"inline-block", fontFamily:"Inter"}}> {data.contact_firstname} {data.contact_lastname} 
                        {Number(data.agent_is_pro) === 1?
                            (data.contact_id != null && data.contact_id != "") ?
                                <img tag="allowRowEvents" alt='' width="20" height="20" style={{verticalAlign: "text-bottom", marginLeft: "5px"}} src={"images/crm_verified.svg"}/>
                            :
                                <img tag="allowRowEvents" alt='' width="20" height="20" style={{verticalAlign: "text-bottom", marginLeft: "5px"}} src={"images/crm_not_verified.svg"}/>
                        : null
                        }
                    </p>
                    <p className="fontSize13 fontWeight600 mb0 ml10 mr10" style={{display:"inline-block", float:'right', fontFamily:"Inter"}}> <Moment format="D MMM YYYY">{data.contact_modified_date ? data.contact_modified_date : data.contact_date_created}</Moment> </p>
                    <div className="fontWeight700" style={{marginLeft:"35px",color: "rgb(18, 23, 29)"}}> 
                        {data.contact_email}
                        {data.contact_email && data.contact_mobile_no ? 
                            <span style={{verticalAlign: "bottom", marginLeft: "2px", marginRight: "2px"}}>•</span> 
                            : null
                        }
                        {data.contact_mobile_no ?    
                        <>
                            ({data.contact_mobile_no})
                        </>
                        :
                            null
                        }
                    </div>                  
                    <hr/>
                </div>
            )
        })
        return list
    }

    renderMoreBuyerLead(){
        let newpage = Number(this.state.page) + 1;
        this.setState({page: newpage, load_more_loading: true}, async () =>{
            const token = localStorage.getItem('token');
            var agent_id = this.props.agent_id;
            let filter = null;
            let active_filter = "both";
            let tag_filter = "all"; 
            await this.props.get_buyer_lead(token, agent_id, this.state.page, filter, active_filter, tag_filter);
            this.setState({load_more_loading: false})
        })
    }

    onInputchange(event) {
        this.setState({
            [event.target.name]: event.target.value,
            page: 1,
        }, async ()=>{
            const token = localStorage.getItem('token');
            var agent_id = this.props.agent_id;
            let page = 1;
            let filter = this.state.search_buyerLead;
            let active_filter = "both";
            let tag_filter = "all"; 
            await this.props.get_buyer_lead(token, agent_id, page, filter, active_filter, tag_filter)            
        });
    }

    render(){
        if(this.state.edit_address_1 === null && this.props.address){
            let len = this.props.address;
            len = len.length;
            this.setState({edit_full_address_length: len});
            this.setState({edit_address_1: this.props.address},() => {
                window.$("#edit_address_1").val(this.props.address)
            });
        }
     
        return(
            <div>
                <div className="modal fade" data-backdrop="static" data-keyboard="false" id="contactListModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content modal_contact_list modalheight" style ={{borderRadius : '12px' }} >
                            <div className="modal-header" style={{ backgroundColor: "#EDF6F6"}}>
                                <h4 className="modal-title fontWeight600"> <Icon className="message_icon color27AFAF mr5" style={{verticalAlign: 'bottom',}} icon={bxCalendarEvent} height='25' width='25' /> Select Contact List</h4>
                                <div id="closeModal_contact_list" className="circle" data-dismiss="modal" onClick={this.handleApplyContact}>
                                    <img src="images/modal-close.png " width="12" height="12" alt=""/>
                                </div> 
                            </div>
                            
                            <div className="modal-body">
                                <div className="row mb40">
                                    <div className="col-md-offset-1 col-md-9" style={{marginTop:"20px"}}>
                                        <center>
                                            <input type="text" id="search_buyerLead" name="search_buyerLead" style={{width:"100%", display:"inline"}} placeholder="Search contact name, email or mobile no." onChange={this.onInputchange}/>
                                        </center>
                                    </div>
                                </div>

                                <div>
                                    {/*Contact List type  */}
                                    {this.props.buyer_lead_data  &&  this.props.buyer_lead_data.length > 0 ? 
                                        <>
                                        {this.getList()}
                                        {this.props.buyer_lead_load_more ? 
                                            <center>
                                                {!this.state.load_more_loading ?
                                                    <button type="button" className="btn btn-info fontWeight700" style={{backgroundColor: "#5FC8BF", marginTop: 20}} onClick={this.renderMoreBuyerLead}>Load More</button>
                                                :   <> <br/> <Loader/> <br/> </>}
                                            </center>
                                        : null
                                        }
                                        <br/><br/>
                                        <center>
                                        <div data-dismiss="modal" style={{textAlign: "center"}}>
                                            <button id="selectContact" type="button" className="btn btn-primary fontBold" style={{ background:'#4AA49C',borderRadius:'4px'}} onClick={this.handleApplyContact}> Select </button>
                                        </div>
                                        </center>
                                    </>
                                    :
                                        <div className="center-screen">
                                            <h3> No Contacts to show</h3> <br/>
                                        </div>
                                    }

                                    <br/>
                                </div>
                            </div>   
                        </div> 
                    </div>
                </div>                
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
      //get contact list
      get_buyer_lead : (token, agent_id, page, filter, active_filter, tag_filter) => dispatch(actions.get_buyer_lead(token, agent_id, page, filter, active_filter, tag_filter)),
    }
  }
  
const mapStateToProps = state => {
    return{
        buyer_lead_data: state.buyer_lead.buyer_lead_data,
        buyer_lead_load_more: state.buyer_lead.buyer_lead_load_more,   
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ContactList);
