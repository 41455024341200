import $ from 'jquery'; 
export default function modal() {
    // var param1 = new Date();
    // $('#daterange-btn').val(param1);
    $(".overview-information").show();
    $(".appointments-information").hide();
    $(".property-information").hide();
    $(".revenue-information").hide();
    $(".other-listing").hide();
    $(".stock-pipeline").hide();
    $(".cold-call-logs").hide();
    $(".networking").hide();
    $(".travel-tracking").hide();
    
    $(".user-modal-overview").click(function (){
        $(".main").css("height","auto");
        $('.user-modal-overview').css('background-color',"#1B375A");
        $('.user-modal-prop').css('background-color',"#0F1D30");
        $('.user-modal-appt').css('background-color',"#0F1D30");
        $('.user-modal-revenue').css('background-color',"#0F1D30");
        $('.user-modal-other_listing').css('background-color',"#0F1D30");
        $('.user-modal-stock-pipeline').css('background-color','#0F1D30');
        $('.user-modal-cold-call-logs').css('background-color','#0F1D30');
        $('.user-modal-archive_property').css('background-color','#0F1D30');
        $('.user-modal-networking').css('background-color','#0F1D30');
        $('.user-modal-travelTracking').css('background-color','#0F1D30');

        
        $('.overview-information').show();
        $(".appointments-information").hide();
        $(".property-information").hide();
        $(".revenue-information").hide();
        $(".other-listing").hide();
        $(".stock-pipeline").hide();
        $(".cold-call-logs").hide();
        $(".archive-prop").hide();
        $(".networking").hide();
        $(".travel-tracking").hide();
    })

    $(".user-modal-appt").click(function (){
        $('.user-modal-overview').css('background-color',"#0F1D30");
        $('.user-modal-appt').css('background-color',"#1B375A");
        $('.user-modal-prop').css('background-color',"#0F1D30");
        $('.user-modal-revenue').css('background-color',"#0F1D30");
        $('.user-modal-other_listing').css('background-color',"#0F1D30");
        $('.user-modal-stock-pipeline').css('background-color','#0F1D30');
        $('.user-modal-cold-call-logs').css('background-color','#0F1D30');
        $('.user-modal-archive_property').css('background-color','#0F1D30');
        $('.user-modal-networking').css('background-color','#0F1D30');
        $('.user-modal-travelTracking').css('background-color','#0F1D30');

        $('.overview-information').hide();
        $(".appointments-information").show();
        $(".property-information").hide();
        $(".revenue-information").hide();
        $(".other-listing").hide();
        $(".stock-pipeline").hide();
        $(".cold-call-logs").hide();
        $(".archive-prop").hide();
        $(".networking").hide();
        $(".travel-tracking").hide();
        
    });

    $(".user-modal-prop").click(function (){
        $(".main").css("height","auto");
        $('.user-modal-overview').css('background-color',"#0F1D30");
        $('.user-modal-prop').css('background-color',"#1B375A");
        $('.user-modal-appt').css('background-color',"#0F1D30");
        $('.user-modal-revenue').css('background-color',"#0F1D30");
        $('.user-modal-other_listing').css('background-color',"#0F1D30");
        $('.user-modal-stock-pipeline').css('background-color','#0F1D30');
        $('.user-modal-cold-call-logs').css('background-color','#0F1D30');
        $('.user-modal-archive_property').css('background-color','#0F1D30');
        $('.user-modal-networking').css('background-color','#0F1D30');
        $('.user-modal-travelTracking').css('background-color','#0F1D30');
        
        $('.overview-information').hide();
        $(".appointments-information").hide();
        $(".property-information").show();
        $(".revenue-information").hide();
        $(".other-listing").hide();
        $(".stock-pipeline").hide();
        $(".cold-call-logs").hide();
        $(".archive-prop").hide();
        $(".networking").hide();
        $(".travel-tracking").hide();
    })

    $(".user-modal-revenue").click(function (){
        $(".main").css("height","auto");
        $('.user-modal-overview').css('background-color',"#0F1D30");
        $('.user-modal-revenue').css('background-color',"#1B375A");
        $('.user-modal-appt').css('background-color',"#0F1D30");
        $('.user-modal-prop').css('background-color',"#0F1D30");
        $('.user-modal-other_listing').css('background-color',"#0F1D30");
        $('.user-modal-stock-pipeline').css('background-color','#0F1D30');
        $('.user-modal-cold-call-logs').css('background-color','#0F1D30');
        $('.user-modal-archive_property').css('background-color','#0F1D30');
        $('.user-modal-networking').css('background-color','#0F1D30');
        $('.user-modal-travelTracking').css('background-color','#0F1D30');

        $('.overview-information').hide();
        $(".property-information").hide();
        $(".appointments-information").hide();
        $(".revenue-information").show();
        $(".other-listing").hide();
        $(".stock-pipeline").hide();
        $(".cold-call-logs").hide();
        $(".archive-prop").hide();
        $(".networking").hide();
        $(".travel-tracking").hide();
        
    })

    $(".user-modal-other_listing").click(function (){
        $('.user-modal-overview').css('background-color',"#0F1D30");
        $('.user-modal-prop').css('background-color',"#0F1D30");
        $('.user-modal-appt').css('background-color',"#0F1D30");
        $('.user-modal-revenue').css('background-color',"#0F1D30");
        $('.user-modal-other_listing').css('background-color',"#1B375A");
        $('.user-modal-stock-pipeline').css('background-color','#0F1D30');
        $('.user-modal-cold-call-logs').css('background-color','#0F1D30');
        $('.user-modal-archive_property').css('background-color','#0F1D30');
        $('.user-modal-networking').css('background-color','#0F1D30');
        $('.user-modal-travelTracking').css('background-color','#0F1D30');

        $('.overview-information').hide();
        $(".property-information").hide();
        $(".appointments-information").hide();
        $(".revenue-information").hide();
        $(".other-listing").show();
        $(".stock-pipeline").hide();
        $(".cold-call-logs").hide();
        $(".archive-prop").hide();
        $(".networking").hide();
        $(".travel-tracking").hide();
    })

    $(".user-modal-stock-pipeline").click(function (){
        $('.user-modal-overview').css('background-color',"#0F1D30");
        $('.user-modal-prop').css('background-color',"#0F1D30");
        $('.user-modal-appt').css('background-color',"#0F1D30");
        $('.user-modal-revenue').css('background-color',"#0F1D30");
        $('.user-modal-other_listing').css('background-color',"#0F1D30");
        $('.user-modal-stock-pipeline').css('background-color','#1B375A');
        $('.user-modal-cold-call-logs').css('background-color','#0F1D30');
        $('.user-modal-archive_property').css('background-color','#0F1D30');
        $('.user-modal-networking').css('background-color','#0F1D30');
        $('.user-modal-travelTracking').css('background-color','#0F1D30');

        $('.overview-information').hide();
        $(".property-information").hide();
        $(".appointments-information").hide();
        $(".revenue-information").hide();
        $(".other-listing").hide();
        $(".stock-pipeline").show();
        $(".cold-call-logs").hide();
        $(".archive-prop").hide();
        $(".networking").hide();
        $(".travel-tracking").hide();
    })

    $(".user-modal-cold-call-logs").click(function (){
        $('.user-modal-overview').css('background-color',"#0F1D30");
        $('.user-modal-prop').css('background-color',"#0F1D30");
        $('.user-modal-appt').css('background-color',"#0F1D30");
        $('.user-modal-revenue').css('background-color',"#0F1D30");
        $('.user-modal-other_listing').css('background-color',"#0F1D30");
        $('.user-modal-stock-pipeline').css('background-color','#0F1D30');
        $('.user-modal-cold-call-logs').css('background-color','#1B375A');
        $('.user-modal-archive_property').css('background-color','#0F1D30');
        $('.user-modal-networking').css('background-color','#0F1D30');
        $('.user-modal-travelTracking').css('background-color','#0F1D30');

        $('.overview-information').hide();
        $(".property-information").hide();
        $(".appointments-information").hide();
        $(".revenue-information").hide();
        $(".other-listing").hide();
        $(".stock-pipeline").hide();
        $(".archive-prop").hide();
        $(".networking").hide();
        $(".travel-tracking").hide();

        var d = new Date();
        var month = '' + (d.getMonth() + 1);
        var year = '' + d.getFullYear();
        if (month.length < 2) month = '0' + month;
        
        $("#month_select").val(month).attr("selected","selected");
        $("#year_select").val(year).attr("selected","selected");
        $(".cold-call-logs").show();
    })

    $(".user-modal-archive_property").click(function (){
        $('.user-modal-overview').css('background-color',"#0F1D30");
        $('.user-modal-prop').css('background-color',"#0F1D30");
        $('.user-modal-appt').css('background-color',"#0F1D30");
        $('.user-modal-revenue').css('background-color',"#0F1D30");
        $('.user-modal-other_listing').css('background-color',"#0F1D30");
        $('.user-modal-stock-pipeline').css('background-color','#0F1D30');
        $('.user-modal-cold-call-logs').css('background-color','#0F1D30');
        $('.user-modal-archive_property').css('background-color','#1B375A');
        $('.user-modal-networking').css('background-color','#0F1D30');
        $('.user-modal-travelTracking').css('background-color','#0F1D30');

        $('.overview-information').hide();
        $(".property-information").hide();
        $(".appointments-information").hide();
        $(".revenue-information").hide();
        $(".other-listing").hide();
        $(".stock-pipeline").hide();
        $(".cold-call-logs").hide();
        $(".archive-prop").show();
        $(".networking").hide();
        $('.archive_properties_tab').addClass('active');
        $('.archive_stockpipeline_tab').removeClass('active');
        $('#archive_properties_tab').addClass('active in');
        $('#archive_stockpipeline_tab').removeClass('active in');
        $(".travel-tracking").hide();
    })

    $(".user-modal-networking").click(function (){
        $('.user-modal-overview').css('background-color',"#0F1D30");
        $('.user-modal-prop').css('background-color',"#0F1D30");
        $('.user-modal-appt').css('background-color',"#0F1D30");
        $('.user-modal-revenue').css('background-color',"#0F1D30");
        $('.user-modal-other_listing').css('background-color',"#0F1D30");
        $('.user-modal-stock-pipeline').css('background-color','#0F1D30');
        $('.user-modal-cold-call-logs').css('background-color','#0F1D30');
        $('.user-modal-archive_property').css('background-color','#0F1D30');
        $('.user-modal-networking').css('background-color','#1B375A');
        $('.user-modal-travelTracking').css('background-color','#0F1D30');

        $('.overview-information').hide();
        $(".property-information").hide();
        $(".appointments-information").hide();
        $(".revenue-information").hide();
        $(".other-listing").hide();
        $(".stock-pipeline").hide();
        $(".cold-call-logs").hide();
        $(".archive-prop").hide();
        $(".networking").show();
        $(".travel-tracking").hide();
        $('.office_listing').addClass('active');
        $('.open_listing').removeClass('active');
        $('#office_listing').addClass('active in');
        $('#open_listing').removeClass('active in');
    })

    $(".user-modal-travelTracking").click(function (){
        $('.user-modal-overview').css('background-color',"#0F1D30");
        $('.user-modal-prop').css('background-color',"#0F1D30");
        $('.user-modal-appt').css('background-color',"#0F1D30");
        $('.user-modal-revenue').css('background-color',"#0F1D30");
        $('.user-modal-other_listing').css('background-color',"#0F1D30");
        $('.user-modal-stock-pipeline').css('background-color','#0F1D30');
        $('.user-modal-cold-call-logs').css('background-color','#0F1D30');
        $('.user-modal-archive_property').css('background-color','#0F1D30');
        $('.user-modal-networking').css('background-color','#0F1D30');
        $('.user-modal-travelTracking').css('background-color','#1B375A');

        $('.overview-information').hide();
        $(".property-information").hide();
        $(".appointments-information").hide();
        $(".revenue-information").hide();
        $(".other-listing").hide();
        $(".stock-pipeline").hide();
        $(".archive-prop").hide();
        $(".networking").hide();
        $(".travel-tracking").show();

        $('.upcoming_trips').addClass('active');
        $('.past_trips').removeClass('active');
        $('.missed_trips').removeClass('active');
        $('#upcoming_trips').addClass('active in');
        $('#past_trips').removeClass('active in');
        $('#missed_trips').removeClass('active in');

    })
    
    $(".userProfileTd").click(function(){
        $("#userProfileModal").modal('show');
    });
    
    $(".property-details").click(function(){
        $("#profileModal").modal('show'); 
    });

    $( "#btn_appointment_today" ).click(function() {
        var d = new Date();
        var day = d.getDate();
        
        var transform = -67.33 * (day - 3);
        if(transform >= 0){
            transform = 0;
        }
        $(".grBOFc").css('transform','translateX('+transform+'px)');
        $(".grBOFc").css('transition-duration', '0.5s');
    })
    
    $("[id^=dd_year_id_]").click(function(){
        $(".grBOFc").css('transform','translateX(0px)');
        $(".grBOFc").css('transition-duration', '0.5s');
    })

    // collapsable panel
    $(document).on('click','.title', function () {
        if($(this).parent().parent().hasClass('active')){
            $(".panel div").removeClass("active");
            $(this).parent().parent().removeClass('active');
        }else{
            $(".panel div").removeClass("active");
            $(this).parent().parent().addClass('active');
        }
    });

    //scroll to top on close add property modal
    $("#closeModal_add_property").on("click", function(){
        window.$(".modal_add_property").scrollTop(0);
    });
    $("#closeModal_add_property_success").on("click", function(){
        window.$(".modal_add_property").scrollTop(0);
    });
    $("#addPropertyBtn").on("click", function(){
        window.$(".modal_add_property").scrollTop(0);
    })
    $("#editPropertyBtn").on("click", function(){
        window.$(".modal_add_property").scrollTop(0);
    })

    //scroll to top on close Add stock pipeline modal
    $("#closeModal_add_stockpipeline").on("click", function(){
        window.$(".modal_add_stockpipeline").scrollTop(0);
    });
    $("#addStockPipelineBtn").on("click", function(){
        window.$(".modal_add_stockpipeline").scrollTop(0);
    })

    //scroll to top on close Add Appointment modal
    $("#closeModal_add_appointment").on("click", function(){
        window.$(".modal_add_appointment").scrollTop(0);
    });
    $("#addAppointmentBtn").on("click", function(){
        window.$(".modal_add_appointment").scrollTop(0);
    })

    //evo calendar
    $(window).resize(function() {
        $('#evoCalendar').addClass('sidebar-hide event-hide');
    });
}
