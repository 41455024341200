import React,{ Component } from "react";

import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import Loader from '../../Loader';

import AddressGoogleMap from "./AddressGoogleMap";

import { Icon } from '@iconify/react';
import officeBuildingMarker from '@iconify/icons-mdi/office-building-marker';
import Swal from 'sweetalert2';

import ErrorText from '../../../auth/ErrorText';

class AddStockPipeline extends Component{
    constructor(props) {
        super(props);
        this.state = {
            className: null,
            full_address: null,
            full_address_length: 0,

            map_address: null,
            lat: null,
            lng: null
        }
        this.submitHandler = this.submitHandler.bind(this);
        this.closeAddStockPipelineModal = this.closeAddStockPipelineModal.bind(this);
        this.onInputchange = this.onInputchange.bind(this);
    }

    opensweetalert(){
        Swal.fire({
            icon: 'success',
            title: 'Stock pipeline added successfully ',
            showConfirmButton: true,
        })
    }
    
    onInputchange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
        if(event.target.name === "full_address"){
            let len = event.target.value;
            len = len.length;
            this.setState({full_address_length: len})
        }
    }

    changeDateFormat(fromDate) {
        var d = new Date(fromDate),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
    
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [year, month, day].join('-');
    }
    
    async submitHandler(event){
        event.preventDefault(); 
        this.setState({loading: true});
        const token = localStorage.getItem('token');
        let user_id = this.props.agent_data.user_id
        await this.props.add_stock_pipeline_data(token, user_id, this.state.full_address, this.state.lat, this.state.lng, this.state.map_address);
        
        if(this.props.add_stock_pipeline_status === 200){
            this.opensweetalert();
            this.closeAddStockPipelineModal();
            window.$("#addStockPipelineModal").modal('hide');

        }
        this.setState({loading: false});

        //refresh stock pipeline list
        await this.props.reset_stockpipeline_data()
        // this.setState({
        //     startDate: new Date(new Date().getFullYear(), 0, 1),
        //     endDate:   new Date(new Date()),
        //     CalenderMinDate: new Date(new Date().getFullYear(), 0, 1),
        // })
        this.setState({stock_loader: true});
        //await this.props.get_auth_data(this.props.agent_id,token);
        var date = new Date();
        var fromDate = this.changeDateFormat(new Date(date.getFullYear(), date.getMonth() - 2, 1));
        var toDate = this.changeDateFormat(new Date(date.getFullYear(), date.getMonth()));
        var page = 1;
        let is_archived='0'
        let stock_pipeline_id = ''
        
        await this.props.get_stock_pipeline_data(page, is_archived, stock_pipeline_id, this.props.authtoken);
    
        // var startDate = this.changeDateFormat(this.state.startDate);
        // var endDate = this.changeDateFormat(this.state.endDate);

        this.props.get_stockpipeline_chart_by_id(fromDate, toDate, this.props.agent_id, token);
    
        this.setState({stock_loader: false});
        this.props.set_page("stockpipeline_tab", false);
    }

    //clear modal data
    closeAddStockPipelineModal(){
        this.setState({
            className: null,
            full_address: null,
            full_address_length: 0,
            
            map_address: null,
            lat: null,
            lng: null
        },
        async () => {
            await this.props.reset_add_stockpipeline_form();
            this.props.reset_google_map();
            window.$("#full_address").val("");
        })
        window.$("#addStockPipelineModal").modal('hide');
    }

    callbackFunction = (address, lat, lng) => {
        this.setState({
            map_address: address,
            lat: lat,
            lng: lng
        })
    }

    render(){
        return(
            <div>
                <div className="modal fade" data-backdrop="static" data-keyboard="false" id="addStockPipelineModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={this.setwrapperRef} >
                    <div className="modal-dialog" role="document" ref={this.wrapperRef}>
                        <div className="modal-content modal_add_stockpipeline modalheight" style ={{borderRadius : '12px' }} >
                            <div className="modal-header" style={{ backgroundColor: "#EDF6F6"}}>
                                <h4 className="modal-title fontWeight600"> <Icon className="message_icon color27AFAF" icon={officeBuildingMarker} height='25' width='25' /> Add Stock Pipeline</h4>
                                <div className="circle" id="closeModal_add_stockpipeline" onClick={this.closeAddStockPipelineModal}>
                                    <img src="images/modal-close.png " width="12" height="12" alt="" />
                                </div>
                            </div>
                            
                            {this.props.stockpipeline_form_error ? 
                                <ErrorText errorMsg = {this.props.stockpipeline_form_error} />
                            : 
                                null
                            }
                            
                            <div className="modal-body">
                                <center>
                                <form id="addStockPipelineform" onSubmit={this.submitHandler}>        
                                    {/* Full Address */}
                                    <div className="shadow" ref="myinput" style={(Number(this.props.add_property_modal_state)) === 1 ? {display: "block"} : {display: this.state.display_card} }>
                                        <p className="cardHeader">What would you like to call this property?</p>
                                        <p className="cardSubheader">Enter the property address details</p>
                                        <div className='form_border'>                                            
                                            <div className='full-input'>
                                                <textarea className="input_modal fontWeight800" style={{resize: 'none'}} rows="4" cols="50" name='full_address' id="full_address" placeholder="Enter full address" ref="full_address" maxLength="50" onChange={this.onInputchange} required>
                                                    {this.state.full_address}
                                                </textarea>
                                                <p className={this.state.full_address_length >= 40 ? "colorEA676A": null} style={{textAlign:"right"}}> {this.state.full_address_length}/50</p>
                                            </div>
                                        </div>
                                        <br/><br/>
                                    </div>
                                    
                                    {/* Google Address */}
                                    <div className="shadow">
                                        <p className="cardHeader ml10 mr10">Set this property’s location on the map for sharing directions.</p>
                                        <AddressGoogleMap
                                            parentCallback = {this.callbackFunction}
                                        />
                                        <br/><br/>
                                    </div>

                                    <br/><br/>
                                    {!this.state.loading ?
                                        <div> 
                                            <button id="addStockPipelineBtn" type="submit" className="btn btn-primary fontBold" style={{ background:'#4AA49C',borderRadius:'4px'}}>
                                                <Icon className="message_icon" icon={officeBuildingMarker} height='20' width='20' />
                                                Add stock Pipline
                                            </button>
                                        </div>
                                        :
                                        <Loader/>
                                    }
                                    <br/>
                                </form>
                                </center>
                            </div>
                        </div> 
                    </div>
                </div> 
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        add_stock_pipeline_data : (token, user_id, full_address, lat, lng, map_address ) => dispatch(actions.add_stock_pipeline_data(token, user_id, full_address, lat, lng, map_address)),
        reset_google_map: () => dispatch(actions.reset_google_map()),
        reset_add_stockpipeline_form: () => dispatch(actions.reset_add_stockpipeline_form()),
        
        reset_stockpipeline_data: () => dispatch(actions.reset_stockpipeline_data()),
        get_stock_pipeline_data : (page, is_archived, stock_pipeline_id, token) => dispatch(actions.get_stock_pipeline_data(page, is_archived, stock_pipeline_id, token)),
        get_stockpipeline_chart_by_id : (fromDate, toDate, agent_id, token) => dispatch(actions.get_stockpipeline_chart_by_id(fromDate, toDate, agent_id, token)),
        //set page for calendar
        set_page: (page, reset_date_range) => dispatch(actions.set_page(page, reset_date_range)),
    }
}
  
const mapStateToProps = state => {
    return{ 
        agent_data: state.agent_modal.agent_data,
        add_stock_pipeline_status: state.add_stock_pipeline.add_stock_pipeline_status,
        stockpipeline_form_error: state.add_stock_pipeline.stockpipeline_form_error
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddStockPipeline);
