import * as actionTypes from './actionsTypes';
import Constants from '../../constants/Constants';

var myHeaders = '';
var raw = '';
var requestOptions = '';

export const get_buyer_lead = (token, agent_id, page, filter, active_filter, tag_filter) => {
    return async dispatch => {
        myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token);
        raw = JSON.stringify(
        {
            "agent_id": agent_id,
            "page": page,
            "filter": filter,

            "active_filter": active_filter,
            "tag_filter": tag_filter
        });
        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        //await fetch(Constants.GET_BUYER_LEAD, requestOptions)
        await fetch(Constants.GET_CONTACT, requestOptions)
        .then(response => response.json())
        .then(data => {
            dispatch(fetch_buyer_lead(data, page));
        })
    }
}
export const fetch_buyer_lead = (data, page) => {
    let load_more = '';
    if(data.data.length === 10){
        load_more = true;
    }else{
        load_more = false;
    }
    return{
        type: actionTypes.GET_BUYER_LEAD,
        buyer_lead_data: data.data,
        buyer_lead_page: page,
        buyer_lead_load_more: load_more,
        buyer_lead_export: data.export
    }
}

export const add_buyer_lead = (first_name, last_name, mobile_no, email, address, tag, user_id, note) => {
    return async dispatch => {
        //var date = new Date();
        //var md5 = require('md5');
        //var contact_id = md5(date.getSeconds()+date.getMilliseconds());
        let error=[];
        if((first_name === null) || ((mobile_no === null) && (email === null)) || (user_id === null)){
            if(user_id === null){
                error.msg = "Please Select Agent";
                dispatch(submitFail(error));
            }else if(first_name === null){ 
                error.msg = "Please Enter the First Name of Contact";
                dispatch(submitFail(error));
            }else if((mobile_no === null) && (email === null)){
                error.msg = "Please Enter Mobile no. or Email address";
                dispatch(submitFail(error));
            }
        }else{
            myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", localStorage.getItem('token'));
              
            raw = JSON.stringify(
            {
                "firstname": first_name,
                "lastname": last_name,
                "address": address ? address : "",
                "mobile_no": mobile_no ? mobile_no : "",
                "email": email ? email : "",
                "tag": tag,
                "user_id": user_id,
                "note": note
                // "remarks": remarks ? remarks : "",
                // "portal_logo": localStorage.getItem('logo'),
                // "property_portal_name": localStorage.getItem('office_name'),
                // "office_lead": 1
            });
            requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            error.msg = "";
            dispatch(submitFail(error));

            await fetch(Constants.CREATE_CONTACT, requestOptions)
            .then(response => response.json())
            .then(data => {
                dispatch(buyer_lead(data));
            })
            .catch(error => console.log('error', error));
        }
    }
}
export const buyer_lead = (data) =>{
    return{
        type: actionTypes.ADD_BUYER_LEAD,
        response: data.status
    }
}

export const submitFail = (error) => {
    return {
        type: actionTypes.BUYER_LEAD_SUBMIT_FAIL,
        add_buyer_lead_form_error: error.msg
    };
}

export const reset_buyer_lead = ()=>{
    return {
        type: actionTypes.RESET_BUYER_LEAD
    }
}
export const reset_buyer_lead_modal = ()=>{
    return {
        type: actionTypes.RESET_BUYER_LEAD_MODAL
    }
}

export const get_user_buyer_lead = (token, lead_id, agent_id) =>{
    return async dispatch => {
        myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token);
        raw = JSON.stringify(
        {
            "user_id": agent_id,
            "id": lead_id,
        });
        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        await fetch(Constants.GET_CONTACT_DETAILS, requestOptions)
        .then(response => response.json())
        .then(data => {
            dispatch(user_buyer_lead(data));
        })
    }
}
export const user_buyer_lead = (data) => {
    return{
        type: actionTypes.GET_USER_BUYER_LEAD,
        user_buyer_lead_data: data.data
    }
}

export const edit_buyer_lead = (user_id, lead_id, is_office_lead, first_name, last_name, mobile_no, email, address, note, tag) => {
    return async dispatch => {
        let error=[];
        if((first_name === null) || ((mobile_no === null || mobile_no === "") && (email === null || email === ""))){
            if(first_name === null){ 
                error.msg = "Please Enter the Name of Buyer Lead";
                dispatch(submitFail(error));
            }else if((mobile_no === null || mobile_no === "") && (email === null || email === "")){
                error.msg = "Please Enter Mobile no. or Email address";
                dispatch(submitFail(error));
            }
        }else{
            myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", localStorage.getItem('token'));
              
            raw = JSON.stringify(
            {
                "user_id": user_id,
                "id": lead_id,
                "firstname": first_name,
                "lastname": last_name,
                "mobile_no": mobile_no ? mobile_no : "",
                "email": email ? email : "",
                "address": address ? address : "",
                "note": note,
                "tag": tag,
                "office_lead": is_office_lead,
                //"description": description,
                // "remarks": remarks ? remarks : "",
                // "portal_logo": portal_logo,
                // "property_portal_name": property_portal_name,
            });
            requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            error.msg = "";
            dispatch(submitFail(error));

            await fetch(Constants.UPDATE_CONTACT_DETAILS, requestOptions)
            .then(response => response.json())
            .then(data => {
                dispatch(buyer_lead_edit(data));
            })
            .catch(error => console.log('error', error));
        }
    }
}
export const buyer_lead_edit = (data) =>{
    return{
        type: actionTypes.EDIT_BUYER_LEAD,
        buyer_lead_form_error: data.data.msg,
        buyer_lead_form_status: data.status
    }
}

