import React from 'react';
import ReactDOM from 'react-dom';

import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';
//import cors from 'cors';

import auth_reducer from './store/reducers/auth_reducer';
import overview_reducer from './store/reducers/overview_reducer';
import property_reducer from './store/reducers/property_reducer';
import appointment_reducer from './store/reducers/appointment_reducer';
import revenue_reducer from './store/reducers/revenue_reducer';
import compare_reducer from './store/reducers/compare_agents_reducer';
import agent_modal_reducer from './store/reducers/agent_modal_reducer';
import property_modal_reducer from './store/reducers/property_modal_reducer';
import add_property_modal_reducer from './store/reducers/add_property_reducer';
import transaction_log_reducer from './store/reducers/transaction_log_reducer';
import buyer_lead_reducer from './store/reducers/buyer_lead_reducer';
import add_stock_pipeline_reducer from './store/reducers/add_stock_pipeline_reducer'
import settings_reducer from './store/reducers/settings_reducer'

import add_appointment_modal_reducer from './store/reducers/add_appointment_reducer';
import edit_appointment_reducer from './store/reducers/edit_appointment_reducer';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

//cors
//import cors from 'cors'

const rootreducer = combineReducers({
    auth: auth_reducer,
    overview: overview_reducer,
    property: property_reducer,
    appointment: appointment_reducer,
    revenue: revenue_reducer,
    compare_reducer: compare_reducer,
    agent_modal: agent_modal_reducer,
    property_modal: property_modal_reducer,
    add_property_modal: add_property_modal_reducer,
    transaction_log: transaction_log_reducer,
    buyer_lead: buyer_lead_reducer,
    add_stock_pipeline: add_stock_pipeline_reducer,
    settings: settings_reducer,
    add_appointment_modal: add_appointment_modal_reducer,
    edit_appointment_modal: edit_appointment_reducer
})


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootreducer, composeEnhancers(
    applyMiddleware(thunk)
));

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
