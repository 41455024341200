import React , { Component } from 'react';
import {connect} from 'react-redux';
import priceTag from '@iconify/icons-entypo/price-tag';
//modal
//import ReportIssueFeatureModal from '../modals/ReportIssueFeatureModal';
import SettingsModal from '../modals/SettingsModal';
//icons
import { Icon } from '@iconify/react';
import handshakeIcon from '@iconify/icons-emojione-monotone/handshake';
//import roundReportProblem from '@iconify/icons-ic/round-report-problem';
import settingsFilled from '@iconify/icons-ci/settings-filled';

class DropDownMenu extends Component{

    handleLogout = () =>{
        localStorage.clear();
        this.props.history.push("/");
    } 
    
    handleReport = async () => {
        window.$("#reportIssueFeature").modal('show');
    }

    handleSettingsModal = () =>{
        window.$("#settingsModal").modal('show');
    }

    render(){
        //const firstname = sessionStorage.getItem('firstname');
       // var logo = sessionStorage.getItem('logo');
        const firstname = this.props.firstname;
        var logo = this.props.logo;
        
        return(
        <>
            <ul className="dropdown-menu profileCard" style={{zIndex:'9999', background:'#fff', position: 'absolute', marginRight:10}}>
            <div className="card padTop15 padBottom15 padLeft15" style={{width: '242px'}}>
            {!logo ?
                <img data-toggle="dropdown" alt="" src = "user-images/default_logo.png" width="45" height="45" className = "dropleft dropdown-toggle cursorPointer img-circle" />
            :
                <img className="card-img-top" src={logo} alt="" width="90" height="45" />
            }
            <br /><br />
            <p className="fontInter fontWeight600">{localStorage.getItem('office_name')}</p>
    
            <div className = "row">
                <div className = "col-md-6">
                    <span style = {{fontFamily:'Inter',color:'#0F1D30',fontWeight:'600',fontSize:'12px'}}>{firstname}</span><br />
                    <span style = {{fontFamily:'Inter',color:'#b3b3b3',fontSize:'12px'}} >{this.props.totalAgentCount ? this.props.totalAgentCount : localStorage.getItem("totalAgentCount")} Agents</span>
                </div>
                {/* <div className = "col-md-3"></div>
                <div className = "col-md-3">
                    <span style={{color:'#4AA49C',fontSize:'12px',fontWeight:'bold',cursor:'pointer'}}>Edit</span>
                </div> */}
            </div>
            <hr className = "mt10 mb10" />
            {/* {localStorage.getItem('subscription_plan_id') && localStorage.getItem('subscription_plan_id') !== null && localStorage.getItem('subscription_plan_id') !== undefined && localStorage.getItem('subscription_plan_id') !== "null" && localStorage.getItem('subscription_plan_id') !== "undefined" ?  */}
                <>
                <div className = "row">
                    <div className = "col-md-9">
                        <a href="/transaction-log"> <span className="color0F1D30 fontWeight600 fontInter fontSize12" >My subscription</span></a>
                    </div>
                    <div className = "col-md-2">
                        <span><Icon style={{color:"#4AA49C", verticalAlign: "middle"}} width='15' height="15" icon={handshakeIcon}/></span>
                    </div>
                </div>
                <hr className = "mt10 mb10" />
                </>
                {/* : null
            } */}

            <div className = "row">
                <div className = "col-md-9">
                    <a href="/pricing"> <span className="color0F1D30 fontWeight600 fontInter fontSize12" >Pricing</span></a>
                </div>
                <div className = "col-md-2">
                    <span><Icon style={{color:"#4AA49C", verticalAlign: "middle"}} width='15' height="15" icon={priceTag}/></span>
                </div>
            </div>
            {window.location.pathname === "/overview" ? 
            <>
                <hr className = "mt10 mb10" />
                <div className = "row">
                    <div className = "col-md-9">
                        <a href="#" onClick={this.handleSettingsModal}> <span className="color0F1D30 fontWeight600 fontInter fontSize12" >Settings</span></a>
                    </div>
                    <div className = "col-md-2">
                        <span><Icon style={{color:"#4AA49C", verticalAlign: "middle"}} width='15' height="15" icon={settingsFilled}/></span>
                    </div>
                </div>
            </>
            : null}
            <hr className = "mt10 mb10" />
            
            {/* <div className = "row">
                <div className = "col-md-9">
                    <span className="color0F1D30 fontWeight600 fontInter fontSize12 cursorPointer" onClick={this.handleReport}>Report Issue / New Feature</span>
                </div>
                <div className = "col-md-2">
                    <span><Icon style={{color:"#4AA49C", verticalAlign: "middle"}} width='15' height="15" icon={roundReportProblem}/></span>
                </div>
            </div>
            <hr className = "mt10 mb10" /> */}
            {/*<div className = "row">
                <div className = "col-md-9">
                    <span className="color0F1D30 fontWeight600 fontInter fontSize12">Ave. Cost per Agent</span>
                </div>
                <div className = "col-md-2">
                    <span className="cursorPointer fontSize12">R250</span>
                </div>
            </div> */}
            
            {/* <hr className = "mt10 mb10" />
            <div className = "row">
                <div className = "col-md-9">
                    <span className="color0F1D30 fontWeight600 fontInter fontSize12" >Contact Support</span>
                </div>
                <div className = "col-md-2">
                    <span><i className="fa fa-phone" aria-hidden="true" style ={{color:'#4AA49C'}}></i></span>
                </div>
            </div>
            <hr className = "mt10 mb10" /> */}
            <div className = "row">
                <div className = "col-md-9">
                    {/* <span className="color0F1D30 fontWeight600 fontInter fontSize12 cursorPointer" onClick ={this.handleLogout}>Log Out</span> */}
                    <span className="fontWeight600 fontInter fontSize12 cursorPointer"><a className="color0F1D30" href="/logout">Log Out</a></span>
                </div>
            </div>
            </div>
        </ul>
        {this.props.data ?
            <SettingsModal settings_data={this.props.settings_data}></SettingsModal>
        : null}
        {/* <ReportIssueFeatureModal></ReportIssueFeatureModal> */}
        </>
        )
    }
}

const mapStateToProps = state =>{
    return{
        logo: state.auth.logo,
        firstname: state.auth.firstname,
        totalAgentCount: state.overview.totalAgentCount,
        settings_data: state.overview.settings,
        data: state.overview.data
    };
};
  
//export default withRouter(connect(null,mapDispatchToProps)(DropDownMenu));
// export default withRouter(DropDownMenu);
export default connect(mapStateToProps)(DropDownMenu);