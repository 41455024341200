import React,{ Component } from "react";
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import Loader from '../Loader';
import ErrorText from '../../auth/ErrorText';
import SuccessText from '../../auth/SuccessText';

import Swal from 'sweetalert2';

//import $ from 'jquery';
import { Icon } from '@iconify/react';
import settingsFilled from '@iconify/icons-ci/settings-filled';
import {Checkbox} from 'primereact/checkbox';

class SettingsModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            checked: false,
            graphs: [],
        }
        this.submitSettingsHandler = this.submitSettingsHandler.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.onGraphChange = this.onGraphChange.bind(this);
    }

    //handle update settings button
    async submitSettingsHandler(event){
        event.preventDefault(); 
        this.setState({loading: true});
        const token = localStorage.getItem('token');
        await this.props.get_update_settings(this.state.graphs,token);
        if(Number(this.props.settings_form_status) === 200) {
            this.props.get_overview_data(token)
            this.opensweetalert();
            window.$("#settingsModal").modal('hide');
        }
        this.setState({loading: false});
        window.location.reload(false);
    }

    opensweetalert(){
        Swal.fire({
            icon: 'success',
            title: 'Settings updated successfully ',
            showConfirmButton: true,
        })
    }

    //clear modal data
    closeModal(){
        this.setState({
            loading: false
        })
    }

    onGraphChange(e) {
        let selectedGraphs = [...this.state.graphs];

        if (e.checked)
            selectedGraphs.push(e.value);
        else
            selectedGraphs.splice(selectedGraphs.indexOf(e.value), 1);
        selectedGraphs.sort()    
        this.setState({graphs: selectedGraphs})
    }

    componentDidMount(){
        this.setState({graphs: this.props.settings_data})
    }
    
    render(){     
        return(
            <div>
                <div className="modal fade" data-backdrop="static" data-keyboard="false" id="settingsModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={this.setwrapperRef} >
                    <div className="modal-dialog" role="document" ref={this.wrapperRef}>
                        <div className="modal-content settingsModal modalheight" style ={{borderRadius : '12px' }} >
                            <div className="modal-header" style={{ backgroundColor: "#EDF6F6"}}>
                                <h4 className="modal-title fontWeight600" style={{textAlign:"left"}}> <Icon icon={settingsFilled} className="color27AFAF" height='25' width='25'style={{verticalAlign:'bottom'}}/> Settings</h4>
                                <div id="closeModal_settingsModal" className="circle" data-dismiss="modal" onClick={this.closeModal}>
                                    <img src="images/modal-close.png " width="12" height="12" alt=""/>
                                </div> 
                            </div>
                            
                            <div className="modal-body">
                                {this.props.mark_as_sold_form_error ? 
                                    this.props.mark_as_sold_form_error === "success" ? 
                                        <SuccessText successMsg = {this.props.mark_as_sold_form_error} /> 
                                    :
                                        <ErrorText errorMsg = {this.props.mark_as_sold_form_error} />
                                : 
                                    null
                                }

                                <form id="settingsform" onSubmit={this.submitSettingsHandler}> 
                                    <br/>
                                    <div className='full-input' style={{border:"none", textAlign:"left"}}>
                                        <h2>Overview Page:</h2>
                                        <h3 className="ml5 mt10">Graphs to Display in Overview Page</h3>
                                            <ol className="settings">
                                                <li>
                                                    <div className="p-field-checkbox ml5">
                                                        <Checkbox inputId="viewing" name="grpahs" value="1" onChange={this.onGraphChange} checked={this.state.graphs.indexOf('1') !== -1} />
                                                        <label htmlFor="viewing" className="ml5">Viewing Graph</label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="p-field-checkbox ml5">
                                                        <Checkbox inputId="Properties" name="grpahs" value="2" onChange={this.onGraphChange} checked={this.state.graphs.indexOf('2') !== -1} />
                                                        <label htmlFor="Properties" className="ml5">Properties Graph</label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="p-field-checkbox ml5">
                                                        <Checkbox inputId="sales_rent" name="grpahs" value="3" onChange={this.onGraphChange} checked={this.state.graphs.indexOf('3') !== -1} />
                                                        <label htmlFor="sales_rent" className="ml5">Sales/Rent Graph</label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="p-field-checkbox ml5">
                                                        <Checkbox inputId="sold_rented" name="grpahs" value="4" onChange={this.onGraphChange} checked={this.state.graphs.indexOf('4') !== -1} />
                                                        <label htmlFor="sold_rented" className="ml5">Sold/Rented Graph</label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="p-field-checkbox ml5">
                                                        <Checkbox inputId="revenue" name="grpahs" value="5" onChange={this.onGraphChange} checked={this.state.graphs.indexOf('5') !== -1} />
                                                        <label htmlFor="revenue" className="ml5">Revenue Graph</label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="p-field-checkbox ml5">
                                                        <Checkbox inputId="stockpipeline" name="grpahs" value="6" onChange={this.onGraphChange} checked={this.state.graphs.indexOf('6') !== -1} />
                                                        <label htmlFor="stockpipeline" className="ml5">Stock Pipeline Graph</label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="p-field-checkbox ml5">
                                                        <Checkbox inputId="contact" name="grpahs" value="7" onChange={this.onGraphChange} checked={this.state.graphs.indexOf('7') !== -1} />
                                                        <label htmlFor="contact" className="ml5">Call/Contact Graph</label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="p-field-checkbox ml5">
                                                        <Checkbox inputId="profitability" name="grpahs" value="8" onChange={this.onGraphChange} checked={this.state.graphs.indexOf('8') !== -1} />
                                                        <label htmlFor="profitability" className="ml5">Profitability Graph</label>
                                                    </div>
                                                </li>
                                            </ol>
                                        
                                    </div>
                                    <br/><br/>
                                    {!this.state.loading ?
                                        this.props.mark_as_sold_form_error !== 'success' ?
                                            <div> 
                                                <button id="updateSettingsBtn" type="submit" className="btn btn-primary fontBold" style={{ background:'#4AA49C',borderRadius:'4px'}}>
                                                <Icon icon={settingsFilled} className="" height='18' width='18'style={{verticalAlign:'bottom', marginRight:"5px"}}/>
                                                    Update Settings
                                                </button>
                                            </div>
                                        : null
                                        :<Loader/>
                                    }
                                    <br/><br/>
                                </form>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        get_update_settings: (settings, token) => dispatch(actions.get_update_settings(settings, token)),
        get_overview_data : (token) => dispatch(actions.get_overview_data(token))
    }
  }
  
const mapStateToProps = state => {
    return{
        settings_form_status: state.settings.settings_form_status
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SettingsModal);
