import * as actionTypes from './actionsTypes';
import Constants from '../../constants/Constants';

var url='';
var myHeaders = '';
var raw = '';
var requestOptions = '';

export const get_update_settings = (settings, token) =>{
    return async dispatch =>{
        url = Constants.UPDATE_SETTINGS;
        myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token);
        raw = JSON.stringify(
        {
            "settings": settings,
        });
        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        await fetch(url, requestOptions)
        .then(response => response.json())
        .then(data => {
            dispatch(update_settings(data));
        })
    }
}
export const update_settings = (data) =>{
    return{
        type: actionTypes.UPDATE_SETTINGS,
        settings_form_status: data.status,
        settings_form_msg: data.data.msg
    }
}