import React ,  { Component } from 'react';
import {connect} from 'react-redux';
import * as actions from './store/actions/index';

import Header from  './components/menu/Header';
import Footer from './components/Footer';
import modal from './CustomFiles/js/modal';
import Constants from './constants/Constants';
import AgentInformationModal from './components/modals/AgentInformationModal';
import PropertyDetailModal from './components/modals/PropertyDetailModal';
import AddAppointment from './components/modals/Appointment/AddAppointment';
import IdleTimerContainer from './components/IdleTimerContainer';

import './CustomFiles/bower_components/bootstrap/dist/css/bootstrap.min.css';
import './CustomFiles/bower_components/font-awesome/css/font-awesome.min.css';
import './CustomFiles/bower_components/Ionicons/css/ionicons.min.css';
import './CustomFiles/dist/css/AdminLTE.min.css';
import './CustomFiles/plugins/iCheck/square/blue.css';
import './CustomFiles/bower_components/datatables.net-bs/css/dataTables.bootstrap.min.css';
import './CustomFiles/dist/css/skins/_all-skins.min.css';
import './CustomFiles/css/predefined.css';
import './CustomFiles/bower_components/bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';

import Helmet from "react-helmet";
import SellerLinkedMessage from './components/modals/SellerLinkedMessage';
import Loader from './components/Loader';
import PropertyChart from  './components/charts/PropertyChart';
import AgentList from './components/AgentList';
import CrmVerifiedModal from './components/modals/CRMInfoModal/CrmVerifiedModal';
import CrmNotVerifiedModal from './components/modals/CRMInfoModal/CrmNotVerifiedModal';

//icons
import { Icon } from '@iconify/react';
import officeBuildingMarker from '@iconify/icons-mdi/office-building-marker';
import downloadFill from '@iconify/icons-eva/download-fill';
import DataTable from 'react-data-table-component';
import ReactExport from "react-export-excel";
import { Dropdown } from 'semantic-ui-react';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

//modal
//import AddProperty from './components/modals/AddProperty';

const $ = require('jquery');
$.DataTable = require('datatables.net');

var globalAgentList = [];
var globalAgentListName = [];
var globalAgentListImage = [];

//datatable
let data=[];
let columns = [];
let data_table_data = [];

var colorList = ['#7B8CA1','#EA676A','#63C8BA','#F8CA55','#F09A56','#6ADA8A', '#9B66E3', '#547DE5', '#B5A78D', '#DE6DA8',
  '#A368D9','#B43B1D','#229649','#2C3E50','#8c9966','#53F49B','#DC9D7D','#660E52','#39C9AE','#BCA7C4',
  '#D08E27','#A3DB7D','#606792','#11E797','#1DC1C1','#D5C175','#1DC1C1','#421B6C','#98E708','#EE2A09',  
  '#017C33','#24F080','#CC5A45','#801FD4','#04405D','#133123','#1B42B1','#7EC377','#2DE6E0','#9FCE27',
  '#F96CDD','#41197D','#4A809B','#794D61','#BA28E3','#183A8C','#D07848','#4F3B87','#3A8947','#100769',
  '#B7931E','#AA0A71','#417A92','#AC5B6C','#7E8F92','#C06EA7','#866E50','#561C56','#DB3DAF','#F03C44',
  '#90A8F4','#93AC64','#5B532B','#1CBB4B','#05A5A5','#CEC21E','#D3A8FF','#3F4D5E','#BD0B49','#2CA6A0',
  '#C7B541','#6D8D6B','#70C29B','#0B0BE2','#EE19DA','#588546','#DBB5A2','#609CBF','#38198D','#904B6C',
  '#8BD18C','#32638D','#792A4F','#7184E1','#35A50F','#4B3871','#8C8007','#4B2257','#089564','#F1A0C1',
  '#0D9168','#8CA06C','#0A7EB7','#AB53CB','#43246A','#2F0020','#06759B','#785646','#B36935','#1A7607',
];

const FilterComponent = ({ filterText, onFilter, onClear }) => (<>
  <label style={{ fontFamily: 'Roboto', fontSize: "14px", color: "#333333", fontWeight: "normal", marginTop:"7px", marginRight:"10px"}}>Search :</label>
  <input id="search" type="text" style={{border: "1px solid #daeeec", height: "28px"}} placeholder="Filter By Agent or Address" aria-label="Search Input" value={filterText} onChange={onFilter} />
  </>
);

const customStyles = {
  rows: {
    style: {
      minHeight: '72px', // override the row height
    }
  },
  headCells: {
    style: {
      paddingLeft: '8px', // override the cell padding for head cells
      paddingRight: '8px',
      fontSize:'12px',
      color:"#4AA49C",
      fontFamily: 'Inter',
      fontWeight: 600,
      textAlign:'center',
      marginLeft:"20px"
    },
  },
  cells: {
    style: {
      paddingLeft: '18px', // override the cell padding for data cells
      paddingRight: '8px',
    },
  },
};

class Properties extends Component{

  constructor(props){
    super(props);
    this.state ={
      isLoading:false,
      agent_id:null,      
      calenderStartDate : new Date(new Date().getFullYear(), 0, 1),
      calenderToDate : new Date(new Date().getFullYear(), 11, 31, 0, 0, 0),
      CalenderMinDate: new Date(new Date().getFullYear(), 0, 1),
      prop_agent_list_data: null,
      globalAgentList: null,
      globalAgentListName: null,
      globalAgentListImage: null,

      filterText: "",
      resetPaginationToggle: false,
      date_sold: null,
      data_table_filter_by: 1,
    }
    this.changeDateFormat = this.changeDateFormat.bind(this);
    this.handleShowModal = this.handleShowModal.bind(this);
  }

  getSubHeaderComponent = () => {
    return (
      <FilterComponent
        onFilter={(e) => {
          let newFilterText = e.target.value;
          this.filteredItems = data.filter(
            (item) =>
              (item.agent_name && item.agent_name.toLowerCase().includes(newFilterText.toLowerCase())) || (item.address && item.address.toLowerCase().includes(newFilterText.toLowerCase())) || (item.unit_name && item.unit_name.toLowerCase().includes(newFilterText.toLowerCase()))
          );
          this.setState({ filterText: newFilterText });
        }}
        onClear={this.handleClear}
        filterText={this.state.filterText}
      />
    );
  };

  handleClear = () => {
    const { resetPaginationToggle } = this.state;

    if (this.state.filterText) {
      this.setState({
        resetPaginationToggle: !resetPaginationToggle,
        filterText: ""
      });
    }
  };

  handleShowModal = (event) =>{
    let id = event.target.getAttribute("data-user_id");
    this.props.reset_agent_modal();
    const agent_id  =  id;

    this.setState({
      agent_id: agent_id,
    },()=> {
      window.$("#userProfileModal").modal('show');
      $(".user-modal-overview").click();
    })
  }

  showPropertyModal = async (event) =>{
    this.props.reset_property_modal();
    
    const agent_id = event.target.getAttribute('data-user_id');
    const prop_id = event.target.getAttribute('data-prop_id');
    const date_sold = event.target.getAttribute('data-date_sold');

    this.setState({
      agent_id: agent_id,
      date_sold: date_sold
    })
    

    /* Creating Date From 1st of Year to Last Of Year */
    // var fromDate = new Date("1/1/" + (new Date()).getFullYear());
    // var toDate = new Date(new Date().getFullYear(), 11, 31, 0, 0, 0);

    // var d = new Date(fromDate),
    // month = '' + (d.getMonth() + 1),
    // day = '' + d.getDate(),
    // year = d.getFullYear();
  
    // if (month.length < 2) month = '0' + month;
    // if (day.length < 2) day = '0' + day;
    //   fromDate = [year, month, day].join('-');
  
    // d = new Date(toDate);
    // month = '' + (d.getMonth() + 1);
    // day = '' + d.getDate();
    // year = d.getFullYear();
  
    // if (month.length < 2) month = '0' + month;
    // if (day.length < 2) day = '0' + day;
  
    // toDate = [year, month, day].join('-');

    const show_agents = 0
    const show_property = 0
    const from_property_page = 1
    const from_stock_pipeline = 0
    await this.props.update_add_appointment_modal_state(show_agents,show_property,from_property_page,from_stock_pipeline)

    var da = new Date();
    var fromDate = this.changeDateFormat(new Date(da.getFullYear(), da.getMonth() - 2, 1));
    var toDate = this.changeDateFormat(new Date(da.getFullYear(), da.getMonth(), da.getDate()));

    const token = localStorage.getItem('token');

    await this.props.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold);
    this.props.set_page("property_modal", true);
    window.$("#profileModal").modal('show');
  }

  showCRMVerifiedModal = () =>{
    window.$("#crmVerifiedModal").modal('show');
  }
  
  showCRMNotVerifiedModal = () =>{
    window.$("#crmNotVerifiedModal").modal('show');
  }

  componentDidMount = () => {
    modal();
    const token = localStorage.getItem("token");
    //const token = this.props.token;
    
    if(!token){
      localStorage.clear();
      this.props.history.push("/");
      return;
    }
    let filter_by = 1;
    //this.props.get_property_list(token, filter_by);
    var da = new Date();
    //var fromDate = this.changeDateFormat(new Date(da.getFullYear(), da.getMonth() - 2, 1));
    var fromDate = new Date("1/12/2018");
    fromDate =  this.changeDateFormat(fromDate);
    var toDate = this.changeDateFormat(new Date(da.getFullYear(), da.getMonth(), da.getDate()));
    this.props.get_property_list_date(filter_by, fromDate, toDate, token);
    fromDate = this.changeDateFormat(new Date(da.getFullYear(), da.getMonth() - 2, 1));
    
    // Getting Only Agent Data
    var date = new Date();
    var month = date.getMonth();
    var year = date.getFullYear();

    this.props.get_agent_list(month, year ,token);

    //var fromDate = new Date("1/1/" + ((new Date()).getFullYear() - 1));
    //var toDate = new Date(new Date().getFullYear(), 11, 31,0,0,0);
    // fromDate =  this.changeDateFormat(fromDate);
    // toDate =  this.changeDateFormat(toDate);

    // let d = new Date();
    // let day = '' + d.getDate();
    // month = '' + (d.getMonth() + 1);
    // year = d.getFullYear();
  
    // if (month.length < 2) month = '0' + month;
    // if (day.length < 2) day = '0' + day;
    // let toDate_stockpipeline = [year, month, day].join('-');

    // d = new Date();
    // day = '' + d.getDate();
    // month = '' + (d.getMonth() + 1);
    // year = d.getFullYear();
    // var fromDate_property = [year-1, 12, "01"].join('-');

    this.props.get_property_chart_property(fromDate, toDate, token);
    this.props.change_from_to_date(fromDate, toDate)
    this.props.set_page("properties_page", true);
    this.setState({
      prop_agent_list_data: [...JSON.parse(localStorage.getItem('data'))]
    })
  }

  changeDateFormat(fromDate){
    var d = new Date(fromDate),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year,month,day].join('-');
  }

  changeDateFormat_DMY(fromDate){
    var d = new Date(fromDate),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [day,month,year].join('-');
  }

  toDateOverview = (e)=> {
    var fromDate = null;
    var toDate = null;

    const token = localStorage.getItem('token');
    if (token === '' || token === null) {
      sessionStorage.clear();
      this.props.history.push("/");
      return;
    }

    fromDate = this.changeDateFormat(this.state.calenderStartDate);
    toDate = this.changeDateFormat(e.value);
    let filter_by = 1;
    //Property Table Date Filter
    this.props.get_property_list_date(filter_by, fromDate, toDate, token);
    
    /* Property Chart API */
    this.props.get_property_chart_property(fromDate, toDate, token);
    this.setState({isLoading:false});
    
  }

  handlePropertyModal = async (prop_id, date_sold, created_user_id, from_archiveProperty) =>{
    this.props.reset_property_modal();
    const show_agents = 0
    const show_property = 0
    const from_property_page = 1
    const from_stock_pipeline = 0
    await this.props.update_add_appointment_modal_state(show_agents,show_property,from_property_page,from_stock_pipeline)
    // var fromDate = new Date("1/1/" + (new Date()).getFullYear());
    // var toDate = new Date(new Date().getFullYear(), 11, 31, 0, 0, 0);

    // var d = new Date(fromDate),
    // month = '' + (d.getMonth() + 1),
    // day = '' + d.getDate(),
    // year = d.getFullYear();
    // if (month.length < 2) month = '0' + month;
    // if (day.length < 2) day = '0' + day;
    // fromDate = [year, month, day].join('-');

    // d = new Date(toDate);
    // month = '' + (d.getMonth() + 1);
    // day = '' + d.getDate();
    // year = d.getFullYear();
    // if (month.length < 2) month = '0' + month;
    // if (day.length < 2) day = '0' + day;
    // toDate = [year, month, day].join('-');
    var da = new Date();
    var fromDate = this.changeDateFormat(new Date(da.getFullYear(), da.getMonth() - 2, 1));
    var toDate = this.changeDateFormat(new Date(da.getFullYear(), da.getMonth(), da.getDate()));

    const token = localStorage.getItem('token');
    if(created_user_id){
      var property_notes_user_id = this.state.agent_id;
      let agent_id = created_user_id;
      let fromnetworking = 1;
      await this.props.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold, fromnetworking, property_notes_user_id);
    }else if(from_archiveProperty){
      let agent_id = this.state.agent_id;
      await this.props.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold, null, null, from_archiveProperty);
    }else{
      let agent_id = this.state.agent_id;
      await this.props.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold);
    }
    this.props.set_page("property_modal", true);
    window.$("#profileModal").modal('show');
  } 

  removeDuplicateItem = (arr)=> {        
    var c;        
    var len = arr.length;        
    var result = [];        
    var obj = {};                
    for (c = 0; c<len; c++)  {            
      obj[arr[c]] = 0;        
    }  
    for (c in obj) {            
      result.push(c);        
    }            
    return result;      
  }          
    
  renderPropertyChart = () => {  
    var agentList =  this.props.agent_list;
    var temp_property_chart_data=[];
    temp_property_chart_data = JSON.parse(JSON.stringify(this.props.property_agen_count));

    if((agentList.length > 0) && (temp_property_chart_data && temp_property_chart_data.length)){ 
      globalAgentList = [];
      globalAgentListName=[];
      for( let i = 0; i < agentList.length ; i++ ){  
        //adding agents with tracking on
        if(Number(agentList[i].tracking_prefrence) !== Number(0) ){
          var agentName = agentList[i].firstname + ' ' + agentList[i].lastname;
          globalAgentListName.push({
            agent_id: agentList[i].userid,
            name: agentName,
            color: colorList[i],
            image:agentList[i].image
          })
          globalAgentList.push(agentList[i].userid);
        }else{
          //Removing graph data of agents with tracking off 
          delete temp_property_chart_data[0][agentList[i].userid];
        }
      }
        
      let globalAgentListName1 = {}; 
      let objTitle = [];
      for (let i in globalAgentListName) { 
        objTitle = globalAgentListName[i]['agent_id'];
        globalAgentListName1[objTitle] = globalAgentListName[i];
      }

      var propertyChart='';
      //if((this.props.data && this.props.property_labels)){
        //if (this.props.property_labels.length > 0 && this.props.data.length > 0  ) {
          propertyChart = (<PropertyChart
            globalAgentListName={globalAgentListName1}
            globalAgentListImage={globalAgentListImage}
            globalAgentList={globalAgentList}
            total_agent={this.props.data.length} property_labels={this.props.property_labels}
            property_agen_count={temp_property_chart_data} overview = "0" 
          />);
          return propertyChart;
        //}
      //}
    }else {
      propertyChart = (<Loader />);
      return propertyChart;
    }
  }

  dateWisePropertyAppointment = (fromDate,toDate,prop_id,agent_id, date_sold, fromnetworking) => {
    fromDate = this.changeDateFormat(fromDate);
    toDate = this.changeDateFormat(toDate);

    const token = localStorage.getItem('token');
    if(Number(fromnetworking) === 1){
      var property_notes_user_id = this.state.agent_id;
      let agent_id = this.props.property_detail.agent_id;
      let fromnetworking = 1;
      this.props.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold, fromnetworking, property_notes_user_id)
    }else{
      this.props.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold)
    }

  }

  handleAddProperty(){
    window.$("#addPropertyModal").modal('show');
  }

  callbackFunction = (globalAgentList, globalAgentListName, globalAgentListImage) => {
    this.setState({
      globalAgentList: [...globalAgentList],
      globalAgentListName: [...globalAgentListName ],
      globalAgentListImage: [...globalAgentListImage]
    })
  }

  handleSelect = async (e, {value}) => {
    e.persist();
    const token = localStorage.getItem("token");
    this.props.set_filter_by(value);
    this.setState({data_table_filter_by: value})
    
    var date = new Date();
    var fromDate = new Date("1/12/2018");
    fromDate =  this.changeDateFormat(fromDate);
    var toDate = this.changeDateFormat(new Date(date.getFullYear(), date.getMonth(), date.getDate()));
    await this.props.get_property_list_date(value, fromDate, toDate, token);
    //await this.props.get_property_list_date(value, this.changeDateFormat(this.props.page_from_date), this.changeDateFormat(this.props.page_to_date), token);
    //await this.props.get_property_list(token, value);
  }

  render(){
    columns = [
      {
        name: 'Address',
        selector: 'address',
        sortable: true,
        ignoreRowClick: true,
        // onClick={window.handleShowModal(`${row.user_id}`)}
        style: {marginLeft: '15px', marginTop: '10px', marginBottom: '10px'},
        cell: row => <div tag="allowRowEvents" data-user_id={`${row.user_id}`} data-prop_id={`${row.prop_id}`} data-date_sold={`${row.date_sold}`} className="cursorPointer">
            <span tag="allowRowEvents" data-user_id={`${row.user_id}`} data-prop_id={`${row.prop_id}`} data-date_sold={`${row.date_sold}`} onClick={this.showPropertyModal} className="color2D3E54" style={{fontFamily: "Inter", textDecoration: "underline"}}>{row.address} </span>
            {Number(row.pro_user) === Number(1) && Number(row.crm_verified) === Number(1) ? 
              <img tag="allowRowEvents" data-user_id={`${row.user_id}`} alt='' src={"images/crm_verified.svg"} style={{verticalAlign: "text-bottom", marginLeft: "5px"}}  onClick={this.showCRMVerifiedModal}/>
            :
              Number(row.pro_user) === Number(1) && Number(row.crm_verified) === Number(0) ?
                <img tag="allowRowEvents" data-user_id={`${row.user_id}`} alt='' src={"images/crm_not_verified.svg"} style={{verticalAlign: "text-bottom", marginLeft: "5px"}}  onClick={this.showCRMNotVerifiedModal}/>
              :
                null
            }
            
            <span tag="allowRowEvents" data-user_id={`${row.user_id}`} data-prop_id={`${row.prop_id}`} data-date_sold={`${row.date_sold}`} onClick={this.showPropertyModal} className="color2D3E54 unit_name" style={{fontFamily: "Inter"}}>{row.unit_name}</span>
            {row.date_sold ? 
              <span className="soldspan" data-user_id={`${row.user_id}`} data-prop_id={`${row.prop_id}`} style={{paddingTop:2, marginLeft:"0px"}}> {Number(row.rented) === 1 ? "Rented" : "Sold"} </span> 
            : 
              <span className="property_sale_type_span" data-user_id={`${row.user_id}`} data-prop_id={`${row.prop_id}`} style={{paddingTop:2}}> {Number(row.property_type) === Number(1) ? "For Sale": "For Rent" }</span> 
            }
            {Number(row.is_office_listing) === Number(1) ? <span className="ml5 office_listing_span" data-user_id={`${row.user_id}`} data-prop_id={`${row.prop_id}`} style={{paddingTop:2}}> Office Listing </span>: null }
            {Number(row.is_open_listing) === Number(1) ? <span className="ml5 open_listing_span" data-user_id={`${row.user_id}`} data-prop_id={`${row.prop_id}`} style={{paddingTop:2}}> Other Listing </span> : null }
            
          </div>
      },
      {
        name: 'Agent Name',
        selector: 'agent_name',
        sortable: true,
        left: true,
        cell: row => <div tag="allowRowEvents" data-user_id={`${row.user_id}`} onClick={this.handleShowModal} className="cursorPointer"> 
              <img tag="allowRowEvents" data-user_id={`${row.user_id}`} alt='' src={row.image ? Constants.IMAGE_URL+row.image : "user-images/userDefault.png"} className="img-circle" width="24" height="24" />
              <span tag="allowRowEvents" data-user_id={`${row.user_id}`} className="color2D3E54 fontWeight600" style={{marginLeft: 5, fontFamily: "Inter", textDecoration: "underline"}}>{row.firstname} {row.lastname}</span>
            </div>
      },
      {
        name: 'Past Viewings',
        selector: 'past_appts',
        sortable: true,
        center: true
      },
      {
        name: 'Upcoming Viewings',
        selector: 'upcoming_appts',
        sortable: true,
        center: true
      },
      {
        name: 'Total Viewings',
        selector: 'schedule_appts_total',
        sortable: true,
        center: true
      },
      {
        name: 'Days For Sale',
        selector: 'days_for_sale',
        sortable: true,
        center: true
      },
    ]

    if(this.props.data){
      data =
        this.props.data.map((data, index) => (
        {
          user_id:              data.user_id,
          prop_id:              data.prop_id,
          date_sold:            data.date_sold,
          tracking:             data.tracking_prefrence,
          firstname:            data.firstname,
          lastname:             data.lastname,
          image:                data.user_image,
          agent_name:           data.firstname +" "+ data.lastname,
          address:              data.address,
          unit_name:            data.unit_name,
          property_type:        data.sale_type,
          is_office_listing:    data.is_office_listing,
          is_open_listing:      data.is_open_listing,
          property_type_text:   data.date_sold ? Number(data.rented) === 1 ? "Rented" : "Sold" :  Number(data.sale_type) === Number(1) ? "For Sale": "For Rent" ,
          networking:           (Number(data.is_office_listing) === 1 && Number(data.is_open_listing) === 1) ? "Office Listing, Other Listing" :  (Number(data.is_office_listing) === 1 && Number(data.is_open_listing) === 0) ? "Office Listing" : (Number(data.is_office_listing) === 0 && Number(data.is_open_listing) === 1) ? "Other Listing": "",
          past_appts:           data.past_appt ? Number(data.past_appt) : 0 ,
          upcoming_appts:       data.uppcoming_appt ? Number(data.uppcoming_appt) : 0,
          schedule_appts_total: data.schedule_appt ? Number(data.schedule_appt) : 0,
          days_for_sale:        data.days_for_sale ? Number(data.days_for_sale) : 0,
          rented:               data.rented,
          crm_id:               data.crm_id,
          crm_verified:         data.crm_verified,
          pro_user:             data.pro_user
        }
      ))  
    }
      
    this.filteredItems = data.filter( 
      (item) =>
        (item.agent_name && item.agent_name.toLowerCase().includes(this.state.filterText.toLowerCase())) || (item.address && item.address.toLowerCase().includes(this.state.filterText.toLowerCase())) || (item.unit_name && item.unit_name.toLowerCase().includes(this.state.filterText.toLowerCase()))
    );
    data_table_data = this.filteredItems

    let footer= "footer";

    /* Linked User Scenario  */
    var sellerLinkedMessage = null;
    sellerLinkedMessage = ( <SellerLinkedMessage property_detail={this.props.property_detail} /> );

    var agents  =  this.props.data;
    var prop_sum = 0;
    var dataSetsChart='';
    var agentId;
    if(this.props.data && this.props.property_agen_count){
      if(this.props.property_agen_count.length > 0){
        for(let a=0 ; a < this.props.data.length;a++){
          if(agents[a]){
            agentId = agents[a].user_id;
            let non_active_agent_buttons =  localStorage.getItem('non_active_agent_buttons') ? JSON.parse(localStorage.getItem('non_active_agent_buttons')) : [];
            if(non_active_agent_buttons){
              if(!non_active_agent_buttons.includes(agentId)){
                dataSetsChart = (this.props.property_agen_count[0][agentId]);
              }
            }
          }
        }
      }
    }

    var totalAgentId = [];
    
    for (let i in agents) { 
      totalAgentId.push(agents[i]['user_id']);
    } 

    if(typeof dataSetsChart!=='undefined' && this.props.property_agen_count && dataSetsChart){
      var totalAgents =   this.removeDuplicateItem(totalAgentId);
      if(totalAgents.length > 0){ 
        for(let a = 0 ; a < totalAgents.length; a++ ){
          agentId = totalAgents[a];
          if(this.props.property_agen_count.length > 0){
            let non_active_agent_buttons = localStorage.getItem('non_active_agent_buttons') ? JSON.parse(localStorage.getItem('non_active_agent_buttons')) : [];
            if(non_active_agent_buttons){
              if(!non_active_agent_buttons.includes(agentId)){
                dataSetsChart = (this.props.property_agen_count[0][agentId]);
              }
            }
          }
        }
      }  
    }
    //count for chart
    //for(let a = 0 ; a < Object.keys(this.props.property_agen_count[0]).length; a++ ){
    if(this.props.property_agen_count && this.props.agent_list){
      for(let a = 0 ; a < this.props.agent_list.length; a++ ){
        if(this.props.agent_list[a]){
          if(this.props.property_agen_count[0][this.props.agent_list[a]['userid']]){
            if(Number(this.props.agent_list[a]['tracking_prefrence']) !== 0){
              prop_sum = Number(prop_sum) + Number(this.props.property_agen_count[0][this.props.agent_list[a]['userid']].slice(-1).pop())
            }
          }
        }
      }
    } 

    //count for datatable
    let prop_count_datatable = 0
    if(this.props.data){
      this.props.data.map((data) => (   
        Number(data.tracking_prefrence) !== Number(0) ? prop_count_datatable = prop_count_datatable + 1  : null
      ))  
    }

    //Properties Filter
    let stateOptions=[
      {
        key: 1,
        text: 'All Properties',
        value: 1
      },
      {
        key: 2,
        text: 'For Sale',
        value: 2
      },
      {
        key: 3,
        text: 'For Rent',
        value: 3
      },
      {
        key: 4,
        text: 'Office Listings',
        value: 4
      },
      {
        key: 5,
        text: 'Other Listings',
        value: 5
      },
      {
        key: 6,
        text: 'Sold',
        value: 6
      },
      {
        key: 7,
        text: 'Rented',
        value: 7
      },
    ];
   

    let file_name  = "Property Data From- "+ this.changeDateFormat_DMY(this.props.page_from_date) +" To- "+ this.changeDateFormat_DMY(this.props.page_to_date);
    let sheet_name = "Report From- "+ this.changeDateFormat_DMY(this.props.page_from_date) +" To- "+this.changeDateFormat_DMY(this.props.page_to_date);

    /* Property Detail Modal With Linked Message */
    return(
      <div style={{fontSize:'14px'}}>
        <IdleTimerContainer></IdleTimerContainer>
        <Helmet>
          <title>Roundr - Properties</title>
          <link rel="icon" href="https://roundr.app/wp-content/uploads/2018/03/favicon.png" type="image/png"></link>
        </Helmet>
        <Header active="properties" />
        <div className="content-wrapper" style={{marginLeft:'0px' }}>
          {this.state.prop_agent_list_data ?
            <AgentList 
              parentCallback = {this.callbackFunction}
              agent_list={this.state.prop_agent_list_data}
              data_table_filter_by={this.state.data_table_filter_by}  
            ></AgentList>
            : null
          }
          <section className="content">
            <div className="row">
              <div className="col-xs-12 col-md-12 col-lg-12">
                {this.state.blocking ?
                  <center>
                    <img src="images/loader.svg" width="50" height="50" alt="" />
                  </center> : null
                }

                {/* Property Chart */}
                <div className="box">
                  <div className="box-header ml20">
                    <img src="images/side_prop_icon.png" width="24" height="19" alt="" />
                    <h3 className="box-title fontSize17 ml15 mt15 color0F1D30 fontWeight500 fontInter">Properties&nbsp;</h3>
                    <span>
                      <div className="dateRange mt5 displayFlex" style={{float:'right'}}>
                      {/*  <Calendar  value={this.state.calenderStartDate} dateFormat="dd-mm-yy" width={135}
                          onChange={(e) => this.setState({ calenderStartDate: e.value, renderAgentButton: 1,CalenderMinDate:e.value })}>
                        </Calendar>
                        &nbsp;
                        <Calendar minDate={this.state.CalenderMinDate} value={this.state.calenderToDate} dateFormat="dd-mm-yy"
                          onChange={this.toDateOverview}>
                        </Calendar>*/}
                        <span className="fontSize15 fontInter color8591A0 mt10 ml20" style={{ float: 'right' }}>Current Total
                          &nbsp;&nbsp;
                          {prop_sum}
                        </span>
                      </div>
                    </span> 
                  </div>

                  <hr style={{ borderTop: '1px solid #DAEEEC !important' }} />
                  <div className="box-body table-responsive agentListingTable">
                    { (this.props.data && this.props.agent_list) ?
                      this.renderPropertyChart() : null
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <div className="box">
                  <div className="box-header">

                    <div className = "row">
                      <div className = "col-md-3" style={{paddingLeft:'45px',paddingTop:'10px'}}>
                        <img src= "images/side_prop_icon.png" width="24" height="19" alt="" />
                        &nbsp;&nbsp;
                        <span className = "fontSize22 padTop5"> Properties
                          <span className="colorA8AFB8 fontSize22">
                        &nbsp; ({prop_count_datatable})
                          </span>
                        </span>
                      </div>
                      <div className = "col-md-5">
                        <div style={{float:'left'}}>
                          <div style={{marginTop: 10, display:"inline-block", paddingLeft: "70px"}}>
                            <span className="fontSize15 fontInter color0F1D30 fontWeight500">
                              Property Filter
                            </span>
                          </div>
                          <div style={{marginTop: 8, marginLeft: 12, display:"inline-block"}}>
                            <Dropdown placeholder='Select Properties' selection  options={stateOptions}  onChange={this.handleSelect} defaultValue={1}/>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 padTop10">
                        <div style={{float:"right"}}>
                          <button type="submit" className="btn btn-primary fontBold" style={{ background:'#4AA49C', borderRadius:'4px', marginRight:30}} onClick={this.handleAddProperty}>
                            <Icon className="message_icon" icon={officeBuildingMarker} height='20' width='20' />
                              Add Property
                          </button>
                          <div id="excel_report" style={{float:'right'}}>
                            <ExcelFile filename={file_name} element={<button className="btn btn-primary fontBold" style={{ background:'#4AA49C', borderRadius:'4px'}}> <Icon className="message_icon" icon={downloadFill} height='20' width='20'/> Export to Excel</button>}> 
                              <ExcelSheet data={data_table_data} name={sheet_name}>
                                <ExcelColumn label="Address" value="address"/>
                                <ExcelColumn label="Additional address info" value="unit_name"/>
                                <ExcelColumn label="Sale Type" value="property_type_text"/>
                                <ExcelColumn label="Networking" value="networking"/>
                                <ExcelColumn label="Agent Name" value={(col) => col.firstname + " " + col.lastname }/>
                                <ExcelColumn label="Tracking" value={(col) => col.tracking ? "ON" : "OFF"}/>
                                <ExcelColumn label="Past Viewings" value="past_appts"/>
                                <ExcelColumn label="Upcoming Viewings" value="upcoming_appts"/>
                                <ExcelColumn label="Total Viewings" value="schedule_appts_total"/>
                                <ExcelColumn label="Days For Sale" value="days_for_sale"/>
                              </ExcelSheet>
                            </ExcelFile>
                          </div>
                        </div>
                      </div>
                    </div> {/* End Row Here  */}
                  </div>

                  <center>
                    {this.state.isLoading ? 
                    <img src="images/loader.svg" width="50" height="50" alt="" /> :null }
                  </center>
                  <div>
                    <DataTable
                      noHeader={true}
                      highlightOnHover={true}
                      pagination={true}
                      // progressPending="true"
                      // progressComponent={<Loader />}
                      subHeader
                      subHeaderComponent={this.getSubHeaderComponent()}
                      columns={columns}
                      data={this.filteredItems}
                      customStyles={customStyles}
                      ignoreRowClick={true}
                      //defaultSortField={'agent_name'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div> { /*  Content Wrapper End */ }

        { /* Agent Information Modal */ }
        <AgentInformationModal 
          agent_id = { this.state.agent_id }
          handlePropertyModal={this.handlePropertyModal }
          agent_list={this.props.agent_list}
        />
        
        { /* Proprties Information Modal */ }
        <PropertyDetailModal
          dateWisePropertyAppointment = { this.dateWisePropertyAppointment }
          property_detail={this.props.property_detail}
          appointment_cnt={this.props.appointment_cnt}
          general_cnt_property_modal= {this.props.general_cnt_property_modal}
          feedback={this.props.feedback}
          seller_message={this.props.seller_message}
          occupant_message={this.props.occupant_message}
          agent_data={this.props.agent_data}
          sellerLinkedMessage={sellerLinkedMessage}
          agent_id = { this.state.agent_id }
          html_export={this.props.html_export}
          property_notes_html={this.props.property_notes_html}
          office_notes_html={this.props.office_notes_html}
          date_sold={this.state.date_sold}
          data_table_filter_by={this.state.data_table_filter_by}
        />
        <AddAppointment
          agent_data = {this.props.agent_data}
          agent_id = {this.state.agent_id}
        />
        <CrmVerifiedModal/>
        <CrmNotVerifiedModal/>
        <Footer footer={footer}></Footer>
      </div> 
      
    )
  }
}
  
const mapDispatchToProps = dispatch => {
  return {
    change_from_to_date: (fromDate, toDate) => dispatch(actions.change_from_to_date(fromDate, toDate)),
    get_property_list : (token, filter_by) => dispatch(actions.get_property_list(token, filter_by)),
    set_filter_by : (filter_by) => dispatch(actions.set_filter_by(filter_by)),
    get_agent_list : (month, year, token) => dispatch(actions.get_agent_list(month, year, token)),
    get_property_chart_property : (fromDate, toDate, token) => dispatch(actions.get_property_chart_property(fromDate, toDate, token)),
    get_property_list_date : (filter_by, fromDate, toDate, token) => dispatch(actions.get_property_list_date(filter_by, fromDate, toDate, token)),
    get_property_data_modal: (fromDate, toDate, prop_id, agent_id, token, date_sold, fromnetworking, property_notes_user_id, from_archiveProperty) => dispatch(actions.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold, fromnetworking, property_notes_user_id, from_archiveProperty)),
    reset_agent_modal: () => dispatch(actions.reset_agent_modal()),
    reset_property_modal: () => dispatch(actions.reset_property_modal()),
    update_add_property_modal_state: (add_property_modal_state) => dispatch(actions.update_add_property_modal_state(add_property_modal_state)),
    update_add_appointment_modal_state: (show_agents,show_property,from_property_page,from_stock_pipeline) => dispatch(actions.update_add_appointment_modal_state(show_agents,show_property,from_property_page,from_stock_pipeline)),
    //set page for calendar
    set_page: (page, reset_date_range) => dispatch(actions.set_page(page, reset_date_range)),
  };
}

const mapStateToProps = state => {
  return{
    token: state.auth.token,
    data: state.property.property_list,

    agent_list: state.property.agent_list,

    page_from_date: state.overview.page_from_date,
    page_to_date: state.overview.page_to_date,
    //property_chart
    property_labels: state.property.property_labels,
    property_agen_count: state.property.property_agen_count,
    //temp_chart_property_count : state.property.temp_chart_property_count,

    //property_modal
    property_detail: state.property_modal.property_detail,
    appointment_cnt: state.property_modal.appointment_cnt,
    general_cnt_property_modal: state.property_modal.general_cnt_property_modal,
    feedback: state.property_modal.feedback,
    seller_message: state.property_modal.seller_message,
    occupant_message: state.property_modal.occupant_message,
    html_export :state.property_modal.html_export,
    property_notes_html :state.property_modal.property_notes_html,
    office_notes_html: state.property_modal.office_notes_html,
    
    //agent_modal
    agent_data: state.agent_modal.agent_data
  };
};

//export default (Properties);
export default connect(mapStateToProps, mapDispatchToProps)(Properties);
