import React,{ Component } from "react";
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import Loader from '../Loader';
import ErrorText from '../../auth/ErrorText';
import SuccessText from '../../auth/SuccessText';
import Swal from 'sweetalert2';

import { Icon } from '@iconify/react';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';

let items = [];
const $ = require('jquery');
class AddBuyerLead extends Component{
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            hash: null,
            first_name: null,
            last_name: null,
            mobile_no: null,
            email : null,
            address:null,
            note: null,
            tag: "Buyer",
            user_id: null,
        }
        this.submitHandler = this.submitHandler.bind(this);
        this.onInputchange = this.onInputchange.bind(this);
        this.toggleTag = this.toggleTag.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    opensweetalert(){
        Swal.fire({
            icon: 'success',
            title: 'Contact added successfully ',
            showConfirmButton: true,
        })
    }

    //handle add property button
    async submitHandler(event){
        event.preventDefault(); 
        this.setState({loading: true});
        //var hash = this.props.hash ? this.props.hash : this.state.hash;
        let agent_id = this.state.user_id ? this.state.user_id : this.props.agent_id
        //await this.props.add_buyer_lead(hash, this.state.first_name, this.state.mobile_no, this.state.email, this.state.address, this.state.note);
        await this.props.add_buyer_lead(this.state.first_name, this.state.last_name, this.state.mobile_no, this.state.email, this.state.address, this.state.tag, agent_id, this.state.note)
        if(Number(this.props.add_buyer_lead_form_error) === Number(200)) {
            const token = localStorage.getItem('token');
            this.props.reset_buyer_lead();
            var page = 1
            var filter = this.props.filter
            if(this.props.agent_id){
                this.props.get_buyer_lead(token, this.props.agent_id, page, filter, this.props.active_filter, this.props.tag_filter);
            }else{
                agent_id = ""
                this.props.get_buyer_lead(token, agent_id, page, filter, this.props.active_filter, this.props.tag_filter);
            }
            this.opensweetalert();
            this.closeModal();
        }
        this.setState({loading: false});
    }
    
    //clear modal data
    closeModal(){
        $(document).find('#addBuyerLeadform').trigger('reset');
        $('select').find('option[value=""]').attr('selected','selected');
        this.setState({
            loading: false,
            hash: null,
            first_name: null,
            last_name: null,
            mobile_no: null,
            email: null,
            address: null,
            note: null,
            tag: "Buyer",
            user_id: null
        })
        this.props.reset_buyer_lead_modal();
        window.$("#addBuyerLeadModal").modal('hide');
    }

    onInputchange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }
    
    get_userAuth = async (event) => {
        var index = event.target.selectedIndex;
        //var optionElement = event.target.childNodes[index]
        //var option = optionElement.getAttribute('data-hash');
        this.setState({
            hash : this.props.agent_list[index-1].hash ? this.props.agent_list[index-1].hash : null,
            user_id : event.target.value ? event.target.value : null
        })
    }

    toggleTag(event){
        this.setState({
            tag: event.target.value,
        });
    }
    
    createSelectItems = () => {
        items = [];
        if(this.props.agent_list){
            for (let i = 0; i < this.props.agent_list.length; i++) { 
                //if(Number(this.props.agent_list[i].pro_user) === 1){
                    items.push(<option key={i} value={this.props.agent_list[i].user_id} data-hash={this.props.agent_list[i].hash}>{this.props.agent_list[i].firstname} {this.props.agent_list[i].lastname}</option>);   
                // }else{
                //     items.push(<option key={i} disabled value={this.props.agent_list[i].userid}>{this.props.agent_list[i].firstname} {this.props.agent_list[i].lastname}</option>);   
                // }
            }
            return items;
        }
    }

    render(){     
        return(
            <div>
                <div className="modal fade" data-backdrop="static" data-keyboard="false" id="addBuyerLeadModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={this.setwrapperRef} >
                    <div className="modal-dialog" role="document" ref={this.wrapperRef}>
                        <div className="modal-content modal_add_buyer_lead modalheight" style ={{borderRadius : '12px' }} >
                            <div className="modal-header" style={{ backgroundColor: "#EDF6F6"}}>
                                <h4 className="modal-title fontWeight600"> <img src="images/buyer_lead_green.png" width="20" height="20" alt="" style={{marginRight: "5px"}}/> Add New Contact</h4>
                                <div id="closeModal_add_buyer_lead" className="circle" data-dismiss="modal" onClick={this.closeModal}>
                                    <img src="images/modal-close.png " width="12" height="12" alt=""/>
                                </div> 
                            </div>
                            
                            <div className="modal-body">
                                <center>
                                {this.props.add_buyer_lead_form_error ? 
                                    this.props.add_buyer_lead_form_error === "success" ? 
                                        <SuccessText successMsg = {this.props.add_buyer_lead_form_error} /> 
                                    :
                                        <ErrorText errorMsg = {this.props.add_buyer_lead_form_error} />
                                : 
                                    null
                                }
                                <form id="addBuyerLeadform" onSubmit={this.submitHandler}>
                                    {this.props.show_agents ?
                                        /* Agent Selection */
                                        <div className="select-wrap">
                                            <label style={{float:'left'}}>Select Agent</label>
                                            {Number(this.props.add_property_modal_state) === 1 ? 
                                                <select id="agent_name" name="agent_name" ref="agent_name" disabled={true} style={{cursor:"not-allowed"}}>
                                                    <option value={this.props.agents_id} selected>{this.props.agent_data.firstname} {this.props.agent_data.lastname}</option>
                                                </select>
                                            :
                                                <select id="agent_name" name="agent_name" ref="agent_name" onChange={this.get_userAuth}>
                                                    <option value="" disabled selected>---</option>
                                                    {this.createSelectItems()}
                                                </select> 
                                            }
                                            
                                            <Icon id="dropdown_icon" className="dropdown_icon" icon={arrowIosDownwardFill} style={{color: '#27afaf', fontSize: '24px'}} />
                                        </div>
                                    : null}
                                    
                                    {/* Lead Details */}
                                    <div className="shadow" ref="myinput" style={(Number(this.props.add_property_modal_state)) === 1 ? {display: "block"} : {display: this.state.display_card} }>
                                        <p className="cardHeader">Contact Details</p>
                                        {/* <p className="cardSubheader" style={{fontWeight:500}}>Enter the lead details</p> */}
                                        <div className='form_border'>
                                            <br/>
                                            <div className='full-input' style={{borderBottom: "none"}}>
                                                <label className="label_modal">Select Contact Type (Current or future)</label>
                                                <input className="btn_contact_type" type="radio" id="forBuyer"    name="contact_type" onClick={this.toggleTag} value="Buyer" defaultChecked={true}/><label htmlFor="forBuyer" className="btn_contact_type">Buyer</label>
                                                <input className="btn_contact_type" type="radio" id="forSeller"   name="contact_type" onClick={this.toggleTag} value="Seller"/><label htmlFor="forSeller" className="btn_contact_type">Seller</label>
                                                <input className="btn_contact_type" type="radio" id="forTenant"   name="contact_type" onClick={this.toggleTag} value="Tenant"/><label htmlFor="forTenant" className="btn_contact_type">Tenant</label>
                                                <input className="btn_contact_type" type="radio" id="forLandlord" name="contact_type" onClick={this.toggleTag} value="Landlord"/><label htmlFor="forLandlord" className="btn_contact_type">Landlord</label>
                                            </div>

                                            <div className='full-input'>
                                                <label className="label_modal">First Name*</label>
                                                <input className="input_modal" type='text' name='first_name' id="first_name" placeholder="John" ref="first_name" maxLength="1024" value={this.state.first_name} onChange={this.onInputchange} required></input>
                                            </div>
                                            <div className='full-input'>
                                                <label className="label_modal">Last Name</label>
                                                <input className="input_modal" type='text' name='last_name' id="last_name" placeholder="Doe" ref="last_name" maxLength="1024" value={this.state.last_name} onChange={this.onInputchange}></input>
                                            </div>
                                            <hr style={{border: "2px solid #27AFAF"}}/>
                                            <div className='full-input'>
                                                <label className="label_modal">Phone no.*</label>
                                                <input className="input_modal" type='text' name='mobile_no' id="mobile_no" placeholder="987654321" ref="mobile_no" value={this.state.mobile_no} minLength="8" maxLength="12" onChange={this.onInputchange}></input>
                                            </div>

                                            <div className='full-input' style={{borderBottom: "none"}}>
                                                <label className="label_modal" style={{marginLeft:"42%"}}>and/or</label>
                                                <br/>
                                            </div>
                                            
                                            <div className='full-input'>
                                                <label className="label_modal">Email Address*</label>  {/* <span className="optional_modal">Optional</span> */}
                                                <input className="input_modal" type='email' name='email' id="email" placeholder="johndoe@gmail.com" ref="email" maxLength="500" value={this.state.email} pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$' onChange={this.onInputchange}></input>
                                            </div>
                                            <hr style={{border: "2px solid #27AFAF"}}/>

                                            <div className='full-input'>
                                                <label className="label_modal">Address</label>{/* <span className="optional_modal">Optional</span>*/}
                                                <input className="input_modal" type='text' name='address' id="address" placeholder="33 Warren St, Tamboerskloof" maxLength="1024" ref="property" value={this.state.address} onChange={this.onInputchange}></input>
                                            </div>

                                            <div className='full-input' style={{borderBottom: "none"}}>
                                                <label className="label_modal">Note</label>{/*<span className="optional_modal">Optional</span>*/}
                                                <input className="input_modal" type='text' name='note' id="note" placeholder="Write your note" maxLength="1024" ref="note" value={this.state.note} onChange={this.onInputchange}></input>
                                            </div>
                                        </div>
                                        <br/><br/>
                                    </div>
                                    
                                    {/* Optional info */}
                                    {/* <div className="shadow" style={{display: "block"}}>
                                        <p className="cardHeader">Optional extra info</p>
                                        <p className="cardSubheader" style={{fontWeight:500}}>Share addtional info with the agent about this lead</p>
                                        <div className='form_border'>
                                            <div className='full-input'>
                                                <label className="label_modal">Property of Interest</label><span className="optional_modal">Optional</span>
                                                <input className="input_modal" type='text' name='address' id="address" placeholder="33 Warren St, Tamboerskloof" maxLength="1024" ref="property" value={this.state.address} onChange={this.onInputchange}></input>
                                            </div>
                                            <div className='full-input' style={{borderBottom: "none"}}>
                                                <label className="label_modal">Note</label><span className="optional_modal">Optional</span>
                                                <input className="input_modal" type='text' name='note' id="note" placeholder="Write your note" maxLength="1024" ref="note" value={this.state.note} onChange={this.onInputchange}></input>
                                            </div>
                                        </div>
                                        <br/><br/><br/>
                                    </div>*/}
                                    <br/><br/>
                                    {!this.state.loading ? 
                                        this.props.add_buyer_lead_form_error !== "success"? 
                                            <div style={(Number(this.props.add_property_modal_state)) === 1 ? {display: "block"} : {display: this.state.display_card} }> 
                                                <button id="addBuyerLeadBtn" type="submit" className="btn btn-primary fontBold" style={{ background:'#4AA49C',borderRadius:'4px'}}>
                                                    <img src="images/header-component/buyer_lead_active.png" width="15" height="15" alt="" style={{marginRight: "5px"}}/>
                                                    Save Contact
                                                </button>
                                            </div>
                                        : null
                                    :
                                        <Loader/>
                                    }
                                    
                                    <br/><br/>
                                </form>
                                </center>
                            </div>
                            
                        </div> 
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        //buyer_lead
        add_buyer_lead : (first_name, last_name, mobile_no, email, address, tag, user_id, note) => dispatch(actions.add_buyer_lead(first_name, last_name, mobile_no, email, address, tag, user_id, note)),
        get_buyer_lead : (token, agent_id, page, filter, active_filter, tag_filter) => dispatch(actions.get_buyer_lead(token, agent_id, page, filter, active_filter, tag_filter)),
        reset_buyer_lead_modal: () => dispatch(actions.reset_buyer_lead_modal()),
        reset_buyer_lead : () => dispatch(actions.reset_buyer_lead()),
    }
  }
  
const mapStateToProps = state => {
    return{
        authtoken: state.add_property_modal.authtoken,
        add_buyer_lead_form_error: state.buyer_lead.add_buyer_lead_form_error,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddBuyerLead);
