import * as actionTypes from '../actions/actionsTypes'; 
import {updateObject} from '../utility';

const initialState = {
    compare_agent_list: null,
}

const get_compare_agent = (state, action) => {
    return updateObject(state,{
        compare_agent_list: action.compare_agent_list
    })
}

function compare_agents_reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.GET_COMPARE_AGENT: return get_compare_agent(state, action);
        default:
        return state;
    }
}

export default compare_agents_reducer;
