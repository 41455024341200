import React ,  { Component } from 'react';

import '../CustomFiles/bower_components/bootstrap/dist/css/bootstrap.min.css';
import '../CustomFiles/bower_components/font-awesome/css/font-awesome.min.css';
import '../CustomFiles/bower_components/Ionicons/css/ionicons.min.css';
import '../CustomFiles/dist/css/AdminLTE.min.css';
import '../CustomFiles/plugins/iCheck/square/blue.css';
class ForgotPass extends Component{
    render(){
        return(
<div>
<div className="login-box">
  <div className="login-logo">
   
    <img src = "/images/logo.png" height="35" alt="" />
       &nbsp;
        <b>Office</b>
  </div>
  <div className="login-box-body">
    <p className="login-box-msg">Enter your registered email</p>

    <form action="#" method="post">
      <div className="form-group has-feedback">
        <input type="email" className="form-control" placeholder="Email" />
        <span className="glyphicon glyphicon-envelope form-control-feedback"></span>
      </div>
     
      <div className="row">
        <div className="col-xs-8">
        </div>
        <div className="col-xs-4">
          <button type="button" className="btn btn-primary btn-block btn-flat">Send</button>
        </div>
      </div>
    </form>
    <a href="/">Already a Member</a><br />
  </div>
</div>
</div>)
    }
}
export default ForgotPass;

