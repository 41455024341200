import * as actionTypes from './actionsTypes';
import Constants from '../../constants/Constants';

var myHeaders = '';
var raw = '';
var requestOptions = '';

export const add_stock_pipeline_data = (token, user_id, full_address, lat, lng, map_address) => {
    return async dispatch =>{
        let error=[];
        if((!full_address) || (!lat) || (!lng) || (!map_address)){
            if(!full_address){
                error.msg = "Please enter the address";
                dispatch(submitFail(error));
            }else if(!map_address || !lng || !lat ){ 
                error.msg = "Please select the address from the map";
                dispatch(submitFail(error));
            }
        }else{
            myHeaders = new Headers();
            myHeaders.append("Content-Type","application/json");
            myHeaders.append("Authorization", token);
            raw = JSON.stringify(
            {
                "user_id":user_id,
                "address": full_address,
                "additional_info": null,
                "lat": lat,
                "long": lng,
                "address_google": map_address
            });
            requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            await fetch(Constants.ADD_STOCK_PIPELINE, requestOptions)
            .then(response => response.json())
            .then(data => {
                dispatch(add_stock_pipeline(data));
            })
            .catch(error => console.log('error', error));
        }
    }
}
export const add_stock_pipeline = (data)=>{
    return{
        type: actionTypes.ADD_STOCK_PIPELINE,
        add_stock_pipeline_status: data.status
    }
}

export const update_stock_pipeline_data = (token, user_id, full_address, unit_no, lat, lng, is_archived, stock_pipeline_id, map_address) => {
    return async dispatch =>{
        let error=[];
        if((!full_address) || (!lat) || (!lng) || (!map_address)){
            if(!full_address){
                error.msg = "Please enter the address";
                dispatch(submitFail(error));
            }else if(!map_address || !lng || !lat ){ 
                error.msg = "Please select the address from the map";
                dispatch(submitFail(error));
            }else if(!stock_pipeline_id){
                error.msg = "Something went wrong please try again";
                dispatch(submitFail(error));
            }
        }else{
            myHeaders = new Headers();
            myHeaders.append("Content-Type","application/json");
            myHeaders.append("Authorization", token);
            raw = JSON.stringify(
            {
                "user_id":Number(user_id),
                "address": full_address,
                "additional_info": unit_no ? unit_no : "",
                "lat": lat,
                "long": lng,
                "is_archived": Number(is_archived),
	            "stock_pipeline_id": Number(stock_pipeline_id),
                "address_google": map_address
            });
            requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            await fetch(Constants.UPDATE_STOCK_PIPELINE, requestOptions)
            .then(response => response.json())
            .then(data => {
                dispatch(update_stock_pipeline(data));
            })
            .catch(error => console.log('error', error));
        }
    }
}
export const update_stock_pipeline = (data)=>{
    return{
        type: actionTypes.UPDATE_STOCK_PIPELINE,
        update_stock_pipeline_status: data.status
    }
}

export const submitFail = (error) => {
    return {
        type: actionTypes.SUBMIT_FAIL,
        stockpipeline_form_error: error.msg
    };
}

export const reset_google_map = () =>{
    return dispatch => {
        dispatch({
            type: actionTypes.RESET_GOOGLE_MAP,
            reset_google_map: 1
        })
    }
}

export const reset_add_stockpipeline_form = () =>{
    return dispatch => {
        dispatch({
            type: actionTypes.RESET_ADD_STOCK_PIPELINE,
            add_stock_pipeline_status: null,
            update_stock_pipeline_status: null,
            edit_stockpipeline_state: 0,
            reset_google_map: 0
        })
    }
}

export const set_edit_map_address = (edit_map_address, edit_lat, edit_lng)=>{
    //console.log(edit_map_address);
    return dispatch => {
        dispatch({
            type: actionTypes.SET_EDIT_MAP_ADDRESS,
            edit_stockpipeline_state: 1,
            edit_map_address: edit_map_address, 
            edit_lat: edit_lat, 
            edit_lng: edit_lng
        })
    }
}
