import React,{ Component } from "react";
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import modal from '../../../CustomFiles/js/modal';
import Swal from 'sweetalert2';

import { Calendar } from 'primereact/calendar';
import evoCalendar from "evo-calendar"; // eslint-disable-line no-unused-vars

//import ReactTooltip from 'react-tooltip';
import { Icon } from '@iconify/react';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import bxCalendarEvent from '@iconify/icons-bx/bx-calendar-event';
import infoOutline from '@iconify/icons-dashicons/info-outline';

import ErrorText from '../../../auth/ErrorText';
import SuccessText from '../../../auth/SuccessText';

import Contactlist from './ContactList'
import PropertyList from './PropertyList'
import $ from 'jquery';
let items = [];

class AddAppointment extends Component{
    constructor(props) {
        super(props);
        this.state = {
            display_card: 'none',
            agent_id: null,
            appointment_type: 1,
            //Viewings
            selected_contact_id: null,
            viewer_name: null,
            viewer_contact_no: null,
            viewer_email_id: null,
            startDate: null,
            endDate: null,
            date: null,
            time: null,
            selected_property_id: null,
            selected_property: null,
            selected_stock_pipeline_id: null,
            selected_stockpipeline_address: null,
            
            //general
            appointment_reason: null,
            appointment_reason_length: 0,
            viewer_name_general: null,
        }
        this.myRef = React.createRef();

        this.submitAppointmentHandler = this.submitAppointmentHandler.bind(this);

        this.onInputchange = this.onInputchange.bind(this);

        this.toggleAppointmentType = this.toggleAppointmentType.bind(this);
        this.handleContactList = this.handleContactList.bind(this);
        this.changeDateFormat = this.changeDateFormat.bind(this);
        this.closeModal_Appointment = this.closeModal_Appointment.bind(this);
        this.clearform = this.clearform.bind(this);
        this.SetAgentSelect = this.SetAgentSelect.bind(this);

        this.wrapperRef = React.createRef();
        this.setwrapperRef = React.createRef();
    }
    
    appointmentSuccess_sweetalert(){
        Swal.fire({
            icon: 'success',
            title: 'Appointment added successfully ',
            showConfirmButton: true,
        })
    }
    appointmentError_sweetalert(){
        Swal.fire({
            icon: 'error',
            title: 'Something went wrong',
            showConfirmButton: true,
        })
    }
    appointmentLimitReached_sweetalert(){
        Swal.fire({
            icon: 'warning',
            title: "Limit Reached.",
            html:
            "You have reached the limit for Roundr's free option." +
            "Contact us at <a href='mailto:hello@roundr.app'> hello@roundr.app </a>" +
            "to upgrade or to manage your subscription.",
            showConfirmButton: true,
        })
    }
    

    onInputchange(event) {
        this.setState({
            [event.target.name]: event.target.value,
            selected_contact_id: null,
            viewer_contact_no: null,
            viewer_email_id: null
        });
        if(event.target.name === "appointment_reason"){
            let len = event.target.value;
            len = len.length;
            this.setState({appointment_reason_length: len})
        }
    }

    //handle radio buttons toggle
    toggleAppointmentType = (event) =>{
        this.clearform();
        this.setState({
            appointment_type: event.target.value,
        });
    }
    clearform(){
        if(Number(this.state.appointment_type) === Number(1)){
            this.setState({
                //general
                appointment_reason: null,
                appointment_reason_length: 0,
                viewer_name_general: null,
                date: null,
                time: null,
                selected_property_id: null,
                selected_property: null,
                selected_stock_pipeline_id: null,
                selected_stockpipeline_address: null,
            },()=>{
                this.callContactList();
                this.callPropertyList();
                //general
                window.$("textarea#appointment_reason").val("");
                window.$("#viewer_name_general").val("");
                window.$("#select_date").find(".p-inputtext").val("");
                window.$("#select_time").find(".p-inputtext").val("");
                window.$("#selected_property").val("");
            })
        }else{
            this.setState({
                selected_contact_id: null,
                viewer_name: null,
                viewer_contact_no: null,
                viewer_email_id: null,
                date: null,
                time: null,
                selected_property_id: null,
                selected_property: null,
                selected_stock_pipeline_id: null,
                selected_stockpipeline_address: null,
            },()=>{
                this.callContactList();
                this.callPropertyList();
                //veiwing
                window.$("#viewer_name").val("");
                window.$("#select_date").find(".p-inputtext").val("");
                window.$("#select_time").find(".p-inputtext").val("");
                window.$("#selected_property").val("");
            })
        }
        return
    }
     
    //handle add property button
    async submitAppointmentHandler(event){
        event.preventDefault(); 
        let authtoken = this.props.authtoken;
        if(Number(this.props.from_stock_pipeline) === Number(0)){
            let property_id ='';
            if(Number(this.state.appointment_type) === Number(1)){// for viewing appointment
                let appointment_type_id = "1";
                let buyer_email = this.state.viewer_email_id;
                let roundr_contact_id = this.state.selected_contact_id;
                let confirm_for_seller = '0';
                let buyer_cell = this.state.viewer_contact_no;
                let appointment_date = (this.state.date && this.state.time) ? this.state.date+" "+ this.state.time.getHours()+":"+this.state.time.getMinutes() : null;
                let buyer_name = this.state.viewer_name;
                let listing_type = "";
                let contact_id = "";
                property_id = this.state.selected_property_id ? this.state.selected_property_id : this.props.property_detail ? this.props.property_detail.id : null;
                
                await this.props.create_viewing_appointment(authtoken, this.props.timezone, appointment_type_id, buyer_email, roundr_contact_id, confirm_for_seller, buyer_cell, appointment_date, buyer_name, listing_type, contact_id, property_id);
                // if(Number(this.props.add_appointment_success) === Number(1)){
                //     this.appointmentSuccess_sweetalert();
                //     this.closeModal_Appointment();
                //     window.$("#addAppointmentModal").modal('hide');
                // }else if(Number(this.props.add_appointment_success) === Number(0) && this.props.add_appointment_success !== null ){
                //     this.appointmentError_sweetalert();
                // }
            }else if(Number(this.state.appointment_type) === Number(0)){// for general appointment
                let reason = this.state.appointment_reason;
                let my_stock_pipeline_id = this.state.selected_stock_pipeline_id ? this.state.selected_stock_pipeline_id : null;
                let appointment_date = (this.state.date && this.state.time) ? this.state.date+" "+ this.state.time.getHours()+":"+this.state.time.getMinutes() : null;
                let meeting_with = this.state.viewer_name_general;
                property_id = this.state.selected_property_id ? this.state.selected_property_id : this.props.property_detail ? this.props.property_detail.id : null;
                let audio_data = null;
                let address = this.state.selected_property ? this.state.selected_property : this.state.selected_stockpipeline_address ? this.state.selected_stockpipeline_address : this.props.property_detail ? this.props.property_detail.address : null;
                
                //console.log("General Appointment"+reason, appointment_date, meeting_with, property_id, address, my_stock_pipeline_id)
                await this.props.create_general_appointment(authtoken, this.props.timezone, reason, my_stock_pipeline_id, appointment_date, meeting_with, property_id, audio_data, address);
            }
            
            //refreshing modal and page data 
            if(Number(this.props.add_appointment_success) === Number(1)){
                this.appointmentSuccess_sweetalert();
                const token = localStorage.getItem('token')
                let agent_id = window.$('#appointment_agent_name').find(":selected").val();
                var d = new Date();
                var fromDate = this.changeDateFormat(new Date(d.getFullYear(), d.getMonth() - 2, 1));
                var toDate = this.changeDateFormat(new Date(d.getFullYear(), d.getMonth(), d.getDate()));
                if((Number(this.props.show_agents) === 1) || (Number(this.props.from_property_page) === Number(1) && this.props.property_detail)){ 
                    if(Number(this.props.from_property_page) === Number(1)){
                        let viewings_sort = null;
                        let recent_property = 'desc';
                        let date_sold = '';
                        this.props.set_page("property_modal", true);
                        this.props.get_property_data(fromDate, toDate, agent_id, token, viewings_sort, recent_property);
                        this.props.get_property_data_modal(fromDate, toDate, property_id, agent_id, token, date_sold);
                    }else{
                        window.$("#appt_link").trigger("click")
                        this.props.set_page("appts_tab", true);

                        // var today = new Date();
                        // var dd = today.getDate();
                        // var mm = today.getMonth()+1; 
                        // var yyyy = today.getFullYear();
                        // var todayDate = this.changeDateFormat(yyyy+'-'+mm+'-'+dd)
                        
                        var appointmentFromDate = this.changeDateFormat(new Date(d.getFullYear(), (d.getMonth() - 2), 1));
                        var appointmentToDate = this.changeDateFormat(new Date(d.getFullYear(), d.getMonth(), d.getDate()));
                        this.setState({appointmentToDate: appointmentToDate})
                        //this.props.get_appointment_data_modal(appointmentFromDate, appointmentToDate, todayDate, agent_id, token);
                        this.props.get_general_chart_agent_modal(appointmentFromDate, appointmentToDate, agent_id, token);
                        //reseting agent modal appointment calendar
                        this.reset_appointment_calendar(agent_id);
                    }
                }else{ //appointment page reload                    
                    let agent_list_data = [...JSON.parse(localStorage.getItem('data'))]
                    this.props.get_filtered_agent_list_appointment(fromDate, toDate, token);
                    this.props.get_appointment_chart_appointment(fromDate, toDate, agent_list_data, token);
                    this.props.get_general_chart_appointment(fromDate, toDate, agent_list_data, token);
                    this.props.set_page("viewings_page", true);
                }
                
                this.closeModal_Appointment();
                window.$("#addAppointmentModal").modal('hide');
            }else if(Number(this.props.add_appointment_success) === Number(0)){
                this.appointmentError_sweetalert();
            }else if(Number(this.props.add_appointment_success) === Number(2)){
                this.appointmentLimitReached_sweetalert();
            }

        }else{ // for stock pipeline modal
            let reason = this.state.appointment_reason;
            let my_stock_pipeline_id = this.props.stock_pipeline_property_data ? this.props.stock_pipeline_property_data[0].id : null;
            let appointment_date = (this.state.date && this.state.time) ? this.state.date+" "+ this.state.time.getHours()+":"+this.state.time.getMinutes() : null;
            let meeting_with = this.state.viewer_name_general;
            let property_id = null;
            let audio_data = null;
            let address = this.props.stock_pipeline_property_data ? this.props.stock_pipeline_property_data[0].address : null;
            
            //console.log("Stock pipeline Appointment "+reason, appointment_date, meeting_with, property_id, address, my_stock_pipeline_id)
            await this.props.create_general_appointment(authtoken, this.props.timezone, reason, my_stock_pipeline_id, appointment_date, meeting_with, property_id, audio_data, address);
            if(Number(this.props.add_appointment_success) === Number(1)){
                this.appointmentSuccess_sweetalert();
                this.renderStockpipelineData(my_stock_pipeline_id)
                this.closeModal_Appointment();
                window.$("#addAppointmentModal").modal('hide');
            }else if(Number(this.props.add_appointment_success) === Number(0)){
                this.appointmentError_sweetalert();
            }else if(Number(this.props.add_appointment_success) === Number(2)){
                this.appointmentLimitReached_sweetalert();
            }
        }
    }

    reset_appointment_calendar = async(agent_id) => {
        const token = localStorage.getItem('token');
        if($("#eventListToggler").hasClass("clicked")){
            $("#eventListToggler").click();
        }
        $(".calendar-months").find('.active-month').removeClass('.active-month')
        $(".calendar-months").find("[data-month-val='3']"); 
        //$(".calendar-months").find("[data-month-val='" + current + "']"); 
        //delete old events
        if(this.props.deleteEvents && this.props.deleteEvents.length > 0 ){
            $('#evoCalendar').evoCalendar('removeCalendarEvent', this.props.deleteEvents);
        }
        this.props.reset_calendar_data();
        window.$("#appt_link").trigger("click")
        this.props.set_page("appts_tab", true);
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth()+1; 
        var yyyy = today.getFullYear();
        var todayDate = this.changeDateFormat(yyyy+'-'+mm+'-'+dd)
        
        var appointmentFromDate = this.changeDateFormat(new Date(today.getFullYear(), (today.getMonth() - 2), 1));
        var appointmentToDate = this.changeDateFormat(new Date(today.getFullYear(), today.getMonth(), today.getDate()));
        this.setState({appointmentToDate: appointmentToDate})
        await this.props.get_appointment_data_modal(appointmentFromDate, appointmentToDate, todayDate, agent_id, token);

        var appt_data = JSON.stringify(this.props.date_wise_appt);
        appt_data = JSON.parse(appt_data);
        
        $('#evoCalendar').evoCalendar('addCalendarEvent', appt_data);
    }
    
    renderStockpipelineData = async (my_stock_pipeline_id) =>{
        await this.props.reset_stockpipeline_data()
        this.setState({
            startDate: new Date(new Date().getFullYear(), 0, 1),
            endDate:   new Date(new Date()),
            CalenderMinDate: new Date(new Date().getFullYear(), 0, 1),
        })
        let token = localStorage.getItem('token');
        let agent_id = this.props.agent_id;
        await this.props.get_auth_data(agent_id,token);
        var page = 1;
        let is_archived='0'
        let stock_pipeline_id = null
        await this.props.get_stock_pipeline_data(page, is_archived, stock_pipeline_id, this.props.authtoken);
        var date = new Date();
        var startDate = this.changeDateFormat(new Date(date.getFullYear(), date.getMonth() - 2, 1));
        var endDate = this.changeDateFormat(new Date(date.getFullYear(), date.getMonth(), date.getDate()));
        this.props.set_page("stockpipeline_tab", true);
        this.props.get_stockpipeline_chart_by_id(startDate, endDate, this.props.agent_id, token);
        
        //render stockpipeline detail
        stock_pipeline_id = my_stock_pipeline_id;
        await this.props.get_stock_pipeline_property_data(stock_pipeline_id, agent_id, token)
    }

    //clear modal data
    closeModal_Appointment = async () =>{
        this.setState({
            display_card: 'none',
            appointment_type: 1,
            //viewing
            selected_contact_id: null,
            viewer_name: null,
            viewer_contact_no: null,
            viewer_email_id: null,
            date: null,
            time: null,
            startDate: null,
            endDate: null,
            selected_property_id: null,
            selected_property: null,
            selected_stock_pipeline_id: null,
            selected_stockpipeline_address: null,
            
            //general
            appointment_reason: "",
            appointment_reason_length: 0,
            viewer_name_general: null,
        }
        ,() => {  
                this.callContactList();
                this.callPropertyList();
                window.$("#forViewing").click();
                window.$("#select_date").find(".p-inputtext").val("");
                window.$("#select_time").find(".p-inputtext").val("");
                window.$("#selected_property").val("");
                //viewing
                window.$("#viewer_name").val("");
                //general
                window.$("#viewer_name_general").val("");
                window.$("textarea#appointment_reason").val("");
                this.props.reset_appointment_modal_error();
                this.props.reset_property_modal_error();
            }
        );
    }
    
    componentDidMount() {
        if(window.location.pathname === "/viewings"){
            this.props.setClick(this.SetAgentSelect);
        }
        modal();
    }
    
    get_userAuth = async (event) => {
        var token = localStorage.getItem('token');
        if(token && event.target.value){
            this.setState({display_card: "none"})
            await this.props.get_auth_data(event.target.value,token);
            this.props.get_all_agent_data_dd(this.props.authtoken)
            this.setState({display_card: "block"})
        }
    }
    
    createSelectItems = () => {
        items = [];
        if(this.props.agent_list){
            for (let i = 0; i < this.props.agent_list.length; i++) { 
                if(Number(this.props.agent_id) === Number(this.props.agent_list[i].user_id)){
                    items.push(<option key={i} selected value={this.props.agent_list[i].user_id}>{this.props.agent_list[i].firstname} {this.props.agent_list[i].lastname}</option>);   
                }else{
                    items.push(<option key={i} value={this.props.agent_list[i].user_id}>{this.props.agent_list[i].firstname} {this.props.agent_list[i].lastname}</option>);   
                }
            }
            return items;
        }
    }

    appointmentHelpModal(){
        window.$("#appointmentHelpModal").modal('show');
    }
    openHelpModal(){
        window.$("#openHelpModal").modal('show');
    }
    
    callbackFunction = (selected_contact_id, selected_contact_name, selected_contact_mobile_no, selected_contact_email_id) => {
        this.setState({
            selected_contact_id: selected_contact_id,
            viewer_name: selected_contact_name,
            viewer_contact_no: selected_contact_mobile_no,
            viewer_email_id: selected_contact_email_id
        },()=>{
            window.$("#viewer_name").val(selected_contact_name);
        })
    }

    propertyCallback = (selected_property_id, selected_property, selected_stock_pipeline_id, selected_stockpipeline_address) =>{
        this.setState({
            selected_property_id: selected_property_id,
            selected_property: selected_property,
            selected_stock_pipeline_id: selected_stock_pipeline_id,
            selected_stockpipeline_address: selected_stockpipeline_address
        })
    }
    
    changeDateFormat(fromDate) {
        var d = new Date(fromDate),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [year, month, day].join('-');
    }

    getCalendarData = (e) => {
        this.setState({date: this.changeDateFormat(e.value)})
    }

    setTime = (e) => {
        this.setState({time: e.value})
        //console.log(e.value.toLocaleTimeString('it-IT'))
    }
    
    SetAgentSelect() {
        window.$("#appointment_agent_name").val(window.$("#appointment_agent_name option:first").val());
    }
    
    handleContactList = async () =>{
        const token = localStorage.getItem('token');
        let agent_id = window.$('#appointment_agent_name').find(":selected").val();
        let page = 1;
        let filter = null;
        let active_filter = "both";
        let tag_filter = "all"; 
        await this.props.get_buyer_lead(token, agent_id, page, filter, active_filter, tag_filter)
        
        window.$("#contactListModal").modal('show');
    }
    
    handleProperty = async () =>{
        await this.props.reset_stockpipeline_data()
        let d = new Date();
        let fromDate = this.changeDateFormat(new Date(d.getFullYear(), d.getMonth() - 2, 1));
        let toDate = this.changeDateFormat(new Date(d.getFullYear(), d.getMonth(), d.getDate()));
        let agent_id = window.$('#appointment_agent_name').find(":selected").val();
        const token = localStorage.getItem('token');
        let viewings_sort = null;
        let recent_property = 'desc';
        await this.props.get_property_data(fromDate, toDate, agent_id, token, viewings_sort, recent_property)
        if(Number(this.state.appointment_type) === Number(0)){
            //get stock pipeline property
            await this.props.get_auth_data(agent_id,token);
            let page = 1;
            let is_archived='0'
            let stock_pipeline_id = null
            await this.props.get_stock_pipeline_data(page, is_archived, stock_pipeline_id, this.props.authtoken);
        }
        window.$("#propertyListModal").modal('show');
    }

    render(){
        if(this.state.edit_address_1 === null && this.props.address){
            let len = this.props.address;
            len = len.length;
            this.setState({edit_full_address_length: len});
            this.setState({edit_address_1: this.props.address},() => {
                window.$("#edit_address_1").val(this.props.address)
            });
        }
     
        return(
            <div>
                <div className="modal fade" data-backdrop="static" data-keyboard="false" id="addAppointmentModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={this.setwrapperRef} >
                    <div className="modal-dialog" role="document" ref={this.wrapperRef}>
                        <div className="modal-content modal_add_appointment modalheight" style ={{borderRadius : '12px' }} >
                            <div className="modal-header" style={{ backgroundColor: "#EDF6F6"}}>
                                <h4 className="modal-title fontWeight600"> <Icon className="message_icon color27AFAF mr5" style={{verticalAlign: 'bottom',}} icon={bxCalendarEvent} height='25' width='25' /> Add Appointment</h4>
                                <div id="closeModal_add_appointment" className="circle" data-dismiss="modal" onClick={this.closeModal_Appointment}>
                                    <img src="images/modal-close.png " width="12" height="12" alt=""/>
                                </div> 
                            </div>
                            
                            {this.props.appointment_form_error ? 
                                this.props.appointment_form_error === "success" ? 
                                    <SuccessText successMsg = {this.props.appointment_form_error} /> 
                                :
                                    <ErrorText errorMsg = {this.props.appointment_form_error} />
                            : 
                                null
                            }
                            {
                                //add appointment form
                                <div className="modal-body">
                                    <center>
                                    <form id="addAppointmentform" onSubmit={this.submitAppointmentHandler}>
                                        {/* Agent Selection */}
                                        {Number(this.props.from_stock_pipeline) === Number(0) ?
                                            <div className="select-wrap">
                                                <label style={{float:'left'}}>Select Agent</label>

                                                {(Number(this.props.show_agents) === 1) || (Number(this.props.from_property_page) === Number(1) && this.props.property_detail) ? 
                                                    <>
                                                    {Number(this.props.from_property_page) === Number(1) && (Object.keys(this.props.property_agent_data).length) > 0 ?
                                                        //from property modal
                                                        <select id="appointment_agent_name" name="agent_name" ref="agent_name" disabled={true} style={{cursor:"not-allowed"}}>
                                                            <option value={this.props.property_agent_data.user_id} selected>{this.props.property_agent_data.firstname} {this.props.property_agent_data.lastname}</option>
                                                        </select>
                                                    :
                                                        <select id="appointment_agent_name" name="agent_name" ref="agent_name" disabled={true} style={{cursor:"not-allowed"}}>
                                                            <option value={this.props.agent_data.user_id} selected>{this.props.agent_data.firstname} {this.props.agent_data.lastname}</option>
                                                        </select>
                                                    }
                                                    </>
                                                :
                                                    <select id="appointment_agent_name" name="agent_name" ref="agent_name" onChange={this.get_userAuth}>
                                                        <option value="" disabled selected>---</option>
                                                        {this.createSelectItems()}
                                                    </select> 
                                                }
                                                <Icon id="dropdown_icon" className="dropdown_icon" icon={arrowIosDownwardFill} style={{color: '#27afaf', fontSize: '24px'}} />
                                            </div>
                                        : null}

                                        {Number(this.props.from_stock_pipeline) === Number(0) ?
                                        <>
                                            {/*Appointment type  */}
                                            <div className="shadow" style={this.props.authtoken !== null ? {display: "block"} : {display: this.state.display_card} }>
                                            {/* <div className="shadow"> */}
                                                <br/>
                                                {Number(this.props.from_stock_pipeline) === Number(0) ?
                                                    <div>
                                                        <input className="btn_sale_type" type="radio" id="forViewing" name="appointment_type" onClick={this.toggleAppointmentType} value="1" defaultChecked={true}/><label htmlFor="forViewing" className="btn_sale_type">Viewing</label>
                                                        <input className="btn_sale_type" type="radio" id="forGeneral" name="appointment_type" onClick={this.toggleAppointmentType} value="0"/><label htmlFor="forGeneral" className="btn_sale_type">General</label>
                                                        <Icon icon={infoOutline} style={{color: '#27afaf', fontSize: '24px', verticalAlign: 'middle', marginLeft:"20px", cursor: "pointer" }} onClick={this.appointmentHelpModal}/>
                                                    </div>
                                                : null}
                                                
                                                {/* Viewing Appointment */}
                                                <div style={Number(this.state.appointment_type) === Number(1)? {display: "block"}:{display:"none"}}>
                                                    <p className="cardHeader" style={{marginBottom:"15px"}}>Who is viewing the property?</p>
                                                    <button type="button" className="btn-link" style={{fontSize: '20px', color:"#27AFAF", marginBottom:"5px"}} onClick={this.handleContactList}>Select from Contacts</button>
                                                    <p style={{fontSize: '20px', color:"#738196", marginBottom:"5px"}}>or</p>
                                                    <p style={{fontSize: '20px', color:"#738196", marginBottom:"5px"}}>Simply type the viewers name here</p>
                                                    <div className='form_border'>
                                                        <div className='full-input' style={{borderBottom: "none"}}>
                                                            <label className="label_modal" style={{width:'100%', textAlign:'left'}}>Viewer's Name</label>
                                                            <input className="input_modal fontWeight800" type='text' name='viewer_name' id="viewer_name" placeholder="John Doe" ref="viewer_name" value={this.state.viewer_name} onChange={this.onInputchange}></input>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* General Appointment */}
                                                <div style={Number(this.state.appointment_type) === Number(0)? {display: "block"}:{display:"none"}}>
                                                    <p className="cardHeader" style={{marginBottom:"15px"}} maxLength="50">What is the reason for this appointment?</p>
                                                    <div className='form_border'>
                                                        <div className='full-input' style={{borderBottom: "none"}}>
                                                            <label className="label_modal" style={{width:'100%', textAlign:'left'}}>Reason/Note for appointment</label>
                                                            <textarea className="input_modal fontWeight800" style={{resize: 'none'}} rows="4" cols="50" name='appointment_reason' id="appointment_reason" placeholder="Reason/Note For Appointment" ref="appointment_reason" maxLength="500" onChange={this.onInputchange}>
                                                                {this.state.appointment_reason}
                                                            </textarea>
                                                            <p className={this.state.appointment_reason_length >= 470 ? "colorEA676A": null} style={{textAlign:"right"}}> {this.state.appointment_reason_length}/500</p>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <div className='form_border'>
                                                        <div className='full-input' style={{borderBottom: "none"}}>
                                                            <label className="label_modal" style={{width:'100%', textAlign:'left'}}>Who are you meeting with? <i>(optional)</i></label>
                                                            <input className="input_modal fontWeight800" type='text' name='viewer_name_general' id="viewer_name_general" placeholder="John Doe" ref="viewer_name_general" value={this.state.viewer_name_general} onChange={this.onInputchange}></input>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/><br/><br/>
                                            </div>

                                            {/* Date Time */}
                                            <div className="shadow" style={this.props.authtoken !== null ? {display: "block"} : {display: this.state.display_card} }>
                                            {/* <div className="shadow"> */}
                                                <p className="cardHeader">When would they like to view?</p>
                                                <div className="row">
                                                    <div className="col-md-4 col-md-offset-1">
                                                        <input type="text" className="from_label" value="Select Date:" style={{width:"auto", left:"40px"}} disabled/>
                                                        <Calendar value={this.state.startDate} /*maxDate={this.state.endDate}*/ dateFormat="dd-mm-yy" name="select_date" id="select_date" style={{textAlign: 'left'}}
                                                            onChange={(e) => {this.setState({ startDate: e.value }); this.getCalendarData(e)}}>
                                                        </Calendar>
                                                    </div>
                                                    <div className="col-offset-md-1 col-md-4">
                                                        <input type="text" className="from_label" value="Select Time:" style={{width:"auto", left:"40px"}} disabled/>
                                                        <Calendar timeOnly showTime hourFormat="12" value={this.state.time} onChange={(e) => this.setTime(e)} id="select_time"></Calendar>
                                                    </div>
                                                </div>
                                                <br/>

                                                {Number(this.props.show_property_list) === Number(1) && Number(this.props.from_stock_pipeline) === Number(0) ?
                                                    <div className='form_border'>
                                                        <div className='full-input' style={{borderBottom: "none"}}>
                                                            <label className="label_modal" style={{width:'100%', textAlign:'left'}}>Select Property {Number(this.state.appointment_type) === 0 ? <i>(optional)</i> : null}</label>
                                                            <input readOnly className="input_modal fontWeight800" style={{cursor:"pointer"}} type='text' name='selected_property' id="selected_property" placeholder="John Doe" ref="selected_property" value={this.state.selected_property ? this.state.selected_property : this.state.selected_stockpipeline_address ? this.state.selected_stockpipeline_address : null} onClick={this.handleProperty} ></input>
                                                        </div>
                                                    </div>
                                                :
                                                    null
                                                }
                                                
                                                <br/><br/>
                                            </div>
                                            <br/><br/>
                                        </>
                                        :
                                        null}

                                        {Number(this.props.from_stock_pipeline) === Number(1) ?
                                        <>
                                            <div className="shadow">
                                                {/* Stock pipeline Appointment */}
                                                <div>
                                                    <p className="cardHeader" style={{marginBottom:"15px"}} maxLength="50">What is the reason for this appointment?</p>
                                                    <div className='form_border'>
                                                        <div className='full-input' style={{borderBottom: "none"}}>
                                                            <label className="label_modal" style={{width:'100%', textAlign:'left'}}>Reason/Note for appointment</label>
                                                            <textarea className="input_modal fontWeight800" style={{resize: 'none'}} rows="4" cols="50" name='appointment_reason' id="appointment_reason" placeholder="Reason/Note For Appointment" ref="appointment_reason" maxLength="500" onChange={this.onInputchange}>
                                                                {this.state.appointment_reason}
                                                            </textarea>
                                                            <p className={this.state.appointment_reason_length >= 470 ? "colorEA676A": null} style={{textAlign:"right"}}> {this.state.appointment_reason_length}/500</p>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <div className='form_border'>
                                                        <div className='full-input' style={{borderBottom: "none"}}>
                                                            <label className="label_modal" style={{width:'100%', textAlign:'left'}}>Who are you meeting with? <i>(optional)</i></label>
                                                            <input className="input_modal fontWeight800" type='text' name='viewer_name_general' id="viewer_name_general" placeholder="John Doe" ref="viewer_name_general" value={this.state.viewer_name_general} onChange={this.onInputchange}></input>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/><br/><br/>
                                            </div>
                                            
                                            <div className="shadow">
                                                <p className="cardHeader">When would they like to view?</p>
                                                <div className="row">
                                                    <div className="col-md-4 col-md-offset-1">
                                                        <input type="text" className="from_label" value="Select Date:" style={{width:"auto", left:"40px"}} disabled/>
                                                        <Calendar value={this.state.startDate} maxDate={this.state.endDate} dateFormat="dd-mm-yy" name="select_date" id="select_date" style={{textAlign: 'left'}}
                                                            onChange={(e) => {this.setState({ startDate: e.value, renderAgentButton: 1, CalenderMinDate:e.value }); this.getCalendarData(e)} }>
                                                        </Calendar>
                                                    </div>
                                                    <div className="col-offset-md-1 col-md-4">
                                                        <input type="text" className="from_label" value="Select Time:" style={{width:"auto", left:"40px"}} disabled/>
                                                        <Calendar timeOnly showTime hourFormat="12" value={this.state.time} onChange={(e) => this.setTime(e)} id="select_time"></Calendar>
                                                    </div>
                                                </div>
                                                <br/>
                                            </div><br/><br/><br/>
                                            </>
                                        :
                                            null
                                        }
                                        <div style={this.props.authtoken !== null ? {display: "block"} : {display: this.state.display_card} }> 
                                        {/* <div>  */}
                                            <button id="addAppointmentBtn" type="submit" className="btn btn-primary fontBold" style={{ background:'#4AA49C',borderRadius:'4px'}}>
                                                <Icon className="message_icon mr5" style={{verticalAlign: 'bottom'}} icon={bxCalendarEvent} height='20' width='20' />
                                                Add Appointment
                                            </button>
                                        </div>
                                        <br/>
                                    </form>
                                    </center>
                                </div> 
                            }
                        </div> 
                    </div>
                </div> 
                
                <div className="modal fade" data-backdrop="static" data-keyboard="false" id="appointmentHelpModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={this.setwrapperRef} >
                    <div className="modal-dialog" role="document" ref={this.wrapperRef}>
                        <div className="modal-content modal_add_appointment modalheight" style ={{borderRadius : '12px' }} >
                            <div className="help_modal_header"> <h2>Appointment Type</h2></div>
                            <hr/>
                            <div className="help_modal_text">
                                <p style={{color:"#738196", marginBottom: "5px"}}><b>Viewing Appointment</b></p>
                                <p>Select this option if your appointment is a property viewing.</p>
                                <p style={{color:"#738196", marginBottom: "5px"}}><b>General Appointment</b></p>
                                <p>Select this option for any other type of appointment.</p>
                            </div>
                            <hr/>
                            <div data-dismiss="modal" style={{textAlign: "center"}}>
                                <button className="btn btn-primary fontBold" style={{ background:'#4AA49C',borderRadius:'4px', padding:'15px 30px 15px 30px'}}>
                                    Dismiss
                                </button>
                            </div> 
                            <br/>
                        </div>
                    </div>
                </div>

                <Contactlist
                    agent_id = {window.$('#appointment_agent_name').find(":selected").val()}
                    parentCallback = {this.callbackFunction}
                    resetContactSelect={click => this.callContactList = click}
                />

                <PropertyList
                    propertyCallback = {this.propertyCallback}
                    resetPropertySelect={click => this.callPropertyList = click}
                />
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        //add appointment
        get_auth_data : (agent_id,token) => dispatch(actions.get_auth_data(agent_id,token)),
        reset_property_modal_error: () => dispatch(actions.reset_property_modal_error()),
         
        //get all agent data
        get_all_agent_data_dd: (token)=> dispatch(actions.get_all_agent_data_dd(token)),
        
        //get property list
        get_property_data: (fromDate, toDate, agent_id, token, viewings_sort, recent_property) => dispatch(actions.get_property_data(fromDate, toDate, agent_id, token, viewings_sort, recent_property)),
        get_stock_pipeline_data: (page, is_archived, stock_pipeline_id, authtoken) => dispatch(actions.get_stock_pipeline_data(page, is_archived, stock_pipeline_id, authtoken)),
        reset_stockpipeline_data:() => dispatch(actions.reset_stockpipeline_data()),
        get_property_data_modal: (fromDate, toDate, prop_id, agent_id, token, date_sold, fromnetworking, property_notes_user_id) => dispatch(actions.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold, fromnetworking, property_notes_user_id)),
        
        //get contact list
        get_buyer_lead : (token, agent_id, page, filter, active_filter, tag_filter) => dispatch(actions.get_buyer_lead(token, agent_id, page, filter, active_filter, tag_filter)),
        reset_appointment_modal_error: () => dispatch(actions.reset_appointment_modal_error()),
        
        //set page for calendar
        set_page: (page, reset_date_range) => dispatch(actions.set_page(page, reset_date_range)),

        //get appointment modal details
        get_appointment_data_modal : (appointmentFromDate, appointmentToDate, todayDate, agent_id, token) => dispatch(actions.get_appointment_data_modal(appointmentFromDate, appointmentToDate, todayDate, agent_id, token)),
        get_general_chart_agent_modal: (appointmentFromDate, appointmentToDate, agent_id, token) => dispatch(actions.get_general_chart_agent_modal(appointmentFromDate, appointmentToDate, agent_id, token)),

        //get appointment page data
        get_appointment_chart_appointment: (fromDate, toDate, agent_list, token) => dispatch(actions.get_appointment_chart_appointment(fromDate, toDate, agent_list, token)),
        get_general_chart_appointment: (fromDate, toDate, agent_list, token) => dispatch(actions.get_general_chart_appointment(fromDate, toDate, agent_list, token)),
        get_filtered_agent_list_appointment: (fromDate, toDate, token) => dispatch(actions.get_filtered_agent_list_appointment(fromDate, toDate, token)),

        //get stockpipeline data
        get_stock_pipeline_property_data : (stock_pipeline_id, agent_id, token) => dispatch(actions.get_stock_pipeline_property_data(stock_pipeline_id, agent_id, token)),
        get_stockpipeline_chart_by_id : (fromDate, toDate, agent_id, token) => dispatch(actions.get_stockpipeline_chart_by_id(fromDate, toDate, agent_id, token)),
        
        //get stockpipeline details

        //create viewing appointment
        create_viewing_appointment: (authtoken, timezone, appointment_type_id, buyer_email, roundr_contact_id, confirm_for_seller, buyer_cell, appointment_date, buyer_name, listing_type, contact_id, property_id) => dispatch(actions.create_viewing_appointment(authtoken, timezone, appointment_type_id, buyer_email, roundr_contact_id, confirm_for_seller, buyer_cell, appointment_date, buyer_name, listing_type, contact_id, property_id)),
        
        //create general appointment
        create_general_appointment: (authtoken, timezone, reason, my_stock_pipeline_id, appointment_date, meeting_with, property_id, audio_data, address) => dispatch(actions.create_general_appointment(authtoken, timezone, reason, my_stock_pipeline_id, appointment_date, meeting_with, property_id, audio_data, address)),
        
        //reset Calendar data for agent modal appointment
        reset_calendar_data : () => dispatch(actions.reset_calendar_data()),
    }
  }
  
const mapStateToProps = state => {
    return{
        authtoken: state.add_property_modal.authtoken,
        agent_data: state.agent_modal.agent_data,
        timezone: state.add_property_modal.timezone,

        show_agents: state.add_appointment_modal.show_agents,
        show_property_list: state.add_appointment_modal.show_property,
        from_property_page: state.add_appointment_modal.from_property_page,
        from_stock_pipeline: state.add_appointment_modal.from_stock_pipeline,
        
        agent_list: state.appointment.agent_list_appointment,
        property_agent_data: state.property_modal.property_agent_data,
        property_detail: state.property_modal.property_detail,

        //property list
        property_for_sale: state.agent_modal.property_for_sale,
        property_for_rent: state.agent_modal.property_for_rent,
        
        //stock pipeline property data
        stock_pipeline_property_data: state.agent_modal.stock_pipeline_property_data,
        //appointment status
        appointment_form_error: state.add_appointment_modal.appointment_form_error,
        add_appointment_success: state.add_appointment_modal.add_appointment_success,
        
        //data for agent modal appointment calendar
        date_wise_appt: state.agent_modal.date_wise_appt,
        deleteEvents: state.agent_modal.deleteEvents,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddAppointment);
