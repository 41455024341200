import React ,  { Component } from 'react';
import {connect} from 'react-redux';
import * as actions from '../store/actions/index';

import Helmet from "react-helmet";
import Footer from '../components/Footer';
import '../CustomFiles/bower_components/bootstrap/dist/css/bootstrap.min.css';
import '../CustomFiles/bower_components/font-awesome/css/font-awesome.min.css';
import '../CustomFiles/bower_components/Ionicons/css/ionicons.min.css';
import '../CustomFiles/dist/css/AdminLTE.min.css';
import '../CustomFiles/plugins/iCheck/square/blue.css';
import Loader from '../components/Loader';
import ErrorText from './ErrorText';
import { Redirect } from 'react-router-dom';


class AdminLogin extends Component{
    //eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    componentDidMount = () =>{
        let searchParams = new URLSearchParams(window.location.search)
        searchParams.has('d') // true
        var param = searchParams.get('d')
        if(param){
            //decode base64 to string
            var data = Buffer.from(param, 'base64')
            this.props.get_admin_auth(data);
        }
    }
  
    render(){
        let footer= "footer_color";
        if (window.innerWidth > document.body.clientWidth) {
        footer = 'footer';
        }
        return(
        <div>
            <Helmet>
            <title>Office Login</title>
            <link rel="icon" href="https://roundr.app/wp-content/uploads/2018/03/favicon.png" type="image/png"></link>
            </Helmet>
            <div className="login-box">
            <div className="login-logo">
                <a href="/#">
                <img src = "/images/logo.png" height="35" alt = "" />
                &nbsp;
                <b>Office</b>
                </a>
            </div>
            <br />
            
            { this.props.error ? <ErrorText errorMsg = {this.props.error} /> : <Loader/>}
                
            </div>
            { this.props.redirect_link ? <Redirect to={this.props.redirect_link}/> : null}
            <Footer footer={footer}></Footer>
        </div>
        )
    }
}

const mapStateToProps = state => {
    return{
        redirect_link: state.auth.redirect_link,
        error: state.auth.error
    };
};
const mapDispatchToProps = dispatch => {
    return {
        get_admin_auth : (data) => dispatch(actions.get_admin_auth(data))
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(AdminLogin);
