import React,{ Component } from "react";
import {connect} from 'react-redux';
import * as actions from '../store/actions/index';

//import Constants from '../constants/Constants';
import ReactTooltip from "react-tooltip";
import { Calendar } from 'primereact/calendar';
//import Loader from '../Loader';
import { Icon } from '@iconify/react';
//import bxsUser from '@iconify/icons-bx/bxs-user';
import informationIcon from '@iconify/icons-mdi/information';
import $ from 'jquery';

//var today = new Date(new Date().getFullYear(), new Date().getMonth()+1, new Date().getDate());
class DateRange extends Component{
    constructor(props) {
        super(props);
        this.state = {
          startDate: new Date(new Date().getFullYear(), 0, 1),
          endDate:   new Date(new Date().getFullYear(), 11, new Date().getDate()),
          renderAgentButton: 0,
          CalenderMinDate: new Date(new Date().getFullYear(), 0, 1),
          displayRange: false,
        }
        this.changeDateFormat = this.changeDateFormat.bind(this);
        this.resetDateRange = this.resetDateRange.bind(this);
    }        

    changeDateFormat(fromDate) {
        var d = new Date(fromDate),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
    
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [year, month, day].join('-');
    }

    resetDateRange = () => {
        this.setState({displayRange: false});
        $(".select_daterange").val("3");
        this.props.set_page(this.props.page, false);
    }

    changeDateRange = (event) => {
        var date = new Date();
        var fromDate = '';
        var toDate = this.changeDateFormat(new Date(date.getFullYear(), date.getMonth(), date.getDate()));
        const token = localStorage.getItem('token');
        var property_notes_user_id;
        let agent_id;
        let fromnetworking;

        //current month
        if(Number(event.target.value) === Number(1)){
            fromDate = this.changeDateFormat(new Date(date.getFullYear(), date.getMonth(), 1));
            this.props.change_from_to_date(fromDate, toDate)
            if(this.props.page === "overview_page"){
                this.props.get_overview_data_with_date(fromDate, toDate, token);
            }else if(this.props.page === "properties_page"){
                //Property Table Date Filter
                //this.props.get_property_list_date(this.props.data_table_filter_by, fromDate, toDate, token);
                /* Property Chart API */
                this.props.get_property_chart_property(fromDate, toDate, token);
            }else if(this.props.page === "viewings_page"){
                this.props.get_filtered_agent_list_appointment(fromDate, toDate, token);
                this.props.get_appointment_chart_appointment(fromDate, toDate, this.props.agent_list, token);
                this.props.get_general_chart_appointment(fromDate, toDate, this.props.agent_list, token);
            }else if(this.props.page === "revenue_page"){
                this.props.get_revenue_chart_revenue(fromDate, toDate, this.props.agent_list, token);
            }else if(this.props.page === "appts_tab"){
                this.props.get_appointment_data_modal(fromDate, toDate, toDate, this.props.agent_id, token);
                this.props.get_general_chart_agent_modal(fromDate, toDate, this.props.agent_id, token);
            }else if(this.props.page === "property_tab"){
                this.props.get_property_data(fromDate, toDate, this.props.agent_id, token, this.props.viewings_sort, this.props.recent_property);
            }else if(this.props.page === "stockpipeline_tab"){
                this.props.get_stockpipeline_chart_by_id(fromDate, toDate, this.props.agent_id, token);
            }else if(this.props.page === "call_contact_tab"){
                this.props.get_coldcalls_chart_by_id(fromDate, toDate, this.props.agent_id, token);
            }else if(this.props.page === "property_modal"){
                if(Number(this.props.fromnetworking) === 1){
                    property_notes_user_id = this.props.agent_id;
                    agent_id = this.props.property_detail.agent_id;
                    fromnetworking = 1;
                    this.props.get_property_data_modal(fromDate, toDate, this.props.prop_id, agent_id, token, this.props.date_sold, fromnetworking, property_notes_user_id)
                }else{
                    this.props.get_property_data_modal(fromDate, toDate, this.props.prop_id, this.props.agent_id, token, this.props.date_sold)
                }
            }
        // 3 month
        }else if(Number(event.target.value) === Number(3)){
            fromDate = this.changeDateFormat(new Date(date.getFullYear(), date.getMonth() - 2, 1));
            this.props.change_from_to_date(fromDate, toDate)
            if(this.props.page === "overview_page"){
                this.props.get_overview_data_with_date(fromDate, toDate, token);
            }else if(this.props.page === "properties_page"){
                //Property Table Date Filter
                //this.props.get_property_list_date(this.props.data_table_filter_by, fromDate, toDate, token);
                /* Property Chart API */
                this.props.get_property_chart_property(fromDate, toDate, token);
            }else if(this.props.page === "viewings_page"){
                this.props.get_filtered_agent_list_appointment(fromDate, toDate, token);
                this.props.get_appointment_chart_appointment(fromDate, toDate, this.props.agent_list, token);
                this.props.get_general_chart_appointment(fromDate, toDate, this.props.agent_list, token);
            }else if(this.props.page === "revenue_page"){
                this.props.get_revenue_chart_revenue(fromDate, toDate, this.props.agent_list, token);
            }else if(this.props.page === "appts_tab"){
                this.props.get_appointment_data_modal(fromDate, toDate, toDate, this.props.agent_id, token);
                this.props.get_general_chart_agent_modal(fromDate, toDate, this.props.agent_id, token);
            }else if(this.props.page === "property_tab"){
                this.props.get_property_data(fromDate, toDate, this.props.agent_id, token, this.props.viewings_sort, this.props.recent_property);
            }else if(this.props.page === "stockpipeline_tab"){
                this.props.get_stockpipeline_chart_by_id(fromDate, toDate, this.props.agent_id, token);
            }else if(this.props.page === "call_contact_tab"){
                this.props.get_coldcalls_chart_by_id(fromDate, toDate, this.props.agent_id, token);
            }else if(this.props.page === "property_modal"){
                if(Number(this.props.fromnetworking) === 1){
                    property_notes_user_id = this.props.agent_id;
                    agent_id = this.props.property_detail.agent_id;
                    fromnetworking = 1;
                    this.props.get_property_data_modal(fromDate, toDate, this.props.prop_id, agent_id, token, this.props.date_sold, fromnetworking, property_notes_user_id)
                }else{
                    this.props.get_property_data_modal(fromDate, toDate, this.props.prop_id, this.props.agent_id, token, this.props.date_sold)
                }
            }
        // 6 month
        }else if(Number(event.target.value) === Number(6)){
            fromDate = this.changeDateFormat(new Date(date.getFullYear(), date.getMonth() - 5, 1));
            this.props.change_from_to_date(fromDate, toDate)
            if(this.props.page === "overview_page"){
                this.props.get_overview_data_with_date(fromDate, toDate, token);
            }else if(this.props.page === "properties_page"){
                //Property Table Date Filter
                //this.props.get_property_list_date(this.props.data_table_filter_by, fromDate, toDate, token);
                /* Property Chart API */
                this.props.get_property_chart_property(fromDate, toDate, token);
            }else if(this.props.page === "viewings_page"){
                this.props.get_filtered_agent_list_appointment(fromDate, toDate, token);
                this.props.get_appointment_chart_appointment(fromDate, toDate, this.props.agent_list, token);
                this.props.get_general_chart_appointment(fromDate, toDate, this.props.agent_list, token);
            }else if(this.props.page === "revenue_page"){
                this.props.get_revenue_chart_revenue(fromDate, toDate, this.props.agent_list, token);
            }else if(this.props.page === "appts_tab"){
                this.props.get_appointment_data_modal(fromDate, toDate, toDate, this.props.agent_id, token);
                this.props.get_general_chart_agent_modal(fromDate, toDate, this.props.agent_id, token);
            }else if(this.props.page === "property_tab"){
                this.props.get_property_data(fromDate, toDate, this.props.agent_id, token, this.props.viewings_sort, this.props.recent_property);
            }else if(this.props.page === "stockpipeline_tab"){
                this.props.get_stockpipeline_chart_by_id(fromDate, toDate, this.props.agent_id, token);
            }else if(this.props.page === "call_contact_tab"){
                this.props.get_coldcalls_chart_by_id(fromDate, toDate, this.props.agent_id, token);
            }else if(this.props.page === "property_modal"){
                if(Number(this.props.fromnetworking) === 1){
                    property_notes_user_id = this.props.agent_id;
                    agent_id = this.props.property_detail.agent_id;
                    fromnetworking = 1;
                    this.props.get_property_data_modal(fromDate, toDate, this.props.prop_id, agent_id, token, this.props.date_sold, fromnetworking, property_notes_user_id)
                }else{
                    this.props.get_property_data_modal(fromDate, toDate, this.props.prop_id, this.props.agent_id, token, this.props.date_sold)
                }
            }
        //custom dates
        }else if(event.target.value === "customize"){
            fromDate = this.changeDateFormat(this.state.startDate);
            toDate = this.changeDateFormat(this.state.endDate);
            if(this.props.page === "overview_page"){
                this.props.get_overview_data_with_date(fromDate, toDate, token);
            }else if(this.props.page === "properties_page"){
                //Property Table Date Filter
                //this.props.get_property_list_date(this.props.data_table_filter_by, fromDate, toDate, token);
                /* Property Chart API */
                this.props.get_property_chart_property(fromDate, toDate, token);
            }else if(this.props.page === "viewings_page"){
                this.props.get_filtered_agent_list_appointment(fromDate, toDate, token);
                this.props.get_appointment_chart_appointment(fromDate, toDate, this.props.agent_list, token);
                this.props.get_general_chart_appointment(fromDate, toDate, this.props.agent_list, token);
            }else if(this.props.page === "revenue_page"){
                this.props.get_revenue_chart_revenue(fromDate, toDate, this.props.agent_list, token);
            }else if(this.props.page === "appts_tab"){
                this.props.get_appointment_data_modal(fromDate, toDate, toDate, this.props.agent_id, token);
                this.props.get_general_chart_agent_modal(fromDate, toDate, this.props.agent_id, token);
            }else if(this.props.page === "property_tab"){
                this.props.get_property_data(fromDate, toDate, this.props.agent_id, token, this.props.viewings_sort, this.props.recent_property);
            }else if(this.props.page === "stockpipeline_tab"){
                this.props.get_stockpipeline_chart_by_id(fromDate, toDate, this.props.agent_id, token);
            }else if(this.props.page === "call_contact_tab"){
                this.props.get_coldcalls_chart_by_id(fromDate, toDate, this.props.agent_id, token);
            }else if(this.props.page === "property_modal"){
                if(Number(this.props.fromnetworking) === 1){
                    property_notes_user_id = this.props.agent_id;
                    agent_id = this.props.property_detail.agent_id;
                    fromnetworking = 1;
                    this.props.get_property_data_modal(fromDate, toDate, this.props.prop_id, agent_id, token, this.props.date_sold, fromnetworking, property_notes_user_id)
                }else{
                    this.props.get_property_data_modal(fromDate, toDate, this.props.prop_id, this.props.agent_id, token, this.props.date_sold)
                }
            }
            this.setState({displayRange: true})
        }
    }

    closeDateRange = () => {
        this.setState({displayRange: false})
        $(".select_daterange").val("3");
        const token = localStorage.getItem('token');
        var date = new Date();
        var fromDate = this.changeDateFormat(new Date(date.getFullYear(), date.getMonth() - 2, 1));
        var toDate = this.changeDateFormat(new Date(date.getFullYear(), date.getMonth(), date.getDate()));
        if(this.props.page === "overview_page"){
            this.props.get_overview_data_with_date(fromDate, toDate, token);
        }else if(this.props.page === "properties_page"){
            //Property Table Date Filter
            //this.props.get_property_list_date(this.props.data_table_filter_by, fromDate, toDate, token);
            /* Property Chart API */
            this.props.get_property_chart_property(fromDate, toDate, token);
        }else if(this.props.page === "viewings_page"){
            this.props.get_filtered_agent_list_appointment(fromDate, toDate, token);
            this.props.get_appointment_chart_appointment(fromDate, toDate, this.props.agent_list, token);
            this.props.get_general_chart_appointment(fromDate, toDate, this.props.agent_list, token);
        }else if(this.props.page === "revenue_page"){
            this.props.get_revenue_chart_revenue(fromDate, toDate, this.props.agent_list, token);
        }else if(this.props.page === "appts_tab"){
            this.props.get_appointment_data_modal(fromDate, toDate, toDate, this.props.agent_id, token);
            this.props.get_general_chart_agent_modal(fromDate, toDate, this.props.agent_id, token);
        }else if(this.props.page === "property_tab"){
            this.props.get_property_data(fromDate, toDate, this.props.agent_id, token, this.props.viewings_sort, this.props.recent_property);
        }else if(this.props.page === "stockpipeline_tab"){
            this.props.get_stockpipeline_chart_by_id(fromDate, toDate, this.props.agent_id, token);
        }else if(this.props.page === "call_contact_tab"){
            this.props.get_coldcalls_chart_by_id(fromDate, toDate, this.props.agent_id, token);
        }else if(this.props.page === "property_modal"){
            if(Number(this.props.fromnetworking) === 1){
                var property_notes_user_id = this.props.agent_id;
                let agent_id = this.props.property_detail.agent_id;
                let fromnetworking = 1;
                this.props.get_property_data_modal(fromDate, toDate, this.props.prop_id, agent_id, token, this.props.date_sold, fromnetworking, property_notes_user_id)
            }else{
                this.props.get_property_data_modal(fromDate, toDate, this.props.prop_id, this.props.agent_id, token, this.props.date_sold)
            }
        }
    }

    getCalendarData = (e) => {
        var fromDate = null;
        var toDate = null;
        const token = localStorage.getItem('token');
        //set date
        if(e.target.name === "from_date"){
          fromDate = this.changeDateFormat(e.value);
          toDate = this.changeDateFormat(this.state.endDate);
        }else{
          fromDate = this.changeDateFormat(this.state.startDate);
          toDate = this.changeDateFormat(e.value);
        }
        this.props.change_from_to_date(fromDate, toDate)
        
        //get graphs
        if(this.props.page === "overview_page"){
            this.props.get_overview_data_with_date(fromDate, toDate, token);
        }else if(this.props.page === "properties_page"){
            //Property Table Date Filter
            //this.props.get_property_list_date(this.props.data_table_filter_by, fromDate, toDate, token);
            /* Property Chart API */
            this.props.get_property_chart_property(fromDate, toDate, token);
        }else if(this.props.page === "viewings_page"){
            this.props.get_filtered_agent_list_appointment(fromDate, toDate, token);
            this.props.get_appointment_chart_appointment(fromDate, toDate, this.props.agent_list, token);
            this.props.get_general_chart_appointment(fromDate, toDate, this.props.agent_list, token);
        }else if(this.props.page === "revenue_page"){
            this.props.get_revenue_chart_revenue(fromDate, toDate, this.props.agent_list, token);
        }else if(this.props.page === "appts_tab"){
            this.props.get_appointment_data_modal(fromDate, toDate, toDate, this.props.agent_id, token);
            this.props.get_general_chart_agent_modal(fromDate, toDate, this.props.agent_id, token);
        }else if(this.props.page === "property_tab"){
            this.props.get_property_data(fromDate, toDate, this.props.agent_id, token, this.props.viewings_sort, this.props.recent_property);
        }else if(this.props.page === "stockpipeline_tab"){
            this.props.get_stockpipeline_chart_by_id(fromDate, toDate, this.props.agent_id, token);
        }else if(this.props.page === "call_contact_tab"){
            this.props.get_coldcalls_chart_by_id(fromDate, toDate, this.props.agent_id, token);
        }else if(this.props.page === "property_modal"){
            if(Number(this.props.fromnetworking) === 1){
                var property_notes_user_id = this.props.agent_id;
                let agent_id = this.props.property_detail.agent_id;
                let fromnetworking = 1;
                this.props.get_property_data_modal(fromDate, toDate, this.props.prop_id, agent_id, token, this.props.date_sold, fromnetworking, property_notes_user_id)
            }else{
                this.props.get_property_data_modal(fromDate, toDate, this.props.prop_id, this.props.agent_id, token, this.props.date_sold)
            }
        }
    }

    render(){ 
        let info_message =  "If chosen date range is less than 60 days - data is shown in days <br/>"+
                            "If chosen date range is 61-180 days - data is shown in weeks <br/>"+
                            "If chosen date range is 181-365 days - data is shown in months <br/>";
        
        if(this.props.page === "appts_tab"){
            if(this.props.reset_date_range){
                this.resetDateRange();
            }
        }else if(this.props.page === "property_tab"){
            if(this.props.reset_date_range){
                this.resetDateRange();
            }
        }else if(this.props.page === "call_contact_tab"){
            if(this.props.reset_date_range){
                this.resetDateRange();
            }
        }else if(this.props.page === "property_modal"){
            if(this.props.reset_date_range){
                this.resetDateRange();
            }
        }else if(this.props.reset_date_range){
            this.resetDateRange();
        }
        
        return(
            <div style={this.props.page !== "property_modal" ? {float:'right'}: null}>
                <div style={{ float:'left'}}>
                <ReactTooltip resizeHide = {true} html={true} backgroundColor={'#0B2C4C'}/>
                <span className="fontSize15 fontInter color0F1D30 fontWeight500 dateRange" style={this.props.page !== "property_modal" ? {marginTop:'8px'} : {marginTop:'1px'}}>
                    <span data-place="top" data-tip={info_message}>
                        {this.props.page !== "overview_page" ?
                           <Icon icon={informationIcon} className="color8591A0" style={{fontSize: '20px', verticalAlign: "text-bottom"}}/>
                        :
                        null}
                    </span>
                    &nbsp;
                    Date Range
                </span>
                </div>
                
                <div style={this.state.displayRange ? {display:"none", marginTop: 8, float:'right'} : this.props.page !== "property_modal" ? {marginTop: 8, float:'right'} : {marginTop: 1}}>
                    <select className="select_daterange" style={{display:"inline", border: "2px solid", borderRadius: "5px", marginLeft: "15px", fontSize:"16px"}} id="select_daterange" name="select_daterange" ref="select_daterange" onChange={this.changeDateRange}>
                        <option value={1}>Current Month</option>
                        <option value={3} selected>3 Months</option>
                        <option value={6}>6 Months</option>
                        <option value="customize">Customize</option>
                    </select>
                </div>
                
                <div style={!this.state.displayRange ? {display:"none", marginTop: 8, marginRight: 30, float:'right'} : this.props.page !== "property_modal" ? {marginTop: 8, marginRight: 38, float:'right'} : {marginTop: 2} }>
                    <input type="text" className="from_label" value="From:" disabled/>
                    <Calendar value={this.state.startDate} maxDate={this.state.endDate} dateFormat="dd-mm-yy" name="from_date" id="from_date" className='ml15'
                        onChange={(e) => {this.setState({ startDate: e.value, renderAgentButton: 1, CalenderMinDate:e.value }); this.getCalendarData(e)} }>
                    </Calendar>
                    &nbsp;
                    <input type="text" className="to_label" value="To:" disabled/>
                    <Calendar minDate={this.state.CalenderMinDate} maxDate={new Date()} name="to_date" id="to_date" value={this.state.endDate} dateFormat="dd-mm-yy" className={this.props.page === "property_modal" ? "prop_modal_calendar_popup" : null}
                        onChange={(e) => {this.setState({ endDate: e.value }); this.getCalendarData(e)} }>
                    </Calendar>
                    <div id="closeDateRange" className="circle" style={this.props.page !== "property_modal" ? {width:"20px", height:"20px"}:{width:"20px", height:"20px", top: "4px", left: "85%"}} onClick={this.closeDateRange}>
                        <img src="images/modal-close.png" style={{verticalAlign: "super"}} width="9" height="9" alt="" onClick={this.closeDateRange}/>
                    </div> 
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        //set Date for properties data-table
        change_from_to_date: (fromDate, toDate) => dispatch(actions.change_from_to_date(fromDate, toDate)),
        //set page for calendar
        set_page: (page, reset_date_range) => dispatch(actions.set_page(page, reset_date_range)),
        
        //overview_Page
        get_overview_data_with_date: (fromDate, toDate, token) => dispatch(actions.get_overview_data_with_date(fromDate, toDate, token)),
        //properties_page
        get_property_chart_property : (fromDate, toDate, token) => dispatch(actions.get_property_chart_property(fromDate, toDate, token)),
        get_property_list_date : (filter_by, fromDate, toDate, token) => dispatch(actions.get_property_list_date(filter_by, fromDate, toDate, token)),
        //viewings_page
        get_filtered_agent_list_appointment: (fromDate, toDate, token) => dispatch(actions.get_filtered_agent_list_appointment(fromDate, toDate, token)),
        get_appointment_chart_appointment: (fromDate, toDate, agent_list, token) => dispatch(actions.get_appointment_chart_appointment(fromDate, toDate, agent_list, token)),
        get_general_chart_appointment: (fromDate, toDate, agent_list, token) => dispatch (actions.get_general_chart_appointment(fromDate, toDate, agent_list, token)),
        //revenue_page
        get_revenue_chart_revenue : (fromDate, toDate, agent_list, token) => dispatch(actions.get_revenue_chart_revenue(fromDate, toDate, agent_list, token)),

        //appts_tab
        get_appointment_data_modal : (appointmentFromDate, appointmentToDate, todayDate, agent_id, token) => dispatch(actions.get_appointment_data_modal(appointmentFromDate, appointmentToDate, todayDate, agent_id, token)),
        get_general_chart_agent_modal: (appointmentFromDate, appointmentToDate, agent_id, token) => dispatch(actions.get_general_chart_agent_modal(appointmentFromDate, appointmentToDate, agent_id, token)),
        //property_tab
        get_property_data : (fromDate, toDate, agent_id, token, viewings_sort, recent_property) => dispatch(actions.get_property_data(fromDate, toDate, agent_id, token, viewings_sort, recent_property)),
        //stockpipeline_tab
        get_stockpipeline_chart_by_id : (fromDate, toDate, agent_id, token) => dispatch(actions.get_stockpipeline_chart_by_id(fromDate, toDate, agent_id, token)),
        //call_contact_tab
        get_coldcalls_chart_by_id : (fromDate, toDate, agent_id, token) => dispatch(actions.get_coldcalls_chart_by_id(fromDate, toDate, agent_id, token)),
        
        //property_modal
        get_property_data_modal: (fromDate, toDate, prop_id, agent_id, token, date_sold, fromnetworking, property_notes_user_id) => dispatch(actions.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold, fromnetworking, property_notes_user_id)),
    }
}

const mapStateToProps = state => {
    return{
        token: state.auth.token,
        page: state.agent_modal.page,
        reset_date_range: state.agent_modal.reset_date_range,
        //property modal data
        property_detail: state.property_modal.property_detail,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DateRange);