import React,{ Component } from "react";
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import Loader from '../Loader';
import modal from '../../CustomFiles/js/modal';

//import ReactTooltip from 'react-tooltip';
import { Icon } from '@iconify/react';
import officeBuildingMarker from '@iconify/icons-mdi/office-building-marker';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
//import informationIcon from '@iconify/icons-mdi/information';
import infoOutline from '@iconify/icons-dashicons/info-outline';
import {Checkbox} from 'primereact/checkbox';
import AddPropertySuccess from './AddPropertySuccess';
import ErrorText from '../../auth/ErrorText';
import SuccessText from '../../auth/SuccessText';

import AddressGoogleMap from "./Add_Edit_StockPIpeline/AddressGoogleMap";
import EditAddressGoogleMap from "./Add_Edit_StockPIpeline/EditAddressGoogleMap";

let items = [];

class AddProperty extends Component{
    constructor(props) {
        super(props);
        this.state = {
            seller: "seller",
            agent_list: null,
            address:'',

            //checkbox
            vacantisChecked: false,
            mapisChecked: false,
            open_listing: false,
            office_listing: false,
            is_office_listing_edited: false,
            is_open_listing_edited: false,
            //radio btn
            reside: 1,
            sale_type: 1,

            //edit property
            edit_address: null,
            edit_address_1: null,
            edit_sale_type: null,
            external_link: null,
            crm_id: null,
            unit: null,
            price: null,

            display_card: "none",

            //map
            full_address: null,
            full_address_length: 0,
            edit_full_address_length: 0,
            map_address: null,
            lat: null,
            lng: null,
        }
        this.myRef = React.createRef();

        this.submitHandler = this.submitHandler.bind(this);
        this.edit_submitHandler = this.edit_submitHandler.bind(this);

        this.onInputchange = this.onInputchange.bind(this);

        this.toggleResideChange = this.toggleResideChange.bind(this);
        this.edittoggleSaleTypeChange = this.edittoggleSaleTypeChange.bind(this);
        this.toggleSaleTypeChange = this.toggleSaleTypeChange.bind(this);
        this.closeModal = this.closeModal.bind(this);
        
        this.wrapperRef = React.createRef();
        this.setwrapperRef = React.createRef();
    }
    
    onInputchange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
        if(event.target.name === "full_address"){
            let len = event.target.value;
            len = len.length;
            this.setState({full_address_length: len})
        }
        if(event.target.name === "edit_address_1"){
            let len = event.target.value;
            len = len.length;
            this.setState({edit_full_address_length: len})
        }
    }

    //handle radio buttons toggle
    toggleSaleTypeChange(event){
        this.setState({
            sale_type: event.target.value,
            seller: Number(event.target.value) === 1 ? "seller" : "owner",
            vacantisChecked: false,
        });
        if(Number(event.target.value) === Number(1)){
            if(Number(this.state.reside) === Number(0)){
                window.$("#no_reside").trigger('click');
                window.$("#forSale").trigger('click');
            }
        }
    }

    edittoggleSaleTypeChange(e) { 
        this.setState({
            edit_sale_type: e.target.value,
        });
    }
    
    toggleResideChange(event){
        this.setState({
            reside: event.target.value
        });
    }

    //handle checkbox toggle
    toggleMapCheckboxChange=()=>{
        this.setState(({ mapisChecked }) => (
            {
                mapisChecked: !mapisChecked,
            }
        ));
    }
        
    toggleVacantCheckboxChange=()=>{
        this.setState(({ vacantisChecked }) => (
            {
                vacantisChecked: !vacantisChecked,
            }
        ));
    }

    toggleOfficeListingCheckboxChange = ()=>{
        if(!this.state.is_office_listing_edited){
            this.setState({office_listing: Number(this.props.office_listing) === 1 ? false: true,
                open_listing: false,
                is_office_listing_edited: true,
                is_open_listing_edited: true})
        }else{
            if(this.state.office_listing){
                this.setState({
                    office_listing: false,
                    open_listing: false
                });
            }else{
                this.setState({office_listing: true});
            }
        }

    }

    toggleOpenListingCheckboxChange=()=>{
        if(!this.state.is_open_listing_edited){
            this.setState({
                open_listing: Number(this.props.open_listing) === 1 ? false: true,
                is_open_listing_edited: true
            })
        }else{
            if(this.state.open_listing){
                this.setState({
                    open_listing: false,
                });
            }else{
                this.setState({open_listing: true});
            }
        } 
    }
     
    //handle add property button
    async submitHandler(event){
        event.preventDefault(); 
        let hide_map = 1;
        let is_seller_occupant = this.state.reside;
        let empty_land = '';

        // if(this.refs.hide_map.props.checked){
        //     hide_map = '1';
        // }else{
        //     hide_map = '0';
        // }

        if((ReactDOM.findDOMNode(this.refs.show_vacant_empty)) !== null){
            if(this.refs.vacant_empty.props.checked){
                is_seller_occupant = 1
            }
        }

        if((ReactDOM.findDOMNode(this.refs.show_vacant_empty)) === null){
            empty_land = 0
        }else if(this.refs.vacant_empty.props.checked){
            empty_land = 1
        }else{
            empty_land = 0
        }
        // address_1:     this.refs.address_1.value,
        let authtoken=     this.props.authtoken;
        //let agent_id=      this.refs.agent_name.value;
        let address =      this.state.full_address;
        let lat=           this.state.lat;
        let lng=           this.state.lng;
        
        //let unit=          this.refs.unit.value;
        let external_link= this.refs.external_link.value;
        //let external_link= null;

        let sale_type =    this.state.sale_type;
        let price=         this.refs.price.value;
        let is_office_listing = this.state.office_listing ? "1" : "0"; 
        let is_open_listing =   this.state.open_listing   ? "1" : "0";
        
        let reside=        is_seller_occupant;
        let timezone= this.props.timezone;
        let crm_id='';
        // if(Number(this.props.pro_user) === Number(1)){
        //     crm_id =       this.refs.crm_id.value
        // }
        //let vacant_empty=  this.refs.vacant_empty.props.checked;
        
        //authtoken = this.props.authtoken;
        
        await this.props.add_property_data(authtoken,address,lat,lng,external_link,hide_map,sale_type,price,is_office_listing,is_open_listing,reside,empty_land,crm_id,timezone);
        window.$("#addPropertySuccessModal").modal('show');
        
        const token = localStorage.getItem('token');
        var fromDate = new Date("1/1/" + (new Date()).getFullYear());
        var toDate = new Date(new Date().getFullYear(), 11, 31, 0, 0, 0);

        var d = new Date(fromDate);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        fromDate = [year, month, day].join('-');

        d = new Date(toDate);
        month = '' + (d.getMonth() + 1);
        day = '' + d.getDate();
        year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        toDate = [year, month, day].join('-');

        let viewings_sort = null;
        let recent_property = 'desc';
        if(this.props.property_form_error === 'success') {
            this.props.get_property_data(fromDate, toDate, this.props.agents_id, token, viewings_sort, recent_property);
            if(window.location.pathname === "/overview"){ 
                this.props.get_overview_data(token)
            }else if(window.location.pathname === "/properties"){
                this.props.get_property_list(token, this.props.filter_by);
            }
        }    
    }

    async edit_submitHandler(event){
        event.preventDefault(); 
        let hide_map = this.props.hide_map;
        let lat = '';
        let lng = '';
        // if(this.refs.edit_hide_map.props.checked){
        //     hide_map = '1';
        // }else{
        //     hide_map = '0';
        // }
        //let agent_id=      this.refs.agent_name.value;
        
        let authtoken = this.props.authtoken;
        let prop_id = this.props.prop_id
        let address   = this.state.edit_address_1 ? this.state.edit_address_1 : this.props.address;
        if(!this.state.lat || !this.state.lng){
            lat = this.props.lat;
            lng = this.props.lng;
        }else{
            lat = this.state.lat;
            lng = this.state.lng;
        }
        
        let external_link= this.state.external_link ? this.state.external_link: this.props.external_url;
        let edit_sale_type =    !this.state.edit_sale_type ? this.props.sale_type : this.state.edit_sale_type;
        let price=         this.refs.price.value;
        let is_office_listing = this.state.is_office_listing_edited ? this.state.office_listing ? "1" : "0" : this.props.office_listing;
        let is_open_listing   = this.state.is_open_listing_edited ? this.state.open_listing ? "1" : "0" : this.props.open_listing;
        let reside=        this.props.reside;
        let timezone =      this.props.timezone;
        let crm_id = '';
        if(Number(this.props.pro_user) === Number(1)){
            crm_id = (this.state.crm_id === null) ? this.props.crm_id : this.state.crm_id;
        }
        
        await this.props.edit_property_data(authtoken,prop_id,address,lat,lng,external_link,hide_map,edit_sale_type,price,is_office_listing,is_open_listing,reside,crm_id,timezone);
        window.$("#addPropertySuccessModal").modal('show');

        /* Creating Date From 1st of Year to Last Of Year */
        var fromDate = new Date("1/1/" + (new Date()).getFullYear());
        var toDate = new Date(new Date().getFullYear(), 11, 31, 0, 0, 0);
        var d = new Date(fromDate),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        fromDate = [year, month, day].join('-');
        d = new Date(toDate);
        month = '' + (d.getMonth() + 1);
        day = '' + d.getDate();
        year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        toDate = [year, month, day].join('-');
        const token = localStorage.getItem('token');
        let date_sold="";
        this.props.get_property_data_modal(fromDate, toDate, prop_id, this.props.agent_id, token, date_sold);
        fromDate = new Date("1/1/" + (new Date()).getFullYear());
        toDate = new Date(new Date().getFullYear(), 11, 31, 0, 0, 0);
        let viewings_sort = null;
        let recent_property = 'desc';
        this.props.get_property_data(fromDate, toDate, this.props.agent_id, token, viewings_sort, recent_property);
    }

    handleAddPropertySuccessModal = () => {
        window.$("#addPropertySuccessModal").modal('show');
    }
    
    //clear modal data
    closeModal(){
        this.props.reset_all_form_state();
        this.setState({
            seller: "seller",
            reside: 1,
            sale_type: 1,
            vacantisChecked: false,
            mapisChecked: false,
            open_listing: false,
            office_listing: false,
            is_office_listing_edited: false,
            is_open_listing_edited: false,
            address: null,
            external_link: null,
            unit: null,
            price: null,

            address_1: null,
            edit_address_1: null,
            edit_sale_type: null,
            lat: null,
            lng: null,
            display_card: "none",
            crm_id: null,

            map_address: null,
            full_address: null,
            full_address_length: 0,
            edit_full_address_length: 0
        },
        async () => {
            var add_property_modal_state = 0;
            this.props.update_add_property_modal_state(add_property_modal_state);
            this.props.update_add_property_success_modal_state();
            this.props.reset_property_modal_error();
            await this.props.reset_add_stockpipeline_form();
            this.props.reset_google_map();
                window.$("#full_address").val("");
                window.$("#edit_address_1").val("");
                window.$("#addPropertyModal").modal('hide');
            }
        );
    }
    
    componentDidMount() {
        modal();
    }
    
    get_userAuth = async (event) => {
        var token = localStorage.getItem('token');
        if(token && event.target.value){
            this.setState({address: ""});
            this.setState({display_card: "none"})
            await this.props.get_auth_data(event.target.value,token);
            this.props.get_all_agent_data_dd(this.props.authtoken)
            this.setState({display_card: "block"})
        }
    }
    
    createSelectItems = () => {
        items = [];
        if(this.props.agent_list){
            for (let i = 0; i < this.props.agent_list.length; i++) { 
                if(Number(this.props.agent_id) === Number(this.props.agent_list[i].userid)){
                    items.push(<option key={i} selected value={this.props.agent_list[i].userid}>{this.props.agent_list[i].firstname} {this.props.agent_list[i].lastname}</option>);   
                }else{
                    items.push(<option key={i} value={this.props.agent_list[i].userid}>{this.props.agent_list[i].firstname} {this.props.agent_list[i].lastname}</option>);   
                }
            }
           
            return items;
        }
    }

    get_data(){
        if(Number(this.props.add_property_modal_state) === 2){
            this.setState({
                price : this.props.price,
                unit : this.props.unit,
                external_url : this.props.external_url,
            })
        }
    }

    officeHelpModal(){
        window.$("#officeHelpModal").modal('show');
    }
    openHelpModal(){
        window.$("#openHelpModal").modal('show');
    }

    callbackFunction = (address, lat, lng) => {
        this.setState({
            map_address: address,
            lat: lat,
            lng: lng
        })
    }

    render(){
        if(this.state.edit_address_1 === null && this.props.address){
            let len = this.props.address;
            len = len.length;
            this.setState({edit_full_address_length: len});
            this.setState({edit_address_1: this.props.address},() => {
                window.$("#edit_address_1").val(this.props.address)
            });
        }
     
        return(
            <div>
                <div className="modal fade" data-backdrop="static" data-keyboard="false" id="addPropertyModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={this.setwrapperRef} >
                    <div className="modal-dialog" role="document" ref={this.wrapperRef}>
                        <div className="modal-content modal_add_property modalheight" style ={{borderRadius : '12px' }} >
                            <div className="modal-header" style={{ backgroundColor: "#EDF6F6"}}>
                            {Number(this.props.add_property_modal_state) === 1 || Number(this.props.add_property_modal_state) === 0 ?
                                <h4 className="modal-title fontWeight600"> <Icon className="message_icon color27AFAF" icon={officeBuildingMarker} height='25' width='25' /> Add Property</h4>
                                :
                                <h4 className="modal-title fontWeight600"> <Icon className="message_icon color27AFAF" icon={officeBuildingMarker} height='25' width='25' /> Edit Property</h4>
                            }
                                <div id="closeModal_add_property" className="circle" data-dismiss="modal" onClick={this.closeModal}>
                                    <img src="images/modal-close.png " width="12" height="12" alt=""/>
                                </div> 
                            </div>
                            
                            {this.props.property_form_error ? 
                                this.props.property_form_error === "success" ? 
                                    <SuccessText successMsg = {this.props.property_form_error} /> 
                                :
                                    <ErrorText errorMsg = {this.props.property_form_error} />
                            : 
                                null
                            }
                            {Number(this.props.add_property_modal_state) === 1 || Number(this.props.add_property_modal_state) === 0 ?
                                //add property form
                                <div className="modal-body">
                                    <center>
                                    <form id="addPropertyform" onSubmit={this.submitHandler}>
                                        {/* Agent Selection */}
                                        <div className="select-wrap">
                                            <label style={{float:'left'}}>Select Agent</label>
                                            {Number(this.props.add_property_modal_state) === 1 ? 
                                            <>
                                                <select id="agent_name" name="agent_name" ref="agent_name" disabled={true} style={{cursor:"not-allowed"}}>
                                                    <option value={this.props.agents_id} selected>{this.props.agent_data.firstname} {this.props.agent_data.lastname}</option>
                                                </select>
                                            </>
                                            :
                                                Number(this.props.add_property_modal_state) === 2 ?
                                                    <>
                                                    <select id="agent_name" name="agent_name" ref="agent_name" disabled={true} style={{cursor:"not-allowed"}}>
                                                        <option value={this.props.agents_id} selected>{this.props.agent_data.firstname} {this.props.agent_data.lastname}</option>
                                                    </select>
                                                    </>
                                                :   
                                                    <>
                                                    <select id="agent_name" name="agent_name" ref="agent_name" onChange={this.get_userAuth}>
                                                        <option value="" disabled selected>---</option>
                                                        {this.createSelectItems()}
                                                    </select> 
                                                    </>
                                            }
                                            
                                            <Icon id="dropdown_icon" className="dropdown_icon" icon={arrowIosDownwardFill} style={{color: '#27afaf', fontSize: '24px'}} />
                                        </div>

                                        {/* Full Address */}
                                        <div className="shadow" ref="myinput" style={(Number(this.props.add_property_modal_state)) === 1 ? {display: "block"} : {display: this.state.display_card} }>
                                            <p className="cardHeader">What would you like to call this property?</p>
                                            <p className="cardSubheader">Enter the property address details</p>
                                            <div className='form_border'>                                            
                                                <div className='full-input'>
                                                    <textarea className="input_modal fontWeight800" style={{resize: 'none'}} rows="4" cols="50" name='full_address' id="full_address" placeholder="Enter full address" ref="full_address" maxLength="50" onChange={this.onInputchange} required>
                                                        {this.state.full_address}
                                                    </textarea>
                                                    <p className={this.state.full_address_length >= 40 ? "colorEA676A": null} style={{textAlign:"right"}}> {this.state.full_address_length}/50</p>
                                                </div>
                                            </div>
                                            <br/><br/>
                                        </div> 
                                        
                                        {/* Google Address */}
                                        <div className="shadow" style={(Number(this.props.add_property_modal_state)) === 1 ? {display: "block"} : {display: this.state.display_card} }>
                                            <p className="cardHeader ml10 mr10">Set this property’s location on the map for sharing directions.</p>
                                            <AddressGoogleMap
                                                parentCallback = {this.callbackFunction}
                                            />
                                            <br/><br/>
                                        </div>

                                        {/* Price */}
                                        <div className="shadow" style={(Number(this.props.add_property_modal_state)) === 1 ? {display: "block"} : {display: this.state.display_card} }>
                                            <p className="cardHeader">What is the asking price?</p>
                                            <p className="cardSubheader">Enter the property price details</p>
                                            <br/>
                                            <input className="btn_sale_type" type="radio" id="forSale" name="sale_type" onClick={this.toggleSaleTypeChange} value="1" defaultChecked={true}/><label htmlFor="forSale" className="btn_sale_type">For Sale</label>
                                            <input className="btn_sale_type" type="radio" id="forRent" name="sale_type" onClick={this.toggleSaleTypeChange} value="0"/><label htmlFor="forRent" className="btn_sale_type">Rental</label>
                                            <br/><br/>
                                            <div className='form_border'>
                                                <div className='full-input' style={{borderBottom: "none"}}>
                                                    <label className="label_modal" style={{width:'100%', textAlign:'left'}}>Asking Price</label>
                                                    <span className="priceSymbol">{this.props.currency_symbol}</span>
                                                    <input className="input_modal fontWeight800" style={{width:'80%',marginLeft:-30}} type='text' name='price' id="price" placeholder="120000" ref="price" pattern='[0-9]+([,\.][0-9]+)?' value={this.state.price}></input>
                                                </div>
                                            </div>
                                            <br/><br/><br/>
                                        </div>

                                        {/* Networking */}
                                        <div className="shadow" style={(Number(this.props.add_property_modal_state)) === 1 ? {display: "block"} : {display: this.state.display_card} }>
                                            <p className="cardHeader">Networking this property?</p>
                                            <p className="cardSubheader">Increase your chances of {Number(this.state.sale_type) === 1 ? "selling" : "renting"} this property by opening it up to other agents in your office.</p>
                                            <br/>
                                            <div className="networking_checkbox mb10" style={{paddingLeft:"20px"}}>
                                                <Checkbox style={{float:'left'}} inputId="office_listing" name="office_listing" checked={this.state.office_listing} value="yes" onChange={this.toggleOfficeListingCheckboxChange} ref="office_listing"></Checkbox> &nbsp;&nbsp; 
                                                <p className="fontSize13 fontWeight600 mb0 mr10" style={{display:"inline-block", fontFamily:"Inter"}}> Add to Office listings</p>
                                                <Icon icon={infoOutline} style={{color: '#27afaf', fontSize: '24px', float: 'right', cursor: "pointer" }} onClick={this.officeHelpModal}/>
                                            </div>
                                            <div className="networking_checkbox" style={{display: this.state.office_listing ? "block" : 'none', paddingLeft:"20px"}}>
                                                <Checkbox style={{float:'left', marginRight: "-15px"}} inputId="open_listing" name="open_listing" checked={this.state.open_listing} value="yes" onChange={this.toggleOpenListingCheckboxChange} ref="open_listing"></Checkbox> &nbsp;&nbsp; 
                                                <p className="fontSize13 fontWeight600 mb0" style={{display:"inline-block", fontFamily:"Inter", marginLeft: "-44px"}}> Add to Other listings</p>                                                                                            
                                                <Icon icon={infoOutline} style={{color: '#27afaf', fontSize: '24px', float: 'right', cursor: "pointer"}}  onClick={this.openHelpModal}/>
                                            </div>
                                            <br/>
                                            {/* <div style={{display: this.state.is_office_listing_edited ? this.state.office_listing ? "block" : 'none' : Number(this.props.office_listing) === Number(1) ? "block" : 'none', paddingLeft:"20px"}}> */}
                                            <div style={{paddingLeft:"20px"}}>
                                                <p className="fontSize13 fontWeight600 mb0 cardSubheader" style={{width:"77%", textAlign:"left"}}> 
                                                    Get more hits - Upload your marketing link for this listing.
                                                </p>
                                                <p className="fontSize13 fontWeight600 mb0 cardSubheader" style={{width:"77%", textAlign:"left"}}> 
                                                    *Can also be added later.
                                                </p>
                                                <br/>
                                                <div className='form_border'>
                                                    <div className='full-input' style={{borderBottom: "none"}}>
                                                        <label className="label_modal">External Link</label><span className="optional_modal">Optional</span>
                                                        <input className="input_modal" type='url' name='external_link' id="external_link" placeholder="https://www.Property24.com" ref="external_link"></input>
                                                    </div>
                                                </div>
                                            </div>
                                            <br/><br/>
                                        </div>

                                        {/* Owner Reside */}
                                        <div className="shadow" style={(Number(this.props.add_property_modal_state)) === 1 ? {display: "block"} : {display: this.state.display_card} }>
                                            <p className="cardHeader" style={{width:"90%"}}>Does the {this.state.seller} reside in this property?</p>
                                            <p className="cardSubheader">This enables you to send the correct communication (viewing requests or feedback) to the correct recipient</p>
                                            <br/>
                                            <input className="btn_reside" type="radio" id="yes_reside" name="reside" onClick={this.toggleResideChange} value="1" defaultChecked={true}/><label htmlFor="yes_reside" className="btn_reside">Yes, {this.state.seller} resides here</label>
                                            <input className="btn_reside" type="radio" id="no_reside" name="reside" onClick={this.toggleResideChange} value="0"/><label htmlFor="no_reside" className="btn_reside">No, {this.state.seller} does NOT resides here</label>
                                            <br/>
                                            
                                            {this.state.seller !== "owner" ?
                                                <div id="show_vacant_empty" ref="show_vacant_empty" style={{ display: Number(this.state.reside) === Number(0) ? "block" : "none" }}>                
                                                    <Checkbox inputId="vacant_empty" name="vacant_empty" checked={this.state.vacantisChecked} value="yes" onChange={this.toggleVacantCheckboxChange} ref="vacant_empty"></Checkbox> &nbsp;&nbsp; <p className="fontSize13 fontWeight600" style={{display:"inline-block", fontFamily:"Inter"}}> Vacant land / Empty house</p>
                                                </div>
                                            : null }
                                            <br/>
                                            <br/>
                                        </div>

                                        {/* CRM ID */}
                                        {/* {Number(this.props.pro_user) === Number(1) ?
                                            <>
                                            <div className="shadow">
                                                <p className="cardHeader"></p>
                                                <p className="cardSubheader">Required to sync property viewing & feedback data with your CRM system</p>
                                                <br/>
                                                <div className='form_border'>
                                                    <div className='full-input' style={{borderBottom: "none"}}>
                                                        <label className="label_modal">Enter Property CRM ID</label><span className="optional_modal">Optional</span>
                                                        <input className="input_modal" type='text' name='crm_id' id="crm_id" placeholder="CRM ID" ref="crm_id"></input>
                                                    </div>
                                                </div>    
                                                <br/><br/>
                                            </div>
                                            <br/><br/>
                                            </>
                                        :
                                            <><br/><br/></>
                                        } */}
                                        <br/><br/>
                                        <div style={(Number(this.props.add_property_modal_state)) === 1 ? {display: "block"} : {display: this.state.display_card} }> 
                                            <button id="addPropertyBtn" type="submit" className="btn btn-primary fontBold" style={{ background:'#4AA49C',borderRadius:'4px'}}>
                                                <Icon className="message_icon" icon={officeBuildingMarker} height='20' width='20' />
                                                Add Property
                                            </button>
                                        </div>
                                        <br/>
                                    </form>
                                    </center>
                                </div>
                            :
                                //edit proeprty form
                                <div className="modal-body">
                                    <center>
                                    <form id="editPropertyform" onSubmit={this.edit_submitHandler}>
                                        {/* Agent Selection */}
                                        <div className="select-wrap">
                                            <label style={{float:'left'}}>Select Agent</label>
                                            {this.props.agent_name?
                                                <select id="agent_name" name="agent_name" ref="agent_name">       
                                                    <option value={this.props.agent_id} selected>{this.props.agent_name}</option>
                                                </select>
                                            :<Loader />}
                                                
                                            <Icon id="dropdown_icon" className="dropdown_icon" icon={arrowIosDownwardFill} style={{color: '#27afaf', fontSize: '24px'}} />
                                        </div>
                                        
                                        {/* Full Address */}
                                        <div className="shadow" ref="myinput">
                                            <p className="cardHeader">What would you like to call this property?</p>
                                            <p className="cardSubheader">Enter the property address details</p>
                                            <div className='form_border'>                                            
                                                <div className='full-input'>
                                                    <textarea className="input_modal fontWeight800" style={{resize: 'none'}} rows="4" cols="50" name='edit_address_1' id="edit_address_1" placeholder="Enter full address" ref="edit_address_1" maxLength="50" onChange={this.onInputchange} value={this.state.edit_address_1} required>
                                                    </textarea>
                                                    <p className={this.state.edit_full_address_length >= 40 ? "colorEA676A": null} style={{textAlign:"right"}}> {this.state.edit_full_address_length}/50</p>
                                                </div>
                                            </div>
                                            <br/><br/>
                                        </div> 
                                        
                                        {/* Google Address */}
                                        <div className="shadow">
                                            <p className="cardHeader ml10 mr10">Set this property’s location on the map for sharing directions.</p>
                                            <EditAddressGoogleMap
                                                parentCallback = {this.callbackFunction}
                                            />
                                            <br/><br/>
                                        </div>

                                        {/* Price */}
                                        <div className="shadow">
                                            <p className="cardHeader">What is the asking price?</p>
                                            <p className="cardSubheader">Enter the property price details</p>
                                            <br/>
                                            {this.props.sale_type ?
                                            <>
                                            {Number(this.props.sale_type) === 1 ?
                                                <>
                                                    <input className="btn_sale_type" type="radio" name="edit_sale_type" onChange={this.edittoggleSaleTypeChange} value="1" id="editforSale" defaultChecked={true} /><label id="edit_for_sale" htmlFor="editforSale" className= "btn_sale_type">For Sale</label>
                                                    <input className="btn_sale_type" type="radio" name="edit_sale_type" onChange={this.edittoggleSaleTypeChange} value="0" id="editforRent" /><label id="edit_for_rent" htmlFor="editforRent" className="btn_sale_type">Rental</label>
                                                </>
                                            : 
                                                <>  
                                                    <input className="btn_sale_type" type="radio" name="edit_sale_type" onChange={this.edittoggleSaleTypeChange} value="1" id="editforSale" /><label id="edit_for_sale" for="editforSale" className= "btn_sale_type">For Sale</label>
                                                    <input className="btn_sale_type" type="radio" name="edit_sale_type" onChange={this.edittoggleSaleTypeChange} value="0" id="editforRent" defaultChecked={true} /><label id="edit_for_rent" for="editforRent" className="btn_sale_type">Rental</label> 
                                                </>
                                            }
                                                
                                            </>
                                            : null}

                                            <br/><br/>
                                            <div className='form_border'>
                                                <div className='full-input' style={{borderBottom: "none"}}>
                                                    <label className="label_modal" style={{width:'100%', textAlign:'left'}}>Asking Price</label>
                                                    <span className="priceSymbol">{this.props.currency_symbol}</span>
                                                    <input className="input_modal fontWeight800" style={{width:'80%',marginLeft:-30}} type='text' name='price' id="price" pattern='[0-9]+([,\.][0-9]+)?' placeholder="120000" ref="price" value={(this.state.price === "") || (this.state.price !== null) ? this.state.price : this.props.price} onChange={this.onInputchange} required title="Only numbers are allowed"></input>
                                                </div>
                                            </div>
                                            <br/><br/><br/>
                                        </div>

                                        {/* Networking */}
                                        <div className="shadow">
                                            <p className="cardHeader">Networking this property?</p>
                                            <p className="cardSubheader">Increase your chances of {!this.state.edit_sale_type ? Number(this.props.sale_type) === Number(1) ? "selling" : "renting"  : Number(this.state.edit_sale_type) === 1 ? "selling" : "renting"} this property by opening it up to other agents in your office.</p>
                                            <br/>
                                            <div className="networking_checkbox mb10" style={{paddingLeft:"20px"}}>
                                                <Checkbox style={{float:'left'}} inputId="office_listing" name="office_listing" checked={this.state.is_office_listing_edited ? this.state.office_listing : Number(this.props.office_listing) === Number(1) ? true: false} value="yes" onChange={this.toggleOfficeListingCheckboxChange} ref="office_listing"></Checkbox> &nbsp;&nbsp; 
                                                <p className="fontSize13 fontWeight600 mb0 mr10" style={{display:"inline-block", fontFamily:"Inter"}}> Add to Office listings</p>
                                                <Icon icon={infoOutline} style={{color: '#27afaf', fontSize: '24px', float: 'right', cursor: "pointer"}} onClick={this.officeHelpModal}/>
                                            </div>
                                            <div className="networking_checkbox" style={{display: this.state.is_office_listing_edited ? this.state.office_listing ? "block" : 'none' : Number(this.props.office_listing) === Number(1) ? "block" : 'none', paddingLeft:"20px"}}>
                                                <Checkbox style={{float:'left', marginRight: "-15px"}} inputId="open_listing" name="open_listing" checked={this.state.is_open_listing_edited ? this.state.open_listing : Number(this.props.open_listing) === Number(1) ? true: false} value="yes" onChange={this.toggleOpenListingCheckboxChange} ref="open_listing"></Checkbox> &nbsp;&nbsp; 
                                                <p className="fontSize13 fontWeight600 mb0" style={{display:"inline-block", fontFamily:"Inter"}}> Add to Other listings</p>
                                                <Icon icon={infoOutline} style={{color: '#27afaf', fontSize: '24px', float: 'right', cursor: "pointer"}} onClick={this.openHelpModal}/>
                                            </div>
                                            <br/>
                                            {/* <div style={{display: this.state.is_office_listing_edited ? this.state.office_listing ? "block" : 'none' : Number(this.props.office_listing) === Number(1) ? "block" : 'none', paddingLeft:"20px"}}> */}
                                            <div style={{paddingLeft:"20px"}}>
                                                <p className="fontSize13 fontWeight600 mb0 cardSubheader" style={{width:"77%", textAlign:"left"}}> 
                                                    Get more hits - Upload your marketing link for this listing.
                                                </p>
                                                <p className="fontSize13 fontWeight600 mb0 cardSubheader" style={{width:"77%", textAlign:"left"}}> 
                                                    *Can also be added later.
                                                </p>
                                                <br/>
                                                <div className='form_border'>
                                                    <div className='full-input' style={{borderBottom: "none"}}>
                                                        <label className="label_modal">External Link</label><span className="optional_modal">Optional</span>
                                                        <input className="input_modal" type='url' name='external_link' id="external_link" placeholder="https://www.Property24.com" ref="external_link" value={(this.state.external_link === "") || (this.state.external_link !== null) ? this.state.external_link : this.props.external_url} onChange={this.onInputchange}></input>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <br/><br/>
                                        </div>

                                        {/* CRM ID */}
                                        {/* {(Number(this.props.pro_user) === Number(1)) ?
                                            <>
                                            <div className="shadow">
                                                <p className="cardHeader"></p>
                                                <p className="cardSubheader">Required to sync property viewing & feedback data with your CRM system</p>
                                                <br/>
                                                <div className='form_border'>
                                                    {Number(this.props.sync_crm) === Number(1) ?
                                                        <div className='full-input' style={{borderBottom: "none"}}>
                                                            <label className="label_modal">Verified Property CRM ID</label>
                                                            <input className="input_modal" type='text' name='crm_id' id="crm_id" placeholder="CRM ID" ref="crm_id" value={this.props.crm_id} readOnly></input>
                                                        </div>
                                                        :
                                                        <div className='full-input' style={{borderBottom: "none"}}>
                                                            <label className="label_modal">Enter Property CRM ID</label><span className="optional_modal">Optional</span>
                                                            <input className="input_modal" type='text' name='crm_id' id="crm_id" placeholder="CRM ID" ref="crm_id" value={(this.state.crm_id === null ) ? this.props.crm_id : this.state.crm_id} onChange={this.onInputchange}></input>
                                                        </div>
                                                    }
                                                </div>    
                                                <br/><br/>
                                            </div>
                                            <br/><br/>
                                            </>
                                            :
                                            <><br/><br/></>
                                        } */}
                                        
                                        <br/><br/>
                                        <div>
                                            {this.props.sold_price === "0.00" || this.props.sold_price === null ?
                                                <button id="editPropertyBtn" type="submit" className="btn btn-primary fontBold" style={{ background:'#4AA49C',borderRadius:'4px'}}>
                                                    <Icon className="message_icon" icon={officeBuildingMarker} height='20' width='20' />
                                                    Save Property
                                                </button>
                                            : null}
                                        </div>
                                    </form>
                                    </center>
                                </div>
                            }
                        </div> 
                    </div>
                </div> 
                
                <div className="modal fade" data-backdrop="static" data-keyboard="false" id="officeHelpModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={this.setwrapperRef} >
                    <div className="modal-dialog" role="document" ref={this.wrapperRef}>
                        <div className="modal-content modal_add_property modalheight" style ={{borderRadius : '12px' }} >
                            <div className="help_modal_header"> <h2>Office Listing </h2></div>
                            <hr/>
                            <div className="help_modal_text">
                                <p>Increase your chances of {Number(this.props.add_property_modal_state) === 2 ? !this.state.edit_sale_type ? Number(this.props.sale_type) === Number(1) ? "selling" : "renting"  : Number(this.state.edit_sale_type) === 1 ? "selling" : "renting" : Number(this.state.sale_type) === 1 ? "selling" : "renting"} this property by opening it up to other agents in your office.</p>
                                <p>The property will appear in the 'Office listings' section on the app where agents in your office can send you viewing requests.</p>
                                <p>You remain totally in control of viewing requests for the property and other agents have no visibility or communication with your property owner.</p>
                            </div>
                            <hr/>
                            <div data-dismiss="modal" style={{textAlign: "center"}}>
                                <button className="btn btn-primary fontBold" style={{ background:'#4AA49C',borderRadius:'4px', padding:'15px 30px 15px 30px'}}>
                                    Dismiss
                                </button>
                            </div> 
                            <br/>
                        </div>
                    </div>
                </div>
                <div className="modal fade" data-backdrop="static" data-keyboard="false" id="openHelpModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={this.setwrapperRef} >
                    <div className="modal-dialog" role="document" ref={this.wrapperRef}>
                        <div className="modal-content modal_add_property modalheight" style ={{borderRadius : '12px' }} >
                            <div className="help_modal_header"> <h2>Other Listing </h2></div>
                            <hr/>
                            <div className="help_modal_text">
                                <p>Increase your chances of {Number(this.props.add_property_modal_state) === 2 ? !this.state.edit_sale_type ? Number(this.props.sale_type) === Number(1) ? "selling" : "renting"  : Number(this.state.edit_sale_type) === 1 ? "selling" : "renting" : Number(this.state.sale_type) === 1 ? "selling" : "renting"} this property by opening it up to other agents on Roundr.</p>
                                <p>The property will appear in the 'Networking' section on the app where agents can send you viewing requests.</p>
                                <p>You remain totally in control of viewing requests for the property and other agents have no visibility or communication with your property owner.</p>
                            </div>
                            <hr/>
                            <div data-dismiss="modal" style={{textAlign: "center"}}>
                                <button className="btn btn-primary fontBold" style={{ background:'#4AA49C',borderRadius:'4px', padding:'15px 30px 15px 30px'}}>
                                    Dismiss
                                </button>
                            </div> 
                            <br/>
                        </div>
                    </div>
                </div>
                
                {this.props.property_form_error === 'success'?
                    <AddPropertySuccess
                        closeModal = {this.closeModal}
                        share_code = {this.props.share_code}
                    />
                : null}
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
      //add property
      add_property_data : (authtoken,address,lat,lng,unit,external_link,hide_map,sale_type,price,is_office_listing,is_open_listing,reside,empty_land,crm_id,timezone) => dispatch(actions.add_property_data(authtoken,address,lat,lng,unit,external_link,hide_map,sale_type,price,is_office_listing,is_open_listing,reside,empty_land,crm_id,timezone)),
      edit_property_data : (authtoken,prop_id,address,lat,lng,unit,external_link,hide_map,sale_type,price,is_office_listing,is_open_listing,reside,crm_id,timezone) => dispatch(actions.edit_property_data(authtoken,prop_id,address,lat,lng,unit,external_link,hide_map,sale_type,price,is_office_listing,is_open_listing,reside,crm_id,timezone)),
      update_add_property_modal_state: (add_property_modal_state) => dispatch(actions.update_add_property_modal_state(add_property_modal_state)),
      get_auth_data : (agent_id,token) => dispatch(actions.get_auth_data(agent_id,token)),
      update_add_property_success_modal_state : () => dispatch(actions.update_add_property_success_modal_state()),
      reset_all_form_state: () => dispatch(actions.reset_all_form_state()),
      reset_property_modal_error: () => dispatch(actions.reset_property_modal_error()),
      get_property_list : (token, filter_by) => dispatch(actions.get_property_list(token, filter_by)),
      get_overview_data : (token) => dispatch(actions.get_overview_data(token)),
      //get all agent data
      get_all_agent_data_dd: (token)=> dispatch(actions.get_all_agent_data_dd(token)),

      reset_add_stockpipeline_form: () => dispatch(actions.reset_add_stockpipeline_form()),
      reset_google_map: () => dispatch(actions.reset_google_map()),
      get_property_data_modal: (fromDate, toDate, prop_id, agent_id, token, date_sold) => dispatch(actions.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold)),
      get_property_data: (fromDate, toDate, agent_id, token, viewings_sort, recent_property) => dispatch(actions.get_property_data(fromDate, toDate, agent_id, token, viewings_sort, recent_property)),
      toggleMapCheckboxChange: (hide_map) => dispatch(actions.toggleMapCheckboxChange(hide_map))
    }
  }
  
const mapStateToProps = state => {
    return{
        authtoken: state.add_property_modal.authtoken,
        country_code: state.add_property_modal.country_code_dd,
        currency_symbol: state.add_property_modal.currency_symbol_dd,
        pro_user: state.add_property_modal.pro_user,
        timezone: state.add_property_modal.timezone,
        crm_id: state.add_property_modal.crm_id,
        sync_crm: state.add_property_modal.sync_crm,

        agent_data: state.agent_modal.agent_data,
        add_property_modal_state: state.add_property_modal.add_property_modal_state,

        //property_data
        //country_code: state.agent_modal.country_code,
        //currency_symbol: state.agent_modal.currency_symbol,

        //add property
        add_property_success: state.add_property_modal.add_property_success,
        share_code: state.add_property_modal.share_code,
        
        //edit 
        prop_id: state.add_property_modal.prop_id,
        agent_id: state.add_property_modal.agent_id,
        agent_name: state.add_property_modal.agent_name,
        address: state.add_property_modal.address,
        external_url:state.add_property_modal.external_url,
        lat: state.add_property_modal.lat,
        lng: state.add_property_modal.lon,
        unit: state.add_property_modal.unit,
        hide_map: state.add_property_modal.hide_map,
        sale_type: state.add_property_modal.sale_type,
        price: state.add_property_modal.price,
        office_listing: state.add_property_modal.is_office_listing,
        open_listing: state.add_property_modal.is_open_listing,
        reside: state.add_property_modal.reside,
        edit_property_success: state.add_property_modal.edit_property_success,
        property_form_error: state.add_property_modal.property_form_error,
        sold_price: state.add_property_modal.sold_price,
        filter_by: state.property.filter_by
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddProperty);
