//import Appointment from "../Appointment";
//preference 1 = live api, 2 = localhoverview/agent-cardost api, 3 = staging api

let preference = 3; 
let api_version = "4.2";
if(window.location.origin.toString() === "https://office.roundr.app"){
  preference = 1;
}else if(window.location.origin.toString() === "https://staging-office.roundr.app"){
  preference = 3;
}
let Constants ='';
// if(/*window.location.origin.toString() === "http://localhost:3000" ||*/ window.location.origin.toString() === "https://office.roundr.app"){
if(preference === 1 || window.location.origin.toString() === "https://office.roundr.app"){
  Constants = {
    SEARCH_MOVIES: 'SEARCH_MOVIES',
    SHOW_MOVIE_RESULTS: 'SHOW_MOVIE_RESULTS',
    USER_LOGIN:'USER_LOGIN',
    ADMINLOGIN: 'https://admin.realtorsroundr.com/admin/OfficePortal/access_request',
    LOGIN_API_URL:'https://admin.realtorsroundr.com/office-api/login',
    AGENT_LISTING_API_URL:'https://admin.realtorsroundr.com/office-api/overview',
    AGENT_OVERVIEW_LISTING_API_URL:'https://admin.realtorsroundr.com/office-api/overview/agent-overview',
    
    APPOINTMENT_LISTING_API_URL:'https://admin.realtorsroundr.com/office-api/appointment',
    IMAGE_URL:"https://api.realtorsroundr.com/roundr-api/img/users/",
    PROPERTIES_LIST:'https://admin.realtorsroundr.com/office-api/properties/',
    PROPERTY_IMAGE_URL:'https://api.realtorsroundr.com/roundr-api/img/property/',
    SOUND_URL:'https://api.realtorsroundr.com/roundr-api/img/sound/',
    SOUND_URL_COMMENT:'https://api.realtorsroundr.com/roundr-api/img/appoint_comment/',
    PROPERTIES_FILTER:'https://admin.realtorsroundr.com/office-api/properties/date-filter/',
    APPOINTMENT_FILTER:'https://admin.realtorsroundr.com/office-api/appointment/date-filter',
    PROPERTY_DETAIL:'https://admin.realtorsroundr.com/office-api/overview/property-card',
    AGENT_CARD:'https://admin.realtorsroundr.com/office-api/overview/agent-card',
    FILTER_PROPERTY_LIST: 'https://admin.realtorsroundr.com/office-api/overview/agent-card-clients',
    AGENT_PERFORMANCE: 'https://admin.realtorsroundr.com/office-api/overview/agent-performance-overview',
    AGENT_CARD_APPT:'https://admin.realtorsroundr.com/office-api/overview/agent-card-appt',
    AGENT_CARD_CALL_LOGS:'https://api.realtorsroundr.com/roundr-api/'+api_version+'/cold_calling.php?action=get-call-log',
    EXPORT_COLD_CALL:'https://admin.realtorsroundr.com/office-api/overview/cold-call-report',
    AGENT_STOCK_PIPELINE:'https://api.realtorsroundr.com/roundr-api/'+api_version+'/stock_pipeline.php?action=get-stock-pipeline',
    STOCKPIPLINE_APPOINTMENT:'https://admin.realtorsroundr.com/office-api/overview/stockpipline-property',
    //STOCKPIPLINE_APPOINTMENT:'https://api.realtorsroundr.com/roundr-api/'+api_version+'/general_appointment.php?action=getMyStockPipelineAppointmentList',
    AGENT_ARCHIVED_PROPERTY:'https://api.realtorsroundr.com/roundr-api/'+api_version+'/property_v4.php?action=list',
    AGENT_NETWORKING_PROPERTY:'https://admin.realtorsroundr.com/office-api/overview/agent-card-networking-listing',
    OTHER_LISTING:' https://admin.realtorsroundr.com/office-api/overview/agent_card_other_listing',
  
    AGENT_LIST:'https://admin.realtorsroundr.com/office-api/revenue',
    REVENUE_CHART_MODAL:'https://admin.realtorsroundr.com/office-api/overview/agent-card-revenue',
  
    // charts
    APPOINTMENT_CHART: 'https://admin.realtorsroundr.com/office-api/dashboard/appointment',
    GENERAL_CHART:'https://admin.realtorsroundr.com/office-api/dashboard/general_appointment',
    STOCKPIPELINE_CHART: 'https://admin.realtorsroundr.com/office-api/dashboard/stock-pipeline',
    PROPERTY_CHART: 'https://admin.realtorsroundr.com/office-api/dashboard/properties',
    SALES_CHART: 'https://admin.realtorsroundr.com/office-api/dashboard/sales',
    COLDCALL_CHART: 'https://admin.realtorsroundr.com/office-api/dashboard/cold-call',
    REVENUE_CHART: 'https://admin.realtorsroundr.com/office-api/dashboard/all_revenue',
    REVENUE_PAGE_CHART: 'https://admin.realtorsroundr.com/office-api/dashboard/revenue',
    RENTAL_REVENUE_CHART: 'https://admin.realtorsroundr.com/office-api/dashboard/revenue_rental',
    PROFITABILITY_CHART: 'https://admin.realtorsroundr.com/office-api/dashboard/profitability',
  
    //add property modal
    GET_AUTH_DATA: 'https://admin.realtorsroundr.com/office-api/MobileAPI/token',
    ADD_PROPERTY: 'https://api.realtorsroundr.com/roundr-api/'+api_version+'/property_v4.php?action=new-create',
    GET_EDIT_PROPERTY_DATA: 'https://api.realtorsroundr.com/roundr-api/'+api_version+'/property_v4.php?action=get',
    EDIT_PROPERTY: 'https://api.realtorsroundr.com/roundr-api/'+api_version+'/property_v4.php?action=edit',
    EXPORT_REPORT: 'https://api.realtorsroundr.com/roundr-api/'+api_version+'/property_v4.php',
    GET_ALL_AGENT_DATA: 'https://api.realtorsroundr.com/roundr-api/'+api_version+'/user_v4.php?action=alldata',

    MARK_AS_SOLD: 'https://api.realtorsroundr.com/roundr-api/'+api_version+'/property_v4.php?action=mark-as-sold',
    MARK_AS_RENT: 'https://api.realtorsroundr.com/roundr-api/'+api_version+'/property_v4.php?action=mark-as-rented',
    UPDATE_RENT:  'https://api.realtorsroundr.com/roundr-api/'+api_version+'/property_v4.php?action=update-rental',

    //add stock pipeline
    ADD_STOCK_PIPELINE: 'https://admin.realtorsroundr.com/office-api/StockPipeline/add',
    UPDATE_STOCK_PIPELINE: 'https://admin.realtorsroundr.com/office-api/StockPipeline/update',
    
    //Transaction log
    GET_SUBCRIPTION_DETAIL: 'https://admin.realtorsroundr.com/office-api/paddle-api/get-subscription-detail',
    PAYMENT_CALLBACK: 'https://admin.realtorsroundr.com/office-api/paddle-api/payment-callback',
    PAYMENT_TRANSACTION_LOG: 'https://admin.realtorsroundr.com/office-api/paddle-api/transaction-log',
    GET_PRICING_PLAN_ID: "https://admin.realtorsroundr.com/office-api/paddle-api/get-subscription-plan-details",
    UPGRADE_SUBSCRIPTION_QUANTITY: 'https://admin.realtorsroundr.com/office-api/paddle-api/upgrade-qty',
    MANAGE_SUBSCRIPTION_EMAIL: 'https://api.realtorsroundr.com/roundr-api/'+api_version+'/office_payment.php?action=send_email',

    //Buyer Lead
    //ADD_BUYER_LEAD: 'https://api.realtorsroundr.com/roundr-api/external/buyerlead',
    //ADD_BUYER_LEAD: 'https://admin.realtorsroundr.com/office-api/buyerLead/create',
    //GET_BUYER_LEAD: 'https://admin.realtorsroundr.com/office-api/buyerLead',
    //GET_USER_BUYER_LEAD: 'https://admin.realtorsroundr.com/office-api/buyerLead/lead_details',
    //EDIT_BUYER_LEAD: 'https://admin.realtorsroundr.com/office-api/buyerLead/update_lead_details'
    
    //Settings
    UPDATE_SETTINGS: 'https://admin.realtorsroundr.com/office-api/settings/update',
    
    //Contact
    GET_CONTACT: 'https://admin.realtorsroundr.com/office-api/contact',
    CREATE_CONTACT: 'https://admin.realtorsroundr.com/office-api/contact/create',
    GET_CONTACT_DETAILS: 'https://admin.realtorsroundr.com/office-api/contact/contact_details',
    UPDATE_CONTACT_DETAILS: 'https://admin.realtorsroundr.com/office-api/contact/update_contact_detail',
  
    //add appointment
    CREATE_VIEWING_APPOINTMENT: 'https://api.realtorsroundr.com/roundr-api/'+api_version+'/appointment_v4.php?action=createViewing',
    CREATE_GENERAL_APPOINTMENT: 'https://api.realtorsroundr.com/roundr-api/'+api_version+'/general_appointment.php?action=create',
    //edit appointment
    GET_VIEWING_APPOINTMENT: 'https://api.realtorsroundr.com/roundr-api/'+api_version+'/appointment_v4.php?action=get',
    UPDATE_VIEWING_APPOINTMENT: 'https://api.realtorsroundr.com/roundr-api/'+api_version+'/appointment_v4.php?action=updatetime',
    GET_GENERAL_APPOINTMENT: 'https://api.realtorsroundr.com/roundr-api/'+api_version+'/general_appointment.php?action=generalAppointmentDetail',
    UPDATE_GENERAL_APPOINTMENT: 'https://api.realtorsroundr.com/roundr-api/'+api_version+'/general_appointment.php?action=edit',
  
    //travel logbook
    GET_UPCOMING_TRIPS: 'https://api.realtorsroundr.com/roundr-api/'+api_version+'/travellogbook.php?action=get-upcoming-trips',
    GET_PAST_TRIPS: 'https://api.realtorsroundr.com/roundr-api/'+api_version+'/travellogbook.php?action=get-past-trips',
    GET_MISSED_TRIPS: 'https://api.realtorsroundr.com/roundr-api/'+api_version+'/travellogbook.php?action=get-missed-trips',
    GET_TRAVEL_REPORT: 'https://api.realtorsroundr.com/roundr-api/'+api_version+'/travellogbook.php?action=download-report',
  }
}else if(preference === 2){
  Constants = {
    SEARCH_MOVIES: 'SEARCH_MOVIES',
    SHOW_MOVIE_RESULTS: 'SHOW_MOVIE_RESULTS',
    USER_LOGIN:'USER_LOGIN',
    ADMINLOGIN: 'https://localhost/roundr-admin-ci/admin/OfficePortal/access_request',
    LOGIN_API_URL:'http://localhost/roundr-admin-ci/office-api/login',
    AGENT_LISTING_API_URL:'http://localhost/roundr-admin-ci/office-api/overview',
    AGENT_OVERVIEW_LISTING_API_URL:'http://localhost/roundr-admin-ci/office-api/overview/agent-overview',
    
    APPOINTMENT_LISTING_API_URL:'http://localhost/roundr-admin-ci/office-api/appointment',
    PROPERTIES_LIST:'http://localhost/roundr-admin-ci/office-api/properties/',
    PROPERTIES_FILTER:'http://localhost/roundr-admin-ci/office-api/properties/date-filter/',
    APPOINTMENT_FILTER:'http://localhost/roundr-admin-ci/office-api/appointment/date-filter',
    PROPERTY_DETAIL:'http://localhost/roundr-admin-ci/office-api/overview/property-card',
    AGENT_CARD:'http://localhost/roundr-admin-ci/office-api/overview/agent-card',
    FILTER_PROPERTY_LIST: 'http://localhost/roundr-admin-ci/office-api/overview/agent-card-clients',
    AGENT_PERFORMANCE: 'http://localhost/roundr-admin-ci/office-api/overview/agent-performance-overview',
    AGENT_CARD_APPT:'http://localhost/roundr-admin-ci/office-api/overview/agent-card-appt',
    AGENT_CARD_CALL_LOGS:'https://staging-api.realtorsroundr.com/'+api_version+'/cold_calling.php?action=get-call-log',
    EXPORT_COLD_CALL:'http://localhost/roundr-admin-ci/office-api/overview/cold-call-report',
    AGENT_STOCK_PIPELINE:'https://staging-api.realtorsroundr.com/'+api_version+'/stock_pipeline.php?action=get-stock-pipeline',
    STOCKPIPLINE_APPOINTMENT:'http://localhost/roundr-admin-ci/office-api/overview/stockpipline-property',
    //STOCKPIPLINE_APPOINTMENT:'https://staging-api.realtorsroundr.com/'+api_version+'/general_appointment.php?action=getMyStockPipelineAppointmentList',
    AGENT_ARCHIVED_PROPERTY:'https://staging-api.realtorsroundr.com/'+api_version+'/property_v4.php?action=list',
    AGENT_NETWORKING_PROPERTY:'http://localhost/roundr-admin-ci/office-api/overview/agent-card-networking-listing',
    OTHER_LISTING:' http://localhost/roundr-admin-ci/office-api/overview/agent_card_other_listing',
    AGENT_LIST:'http://localhost/roundr-admin-ci/office-api/revenue',
    REVENUE_CHART_MODAL:'http://localhost/roundr-admin-ci/office-api/overview/agent-card-revenue',
    // charts
    APPOINTMENT_CHART: 'http://localhost/roundr-admin-ci/office-api/dashboard/appointment',
    GENERAL_CHART:'http://localhost/roundr-admin-ci/office-api/dashboard/general_appointment',
    STOCKPIPELINE_CHART: 'http://localhost/roundr-admin-ci/office-api/dashboard/stock-pipeline',
    PROPERTY_CHART: 'http://localhost/roundr-admin-ci/office-api/dashboard/properties',
    SALES_CHART: 'http://localhost/roundr-admin-ci/office-api/dashboard/sales',
    COLDCALL_CHART: 'http://localhost/roundr-admin-ci/office-api/dashboard/cold-call',
    REVENUE_CHART: 'http://localhost/roundr-admin-ci/office-api/dashboard/all_revenue',
    REVENUE_PAGE_CHART: 'http://localhost/roundr-admin-ci/office-api/dashboard/revenue',
    RENTAL_REVENUE_CHART: 'http://localhost/roundr-admin-ci/office-api/dashboard/revenue_rental',
    PROFITABILITY_CHART: 'http://localhost/roundr-admin-ci/office-api/dashboard/profitability',
  
    //IMAGE_URL:"https://staging-api.realtorsroundr.com/img/users/",
    IMAGE_URL:"https://api.realtorsroundr.com/roundr-api/img/users/",
    PROPERTY_IMAGE_URL:'https://staging-api.realtorsroundr.com/img/property/',
    SOUND_URL:'https://staging-api.realtorsroundr.com/img/sound/',
    SOUND_URL_COMMENT:'https://staging-api.realtorsroundr.com/img/appoint_comment/',
    //add property modal
    GET_AUTH_DATA: 'http://localhost/roundr-admin-ci/office-api/MobileAPI/token',
    
    ADD_PROPERTY: 'https://staging-api.realtorsroundr.com/'+api_version+'/property_v4.php?action=new-create',
    GET_EDIT_PROPERTY_DATA: 'https://staging-api.realtorsroundr.com/'+api_version+'/property_v4.php?action=get',
    EDIT_PROPERTY: 'https://staging-api.realtorsroundr.com/'+api_version+'/property_v4.php?action=edit',
    EXPORT_REPORT: 'https://staging-api.realtorsroundr.com/'+api_version+'/property_v4.php',
    
    GET_ALL_AGENT_DATA: 'https://staging-api.realtorsroundr.com/'+api_version+'/user_v4.php?action=alldata',
    MARK_AS_SOLD: 'https://staging-api.realtorsroundr.com/'+api_version+'/property_v4.php?action=mark-as-sold',
    MARK_AS_RENT: 'https://staging-api.realtorsroundr.com/'+api_version+'/property_v4.php?action=mark-as-rented',
    UPDATE_RENT:  'https://staging-api.realtorsroundr.com/'+api_version+'/property_v4.php?action=update-rental',

    //add stock pipeline
    ADD_STOCK_PIPELINE: 'http://localhost/roundr-admin-ci/office-api/StockPipeline/add',
    UPDATE_STOCK_PIPELINE: 'http://localhost/roundr-admin-ci/office-api/StockPipeline/update',

    //Transaction log
    //: 'https://admin.realtorsroundr.com/office-api/paddle-api/subscription-user',
    PAYMENT_TRANSACTION_LOG: 'https://admin.realtorsroundr.com/office-api/paddle-api/transaction-log',
    GET_SUBCRIPTION_DETAIL: "https://staging-admin.realtorsroundr.com/office-api/paddle-api/get-subscription-detail",
    GET_PRICING_PLAN_ID: "https://staging-admin.realtorsroundr.com/office-api/paddle-api/get-subscription-plan-details",

    // PAYMENT_USER_DATA: 'https://staging-admin.realtorsroundr.com/office-api/paddle-api/subscription-user',
    // PAYMENT_TRANSACTION_LOG: 'https://staging-admin.realtorsroundr.com/office-api/paddle-api/transaction-log',
    MANAGE_SUBSCRIPTION_EMAIL: 'https://staging-api.realtorsroundr.com//'+api_version+'/office_payment.php?action=send_email',
    
    //Buyer Lead
    //ADD_BUYER_LEAD: 'https://staging-api.realtorsroundr.com/external/buyerlead',
    
    // ADD_BUYER_LEAD: 'https://staging-admin.realtorsroundr.com/office-api/buyerLead/create',
    // GET_BUYER_LEAD: 'http://localhost/roundr-admin-ci/office-api/buyerLead',
    // GET_USER_BUYER_LEAD: 'http://localhost/roundr-admin-ci/office-api/buyerLead/lead_details',
    // EDIT_BUYER_LEAD: 'http://localhost/roundr-admin-ci/office-api/buyerLead/update_lead_details'
    //Settings
    UPDATE_SETTINGS: 'http://localhost/roundr-admin-ci/office-api/settings/update',
    //Contact
    GET_CONTACT: 'http://localhost/roundr-admin-ci/office-api/contact',
    CREATE_CONTACT: 'http://localhost/roundr-admin-ci/office-api/contact/create',
    GET_CONTACT_DETAILS: 'http://localhost/roundr-admin-ci/office-api/contact/contact_details',
    UPDATE_CONTACT_DETAILS: 'http://localhost/roundr-admin-ci/office-api/contact/update_contact_detail',
    
    //add appointment
    CREATE_VIEWING_APPOINTMENT: 'https://staging-api.realtorsroundr.com/'+api_version+'/appointment_v4.php?action=createViewing',
    CREATE_GENERAL_APPOINTMENT: 'https://staging-api.realtorsroundr.com/'+api_version+'/general_appointment.php?action=create',
    //edit appointment
    GET_VIEWING_APPOINTMENT: 'https://staging-api.realtorsroundr.com/'+api_version+'/appointment_v4.php?action=get',
    UPDATE_VIEWING_APPOINTMENT: 'https://staging-api.realtorsroundr.com/'+api_version+'/appointment_v4.php?action=request_change',
    GET_GENERAL_APPOINTMENT: 'https://staging-api.realtorsroundr.com/'+api_version+'/general_appointment.php?action=generalAppointmentDetail',
    UPDATE_GENERAL_APPOINTMENT: 'https://staging-api.realtorsroundr.com/'+api_version+'/general_appointment.php?action=edit',
    
    //travel logbook
    GET_UPCOMING_TRIPS: 'https://staging-api.realtorsroundr.com/'+api_version+'/travellogbook.php?action=get-upcoming-trips',
    GET_PAST_TRIPS: 'https://staging-api.realtorsroundr.com/'+api_version+'/travellogbook.php?action=get-past-trips',
    GET_MISSED_TRIPS: 'https://staging-api.realtorsroundr.com/'+api_version+'/travellogbook.php?action=get-missed-trips',
    GET_TRAVEL_REPORT: 'https://staging-api.realtorsroundr.com/'+api_version+'/travellogbook.php?action=download-report',
  }
}else if(preference === 3 || window.location.origin.toString() === "https://staging-office.roundr.app"){
  Constants = {
    SEARCH_MOVIES: 'SEARCH_MOVIES',
    SHOW_MOVIE_RESULTS: 'SHOW_MOVIE_RESULTS',
    USER_LOGIN:'USER_LOGIN',
    ADMINLOGIN: 'https://staging-admin.realtorsroundr.com/admin/OfficePortal/access_request',
    LOGIN_API_URL:'https://staging-admin.realtorsroundr.com/office-api/login',
    //LOGIN_API_URL:'http://192.168.64.2/staging-roundr-admin/office-api/login',
    AGENT_LISTING_API_URL:'https://staging-admin.realtorsroundr.com/office-api/overview',
    AGENT_OVERVIEW_LISTING_API_URL:'https://staging-admin.realtorsroundr.com/office-api/overview/agent-overview',
    
    APPOINTMENT_LISTING_API_URL:'https://staging-admin.realtorsroundr.com/office-api/appointment',
    PROPERTIES_LIST:'https://staging-admin.realtorsroundr.com/office-api/properties/',
    PROPERTIES_FILTER:'https://staging-admin.realtorsroundr.com/office-api/properties/date-filter/',
    APPOINTMENT_FILTER:'https://staging-admin.realtorsroundr.com/office-api/appointment/date-filter',
    PROPERTY_DETAIL:'https://staging-admin.realtorsroundr.com/office-api/overview/property-card',
    AGENT_CARD:'https://staging-admin.realtorsroundr.com/office-api/overview/agent-card',
    FILTER_PROPERTY_LIST: 'https://staging-admin.realtorsroundr.com/office-api/overview/agent-card-clients',
    AGENT_PERFORMANCE: 'https://staging-admin.realtorsroundr.com/office-api/overview/agent-performance-overview',
    AGENT_CARD_APPT:'https://staging-admin.realtorsroundr.com/office-api/overview/agent-card-appt',
    AGENT_CARD_CALL_LOGS:'https://staging-api.realtorsroundr.com/'+api_version+'/cold_calling.php?action=get-call-log',
    EXPORT_COLD_CALL:'https://staging-admin.realtorsroundr.com/office-api/overview/cold-call-report',
    AGENT_STOCK_PIPELINE:'https://staging-api.realtorsroundr.com/'+api_version+'/stock_pipeline.php?action=get-stock-pipeline',
    STOCKPIPLINE_APPOINTMENT:'https://staging-admin.realtorsroundr.com/office-api/overview/stockpipline-property',
    //STOCKPIPLINE_APPOINTMENT:'https://staging-api.realtorsroundr.com/'+api_version+'/general_appointment.php?action=getMyStockPipelineAppointmentList',
    AGENT_ARCHIVED_PROPERTY:'https://staging-api.realtorsroundr.com/'+api_version+'/property_v4.php?action=list',
    AGENT_NETWORKING_PROPERTY:'https://staging-admin.realtorsroundr.com/office-api/overview/agent-card-networking-listing',
    OTHER_LISTING:' https://staging-admin.realtorsroundr.com/office-api/overview/agent_card_other_listing',
    AGENT_LIST:'https://staging-admin.realtorsroundr.com/office-api/revenue',
    REVENUE_CHART_MODAL:'https://staging-admin.realtorsroundr.com/office-api/overview/agent-card-revenue',
    // charts
    APPOINTMENT_CHART: 'https://staging-admin.realtorsroundr.com/office-api/dashboard/appointment',
    GENERAL_CHART:'https://staging-admin.realtorsroundr.com/office-api/dashboard/general_appointment',
    STOCKPIPELINE_CHART: 'https://staging-admin.realtorsroundr.com/office-api/dashboard/stock-pipeline',
    PROPERTY_CHART: 'https://staging-admin.realtorsroundr.com/office-api/dashboard/properties',
    SALES_CHART: 'https://staging-admin.realtorsroundr.com/office-api/dashboard/sales',
    COLDCALL_CHART: 'https://staging-admin.realtorsroundr.com/office-api/dashboard/cold-call',
    REVENUE_CHART: 'https://staging-admin.realtorsroundr.com/office-api/dashboard/all_revenue',
    REVENUE_PAGE_CHART: 'https://staging-admin.realtorsroundr.com/office-api/dashboard/revenue',
    RENTAL_REVENUE_CHART: 'https://staging-admin.realtorsroundr.com/office-api/dashboard/revenue_rental',
    PROFITABILITY_CHART: 'https://staging-admin.realtorsroundr.com/office-api/dashboard/profitability',
  
    IMAGE_URL:"https://staging-api.realtorsroundr.com/img/users/",
    PROPERTY_IMAGE_URL:'https://staging-api.realtorsroundr.com/img/property/',
    SOUND_URL:'https://staging-api.realtorsroundr.com/img/sound/',
    SOUND_URL_COMMENT:'https://staging-api.realtorsroundr.com/img/appoint_comment/',
    //add property modal
    GET_AUTH_DATA: 'https://staging-admin.realtorsroundr.com/office-api/MobileAPI/token',
    
    ADD_PROPERTY: 'https://staging-api.realtorsroundr.com/'+api_version+'/property_v4.php?action=new-create',
    GET_EDIT_PROPERTY_DATA: 'https://staging-api.realtorsroundr.com/'+api_version+'/property_v4.php?action=get',
    EDIT_PROPERTY: 'https://staging-api.realtorsroundr.com/'+api_version+'/property_v4.php?action=edit',
    EXPORT_REPORT: 'https://staging-api.realtorsroundr.com/'+api_version+'/property_v4.php',
    
    GET_ALL_AGENT_DATA: 'https://staging-api.realtorsroundr.com/'+api_version+'/user_v4.php?action=alldata',
    MARK_AS_SOLD: 'https://staging-api.realtorsroundr.com/'+api_version+'/property_v4.php?action=mark-as-sold',
    MARK_AS_RENT: 'https://staging-api.realtorsroundr.com/'+api_version+'/property_v4.php?action=mark-as-rented',
    UPDATE_RENT:  'https://staging-api.realtorsroundr.com/'+api_version+'/property_v4.php?action=update-rental',

    //add stock pipeline
    ADD_STOCK_PIPELINE: 'https://staging-admin.realtorsroundr.com/office-api/StockPipeline/add',
    UPDATE_STOCK_PIPELINE: 'https://staging-admin.realtorsroundr.com/office-api/StockPipeline/update',

    //Transaction log
    GET_SUBCRIPTION_DETAIL: "https://staging-admin.realtorsroundr.com/office-api/paddle-api/get-subscription-detail",    
    PAYMENT_CALLBACK: 'https://staging-admin.realtorsroundr.com/office-api/paddle-api/payment-callback',
    PAYMENT_TRANSACTION_LOG: 'https://staging-admin.realtorsroundr.com/office-api/paddle-api/transaction-log',
    UPGRADE_SUBSCRIPTION_QUANTITY: 'https://staging-admin.realtorsroundr.com/office-api/paddle-api/upgrade-qty',
    GET_PRICING_PLAN_ID: "https://staging-admin.realtorsroundr.com/office-api/paddle-api/get-subscription-plan-details",
    MANAGE_SUBSCRIPTION_EMAIL: 'https://staging-api.realtorsroundr.com//'+api_version+'/office_payment.php?action=send_email',
    
    //Buyer Lead
    //ADD_BUYER_LEAD: 'https://staging-api.realtorsroundr.com/external/buyerlead',
    
    // ADD_BUYER_LEAD: 'https://staging-admin.realtorsroundr.com/office-api/buyerLead/create',
    // GET_BUYER_LEAD: 'https://staging-admin.realtorsroundr.com/office-api/buyerLead',
    // GET_USER_BUYER_LEAD: 'https://staging-admin.realtorsroundr.com/office-api/buyerLead/lead_details',
    // EDIT_BUYER_LEAD: 'https://staging-admin.realtorsroundr.com/office-api/buyerLead/update_lead_details'
    
    //Settings
    UPDATE_SETTINGS: 'https://staging-admin.realtorsroundr.com/office-api/settings/update',
    
    //Contact
    GET_CONTACT: 'https://staging-admin.realtorsroundr.com/office-api/contact',
    CREATE_CONTACT: 'https://staging-admin.realtorsroundr.com/office-api/contact/create',
    GET_CONTACT_DETAILS: 'https://staging-admin.realtorsroundr.com/office-api/contact/contact_details',
    UPDATE_CONTACT_DETAILS: 'https://staging-admin.realtorsroundr.com/office-api/contact/update_contact_detail',
    
    //add appointment
    CREATE_VIEWING_APPOINTMENT: 'https://staging-api.realtorsroundr.com/'+api_version+'/appointment_v4.php?action=createViewing',
    CREATE_GENERAL_APPOINTMENT: 'https://staging-api.realtorsroundr.com/'+api_version+'/general_appointment.php?action=create',
    //edit appointment
    GET_VIEWING_APPOINTMENT: 'https://staging-api.realtorsroundr.com/'+api_version+'/appointment_v4.php?action=get',
    UPDATE_VIEWING_APPOINTMENT: 'https://staging-api.realtorsroundr.com/'+api_version+'/appointment_v4.php?action=request_change',
    GET_GENERAL_APPOINTMENT: 'https://staging-api.realtorsroundr.com/'+api_version+'/general_appointment.php?action=generalAppointmentDetail',
    UPDATE_GENERAL_APPOINTMENT: 'https://staging-api.realtorsroundr.com/'+api_version+'/general_appointment.php?action=edit',
    
    //travel logbook
    GET_UPCOMING_TRIPS: 'https://staging-api.realtorsroundr.com/'+api_version+'/travellogbook.php?action=get-upcoming-trips',
    GET_PAST_TRIPS: 'https://staging-api.realtorsroundr.com/'+api_version+'/travellogbook.php?action=get-past-trips',
    GET_MISSED_TRIPS: 'https://staging-api.realtorsroundr.com/'+api_version+'/travellogbook.php?action=get-missed-trips',
    GET_TRAVEL_REPORT: 'https://staging-api.realtorsroundr.com/'+api_version+'/travellogbook.php?action=download-report',
  }
}
export default Constants;
