import * as actionTypes from '../actions/actionsTypes'; 
import {updateObject} from '../utility';

const initialState = {
    settings_form_status: null,
    settings_form_msg: null,
}

const get_update_settings = (state, action) => {
    return updateObject(state,{
        settings_form_status: action.settings_form_status,
        settings_form_msg: action.settings_form_msg
    })
}

function settings_reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.UPDATE_SETTINGS: return get_update_settings(state, action);
        default:
        return state;
    }
}

export default settings_reducer;
