import React,{ Component } from "react";
import {connect} from 'react-redux';
import * as actions from '../store/actions/index';

import Constants from '../constants/Constants';
import ReactTooltip from "react-tooltip";
//import { Calendar } from 'primereact/calendar';
//import Loader from '../Loader';
import { Icon } from '@iconify/react';
import bxsUser from '@iconify/icons-bx/bxs-user';
//import informationIcon from '@iconify/icons-mdi/information';
import $ from 'jquery';
import DateRange from "./DateRange";

var colorList = ['#7B8CA1','#EA676A','#63C8BA','#F8CA55','#F09A56','#6ADA8A', '#9B66E3', '#547DE5', '#B5A78D', '#DE6DA8',
  '#A368D9','#B43B1D','#229649','#2C3E50','#8c9966','#53F49B','#DC9D7D','#660E52','#39C9AE','#BCA7C4',
  '#D08E27','#A3DB7D','#606792','#11E797','#1DC1C1','#D5C175','#1DC1C1','#421B6C','#98E708','#EE2A09',  
  '#017C33','#24F080','#CC5A45','#801FD4','#04405D','#133123','#1B42B1','#7EC377','#2DE6E0','#9FCE27',
  '#F96CDD','#41197D','#4A809B','#794D61','#BA28E3','#183A8C','#D07848','#4F3B87','#3A8947','#100769',
  '#B7931E','#AA0A71','#417A92','#AC5B6C','#7E8F92','#C06EA7','#866E50','#561C56','#DB3DAF','#F03C44',
  '#90A8F4','#93AC64','#5B532B','#1CBB4B','#05A5A5','#CEC21E','#D3A8FF','#3F4D5E','#BD0B49','#2CA6A0',
  '#C7B541','#6D8D6B','#70C29B','#0B0BE2','#EE19DA','#588546','#DBB5A2','#609CBF','#38198D','#904B6C',
  '#8BD18C','#32638D','#792A4F','#7184E1','#35A50F','#4B3871','#8C8007','#4B2257','#089564','#F1A0C1',
  '#0D9168','#8CA06C','#0A7EB7','#AB53CB','#43246A','#2F0020','#06759B','#785646','#B36935','#1A7607',
];
var globalAgentList = [];
var globalAgentListName = [];
var globalAgentListImage = [];
let agent_list = [];
let non_active_agent_buttons = [];
if(localStorage.getItem("non_active_agent_buttons") === null) {
  localStorage.setItem('non_active_agent_buttons', []);
}else{
  non_active_agent_buttons = localStorage.getItem('non_active_agent_buttons') ? JSON.parse(localStorage.getItem('non_active_agent_buttons')) : null;
}
class AgentList extends Component{
  constructor(props) {
      super(props);
      this.AgentInformationModal = React.createRef()
      this.state = {
        blocking:  false,
        startDate: new Date(new Date().getFullYear(), 0, 1),
        endDate:   new Date(new Date().getFullYear(), 11, 31),
        agent_id:  null,
        renderAgentButton: 0,
        CalenderMinDate: new Date(new Date().getFullYear(), 0, 1),
        select_all: false
      }
      this.changeDateFormat = this.changeDateFormat.bind(this);
  }

  sendData = (globalAgentList, globalAgentListName, globalAgentListImage) => {
    this.props.parentCallback(globalAgentList, globalAgentListName, globalAgentListImage);
  }

  componentDidMount = () => {
    const token = localStorage.getItem("token");
    if(!token){
      localStorage.clear();
      this.props.history.push("/");
      return;
    }

    var date = new Date();
    var month = date.getMonth();
    var year = date.getFullYear();
    
    var lastOfMonth = new Date(new Date().getFullYear(), 11, 31, 0, 0, 0);
    
    var d = new Date(lastOfMonth);
    month = '' + (d.getMonth() + 1);
    var day = '' + d.getDate();
    year = d.getFullYear();
    
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    var toDate = [year, month, day].join('-');
    
    d = new Date(this.state.startDate);
    month = '' + (d.getMonth() + 1);
    day = '' + d.getDate();
    year = d.getFullYear();
    
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    
    var fromDate = [year, month, day].join('-');
    var fromDate_property = [year-1, 12, "01"].join('-');

    d = new Date();
    month = '' + (d.getMonth() + 1);
    day = '' + d.getDate();
    year = d.getFullYear();
  
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    var toDate_stockpipeline = [year, month, day].join('-');
    //agent_list = JSON.parse(localStorage.getItem('data')) ? JSON.parse(localStorage.getItem('data')) : this.props.data ; 
    let agent_list = this.props.data;
    if(window.location.pathname === "/overview"){
      if(agent_list){
        var sale_type = "";
        var sold_type = "";
        var type = "sales";
        this.props.get_property_chart(fromDate_property, toDate_stockpipeline, agent_list, token);
        sale_type = this.props.sale_type;
        sold_type = this.props.sold_type;
        this.props.get_property_sale_chart(fromDate_property, toDate_stockpipeline, sale_type, agent_list, token);
        this.props.get_property_sale_chart(fromDate_property, toDate_stockpipeline, sold_type, agent_list, token);
        this.props.get_stockpipeline_chart(fromDate_property, toDate_stockpipeline, agent_list, token);
        this.props.get_appointment_chart(fromDate, toDate, agent_list, token);
        this.props.get_general_chart(fromDate, toDate, agent_list, token);
        this.props.get_sales_chart(fromDate, toDate, agent_list, token);
        this.props.get_coldcalls_chart(fromDate_property, toDate_stockpipeline, agent_list, token);
        this.props.get_revenue_chart(fromDate, toDate, type, agent_list, token);
        this.props.get_rental_revenue_chart(fromDate, toDate, agent_list, token);
        this.props.get_profitability_chart(fromDate, toDate, agent_list, token);
        this.props.change_from_to_date(fromDate, toDate)
      }
      this.props.parentCallbackSetDates(fromDate, toDate);
    }
    this.setState({ renderAgentButton: 1 });
  }

  toDateOverview = (e) => {
    var fromDate = null;
    var toDate = null;
    this.setState({ renderAgentButton: 1})
    
    const token = this.props.token;
    if (token === '' || token === null) {
      sessionStorage.clear();
      this.props.history.push("/");
      return;
    }
    
    if(e.target.name === "from_date"){
      fromDate = this.changeDateFormat(e.value);
      toDate = this.changeDateFormat(this.state.endDate);
    }else{
      fromDate = this.changeDateFormat(this.state.startDate);
      toDate = this.changeDateFormat(e.value);
    }
    
    //for fixed Stock pipeline and cold calls toDate 
    let d = new Date();
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();
  
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    let toDate_stockpipeline = [year, month, day].join('-');
    
    //let to = new Date(toDate);
    //if(to < d){
    //  toDate_stockpipeline = toDate;
    //}

    //from date for property, stock pipeline and cold call graphs
    d = new Date();
    month = '' + (d.getMonth() + 1);
    day = '' + d.getDate();
    year = d.getFullYear();
    var fromDate_property = [year-1, 12, "01"].join('-');

    this.setState({ blocking: true })

    agent_list = this.props.data ? this.props.data : agent_list;
    if(window.location.pathname === "/overview"){ 
      if(agent_list){
        this.props.get_overview_data_with_date(fromDate, toDate, token);
        this.setState({ blocking: false });
        var sale_type = "";
        var sold_type = "";
        var type = this.props.revenue_type;
        this.props.get_property_chart(fromDate_property, toDate_stockpipeline, agent_list, token);
        sale_type = this.props.sale_type;
        sold_type = this.props.sold_type;
        this.props.get_property_sale_chart(fromDate_property, toDate_stockpipeline, sale_type, agent_list, token);
        this.props.get_property_sale_chart(fromDate_property, toDate_stockpipeline, sold_type, agent_list, token);
        this.props.get_stockpipeline_chart(fromDate_property, toDate_stockpipeline, agent_list, token);
        this.props.get_appointment_chart(fromDate, toDate, agent_list, token);
        this.props.get_general_chart(fromDate, toDate, agent_list, token);
        this.props.get_sales_chart(fromDate, toDate, agent_list, token);
        this.props.get_coldcalls_chart(fromDate_property, toDate_stockpipeline, agent_list, token);
        this.props.get_revenue_chart(fromDate, toDate, type, agent_list, token);
        this.props.get_rental_revenue_chart(fromDate, toDate, agent_list, token);
        this.props.get_profitability_chart(fromDate, toDate, agent_list, token);
        this.props.change_from_to_date(fromDate, toDate)
        this.setState({ renderAgentButton: 1 });
        this.props.parentCallbackSetDates(fromDate, toDate);
      }
    }else if(window.location.pathname === "/properties"){
      this.props.get_property_chart_property(fromDate_property, toDate_stockpipeline, token);
      this.props.change_from_to_date(fromDate_property, toDate_stockpipeline)
    }else if(window.location.pathname === "/viewings"){
      this.props.get_filtered_agent_list_appointment(fromDate, toDate, token);
      this.props.get_appointment_chart_appointment(fromDate, toDate, agent_list, token);
      this.props.get_general_chart(fromDate, toDate, agent_list, token);
      this.props.change_from_to_date(fromDate, toDate);
    }else if(window.location.pathname === "/revenue"){
      this.props.get_revenue_chart_revenue(fromDate, toDate, agent_list, token);
      this.props.change_from_to_date(fromDate, toDate);
    }
  }

  changeDateFormat(fromDate) {
    var d = new Date(fromDate),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  renderAgentButton () {
    if (this.state.renderAgentButton === "1" || this.state.renderAgentButton === 1) {
      var agentList = this.props.data ? this.props.data : this.props.agent_list;
      var agentLength = agentList.length;
      var agentButttonListing = [];

      if (agentLength === 0) {
        return (
          <center>
            <img src="images/loader.svg" width="50" height="50" alt="" />
          </center>
        );
      } else {
        globalAgentListName = [];
        for (let i = 0; i < agentLength; i++) {
          var color = "#" + ((1 << 24) * Math.random() | 0).toString(16);
          color  =  colorList[i];
          globalAgentList.push(agentList[i].id)
          var agentName = agentList[i].firstname + ' ' + agentList[i].lastname;
          globalAgentListImage.push(agentList[i].image)
          
          agentButttonListing.push( 
            <button key={i} id={agentList[i].id} 
              data-tip={Number(agentList[i].tracking_prefrence) === Number(0) ? "Tracking is off" : "Show / Hide"} 
              data-for="registerTip"
              data-place ="top"
              // disabled={Number(agentList[i].tracking_prefrence) === Number(0) ? true : false }
              onClick={() => Number(agentList[i].tracking_prefrence) === Number(0) ? false  : this.toggleAgentHandler(agentList[i].id) } 
              className={Number(agentList[i].tracking_prefrence) === Number(0) ? "btn agentsButton fadebutton disablebtn": localStorage.getItem('non_active_agent_buttons') ? [localStorage.getItem('non_active_agent_buttons').includes(agentList[i].id) ? "btn agentsButton btn fadebutton" : "btn agentsButton btn"] : "btn agentsButton btn"} 
              style={{ color: 'white', backgroundColor: color, borderColor: color, padding:"3px 5px"}}
            >
              <ReactTooltip id="registerTip" backgroundColor={'#0B2C4C'}></ReactTooltip>

              <img alt="" src={agentList[i].image ? Constants.IMAGE_URL + agentList[i].image : "user-images/userDefault.png"} className="img-circle" width="20" height="20" />
              
              <span className="fontInter fontWeight600 fontSize11 padLeft5">{agentList[i].firstname}</span>
            </button>
          )
          
          globalAgentListName.push({
            agent_id: agentList[i].id,
            name: agentName,
            color: color
          })
          localStorage.setItem('data',JSON.stringify(agentList));
          this.sendData(globalAgentList, globalAgentListName, globalAgentListImage);
        }
        return agentButttonListing;
      }
    } else {
      return (
        <center>
          <img src="images/loader.svg" width="50" height="50" alt="" />
        </center>
      );
    }
  } 
  
  toggleAgentHandler  = (agent_id)=>{    
    //Appointment Chart Toggle
    var element = document.getElementById(agent_id);
    element.classList.toggle("fadebutton");

    if(window.location.pathname === "/overview"){ 
      this.props.toggle_property_chart(agent_id);
      this.props.toggle_property_sale_chart(agent_id);
      this.props.toggle_property_sold_chart(agent_id);
      this.props.toggle_stockpipeline_chart(agent_id);
      this.props.toggle_appointment_chart(agent_id);
      this.props.toggle_general_chart(agent_id);
      this.props.toggle_sales_chart(agent_id);
      this.props.toggle_coldcalls_chart(agent_id);
      this.props.toggle_revenue_chart(agent_id);
      this.props.toggle_rental_revenue_chart(agent_id);
      this.props.toggle_profitability_chart(agent_id);
    }else if(window.location.pathname === "/properties"){
      this.props.toggle_property_page_chart(agent_id);
    }else if(window.location.pathname === "/viewings"){
      this.props.toggle_appointment_page_chart(agent_id);
      this.props.toggle_appointment_page_general_chart(agent_id);
    }else if(window.location.pathname === "/revenue"){
      this.props.toggle_revenue_page_chart(agent_id);
    }
    
    if(non_active_agent_buttons){
      if(non_active_agent_buttons.includes(agent_id)){
        if(!element.classList.contains("fadebutton")){
          non_active_agent_buttons = non_active_agent_buttons.filter(item => item !== agent_id)
        }
      }else{
        if(element.classList.contains("fadebutton")){
          non_active_agent_buttons.push(agent_id);
        }
      }
    }else{
      if(element.classList.contains("fadebutton")){
        non_active_agent_buttons.push(agent_id);
      }
    }
    localStorage.setItem('non_active_agent_buttons',JSON.stringify(non_active_agent_buttons));
  }

  toggle_select_key = (agent_list) =>{
    
      if(this.state.select_all){
        //select all
        this.setState({select_all: false})
        if(window.location.pathname === "/overview"){
          agent_list.map(async (data, index)  => (
            <>
            {localStorage.getItem('non_active_agent_buttons').includes(data.user_id) ? this.props.toggle_property_chart(data.user_id) : null}
            {localStorage.getItem('non_active_agent_buttons').includes(data.user_id) ? this.props.toggle_property_sale_chart(data.user_id) : null}
            {localStorage.getItem('non_active_agent_buttons').includes(data.user_id) ? this.props.toggle_property_sold_chart(data.user_id) : null}
            {localStorage.getItem('non_active_agent_buttons').includes(data.user_id) ? this.props.toggle_stockpipeline_chart(data.user_id) : null}
            {localStorage.getItem('non_active_agent_buttons').includes(data.user_id) ? this.props.toggle_appointment_chart(data.user_id) : null}
            {localStorage.getItem('non_active_agent_buttons').includes(data.user_id) ? this.props.toggle_general_chart(data.user_id) : null}
            {localStorage.getItem('non_active_agent_buttons').includes(data.user_id) ? this.props.toggle_sales_chart(data.user_id) : null}
            {localStorage.getItem('non_active_agent_buttons').includes(data.user_id) ? this.props.toggle_coldcalls_chart(data.user_id) : null}
            {localStorage.getItem('non_active_agent_buttons').includes(data.user_id) ? this.props.toggle_revenue_chart(data.user_id) : null}
            {localStorage.getItem('non_active_agent_buttons').includes(data.user_id) ? this.props.toggle_rental_revenue_chart(data.user_id) : null}
            {localStorage.getItem('non_active_agent_buttons').includes(data.user_id) ? this.props.toggle_profitability_chart(data.user_id) : null}
            </>
          ))
        }
        else if(window.location.pathname === "/properties"){
          agent_list.map(async (data, index)  => (
            localStorage.getItem('non_active_agent_buttons').includes(data.user_id) ? await this.props.toggle_property_page_chart(data.user_id) : null
          ))
        }else if(window.location.pathname === "/viewings"){
          agent_list.map(async (data, index)  => (
            localStorage.getItem('non_active_agent_buttons').includes(data.user_id) ? await this.props.toggle_appointment_page_chart(data.user_id) : null
          ))
          agent_list.map(async (data, index)  => (
            localStorage.getItem('non_active_agent_buttons').includes(data.user_id) ? await this.props.toggle_appointment_page_general_chart(data.user_id) : null
          ))
        }else if(window.location.pathname === "/revenue"){
          agent_list.map(async (data, index)  => (
            localStorage.getItem('non_active_agent_buttons').includes(data.user_id) ? await this.props.toggle_revenue_page_chart(data.user_id) : null
          ))    
        }
        non_active_agent_buttons = []
        localStorage.setItem('non_active_agent_buttons',JSON.stringify(non_active_agent_buttons));
        $('.agentsButton').each(function(){
          $(this).addClass('fadebutton')
        });
      }else{
        //unselect all
        this.setState({select_all: true})
        non_active_agent_buttons = []
        if(window.location.pathname === "/overview"){
          agent_list.map(async (data, index)  => (
            <>
            {!localStorage.getItem('non_active_agent_buttons').includes(data.user_id) ? this.props.toggle_property_chart(data.user_id) : null}
            {!localStorage.getItem('non_active_agent_buttons').includes(data.user_id) ? this.props.toggle_property_sale_chart(data.user_id) : null}
            {!localStorage.getItem('non_active_agent_buttons').includes(data.user_id) ? this.props.toggle_property_sold_chart(data.user_id) : null}
            {!localStorage.getItem('non_active_agent_buttons').includes(data.user_id) ? this.props.toggle_stockpipeline_chart(data.user_id) : null}
            {!localStorage.getItem('non_active_agent_buttons').includes(data.user_id) ? this.props.toggle_appointment_chart(data.user_id) : null}
            {!localStorage.getItem('non_active_agent_buttons').includes(data.user_id) ? this.props.toggle_general_chart(data.user_id) : null}
            {!localStorage.getItem('non_active_agent_buttons').includes(data.user_id) ? this.props.toggle_sales_chart(data.user_id) : null}
            {!localStorage.getItem('non_active_agent_buttons').includes(data.user_id) ? this.props.toggle_coldcalls_chart(data.user_id) : null}
            {!localStorage.getItem('non_active_agent_buttons').includes(data.user_id) ? this.props.toggle_revenue_chart(data.user_id) : null}
            {!localStorage.getItem('non_active_agent_buttons').includes(data.user_id) ? this.props.toggle_rental_revenue_chart(data.user_id) : null}
            {!localStorage.getItem('non_active_agent_buttons').includes(data.user_id) ? this.props.toggle_profitability_chart(data.user_id) : null}
            </>
          ))
        }else if(window.location.pathname === "/properties"){
          agent_list.map(async (data, index)  => (
            !localStorage.getItem('non_active_agent_buttons').includes(data.user_id) ? await this.props.toggle_property_page_chart(data.user_id) : null
          ))
        }else if(window.location.pathname === "/viewings"){
          agent_list.map(async (data, index)  => (
            !localStorage.getItem('non_active_agent_buttons').includes(data.user_id) ? await this.props.toggle_appointment_page_chart(data.user_id) : null
          ))
          agent_list.map(async (data, index)  => (
            !localStorage.getItem('non_active_agent_buttons').includes(data.user_id) ? await this.props.toggle_appointment_page_general_chart(data.user_id) : null
          ))
        }else if(window.location.pathname === "/revenue"){
          agent_list.map(async (data, index)  => (
            !localStorage.getItem('non_active_agent_buttons').includes(data.user_id) ? await this.props.toggle_revenue_page_chart(data.user_id) : null
          ))
        }
        agent_list.map( (data, index) => (
          non_active_agent_buttons.push(data.user_id)
        ))
        localStorage.setItem('non_active_agent_buttons',JSON.stringify(non_active_agent_buttons))
        $('.agentsButton').each(function(){
          $(this).removeClass('fadebutton')
        });
      }
  }

  render(){ 
    // let info_message =  "If chosen date range is less than 60 days - data is shown in days <br/>"+
    //                     "If chosen date range is 61-180 days - data is shown in weeks <br/>"+
    //                     "If chosen date range is 181-365 days - data is shown in months <br/>";
    return(
      // <section className="sticky" style={window.location.pathname === "/overview" ? {display: 'none'} : {margin: '60px 30px 0px 30px', padding: "0px 10px 0px 10px"}}>
      <section className="sticky" style={{margin: '60px 30px 0px 30px', padding: "0px 10px 0px 10px"}}>
        <div className="row">
          <div className="col-md-12" style={{ position: 'sticky', top : '30px', zIndex: '1', padding: 0}}>
            <div className="box">
              <div className="box-header ml20">
                <div className="row">
                  <div className="col-md-6">
                    <Icon icon={bxsUser} style={{color: '#4aa49c', fontSize: '24px', verticalAlign:'middle'}} />
                    <h3 className="box-title fontSize17 ml15 mt15 color0F1D30 fontWeight500 fontInter">Agents&nbsp; ({this.props.data ? this.props.data.length : [agent_list ? agent_list.length : 0]})</h3>
                    
                    <button className="btn btn-primary fontBold" onClick={() => {this.toggle_select_key(this.props.agent_list)}} style={{ background:'#4AA49C', borderRadius:'4px', float: "right", marginTop:"5px", marginLeft:"15px"}}> {this.state.select_all ? "Select all agents" : "Unselect all agents"} </button>
                  </div>
                   
                  <>
                    <div className="col-md-6" style={{paddingLeft:0}}>
                      <DateRange data_table_filter_by={this.props.data_table_filter_by} agent_list={this.props.data ? this.props.data : agent_list} page={window.location.pathname === "/properties" ? "properties_page" : window.location.pathname === "/viewings" ? "viewings_page" : window.location.pathname === "/revenue" ? "viewings_page" : null }/>
                      {/* <div style={{float:'right'}}>
                        <div style={{ float:'left'}}>
                          <span className="fontSize15 fontInter color0F1D30 fontWeight500 dateRange" style={{marginTop:'8px'}}>
                            <Icon icon={informationIcon} className="color8591A0" data-place="top" data-tip={info_message} style={{fontSize: '20px', verticalAlign: "text-bottom"}}/>
                            &nbsp;
                            Date Range
                          </span>
                        </div>
                        <ReactTooltip resizeHide = { true } backgroundColor={'#0B2C4C'} html={true}/>
                        <div style={{marginTop: 8, float:'right'}}>
                          <input type="text" id="from_label" value="From:" disabled/>
                          <Calendar value={this.state.startDate} maxDate={this.state.endDate} dateFormat="dd-mm-yy" name="from_date" id="from_date" className='ml15'
                            onChange={(e) => {this.setState({ startDate: e.value, renderAgentButton: 1, CalenderMinDate:e.value }); this.toDateOverview(e)} }>
                          </Calendar>
                          &nbsp;
                          <input type="text" id="to_label" value="To:" disabled/>
                          <Calendar minDate={this.state.CalenderMinDate} name="to_date" id="to_date" value={this.state.endDate} dateFormat="dd-mm-yy"
                            onChange={(e) => {this.setState({ endDate: e.value }); this.toDateOverview(e)} }>
                          </Calendar>
                        </div>
                      </div> */}
                    </div>
                  </>
                 
                
                  {/* <div className="col-md-2">
                    <input type="text" id="to_label" value="To:" disabled/>
                    <Calendar minDate={this.state.CalenderMinDate} name="to_date" id="to_date" value={this.state.endDate} dateFormat="dd-mm-yy"
                      onChange={(e) => {this.setState({ endDate: e.value }); this.toDateOverview(e)} }>
                    </Calendar>
                  </div> */}
                </div>
                  
              </div>
              <hr className="mt5 mb5" style={{ borderTop: '1px solid #DAEEEC !important'}} />
              <div className="ml20 mb20 padBottom5">
                <div style={{whiteSpace:'nowrap', overflow: 'auto'}}>
                  <div className="agentIconButton" style={{display: 'inline-block'}}>  
                    { this.props.data ? 
                      this.renderAgentButton() : [agent_list ? this.renderAgentButton() : null]
                    }
                  </div>  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    change_from_to_date: (fromDate, toDate) => dispatch(actions.change_from_to_date(fromDate, toDate)),
    get_overview_data_with_date: (fromDate, toDate, token) => dispatch(actions.get_overview_data_with_date(fromDate, toDate, token)),
    get_property_chart : (fromDate, toDate, agent_list, token) => dispatch(actions.get_property_chart(fromDate, toDate, agent_list, token)),
    get_property_sale_chart : (fromDate, toDate, type, agent_list, token) => dispatch(actions.get_property_sale_chart(fromDate, toDate, type, agent_list, token)),
    get_stockpipeline_chart : (fromDate, toDate, agent_list, token) => dispatch(actions.get_stockpipeline_chart(fromDate, toDate, agent_list, token)),
    get_appointment_chart : (fromDate, toDate, agent_list, token) => dispatch (actions.get_appointment_chart(fromDate, toDate, agent_list, token)),
    get_general_chart : (fromDate, toDate, agent_list, token) => dispatch (actions.get_general_chart(fromDate, toDate, agent_list, token)),
    get_sales_chart : (fromDate, toDate, agent_list, token) => dispatch(actions.get_sales_chart(fromDate, toDate, agent_list, token)),
    get_coldcalls_chart : (fromDate, toDate, agent_list, token) => dispatch(actions.get_coldcalls_chart(fromDate, toDate, agent_list, token)),
    get_revenue_chart: (fromDate, toDate, type, agent_list, token) => dispatch(actions.get_revenue_chart(fromDate, toDate, type, agent_list, token)),
    get_rental_revenue_chart: (fromDate, toDate, agent_list, token) => dispatch(actions.get_rental_revenue_chart(fromDate, toDate, agent_list, token)),
    get_profitability_chart: (fromDate, toDate, agent_list, token) => dispatch(actions.get_profitability_chart(fromDate, toDate, agent_list, token)),
    
    //get chart by date per page
    get_property_chart_property: (fromDate, toDate, agent_list, token) => dispatch(actions.get_property_chart_property(fromDate, toDate, agent_list, token)),
    get_appointment_chart_appointment: (fromDate, toDate, agent_list, token) => dispatch(actions.get_appointment_chart_appointment(fromDate, toDate, agent_list, token)),
    get_revenue_chart_revenue: (fromDate, toDate, agent_list, token) => dispatch(actions.get_revenue_chart_revenue(fromDate, toDate, agent_list, token)),
    
    //get table data per page
    get_filtered_agent_list_appointment: (fromDate, toDate, token) => dispatch(actions.get_filtered_agent_list_appointment(fromDate, toDate, token)),

    //toggle_agent
    toggle_property_chart: (agent_id) => dispatch(actions.toggle_property_chart(agent_id)),
    toggle_property_sale_chart: (agent_id) => dispatch(actions.toggle_property_sale_chart(agent_id)),
    toggle_property_sold_chart: (agent_id) => dispatch(actions.toggle_property_sold_chart(agent_id)),
    toggle_stockpipeline_chart: (agent_id) => dispatch(actions.toggle_stockpipeline_chart(agent_id)),
    toggle_appointment_chart: (agent_id) => dispatch(actions.toggle_appointment_chart(agent_id)),
    toggle_general_chart: (agent_id) => dispatch(actions.toggle_general_chart(agent_id)),
    toggle_sales_chart: (agent_id) => dispatch(actions.toggle_sales_chart(agent_id)),
    toggle_coldcalls_chart: (agent_id) => dispatch(actions.toggle_coldcalls_chart(agent_id)),
    toggle_revenue_chart: (agent_id) => dispatch(actions.toggle_revenue_chart(agent_id)),
    toggle_rental_revenue_chart: (agent_id) => dispatch(actions.toggle_rental_revenue_chart(agent_id)),
    toggle_profitability_chart: (agent_id) => dispatch(actions.toggle_profitability_chart(agent_id)),

    //toggle_agent_property
    toggle_property_page_chart: (agent_id) => dispatch(actions.toggle_property_page_chart(agent_id)),
    //toggle_agent_appointment
    toggle_appointment_page_chart: (agent_id) => dispatch(actions.toggle_appointment_page_chart(agent_id)),
    toggle_appointment_page_general_chart: (agent_id) => dispatch(actions.toggle_appointment_page_general_chart(agent_id)),
    //toggle_agent_revenue
    toggle_revenue_page_chart: (agent_id) => dispatch(actions.toggle_revenue_page_chart(agent_id)),
  }
}

const mapStateToProps = state => {
  return{
    token: state.auth.token,

    //overview data
    data: state.overview.data,
    total_prop: state.overview.total_prop,
    soldPropertyValues: state.overview.soldPropertyValues,
    linkedUserCount: state.overview.linkedUserCount,
    appointmentCount: state.overview.appointmentCount,
    
    //appointment_chart
    appt_lables: state.overview.appt_lables,
    chart_appt_count: state.overview.chart_appt_count,
    //temp_chart_appt_count: state.overview.temp_chart_appt_count,

    //property_chart
    property_labels: state.overview.property_labels,
    property_agen_count: state.overview.property_agen_count,
    //temp_chart_property_count : state.overview.temp_chart_property_count,

    //sales_chart
    sales_labels: state.overview.sales_labels,
    chart_sales_cnt: state.overview.chart_sales_cnt,
    //temp_chart_sales_count: state.overview.temp_chart_appt_count,
    
    //revenue_chart
    revenue_labels: state.overview.revenue_labels,
    chart_revenue_cnt: state.overview.chart_revenue_cnt,
    //temp_chart_revenue_count: state.overview.temp_chart_revenue_count,

    //profitability_chart
    profitability_labels: state.overview.profitability_labels,
    profitability_chart: state.overview.profitability_chart,
    //temp_chart_profitability_count: state.overview.temp_chart_profitability_count
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AgentList);