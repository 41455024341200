import React from 'react';
import {Line} from 'react-chartjs-2';


class  AgentRevenueChartById extends React.Component {
  render() {


    var sales_value   = this.props.sales_value;
    var revenue_value = this.props.revenue;

    const state = {
      labels: this.props.labels,
      datasets: [
        {
          label: 'Revenue',
          fill: false,
          lineTension: 0,
          backgroundColor: 'rgba(234,103,106,1)',
          borderColor: '#EA676A',
          borderWidth: 2,
          data: revenue_value
        },
        {
          label: 'Sales Value',
          fill: false,
          lineTension: 0,
          backgroundColor: 'rgba(75,192,192,1)',
          borderColor: '#27AFAF',
          borderWidth: 2,
          data: sales_value
        }
        
      ]
    }
    
    


    return (
      
      <div>
        <Line
          data={state}
          options={{
            title:{
              display:false,
            },
            legend:{
              display:false,
              backgroundColor: 'rgba(75,192,192,1)',
              borderWidth: 2,
            },
            scales: {
              yAxes: [{
                display: true,
                ticks: {
                  beginAtZero: true,
                  userCallback: function(label, index, labels) {
                    if (Math.floor(label) === label) {
                        return label;
                    }
                  },
                }
              }]
            }
          }}
        />
        
      </div>
    );
  }
}

export default AgentRevenueChartById