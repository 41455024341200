import React, { Component } from 'react'
import IdleTimer from 'react-idle-timer'
import {connect} from 'react-redux';
import * as actions from '../store/actions/index';
            
class IdleTimerContainer extends Component {
    constructor(props) {
        super(props)
        this.idleTimer = null
        this.handleOnAction = this.handleOnAction.bind(this)
        //this.handleOnActive = this.handleOnActive.bind(this)
        this.handleOnIdle = this.handleOnIdle.bind(this)
    }

    async handleOnAction (event) {
        //console.log('user did something', event);
        if(!localStorage.getItem("lastActive")){
            this.props.logout();
            window.location.href = "/";
        }

        localStorage.setItem("lastActive", new Date());
        await this.props.update_expire(localStorage.getItem("lastActive"));
    }
    
    // handleOnActive (event) {
    //     console.log('user is active', event)
    //     console.log('time remaining', this.idleTimer.getRemainingTime())
    // }
    
    handleOnIdle (event) {
        const idleTime = new Date (localStorage.getItem('lastActive'));
        const currTime = new Date(this.props.lastActive);
        let timeDiff = Number(idleTime.getTime()) - Number(currTime.getTime());
        
        if(timeDiff <= 0){
            this.props.logout();
            window.location.href = "/";
            return
        }else{
            this.props.update_expire(localStorage.getItem("lastActive"));
            return
        }
    }
    
    render() {
        window.addEventListener('storage', () => {
            if(!this.props.lastActive){
                this.props.logout();
                window.location.href = "/";
            }
        });

        return (
            <div>
                <IdleTimer
                ref={ref => { this.idleTimer = ref }}
                timeout={3600 * 1000}
                //timeout={5000}
                onIdle={this.handleOnIdle}
                //onActive={this.handleOnActive}
                onAction={this.handleOnAction}
                //debounce={250}
                />

                {/* {!this.props.login ?
                    window.location.href = "/"
                    : null
                } */}
            </div>
        )
    }
}
    
const mapDispatchToProps = dispatch => {
    return {
        update_expire : (lastActive) => dispatch(actions.update_expire(lastActive)),
        logout : () => dispatch(actions.logout()),
    }
}

const mapStateToProps = state => {
    return{
        login: state.auth.login,
        lastActive: state.auth.lastActive,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(IdleTimerContainer);
