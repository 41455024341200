import React,{ Component } from "react";
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
// import Constants from '../../constants/Constants';
import { Icon } from '@iconify/react';
import bxsShareAlt from '@iconify/icons-bx/bxs-share-alt';
import shareIcon from '@iconify/icons-feather/share';
import copyIcon from '@iconify/icons-fa-regular/copy';
import Loader from "../Loader";
import $ from 'jquery';

class ShareCode extends Component{
    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();
        this.setwrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        //if (!this.wrapperRef.current.contains(event.target)) {
            //if(this.setwrapperRef.current.contains(event.target)){
                //window.$("#sharecodeModal").modal('hide');
                //window.$("#addPropertyModal").modal('hide');
                //this.props.closeModal();
            //}
        //}
    }
    closeModal(){
        this.props.reset_all_form_state()
    }

    copyShareCode = (id) =>{
        var copyText = document.getElementById(id);
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
        
        $('#copied-success').fadeIn(800);
        $('#copied-success').fadeOut(800);
    }

    render(){ 
        let share_code = this.props.share_code  
        if(this.props.share_code){
            share_code = this.props.share_code[1]
        }else{
            share_code = null;  
        }
        var formattedBody = "Your realtor, has loaded your property onto the Roundr app for simple viewing scheduling, feedback, and record keeping. \n Learn more about how it works, and download the app - https://roundr.app/share/ \n After downloading the app, use code "+ share_code +" to link to your property";
        var mailToLink = "mailto:?subject=Property Code of Roundr Property &body="+formattedBody;
        return(
            <div>
                <div className="modal fade" id="sharecodeModal" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={this.setwrapperRef}>
                    <div className="modal-dialog" role="document" ref={this.wrapperRef}>
                    {Number(this.props.prop_id) ? 
                        <div className="modal-content modalheight" style ={{borderRadius : '12px' }} >
                            <div className="modal-header" style={{ backgroundColor: "#EDF6F6"}}>
                                <h4 className="modal-title fontWeight600"> 
                                    <Icon icon={bxsShareAlt} style={{color: '#27afaf', fontSize: '26px', verticalAlign:'bottom'}} /> &nbsp;&nbsp;
                                    Share Property Code
                                </h4>
                                
                                <div id="closeModal_add_property_success" className="circle" data-dismiss="modal" onClick={this.closeModal}>
                                    <img src="images/modal-close.png " width="12" height="12" alt="" />
                                </div> 
                            </div>
                            <div className="modal-body">
                                <center>
                                <img alt="" src = "user-images/sharePropertySuccess.png" />
                                <p className="fontSize15" style={{fontFamily:'Inter', color:'#1B2735', marginTop:10}}>
                                    Almost done...
                                </p>
                                <h1 className="cardHeader" style={{paddingTop: 10}}>Would you like to link up?</h1>
                                {Number(this.props.sale_type) === 1 ?
                                    Number(this.props.reside) === 1 ?
                                        <p className="propertysucctext"> Get the most out of Roundr by having your seller connect with you.</p>
                                    :
                                        <p className="propertysucctext"> Get the most out of Roundr by having your seller/occupant connect with you.</p>
                                :
                                    Number(this.props.reside) === 1 ?
                                        <p className="propertysucctext"> Get the most out of Roundr by having the owner connect with you.</p>
                                    :
                                        <p className="propertysucctext"> Get the most out of Roundr by having the owner/occupant connect with you.</p>
                                       
                                }
                                
                                <p className="propertysucctext">They need to download the app and enter the following code.</p>
                                <p className="propertysucctext">You can also share this code later.</p>

                                <div className="shadow" style={{borderRadius:8, marginTop:30, marginBottom:30, width:"55%"}}>
                                    <p className="propertysucctext" style={{paddingTop: 22}}>{Number(this.props.sale_type) === Number(1) ? "Seller": "Owner" }’s Code:</p>
                                    <input className="sharecode mb20" style={{width:"inherit", textAlign:"center", border: "0px"}} value={this.props.share_code ? this.props.share_code[1] : null} id="seller_code" readOnly={true} />
                                    {/* <h1 className="sharecode" id="seller_code" value= {this.props.share_code ? this.props.share_code[1] : null} >{this.props.share_code ? this.props.share_code[1] : null}</h1> */}
                                    <br/>
                                    <button className="btn btn-primary fontBold mr15" style={{ background:'#4AA49C', borderRadius:'4px', padding:10, marginBottom:30}} id="copyButton" onClick={() => this.copyShareCode('seller_code')}><Icon icon={copyIcon} style={{fontSize: '20px', verticalAlign: 'bottom'}} /> &nbsp;Copy Code</button>
                                    <a href={mailToLink} className="btn btn-primary fontBold" style={{ background:'#4AA49C', borderRadius:'4px', padding:10, marginBottom:30}}>
                                        <Icon icon={shareIcon} style={{fontSize:'20px', verticalAlign:'bottom'}} />&nbsp;&nbsp;
                                        {/* Share with {Number(this.props.sale_type) === Number(1) ? "seller": "owner" } */}
                                        Share via email
                                    </a>
                                </div>
                                { Number(this.props.reside) === 0 ?
                                    <div className="shadow" style={{borderRadius:8, marginTop:30, marginBottom:30, width:"55%"}}>
                                        <p className="propertysucctext" style={{paddingTop: 22}}>Occupant’s Code:</p>
                                        {/* <h1 className="sharecode">{this.props.share_code ? this.props.share_code[2] : null}</h1> */}
                                        <input className="sharecode mb20" style={{width:"inherit", textAlign:"center", border: "0px"}} value={this.props.share_code ? this.props.share_code[2] : null} id="occupant_code" readOnly={true} />
                                        <br/>
                                        <button className="btn btn-primary fontBold mr15" style={{ background:'#4AA49C', borderRadius:'4px', padding:10, marginBottom:30}} id="copyButton" onClick={() => this.copyShareCode('occupant_code')}><Icon icon={copyIcon} style={{fontSize: '20px', verticalAlign: 'bottom'}} /> &nbsp;Copy Code</button>
                                        <a href={mailToLink} className="btn btn-primary fontBold" style={{ background:'#4AA49C', borderRadius:'4px', padding:10, marginBottom:30}}>
                                            <Icon icon={shareIcon} style={{fontSize:'20px', verticalAlign:'bottom'}} />&nbsp;&nbsp;
                                            Share via email
                                        </a>
                                    </div>
                                    : null
                                }
                                </center>
                            </div>
                        </div>
                    : 
                        <div className="modal-content modalheight" style ={{borderRadius : '12px' }} >
                            <div className="modal-header" style={{ backgroundColor: "#EDF6F6"}}>
                                <h4 className="modal-title fontWeight600"> 
                                    Loading
                                </h4>
                                <div id="closeModal_add_property_success" className="circle" data-dismiss="modal" onClick={this.closeModal}>
                                    <img src="images/modal-close.png " width="12" height="12" alt="" />
                                </div> 
                            </div>
                            <div className="modal-body">
                                <center>
                                <Loader/>
                                </center>
                            </div>
                        </div>
                    }
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        reset_all_form_state : () => dispatch(actions.reset_all_form_state())
    }
}

const mapStateToProps = state => {
    return{
        prop_id: state.add_property_modal.prop_id,
        sale_type: state.add_property_modal.sale_type,
        reside: state.add_property_modal.reside,
        share_code: state.add_property_modal.share_code,

        // share_code: state.add_property_modal.share_code,
        // share_code_occupant: state.add_property_modal.share_code_occupant
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ShareCode);
