import React ,  { Component } from 'react';
import {connect} from 'react-redux';
import * as actions from '../store/actions/index';

import Helmet from "react-helmet";
import Footer from '../components/Footer';
import '../CustomFiles/bower_components/bootstrap/dist/css/bootstrap.min.css';
import '../CustomFiles/bower_components/font-awesome/css/font-awesome.min.css';
import '../CustomFiles/bower_components/Ionicons/css/ionicons.min.css';
import '../CustomFiles/dist/css/AdminLTE.min.css';
import '../CustomFiles/plugins/iCheck/square/blue.css';
import Loader from '../components/Loader';
import ErrorText from './ErrorText';
import { Redirect } from 'react-router-dom';

class Login extends Component{

  constructor(props) {
    super(props);
    this.submitHandler = this.submitHandler.bind(this);
  }
 
  componentDidMount = ()=>{
    if(!localStorage.getItem('token')){
      localStorage.clear();
      this.props.history.push("/");
    }else{
      this.props.history.push("/overview");
      return;
    }
  }

  submitHandler = (event) => {
    event.preventDefault();
    let loginDetails = {
      username : this.refs.username.value,
      password : this.refs.password.value
    }
    this.props.onAuth(loginDetails.username, loginDetails.password);
  }
  
  render(){
    let footer= "footer_color";
    if (window.innerWidth > document.body.clientWidth) {
      footer = 'footer';
    }
    return(
      <div>
        <Helmet>
          <title>Office Login</title>
          <link rel="icon" href="https://roundr.app/wp-content/uploads/2018/03/favicon.png" type="image/png"></link>
        </Helmet>
        <div className="login-box">
          <div className="login-logo">
            <a href="/#">
              <img src = "/images/logo.png" height="35" alt = "" />
              &nbsp;
              <b>Office</b>
            </a>
          </div>
          <br />

          <div className="login-box-body">
            <p className="login-box-msg">Sign in to start your session</p>
            { this.props.loading ? <Loader /> :null }
            { this.props.error ? <ErrorText errorMsg = {this.props.error} /> : null}
            <br/>
            <form onSubmit={this.submitHandler}>
              <div className="form-group has-feedback">
                <input type="text" className="form-control" id="username" placeholder="Username" ref="username"/>
                <span className="glyphicon glyphicon-envelope form-control-feedback"></span>
              </div>
              <div className="form-group has-feedback">
                <input type="password" id="password" ref="password" className="form-control" placeholder="Password"  />
                <span className="glyphicon glyphicon-lock form-control-feedback"></span>
              </div>
              <div className="row">
                <div className="col-xs-8">
                </div>
                <div className="col-xs-4">
                  { this.props.loading ?
                    <button disabled type="button" className="btn btn-primary btn-block btn-flat">
                      <i className="fa fa-spinner fa-spin"></i>
                    </button>
                    :
                    <button type="submit" className="btn btn-primary btn-block btn-flat">Sign In</button>
                  }
                </div>  
              </div>
            </form>
            {/* <a href="/forgot-pass">I forgot my password</a><br /> */}
          </div>
        </div>
        { this.props.redirect_link ? <Redirect to={this.props.redirect_link}/> : null}
        <Footer footer={footer}></Footer>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return{
    loading: state.auth.loading,
    token: state.auth.token,
    redirect_link: state.auth.redirect_link,
    error: state.auth.error
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onAuth : (email, password) => dispatch(actions.auth(email, password))
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);
