import * as actionTypes from './actionsTypes';
import Constants from '../../constants/Constants';

var myHeaders = '';
var raw = '';
var requestOptions = '';

export const create_viewing_appointment = (authtoken, timezone, appointment_type_id, buyer_email, roundr_contact_id, confirm_for_seller, buyer_cell, appointment_date, buyer_name, listing_type, contact_id, property_id) => {
    return async dispatch =>{
        let error=[];
        //console.log(authtoken, timezone, appointment_type_id, buyer_email, roundr_contact_id, confirm_for_seller, buyer_cell, appointment_date, buyer_name, listing_type, contact_id, property_id)
        if((!authtoken) || (!appointment_type_id) || (appointment_date === null) || (buyer_name === null) || (property_id === null)){
            if(!authtoken){
                error.msg = "Please select the Agent";
                dispatch(submitFail(error));
            }else if(!appointment_type_id){
                error.msg = "Please enter the Select appointment type";
                dispatch(submitFail(error));
            }else if(buyer_name === null){
                error.msg = "Please select Viewer from contact or Enter Viewer's Name";
                dispatch(submitFail(error));
            }else if(!appointment_date ){ 
                error.msg = "Please select appointment date and time";
                dispatch(submitFail(error));
            }else if(property_id === null){
                error.msg = "Please select the property";
                dispatch(submitFail(error));
            }
        }else if(authtoken || appointment_type_id || appointment_date || buyer_name || property_id){
            myHeaders = new Headers();
            myHeaders.append("authtoken", authtoken);
            myHeaders.append("timezone", timezone);
            
            raw = JSON.stringify(
            {
                "appointment_type_id": appointment_type_id, 
                "buyer_email": buyer_email, 
                "roundr_contact_id": roundr_contact_id, 
                "confirm_for_seller": confirm_for_seller, 
                "buyer_cell": buyer_cell, 
                "appointment_date": appointment_date, 
                "buyer_name": buyer_name, 
                "listing_type": listing_type, 
                "contact_id": contact_id, 
                "property_id": property_id,
                "add_office_agent_notification": 1
            });
            
            requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            error.msg = "";
            dispatch(submitFail(error));
            await fetch(Constants.CREATE_VIEWING_APPOINTMENT, requestOptions)
            .then(response => response.json())
            .then(data => {
                dispatch(viewing_appointment(data));
            })
            .catch(error => console.log('error', error));
        }
    }
}
export const viewing_appointment = (data)=>{
    if(Number(data.success) === 0){
        return{
            type: actionTypes.VIEWING_APPOINTMENT,
            add_appointment_success: data.success,
        }
    }else if(data && data.data.has_sub === false) {
        return {
            type: actionTypes.VIEWING_APPOINTMENT,
            add_appointment_success: 2,
        }
    }else if(Number(data.success) === 1){
        return {
            type: actionTypes.VIEWING_APPOINTMENT,
            add_appointment_success: data.success,
        }
    }
}

export const create_general_appointment = (authtoken, timezone, reason, my_stock_pipeline_id, appointment_date, meeting_with, property_id, audio_data, address) => {
    return async dispatch =>{
        let error=[];
        //console.log(authtoken, timezone, reason, my_stock_pipeline_id, appointment_date, meeting_with, property_id, audio_data, address)
        if((!authtoken) || (!reason) || (!appointment_date)){
            if(!authtoken){
                error.msg = "Please select the Agent";
                dispatch(submitFail(error));
            }else if(!reason){
                error.msg = "Please enter the Reason/Note for appointment";
                dispatch(submitFail(error));
            // }else if(!my_stock_pipeline_id || !property_id || !address){
            //     error.msg = "Please select property";
            //     dispatch(submitFail(error));
            }else if(!appointment_date ){ 
                error.msg = "Please select appointment date and time";
                dispatch(submitFail(error));
            }
        }else if(authtoken || reason || (my_stock_pipeline_id && property_id) || appointment_date || address){
            myHeaders = new Headers();
            myHeaders.append("authtoken", authtoken);
            myHeaders.append("timezone", timezone);
            
            var raws = new FormData();
            raws.append("reason", reason.trim());
            raws.append("my_stock_pipeline_id", my_stock_pipeline_id ? my_stock_pipeline_id : '' );
            raws.append("appointment_date", appointment_date);
            raws.append("meeting_with", meeting_with ? meeting_with: '');
            raws.append("property_id", property_id ? property_id : '');
            //raws.append("audiodata", audio_data);
            raws.append("office_agent_notification", 1);

            requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raws,
                redirect: 'follow'
            };

            error.msg = "";
            dispatch(submitFail(error));
            await fetch(Constants.CREATE_GENERAL_APPOINTMENT, requestOptions)
            .then(response => response.json())
            .then(data => {
                dispatch(general_appointment(data));
            })
            .catch(error => console.log('error', error));
        }
    }
}
export const general_appointment = (data)=>{
    if(Number(data.success) === 0){
        return{
            type: actionTypes.VIEWING_APPOINTMENT,
            add_appointment_success: data.success,
        }
    }else if(data && data.data.has_sub === false) {
        return {
            type: actionTypes.VIEWING_APPOINTMENT,
            add_appointment_success: 2,
        }
    }else if(Number(data.success) === 1){
        return {
            type: actionTypes.VIEWING_APPOINTMENT,
            add_appointment_success: data.success,
        }
    }
}

export const submitFail = (error) => {
    return {
        type: actionTypes.APPOINTMENT_SUBMIT_FAIL,
        appointment_form_error: error.msg,
        add_appointment_success: 3,
    }
}

export const update_add_appointment_modal_state = (show_agents,show_property,from_property_page,from_stock_pipeline) => {
    return dispatch =>{
        dispatch({
            type: actionTypes.UPDATE_ADD_APPOINTMENT_MODAL_STATE,
            show_agents: show_agents,
            show_property: show_property,
            from_property_page: from_property_page,
            from_stock_pipeline: from_stock_pipeline
        })
    }
}

export const reset_appointment_modal_error = () =>{
    return dispatch =>{
        dispatch({
            type: actionTypes.RESET_APPOINTMENT_MODAL_ERROR
        })
    }
}