import React,{ Component } from "react";
import {connect} from 'react-redux';
import * as actions from './store/actions/index';
import Loader from './components/Loader';
import Helmet from "react-helmet";

import Header from './components/menu/Header';
import Footer from './components/Footer';
//icon
import { Icon} from '@iconify/react';
import tickSolid from '@iconify/icons-teenyicons/tick-solid';
import crossIcon from '@iconify/icons-akar-icons/cross';

import IdleTimerContainer from './components/IdleTimerContainer';
import Button from 'react-bootstrap/Button';
import modal from './CustomFiles/js/modal';

const $ = require('jquery');
$.DataTable = require('datatables.net');
var datatable_properties_triggered = 0;
let table='';
class Pricing extends Component {  
    constructor(props) {
        super(props);
        this.state = {        
            isLoading : false
        }
    }

    componentDidMount = async ()=>{
        modal();
        const token = localStorage.getItem('token');
        if( token === '' || token === null ){
            localStorage.clear();
            this.props.history.push("/");
            return;
        }
        this.setState({isLoading: false});
        await this.props.get_pricing_plan_id(token);
        this.setState({isLoading: true});
    }

    datatableproperties = ()=>{
        if(datatable_properties_triggered === 1){
          table.destroy();  
        }
        table = $('.agentListingTable').DataTable( {
          'paging': true,
          'lengthChange': true,
          'searching': true,
        });
        datatable_properties_triggered = 1;
    }

    handleManageSubscription(){
        window.$("#update_subscription_Modal").modal('show');
    }

    Handlecallback = (completed, callback_data) => {
        this.props.send_payment_callback(completed, callback_data, localStorage.getItem('token'));
    }

    render() {
        let footer= "footer_color";
        if (window.innerWidth > document.body.clientWidth) {
            footer = 'footer';
        }
        const Paddle = window.Paddle;
        var _ = this;
        const openCheckout  = (event) => { 
            //console.log(event.target.getAttribute("data-subscription_id"));
            var vendor_id='';
            if(window.location.origin.toString() !== "https://office.roundr.app"){
                //Paddle.Environment.set('sandbox');
                vendor_id = 1125;
                //vendor_id = 125307;
            }else{
                vendor_id = 125307;
            }

            Paddle.Setup({ vendor: vendor_id,
                eventCallback: function(data) {
                    // The data.event will specify the event type
                    if (data.event === "Checkout.Complete") {
                        let completed = 1;
                        _.Handlecallback(completed, data.eventData);
                    }
                    else if (data.event === "Checkout.Close") {
                        //console.log(data.eventData); // Data specifics on the event
                    }
                    else if(data.event === "Checkout.Error"){
                        //console.log(data.eventData);
                    }
                }
            });
            Paddle.Checkout.open({ product: event.target.getAttribute("data-subscription_id"),
                passthrough: localStorage.getItem('office_id'),
                allowQuantity: true
            });
        }

        return (<div>
                <IdleTimerContainer></IdleTimerContainer>
                {/* <Notifications /> */}
                <Helmet>
                <title>Roundr - Pricing</title>
                <link rel="icon" href="https://roundr.app/wp-content/uploads/2018/03/favicon.png" type="image/png"></link>
                </Helmet>
                {<Header active="dashboard"/>}

                <div className="content-wrapper" style={{ marginLeft: '0px' }}>
                    <section className="content" style={{ margin: '30px', marginTop: '30px' }}>
                        <div className="row">
                            <div className="col-xs-12 col-md-12">
                            
                                <div className="row" style={{fontSize:"18px"}}>
                                    <div className="col-md-10 col-md-offset-1 offset-md-1" style={{height:"50px", zIndex:100}}>
                                        <p className="pricingHeader" >
                                            Office Subscription Plans
                                        </p>
                                    </div>
                                    <div className="col-md-10 col-md-offset-1 offset-md-1">
                                        <div className="row">
                                            <div className="col-md-4 col-md-offset-1" style={{textAlign:'center', backgroundColor:"#fff"}}>
                                                <h2 style={{marginTop:25}}>Standard</h2>
                                                {this.props.pricing_plan ? 
                                                    <div className="pricing_price">
                                                        <p>
                                                            {this.props.pricing_plan.std.per_user}/user<br/>Monthly <br/><span style={{fontSize:"12px"}}>(excl VAT)</span>
                                                        </p>
                                                        <p>
                                                            {this.props.pricing_plan.std.billed_annual} billed annually - save {this.props.pricing_plan.std.save_annual} <br/> <span style={{fontSize:"12px"}}>(excl VAT)</span>
                                                        </p>
                                                    </div>
                                                    : <Loader/>
                                                }
                                                <div style={{marginTop:"30px"}}>
                                                    <span>
                                                        <Icon icon={tickSolid} style={{color:"#27AFAF", marginRight:"5px"}}/>
                                                        Agents have access to the roundr mobile app
                                                    </span>
                                                </div>
                                                <hr style={{color: "#DAEEEC"}} />
                                                <div>
                                                    <span>
                                                        <Icon icon={tickSolid} style={{color:"#27AFAF", marginRight:"5px"}}/>
                                                        Principals / Managers have access to agent data via Roundr office portal
                                                    </span>
                                                </div>
                                                <hr style={{color: "#DAEEEC"}} />
                                                <div style={{marginBottom:"30px"}}>
                                                    <Icon icon={crossIcon}  style={{color:"#EB5757", marginRight:"5px"}}/>
                                                    Integrate with your existing CRM system
                                                </div>
                                                <hr style={{color: "#DAEEEC"}} />
                                                <div style={{marginBottom:"30px"}}>
                                                    {this.state.isLoading ?<>
                                                        <Button className="paddle_button" style={{background: "#27AFAF", borderColor: "#27AFAF", fontWeight: "600", width: 200, marginRight: 15, marginTop: 7, fontSize:18, borderRadius:"5px", filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"}}  onClick={openCheckout} data-passthrough={localStorage.getItem('office_id')} data-subscription_id= {this.props.pricing_plan ? this.props.pricing_plan.std.month: null}>Subscribe Monthly</Button>
                                                        <Button className="paddle_button" style={{background: "#3C8DBC", borderColor: "#3C8DBC", fontWeight: "600", width: 200, marginRight: 15, marginTop: 7, fontSize:18, borderRadius:"5px", filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"}}  onClick={openCheckout} data-passthrough={localStorage.getItem('office_id')} data-subscription_id= {this.props.pricing_plan ? this.props.pricing_plan.std.annual: null}>Subscribe Annually</Button>
                                                        </>
                                                    :
                                                        <Loader></Loader> 
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-4 offset-md-1" style={{textAlign:'center', backgroundColor:"#fff"}}>
                                                <h2 style={{marginTop:25}}>Pro</h2>
                                                {this.props.pricing_plan ? 
                                                    <div className="pricing_price">
                                                        <p>
                                                            {this.props.pricing_plan.pro.per_user}/user<br/>Monthly <br/><span style={{fontSize:"12px"}}>(excl VAT)</span>
                                                        </p>
                                                        <p>{this.props.pricing_plan.pro.billed_annual} billed annually - save {this.props.pricing_plan.pro.save_annual} <br/> <span style={{fontSize:"12px"}}>(excl VAT)</span></p>
                                                    </div>
                                                : <Loader/>
                                                }
                                                <div style={{marginTop:"30px"}}>
                                                    <span>
                                                        <Icon icon={tickSolid} style={{color:"#27AFAF", marginRight:"5px"}}/>
                                                        Agents have access to the roundr mobile app
                                                    </span>
                                                </div>
                                                <hr style={{color: "#DAEEEC"}} />
                                                <div>
                                                    <span>
                                                        <Icon icon={tickSolid} style={{color:"#27AFAF", marginRight:"5px"}}/>
                                                        Principals / Managers have access to agent data via Roundr office portal
                                                    </span>
                                                </div>
                                                <hr style={{color: "#DAEEEC"}} />
                                                <div style={{marginBottom:"30px"}}>
                                                    <Icon icon={tickSolid} style={{color:"#27AFAF", marginRight:"5px"}}/>
                                                    Integrate with your existing CRM system
                                                </div>
                                                <hr style={{color: "#DAEEEC"}} />
                                                <div style={{marginBottom:"30px"}}>
                                                    {this.state.isLoading ?
                                                        <>
                                                        <Button className="paddle_button" style={{background: "#27AFAF", borderColor: "#27AFAF", fontWeight: "600", width: 200, marginRight: 15, marginTop: 7, fontSize:18, borderRadius:"5px", filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"}}  onClick={openCheckout} data-passthrough={localStorage.getItem('office_id')} data-subscription_id= {this.props.pricing_plan ? this.props.pricing_plan.pro.month: null}>Subscribe Monthly</Button>
                                                        <Button className="paddle_button" style={{background: "#3C8DBC", borderColor: "#3C8DBC", fontWeight: "600", width: 200, marginRight: 15, marginTop: 7, fontSize:18, borderRadius:"5px", filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"}}  onClick={openCheckout} data-passthrough={localStorage.getItem('office_id')} data-subscription_id={this.props.pricing_plan ?this.props.pricing_plan.pro.annual: null}>Subscribe Annually</Button>
                                                        </>
                                                    :
                                                    <Loader></Loader> }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-10 col-md-offset-1 offset-md-1" style={{height:"50px"}}>
                                        <p className="pricingFooter">
                                            Roundr is a tax-deductible business expense
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer footer={footer}></Footer>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        get_pricing_plan_id: (token) => dispatch(actions.get_pricing_plan_id(token)),
        send_payment_callback: (completed, paddle_response, token) => dispatch(actions.send_payment_callback(completed, paddle_response, token))
    }
}
  
const mapStateToProps = state => {
    return{
        pricing_plan: state.transaction_log.pricing_plan
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Pricing)
