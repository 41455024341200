import React from "react";
import {connect} from 'react-redux';
import {
    GoogleMap,
    useLoadScript,
    Marker,
} from '@react-google-maps/api';

import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import Geocode from "react-geocode";

import { Icon } from '@iconify/react';
import pinLocation2 from '@iconify/icons-si-glyph/pin-location-2';

const libraries = ["places"];
const mapContainerStyle = {
    width: "425px",
    height: "425px"
};

// var center = {
//     lat: 43.653225,
//     lng: -79.383186
// }

function AddressGoogleMap(props){
    Geocode.setApiKey("AIzaSyDwg4e6UirDc4yc02KqWK2VJZRtdaCREts");
    //address search box geo restriction
    const searchOptions = {
        componentRestrictions: { country: [props.agent_data ? props.agent_data.country_code : props.country_code ] },
    }
    
    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: "AIzaSyDwg4e6UirDc4yc02KqWK2VJZRtdaCREts",
        libraries
    });
    
    const [markers, setMarkers] = React.useState([]);
    const [address, setAddress] = React.useState([]);
    const [center, setCenter] = React.useState([]);
    
    const onMapClick = React.useCallback((event) => {
        setMarkers([
            {
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
                time: event.domEvent.timeStamp
            },
        ]);
        
        //seting the map address in the searchbox
        Geocode.fromLatLng(event.latLng.lat(), event.latLng.lng()).then(
            (response) => {
                var new_address = response.results[0].formatted_address;
                setAddress(new_address, event.latLng.lat(), event.latLng.lng());
            },
            (error) => {
              console.error(error);
            }
        );
    },[]);

    React.useEffect(() => {
        props.parentCallback(address, markers.length > 0 ? markers[0].lat : null, markers.length > 0 ? markers[0].lng : null );
    })

    //reset address on close of modal
    React.useEffect(() => {
        if(Number(props.reset_google_map) === 1){
            setMarkers([
                {
                    lat: 0,
                    lng: 0,
                    time: null
                }
            ])
            let reset_address = '';
            setAddress(reset_address);
        }
        Geocode.fromAddress(props.agent_data ? props.agent_data.country : "South Africa").then(
            (response) => {
                const { lat, lng } = response.results[0].geometry.location;
                setCenter([
                    {
                        lat: lat,
                        lng: lng,
                    },
                ]);
            },
            (error) => {
                console.error(error);
            }
        );
    },[props.reset_google_map, props.agent_data])

    React.useEffect(() => {
        Geocode.fromAddress(props.agent_data ? props.agent_data.country : "South Africa").then(
            (response) => {
                const { lat, lng } = response.results[0].geometry.location;
                setCenter([
                    {
                        lat: lat,
                        lng: lng,
                    },
                ]);
            },
            (error) => {
                console.error(error);
            }
        );
    },[props.agent_data])
    
    const handleChange = React.useCallback((address) => {
        setAddress(address);
    },[]);
    
    const mapRef = React.useRef();
    
    const onMapLoad = React.useCallback((map) => {
        //console.log(map)
        mapRef.current = map;
    },[]);

    const panTo = React.useCallback(({lat, lng}) => {
        mapRef.current.panTo({lat, lng});
        mapRef.current.setZoom(18)
    },[])

    if (loadError) return "Error Loading maps";
    if (!isLoaded) return "Loading Maps";
    
    const handleSelect = async(address) => {
        setAddress(address);
        try{
            const results = await geocodeByAddress(address.toString());
            const {lat, lng} = await getLatLng(results[0]);
            panTo({lat, lng})
            
            var currentTimeInSeconds=Math.floor(Date.now()/1000);
            setMarkers([
                {
                    lat: lat,
                    lng: lng,
                    time: currentTimeInSeconds
                },
            ]);
        }catch(error){
            console.log("Error!")
        }
    };

    return(
        <div>
            <div className='form_border' style={{marginBottom: 25,marginRight: 10 , display: 'inline-block'}}>
                <PlacesAutocomplete
                    value={address}
                    onChange={handleChange}
                    onSelect={handleSelect}
                    searchOptions={searchOptions}
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div className='full-input' style={{borderBottom: "none"}}>
                        <input className="input_modal" type='text' name='google_address' id="google_address" 
                            {...getInputProps({
                                placeholder: 'Search by address',
                            // className: 'location-search-input',
                            })}
                        />

                        <div className="autocomplete-dropdown-container dropdown_address">
                        {loading && <div>Loading...</div>}
                        {suggestions.map(suggestion => {
                            return (
                                <div id="dropdown_items" key={suggestion.placeId} ref="dropdown_items" className= {suggestion.active ? "dropdown_items dropdown_items_active":"dropdown_items dropdown_items_inactive"} {...getSuggestionItemProps(suggestion)}>
                                    {suggestion.description}
                                </div>
                            );
                        })}
                        </div>
                    </div>
                    )}
                </PlacesAutocomplete>
            </div>
            <div style={{display: 'inline'}}>
                <Locate panTo={panTo} />
            </div>
            
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={12}
                center={center[0]}
                //options = {options}
                onClick={onMapClick}
                onLoad={onMapLoad}
            >
                {markers.map(marker => 
                    <Marker 
                        key={marker.time} 
                        position={{ lat: marker.lat, lng: marker.lng}} 
                        icon = {{
                            url: 'images/map_pin.svg',
                            scaledSize: new window.google.maps.Size(50,50)
                        }}
                    />
                )}
            </GoogleMap>
            
        </div>
    );
}

function Locate({panTo}){
    return (
        <span style={{verticalAlign: "super", cursor: "pointer"}}
            onClick={()=> {
                navigator.geolocation.getCurrentPosition(
                    (position)=>{ 
                        panTo({
                            lat: position.coords.latitude, 
                            lng: position.coords.longitude 
                        })
                    }, 
                    () => null
                )
            }}
        >
            <Icon className="message_icon color4AA49C" icon={pinLocation2} height='28' width='28' />
        </span>
    )
}

// const mapDispatchToProps = dispatch => {

// }
  
const mapStateToProps = state => {
    return{
        authtoken: state.add_property_modal.authtoken,
        reset_google_map: state.add_stock_pipeline.reset_google_map,
        country_code: state.add_property_modal.country_code_dd,
        agent_data: state.agent_modal.agent_data,

        //edit address
        // edit_map_address: state.add_stock_pipeline.edit_map_address,
        // edit_lat: state.add_stock_pipeline.edit_lat,
        // edit_lng: state.add_stock_pipeline.edit_lng
    }
}
export default connect(mapStateToProps, null)(AddressGoogleMap);