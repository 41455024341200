import * as actionTypes from './actionsTypes';
import Constants from '../../constants/Constants';

var url='';
var myHeaders = '';
var raw = '';
var requestOptions = '';

export const get_compare_agent_list = (month, year, token) => {
    return async dispatch =>{
        url = Constants.AGENT_LIST;
        myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token);
        raw = JSON.stringify(
        {
            "month": month,
            "year": year
        });
        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        await fetch(url, requestOptions)
        .then(response => response.json())
        .then(data => {
            dispatch(compare_agent_list(data));
        })
        .catch(error => console.log('error', error)); 
    };
}
export const compare_agent_list = (data) =>{
    return{
        type: actionTypes.GET_COMPARE_AGENT,
        compare_agent_list: data,
    }
}
