import React , { Component } from 'react';
// import { Icon } from '@iconify/react';
// import roundReportProblem from '@iconify/icons-ic/round-report-problem';
class Footer extends Component{
    constructor(props) {
        super(props);
        this.state = {
            year: new Date().getFullYear(),
        }
    }
    handleReport = async () => {
        window.$("#reportIssueFeature").modal('show');
    }
    render () {
        return (
            <div className='navbar-header padTop5 padBottom5' style={{backgroundColor:"#1F2F44", width:'100%', position:"fixed", bottom:0, display:"flex", flexFlow:"row wrap" }}>
                <span className="fontInter fontWeight600 colorDAEEEC padLeft15" style={{width:"33.33333%", textAlign:"left"}}> Version 1.11.3</span>  
                <span className="fontInter fontWeight600 colorDAEEEC" style={{width:"33.33333%", textAlign:"center"}}> © 2017-{this.state.year} Roundr Realtor Ltd. All Rights Reserved.</span>  
                {/* <span className="fontInter fontWeight600 colorDAEEEC padRight15" style={{width:"33.33333%", textAlign:"right", cursor:"pointer"}} onClick={this.handleReport}> 
                    <Icon icon={roundReportProblem} className="colorDAEEEC mr5" height='18' width='18'style={{verticalAlign:'bottom'}}/> 
                    Report Issue / Request New Feature
                </span> */}
            </div>
        )
    }
}

export default Footer;