import React , { Component } from 'react';
import {connect} from 'react-redux';
import '../../CustomFiles/bower_components/bootstrap/dist/css/bootstrap.min.css';
import '../../CustomFiles/css/Basic-Header.css';
import '../../CustomFiles/css/predefined.css';
import DropDownMenu from './DropDownMenu';
import { Icon } from '@iconify/react';
// import roundMessage from '@iconify/icons-ic/round-message';
// import { Redirect } from 'react-router-dom';
import hamburgerIcon from '@iconify/icons-ci/hamburger';

class Header extends Component{
  state = {
    navCollapsed: true,
    menuStyle : {
      display:"table-caption",
      justifyContent: "center",
      alignItems: "center",
      color: "#8591a0",
      fontWeight:'500',
      fontFamily: "Inter",
      padding:"6px 12px",
    },

    a_menuStyle : {
      display:"table-caption",
      justifyContent: "center",
      alignItems: "center",
      color: "#fff",
      fontWeight:'500',
      fontFamily: "Inter",
      padding:"6px 12px",
    },
  }

  _onToggleNav = () => {
    this.setState({ navCollapsed: !this.state.navCollapsed })
  }
  
  render () {
    const active =  this.props.active;
    const {navCollapsed} = this.state;
    //var logo = sessionStorage.getItem('logo');
    var logo = this.props.logo;
    var totalAgentCount =  sessionStorage.getItem('totalAgent');
  
    return (
      <nav className='navbar mt20'>
        <div className='navbar-header' style={{marginTop:5, marginLeft:40 }}>
          <a href='/overview'><img src = "images/logo.png" width = "31.60"  height = "32.6" alt="" /></a>
          <span className="fontInter fontWeight600 padLeft20 color1F2F44">OFFICE</span>

          <button aria-expanded='false' style={{backgroundColor:'#3FA098'}} className='navbar-toggle collapsed' onClick={this._onToggleNav} type='button'>
            <span className='sr-only'>Toggle navigation</span>
            <span className='icon-bar' style={{backgroundColor:'#fff'}}></span>
            <span className='icon-bar' style={{backgroundColor:'#fff'}}></span>
            <span className='icon-bar' style={{backgroundColor:'#fff'}}></span>
          </button>
        </div>

        <div className={(navCollapsed ? 'collapse newHeaderMenu' : '') + ' navbar-collapse'}>  
          <ul className='nav navbar-nav' style={{textAlign: "-webkit-center", width:'100%'}}>
            <li className={(active ==='dashboard' ? 'headerMenuActive ml20':'ml20')}>
              <a href="/overview" style={(active ==='dashboard' ? this.state.a_menuStyle : this.state.menuStyle)} ><img src={(active ==='dashboard' ? 'images/overview_white.png' : 'images/overview_black.png')} height="16" alt=""/>Overview</a>
            </li>
            <li className={(active ==='properties' ? 'headerMenuActive ml20':'ml20')}>
              <a href="/properties" style={(active ==='properties' ? this.state.a_menuStyle : this.state.menuStyle)}> <img src={(active ==='properties' ? "images/header-component/property_white.png":"images/header-component/property_black.png")} width="20" height="15.83" alt=""></img>Properties</a>
              {/* <span style={(active ==='properties' ? this.state.a_menuStyle : this.state.menuStyle)}><Redirect to="/properties"/><img src={(active ==='appointment' ? "images/header-component/appointment_white.png":"images/header-component/appointment_black.png")} width="15" height="16.67" alt=""/>Properties</span> */}
            </li>
            <li className={(active ==='appointment' ? 'headerMenuActive ml20':'ml20')}>
              <a href="/viewings" style={(active ==='appointment' ? this.state.a_menuStyle : this.state.menuStyle)}><img src={(active ==='appointment' ? "images/header-component/appointment_white.png":"images/header-component/appointment_black.png")} width="15" height="16.67" alt=""/>Appointments</a>
            </li>
            <li className={(active ==='revenue' ? 'headerMenuActive ml20':'ml20')}>
              <a href="/revenue" style={(active ==='revenue' ? this.state.a_menuStyle : this.state.menuStyle)}><img src={(active ==='revenue' ? "images/header-component/revenue_white.png": "images/header-component/Revenue_black.png")} width="15" height="15" alt=""/>Revenue</a>
            </li>
            <li className={(active ==='buyerlead' ? 'headerMenuActive ml20':'ml20')}>
              <a href="/buyerlead" style={(active ==='buyerlead' ? this.state.a_menuStyle : this.state.menuStyle)}><img src={(active ==='buyerlead' ? "images/header-component/contact_white.png": "images/header-component/contact_black.png")} width="15" height="15" alt=""/><span style={{whiteSpace: "nowrap"}}>Contacts</span> </a>
            </li>
            
            <div className={(navCollapsed ? 'dropdown header_dropdown_style' : 'dropdown header_dropdown_style_collapse')}>
              {!logo ?
                <img data-toggle="dropdown" style={{marginRight:5}} alt="" src = "user-images/default_logo.png" width="70" height="70" className = "dropleft dropdown-toggle cursorPointer img-circle" />
              :
                <img data-toggle="dropdown" style={{marginRight:5}} alt="" src = {logo} width="140" height="70" className = "dropleft dropdown-toggle cursorPointer" />
              }
              <Icon data-toggle="dropdown" style={{verticalAlign: 'middle'}} icon={hamburgerIcon} color="#171e24" height="45" className = "menu-hamburger dropleft dropdown-toggle cursorPointer" />
              {/* DropDown Menu */}
              <DropDownMenu totalAgentCount = {totalAgentCount}/>
            </div>
          </ul>    
        </div>
      </nav>
    )
  }
}

const mapStateToProps = state => {
  return{
    logo: state.auth.logo,
  };
};

//export default (Header);
export default connect(mapStateToProps)(Header);