import * as actionTypes from '../actions/actionsTypes'; 
import {updateObject} from '../utility';

const initialState = {
    add_stock_pipeline_status: null,
    update_stock_pipeline_status: null,
    edit_stockpipeline_state: 0,
    stockpipeline_form_error: null,
    reset_google_map: 0,

    edit_map_address: null,
    edit_lat: null,
    edit_lng: null
}

const add_stock_pipeline = (state, action) => {
    return updateObject(state,{
        add_stock_pipeline_status: action.add_stock_pipeline_status,
        stockpipeline_form_error: null
    });
}

const update_stock_pipeline = (state, action) => {
    return updateObject(state,{
        update_stock_pipeline_status: action.update_stock_pipeline_status,
        stockpipeline_form_error: null
    });
}

const submit_fail = (state, action) => {
    return updateObject(state,{
        stockpipeline_form_error: action.stockpipeline_form_error
    })
}

const reset_google_map = (state, action) => {
    return updateObject(state,{
        reset_google_map: action.reset_google_map,
        stockpipeline_form_error: null
    });
}

const reset_add_stockpipeline_form = (state, action) => {
    return updateObject(state,{
        add_stock_pipeline_status: action.add_stock_pipeline_status,
        update_stock_pipeline_status: action.update_stock_pipeline_status,
        edit_stockpipeline_state: action.edit_stockpipeline_state,
        reset_google_map: action.reset_google_map,
        stockpipeline_form_error: null,
        //reset for edit map
        edit_map_address: null,
        edit_lat: null,
        edit_lng: null
    });
}

const set_edit_map_address = (state, action) => {
    return updateObject(state,{
        edit_map_address: action.edit_map_address,
        edit_stockpipeline_state: action.edit_stockpipeline_state,
        edit_lat: action.edit_lat,
        edit_lng: action.edit_lng
    })
}

function add_property_reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.ADD_STOCK_PIPELINE: return add_stock_pipeline(state, action);
        case actionTypes.UPDATE_STOCK_PIPELINE: return update_stock_pipeline(state, action);
        case actionTypes.RESET_GOOGLE_MAP: return reset_google_map(state, action);
        case actionTypes.RESET_ADD_STOCK_PIPELINE: return reset_add_stockpipeline_form(state, action);
        case actionTypes.SUBMIT_FAIL: return submit_fail(state, action);
        case actionTypes.SET_EDIT_MAP_ADDRESS: return set_edit_map_address(state, action);
        default:
            return state;
    }
}

export default add_property_reducer;
