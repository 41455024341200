import * as actionTypes from '../actions/actionsTypes'; 
import {updateObject} from '../utility';


const initialState = {
    agent_list_appointment: null,
    count: null,

    appt_lables_appointment: null,
    chart_appt_count_appointment: null,
    //temp_chart_appointment_count: null
    temp_chart_appt_count: null,
    
    general_lables_appointment: null,
    chart_general_count_appointment: null,
    temp_chart_general_count: null
}

const get_appointment_list = (state, action) =>{
    return updateObject(state,{
        agent_list_appointment: action.agent_list,
        count: action.count,
    });
}

const get_appointment_chart = (state, action) =>{
    const appointment_graph_data = JSON.parse(JSON.stringify(action.chart_appt_count));
    const non_active_agent_buttons = localStorage.getItem('non_active_agent_buttons') ? JSON.parse(localStorage.getItem('non_active_agent_buttons')) : [];
    if(non_active_agent_buttons){
        // eslint-disable-next-line
        non_active_agent_buttons.map((agent_id) =>{
            delete appointment_graph_data[0][agent_id];
        })
        return updateObject(state, {
            appt_lables_appointment: action.appt_lables,
            chart_appt_count_appointment: appointment_graph_data,
            temp_chart_appt_count : action.temp_chart_appointment_count
        })
    }else{
        return updateObject(state,{
            appt_lables_appointment: action.appt_lables,
            chart_appt_count_appointment: action.chart_appt_count,
            temp_chart_appt_count : action.temp_chart_appointment_count
        });
    }
}

const get_general_chart = (state, action) =>{
    const general_graph_data = JSON.parse(JSON.stringify(action.chart_general_count));
    const non_active_agent_buttons = localStorage.getItem('non_active_agent_buttons') ? JSON.parse(localStorage.getItem('non_active_agent_buttons')) : [];
    if(non_active_agent_buttons){
        // eslint-disable-next-line
        non_active_agent_buttons.map((agent_id) =>{
            delete general_graph_data[0][agent_id];
        })
        return updateObject(state, {
            general_lables_appointment: action.general_lables,
            chart_general_count_appointment: general_graph_data,
            temp_chart_general_count : action.temp_chart_general_count
        })
    }else{
        return updateObject(state,{
            general_lables_appointment: action.general_lables,
            chart_general_count_appointment: action.chart_general_count,
            temp_chart_general_count : action.temp_chart_general_count
        });
    }
}

const toggle_appointment_page_chart = (state, action) =>{
    const appointment_graph_data = JSON.parse(JSON.stringify(state.chart_appt_count_appointment));
    if(appointment_graph_data[0][action.agent_id]){
        delete appointment_graph_data[0][action.agent_id];
        return updateObject(state, {chart_appt_count_appointment: appointment_graph_data});
    }else{
      const data = state.chart_appt_count_appointment.map((index)=>{
        return {...index, [action.agent_id]: state.temp_chart_appt_count[0][action.agent_id]};
      });
      return updateObject(state, {chart_appt_count_appointment: data});
    }
}

const toggle_appointment_page_general_chart = (state, action) =>{
    const general_graph_data = JSON.parse(JSON.stringify(state.chart_general_count_appointment));
    if(general_graph_data[0][action.agent_id]){
        delete general_graph_data[0][action.agent_id];
        return updateObject(state, {chart_general_count_appointment: general_graph_data});
    }else{
      const data = state.chart_general_count_appointment.map((index)=>{
        return {...index, [action.agent_id]: state.temp_chart_general_count[0][action.agent_id]};
      });
      return updateObject(state, {chart_general_count_appointment: data});
    }
}

function appointment_reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.GET_AGENT_LIST_APPOINTMENT: return get_appointment_list(state, action);
        case actionTypes.GET_APPOINTMENT_CHART: return get_appointment_chart(state, action);
        case actionTypes.GET_GENERAL_CHART: return get_general_chart(state, action);
        case actionTypes.GET_FILTERED_AGENT_LIST_APPOINTMENT: return get_appointment_list(state, action);
        case actionTypes.TOGGLE_APPOINTMENT_PAGE_CHART: return toggle_appointment_page_chart(state, action);
        case actionTypes.TOGGLE_APPOINTMENT_PAGE_GENERAL_CHART: return toggle_appointment_page_general_chart(state, action);
        default:
        return state;
    }
}
export default appointment_reducer;
