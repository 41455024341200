import * as actionTypes from './actionsTypes';
import Constants from '../../constants/Constants';
var myHeaders = '';
var raw = '';
var requestOptions = '';
var url='';

export const get_property_list = (token, filter_by) =>{
    return dispatch =>{
        url= Constants.PROPERTIES_LIST;
        myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization",token);
        raw = JSON.stringify(
            {"token":token,
            "filter_by": filter_by
            }
        );
        requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };
        fetch(url, requestOptions)
        .then(response => response.json())
        .then(data => {      
            data.data = data.data.filter((_, index) => data.data.hasOwnProperty(index));
            dispatch(property_list(data.data));
        }); 
        // this.$el = $(this.el);
        // this.$el.DataTable({
        //     'paging'      : true,
        //     'lengthChange': true,
        //     'searching'   : true,
        // });
    };
}
export const property_list = (data) => {
    for(let i =0; i < data.length; i++){
        if(Number(data[i].tracking_prefrence) === Number(0) ){
            delete data[i];
        }
    }
    return {
        type: actionTypes.GET_PROPERTY_LIST,
        data: data,
    };
}

export const get_agent_list = (month, year, token)=>{
    return dispatch =>{
        url = Constants.AGENT_LIST;
        myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization",token);
        raw = JSON.stringify({
            "month":month,
            "year":year
        });
        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(url, requestOptions)
        .then(response => response.json())
        .then(data => {
            dispatch(agent_list(data));
            //this.setState({agent_list:data});
        });
    }
}
export const agent_list = (data) => {
    return {
        type: actionTypes.GET_AGENT_LIST,
        agent_data: data,
    };
}

export const get_property_chart_property = (fromDate, toDate ,token)=>{
    return dispatch =>{
        url = Constants.PROPERTY_CHART;
        myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token);
        raw = JSON.stringify(
        {
            "from": fromDate,
            "to": toDate
        });
        requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };
        fetch(url, requestOptions)
        .then(response => response.json())
        .then(data => {
            dispatch(property_chart(data));
        })
    }
}
export const property_chart = (data)=>{
    return{
        type: actionTypes.GET_PROPERTY_CHART,
        property_labels: data.data.labels,
        property_agen_count: data.data.agent_prop_cnt,
        temp_chart_property_count : data.data.agent_prop_cnt
    }
}

export const get_property_list_date = (filter_by, fromDate, toDate ,token) => {
    return dispatch =>{
        url= Constants.PROPERTIES_FILTER;
        myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization",token);
        
        raw = JSON.stringify(
        {"token":token,
            "from":fromDate,
            "to":toDate,
            "filter_by": filter_by
        });
        
        requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };
        fetch(url, requestOptions)
        .then(response => response.json())
        .then(data => {
            dispatch(property_list(data));
        })
        .catch(error => console.log('error', error));
    }
}
export const toggle_property_page_chart = (agent_id) =>{
    return{
        type: actionTypes.TOGGLE_PROPERTY_PAGE_CHART,
        agent_id: agent_id
    }
}

export const set_filter_by = (value) => {
    return {
        type: actionTypes.SET_FILTER_BY,
        filter_by: value
    };
}
