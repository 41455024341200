import React,{ Component } from "react";
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import Swal from "sweetalert2";  

import ReactTooltip from "react-tooltip";
// import Constants from '../../constants/Constants';
import { Icon } from '@iconify/react';
import upgradeIcon from '@iconify/icons-grommet-icons/upgrade';
import informationIcon from '@iconify/icons-mdi/information';
//modules
import Button from 'react-bootstrap/Button';
import { Dropdown } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css'

class UpdateSubQuantityModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            display_submit_btn: 'none',
            quantity: this.props.quantity,
            subscription_id: null,
            subscription_plan_no: null,
        }
        this.wrapperRef = React.createRef();
        this.setwrapperRef = React.createRef();
        this.submitHandler = this.submitHandler.bind(this);
        this.HandleSubQuantity = this.HandleSubQuantity.bind(this)
        this.HandleAddQuantity = this.HandleAddQuantity.bind(this)
        this.handleSelect = this.handleSelect.bind(this)
    }

    submitHandler = async (event) =>{
        event.preventDefault(); 
        const token = localStorage.getItem("token");
        if(!token){
            localStorage.clear();
            this.props.history.push("/");
            return;
        }
        this.setState({ 
            display_submit_btn: 'none'
        })

        Swal.fire({  
            title: 'You are about to upgrade your '+this.state.subscription_plan_no+' plan to the number of agents: '+ this.state.quantity,  
            type: 'question',  
            
            allowEscapeKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Proceed",
            reverseButtons: true,
            cancelButtonText: "Cancel",
            confirmButtonColor:"#27AFAF",
            cancelButtonColor:"#EA676A"
        }).then (async (result) => {
            if (result.value) {  
                await this.props.upgrade_subscription_quantity(this.state.subscription_id, this.state.quantity, token);
                if(this.props.upgrade_next_payment){
                    this.props.get_transaction_log();
                    Swal.fire(
                        'Success!',
                        '<strong>Hooray!🥳</strong> Your '+this.state.subscription_plan_no+' has been upgraded successfully <br/> (Agents: '+this.state.quantity+') ',
                        'success'
                    )
                    window.$("#update_sub_quantity_Modal").modal('hide');
                    //this.props.closeModal();
                }else{
                    Swal.fire(
                        'Error!',
                        'Something went wrong please try again',
                        'error'
                        )
                    window.$("#update_sub_quantity_Modal").modal('hide');
                    //this.props.closeModal();
                    this.setState({ 
                        display_submit_btn: 'block'
                    })
                }
            }else{
                this.setState({ 
                    display_submit_btn: 'block'
                })
            } 
        }) 
    }

    HandleSubQuantity = () => {
        if( Number(this.state.quantity-1) <= Number(this.props.quantity) ){
            this.setState({
                quantity: Number(this.props.quantity)
            })
        }else{
            this.setState({
                quantity: Number(this.state.quantity) - 1,
            }) 
        }

        if(!this.state.subscription_id || (Number(this.state.quantity-1) <= Number(this.props.quantity)) ) {
            this.setState({
                display_submit_btn: 'none'
            })
        }else{
            this.setState({
                display_submit_btn: 'block'
            })
        }
    }

    HandleAddQuantity = () => {
        this.setState({
            quantity: Number(this.state.quantity) + 1,
        })
        if(this.state.subscription_id){
            this.setState({
                display_submit_btn: 'block'
            })
        }else{
            this.setState({
                display_submit_btn: 'none'
            })
        }
    }

    handleSelect = (e, {value}) => {
        e.persist();
        this.setState({ subscription_id: value,
            subscription_plan_no: e.target.textContent
        });
        
        if(this.state.quantity !== this.props.quantity){
            this.setState({ display_submit_btn: 'block' })
        }
    }

    render(){
        let stateOptions=[];
        if(this.props.subscriptions){
            for(let i= 0; i < this.props.subscriptions.length; i++ ){
                stateOptions = [
                    {
                        key: i,
                        text: 'Subscription #'+(i+1),
                        value: this.props.subscriptions[i]
                    },
                ]
            }
        }
        
        return(
            <div>
                <div className="modal fade" id="update_sub_quantity_Modal" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={this.setwrapperRef}>
                    <div className="modal-dialog" role="document" ref={this.wrapperRef}>

                        <div className="modal-content modalheight" style ={{borderRadius : '12px' }} >
                            <div className="modal-header" style={{ backgroundColor: "#EDF6F6"}}>
                                <h4 className="modal-title fontWeight600"> 
                                    Add users to my subscription
                                </h4>
                                <div className="circle" data-dismiss="modal" /*onClick={this.props.closeModal}*/>
                                    <img src="images/modal-close.png " width="12" height="12" alt="" />
                                </div> 
                            </div>
                            <div className="modal-body">
                                <form id="update_sub_qty_form" onSubmit={this.submitHandler} style={{paddingTop: 25}}>
                                    <center>
                                    <div className="select-wrap" style={{width:'220px'}}>
                                        <label style={{float:'left'}}>Select Subscription</label>
                                        <Dropdown placeholder='Select Subscription' selection options={stateOptions} onChange={this.handleSelect}/>
                                    </div>
                                    <div className="shadow" style={{borderRadius:8, marginTop:30, marginBottom:30, width:'92%'}}>
                                        <h3 style={{paddingTop: 25}}>Set the Number of Agents</h3>
                                        {/* <Dropdown placeholder='State' selection options={stateOptions} /> */}
                                        <div className='form_border' style={{width:'93%'}}>
                                            <div className='full-input' style={{marginTop: 10}}>
                                                <ReactTooltip resizeHide = { true } backgroundColor={'#0B2C4C'}  />
                                                <span style={{display:'inline-block'}}><Button className="paddle_button" style={{background: "#117777", borderColor: "#117777", fontWeight: "600", marginRight: 15, fontSize:14}}  onClick={this.HandleSubQuantity}> - </Button></span>
                                                <span style={{display:'inline-block'}}><input className="input_modal" style={{textAlign:"center"}} type='text' name='quantity' id="quantity" value={this.state.quantity} placeholder={"Min Value is : "} required readOnly pattern=".*\S+.*" title="This field is required"></input></span>
                                                <span style={{display:'inline-block'}}><Button className="paddle_button" style={{background: "#117777", borderColor: "#117777", fontWeight: "600", marginLeft: 15, fontSize:14}}  onClick={this.HandleAddQuantity}> + </Button></span>
                                                <span style={{display:'inline-block', float:'right'}}><Icon icon={informationIcon} className="color4AA49C" data-place="top" data-tip={"Number of Agents cannot be reduced below your current number of agents which is "+this.props.quantity} style={{fontSize: '24px', verticalAlign: "bottom"}}/></span>
                                            </div>
                                            <br/>
                                        </div>
                                        <br/>
                                        <div style={{paddingBottom:30}}>
                                            <Button type="submit" className="btn btn-primary fontBold" style={{ background:'#117777',borderRadius:'4px', padding:10, display: this.state.display_submit_btn }}>
                                                <Icon icon={upgradeIcon} style={{fontSize:'20px', verticalAlign:'bottom'}} />&nbsp;&nbsp;
                                                Upgrade Quantity
                                            </Button>
                                        </div>
                                    </div>
                                    </center>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapDispatchToProps = dispatch => {
    return {
        upgrade_subscription_quantity: (subscription_id, quantity, token) => dispatch(actions.upgrade_subscription_quantity(subscription_id, quantity, token)),
        get_transaction_log : () => dispatch(actions.get_transaction_log()),
    }
}
const mapStateToProps = state => {
    return{
        upgrade_next_payment: state.transaction_log.upgrade_next_payment,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UpdateSubQuantityModal);
