import * as actionTypes from '../actions/actionsTypes'; 
import {updateObject} from '../utility';

const initialState = {
    show_agents: 0,    //0 - new appointment from page, 1 - new appointment from agent card, property card, stockpipeline card 
    show_property: 0,    //0 - Don't Show Property list, 1 - Show Property List 
    from_property_page: 0, // 0 - Not from Property Page, 1 - From Property page
    appointment_form_error: null,
    from_stock_pipeline: 0,
    add_appointment_success: null
}
const viewing_appointment =(state, action) => {
    return updateObject(state,{
        add_appointment_success: action.add_appointment_success
    })
}

const general_appointment =(state, action) => {
    return updateObject(state,{
        add_appointment_success: action.add_appointment_success
    })
}

const update_add_appointment_modal_state = (state, action) =>{
    return updateObject(state,{
        show_agents: action.show_agents,
        show_property: action.show_property,
        from_property_page: action.from_property_page,
        from_stock_pipeline: action.from_stock_pipeline
    })
}

const appointment_submit_fail = (state, action) => {
    return updateObject(state,{
        appointment_form_error: action.appointment_form_error,
        add_appointment_success: action.add_appointment_success
    })
}

const reset_appointment_modal_error = (state, action) => {
    return updateObject(state,{
        appointment_form_error: null,
        add_appointment_success: null
    })
}

function add_appointment_reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.VIEWING_APPOINTMENT: return viewing_appointment(state, action);
        case actionTypes.GENERAL_APPOINTMENT: return general_appointment(state, action);
        case actionTypes.UPDATE_ADD_APPOINTMENT_MODAL_STATE: return update_add_appointment_modal_state(state, action);
        case actionTypes.APPOINTMENT_SUBMIT_FAIL: return appointment_submit_fail(state, action);
        case actionTypes.RESET_APPOINTMENT_MODAL_ERROR: return reset_appointment_modal_error(state, action);
        
        default:
            return state;
    }
}

export default add_appointment_reducer;