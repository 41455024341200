import React,{ Component } from "react";
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import modal from '../../../CustomFiles/js/modal';
import Swal from 'sweetalert2';

import { Calendar } from 'primereact/calendar';
//import ReactTooltip from 'react-tooltip';
import { Icon } from '@iconify/react';
import bxCalendarEvent from '@iconify/icons-bx/bx-calendar-event';

import ErrorText from '../../../auth/ErrorText';
import SuccessText from '../../../auth/SuccessText';

class EditViewingAppointment extends Component{
    constructor(props) {
        super(props);
        this.state = {
            startDate: null,
            time: null
        }
        this.setViewingDate = this.setViewingDate.bind(this);
        this.submitViewingAppointmentHandler = this.submitViewingAppointmentHandler.bind(this);
    }

    appointmentSuccess_sweetalert(){
        Swal.fire({
            icon: 'success',
            title: 'Appointment Updated successfully ',
            showConfirmButton: true,
        })
    }
    appointmentError_sweetalert(){
        Swal.fire({
            icon: 'error',
            title: 'Something went wrong',
            showConfirmButton: true,
        })
    }
    
    changeDateFormat(fromDate) {
        var d = new Date(fromDate),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [year, month, day].join('-');
    }

    getCalendarData = (e) => {
        this.setState({startDate: this.changeDateFormat(e.value)})
    }
    
    setTime = (e) => {
        this.setState({time: e.value})
    }

    componentDidMount() {
        modal();
        this.props.setViewingDateTime(this.setViewingDate);
    }
    
    setViewingDate = ()=>{
        var currentTime = this.props.viewing_appointment.appointment_date.split(" ")
        this.setState({
            startDate: new Date(currentTime[0]),
            time: new Date(this.props.viewing_appointment.appointment_date)
        })
    }

    submitViewingAppointmentHandler = async (event)=>{
        event.preventDefault(); 
        let authtoken = this.props.authtoken;
        let appointment_date = (this.state.startDate && this.state.time) ? this.changeDateFormat(this.state.startDate)+" "+ this.state.time.getHours()+":"+this.state.time.getMinutes() : null;
        let appointment_id = this.props.viewing_appointment.appointment_id.toString()

        await this.props.update_viewing_appointment(authtoken, appointment_id, appointment_date);
        if(Number(this.props.update_viewing_appointment_success) === 1){
            this.appointmentSuccess_sweetalert();
            window.$("#editViewingAppointmentModal").modal('hide');
            var da = new Date();
            var fromDate = this.changeDateFormat(new Date(da.getFullYear(), da.getMonth() - 2, 1));
            var toDate = this.changeDateFormat(new Date(da.getFullYear(), da.getMonth(), da.getDate()));
            let prop_id = this.props.viewing_appointment.property_id
            let agent_id = this.props.viewing_appointment.user_id
            const token = localStorage.getItem('token');
            let date_sold = this.props.viewing_appointment.date_sold
            this.props.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold);
        }else if(Number(this.props.update_viewing_appointment_success) === 0){
            this.appointmentError_sweetalert();
        }
    }

    render(){
        return(
            <div>
                <div className="modal fade" data-backdrop="static" data-keyboard="false" id="editViewingAppointmentModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={this.setwrapperRef} >
                    <div className="modal-dialog" role="document" ref={this.wrapperRef}>
                        <div className="modal-content modal_edit_viewing_appointment modalheight" style ={{borderRadius : '12px' }} >
                            <div className="modal-header" style={{ backgroundColor: "#EDF6F6"}}>
                                <h4 className="modal-title fontWeight600"> <Icon className="message_icon color27AFAF mr5" style={{verticalAlign: 'bottom',}} icon={bxCalendarEvent} height='25' width='25' /> Edit Viewing Appointment</h4>
                                <div id="closeModal_edit_viewing_appointment" className="circle" data-dismiss="modal" onClick={this.closeModal_Edit_Viewing_Appointment}>
                                    <img src="images/modal-close.png " width="12" height="12" alt=""/>
                                </div> 
                            </div>
                            
                            {this.props.appointment_form_error ? 
                                this.props.appointment_form_error === "success" ? 
                                    <SuccessText successMsg = {this.props.appointment_form_error} /> 
                                :
                                    <ErrorText errorMsg = {this.props.appointment_form_error} />
                            : 
                                null
                            }
                            {
                                //Edit Viewing appointment form
                                <div className="modal-body">
                                    <center>
                                    <form id="editViewingAppointmentform" onSubmit={this.submitViewingAppointmentHandler}>
                                        {/* Date Time */}
                                        <div className="shadow" style={this.props.authtoken !== null ? {display: "block"} : {display: this.state.display_card} }>
                                            <p className="cardHeader">When would they like to view?</p>
                                            <div className="row">
                                                <div className="col-md-4 col-md-offset-1">
                                                    <input type="text" className="from_label" value="Select Date:" style={{width:"auto", left:"40px"}} disabled/>
                                                    <Calendar value={this.state.startDate} /*maxDate={this.state.endDate}*/ dateFormat="dd-mm-yy" name="select_date" id="select_date" style={{textAlign: 'left'}}
                                                        onChange={(e) => {this.setState({ startDate: e.value }); this.getCalendarData(e)}}>
                                                    </Calendar>
                                                </div>
                                                <div className="col-offset-md-1 col-md-4">
                                                    <input type="text" className="from_label" value="Select Time:" style={{width:"auto", left:"40px"}} disabled/>
                                                    <Calendar timeOnly showTime hourFormat="12" value={this.state.time} onChange={(e) => this.setTime(e)} id="select_time"></Calendar>
                                                </div>
                                            </div>
                                            <br/><br/>
                                        </div>
                                        <br/><br/>
                                        
                                        <div style={this.props.authtoken !== null ? {display: "block"} : {display: this.state.display_card} }> 
                                        {/* <div>  */}
                                            <button id="editViewingAppointmentBtn" type="submit" className="btn btn-primary fontBold" style={{ background:'#4AA49C',borderRadius:'4px'}}>
                                                <Icon className="message_icon mr5" style={{verticalAlign: 'bottom'}} icon={bxCalendarEvent} height='20' width='20' />
                                                Edit Appointment
                                            </button>
                                        </div>
                                        <br/>
                                    </form>
                                    </center>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )   
    }    
}

const mapDispatchToProps = dispatch => {
    return {
        update_viewing_appointment : (authtoken, appointment_id, appointment_date) => dispatch(actions.update_viewing_appointment(authtoken, appointment_id, appointment_date)), 
        get_property_data_modal: (fromDate, toDate, prop_id, agent_id, token, date_sold) => dispatch(actions.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold)),
    }
}
const mapStateToProps = state => {
    return{
        authtoken: state.add_property_modal.authtoken,
        viewing_appointment: state.edit_appointment_modal.viewing_appointment,
        update_viewing_appointment_success: state.edit_appointment_modal.update_viewing_appointment_success
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditViewingAppointment);