import * as actionTypes from '../actions/actionsTypes'; 
import {updateObject} from '../utility';

const initialState = {
    viewing_appointment: null,
    general_appointment: null,
    update_viewing_appointment_success: null,
    update_general_appointment_success: null,
    update_appointment_form_error: null
}

const get_viewing_appointment =(state, action) => {
    return updateObject(state,{
        viewing_appointment: action.viewing_appointment
    })
}

const get_general_appointment =(state, action) => {
    return updateObject(state,{
        general_appointment: action.general_appointment
    })
}

const reset_appointment_data = (state) =>{
    return updateObject(state,{
        viewing_appointment: null,
        general_appointment: null,
        update_appointment_form_error: null
    })
}
const update_viewing_appointment = (state, action) =>{
    return updateObject(state,{
        update_viewing_appointment_success: action.update_viewing_appointment_success
    })
}
const update_general_appointment = (state, action) =>{
    return updateObject(state,{
        update_general_appointment_success: action.update_general_appointment_success
    })
}
const update_appointment_submit_fail = (state, action) =>{
    return updateObject(state,{
        update_general_appointment_success: action.update_general_appointment_success,
        update_appointment_form_error: action.update_appointment_form_error
    })
}

function edit_appointment_reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.GET_VIEWING_APPOINTMENT: return get_viewing_appointment(state, action);
        case actionTypes.GET_GENERAL_APPOINTMENT: return get_general_appointment(state, action);
        case actionTypes.EDIT_VIEWING_APPOINTMENT: return update_viewing_appointment(state, action);
        case actionTypes.EDIT_GENERAL_APPOINTMENT: return update_general_appointment(state, action);
        case actionTypes.UPDATE_APPOINTMENT_SUBMIT_FAIL: return update_appointment_submit_fail(state, action);
        case actionTypes.RESET_APPOINTMENT_DATA :return reset_appointment_data(state);
        default:
            return state;
    }
}

export default edit_appointment_reducer;