import * as actionTypes from '../actions/actionsTypes'; 
import {updateObject} from '../utility';

const initialState = {
    //Overview data
    agent_performance: null,

    //general graph
    general_lables_agent_modal: null,
    chart_general_count_agent_modal: null,

    //property data
    agent_data: null,
    agent_linked_user_cnt: null,
    property_for_sale: null,
    property_for_rent: null,
    property_for_sale_total: null,
    property_for_rent_total: null,
    graph: null,
    linked_user_cnt: null,
    country_code: null,
    currency_symbol: null,

    //stock_pipeline
    stock_pipeline: null,
    stock_load_more: null,
    stock_notes: null,
    stock_pipeline_data: null,
    stockpipeline_labels_by_id: null,
    stockpipeline_count_by_id: null,

    stock_pipeline_property_data: null,
    stock_pipeline_notes: null,
    stock_pipeline_appointments: null,
    from_archiveStockPipeline: null,

    //cold_calls_log
    cold_calls_log: null,
    load_more: null,
    coldcalls_labels_by_id: null,
    coldcalls_count_by_id: null,
    cold_call_export: null,

    //appointment data
    agentAppointmentData: null,
    date_wise_appt: null,
    deleteEvents: null,

    //revenue data
    current_sales: null,
    last_sales: null,
    reve_sales: null,
    
    //other_listing_data
    other_listing_data: null,

    //archived property data
    archived_property: null,
    archive_property_load_more: null,
    archive_property_page: null,

    //networking property data
    office_list: null,
    open_list: null,
    //calendar page
    page: null,
    reset_date_range: false,

    //travel log book
    upcoming_trips: null,
    past_trips: null,
    total_distance: null,
    total_claim_exp: null,
    missed_trips: null,
    upcoming_trips_load_more: null,
    past_trips_load_more: null,
    missed_trips_load_more: null,
}

const get_agent_performance_data = (state, action) => {
    return updateObject(state,{
        agent_data: action.agent_data,
        agent_performance: action.agent_performance
    })
}

const get_property_data = (state, action) => {
    return updateObject(state,{
        agent_data: action.agent_data,
        agent_linked_user_cnt: action.linked_user_cnt,
        property_for_sale: action.property_for_sale,
        property_for_rent: action.property_for_rent,
        property_for_sale_total: action.property_for_sale_total,
        property_for_rent_total: action.property_for_rent_total,
        graph:action.graph,
        linked_user_cnt:action.linked_user_cnt
    })
}

const get_filtered_property_list = (state, action) => {
    if(action.property_type === "sale"){
        return updateObject(state,{
            property_for_sale: action.property_for_sale,
        })
    }else{
        return updateObject(state,{
            property_for_rent: action.property_for_rent,
        })
    }
}

const get_appointment_data_modal = (state, action) => {
    return updateObject(state,{
        //agent_data: action.agent_data,
        agentAppointmentData: action.agentAppointmentData,
        date_wise_appt: action.date_wise_appt,
        deleteEvents: action.deleteEvents
    })
}
const get_general_chart_agent_modal = (state, action) => {
    return updateObject(state,{
        general_lables_agent_modal: action.general_lables_agent_modal,
        chart_general_count_agent_modal: action.chart_general_count_agent_modal,
    })
}
const get_stock_pipeline = (state,action) => {
    if(!state.stock_pipeline || Number(action.stock_page) === 1){
        return updateObject(state,{
            stock_pipeline: action.stock_pipeline,
            stock_load_more: action.stock_load_more,
            //stock_notes: action.stock_notes,
            //stock_pipeline_data: action.stock_pipeline_data
        })
    }else{
        if(action.stock_page === ''){
            return {
                ...state,
                stock_notes: action.stock_notes,
                stock_pipeline_data: action.stock_pipeline_data,
                //stock_load_more: action.stock_load_more,
                //stock_pipeline: action.stock_pipeline ? [...state.stock_pipeline, ...action.stock_pipeline] : state.stock_pipeline,
            };
        }else{
            return {
                ...state,
                stock_load_more: action.stock_load_more,
                stock_pipeline: [...state.stock_pipeline, ...action.stock_pipeline],
            };
        }
    }
}

const get_stock_pipeline_property_data = (state,action) => {
    return updateObject(state, {
        stock_pipeline_property_data: action.stock_pipeline_property_data,
        stock_pipeline_notes: action.stock_pipeline_notes,
        stock_pipeline_appointments: action.stock_pipeline_appointments,
        from_archiveStockPipeline: action.from_archiveStockPipeline
    })
}

const get_cold_call_logs = (state, action) => {
    if(!state.cold_calls_log || Number(action.page) === 1){
        return updateObject(state,{
            cold_calls_log: action.cold_calls_log,
            load_more: action.load_more
        })
    }else{
        return {
            ...state,
            cold_calls_log: [...state.cold_calls_log, ...action.cold_calls_log],
            load_more: action.load_more
        };
    }
}

const get_cold_call_logs_reports = (state,action) => {
    return updateObject(state,{
        cold_call_export: action.cold_call_export,
    })
}

const get_revenue_data_modal = (state, action) => {
    return updateObject(state,{
        current_sales: action.current_sales,
        last_sales: action.last_sales,
        reve_sales: action.reve_sales
    })
}

const get_other_listing_data = (state, action) => {
    return updateObject(state,{
        other_listing_data: action.other_listing_data
    })
} 

const reset_agent_modal = (state) =>{
    return updateObject(state, initialState)
}

const get_all_agent_data = (state, action) =>{
    return updateObject(state,{
        country_code: action.country_code,
        currency_symbol: action.currency_symbol
    })
}

const get_stockpipeline_chart_by_id = (state, action) => {
    return updateObject(state,{
        stockpipeline_labels_by_id: action.stockpipeline_labels_by_id,
        stockpipeline_count_by_id: action.stockpipeline_count_by_id
    })
}

const get_coldcalls_chart_by_id = (state, action) => {
    return updateObject(state,{
        coldcalls_labels_by_id: action.coldcalls_labels_by_id,
        coldcalls_count_by_id: action.coldcalls_count_by_id
    })
}

const get_archived_property_data = (state, action) =>{
    if(!state.archived_property || Number(action.archive_property_page) === 1){
        return updateObject(state,{
            archive_property_load_more: action.archive_property_load_more,
            archive_property_page: action.archive_property_page,
            archived_property: action.archived_property,
        })
    }else{
        return {
            ...state,
            archive_property_load_more: action.archive_property_load_more,
            archive_property_page: action.archive_property_page,
            archived_property: [...state.archived_property, ...action.archived_property],
        };
    }
}

const get_networking_data = (state, action )=>{
    return updateObject(state,{
        office_list: action.office_list,
        open_list: action.open_list
    })
}

const get_upcoming_trips =(state, action) => {
    if(!state.upcoming_trips || Number(action.page) === 1){
        return updateObject(state,{
            upcoming_trips: action.upcoming_trips,
            upcoming_trips_load_more: action.upcoming_trips_load_more,
        })
    }else{
        return {
            ...state,
            upcoming_trips: [...state.upcoming_trips, ...action.upcoming_trips],
            upcoming_trips_load_more: action.upcoming_trips_load_more
        };
    }
}
const get_past_trips =(state, action) => {
    if(!state.past_trips || Number(action.page) === 1){
        return updateObject(state,{
            past_trips: action.past_trips,
            total_distance: action.total_distance,
            total_claim_exp: action.total_claim_exp,
            past_trips_load_more: action.past_trips_load_more,
        })
    }else{
        return {
            ...state,
            past_trips: [...state.past_trips, ...action.past_trips],
            total_distance: action.total_distance,
            total_claim_exp: action.total_claim_exp,
            past_trips_load_more: action.past_trips_load_more
        };
    }
}
const get_missed_trips =(state,action) => {
    if(!state.missed_trips || Number(action.page) === 1){
        return updateObject(state,{
            missed_trips: action.missed_trips,
            missed_trips_load_more: action.missed_trips_load_more,
        })
    }else{
        return {
            ...state,
            missed_trips: [...state.missed_trips, ...action.missed_trips],
            missed_trips_load_more: action.upcoming_trips_load_more
        };
    }
}

const reset_stock_pipeline_modal = (state,action)=>{
    return updateObject(state,{
        stock_pipeline_property_data: action.stock_pipeline_property_data
    })
}
const reset_stock_pipeline_data = (state,action) =>{
    return updateObject(state,{
        stock_notes: null,
        stock_pipeline: null,
        stock_load_more: true,
    })
}

const reset_calendar_data = (state,action)=>{
    return updateObject(state,{
        date_wise_appt: action.date_wise_appt,
        deleteEvents: action.deleteEvents
    })
}

const set_page = (state, action)=>{
    return updateObject(state,{
        page: action.page,
        reset_date_range: action.reset_date_range
    })
}

function agent_modal_reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.GET_AGENT_PERFORMANCE_DATA: return get_agent_performance_data(state, action);
        case actionTypes.GET_PROPERTY_DATA:   return get_property_data(state, action);
        case actionTypes.FILTER_PROPERTY_LIST:   return get_filtered_property_list(state, action);
        case actionTypes.GET_APPOINTMENT_DATA_MODAL: return get_appointment_data_modal(state, action);
        case actionTypes.GET_GENERAL_CHART_AGENT_MODAL: return get_general_chart_agent_modal(state, action);
        case actionTypes.GET_STOCK_PIPELINE: return get_stock_pipeline(state, action);
        case actionTypes.GET_STOCK_PIPELINE_PROPERTY_DATA: return get_stock_pipeline_property_data(state, action);
        case actionTypes.RESET_STOCK_PIPELINE_MODAL: return reset_stock_pipeline_modal(state, action);
        case actionTypes.GET_COLD_CALL_LOGS: return get_cold_call_logs(state, action);
        case actionTypes.GET_COLD_CALL_LOGS_REPORTS: return get_cold_call_logs_reports(state, action);
        case actionTypes.GET_REVENUE_DATA_MODAL: return get_revenue_data_modal(state, action);
        case actionTypes.GET_OTHER_LISTING_DATA: return get_other_listing_data(state, action);
        case actionTypes.RESET_AGENT_MODAL: return reset_agent_modal(state);
        case actionTypes.GET_ALL_AGENT_DATA: return get_all_agent_data(state, action);
        case actionTypes.GET_STOCKPIPELINE_CHART_BY_ID: return get_stockpipeline_chart_by_id(state, action);
        case actionTypes.GET_COLDCALLS_CHART_BY_ID: return get_coldcalls_chart_by_id(state, action);
        case actionTypes.GET_ARCHIVED_PROPERTY: return get_archived_property_data(state, action);
        case actionTypes.GET_NETWORKING_PROPERTY: return get_networking_data(state, action);
        case actionTypes.RESET_STOCKPIPELINE_DATA: return reset_stock_pipeline_data(state, action);
        case actionTypes.RESET_CALENDAR_DATA: return reset_calendar_data(state,action);
        case actionTypes.SET_PAGE: return set_page(state,action);
        case actionTypes.GET_UPCOMING_TRIPS: return get_upcoming_trips(state, action);
        case actionTypes.GET_PAST_TRIPS: return get_past_trips(state, action);
        case actionTypes.GET_MISSED_TRIPS: return get_missed_trips(state,action);
        default:
        return state;
    }
}

export default agent_modal_reducer;