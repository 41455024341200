import React,{ Component } from "react";
import {connect} from 'react-redux';
//import * as actions from '../../store/actions/index';
// import Constants from '../../constants/Constants';
import { Icon } from '@iconify/react';
import bxsShareAlt from '@iconify/icons-bx/bxs-share-alt';
import shareIcon from '@iconify/icons-feather/share';
import copyIcon from '@iconify/icons-fa-regular/copy';
import Loader from "../Loader";
import $ from 'jquery';

class AddPropertySuccess extends Component{
    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();
        this.setwrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        //if (!this.wrapperRef.current.contains(event.target)) {
            //if(this.setwrapperRef.current.contains(event.target)){
                //window.$("#addPropertySuccessModal").modal('hide');
                //window.$("#addPropertyModal").modal('hide');
                //this.props.closeModal();
            //}
        //}
    }

    copyShareCode = (id) =>{
        var copyText = document.getElementById(id);
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
        
        $('#copied-success').fadeIn(800);
        $('#copied-success').fadeOut(800);
    }

    render(){        
        var formattedBody = "Your realtor, has loaded your property onto the Roundr app for simple viewing scheduling, feedback, and record keeping. \n Learn more about how it works, and download the app - https://roundr.app/share/ \n After downloading the app, use code "+ this.props.share_code +" to link to your property";
        var mailToLink = "mailto:?subject=Property Code of Roundr Property &body="+formattedBody;
        return(
            <div>
                <div className="modal fade" id="addPropertySuccessModal" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={this.setwrapperRef}>
                    <div className="modal-dialog" role="document" ref={this.wrapperRef}>
                    {Number(this.props.add_property_success) === 1 ? 
                        <div className="modal-content modalheight" style ={{borderRadius : '12px' }} >
                            <div className="modal-header" style={{ backgroundColor: "#EDF6F6"}}>
                                <h4 className="modal-title fontWeight600"> 
                                    <Icon icon={bxsShareAlt} style={{color: '#27afaf', fontSize: '26px', verticalAlign:'bottom'}} /> &nbsp;&nbsp;
                                    Share Property
                                </h4>
                                <div id="closeModal_add_property_success" className="circle" data-dismiss="modal" onClick={this.props.closeModal}>
                                    <img src="images/modal-close.png " width="12" height="12" alt="" />
                                </div> 
                            </div>
                            <div className="modal-body">
                                <center>
                                <img alt="" src = "user-images/sharePropertySuccess.png" />
                                <p className="fontSize15" style={{fontFamily:'Inter', color:'#1B2735', marginTop:10}}>
                                    Almost done...
                                </p>
                                <h1 className="cardHeader" style={{paddingTop: 10}}>Would you like to link up?</h1>
                                {Number(this.props.seller) === 1 ?
                                    Number(this.props.reside) === 1 ?
                                        <p className="propertysucctext"> Get the most out of Roundr by having your seller connect with you.</p>
                                    :
                                        Number(this.props.empty_land) === 0 ? 
                                            <p className="propertysucctext"> Get the most out of Roundr by having your seller/occupant connect with you.</p>
                                        :
                                            <p className="propertysucctext"> Get the most out of Roundr by having your seller connect with you.</p>
                                :
                                    Number(this.props.reside) === 1 ?
                                        <p className="propertysucctext"> Get the most out of Roundr by having the owner connect with you.</p>
                                    :
                                        Number(this.props.empty_land) === 0 ?
                                            <p className="propertysucctext"> Get the most out of Roundr by having the owner/occupant connect with you.</p>
                                        :
                                            <p className="propertysucctext"> Get the most out of Roundr by having the owner connect with you.</p>
                                }
                                
                                <p className="propertysucctext">They need to download the app and enter the following code.</p>
                                <p className="propertysucctext">You can also share this code later.</p>

                                <div className="shadow" style={{borderRadius:8, marginTop:30, marginBottom:30, width:"55%"}}>
                                    <p className="propertysucctext" style={{paddingTop: 22}}>{this.props.seller === 1 ? "Seller": "Owner" }’s Code:</p>
                                    {/* <h1 className="sharecode">{this.props.share_code}</h1> */}
                                    <input className="sharecode mb20" style={{width:"inherit", textAlign:"center", border: "0px"}} value={this.props.share_code} id="seller_code" readOnly={true} />
                                    <br/>
                                    <button className="btn btn-primary fontBold mr15" style={{ background:'#4AA49C', borderRadius:'4px', padding:10, marginBottom:30}} id="copyButton" onClick={() => this.copyShareCode('seller_code')}><Icon icon={copyIcon} style={{fontSize: '20px', verticalAlign: 'bottom'}} /> &nbsp;Copy Code</button>
                                    <a href={mailToLink} className="btn btn-primary fontBold" style={{ background:'#4AA49C', borderRadius:'4px', padding:10, marginBottom:30}}>
                                        <Icon icon={shareIcon} style={{fontSize:'20px', verticalAlign:'bottom'}} />&nbsp;&nbsp;
                                        {/* Share with {this.props.seller === 1 ? "seller": "owner" } */}
                                        Share via email
                                    </a>
                                </div>
                                {this.props.share_code_occupant ?
                                    <div className="shadow" style={{borderRadius:8, marginTop:30, marginBottom:30, width:"55%"}}>
                                        <p className="propertysucctext" style={{paddingTop: 22}}>Occupant’s Code:</p>
                                        {/* <h1 className="sharecode">{this.props.share_code_occupant}</h1> */}
                                        <input className="sharecode mb20" style={{width:"inherit", textAlign:"center", border: "0px"}} value={this.props.share_code_occupant} id="occupant_code" readOnly={true} />
                                        <br/>
                                        <button className="btn btn-primary fontBold mr15" style={{ background:'#4AA49C', borderRadius:'4px', padding:10, marginBottom:30}} id="copyButton" onClick={() => this.copyShareCode('occupant_code')}><Icon icon={copyIcon} style={{fontSize: '20px', verticalAlign: 'bottom'}} /> &nbsp;Copy Code</button>
                                        <a href={mailToLink} className="btn btn-primary fontBold" style={{ background:'#4AA49C', borderRadius:'4px', padding:10, marginBottom:30}}>
                                            <Icon icon={shareIcon} style={{fontSize:'20px', verticalAlign:'bottom'}} />&nbsp;&nbsp;
                                            Share via email
                                        </a>
                                    </div>
                                    : null
                                }
                                </center>
                            </div>
                        </div>
                    : 
                        Number(this.props.edit_property_success) === 1 ?
                            <div className="modal-content modalheight" style ={{borderRadius : '12px' }} >
                                <div className="modal-header" style={{ backgroundColor: "#EDF6F6"}}>

                                    <h4 className="modal-title fontWeight600"> 
                                        <Icon icon={bxsShareAlt} style={{color: '#27afaf', fontSize: '26px', verticalAlign:'bottom'}} /> &nbsp;&nbsp;
                                        Save Property
                                    </h4>
                                    <div id="closeModal_add_property_success" className="circle" data-dismiss="modal" onClick={this.props.closeModal}>
                                        <img src="images/modal-close.png " width="12" height="12" alt="" />
                                    </div> 
                                </div>
                                <div className="modal-body">
                                    <center>
                                    <img alt="" src = "user-images/sharePropertySuccess.png" />
                                    <p className="fontSize15" style={{fontFamily:'Inter', color:'#1B2735', marginTop:10}}>
                                        Property has been Updated
                                    </p>                                
                                    </center>
                                </div>
                            </div>
                        : 
                            this.props.add_property_success === null || this.props.edit_property_success === null ?
                                <div className="modal-content modalheight" style ={{borderRadius : '12px' }} >
                                    <div className="modal-header" style={{ backgroundColor: "#EDF6F6"}}>
                                        <h4 className="modal-title fontWeight600"> 
                                            Loading
                                        </h4>
                                        <div id="closeModal_add_property_success" className="circle" data-dismiss="modal" onClick={this.props.closeModal}>
                                            <img src="images/modal-close.png " width="12" height="12" alt="" />
                                        </div> 
                                    </div>
                                    <div className="modal-body">
                                        <center>
                                        <Loader/>
                                        </center>
                                    </div>
                                </div>
                            :
                                Number(this.props.add_property_success) === 0 || Number(this.props.edit_property_success) === 0 ?
                                    <div className="modal-content modalheight" style ={{borderRadius : '12px' }} >
                                        <div className="modal-header" style={{ backgroundColor: "#EDF6F6"}}>
                                            <h4 className="modal-title fontWeight600"> 
                                                Error
                                            </h4>
                                            <div id="closeModal_add_property_success" className="circle" data-dismiss="modal" onClick={this.props.closeModal}>
                                                <img src="images/modal-close.png " width="12" height="12" alt="" />
                                            </div> 
                                        </div>
                                        <div className="modal-body">
                                            <center>
                                            <img alt="" src = "user-images/sharePropertyError.png" />
                                            <p className="fontSize15" style={{fontFamily:'Inter', color:'#1B2735', marginTop:10}}>
                                                Something has gone wrong. please try again
                                            </p>                                
                                            </center>
                                        </div>
                                    </div> 
                                : null
                    }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return{
        seller: state.add_property_modal.seller,
        reside: state.add_property_modal.reside,
        empty_land: state.add_property_modal.empty_land,
        add_property_success: state.add_property_modal.add_property_success,
        edit_property_success: state.add_property_modal.edit_property_success,
        share_code: state.add_property_modal.share_code,
        share_code_occupant: state.add_property_modal.share_code_occupant
    }
}
export default connect(mapStateToProps, null)(AddPropertySuccess);
