import React,{ Component } from "react";
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';

import AgentPropertyModalChart from '../modal_charts/AgentPropertyModalChart';
import Loader from '../../components/Loader';
import Constants from '../../constants/Constants';
//import ReactTooltip from 'react-tooltip';
//import AgentComment from "./AgentComment";
//import SimpleMap from './SimpleMap';
import Moment from 'react-moment';

//import { Calendar } from 'primereact/calendar';
// eslint-disable-next-line
import TableToExcel from "@linways/table-to-excel";
import ShareCode from './ShareCode';
import MarkAsSold from './MarkAsSoldModal';
import MarkAsRented from './MarkAsRentedModal';
import MarkAsRentedUpdate from './MarkAsRentedUpdateModal';
import CrmVerifiedModal from './CRMInfoModal/CrmVerifiedModal';
import CrmNotVerifiedModal from './CRMInfoModal/CrmNotVerifiedModal';
import EditViewingAppointment from './Appointment/EditViewingAppointment';
import EditGeneralAppointment from './Appointment/EditGeneralAppointment';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

//icons
import { Icon } from '@iconify/react';
//import webIcon from '@iconify/icons-mdi/web';
import downloadFill from '@iconify/icons-eva/download-fill';
import chatRightTextFill from '@iconify/icons-bi/chat-right-text-fill';
import editOutlined from '@iconify/icons-ant-design/edit-outlined';
import menuButtonWide from '@iconify/icons-bi/menu-button-wide';
import shareRounded from '@iconify/icons-grommet-icons/share-rounded';
import eyeOpen from '@iconify/icons-akar-icons/eye-open';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import clockHistory from '@iconify/icons-bi/clock-history';
import receiptIcon from '@iconify/icons-bi/receipt';
import DateRange from "../DateRange";
import bxCalendarEvent from '@iconify/icons-bx/bx-calendar-event';
import editIcon from '@iconify/icons-ci/edit';

//modal
//import AddProperty from './AddProperty';

//const $ = require('jquery');
var g_prop_id = '';
class PropertyDetailModal extends Component{
  constructor(props){
    super(props);
    this.state = { 
      startDate: new Date(new Date().getFullYear(), 0, 1),
      endDate:   new Date(new Date().getFullYear(), 11, 31),
      CalenderMinDate: new Date(new Date().getFullYear(), 0, 1),
      visible: false,
      viewings_active: true,
      general_active: true,
    }
    this.closeModal = this.closeModal.bind(this);
    this.handleAppointmentEdit = this.handleAppointmentEdit.bind(this);
  }
  handleEditPropertyModal = () => {
    this.auth_data();
    window.$("#addPropertyModal").modal('show');
  }
  
  handleMarkAsSold = () => {
    window.$("#markAsSoldModal").modal('show');
  }
  handleMarkAsRented = () => {
    window.$("#markAsRentedModal").modal('show');
  }
  handleManageRent = () => {
    window.$("#markAsRentedUpdateModal").modal('show');
  }
  async auth_data(){
    var token = localStorage.getItem('token');
    await this.props.get_auth_data(this.props.agent_id, token);
    await this.props.get_edit_property_data(g_prop_id, this.props.authtoken);
    var add_property_modal_state = 2;
    this.props.update_add_property_modal_state(add_property_modal_state);
  }
  
  closeModal = async() => {
    this.setState({
      startDate: new Date(new Date().getFullYear(), 0, 1),
      endDate:   new Date(new Date().getFullYear(), 11, 31),
      CalenderMinDate: new Date(new Date().getFullYear(), 0, 1),
      visible: false,
      viewings_active: true,
      general_active: true
    })
    window.$("#property_info").removeClass("in");
    window.$("#agent_link").removeClass("in");
    window.$("#agent_link_data").removeClass("in");
    window.$("#viewings_property").addClass("active in");
    window.$("#viewingstab").addClass("active");
    window.$("#property_notes").removeClass("in");
    window.$("#propertynotestab").removeClass("active");
    window.$("#office_notes").removeClass("in");
    window.$("#officenotestab").removeClass("active");

    window.$("#appointmentFromProp").attr("value",this.state.startDate).trigger("change");
    window.$("#appointmentToProp").attr("value",this.state.endDate).trigger("change");
    if(String(window.location.href).includes('#')){
      var tab_id = String(window.location.href).split("#");
      tab_id = tab_id[1];
      if(tab_id === "appt"){
        this.props.set_page("appts_tab")
      }else if(tab_id === "prop"){
        this.props.set_page("property_tab")
      }
    }else{
      if(window.location.pathname === "/overview"){
        this.props.set_page("overview_page")
      }else if(window.location.pathname === "/properties"){
        this.props.set_page("properties_page")
      }else if(window.location.pathname === "/viewings"){
        this.props.set_page("viewings_page")
      }else if(window.location.pathname === "/revenue"){
        this.props.set_page("revenue_page")
      }
    }
    const show_agents = 0
    const show_property = 0
    const from_property_page = 0
    const from_stock_pipeline = 0
    this.props.reset_property_modal();
    await this.props.update_add_appointment_modal_state(show_agents,show_property,from_property_page,from_stock_pipeline)
  }

  handleOfficeNotes(office_text, agentId){
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    var d = new Date()
    var today = d.getDate()+" "+monthNames[d.getMonth()]+" "+d.getFullYear();
    var yesterday = (d.getDate()-1)+" "+monthNames[d.getMonth()]+" "+d.getFullYear();
    

    return(
      Object.keys(this.props.office_text).map(function (date) { 
        return(
          <>
            <center key={date} style={{marginTop:"20px", marginBottom:"10px"}}>
              {today === date ? 
                <span className="office_note_date">Today</span>
                :
                yesterday === date?
                  <span className="office_note_date">Yesterday</span>
                  :
                  <span className="office_note_date">{date}</span>
              }
            </center>

            {(office_text[date]).map(function (data, key) {
              return(
                office_text[date][key].user_id  !== agentId ?
                  <div className="row" key={key}>
                    <div className="col-md-10">
                      <div className="talk-bubble_left tri-right left-bottom">
                        <div className="talktext">
                          <p className="colorDAEEEC mb5">{office_text[date][key].firstname} {office_text[date][key].lastname} </p>
                          {Number(office_text[date][key].has_audio) === 1 ? 
                            <audio controls controlsList="nodownload noplaybackrate">
                              <source src={office_text[date][key].audio_name} type="audio/mpeg" />
                            </audio>
                          :
                            <p>{office_text[date][key].note ? office_text[date][key].note : "Empty Text"}</p>
                          }

                          <Moment className="colorDAEEEC fontSize10 mr5" style={{float:"right"}} format="D MMM YYYY HH:mm">{office_text[date][key].created_date}</Moment>
                          {office_text[date][key].user_image ?
                            <img className="img-circle talk_img_left" src={Constants.IMAGE_URL + office_text[date][key].user_image} alt="" width="22" height="22" />
                            : <img className="img-circle talk_img_left" src="user-images/userDefault.png" alt="" width="22" height="22" />
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                : <>
                  <div className="row" key={key}>
                    <div className="col-md-offset-2 col-md-10">
                      <div className="talk-bubble_right tri-right right-bottom">
                        <div className="talktext">
                          <p className="colorDAEEEC mb5">{office_text[date][key].firstname} {office_text[date][key].lastname}</p>
                          
                          {Number(office_text[date][key].has_audio) === 1 ? 
                            <audio controls controlsList="nodownload noplaybackrate">
                              <source src={office_text[date][key].audio_name} type="audio/mpeg" />
                            </audio>
                          :
                            <p>{office_text[date][key].note ? office_text[date][key].note : "Empty Text"}</p>
                          }
                          <Moment className="colorDAEEEC fontSize10 mr5" style={{float:"right"}} format="D MMM YYYY HH:mm">{office_text[date][key].created_date}</Moment>
                          {office_text[date][key].user_image ?
                            <img className="img-circle talk_img_right" src={Constants.IMAGE_URL + office_text[date][key].user_image} alt="" width="22" height="22" />
                          : <img className="img-circle talk_img_right" src="user-images/userDefault.png" alt="" width="22" height="22" />
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  </>
              )
              })}
          </>
        )
      })
      
    )                          
  }

  render_sales_view_seller(){
    return(
      //for Sale Property
      Number(this.props.property_detail.is_seller_occupant) === Number(1) ?
        Number(this.props.property_detail.isAgentApproved) === Number(1) ?
          <div className="col-md-6">
            <center>
              {/* image */}
              {!this.props.property_detail.seller_image ?
                <img className="img-circle mb5" src="user-images/userDefault.png" width="40" height="40" alt="" />
                :
                <img className="img-circle mb5" src={Constants.IMAGE_URL + this.props.property_detail.seller_image} width="40" height="40" alt="" />
              }<br/>
              {/* name */}
              <span className="color0F1D30 fontSize13 fontWeight600 fontInter"> {!this.props.property_detail.seller_fname ? "No Seller" : this.props.property_detail.seller_fname +" "+this.props.property_detail.seller_lname} </span>
              <br/>
              <img className="img-circle" src=" images/right-sign.png " width="13.33" height="13.33" alt="" />
              <span className="color4AA49C fontSize11 fontWeight600 fontInter ml5" >Seller Linked</span>
            </center>
          </div>
        : 
          <div className="col-md-6">
            <center>
              {/* image */}
              {!this.props.property_detail.seller_image ?
                <img className="img-circle mb5" src="user-images/userDefault.png" width="40" height="40" alt="" />
                :
                <img className="img-circle mb5" src={Constants.IMAGE_URL + this.props.property_detail.seller_image} width="40" height="40" alt="" />
              }<br/>
              {/* name */}
              <span className="color0F1D30 fontSize13 fontWeight600 fontInter"> {!this.props.property_detail.seller_fname ? "No Seller" : this.props.property_detail.seller_fname +" "+this.props.property_detail.seller_lname} </span>
              <br/>
              <Icon icon={clockHistory} style={{color:'#FF9966', verticalAlign:"middle" }} />
              <span style={{color:'#FF9966'}} className="fontInter fontWeight600 fontSize11 ml5">Seller Pending</span>
            </center>
          </div>  
      : 
      <>
        {Number(this.props.property_detail.isAgentApproved) === Number(1) ?
          <div className="col-md-6">
            <center>
              {/* image */}
              {!this.props.property_detail.seller_image ?
                <img className="img-circle mb5" src="user-images/userDefault.png" width="40" height="40" alt="" />
                :
                <img className="img-circle mb5" src={Constants.IMAGE_URL + this.props.property_detail.seller_image} width="40" height="40" alt="" />
              }<br/>
              {/* name */}
              <span className="color0F1D30 fontSize13 fontWeight600 fontInter"> {!this.props.property_detail.seller_fname ? "No Seller" : this.props.property_detail.seller_fname +" "+this.props.property_detail.seller_lname} </span>
              <br/>
              <img className="img-circle" src=" images/right-sign.png " width="13.33" height="13.33" alt="" />
              <span className="color4AA49C fontSize11 fontWeight600 fontInter ml5" >Seller Linked</span>
            </center>
          </div>
        : 
          <div className="col-md-6">
            <center>
              {/* image */}
              {!this.props.property_detail.seller_image ?
                <img className="img-circle mb5" src="user-images/userDefault.png" width="40" height="40" alt="" />
                :
                <img className="img-circle mb5" src={Constants.IMAGE_URL + this.props.property_detail.seller_image} width="40" height="40" alt="" />
              }<br/>
              {/* name */}
              <span className="color0F1D30 fontSize13 fontWeight600 fontInter"> {!this.props.property_detail.seller_fname ? "No Seller" : this.props.property_detail.seller_fname +" "+this.props.property_detail.seller_lname} </span>
              <br/>
              <Icon icon={clockHistory} style={{color:'#FF9966', verticalAlign:"middle" }} />
              <span style={{color:'#FF9966'}} className="fontInter fontWeight600 fontSize11 ml5">Seller Pending</span>
              </center>
          </div>
        }
        {Number(this.props.property_detail.isAgentApprovedFromOccupant) === Number(1) ?  
        <div className="col-md-6">
          <center>
            {/* image */}
            {!this.props.property_detail.o_image ?
              <img className="img-circle mb5" src="user-images/userDefault.png" width="40" height="40" alt="" />
              :
              <img className="img-circle mb5" src={Constants.IMAGE_URL + this.props.property_detail.o_image} width="40" height="40" alt="" />
            }<br/>
            {/* name */}
            <span className="color0F1D30 fontSize13 fontWeight600 fontInter"> {!this.props.property_detail.o_fname ? "No Occupant" : this.props.property_detail.o_fname +" "+this.props.property_detail.o_lname} </span>
            <br/>
            <img className="img-circle" src=" images/right-sign.png " width="13.33" height="13.33" alt="" />
            <span className="color4AA49C fontSize11 fontWeight600 fontInter ml5">Occupant Linked</span>
          </center>
        </div>  
        :
        <div className="col-md-6">
          <center>
            {/* image */}
            {!this.props.property_detail.o_image ?
              <img className="img-circle mb5" src="user-images/userDefault.png" width="40" height="40" alt="" />
              :
              <img className="img-circle mb5" src={Constants.IMAGE_URL + this.props.property_detail.o_image} width="40" height="40" alt="" />
            }<br/>
            {/* name */}
            <span className="color0F1D30 fontSize13 fontWeight600 fontInter"> {!this.props.property_detail.o_fname ? "No Occupant" : this.props.property_detail.o_fname +" "+this.props.property_detail.o_lname} </span>
            <br/>
            <Icon icon={clockHistory} style={{color:'#FF9966', verticalAlign:"middle" }} />
            <span style={{color:'#FF9966'}} className="fontInter fontWeight600 fontSize11 ml5" >Occupant Pending</span>
          </center>
        </div>}
      </>
    )
  }

  render_rent_view_seller(){
    return (
      // for rent
      Number(this.props.property_detail.is_seller_occupant) === Number(1) ?
        Number(this.props.property_detail.isAgentApproved) === Number(1) ?
          <div className="col-md-6">
            <center>
            {/* image */}
            {!this.props.property_detail.seller_image ?
              <img className="img-circle mb5" src="user-images/userDefault.png" width="40" height="40" alt="" />
              :
              <img className="img-circle mb5" src={Constants.IMAGE_URL + this.props.property_detail.seller_image} width="40" height="40" alt="" />
            }<br/>
            {/* name */}
            <span className="color0F1D30 fontSize13 fontWeight600 fontInter"> {!this.props.property_detail.seller_fname ? "No Owner" : this.props.property_detail.seller_fname +" "+this.props.property_detail.seller_lname} </span>
            <br/>
            <img className="img-circle" src=" images/right-sign.png " width="13.33" height="13.33" alt="" />
            <span className="color4AA49C fontSize11 fontWeight600 fontInter ml5" >Owner Linked</span>
            </center>
          </div>
        : 
          <div className="col-md-6">
            <center>
            {/* image */}
            {!this.props.property_detail.seller_image ?
              <img className="img-circle mb5" src="user-images/userDefault.png" width="40" height="40" alt="" />
              :
              <img className="img-circle mb5" src={Constants.IMAGE_URL + this.props.property_detail.seller_image} width="40" height="40" alt="" />
            }<br/>
            {/* name */}
            <span className="color0F1D30 fontSize13 fontWeight600 fontInter"> {!this.props.property_detail.seller_fname ? "No Owner" : this.props.property_detail.seller_fname +" "+this.props.property_detail.seller_lname } </span>
            <br/>
            <Icon icon={clockHistory} style={{color:'#FF9966', verticalAlign:"middle" }} />
            <span style={{color:'#FF9966'}} className="fontInter fontWeight600 fontSize11 ml5">Owner Pending</span>
            </center>
          </div>  
      : 
        <>
        {Number(this.props.property_detail.isAgentApproved) === Number(1) ?
          <div className="col-md-6">
            <center>
            {/* image */}
            {!this.props.property_detail.seller_image ?
              <img className="img-circle mb5" src="user-images/userDefault.png" width="40" height="40" alt="" />
              :
              <img className="img-circle mb5" src={Constants.IMAGE_URL + this.props.property_detail.seller_image} width="40" height="40" alt="" />
            }<br/>
            {/* name */}
            <span className="color0F1D30 fontSize13 fontWeight600 fontInter"> {!this.props.property_detail.seller_fname ? "No Owner" : this.props.property_detail.seller_fname +" "+this.props.property_detail.seller_lname} </span>
            <br/>
            <img className="img-circle" src=" images/right-sign.png " width="13.33" height="13.33" alt="" />
            <span className="color4AA49C fontSize11 fontWeight600 fontInter ml5" >Owner Linked</span>
            </center>
          </div>
        : 
          <div className="col-md-6">
            <center>
            {/* image */}
            {!this.props.property_detail.seller_image ?
              <img className="img-circle mb5" src="user-images/userDefault.png" width="40" height="40" alt="" />
              :
              <img className="img-circle mb5" src={Constants.IMAGE_URL + this.props.property_detail.seller_image} width="40" height="40" alt="" />
            }<br/>
            {/* name */}
            <span className="color0F1D30 fontSize13 fontWeight600 fontInter"> {!this.props.property_detail.seller_fname ? "No Owner" : this.props.property_detail.seller_fname +" "+this.props.property_detail.seller_lname} </span>
            <br/>
            <Icon icon={clockHistory} style={{color:'#FF9966', verticalAlign:"middle" }} />
            <span style={{color:'#FF9966'}} className="fontInter fontWeight600 fontSize11 ml5">Owner Pending</span>
            </center>
          </div>
        }
        {Number(this.props.property_detail.isAgentApprovedFromOccupant) === Number(1) ?  
          <div className="col-md-6">
            <center>
            {/* image */}
            {!this.props.property_detail.o_image ?
              <img className="img-circle mb5" src="user-images/userDefault.png" width="40" height="40" alt="" />
              :
              <img className="img-circle mb5" src={Constants.IMAGE_URL + this.props.property_detail.o_image} width="40" height="40" alt="" />
            }<br/>
            {/* name */}
            <span className="color0F1D30 fontSize13 fontWeight600 fontInter"> {!this.props.property_detail.o_fname ? "No Occupant" : this.props.property_detail.o_fname +" "+ this.props.property_detail.o_lname } </span>
            <br/>
            
            <img className="img-circle" src=" images/right-sign.png " width="13.33" height="13.33" alt="" />
            <span className="color4AA49C fontSize11 fontWeight600 fontInter ml5">Occupant Linked</span>
            </center>
          </div>  
        :
          <div className="col-md-6">
            <center>
            {/* image */}
            {!this.props.property_detail.o_image ?
              <img className="img-circle mb5" src="user-images/userDefault.png" width="40" height="40" alt="" />
              :
              <img className="img-circle mb5" src={Constants.IMAGE_URL + this.props.property_detail.o_image} width="40" height="40" alt="" />
            }<br/>
            {/* name */}
            <span className="color0F1D30 fontSize13 fontWeight600 fontInter"> {!this.props.property_detail.o_fname ? "No Occupant" : this.props.property_detail.o_fname +" "+ this.props.property_detail.o_lname } </span>
            <br/>
            <Icon icon={clockHistory} style={{color:'#FF9966', verticalAlign:"middle" }} />
            <span style={{color:'#FF9966'}} className="fontInter fontWeight600 fontSize11 ml5" >Occupant Pending</span>
            </center>
          </div>
        }
        </>
    )
  }


  rotateArrow = () => {
    if(this.state.visible){
      this.setState({ visible: false })
    }else{
      this.setState({ visible: true })
    }
  }
  
  handleShareCode = async() => {
    var token = localStorage.getItem('token');
    await this.props.get_auth_data(this.props.agent_id, token);
    this.props.get_edit_property_data(g_prop_id, this.props.authtoken);
    window.$("#sharecodeModal").modal('show');
  }

  //Toggle Appointment Graph
  toggleAppointmentDatatHandler =(button_id)=>{
    if(button_id === "viewingsgraph"){
      document.getElementById(button_id).classList.toggle("fadebutton");
      this.state.viewings_active ?
        this.setState({ viewings_active :false })
      :
        this.setState({ viewings_active :true })
    }else{
      document.getElementById(button_id).classList.toggle("fadebutton");
      this.state.general_active ?
        this.setState({ general_active :false })
      :
        this.setState({ general_active :true })
    }
  }

  showCRMVerifiedModal = () =>{
    window.$("#crmVerifiedModal").modal('show');
  }
  
  showCRMNotVerifiedModal = () =>{
    window.$("#crmNotVerifiedModal").modal('show');
  }
  
  handleAddAppointment = async () =>{
    // const show_agents = 1
    // const show_property = 0
    // const from_property_page = 0
    // const from_stock_pipeline = 0 
    var token = localStorage.getItem('token');
    await this.props.get_auth_data(this.props.agent_id, token);
    this.props.get_all_agent_data_dd(this.props.authtoken)
    //await this.props.update_add_appointment_modal_state(show_agents,show_property,from_property_page,from_stock_pipeline)
    window.$("#addAppointmentModal").modal('show');
  }

  handleAppointmentEdit = async(appointment_id, appointment_type) =>{
    //appointment_type 1 = viewing, 2 = general
    if(appointment_type === 1){
      const token = localStorage.getItem('token')
      await this.props.get_auth_data(this.props.agent_id, token);
      await this.props.get_viewing_appointment(this.props.authtoken, appointment_id)
      this.setViewingDateTime();
      window.$("#editViewingAppointmentModal").modal('show');
    }else{
      const token = localStorage.getItem('token')
      await this.props.get_auth_data(this.props.agent_id, token);
      await this.props.get_general_appointment(this.props.authtoken, appointment_id)
      this.props.get_all_agent_data_dd(this.props.authtoken)
      this.setGeneralData();
      window.$("#editGeneralAppointmentModal").modal('show');
    }
  }

  render(){
    var appt_loading =  0;
    if(this.props.appointment_cnt){
      if(typeof this.props.appointment_cnt.labels !=='undefined' ){
        appt_loading =  this.props.appointment_cnt.labels.length;
      }
    }
    
    var property_detail = null;
    var prop_id = null;
    var agentId =  null;
    var html_export = null;
    var property_notes_html = null;
    var office_notes_html = null;
    var office_text= null;
    var _ = this;

    if(this.props.property_detail){
      if(typeof this.props.property_detail.id !=='undefined' ){
        property_detail = this.props.property_detail;
        prop_id  =  this.props.property_detail.id;
        g_prop_id = this.props.property_detail.id;
        agentId  =  this.props.agent_id;
        html_export  = this.props.html_export;
        property_notes_html = this.props.property_notes_html;
        office_notes_html = this.props.office_notes_html;
        office_text = this.props.office_text;
      }
    }
    var feedback = JSON.parse(JSON.stringify(this.props.feedback));
    let is_seller_occupant =  this.props.property_detail ? this.props.property_detail.is_seller_occupant : null;
    let isAgentApproved = this.props.property_detail ? this.props.property_detail.isAgentApproved : null;
    let isAgentApprovedFromOccupant = this.props.property_detail ? this.props.property_detail.isAgentApprovedFromOccupant : null;
    let property_type = this.props.property_detail ? this.props.property_detail.property_type : null;
    return(<div>
      <div className="modal fade" id="profileModal" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content modalheight" style ={{borderRadius : '12px' }} >
            <div className="modal-container">
              
                {!this.props.property_detail ?
                  <center><br /><img src="images/loader.svg" width="50" height="50" alt="" /> </center> 
                  :  
                  // <SimpleMap lat= { this.props.property_detail.lat } lon = {  this.props.property_detail.lon }  />
                  <div style={{ background: '#EDF7F6', paddingLeft: "20px", paddingTop: "20px" }}>
                    <div className="row">
                      <div className="col-md-2">
                        {!this.props.property_detail.agent_image ?
                          <img className="img-circle mb5 mr15" src="user-images/userDefault.png" width="64" height="64" alt="" />
                          :
                          <img className="img-circle mb5 mr15" src={Constants.IMAGE_URL + this.props.property_detail.agent_image} width="64" height="64" alt="" />
                        }
                      </div>
                      <div className="col-md-7" style={{paddingLeft: "0px"}}>
                        {this.props.from_networking === 1 ? <p className="fontBold fontRoboto mb0"> Listing Agent</p>: null}
                        <p className="fontBold fontRoboto fontSize25 mb0">
                          {!this.props.property_detail ? <center><img src="images/loader.svg" width="40" height="40" alt="" /></center> : this.props.property_detail.agent_fname +" "+ this.props.property_detail.agent_lname}
                        </p>
                        <span className="fontRoboto fontSize13 color8591A0">{this.props.property_detail.agent_email}&nbsp;-&nbsp;{this.props.property_detail.agent_cellnumber}</span>
                        {/* <span style={{ float: 'right' }}></span> */}
                      </div>
                      <div className="col-md-1">
                        {/* <div className="circle ml20" data-dismiss="modal" >
                          <img src="images/modal-close.png " width="12" height="12" alt="" />
                        </div> */}
                      </div>
                      <div className="col-md-1"></div>
                    </div>
                  </div>
                }
              
              <div className="circle" data-dismiss="modal" onClick={this.closeModal}>
                <img src="images/modal-close.png " width="12" height="12" alt="" />
              </div> 
              <div className = "container-fluid"> 
                <div className="text-block">
                  <div className="row">
                    <div className="col-md-8">
                      <span className="fontRoboto fontWeight500 fontSize16">
                        {this.props.property_detail ?
                          !this.props.property_detail.address ? 
                          <h4>Loading...</h4>
                          :
                            <> 
                            {this.props.property_detail.external_url ? <a style={{color: "#fff", textDecoration: "underline"}} href={this.props.property_detail.external_url} target="_blank" rel="noreferrer"> {this.props.property_detail.address} </a> : this.props.property_detail.address}
                            {Number(this.props.property_detail.agent_pro_user) === Number(1) && Number(this.props.property_detail.crm_verified) === Number(1) ? 
                              <img tag="allowRowEvents" alt='' width="20" height="20" style={{verticalAlign: "text-bottom", marginLeft: "5px", cursor:"pointer"}} src={"images/crm_verified.svg"} onClick={this.showCRMVerifiedModal}/>
                            :
                              Number(this.props.property_detail.agent_pro_user) === Number(1) && Number(this.props.property_detail.crm_verified) === Number(0) ? 
                                <img tag="allowRowEvents" alt='' width="20" height="20" style={{verticalAlign: "text-bottom", marginLeft: "5px", cursor:"pointer"}} src={"images/crm_not_verified.svg"} onClick={this.showCRMNotVerifiedModal}/>
                              :
                                null
                            }
                            {this.props.property_detail.unit_name ? this.props.property_detail.unit_name.trim() ? <><br/><span className="fontWeight600 fontInter">{this.props.property_detail.unit_name}</span></> : null: null}
                            </>
                          : null 
                        }
                      </span>
                      {this.props.property_detail ?
                        <p className="mb5 fontSize14 color4AA49C">Added on : <Moment format="MMM D, YYYY, HH:mm" className="colorDAEEEC">{this.props.property_detail.date_created}</Moment></p>
                      :
                        null
                      }

                      {this.props.property_detail ?
                        <>
                        {/* <ReactTooltip resizeHide = { true } backgroundColor={'#0B2C4C'}  /> */}
                        <div>
                          {this.props.property_detail.date_sold ?
                            <span className="proeprty_rented_span" style={{marginRight:"5px"}}>
                              {Number(this.props.property_detail.rented) === Number(1) ?
                                "Rented" : "Sold"}
                            </span>
                          : 
                            <span className="property_sale_type_span" style={{marginRight:"5px", fontSize: "14px", paddingBottom: "3px", paddingTop: "3px"}}>
                              {Number(this.props.property_detail.property_type) === Number(1) ?
                                "For Sale" : "For Rent"}
                            </span>
                          }
                          {/* {this.props.property_detail.crm_id && Number(this.props.property_detail.crm_verified) === Number(1)? 
                              <span className="proeprty_crm_verified_span"data-place="top" data-tip="CRM verified">CRM <Icon icon={tickIcon} style={{fontSize: '14px'}} /></span>
                            :
                            this.props.property_detail.crm_id ?
                              <span className="proeprty_crm_span" data-place="top" data-tip="CRM not verified" >CRM</span>
                            : null
                          } */}
                          {Number(this.props.property_detail.is_office_listing) === 1 ? <span className="office_listing_span" style={{padding: "3px 5px 3px 5px", marginLeft: "0px" ,fontSize: "14px"}}> Office Listing </span> : null}
                          {Number(this.props.property_detail.is_open_listing) === 1 ? <span className="ml5 open_listing_span" style={{padding: "3px 5px 3px 5px", fontSize: "14px"}}> Other Listing </span> : null}
                        </div>
                        </>
                      : null}
                    </div>


                    <div className="col-md-4">
                      {this.props.property_detail ?
                        <>
                        <span className="fontRoboto fontBold fontSize16">
                          {!this.props.property_detail.currency_symbol ? <h4>...</h4>:this.props.property_detail.currency_symbol} 
                          {!this.props.property_detail.price ? <h4>...</h4>:this.props.property_detail.price}
                        </span>
                        <span className="floatRight">
                        <button type="button" className="btn btn-link" style={{textDecoration:"none", padding:"0px"}} data-toggle="collapse" data-target="#property_info, #agent_link"><Icon icon={menuButtonWide} height='20' width='20' style={{color:"#27AFAF"}}/></button>
                        </span>
                        </>
                      : null}
                    </div>
                  </div>
                  <br />
                  
                  {/* collapse Panel */}
                  <div id="property_info" className="collapse">
                    <div className="row">
                        {/* {this.props.property_detail ?
                          this.props.property_detail.external_url !== "null" && this.props.property_detail.external_url !== ""  ?
                            <div className = "col-md-3" style={{paddingLeft: 10}}>
                              <a href = {this.props.property_detail.external_url} target="_blank" rel="noopener noreferrer">
                                <button  className="btn btn-primary fontBold mr8 mb8" style={{ background:'#4AA49C',borderRadius:'4px'}}> <Icon className="message_icon" icon={webIcon} height='18' width='18'/> View Listing</button>
                              </a>
                            </div>
                          : null
                        :null} */}

                      {this.props.property_detail ? 
                        <div className = "col-md-3" style={Number(this.props.property_detail.property_type) === 1 ? {paddingLeft: 10} : {paddingLeft: 0} }>
                          <button id="button-excel" className="btn btn-primary fontBold exportbtn mr8 mb8" style={{ background:'#4AA49C',borderRadius:'4px'}}> <Icon className="message_icon" icon={downloadFill} height='18' width='18'/> Exp Report</button>
                        </div>
                      : null}
                      {(!this.props.date_sold || this.props.date_sold === 'null') && this.props.property_detail ?<>
                        {Number(this.props.property_detail.crm_verified) !== Number(1) ?
                            <div className = "col-md-3" style={Number(this.props.property_detail.property_type) === 1 ? {paddingLeft: 10} : {paddingLeft: 0} }>
                              <button id="share_code" onClick={this.handleShareCode} className="btn btn-primary fontBold mr8 mb8" style={{ background:'#4AA49C',borderRadius:'4px'}}> <Icon className="message_icon" icon={shareRounded} height='18' width='18'/> Share Code</button>
                            </div>
                          :
                            Number(this.props.property_detail.code_generated) === Number(1) ?
                              <div className = "col-md-3" style={Number(this.props.property_detail.property_type) === 1 ? {paddingLeft: 10} : {paddingLeft: 0} }>
                                <button id="share_code" onClick={this.handleShareCode} className="btn btn-primary fontBold mr8 mb8" style={{ background:'#4AA49C',borderRadius:'4px'}}> <Icon className="message_icon" icon={shareRounded} height='18' width='18'/> Share Code</button>
                              </div>
                            : null
                        }
                        {Number(this.props.from_archiveProperty) !== 1 && !this.props.property_detail.date_sold ?
                          <div className = "col-md-3" style={Number(this.props.property_detail.property_type) === 1 ? {paddingLeft: 10, paddingRight: 0, marginRight: "-5px"} : {paddingLeft: 0, paddingRight: 0, marginRight: "-10px"} }>
                            <button type="submit" onClick={this.handleEditPropertyModal} className="btn btn-primary fontBold mr8 mb8" style={{ background:'#4AA49C',borderRadius:'4px'}} > <Icon icon={editOutlined} height='18' width='18' style={{verticalAlign:'bottom'}}/> Edit Prop</button>
                          </div>
                          :null
                        }
                        </>
                      :
                      null}
                      
                      {/* Mark as Sold/Rent */}
                      {this.props.property_detail && Number(this.props.from_archiveProperty) !== 1?
                        <div className = "col-md-3" style={Number(this.props.property_detail.property_type) === 1 ? {paddingLeft: 0} : {paddingLeft: 0, marginLeft: "-5px"} }>
                          {Number(this.props.property_detail.property_status_id) === Number(1) && Number(this.props.property_detail.property_type) === Number(1) ?
                            <button type="submit" onClick={this.handleMarkAsSold} className="btn btn-primary fontBold mr8 mb8" style={{ background:'#EA676A',borderRadius:'4px'}} > <Icon icon={receiptIcon} height='18' width='18' style={{verticalAlign:'bottom'}}/> Mark as Sold</button>
                            :
                            Number(this.props.property_detail.property_status_id) === Number(1) && Number(this.props.property_detail.property_type) === Number(0) ?
                              <button type="submit" onClick={this.handleMarkAsRented} className="btn btn-primary fontBold mr8 mb8" style={{ background:'#EA676A',borderRadius:'4px'}} > <Icon icon={receiptIcon} height='18' width='18' style={{verticalAlign:'bottom'}}/> Mark as Rented</button>
                              :
                                Number(this.props.property_detail.property_status_id) === Number(3) && Number(this.props.property_detail.property_type) === Number(0) ?
                                  Number(this.props.property_detail.commission_type) === Number(0) && Number(this.props.property_detail.is_rental_stoped) === Number(0) ?
                                    <button type="submit" onClick={this.handleManageRent} className="btn btn-primary fontBold mr8 mb8" style={{ background:'#EA676A',borderRadius:'4px'}} > <Icon icon={receiptIcon} height='18' width='18' style={{verticalAlign:'bottom'}}/> Update/Stop Rental</button>
                                  :
                                  null
                                :
                                  null
                          }
                        </div>
                      : null}
                    </div> 
                  </div>

                  {/* Table for Export */}
                  {html_export ?
                    <div style={{display:"none"}} dangerouslySetInnerHTML={{ __html: html_export }}></div> 
                    :
                    null
                  }
                  
                  {property_notes_html ? 
                    <div style={{display:"none"}} dangerouslySetInnerHTML={{ __html: property_notes_html }}></div> 
                    :
                    null
                  }

                  {office_notes_html ? 
                    <div style={{display:"none"}} dangerouslySetInnerHTML={{ __html: office_notes_html }}></div> 
                    :
                    null
                  } 
                </div>  { /* text block */ }

                <div id="agent_link" className="agent-link collapse">
                  <div className="row">
                    <div className="col-xs-3">
                      <Icon className="message_icon mr5" icon={eyeOpen} height='18' width='18' style={{color:"#000000"}}/>
                      <span className="fontWeight600 fontInter" style={{color:"#0F182D"}}>View Seller</span>
                    </div>
                    <div className="col-xs-8 line"> </div>
                    <div className="col-xs-1">
                      <button type="button" className="btn btn-link" style={{textDecoration:"none", padding:"0px"}} data-toggle="collapse" data-target="#agent_link_data" onClick={this.rotateArrow}><Icon icon={arrowIosDownwardFill} style={{color: '#27afaf', fontSize: '24px'}} rotate={this.state.visible ? "180deg" : "0deg"}/></button>
                    </div>
                  </div>
                  <div id="agent_link_data" className="collapse">
                    {this.props.property_detail ?
                      <div className="row">
                        {Number (this.props.property_detail.property_type) === Number(1) ?
                            this.render_sales_view_seller()
                          :
                            this.render_rent_view_seller()
                          }
                      
                      </div>
                    : null}
                  </div>
                </div>

                {Number(this.props.from_networking) !== 1 && Number(this.props.from_archiveProperty) !== 1 && this.props.property_detail?
                <>
                <br />
                  <button type="submit" className="btn btn-primary fontBold" style={{ background:'#4AA49C', borderRadius:'4px', marginRight:30}} onClick={this.handleAddAppointment}>
                    <Icon className="message_icon mr5" style={{verticalAlign:'bottom'}} icon={bxCalendarEvent} height='20' width='20' />
                      Add Appointment
                  </button>
                <br />
                </>
                : null
                }
                <br />

                <div className="container-fluid">
                  { /* Linking Message */}
                  {/* {!this.props.sellerLinkedMessage ? null : this.props.sellerLinkedMessage} */}
                  <div className="row">
                    <div className = "col-md-12">
                      <img src="images/Total_icon.png" width="20" height="20" alt="" />
                      <span className="box-title fontSize17 ml15  color0F1D30 fontWeight600 fontInter">
                        Viewings over time&nbsp;
                      </span>
                    </div>
                  </div>
                  <div className="row mt35">
                    <div className = "col-md-11">
                      <DateRange prop_id={prop_id} agent_id={agentId} date_sold={this.props.date_sold} fromnetworking={this.props.from_networking}/>
                      {/* <div style={{display:'flex'}}>
                        <Calendar value={this.state.startDate} dateFormat="dd-mm-yy" name="appointmentFromProp" id="appointmentFromProp" className='ml15'
                          onChange={(e) => this.setState({ startDate: e.value, renderAgentButton: 1, CalenderMinDate:e.value }) }>
                        </Calendar>
                        
                        <Calendar value={this.state.endDate} minDate={this.state.CalenderMinDate} dateFormat="dd-mm-yy" name="appointmentToProp" id="appointmentToProp"
                          onChange={(e) => this.setState({ endDate: e.value }) }>
                        </Calendar>

                        <button type="button" 
                          style={{ backgroundColor: '#5FC8BF', borderRadius: '0px 10px 10px 0px', padding: "4px 12px" }}
                          className="btn btn-info" 
                          //onClick = { () => this.props.dateWisePropertyAppointment ($("#propAppointmentFromDate").val(),$("#propAppointmentToDate").val() , prop_id,agentId  ) } >
                          onClick = { () => this.props.dateWisePropertyAppointment (this.state.startDate, this.state.endDate, prop_id, agentId, this.props.date_sold, this.props.from_networking  ) } >
                          Go
                        </button>
                      </div>  */}
                    </div>
                  </div>
                  { appt_loading === 0 ?  <center> <img src="images/loader.svg" width="50" height="50" alt="" /> </center> : 
                  <>
                    <br/>
                    <div className = "row">
                      <div className = "col-md-1">
                        <span className = "fontInter fontSize12 color12171D" style={{verticalAlign:'-webkit-baseline-middle'}}>Show:</span>
                      </div>
                      <div className = "col-md-2 ">
                        <button className = "btn fontInter" id="viewingsgraph" style={{background:'#27AFAF',color:'white',borderRadius:'4px'}} onClick={() => {this.toggleAppointmentDatatHandler("viewingsgraph")}}>Viewings</button>
                      </div>
                      <div className = "col-md-2">
                        <button className = "btn fontInter" id="generalgraph" style={{marginLeft:'8px',background:'#2D3E54',color:'white',borderRadius:'4px'}} onClick={() => {this.toggleAppointmentDatatHandler("generalgraph")}}>General</button>
                      </div>
                    </div>
                    <div className="row mt35">
                      <div className="col-md-12 col-lg-12 col-xs-12">
                        {this.props.property_detail && this.props.general_cnt_property_modal ?
                          <AgentPropertyModalChart  appointment_cnt = { this.props.appointment_cnt } 
                            labels = { this.props.appointment_cnt.labels }
                            viewing_appt_cnt = {this.state.viewings_active ? this.props.appointment_cnt : '' }
                            general_appt_cnt={this.state.general_active ? this.props.general_cnt_property_modal : '' } 
                            agent_id = { this.props.property_detail.agent_id }
                          />
                        :
                          <Loader/>
                        }                      
                      </div>
                    </div>
                  </>
                  }

                  <div className="row mt35">
                    <div className = "col-md-12">
                      {/* <span className="box-title fontSize24 ml15  color0F1D30 fontWeight600 fontInter">
                        Viewings &nbsp;
                      </span> */}
                      {/* Viewing and Property Notes Tab */}
                      <ul className="nav nav-tabs">
                        <li style={{ width: '33%' }} id="viewingstab" className="active fontInter">
                          <a data-toggle="tab" href="#viewings_property">Appointments
                            <br />
                              {feedback ?
                                <span className="fontSize12 fontWeightNormal fontInter"><b>{feedback.length} &nbsp; {feedback.length === 1 ? "View" : "Views" }</b></span>
                            : null
                            }
                          </a>
                        </li>
                        
                        <li style={{ width: '33%' }} id="propertynotestab">
                          <a data-toggle="tab" href="#property_notes">Private Notes
                            <br />
                            {this.props.property_notes ?
                                <span className="fontSize12 fontWeightNormal fontInter"><b>{this.props.property_notes.length} &nbsp; {this.props.property_notes.length === 1 ? "Note" : "Notes" }</b></span>
                                : null
                            }
                            </a>
                        </li>

                        <li style={{ width: '34%' }} id="officenotestab">
                          <a data-toggle="tab" href="#office_notes">Office Chat
                            <br />
                            {this.props.office_notes ?
                              <span className="fontSize12 fontWeightNormal fontInter"><b>{this.props.office_notes.length} &nbsp; {this.props.office_notes.length === 1 ? "Note" : "Notes" }</b></span>
                              : null
                            }
                            </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  
                  <div className="tab-content" style={{ marginTop: '10px', fontSize: '16px'}}>
                    <div id="viewings_property" className="tab-pane fade in active">
                      <div className="mt15">
                        <div className="row">
                          <div className="col-md-12">
                            {/* {this.props.property_detail ?
                              <AgentComment agent_image = {this.props.property_detail.agent_image}
                                agent_fname = {this.props.property_detail.agent_fname}
                                agent_lname = {this.props.property_detail.agent_lname} 
                                feedback ={this.props.feedback} 
                                seller_message ={this.props.seller_message} 
                                occupant_message ={this.props.occupant_message}
                                property_detail = {this.props.property_detail} 
                              />: null} */}
                            {feedback ?
                              Object.keys(feedback).length !== 0 ?
                                Object.keys(feedback).map(function (outerKey) {  
                                  return (
                                    feedback[outerKey].appointment_type === "viewing" ? 
                                    <>
                                      {_.props.property_detail ? 
                                        <Accordion allowZeroExpanded="true">  
                                          <AccordionItem key={outerKey} className="accordion__item_chat">
                                            <AccordionItemHeading className="accordion__heading_chat">
                                              <AccordionItemButton className="accordion__button accordion__button_chat commentAccordion">
                                                <div>
                                                  <div className="viewingHeader">
                                                    <div style={{display:"flex", justifyContent:"space-between"}}>
                                                      <span>Viewing</span>
                                                      {(Number(_.props.property_detail.is_archived) !== 1) && (Number(_.props.property_detail.is_office_listing) !== 1) && (Number(_.props.property_detail.is_other_listing) !== 1) && (Number(feedback[outerKey]["cancelled"]) === 0) && (Number(feedback[outerKey]["appointment_status_id"]) !== 3 || Number(feedback[outerKey]["appointment_status_id"]) !== 4) && (!feedback[outerKey]["date_closed"]) ?
                                                        <button ref='edit' type="button" id={feedback[outerKey]["id"]} className="btn btn-info fontWeight500" style={{float:'right', backgroundColor: "#1F2F44", border: "1px solid #1F2F44", verticalAlign: 'bottom', padding: "0px 5px 0px 5px", marginRight: "-2.5px"}} onClick={() => _.handleAppointmentEdit(feedback[outerKey]['id'], 1)}> <Icon icon={editIcon} style={{verticalAlign: "middle"}}/> </button> 
                                                      : null
                                                      }
                                                    </div>
                                                  </div>
                                                  <div className = "row" style={{float:"right"}}>
                                                    <div className="col-md-12 padRight0 mt25" style={{marginRight: "35px"}} >
                                                      <span style={{float:"left"}}>
                                                        <Icon className="message_icon color27AFAF" height='22' width='22' icon={chatRightTextFill} />
                                                        {feedback[outerKey].unread > 0 ?
                                                          <span className="badge" style={{top:"-6px", right:"42px"}}> </span>
                                                          : null
                                                        }
                                                      </span>
                                                      <span className="color738196 fontSize12 ml5 mt5 fontInter">{feedback[outerKey].total_message }</span>
                                                    </div>
                                                  </div>
                                                  
                                                  <div className = "viewingDiv"> 
                                                    <div className = "row">
                                                      {property_detail ?
                                                        <div className="col-md-12 padRight0 padLeft10" style={{marginTop:-30}}>
                                                          {/* seller agent details */}
                                                          <span className="fontSize16 color0F1D30 fontWeight500 fontInter">
                                                            {feedback[outerKey].buyer_name}
                                                          </span>
                                                          
                                                          {/* buyers agent details */}
                                                          {feedback[outerKey].buyer_agent_name ?
                                                            <div>
                                                              <span className="fontSize14 color0F1D30 fontWeight500 fontInter">
                                                                Brought by agent {feedback[outerKey].buyer_agent_name}
                                                              </span>
                                                            </div>
                                                          : 
                                                            <br/> 
                                                          }
                                                          
                                                          {/* Appointment Date */}
                                                          <span className = "color556378 fontInter fontSize14 fontWeight400">
                                                            <Moment format="MMM D YYYY">{feedback[outerKey].appointment_date}</Moment><span>,</span><br/>
                                                            <Moment format="HH:mm">{feedback[outerKey].appointment_date}</Moment>
                                                          </span>                                              
                                                        </div>
                                                      : null}
                                                    </div>
                                                  </div>
                                                  
                                                  <hr style={{paddingLeft: -10}} className="viewing_hr"/>
                                                  
                                                  <div className="viewingDiv wrap">  
                                                    {/*Feedback  */}
                                                    <span className="fontSize13 color556378 fontInter">
                                                      {Number(feedback[outerKey].has_audio) === 1 ?
                                                        <p>
                                                          <audio controls controlsList="nodownload noplaybackrate">
                                                            <source src={ feedback[outerKey].feedback } type="audio/mpeg" />
                                                          </audio>
                                                        </p>
                                                      :
                                                        <p className="mt5 padRight10"> 
                                                          {feedback[outerKey].feedback ? 
                                                            feedback[outerKey].feedback 
                                                          : 
                                                            Number(feedback[outerKey].cancelled) === 1 ?
                                                            <span className="colorEB5757">Appointment Canceled</span>
                                                            :
                                                              new Date(feedback[outerKey].appointment_date) > new Date() ?  
                                                              <span className="colorFF9966">Upcoming Appointment</span>
                                                              :
                                                                "No feedback provided"
                                                          } 
                                                        </p>
                                                      }
                                                    </span>
                                                  </div> 
                                                </div>
                                              </AccordionItemButton>
                                            </AccordionItemHeading>

                                            <AccordionItemPanel className="accordion__panel_chat">
                                              <ul className="nav nav-tabs chat-information">
                                                {Number(is_seller_occupant) === Number(0) && Number(isAgentApproved) === Number(1) ? 
                                                  <li style={{ width: '33%' }} className="active fontInter">
                                                    <a data-toggle="tab" href={"#seller_"+outerKey} style={{fontSize:15,fontWeight:700}}>{ Number(property_type) === Number(0)? "Owner" : "Seller" }
                                                      <br />
                                                      <span className="fontSize12 fontWeight700 fontInter"><b>{feedback[outerKey].seller_message.length} </b>&nbsp;Messages</span>&nbsp;<sup>.</sup>&nbsp;
                                                    </a>
                                                  </li>
                                                : 
                                                  Number(isAgentApproved) === Number(1) ? 
                                                    <li style={{ width: '33%' }} className="active fontInter">
                                                      <a data-toggle="tab" href={"#seller_"+outerKey} style={{fontSize:15,fontWeight:700}}>{ Number(property_type) === Number(0)? "Owner" : "Seller" }
                                                        <br />
                                                        <span className="fontSize12 fontWeight700 fontInter"><b>{feedback[outerKey].seller_message.length} </b>&nbsp;Messages</span>&nbsp;<sup>.</sup>&nbsp;
                                                      </a>
                                                    </li>
                                                  : null
                                                }
                                                {Number(is_seller_occupant) === Number(0) && Number(isAgentApprovedFromOccupant) === Number(1) ? 
                                                  <li style={{ width: '33%' }} className={Number(isAgentApproved) === Number(0) && Number(isAgentApprovedFromOccupant) === Number(1) ? "active" :""}>
                                                    <a data-toggle="tab" href={"#occupant_"+outerKey} style={{fontSize:15,fontWeight:700}}>Occupant
                                                      <br />
                                                      <span className="fontSize12 fontWeight700 fontInter"><b>{feedback[outerKey].occupant_message ? feedback[outerKey].occupant_message.length : 0 }</b>&nbsp;Messages</span>&nbsp;<sup>.</sup>&nbsp;
                                                    </a>
                                                  </li>
                                                : null }
                                                
                                                <li style={{ width: '34%' }} className={Number(isAgentApproved) === Number(0) && Number(isAgentApprovedFromOccupant) === Number(0) ? "active":""}>
                                                  <a data-toggle="tab" href={"#viewing_notes"+outerKey} style={{fontSize:15,fontWeight:700}}>Private Notes
                                                    <br />
                                                    <span className="fontSize12 fontWeight700 fontInter"><b>{feedback[outerKey].viewing_notes ? feedback[outerKey].viewing_notes.length : 0 }</b>&nbsp;Messages</span>&nbsp;<sup>.</sup>&nbsp;
                                                  </a>
                                                </li>
                                                
                                              </ul>
                                              <div className="tab-content chat-information" style={{ marginTop: '15px'}}>
                                                {/* Seller Messages */}
                                                <div id={"seller_"+outerKey} className={Number(is_seller_occupant) === Number(1) ? [Number(isAgentApproved) === Number(1) || Number(isAgentApprovedFromOccupant) === Number(1) ? "tab-pane fade in active" : "tab-pane fade in"] : [Number(isAgentApproved) === Number(1) ? "tab-pane fade in active" : "tab-pane fade in"] }>
                                                  {feedback[outerKey].seller_message.map(function (sellerKey) {
                                                    return (
                                                      <div key={sellerKey.id}>
                                                        {sellerKey.creator_id  === agentId ?
                                                          <div className="row">
                                                            <div className="col-md-10">
                                                              <div className="talk-bubble_left tri-right left-bottom">
                                                                <div className="talktext">
                                                                  <p className="colorDAEEEC mb5">{sellerKey.firstname} {sellerKey.lastname} <span className="colorDAEEEC" style={{fontSize:10}}>Agent</span></p>
                                                                  {Number(sellerKey.has_audio) === 1 ? 
                                                                    <audio controls controlsList="nodownload noplaybackrate">
                                                                      <source src={sellerKey.audio_name} type="audio/mpeg" />
                                                                    </audio>
                                                                  :
                                                                    <p>{sellerKey.comment_text ? sellerKey.comment_text : "Empty Text"}</p>
                                                                  }

                                                                  <Moment className="colorDAEEEC fontSize10 mr5" style={{float:"right"}} format="D MMM YYYY HH:mm">{sellerKey.comment_time}</Moment>
                                                                  {sellerKey.image ?
                                                                    <img className="img-circle talk_img_left" src={Constants.IMAGE_URL + sellerKey.image} alt="" width="22" height="22" />
                                                                  : <img className="img-circle talk_img_left" src="user-images/userDefault.png" alt="" width="22" height="22" />
                                                                  }
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        :
                                                          <div key={sellerKey.id} className="row">
                                                            <div className="col-md-offset-2 col-md-10">
                                                              <div className="talk-bubble_right tri-right right-bottom">
                                                                <div className="talktext">
                                                                  <p className="colorDAEEEC mb5">{sellerKey.firstname} {sellerKey.lastname} <span className="colorDAEEEC" style={{fontSize:10}}>{ Number(property_type) === Number(0)? "Owner" : "Seller" }</span></p>
                                                                  
                                                                  {Number(sellerKey.has_audio) === 1 ? 
                                                                    <audio controls controlsList="nodownload noplaybackrate">
                                                                      <source src={sellerKey.audio_name} type="audio/mpeg" />
                                                                    </audio>
                                                                  :
                                                                    <p>{sellerKey.comment_text ? sellerKey.comment_text : "Empty Text"}</p>
                                                                  }
                                                                  <Moment className="colorDAEEEC fontSize10 mr5" style={{float:"right"}} format="D MMM YYYY HH:mm">{sellerKey.comment_time}</Moment>
                                                                  {sellerKey.image ?
                                                                    <img className="img-circle talk_img_right" src={Constants.IMAGE_URL + sellerKey.image} alt="" width="22" height="22" />
                                                                  : <img className="img-circle talk_img_right" src="user-images/userDefault.png" alt="" width="22" height="22" />
                                                                  }
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div> 
                                                        }
                                                      </div>
                                                    )
                                                  })}
                                                  {Number(feedback[outerKey].seller_message.length) === Number(0) ? 
                                                    <center><h4>No Message to show</h4></center>
                                                    :
                                                    null
                                                  }  
                                                </div>
                                                
                                                {Number(is_seller_occupant) === Number(0) ? 
                                                  /* Occupant Messages */
                                                  <div id={"occupant_"+outerKey} className={Number(isAgentApproved) === Number(0) && Number(isAgentApprovedFromOccupant) === Number(1) ? "tab-pane fade in active" : "tab-pane fade in"}>
                                                    {feedback[outerKey].occupant_message.map(function (occupantKey) {
                                                      return (
                                                        <div key={occupantKey.id}>
                                                          {occupantKey.creator_id  === agentId ?
                                                            <div className="row">
                                                              <div className="col-md-10">
                                                                <div className="talk-bubble_left tri-right left-bottom">
                                                                  <div className="talktext">
                                                                    <p className="colorDAEEEC mb5">{occupantKey.firstname} {occupantKey.lastname} <span className="colorDAEEEC" style={{fontSize:10}}>Agent</span></p>
                                                                    {Number(occupantKey.has_audio) === 1 ? 
                                                                      <audio controls controlsList="nodownload noplaybackrate">
                                                                        <source src={occupantKey.audio_name} type="audio/mpeg" />
                                                                      </audio>
                                                                    :
                                                                      <p>{occupantKey.comment_text ? occupantKey.comment_text : "Empty Text"}</p>
                                                                    }

                                                                    <Moment className="colorDAEEEC fontSize10 mr5" style={{float:"right"}} format="D MMM YYYY HH:mm">{occupantKey.comment_time}</Moment>
                                                                    {occupantKey.image ?
                                                                        <img className="img-circle talk_img_left" src={Constants.IMAGE_URL + occupantKey.image} alt="" width="22" height="22" />
                                                                      : <img className="img-circle talk_img_left" src="user-images/userDefault.png" alt="" width="22" height="22" />
                                                                      }
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          :
                                                            <div className="row">
                                                              <div className="col-md-offset-2 col-md-10">
                                                                <div className="talk-bubble_right tri-right right-bottom">
                                                                  <div className="talktext">
                                                                    <p className="colorDAEEEC mb5">{occupantKey.firstname} {occupantKey.lastname} <span className="colorDAEEEC" style={{fontSize:10}}>Occupant</span></p>
                                                                    {Number(occupantKey.has_audio) === 1 ? 
                                                                      <audio controls controlsList="nodownload noplaybackrate">
                                                                        <source src={occupantKey.audio_name} type="audio/mpeg" />
                                                                      </audio>
                                                                    :
                                                                      <p>{occupantKey.comment_text ? occupantKey.comment_text : "Empty Text"}</p>
                                                                    }
                                                                    <Moment className="colorDAEEEC fontSize10 mr5" style={{float:"right"}} format="D MMM YYYY HH:mm">{occupantKey.comment_time}</Moment>
                                                                    {occupantKey.image ?
                                                                        <img className="img-circle talk_img_right" src={Constants.IMAGE_URL + occupantKey.image} alt="" width="22" height="22" />
                                                                      : <img className="img-circle talk_img_right" src="user-images/userDefault.png" alt="" width="22" height="22" />
                                                                      }
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          }
                                                        </div>
                                                      )
                                                    })}
                                                    {Number(feedback[outerKey].occupant_message.length) === Number(0) ? 
                                                      <center><h4>No Message to show</h4></center>
                                                      :
                                                      null
                                                    }  
                                                  </div>
                                                : null}

                                                <div id={"viewing_notes"+outerKey} className={Number(isAgentApproved) === Number(0) && Number(isAgentApprovedFromOccupant) === Number(0) ? "tab-pane fade in active" : "tab-pane fade in"}>
                                                  {feedback[outerKey].viewing_notes ? 
                                                    feedback[outerKey].viewing_notes.length > 0 ?
                                                      feedback[outerKey].viewing_notes.map((data) => (
                                                        <div className="row" key={data.created_date}>
                                                          <div className="col-md-12 padLeft35 padRight35">
                                                            <div className="property_notes_shadow" key={data.created_date}>
                                                              <div className="row">
                                                                <div className="col-md-12">
                                                                  <p className="cold_call_header mt15" style={{float:'left', paddingLeft:15, marginTop:15}}><Moment format="MMM D YYYY, HH:mm">{data.created_date}</Moment></p>
                                                                </div>
                                                                {/* <div className="col-md-12">
                                                                    <p className="cold_call_header mb0" style={{float:'left', paddingLeft:15, margin: 0}}><Moment format="HH:mm">{data.created_date}</Moment></p>
                                                                </div> */}
                                                              </div>
                                                              <hr style={{margin:"10px 0px 10px"}}/>
                                                              <div className='row'>
                                                                <div className="col-md-12">
                                                                  <p className="cold_call_header_date" style={{float:'left', paddingLeft:15}}>
                                                                    {Number(data.has_audio) === Number(1) ? 
                                                                      <audio controls controlsList="nodownload noplaybackrate">
                                                                        <source src={data.audio_name} type="audio/mpeg" />
                                                                      </audio>
                                                                    : 
                                                                      data.note
                                                                    }
                                                                  </p> 
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      ))
                                                      : <div className="mt30"><center><h4>No Notes to Show</h4></center></div>
                                                    : <div className="mt30"><center><h4>No Notes to Show</h4></center></div>
                                                  }
                                                </div>
                                              </div>
                                            </AccordionItemPanel>
                                          </AccordionItem>
                                        </Accordion>
                                      : null}
                                    </>
                                    :
                                      //for general
                                      <div className="open_listing_shadow" key={outerKey}> 
                                        <div className = "row">
                                          <div className="col-md-12">
                                            <div className="generalHeader" style={{marginRight: "-18px"}}>
                                              <span >General</span> {Number(feedback[outerKey].cancelled) === 1 ? <span className="cancelspan floatRight" style={{verticalAlign: "top", padding: "1px 5px 1px 5px"}}> Cancelled </span> : null} 
                                              {(Number(_.props.property_detail.is_archived) !== 1) && (Number(_.props.property_detail.is_office_listing) !== 1) && (Number(_.props.property_detail.is_other_listing) !== 1) && (Number(feedback[outerKey]["cancelled"]) === 0) && (Number(feedback[outerKey]["appointment_status_id"]) !== 3 || Number(feedback[outerKey]["appointment_status_id"]) !== 4) && (!feedback[outerKey]["date_closed"] ) ?
                                                <button ref='edit' type="button" id={feedback[outerKey]["general_appointment_id"]} className="btn btn-info fontWeight500" style={{float:'right', backgroundColor: "#27afaf", border: "1px solid #27afaf", verticalAlign: 'bottom', padding: "0px 5px 0px 5px", marginRight: "24px"}} onClick={() => _.handleAppointmentEdit(feedback[outerKey]['general_appointment_id'], 2)}> <Icon icon={editIcon} style={{verticalAlign: "middle"}}/> </button> 
                                              : null
                                              }
                                            </div>
                                          </div>
                                          <div className="col-md-12 mt15">
                                            {/* Meeting With details */}
                                            {feedback[outerKey].meeting_with ? 
                                              <span style={{display:"block"}} className="fontSize16 color0F1D30 fontWeight500 fontInter">
                                                {feedback[outerKey].meeting_with} 
                                              </span>
                                            : null
                                            }
                                          
                                            {/* Appointment Date */}
                                            <span className = "color556378 fontInter fontSize14 fontWeight400">
                                              <Moment format="MMM D YYYY">{feedback[outerKey].appointment_date}</Moment><span>,</span><br/>
                                              <Moment format="HH:mm">{feedback[outerKey].appointment_date}</Moment>
                                            </span>                                              
                                          </div>
                                        </div>
                                    
                                        <hr className="openlisting_feedback_separator"/>
                                    
                                        <div className="viewingDiv wrap">  
                                          {/*Feedback  */}
                                          <span className="fontSize13 color556378 fontInter">
                                            {feedback[outerKey].has_audio === "1" ?
                                              <p>
                                                <audio controls controlsList="nodownload noplaybackrate">
                                                  <source src={feedback[outerKey].audio_name } type="audio/mpeg" />
                                                </audio>
                                              </p>
                                            :
                                              <p className="mt5 padRight10 fontWeight600"> 
                                                {feedback[outerKey].reason ? 
                                                  feedback[outerKey].reason 
                                                : 
                                                  "No Note provided"
                                                } 
                                              </p>
                                            }
                                          </span>
                                        </div>
                                      </div>
                                  );
                                })
                              :
                                <div className="mt15"><center><h4 className="mt15">No Data Available To Show</h4></center></div>
                            :
                            null }

                          </div>
                        </div>
                      </div>
                    </div>
                  
                    <div id="property_notes" className="tab-pane fade">
                      {this.props.property_notes ? 
                        this.props.property_notes.length > 0 ?
                          this.props.property_notes.map((data) => (
                            <div className="row" key={data.created_date}>
                              <div className="col-md-12">
                                <div className="property_notes_shadow" key={data.created_date}>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <p className="cold_call_header mt15" style={{float:'left', paddingLeft:15, marginTop:15}}><Moment format="MMM D YYYY, HH:mm">{data.created_date}</Moment></p>
                                    </div>
                                    {/* <div className="col-md-12">
                                        <p className="cold_call_header mb0" style={{float:'left', paddingLeft:15, margin: 0}}><Moment format="HH:mm">{data.created_date}</Moment></p>
                                    </div> */}
                                  </div>
                                  <hr style={{margin:"10px 0px 10px"}}/>
                                  <div className='row'>
                                    <div className="col-md-12">
                                      <span className="fontSize13 color556378 fontInter">
                                        <p className="ml15 mr15">
                                          {Number(data.has_audio) === 1 ? 
                                            <audio controls controlsList="nodownload noplaybackrate">
                                              <source src={data.audio_name} type="audio/mpeg" />
                                            </audio>
                                          :
                                            <p className="cold_call_header_date mb15" style={{float:'left'}}>{data.note}</p> 
                                          }
                                        </p>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                          : <div className="mt30"><center><h4>No Notes to Show</h4></center></div>
                        : <div className="mt30"><center><h4>No Notes to Show</h4></center></div>
                      }
                    </div>
                  
                    <div id="office_notes" className="tab-pane fade">
                      {this.props.office_text ?
                        Object.keys(this.props.office_text).length !== 0 ?
                        _.handleOfficeNotes(office_text, agentId)
                          : <div className="mt30"><center><h4>No Notes to Show</h4></center></div>
                        : <div className="mt30"><center><h4>No Notes to Show</h4></center></div>
                      }
                    </div>
                  </div>
                   {/* Tabs panel closed */}

                   
                </div>  { /* Linking Message Div */ }
              </div>  { /* Container */ } 
            </div>
            <div className="modal-body">
              {/* Agent Comment UI Starts Here  */}
            </div>
          </div> 
        </div>
      </div>{ /* Property Modal Ends here */  }
      
      <EditViewingAppointment
        setViewingDateTime={click => this.setViewingDateTime = click}
      />
      <EditGeneralAppointment
        setGeneralData={click => this.setGeneralData = click}
        from_stock_pipeline = {0}
      />

      <ShareCode></ShareCode>
      <MarkAsSold property_detail = {this.props.property_detail} fromnetworking={this.props.from_networking} agent_id={this.props.agent_id} data_table_filter_by={this.props.data_table_filter_by}/>
      <MarkAsRented property_detail = {this.props.property_detail} fromnetworking={this.props.from_networking} agent_id={this.props.agent_id} data_table_filter_by={this.props.data_table_filter_by}/>
      <MarkAsRentedUpdate property_detail = {this.props.property_detail} fromnetworking={this.props.from_networking} agent_id={this.props.agent_id} data_table_filter_by={this.props.data_table_filter_by}/>
      <CrmVerifiedModal/>
      <CrmNotVerifiedModal/>
    </div>)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    get_auth_data : (agent_id,token) => dispatch(actions.get_auth_data(agent_id,token)),
    get_all_agent_data_dd: (token)=> dispatch(actions.get_all_agent_data_dd(token)),
    get_edit_property_data : (prop_id, authtoken) => dispatch(actions.get_edit_property_data(prop_id, authtoken)),
    update_add_property_modal_state: (add_property_modal_state) => dispatch(actions.update_add_property_modal_state(add_property_modal_state)),
    //set page for calendar
    set_page: (page, reset_date_range) => dispatch(actions.set_page(page, reset_date_range)),
    //set appointment state
    update_add_appointment_modal_state: (show_agents,show_property,from_property_page,from_stock_pipeline) => dispatch(actions.update_add_appointment_modal_state(show_agents,show_property,from_property_page,from_stock_pipeline)),
    reset_property_modal: () => dispatch(actions.reset_property_modal()),
    //get viewing appointment
    get_viewing_appointment: (authtoken, appointment_id) => dispatch(actions.get_viewing_appointment(authtoken, appointment_id)),
    //get general appointment
    get_general_appointment: (authtoken, appointment_id) => dispatch(actions.get_general_appointment(authtoken, appointment_id)),
  }
}

const mapStateToProps = state => {
  return{
    authtoken: state.add_property_modal.authtoken,
    date_sold: state.property_modal.date_sold,
    from_networking: state.property_modal.from_networking,
    from_archiveProperty: state.property_modal.from_archiveProperty,
    property_notes: state.property_modal.property_notes,
    office_notes: state.property_modal.office_notes,
    office_text: state.property_modal.office_text,
    general_appointment: state.edit_appointment_modal.general_appointment,
    viewing_appointment: state.edit_appointment_modal.viewing_appointment,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PropertyDetailModal)
