import * as actionTypes from '../actions/actionsTypes'; 
import {updateObject} from '../utility';

const initialState = {
    //property modal data
    property_agent_data: null,
    property_detail: null,
    appointment_cnt: null,
    general_cnt_property_modal: null,
    feedback: null,
    seller_message: null,
    occupant_message: null,
    html_export: null,
    property_notes_html: null,
    property_notes: null,
    office_notes: null,
    office_text: null,
    office_notes_html: null,
    date_sold: null,
    from_networking: null,
    from_archiveProperty: null,
    mark_as_sold_form_error: null
}

const get_property_data_modal = (state, action) => {
    return updateObject(state,{
        property_agent_data: action.property_agent_data,
        property_detail: action.property_detail,
        appointment_cnt: action.appointment_cnt,
        general_cnt_property_modal: action.general_cnt,
        feedback: action.feedback,
        seller_message: action.seller_message,
        occupant_message: action.occupant_message,
        html_export: action.html_export,
        property_notes_html: action.property_notes_html,
        property_notes: action.property_notes,
        office_notes: action.office_notes,
        office_text: action.office_text,
        office_notes_html: action.office_notes_html,
        date_sold: action.date_sold,
        from_networking: action.from_networking,
        from_archiveProperty: action.from_archiveProperty
    })
} 

const reset_property_modal = (state) =>{
    return updateObject(state, initialState)
}

const mark_as_sold = (state) => {
    return updateObject(state, {mark_as_sold_form_error: 'success'})
}
const mark_as_rent = (state) => {
    return updateObject(state, {mark_as_sold_form_error: 'success'})
}
const update_rent = (state) => {
    return updateObject(state, {mark_as_sold_form_error: 'success'})
}
const submit_fail = (state, action) => {
    return updateObject(state,{
        mark_as_sold_form_error: action.mark_as_sold_form_error
    })
}
const reset_mark_as_sold_modal = (state)=>{
    return updateObject(state,{
        mark_as_sold_form_error: null
    })
}

function property_modal_reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.GET_PROPERTY_DATA_MODAL: return get_property_data_modal(state, action);
        case actionTypes.RESET_PROPERTY_MODAL: return reset_property_modal(state);
        case actionTypes.ADD_MARK_AS_SOLD: return mark_as_sold(state);
        case actionTypes.ADD_MARK_AS_RENT: return mark_as_rent(state);
        case actionTypes.ADD_UPDATE_RENT: return update_rent(state);
        case actionTypes.MARK_AS_SOLD_SUBMIT_FAIL: return submit_fail(state,action);
        case actionTypes.RESET_MARK_AS_SOLD_MODAL: return reset_mark_as_sold_modal(state)
        default:
        return state;
    }
}

export default property_modal_reducer;