import React,{ Component } from "react";
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import Loader from '../../Loader';
import AgentStockPipelineChartById from '../../modal_charts/AgentStockPipelineChartById';
//import { Calendar } from 'primereact/calendar';

import AddStockPipeline from '../Add_Edit_StockPIpeline/AddStockPipeline'
import DateRange from "../../DateRange";

//icons
import { Icon } from '@iconify/react';
import homeCityOutline from '@iconify/icons-mdi/home-city-outline';
import funnelDollar from '@iconify/icons-fa-solid/funnel-dollar';
import chatRightTextFill from '@iconify/icons-bi/chat-right-text-fill';
import officeBuildingMarker from '@iconify/icons-mdi/office-building-marker';

class StockPipeline extends Component{
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            startDate: new Date(new Date().getFullYear(), 0, 1),
            endDate:   new Date(new Date()),
            CalenderMinDate: new Date(new Date().getFullYear(), 0, 1),
            CalenderMaxDate: new Date(new Date()),
            todayDate: new Date(),
            renderAddStockpipelineModal: false
        }
    }
    renderStockPipeline_modal = async (event)=>{
        // let page = '';
        // let is_archived='0'
        // await this.props.get_stock_pipeline_data(page, is_archived, stock_pipeline_id, this.props.authtoken)
        
        //let isVisit = 1
        let stock_pipeline_id = event.currentTarget.id;
        const token = localStorage.getItem("token");
        const agent_id = this.props.agent_id;
        await this.props.get_stock_pipeline_property_data(stock_pipeline_id, agent_id, token)
        window.$("#stockpipeline_modal").modal('show');
    }
    
    renderMoreStockPipeline = () =>{
        let newpage = Number(this.state.page) + 1;
        this.setState({page: newpage}, () =>{
            let is_archived='0'
            this.props.get_stock_pipeline_data(this.state.page, is_archived, null, this.props.authtoken);
        })
    }

    retrieveStockPipelineByDate  = () =>{
        //retrieveStockPipelineByDate  = (fromDate,toDate) =>{
        //fromDate = this.changeDateFormat(fromDate);
        //toDate = this.changeDateFormat(toDate);
        var date = new Date();
        var fromDate = this.changeDateFormat(new Date(date.getFullYear(), date.getMonth() - 2, 1));
        var toDate = this.changeDateFormat(new Date(date.getFullYear(), date.getMonth()));
        const token = localStorage.getItem('token');
        const agent_id = this.props.agent_id;
        this.props.get_stockpipeline_chart_by_id(fromDate, toDate, agent_id, token);
    }
    changeDateFormat(fromDate) {
        var d = new Date(fromDate),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
    
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [year, month, day].join('-');
    }

    handleAddStockPipelineModal = async () => {
        this.setState({agent_id: this.props.agent_id,
            renderAddStockpipelineModal : true
        }, ()=>{
            window.$("#addStockPipelineModal").modal('show');
        })
        
    }

    render(){ 
        return(<>
            <div className = "stock-pipeline" style={{minHeight:550}}>
                <div className="row mt20">
                    <div className = "col-md-8">
                        <span className="box-title fontSize17 ml15  color12171D fontWeight600 fontInter">
                        <Icon icon={funnelDollar} style={{color: '#4eb3aa', fontSize: '20px', verticalAlign: 'middle'}} />
                        <span className="ml20">
                            Stock Pipeline&nbsp;</span>
                        </span>
                    </div>
                </div>
                <br/>
                {!this.props.loader?
                    <div className="row" style={{ marginTop: '-15px' }}>
                        <div className="col-md-8" style={{ display: 'center' }}>
                            <DateRange agent_id={this.props.agent_id}/>
                        </div>
                    </div>
                : null}
                {/* <div className="row" style={{ marginTop: '-15px' }}>
                    <div className="col-md-8 padTop10" style={{ display: 'flex' }}>
                      <Calendar value={this.state.startDate} maxDate={this.state.CalenderMaxDate} dateFormat="dd-mm-yy" name="appointmentFromProp" id="appointmentFromProp" className='ml15'
                        onChange={(e) => this.setState({ startDate: e.value, renderAgentButton: 1, CalenderMinDate:e.value }) }>
                      </Calendar>
                      <Calendar value={this.state.todayDate} minDate={this.state.CalenderMinDate} maxDate={this.state.todayDate} dateFormat="dd-mm-yy" name="appointmentToProp" id="appointmentToProp"
                        onChange={(e) => this.setState({ endDate: e.value, CalenderMaxDate:e.value }) }>
                      </Calendar>
                      <button type="button" style={{ backgroundColor: '#5FC8BF', borderRadius: '0px 10px 10px 0px', padding: "4px 12px" }} className="btn btn-info"
                        onClick={() => this.retrieveStockPipelineByDate(this.state.startDate, this.state.endDate) }
                      >
                        Go
                      </button>
                    </div>
                </div> */}
                <br />
                {this.props.stockpipeline_labels_by_id ? 
                    <div className="ml10">
                        <AgentStockPipelineChartById  agent_id = {this.props.agent_id} labels = { this.props.stockpipeline_labels_by_id } agent_prop_cnt = { this.props.stockpipeline_count_by_id } />
                    </div>
                : null}
                
                <div className = "linkedProp mt20">
                    <button type="submit" onClick={this.handleAddStockPipelineModal} className="btn btn-primary fontBold ml15" style={{ background:'#4AA49C',borderRadius:'4px'}} > <Icon className="message_icon" icon={officeBuildingMarker} height='20' width='20' /> Add Stock Pipeline</button>
                </div>
                  
                <div className="row mt20 mb20">
                    <div className = "col-md-8">
                        <span className="box-title fontSize17 ml15  color12171D fontWeight600 fontInter">
                        <Icon icon={homeCityOutline} style={{color: '#4eb3aa', fontSize: '20px', verticalAlign: 'middle'}} />
                        <span className="ml20">
                            {this.props.stock_pipeline ? this.props.stock_pipeline.length : 0 } &nbsp;
                            {this.props.stock_pipeline ? 
                                Number(this.props.stock_pipeline.length) === 1 ? "Property"
                                : "Properties"
                            : "Properties"}
                            </span>
                        </span>
                    </div>
                </div>
                
                {this.props.stock_pipeline ?
                    this.props.stock_pipeline.map((data) => (
                        <div key={data.id}>
                        <div id={data.id} className='row padTop15 padBottom15' style={{cursor:'pointer'}} onClick={this.renderStockPipeline_modal}>
                            <div className="col-md-10">
                                <p className="cold_call_header_date" style={{float:'left', paddingLeft:15, marginBottom:5}}>{data.address}</p> 

                                <div className="row">
                                    <div className="col-md-12">
                                        <span className="cold_call_header" style={{float:'left', paddingLeft:15}}>{data.unit_no}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <span style={{verticalAlign: 'middle'}}>
                                    <Icon icon={chatRightTextFill} style={{color: '#4eb3aa', fontSize: '20px', verticalAlign: 'middle'}} />
                                    <span className="color738196 fontSize12 ml5 mt5 fontInter">{data.notes_cnt}</span>
                                </span>
                            </div>
                        </div>
                        <hr style={{margin:"0px"}}/>
                        </div>
                    ))
                    : this.props.loader ?
                    <Loader/> : null
                }
                
                {Array.isArray(this.props.stock_pipeline) && this.props.stock_pipeline.length ?
                    this.props.stock_load_more ?
                        <center>
                            <button type="button" className="btn btn-info fontWeight700" style={{backgroundColor: "#5FC8BF", marginTop: 20}} onClick={this.renderMoreStockPipeline}>Load More</button>
                        </center>
                        : null
                    :   
                        <center><br/><br/><h4>No Stock Pipeline Properties to Show</h4></center>
                }
            </div>
            {this.state.renderAddStockpipelineModal ?
                <AddStockPipeline
                    authtoken = {this.props.authtoken}
                    agent_id = {this.props.agent_id}
                />
                : false
            }
            </>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        get_stock_pipeline_data : (page, is_archived, stock_pipeline_id, token) => dispatch(actions.get_stock_pipeline_data(page, is_archived, stock_pipeline_id, token)),
        get_stock_pipeline_property_data : (stock_pipeline_id, agent_id, token) => dispatch(actions.get_stock_pipeline_property_data(stock_pipeline_id, agent_id, token)),
        get_stockpipeline_chart_by_id : (fromDate, toDate, agent_id, token) => dispatch(actions.get_stockpipeline_chart_by_id(fromDate, toDate, agent_id, token)),
    }
}
  
const mapStateToProps = state => {
    return{
        //agent tab
        stock_pipeline: state.agent_modal.stock_pipeline,
        stock_load_more: state.agent_modal.stock_load_more,
        //agent mobile authtoken
        authtoken: state.add_property_modal.authtoken,
        
        stockpipeline_labels_by_id: state.agent_modal.stockpipeline_labels_by_id,
        stockpipeline_count_by_id: state.agent_modal.stockpipeline_count_by_id,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StockPipeline)