import * as actionTypes from './actionsTypes';
import Constants from '../../constants/Constants';

var myHeaders = '';
var raw = '';
var requestOptions = '';

export const get_viewing_appointment = (authtoken, appointment_id) => {
    return async dispatch =>{
        myHeaders = new Headers();
        myHeaders.append("authtoken", authtoken);
        raw = JSON.stringify(
        {
            "id": appointment_id,   
        });
        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        await fetch(Constants.GET_VIEWING_APPOINTMENT, requestOptions)
        .then(response => response.json())
        .then(data => {
            dispatch(viewing_appointment(data));
        })
        .catch(error => console.log('error', error));
    }
}
export const viewing_appointment = (data)=>{
    return{
        type: actionTypes.GET_VIEWING_APPOINTMENT,
        viewing_appointment: data.data,
    }
}

export const get_general_appointment = (authtoken, appointment_id) => {
    return async dispatch =>{
        myHeaders = new Headers();
        myHeaders.append("authtoken", authtoken);
        raw = JSON.stringify(
        {
            "appointment_id": appointment_id,   
        });
        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        await fetch(Constants.GET_GENERAL_APPOINTMENT, requestOptions)
        .then(response => response.json())
        .then(data => {
            dispatch(general_appointment(data));
        })
        .catch(error => console.log('error', error));
    }
}
export const general_appointment = (data)=>{
    return{
        type: actionTypes.GET_GENERAL_APPOINTMENT,
        general_appointment: data.data,
    }
}

export const update_viewing_appointment = (authtoken, appointment_id, appointment_date) => {
    return async dispatch =>{
        myHeaders = new Headers();
        myHeaders.append("authtoken", authtoken);
        
        raw = JSON.stringify(
        {
            "appointment_id": appointment_id,
            "appointment_date": appointment_date
        });
        
        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        
        await fetch(Constants.UPDATE_VIEWING_APPOINTMENT, requestOptions)
        .then(response => response.json())
        .then(data => {
            dispatch(edit_viewing_appointment(data));
        })
        .catch(error => console.log('error', error));
    }
}

export const edit_viewing_appointment = (data) => {
    return{
        type: actionTypes.EDIT_VIEWING_APPOINTMENT,
        update_viewing_appointment_success: data.success,
    }
}

export const update_general_appointment = (authtoken, appointment_id, timezone, reason, my_stock_pipeline_id, appointment_date, meeting_with, property_id, audio_data, address) => {
    return async dispatch =>{
        let error=[];
        if((!authtoken) || (!reason && !audio_data) || (!appointment_date) || (!appointment_id)){
            if(!authtoken){
                error.msg = "Please select the Agent";
                dispatch(submitFail(error));
            }else if(!reason && !audio_data){
                error.msg = "Please enter the Reason/Note for appointment";
                dispatch(submitFail(error));
            }else if(!appointment_date ){ 
                error.msg = "Please select appointment date and time";
                dispatch(submitFail(error));
            }else if(!appointment_id){
                error.msg = "Something went wrong please try again";
                dispatch(submitFail(error));
            }
        }else if(authtoken || reason || (my_stock_pipeline_id && property_id) || appointment_date || address){
            myHeaders = new Headers();
            myHeaders.append("authtoken", authtoken);
            myHeaders.append("timezone", timezone);
            
            var raws = new FormData();
            raws.append("appointment_id", appointment_id);
            raws.append("reason", audio_data ? null : reason.trim());
            raws.append("my_stock_pipeline_id", my_stock_pipeline_id ? my_stock_pipeline_id : '' );
            raws.append("appointment_date", appointment_date);
            raws.append("meeting_with", meeting_with ? meeting_with: '');
            raws.append("property_id", property_id ? property_id : '');
            raws.append("audiodata", audio_data);
            //raws.append("office_agent_notification", 1);

            requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raws,
                redirect: 'follow'
            };

            error.msg = "";
            dispatch(submitFail(error));
            await fetch(Constants.UPDATE_GENERAL_APPOINTMENT, requestOptions)
            .then(response => response.json())
            .then(data => {
                dispatch(edit_general_appointment(data));
            })
            .catch(error => console.log('error', error));
        }
    }
}
export const edit_general_appointment = (data) => {
    return{
        type: actionTypes.EDIT_GENERAL_APPOINTMENT,
        update_general_appointment_success: data.success,
    }
}

export const submitFail = (error) => {
    return {
        type: actionTypes.UPDATE_APPOINTMENT_SUBMIT_FAIL,
        update_appointment_form_error: error.msg,
        update_general_appointment_success: 3,
    }
}

export const reset_appointment_data = () =>{
    return {
        type: actionTypes.RESET_APPOINTMENT_DATA
    }
}