import React,{ Component } from "react";
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import ReactTooltip from 'react-tooltip';
import { Calendar } from 'primereact/calendar';
import Moment from 'react-moment';
//icons
import { Icon } from '@iconify/react';
import bxTrip from '@iconify/icons-bx/bx-trip';
// import resultOld from '@iconify/icons-carbon/result-old';
import downloadFill from '@iconify/icons-eva/download-fill';
import Loader from "../../Loader";

class TravelTracking extends Component{
    constructor(props) {
        super(props);
        this.state = {
            startDate: new Date(new Date().getFullYear(), new Date().getMonth()-2, 1),
            endDate:   new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
            CalenderMinDate: new Date(new Date().getFullYear(), 0, 1),
            upcoming_page: 1,
            past_page: 1,
            missed_page: 1,
            total_distance: "0KM",
            total_claim_exp: "0.00",
        }
        this.changeDateFormat = this.changeDateFormat.bind(this);
        //this.resetDateRange = this.resetDateRange.bind(this);
    }    

    changeDateFormat(fromDate) {
        var d = new Date(fromDate),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
    
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [year, month, day].join('-');
    }

    handleOpenTravelTrackingDetailModal = (trip_data) => {
        //window.$("#traveltrackingdetailModal").modal('show');
        this.props.setTrip(trip_data);
    }

    renderMoreUpcomingTrips = async () =>{
        let newpage = Number(this.state.upcoming_page) + 1;
        this.setState({upcoming_page: newpage}, async () =>{
            await this.props.get_upcoming_trips(this.props.authtoken, newpage);
        })
    }
    renderMorePastTrips = async () =>{
        let newpage = Number(this.state.past_page) + 1;
        this.setState({past_page: newpage,
            total_distance: this.props.total_distance,
            total_claim_exp: this.props.total_claim_exp,
        }, async () =>{
            await this.props.get_past_trips(this.props.authtoken, this.changeDateFormat(this.state.startDate), this.changeDateFormat(this.state.endDate), newpage);
        })
    }
    renderMoreMissedTrips = async () =>{
        let newpage = Number(this.state.missed_page) + 1;
        this.setState({missed_page: newpage}, async () =>{
            await this.props.get_missed_trips(this.props.authtoken, newpage);
        })
    }

    filter_travel_log = () => {
        this.setState({past_page: 1}, async () =>{
            await this.props.get_past_trips(this.props.authtoken, this.changeDateFormat(this.state.startDate), this.changeDateFormat(this.state.endDate), this.state.past_page);
        })
    }
    render(){ 
        return(<>
            <div className = "travel-tracking" style={{minHeight:550}}>
                <ReactTooltip resizeHide = { true } backgroundColor={'#0B2C4C'}  />
                {/* Upcoming and past trips Tab */}
                <ul className="nav nav-tabs">
                    <li style={{ width: '33.33%' }} className="active fontInter upcoming_trips">
                      <a data-toggle="tab" id="saletab" href="#upcoming_trips">
                        <Icon className="mr5" icon={bxTrip} style={{color:'#27AFAF', fontSize:'24px', verticalAlign:'middle'}} />
                        <b>Upcoming</b>
                        <br />
                        {this.props.upcoming_trips ?
                            <span className="fontSize12 fontWeightNormal fontInter"><b>{this.props.upcoming_trips.length} &nbsp; {this.props.upcoming_trips.length > 1 ? "Trips" : "Trip" }</b></span>
                        : "0 Trips"
                        }
                      </a>
                    </li>
                     
                    <li style={{ width: '33.33%' }} className="fontInter past_trips">
                      <a data-toggle="tab" href="#past_trips">
                        {/* <Icon className="mr5" icon={resultOld} style={{color:'#4da69e', fontSize:'24px', verticalAlign:'middle'}} />        */}
                        <img src='images/saved_trips.svg' alt='icon' className="mr5"/>
                        <b>Saved Trips</b>
                        <br />
                        {this.props.past_trips ?
                            <span className="fontSize12 fontWeightNormal fontInter"><b>{this.props.past_trips.length} &nbsp; {this.props.past_trips.length > 1 ? "Trips" : "Trip" }</b></span> 
                            : "0 Trips"
                        }
                        </a>
                    </li>

                    <li style={{ width: '33.33%' }} className="fontInter missed_trips">
                      <a data-toggle="tab" href="#missed_trips">
                        {/* <Icon className="mr5" icon={resultOld} style={{color:'#4da69e', fontSize:'24px', verticalAlign:'middle'}} />        */}
                        <img src='images/unsaved_trips.svg' alt='icon' className="mr5"/>
                        <b>Unsaved Trips</b>
                        <br />
                        {this.props.missed_trips ?
                            <span className="fontSize12 fontWeightNormal fontInter"><b>{this.props.missed_trips.length} &nbsp; {this.props.missed_trips.length > 1 ? "Trips" : "Trip" }</b></span>
                            : "0 Trips"
                        }
                        </a>
                    </li>
                </ul>
                
                <div className="tab-content" style={{ marginTop: '40px', overflow: 'auto' }}>
                    {Array.isArray(this.props.upcoming_trips) && this.props.upcoming_trips.length ?
                        <>
                        {this.props.upcoming_trips.map((data, index) => (
                            <div id="upcoming_trips" className="tab-pane fade in active" key={index}>
                                <div className="travel-tracking-list">
                                    <div className="row travel-tracking-list-row" style={{marginRight:"0px"}}>
                                        <div className="col-md-1 travel-tracking-list-col">
                                            <span className="travel-tracking-list-col-span"><Moment format="D MMM">{data.appointment_date}</Moment></span>
                                        </div>
                                        <div className="col-md-8">
                                            <p className="travel-tracking-list-addrress">{data.address}</p>
                                            <p className="travel-tracking-list-date"><Moment format="hh:mm">{data.appointment_date}</Moment></p>
                                        </div>
                                    </div>
                                </div> 
                            </div>  /* For Upcoming Tab Ends Here  */
                        ))}

                        {this.props.upcoming_trips_load_more ?
                            <center>
                                <button type="button" className="btn btn-info fontWeight700" style={{backgroundColor: "#5FC8BF", marginTop: 20}} onClick={this.renderMoreUpcomingTrips}>Load More</button>
                            </center>
                        : null}
                        </>
                    : 
                        <div id="upcoming_trips" className="tab-pane fade in">
                            <center style={{marginBottom: "170px"}}><br/><br/><h4>No Upcoming Trips To Show</h4></center>
                        </div>
                    }

                    {/* Second Tab For Past Trips Starts  */}
                    <div id="past_trips" className="tab-pane fade">
                        {/* Date filter */}
                        <div className="mb20" style={{textAlign:'center'}}>
                            <input type="text" className="from_label" value="From:" disabled style={{fontSize: "16px"}}/>
                            <Calendar value={this.state.startDate} maxDate={this.state.endDate} dateFormat="dd-mm-yy" name="from_date" id="travel_from_date" className='ml15 travel-tracking-date'
                                onChange={(e) => {this.setState({ startDate: e.value, CalenderMinDate:e.value });}}>
                            </Calendar>
                            &nbsp;
                            <input type="text" className="to_label" value="To:" disabled style={{fontSize: "16px"}}/>
                            <Calendar minDate={this.state.CalenderMinDate} maxDate={new Date()} name="to_date" id="travel_to_date" value={this.state.endDate} dateFormat="dd-mm-yy" className='travel-tracking-date'
                                onChange={(e) => {this.setState({ endDate: e.value });}}>
                            </Calendar>
                            <div style={{display:"inline-block"}}>
                                <button type="button" style={{ backgroundColor: '#27AFAF', borderRadius: '5px', padding: "7px 15px 7px 15px" }} className="btn btn-info ml10"
                                    onClick={() => this.filter_travel_log() }
                                >Apply</button>
                            </div> 
                        </div>

                        {/* Trip Information box */}
                        {this.props.past_trips ?
                            <div className="row travel-tracking-past-trips-box">
                                <div className="col-md-5" style={{borderRight: "1px solid #D1E5F8", textAlign:'center', marginTop:5}}>
                                    <span className="travel-tracking-past-trips-text-head"> Distance</span>
                                    {this.state.past_page === 1?
                                        <span className="travel-tracking-past-trips-text"> {this.props.total_distance ? this.props.total_distance : "0 KM"}</span>
                                    :
                                        <span className="travel-tracking-past-trips-text"> {this.state.total_distance}</span>
                                    }
                                </div>
                                <div className="col-md-6" style={{textAlign:'center', marginTop:5}}>
                                    <span className="travel-tracking-past-trips-text-head"> Claimable Expense</span>
                                    {this.state.past_page === 1?
                                        <span className="travel-tracking-past-trips-text"> {this.props.agent_data.currency_symbol} {this.props.total_claim_exp ? Number(this.props.total_claim_exp).toFixed(2) : "0.00"}</span>
                                    :
                                        <span className="travel-tracking-past-trips-text"> {this.props.agent_data.currency_symbol} {Number(this.state.total_claim_exp).toFixed(2)}</span>
                                    }
                                </div>
                                <div className="col-md-1">
                                    <Icon className="mr5" icon={downloadFill} style={{color:'#27AFAF', fontSize:'34px', verticalAlign:'middle'}} />
                                </div>
                            </div>
                        :
                            <Loader />
                        }

                        {(this.props.past_trips && this.props.past_trips.length > 0)  && (this.props.agent_data) ?
                            <>
                            {this.props.past_trips.map((data, index) => (
                                <div className="travel-tracking-list" onClick = { ()=> this.handleOpenTravelTrackingDetailModal(data)} key={index}>
                                    <div className="row travel-tracking-list-row" style={{marginRight:"0px"}}>
                                        <div className="col-md-1 travel-tracking-list-col" style={{paddingTop:"26px", paddingBottom:"26px"}}>
                                            <span className="travel-tracking-list-col-span"><Moment format="D MMM">{data.start_time}</Moment></span>
                                        </div>
                                        <div className="col-md-7" style={{width:"63.66666667%"}}>
                                            <p className="travel-tracking-list-addrress">{data.end_address}</p>
                                            <p className="travel-tracking-list-vehicle">{data.car_make} {data.car_model}</p>
                                            <p className="travel-tracking-list-price">{this.props.agent_data.currency_symbol} {Number(data.claimable_exp).toFixed(2)} {/* {Number(data.cost_per_km * data.distance).toFixed(2)} */} </p>
                                        </div>
                                        <div className="col-md-3" style={{textAlign:"right"}}>
                                            <p className="travel-tracking-list-date"><Moment format="hh:mm">{data.start_time}</Moment> - <Moment format="hh:mm">{data.end_time}</Moment></p>
                                            
                                            <p className="travel-tracking-list-dist">{data.distance}km</p>
                                        </div>
                                    </div>
                                </div>
                            ))}

                            {this.props.past_trips_load_more ?
                                <center>
                                    <button type="button" className="btn btn-info fontWeight700" style={{backgroundColor: "#5FC8BF", marginTop: 20}} onClick={this.renderMorePastTrips}>Load More</button>
                                </center>
                            : null}
                            </>
                        :
                            <div className="tab-pane fade">
                                <center style={{marginBottom: "170px"}}><br/><br/><h4>No Saved Trips To Show</h4></center>
                            </div>
                        }
                    </div>

                    {/* Third Tab for Missed Trips */}
                    <div id="missed_trips" className="tab-pane fade">
                        {Array.isArray(this.props.missed_trips) && this.props.missed_trips.length ?
                            <>
                            {this.props.missed_trips.map((data, index) => (
                                <div className="travel-tracking-list" key={index}>
                                    <div className="row travel-tracking-list-row" style={{marginRight:"0px"}} key={index}>
                                        <div className="col-md-1 travel-tracking-list-col" style={{paddingTop:"26px", paddingBottom:"26px"}}>
                                            <span className="travel-tracking-list-col-span"><Moment format="D MMM">{data.appointment_date}</Moment></span>
                                        </div>
                                        <div className="col-md-12" style={{width:"88.6667%"}}>
                                            <p className="travel-tracking-list-addrress">{data.address}</p>
                                            <p className="travel-tracking-list-date"><Moment format="hh:mm">{data.appointment_date}</Moment></p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {/* {this.props.missed_trips_load_more ?
                                <center>
                                    <button type="button" className="btn btn-info fontWeight700" style={{backgroundColor: "#5FC8BF", marginTop: 20}} onClick={this.renderMoreMissedTrips}>Load More</button>
                                </center>
                            : null}  */}
                            </>
                            :
                            <div id="missed_trips" className="tab-pane fade">
                                <center style={{marginBottom: "170px"}}><br/><br/><h4>No Unsaved Trips To Show</h4></center>
                            </div>
                        }
                    </div>
                </div>
            </div>
            
            </>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        get_upcoming_trips:(authtoken,page) => dispatch(actions.get_upcoming_trips(authtoken,page)),
        get_past_trips:(authtoken, start_date, end_date, page) => dispatch(actions.get_past_trips(authtoken, start_date, end_date, page)),
        get_missed_trips:(authtoken,page) => dispatch(actions.get_missed_trips(authtoken,page)),
        //set page for calendar
        set_page: (page, reset_date_range) => dispatch(actions.set_page(page, reset_date_range)),
    }
}
  
const mapStateToProps = state => {
    return{
        agent_data: state.agent_modal.agent_data,
        authtoken: state.add_property_modal.authtoken,
        upcoming_trips:  state.agent_modal.upcoming_trips,
        past_trips: state.agent_modal.past_trips,
        total_distance: state.agent_modal.total_distance,
        total_claim_exp: state.agent_modal.total_claim_exp,
        missed_trips: state.agent_modal.missed_trips,
        upcoming_trips_load_more: state.agent_modal.upcoming_trips_load_more,
        past_trips_load_more: state.agent_modal.past_trips_load_more,
        missed_trips_load_more: state.agent_modal.missed_trips_load_more,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TravelTracking)