import React,{ Component } from "react";
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import Moment from 'react-moment';
import Constants from '../../constants/Constants';
import ReactTooltip from 'react-tooltip';

class OpenListingPropertyModal extends Component{
    showCRMVerifiedModal = () =>{
        window.$("#crmVerifiedModal").modal('show');
    }
        
    showCRMNotVerifiedModal = () =>{
        window.$("#crmNotVerifiedModal").modal('show');
    }
    render(){ 
        return(
            <div className="modal fade" id="openlisting_modal" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content modalheight" style ={{borderRadius : '12px' }} >
                        <div className="modal-container">
                            {!this.props.property_detail ?
                                <center><br /><img src="images/loader.svg" width="50" height="50" alt="" /> </center> 
                                :  
                                // <SimpleMap lat= { this.props.property_detail.lat } lon = {  this.props.property_detail.lon }  />
                                <div style={{ background: '#EDF7F6', paddingLeft: "20px", paddingTop: "20px",  paddingBottom: "20px" }}>
                                    <div className="row">
                                    <div className="col-md-2">
                                        {!this.props.property_detail.agent_image ?
                                        <img className="img-circle mb5 mr15" src="user-images/userDefault.png" width="64" height="64" alt="" />
                                        :
                                        <img className="img-circle mb5 mr15" src={Constants.IMAGE_URL + this.props.property_detail.agent_image} width="64" height="64" alt="" />
                                        }
                                    </div>
                                    <div className="col-md-7" style={{paddingLeft: "0px"}}>
                                        <p className="fontBold fontRoboto mb0"> Listing Agent</p>
                                        <p className="fontBold fontRoboto fontSize25 mb0">
                                        {!this.props.property_detail ? <center><img src="images/loader.svg" width="40" height="40" alt="" /></center> : this.props.property_detail.agent_fname +" "+ this.props.property_detail.agent_lname}
                                        </p>
                                        {/* <span className="fontRoboto fontSize13 color8591A0">{this.props.property_detail.agent_email}&nbsp;-&nbsp;{this.props.property_detail.agent_cellnumber}</span> */}
                                        {/* <span style={{ float: 'right' }}></span> */}
                                    </div>
                                    <div className="col-md-1">
                                        {/* <div className="circle ml20" data-dismiss="modal" >
                                        <img src="images/modal-close.png " width="12" height="12" alt="" />
                                        </div> */}
                                    </div>
                                    <div className="col-md-1"></div>
                                    </div>
                                </div>
                            }
                            
                            <div className="circle mt15" data-dismiss="modal" style={{marginLeft: "-10px"}} >
                                <img src="images/modal-close.png " width="12" height="12" alt="" />
                            </div> 

                            <div className = "container-fluid mb30" style={{minHeight:550}}> 
                                {this.props.property_detail ?
                                    <div className="openlisting_property_details">
                                        <div className="row">
                                            <div className="col-md-9">
                                                <span className="fontRoboto fontWeight500 fontSize16">
                                                    {this.props.property_detail ?
                                                        !this.props.property_detail.address ? 
                                                            <h4>Loading...</h4>
                                                            : 
                                                                <>
                                                                    {this.props.property_detail.external_url ? <a style={{color: "#fff", textDecoration: "underline"}} href={this.props.property_detail.external_url} target="_blank" rel="noreferrer"> {this.props.property_detail.address} </a> : this.props.property_detail.address}
                                                                    {Number(this.props.property_detail.crm_verified) === Number(1) ? 
                                                                        <img tag="allowRowEvents" alt='' width="20" height="20" style={{verticalAlign: "text-bottom", marginLeft: "5px", cursor:"pointer"}} src={"images/crm_verified.svg"} onClick={this.showCRMVerifiedModal}/>
                                                                    :
                                                                        this.props.property_detail.crm_id ? 
                                                                            <img tag="allowRowEvents" alt='' width="20" height="20" style={{verticalAlign: "text-bottom", marginLeft: "5px", cursor:"pointer"}} src={"images/crm_not_verified.svg"} onClick={this.showCRMNotVerifiedModal}/>
                                                                        :
                                                                            null
                                                                    }
                                                                </>
                                                        : null 
                                                    }
                                                </span>
                                                <span className="fontRoboto fontWeight700 fontSize16" style={{display: "block"}}>{this.props.property_detail.unit_name}</span>
                                                {this.props.property_detail ?
                                                    <p className="fontSize14 colorDAEEEC">Added on : <Moment format="MMM D, YYYY, HH:mm" className="colorDAEEEC">{this.props.property_detail.date_created}</Moment></p>
                                                    :
                                                    null
                                                }
                                                {this.props.property_detail ?
                                                <>
                                                    <ReactTooltip resizeHide = { true } backgroundColor={'#0B2C4C'}  />
                                                    <div>
                                                        {this.props.property_detail.date_sold ?
                                                            <span className="proeprty_rented_span" style={{marginRight:"5px"}}>
                                                                {Number(this.props.property_detail.rented) === Number(1) ? "Rented" : "Sold"}
                                                            </span>
                                                        : 
                                                            <span className="property_sale_type_span" style={{marginRight:"5px", fontSize: "14px", paddingBottom: "3px", paddingTop: "3px"}}>
                                                                {Number(this.props.property_detail.property_type) === Number(1) ? "For Sale" : "For Rent"}
                                                            </span>
                                                        }
                                                        
                                                    </div>
                                                </>
                                                : null}
                                            </div>
                                            <div className="col-md-3">
                                                {this.props.property_detail ?
                                                    <>
                                                    <span className="fontRoboto fontBold fontSize16">
                                                        {!this.props.property_detail.currency_symbol ? <h4>...</h4>:this.props.property_detail.currency_symbol} 
                                                        {!this.props.property_detail.price ? <h4>...</h4>:this.props.property_detail.price}
                                                    </span>
                                                    </>
                                                : null}
                                            </div>
                                        </div>
                                    </div>
                                : null}
                                <div className="row mt20">
                                    <div className = "col-md-8">
                                        <span className="box-title fontSize17 ml15  color12171D fontWeight600 fontInter">
                                            <h2 className="ml20">Viewings &nbsp;</h2>
                                        </span>
                                    </div>
                                </div>
                                
                                {this.props.feedback && this.props.feedback.length > 0 ?
                                    this.props.feedback.map((data, index) => (
                                        data.appointment_type === "viewing" ? 
                                            <div className="open_listing_shadow" key={index}> 
                                                <div className = "row">
                                                    <div className="col-md-12">
                                                        <div className="viewingHeader" style={{marginRight: "-18px"}}><span >Viewing</span></div>
                                                    </div>
                                                    <div className="col-md-12 mt15">
                                                        {/* seller agent details */}
                                                        <span className="fontSize16 color0F1D30 fontWeight500 fontInter">
                                                            {data.buyer_name}
                                                        </span>
                                                    
                                                        {/* buyers agent details */}
                                                        {data.buyer_agent_name ?
                                                            <div>
                                                            <span className="fontSize14 color0F1D30 fontWeight500 fontInter">
                                                                Brought by agent {data.buyer_agent_name}
                                                            </span>
                                                            </div>
                                                        : 
                                                            <br/> 
                                                        }
                                                    
                                                        {/* Appointment Date */}
                                                        <span className = "color556378 fontInter fontSize14 fontWeight400">
                                                            <Moment format="MMM D YYYY">{data.appointment_date}</Moment><span>,</span><br/>
                                                            <Moment format="HH:mm">{data.appointment_date}</Moment>
                                                        </span>                                              
                                                    </div>
                                                </div>
                                            
                                                <hr className="openlisting_feedback_separator"/>
                                            
                                                <div className="viewingDiv wrap">  
                                                    {/*Feedback  */}
                                                    <span className="fontSize13 color556378 fontInter">
                                                    {data.has_audio === "1" ?
                                                        <p>
                                                        <audio controls controlsList="nodownload noplaybackrate">
                                                            <source src={ data.feedback } type="audio/mpeg" />
                                                        </audio>
                                                        </p>
                                                    :
                                                        <p className="mt5 padRight10 fontWeight600"> 
                                                        {data.feedback ? 
                                                            data.feedback 
                                                        : 
                                                            Number(data.cancelled) === 1 ?
                                                            <span className="colorEB5757">Appointment Canceled</span>
                                                            :
                                                            new Date(data.appointment_date) > new Date() ?  
                                                            <span className="colorFF9966">Upcoming Appointment</span>
                                                            :
                                                                "No feedback provided"
                                                        } 
                                                        </p>
                                                    }
                                                    </span>
                                                </div>
                                            </div>
                                        : 
                                            <div className="open_listing_shadow" key={index}> 
                                                <div className = "row">
                                                    <div className="col-md-12">
                                                        <div className="generalHeader" style={{marginRight: "-18px"}}><span >General</span></div>
                                                    </div>
                                                    <div className="col-md-12 mt15">
                                                        {/* Meeting With details */}
                                                        {data.meeting_with ? 
                                                            <span style={{display:"block"}} className="fontSize16 color0F1D30 fontWeight500 fontInter">
                                                                {data.meeting_with} 
                                                            </span>
                                                        : null
                                                        }
                                                    
                                                        {/* Appointment Date */}
                                                        <span className = "color556378 fontInter fontSize14 fontWeight400">
                                                            <Moment format="MMM D YYYY">{data.appointment_date}</Moment><span>,</span><br/>
                                                            <Moment format="HH:mm">{data.appointment_date}</Moment>
                                                        </span>                                              
                                                    </div>
                                                </div>
                                            
                                                <hr className="openlisting_feedback_separator"/>
                                            
                                                <div className="viewingDiv wrap">  
                                                    {/*Feedback  */}
                                                    <span className="fontSize13 color556378 fontInter">
                                                    {data.has_audio === "1" ?
                                                        <p>
                                                        <audio controls controlsList="nodownload noplaybackrate">
                                                            <source src={data.audio_name } type="audio/mpeg" />
                                                        </audio>
                                                        </p>
                                                    :
                                                        <p className="mt5 padRight10 fontWeight600"> 
                                                        {data.reason ? 
                                                            data.reason 
                                                        : 
                                                            "No Note provided"
                                                        } 
                                                        </p>
                                                    }
                                                    </span>
                                                </div>
                                            </div>
                                        
                                    ))
                                    : <center><h4>No Viewings to Show</h4></center>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
  
const mapDispatchToProps = dispatch => {
    return {
        reset_stock_pipeline_data : () => dispatch(actions.reset_stock_pipeline_data()),
    }
}
const mapStateToProps = state => {
    return{
        token: state.auth.token,
        property_detail: state.property_modal.property_detail,
        feedback: state.property_modal.feedback,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(OpenListingPropertyModal)