import React from 'react';
import {Line} from 'react-chartjs-2';



class AgentPropertyModalChart extends React.Component {
  render() {

    var labels = this.props.labels;
    
    var viewing_appt = '';
    var general_appt = '';
      
    if(this.props.viewing_appt_cnt !==''){
      viewing_appt = this.props.viewing_appt_cnt.appt_cnt[0][this.props.agent_id];
    }
    if(this.props.general_appt_cnt !==''){
      general_appt = this.props.general_appt_cnt.appt_cnt[0][this.props.agent_id];
    }
    
    const state = {
      labels: labels,
      datasets: [
        {
          label: 'Viewing Appts',
          fill: false,
          lineTension: 0,
          backgroundColor: 'rgba(75,192,192,1)',
          borderColor: '#27AFAF',
          borderWidth: 2,
          data: viewing_appt
        },
        {
          label: 'General Appts',
          fill: false,
          lineTension: 0,
          backgroundColor: 'rgba(45, 62, 84 ,1)',
          borderColor: '#2d3e54',
          borderWidth: 2,
          data: general_appt
        }
      ]
    }

    return (
      <div>
        <Line
          data={state}
          options={{
            legend:{
              display:false,
              position:'right'
            },
            scales: {
              yAxes: [{
                display: true,
                ticks: {
                  beginAtZero: true,
                  userCallback: function(label, index, labels) {
                    if (Math.floor(label) === label) {
                        return label;
                    }
                  },
                }
              }]
            }
          }}
        />
      </div>
    );
  }
}

export default AgentPropertyModalChart;
