import React ,  { Component } from 'react';
import {connect} from 'react-redux';
import * as actions from './store/actions/index';

import modal from './CustomFiles/js/modal';
import './CustomFiles/bower_components/bootstrap/dist/css/bootstrap.min.css';
import './CustomFiles/bower_components/font-awesome/css/font-awesome.min.css';
import './CustomFiles/bower_components/Ionicons/css/ionicons.min.css';
import './CustomFiles/dist/css/AdminLTE.min.css';
import './CustomFiles/plugins/iCheck/square/blue.css';

import './CustomFiles/bower_components/datatables.net-bs/css/dataTables.bootstrap.min.css';
import './CustomFiles/dist/css/skins/_all-skins.min.css';
import './CustomFiles/css/predefined.css';

import Helmet from "react-helmet";
import Moment from 'react-moment';
import Header from './components/menu/Header';
import Footer from './components/Footer';
import Constants from './constants/Constants';

import "react-datepicker/dist/react-datepicker.css";
import Loader from './components/Loader';
import IdleTimerContainer from './components/IdleTimerContainer';
import AddBuyerLead from './components/modals/AddBuyerLeadModal';
import EditBuyerLead from './components/modals/EditBuyerLeadModal';
import TableToExcel from "@linways/table-to-excel";

//icons
import { Icon } from '@iconify/react';
import editIcon from '@iconify/icons-ci/edit';
import filterIcon from '@iconify/icons-ci/filter';
import downloadFill from '@iconify/icons-eva/download-fill';

const $ = require('jquery');
$.DataTable = require('datatables.net');

class BuyerLead extends Component{
    constructor(props){
        super(props);
        this.child = React.createRef();
        this.state ={
            agent_id:null,
            agent_name: "All Contacts",
            agent_image: null,
            agent_mobile_no: null,
            agent_email: null,
            agent_pro: null,
            show_agents: true,
            hash: null,
            page: 1,
            search_buyerLead: "",
            loading: false,
            load_more_loading: false,
            show_filter: false,
            active_filter: 'both',
            tag_filter: 'all'
        }
        this.HandleAgentClick = this.HandleAgentClick.bind(this);
        this.onInputchange = this.onInputchange.bind(this);
        this.renderMoreBuyerLead = this.renderMoreBuyerLead.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleTags = this.handleTags.bind(this);
        this.handleActive = this.handleActive.bind(this);

    }

    componentDidMount (){
        modal();
        const token = localStorage.getItem('token');
        if( token === '' || token === null ){
            localStorage.clear();
            this.props.history.push("/");
            return;
        }
        var agent_id = null;
        var page = 1;
        var filter = "";
        const office_id = localStorage.getItem('office_id');
        this.props.get_buyer_lead(token, agent_id, page, filter, this.state.active_filter, this.state.tag_filter);
        this.props.get_agent_list_appointment(office_id, token);
    }

    HandleSearch(){
        var input, filter, li, a, i, txtValue;
        input = document.getElementById("myInput");
        filter = input.value.toUpperCase();
        li = document.querySelectorAll('[id^="name_"]')

        for (i = 0; i < li.length; i++) {
            a = li[i].getElementsByTagName("span")[0];
            txtValue = a.textContent || a.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                li[i].style.display = "";
            } else {
                li[i].style.display = "none";
            }
        }
    }

    async HandleAgentClick (event){
        let key = event.currentTarget.getAttribute("data-key");
        if(document.querySelector('.agentName_active')){
            document.querySelector('.agentName_active').classList.add('agentName');
            document.querySelector('.agentName_active').classList.remove('agentName_active');
            $('input[name=search_buyerLead').val('');
        }
        event.currentTarget.className = "agentName_active";
        var page = 1;
        var filter = "";
        var agent_id = null;
        const token = localStorage.getItem('token');
        if(event.currentTarget.getAttribute("data-key") === "all"){
            this.setState({
                agent_id: null,
                agent_name: "All Contacts",
                agent_image: null,
                agent_mobile_no: null,
                agent_email: null,
                agent_pro: null,
                show_agents: true,
                hash: null,
                loading: true,
                search_buyerLead: null
            },async()=>{
                this.props.reset_buyer_lead();
                await this.props.get_buyer_lead(token, agent_id, page, filter, this.state.active_filter, this.state.tag_filter);
                this.setState({loading: false});
            })
        }else{
            this.setState({
                agent_id: this.props.agent_list[key].user_id,
                agent_name: this.props.agent_list[key].firstname +" "+this.props.agent_list[key].lastname,
                agent_image: this.props.agent_list[key].image,
                agent_mobile_no: this.props.agent_list[key].mobile_no,
                agent_email:this.props.agent_list[key].email,
                agent_pro:this.props.agent_list[key].pro_user,
                show_agents: false,
                hash: this.props.agent_list[key].hash,
                loading: true,
                search_buyerLead: null
            }, async ()=>{
                this.props.reset_buyer_lead();
                //if(Number(this.state.agent_pro) === 1){
                    await this.props.get_buyer_lead(token, this.state.agent_id, page, filter, this.state.active_filter, this.state.tag_filter);
                //}
                this.setState({loading: false});
            })
        }
    }
  
    HandleAddBuyerLead(){
        window.$("#addBuyerLeadModal").modal('show');
    }

    renderMoreBuyerLead(){
        let newpage = Number(this.state.page) + 1;
        this.setState({page: newpage, load_more_loading: true}, async () =>{
            const token = localStorage.getItem('token');
            var agent_id = this.state.agent_id;
            var filter = this.state.search_buyerLead;
            await this.props.get_buyer_lead(token, agent_id, this.state.page, filter, this.state.active_filter, this.state.tag_filter);
            this.setState({load_more_loading: false})
        })
    }

    onInputchange(event) {
        this.setState({
            [event.target.name]: event.target.value,
            page: 1,
            loading: true
        }, async ()=>{
            const token = localStorage.getItem('token');
            var agent_id = this.state.agent_id;
            var filter = this.state.search_buyerLead;
            await this.props.get_buyer_lead(token, agent_id, this.state.page, filter, this.state.active_filter, this.state.tag_filter);
            this.setState({loading: false})
        });
    }

    getList(){
        var list = this.props.buyer_lead_data.map((data, key) => {
            return (
                <li key={key} id={data.id}>
                    <div className="cold_call_shadow">
                        <div className="row">
                            <div className="col-md-6">
                                <p className="buyerLead_name mt15" style={{paddingLeft:15}} >
                                    {data.contact_firstname} {data.contact_lastname} {data.contact_tag ? <span className='contact_tag_span'>{data.contact_tag}</span> : null}
                                </p>
                            </div>
                            <div className="col-md-6">
                                <div className='col-md-11 mt15'>
                                    {data.contact_modified_date ?
                                        <p className="buyerLead_detail" style={{display:"unset", float:"right"}}><b>Last Edited: </b><Moment format="MMM D YYYY, HH:mm">{data.contact_modified_date}</Moment> </p> 
                                        : null
                                    }
                                </div>
                                <div className='col-md-1 mt15'>
                                    <button ref='edit' type="button" id={data.id} className="btn btn-info fontWeight500" style={{display:"unset", float:"right", backgroundColor: "#1F2F44", border: "1px solid #1F2F44", verticalAlign: 'bottom', padding: "0px 5px 0px 5px" }} onClick={() => this.handleEdit(data.id, data.agent_id)}> <Icon icon={editIcon} style={{verticalAlign: "middle"}}/> </button>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <p className="buyerLead_detail mt10" style={{paddingLeft:15, display:'inline', margin: "0px 0px 10px"}}>
                                <strong>
                                    {data.contact_email}
                                    {data.contact_email && data.contact_mobile_no ? 
                                        <span style={{verticalAlign: "bottom", marginLeft: "2px", marginRight: "2px"}}>•</span> 
                                        : null
                                    }
                                    {data.contact_mobile_no ?    
                                    <>
                                        ({data.contact_mobile_no})
                                    </>
                                    :
                                        null
                                    }
                                </strong>
                                </p>

                                <p className="buyerLead_property" style={{float:'right', paddingRight:15, margin: "5px 0px 10px 0px"}}>{Number(data.contact_is_active) === Number(1) ? <span className="color27AFAF" style={{fontWeight: 600}}>Active</span> : <span className="colorEA676A" style={{fontWeight: 600}}>Not Active</span> }</p>
                            </div>
                            <div className="col-md-12" style={{marginTop:"2px"}}>
                                <p className="buyerLead_property" style={{float:'left', paddingLeft:15, margin: "0px 0px 10px"}}>{data.contact_address}</p>
                            </div>
                            
                        </div>
                        <div className="row">
                            <div className='col-md-12'>
                                <div className="row">
                                    <div className="col-md-8">
                                        <p className="buyerLead_detail" style={{float:'left', paddingLeft:15}}><b>Added on: </b><Moment format="MMM D YYYY, HH:mm">{data.contact_date_created}</Moment> </p>
                                    </div>
                                    {this.state.agent_name === "All Contacts"?
                                        <div className="col-md-4">
                                            <p className="buyerLead_property" style={{float:'right', paddingRight:15, margin: "0px 0px 10px", wordBreak:"break-word"}}>Assigned to <strong>{data.agent_name}</strong></p>
                                        </div>
                                    :null}
                                </div>
                            </div>
                        </div>
                        <hr style={{margin:"5px 0px 5px"}}/>
                        <div className='row'>
                            <div className="col-md-12">
                                <p className="buyerLead_remarks" style={{float:'left', margin: "0px 0px 10px"}}>
                                    {Number(data.contact_has_audio) === Number(1) ?
                                        <audio controls controlsList="nodownload noplaybackrate">
                                            <source src={data.contact_audio_path} type="audio/mpeg" />
                                        </audio>
                                    : 
                                        data.contact_note ? 
                                            data.contact_note :
                                        <strong>N/A</strong>
                                    }
                                </p> 
                            </div>
                        </div>
                    </div>
                </li>
            );
        })
        return list;
    }

    handleEdit = async (lead_id, agent_id) => { 
        const token = localStorage.getItem('token');
        await this.props.get_user_buyer_lead(token, lead_id, agent_id);
        this.child.setTag();
        window.$("#editBuyerLeadModal").modal('show');
    }

    showFilter = () => {
        if(this.state.show_filter){
            this.setState({show_filter: false})
        }else{
            this.setState({show_filter: true})
        }
    }

    handleActive = (event) => {
        this.setState({loading: true})
        this.setState({
            active_filter: event.target.value,
            page: 1,
        },async () => {
            const token = localStorage.getItem('token');
            var agent_id = this.state.agent_id;
            var filter = this.state.search_buyerLead;
            await this.props.get_buyer_lead(token, agent_id, this.state.page, filter, this.state.active_filter, this.state.tag_filter);
            this.setState({loading: false})
        });

    }
    
    handleTags = (event) => {
        this.setState({loading: true});
        this.setState({
            tag_filter: event.target.value,
            page: 1,
        },async ()=>{
            const token = localStorage.getItem('token');
            var agent_id = this.state.agent_id;
            var filter = this.state.search_buyerLead;
            await this.props.get_buyer_lead(token, agent_id, this.state.page, filter, this.state.active_filter, this.state.tag_filter);
            this.setState({loading: false})
        })
    }
    
    handleDownloadReport = () => {
        let table1 = document.getElementById("table-to-xls");
        //let table2 = document.getElementById("table-to-xls-property-notes");
        let book = TableToExcel.tableToBook(table1, {sheet:{name:"Contacts Report"}});
        //TableToExcel.tableToSheet(book, table2, {sheet:{name:"Property Notes Report"}});
        TableToExcel.save(book, "Contacts Report.xlsx");
    }

    render(){
        let footer= "footer_color";
        if (window.innerWidth > document.body.clientWidth) {
            footer = 'footer';
        }
        return(<div style={{fontSize:'14px', height: "100%"}}>
            <IdleTimerContainer></IdleTimerContainer>
            <Helmet>
                <title>Roundr - Contacts</title>
                <link rel="icon" href="https://roundr.app/wp-content/uploads/2018/03/favicon.png" type="image/png"></link>
            </Helmet>
            <Header active = "buyerlead"/>

            <div className="content-wrapper" style={{marginLeft:'0px', height: "90%", position:"relative", zIndex:0}}>
                <section className="content" style={{height: "100%", margin:0, padding:0}}>
                
                    <div id="main" style={{padding:"0px", marginLeft:"235px", marginBottom:"30px"}}>
                        <div id="mySidebar" className="sidebar" style={{width: "300px"}}>
                            <ul id="myUL">
                                <li className="inactive">
                                    <h1 style={{fontSize:"22px"}}>Contacts</h1>
                                </li>
                                <li className="agentName_active" data-key="all" onClick={this.HandleAgentClick}>
                                    <h3 style={{fontSize:"18px"}}>All Contacts</h3>
                                </li>
                                <li className="inactive">
                                    <h1 style={{fontSize:"22px"}}>Agents</h1>
                                </li>
                                <li className="inactive_search" style={{padding:"8px 8px 8px 2px"}}>
                                    <center><input type="text" id="myInput" style={{width:"75%"}} onChange={this.HandleSearch} placeholder="Search Agent" title="Type in a name"/></center>
                                </li>
                                {this.props.agent_list ?
                                    this.props.agent_list.map((data,key)=>(
                                        <li className="agentName" id={"name_"+key} key={key} data-key={key} onClick={this.HandleAgentClick}>
                                            {data.image ? 
                                                <img alt="" src={Constants.IMAGE_URL + data.image} className="img-circle" width="24" height="24" style={{marginRight:"10px", display:'inline-block'}} />
                                            :
                                                <img alt="" src="user-images/userDefault.png" className="img-circle" width="24" height="24" style={{marginRight:"10px", display:'inline-block'}}/>
                                            }
                                            <span>{data.firstname}&nbsp;{data.lastname} {Number(data.pro_user) === 1 ? <span className="span_pro">Pro</span> : null}</span>
                                        </li>
                                    ))
                                : 
                                    <h3 style={{fontSize:"18px", color:"white", textAlign:"center"}}>No Agents to Display</h3>
                                }
                            </ul>
                            {this.props.buyer_lead_export ?
                                <div style={{display:"none"}} dangerouslySetInnerHTML={{ __html: this.props.buyer_lead_export}}></div> 
                                :
                                null
                            }
                        </div>
                        <button className="openbtn" id="openNav" style={{backgroundColor: "#0F1D30", color:"#ffff"}}>☰</button> 
                        
                        <div id="main_content" style={{marginLeft:"60px"}}>    
                            <div className="buyerLead_header">
                                <div className="row">
                                    <div className="col-md-9" style={{marginTop:"20px"}}>
                                            {this.state.agent_name === "All Contacts" ? 
                                                <span className="fontBold fontRoboto fontSize25" style={{marginLeft:"35px"}}>
                                                    {this.state.agent_name}
                                                </span>
                                            :
                                                <div className="row">
                                                    <div className="col-md-1" style={{marginLeft:"35px"}}>
                                                        {this.state.agent_image ? 
                                                            <img alt="" src={Constants.IMAGE_URL + this.state.agent_image} className="img-circle" width="64" height="64" />
                                                        :
                                                            <img alt="" src="user-images/userDefault.png" className="img-circle" width="64" height="64" />
                                                        }
                                                    </div>
                                                    <div className="col-md-10" style={{marginTop:"15px", marginLeft:"20px"}}>
                                                        <span className="fontBold fontRoboto fontSize25" >
                                                            {this.state.agent_name}
                                                        </span>
                                                        <span className="fontRoboto fontSize13 color8591A0" style={{display: 'block'}}> {this.state.agent_email} <span style={{verticalAlign: "bottom", marginLeft: "2px", marginRight: "2px"}}>•</span> {this.state.agent_mobile_no}</span>
                                                    </div>
                                                </div>
                                            }
                                    </div>
                                    <div className="col-md-2 col-md-offset-1" style={{marginTop:"20px"}}>
                                        {/* {(Number(this.state.agent_pro) === 1) || (this.state.agent_name === "All Contacts") ? */}
                                            <button type="button" style={{backgroundColor:'#1B375A', borderRadius: "5px", fontSize: "14px", padding:"10px 15px 10px 15px", border: "0px"}} className="btn btn-info" onClick={this.HandleAddBuyerLead}>Add New Contact</button>
                                        {/* : null 
                                        } */}
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-offset-1 col-md-8" style={{marginTop:"20px"}}>
                                    <center>
                                        <input type="text" id="search_buyerLead" name="search_buyerLead" style={{width:"100%", display:"inline"}} placeholder="Search contact name, email or mobile no." onChange={this.onInputchange}/>
                                    </center>
                                </div>
                                <div className="col-md-1 col-offset-md-1" style={{marginTop:"20px"}}>
                                    <button ref='edit' type="button" id="filter" className="btn btn-info fontWeight500 mr15" style={{float:'right', backgroundColor: "#1F2F44", border: "1px solid #1F2F44", verticalAlign: 'bottom', padding: "5px 10px"}} onClick={() => this.showFilter()}> <Icon icon={filterIcon} height={30} style={{verticalAlign: "middle"}}/> </button>
                                </div>
                                <div className="col-md-1 col-offset-md-1" style={{marginTop:"20px"}}>
                                    <button ref='edit' type="button" id="filter" className="btn btn-info fontWeight500 mr15" style={{float:'right', backgroundColor: "#1F2F44", border: "1px solid #1F2F44", verticalAlign: 'bottom', padding: "5px 10px"}} onClick={() => this.handleDownloadReport()}> <Icon icon={downloadFill} height={30} style={{verticalAlign: "middle"}}/> </button>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-md-offset-1 col-md-9 col-offset-md-2" style={this.state.show_filter ? {display:'block'} : {display:'none'}}>
                                    <div style={{ border: "1px solid #1B375A", borderRadius: 5}}>
                                        <div className='full-input' style={{borderBottom: "none"}}>
                                            <input className="btn_filter_active" type="radio" id="forActive"    name="filter_active" onClick={this.handleActive} value="1"/><label htmlFor="forActive" className="btn_filter_active">Active</label>
                                            <input className="btn_filter_active" type="radio" id="forNotActive" name="filter_active" onClick={this.handleActive} value="0"/><label htmlFor="forNotActive" className="btn_filter_active">Not Active</label>
                                            <input className="btn_filter_active" type="radio" id="forBoth"      name="filter_active" onClick={this.handleActive} value="both" defaultChecked={true}/><label htmlFor="forBoth" className="btn_filter_active">Both</label>
                                        </div>
                                        <div className='full-input' style={{borderBottom: "none"}}>
                                            <input className="btn_filter_tags" type="radio" id="forBuyer_tag"    name="filter_tags" onClick={this.handleTags} value="buyer"/><label htmlFor="forBuyer_tag" className="btn_filter_tags">Buyer</label>
                                            <input className="btn_filter_tags" type="radio" id="forSeller_tag"   name="filter_tags" onClick={this.handleTags} value="seller"/><label htmlFor="forSeller_tag" className="btn_filter_tags">Seller</label>
                                            <input className="btn_filter_tags" type="radio" id="forTenant_tag"   name="filter_tags" onClick={this.handleTags} value="tenant"/><label htmlFor="forTenant_tag" className="btn_filter_tags">Tenant</label>
                                            <input className="btn_filter_tags" type="radio" id="forLandlord_tag" name="filter_tags" onClick={this.handleTags} value="landLord"/><label htmlFor="forLandlord_tag" className="btn_filter_tags">Landlord</label>
                                            <input className="btn_filter_tags" type="radio" id="forAll_tag"      name="filter_tags" onClick={this.handleTags} value="all" defaultChecked={true}/><label htmlFor="forAll_tag" className="btn_filter_tags">All</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            
                            <div className="row" style={{ minHeight:"100vh"}}>
                                <div className="col-md-offset-1 col-md-10" >
                                    <ul id="myUL">
                                    {!this.state.loading ?
                                        this.props.buyer_lead_data  &&  this.props.buyer_lead_data.length > 0 ? 
                                            <>
                                            {this.getList()}
                                            {this.props.buyer_lead_load_more ? 
                                                <center>
                                                    {!this.state.load_more_loading ?
                                                        <button type="button" className="btn btn-info fontWeight700" style={{backgroundColor: "#5FC8BF", marginTop: 20}} onClick={this.renderMoreBuyerLead}>Load More</button>
                                                    :   <> <br/> <Loader/> <br/> </>}
                                                </center>
                                            : null
                                            }
                                            <br/><br/><br/><br/>
                                        </>
                                        :
                                        // Number(this.state.agent_pro) === 1 || this.state.agent_name === "All Contacts" ?
                                            <div className="center-screen">
                                                <h3> No Contacts to show</h3>
                                            </div>
                                        // :
                                        //     <div className="center-screen">
                                        //         <h3> {this.state.agent_name} is not a Pro User.</h3>
                                        //         <h3 style={{marginTop:"0px"}}> Please Subscribe to PRO to use this feature</h3>
                                        //     </div>
                                        
                                    :  <> <br/> <Loader/> <br/> </> }
                                    </ul>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                             
                </section>

            
            </div>
            <AddBuyerLead
                //agent_data = {this.props.agent_data}
                show_agents = {this.state.show_agents}
                agent_id = {this.state.agent_id}
                agent_list={this.props.agent_list}
                hash = {this.state.hash}
                filter = {this.state.search_buyerLead}
                active_filter = {this.state.active_filter}
                tag_filter = {this.state.tag_filter}
            />
            {this.props.user_buyer_lead_data ? 
                <EditBuyerLead
                    agent_id = {this.state.agent_id}
                    filter = {this.state.search_buyerLead}
                    tag = {this.props.user_buyer_lead_data.contact_tag }
                    active_filter = {this.state.active_filter}
                    tag_filter = {this.state.tag_filter}
                    onRef={ref => (this.child = ref)}
                />
                : null
            }
            <Footer footer = {footer}></Footer>
        </div>)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        get_agent_list_appointment : (office_id, token) => dispatch(actions.get_agent_list_appointment(office_id, token)),
        get_buyer_lead : (token, agent_id, page, filter, active_filter, tag_filter) => dispatch(actions.get_buyer_lead(token, agent_id, page, filter, active_filter, tag_filter)),
        reset_buyer_lead : () => dispatch(actions.reset_buyer_lead()),
        get_user_buyer_lead: (token, lead_id, agent_id) => dispatch(actions.get_user_buyer_lead(token, lead_id, agent_id))
    };
}

const mapStateToProps = state => {
  return{
    token: state.auth.token,
    agent_list: state.appointment.agent_list_appointment,
    buyer_lead_data: state.buyer_lead.buyer_lead_data,
    buyer_lead_load_more: state.buyer_lead.buyer_lead_load_more,
    user_buyer_lead_data: state.buyer_lead.user_buyer_lead_data,
    buyer_lead_export: state.buyer_lead.buyer_lead_export
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuyerLead);
