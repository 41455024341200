import React,{ Component } from "react";
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import Loader from '../../Loader';
import TableToExcel from "@linways/table-to-excel";
//icons
import { Icon } from '@iconify/react';
//import clockHistory from '@iconify/icons-bi/clock-history';
import chatRightTextFill from '@iconify/icons-bi/chat-right-text-fill';
import downloadFill from '@iconify/icons-eva/download-fill';

const from_archiveProperty=1
class ArchiveProperty extends Component{
    constructor(props) {
        super(props);
        this.state = {
            stock_pipeline_page: 1,
            property_page: 1,
            startDate: new Date(new Date().getFullYear(), 0, 1),
            endDate:   new Date(new Date()),
            CalenderMinDate: new Date(new Date().getFullYear(), 0, 1),
            CalenderMaxDate: new Date(new Date()),
            todayDate: new Date()
        }
    }
    renderStockPipeline_modal = async (event)=>{
        // let page = '';
        // let is_archived = '1';
        const token = localStorage.getItem('token');
        const from_archiveStockPipeline = 1
        //await this.props.get_stock_pipeline_data(page, is_archived, event.currentTarget.id, this.props.authtoken)
        await this.props.get_stock_pipeline_property_data(event.currentTarget.id, this.props.agent_id, token, from_archiveStockPipeline)
        window.$("#stockpipeline_modal").modal('show');
    }
    
    renderMoreStockPipeline = () =>{
        let newpage = Number(this.state.stock_pipeline_page) + 1;
        this.setState({stock_pipeline_page: newpage}, () =>{
            this.props.get_stock_pipeline_data(this.state.stock_pipeline_page, null, this.props.authtoken);
        })
    }

    renderMoreArchiveProperty = async () =>{
        let newpage = Number(this.state.property_page) + 1;
        this.setState({property_page: newpage}, async () =>{
            let token = localStorage.getItem('token');
            await this.props.get_auth_data(this.props.agent_id,token);
            let is_archived = 1;
            let type = 'agent';
            await this.props.get_archived_property_data(this.state.property_page, is_archived, type, this.props.authtoken);
            //let stock_pipeline_id = ''
            //await this.props.get_stock_pipeline_data(page, is_archived, stock_pipeline_id, this.props.authtoken);
        })
    }

    retrieveStockPipelineByDate  = (fromDate,toDate) =>{
        fromDate = this.changeDateFormat(fromDate);
        toDate = this.changeDateFormat(toDate);
        const token = localStorage.getItem('token');
        const agent_id = this.props.agent_id;
        this.props.get_stockpipeline_chart_by_id(fromDate, toDate, agent_id, token);
    }
    changeDateFormat(fromDate) {
        var d = new Date(fromDate),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
    
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [year, month, day].join('-');
    }

    handleDownloadReport = async (prop_id, date_sold) => {
        /* Creating Date From 1st of Year to Last Of Year */
        this.props.reset_property_modal();
        var fromDate = new Date("1/1/" + (new Date()).getFullYear());
        var toDate = new Date(new Date().getFullYear(), 11, 31, 0, 0, 0);
    
        var d = new Date(fromDate),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
      
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        fromDate = [year, month, day].join('-');
    
        d = new Date(toDate);
        month = '' + (d.getMonth() + 1);
        day = '' + d.getDate();
        year = d.getFullYear();
      
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
      
        toDate = [year, month, day].join('-');
        var agent_id = this.props.agent_id;
        const token = localStorage.getItem('token');
        await this.props.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold);
        
        let table1 = document.getElementById("table-to-xls");
        let table2 = document.getElementById("table-to-xls-property-notes");
        let book = TableToExcel.tableToBook(table1, {sheet:{name:"Viewing Report"}});
        TableToExcel.tableToSheet(book, table2, {sheet:{name:"Property Notes Report"}});
        TableToExcel.save(book, "Property Report.xlsx");
    }

    render(){ 
        return(
            <div className = "archive-prop" style={{minHeight:550}}>
                
                {/* Property For sale and Rent Tab */}
                <ul className="nav nav-tabs">

                    <li style={{ width: '50%' }} className="active fontInter archive_properties_tab">
                      <a data-toggle="tab" id="saletab" href="#archive_properties_tab">
                        Properties
                        <br />
                        {this.props.archived_property ?
                            <span className="fontSize12 fontWeightNormal fontInter"><b>{this.props.archived_property.length} &nbsp; {this.props.archived_property.length > 1 ? "Properties" : "Property" }</b></span>
                        : null
                        }
                      </a>
                    </li>
                     
                    <li style={{ width: '50%' }} className="fontInter archive_stockpipeline_tab">
                      <a data-toggle="tab" href="#archive_stockpipeline_tab">Stock Pipeline
                        <br />
                        {this.props.stock_pipeline ?
                            <span className="fontSize12 fontWeightNormal fontInter"><b>{this.props.stock_pipeline.length} &nbsp; {this.props.stock_pipeline.length > 1 ? "Properties" : "Property" }</b></span>
                            : null
                        }
                        </a>
                    </li>
                </ul>
                
                <div className="tab-content" style={{ marginTop: '40px', overflow: 'auto' }}>
                
                    {Array.isArray(this.props.archived_property) && this.props.archived_property.length ?
                        <div id="archive_properties_tab" className="tab-pane fade in active">
                            {this.props.archived_property.map((data) => (
                                <div key={data.id}>
                                    <div className="row" style={{marginLeft: "0px",marginRight: "0px"}}>
                                        <div className="col-md-9 cursorPointer" onClick={ ()=>this.props.handlePropertyModal(data.id, null, null,from_archiveProperty) }>
                                            <div className="property-text fontSize14 fontInter" style={{color: '#12171D' }}>
                                                {data.address}
                                                <div className="fontWeight700" style={{color: '#12171D' }}> {data.unit_name} 
                                                    {data.date_sold ?
                                                        <span className="soldspan fontWeight400 ml5" style={{paddingTop: "1px"}}>{Number(data.property_type) === 0 ? "Rented" : "Sold"}</span>
                                                        :
                                                        null
                                                    }
                                                </div>
                                                
                                                {Number(data.property_type) === Number(1) ? 
                                                    <>
                                                    {/* for sale */}
                                                    <div className="fontSize14 fontInter">
                                                        <span className="mr10">
                                                            {data.isSellerActive === "Seller not linked" || data.isSellerActive === "Seller link pending" ?
                                                                // <>
                                                                //     <Icon icon={clockHistory} style={{color:'#FF9966', verticalAlign:"middle" }} />
                                                                //     <span style={{color:'#FF9966'}} className="fontSize11 fontWeight600 fontInter ml5" >Seller Pending</span>
                                                                // </>
                                                                null
                                                                :
                                                                <>
                                                                    <img className="img-circle" src=" images/right-sign.png " width="13.33" height="13.33" alt="" />
                                                                    <span className="color4AA49C fontSize11 fontWeight600 fontInter ml5" >Seller Linked</span>
                                                                </>
                                                            }
                                                        </span>
                                                        {Number(data.is_seller_occupant) === Number(0) ?
                                                            <span>
                                                                {data.isOccupantActive === "Occupant not linked" || data.isOccupantActive === "Occupant link Pending" ?
                                                                // <>
                                                                //     <Icon icon={clockHistory} style={{color:'#FF9966', verticalAlign:"middle" }} />
                                                                //     <span style={{color:'#FF9966'}} className="fontSize11 fontWeight600 fontInter ml5" >Occupant Pending</span>
                                                                // </> 
                                                                null
                                                                :
                                                                <>
                                                                    <img className="img-circle" src=" images/right-sign.png " width="13.33" height="13.33" alt="" />
                                                                    <span className="color4AA49C fontSize11 fontWeight600 fontInter ml5" >Occupant Linked</span>
                                                                </>
                                                                }
                                                            </span>
                                                        : null}
                                                    </div>
                                                    </>
                                                :
                                                    <>
                                                    {/* for rent */}
                                                    <div className="fontSize14 fontInter">
                                                        <span className="mr10">
                                                            {data.isSellerActive === "Seller not linked" || data.isSellerActive === "Seller link pending" ?
                                                            // <>
                                                            //     <Icon icon={clockHistory} style={{color:'#FF9966', verticalAlign:"middle" }} />
                                                            //     <span style={{color:'#FF9966'}} className="fontSize11 fontWeight600 fontInter ml5" > {Number(data.is_seller_occupant) === Number(0) ? "Owner Pending" : "Seller Pending"} </span>
                                                            // </>
                                                            null
                                                            :
                                                            <>
                                                                <img className="img-circle" src=" images/right-sign.png " width="13.33" height="13.33" alt="" />
                                                                <span className="color4AA49C fontSize11 fontWeight600 fontInter ml5" > {Number(data.is_seller_occupant) === Number(0) ? "Owner Linked" : "Seller Linked"} </span>
                                                            </>
                                                            }
                                                        </span>
                                                        {Number(data.is_seller_occupant) === Number(0) ?
                                                            <span>
                                                               {data.isOccupantActive === "Occupant not linked" || data.isOccupantActive === "Occupant link Pending" ?
                                                                // <>
                                                                //     <Icon icon={clockHistory} style={{color:'#FF9966', verticalAlign:"middle" }} />
                                                                //     <span style={{color:'#FF9966'}} className="fontSize11 fontWeight600 fontInter ml5" >Occupant Pending</span>
                                                                // </>
                                                                null
                                                                :
                                                                <>
                                                                    <img className="img-circle" src=" images/right-sign.png " width="13.33" height="13.33" alt="" />
                                                                    <span className="color4AA49C fontSize11 fontWeight600 fontInter ml5" >Occupant Linked</span>
                                                                </>
                                                                }
                                                            </span>
                                                        : null}
                                                    </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="fontInter floatRight mb5">
                                                <span class="fontRoboto fontBold" style={{float:'right'}}>{data.country_currency_symbol}{data.asking_price}</span>
                                                <span style={{display: 'block'}}>
                                                    <button className="btn btn-link fontBold" style={{float:'right'}} onClick={ ()=>this.handleDownloadReport(data.id)}>
                                                        <Icon icon={downloadFill} height='30' width='30' style={{color: '#5CB6AE'}} />
                                                    </button>
                                                </span>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <hr style={{marginTop:"15px", marginBottom:"20px"}}/>
                                </div>
                            )) }
                            {this.props.archive_property_load_more ?
                            <center>
                                <button type="button" class="btn btn-info fontWeight700" style={{backgroundColor: "#5FC8BF", marginTop: 20}} onClick={this.renderMoreArchiveProperty}>Load More</button>
                            </center>
                            : null}

                            {/* Table for Export */}
                            {this.props.html_export ?
                                <div style={{display:"none"}} dangerouslySetInnerHTML={{ __html: this.props.html_export }}></div> 
                                :
                                null
                            }

                            {this.props.property_notes_html ?
                                <div style={{display:"none"}} dangerouslySetInnerHTML={{ __html: this.props.property_notes_html }}></div> 
                                : null
                            }

                            {this.props.office_notes_html ?
                                <div style={{display:"none"}} dangerouslySetInnerHTML={{ __html: this.props.office_notes_html }}></div> 
                                : null
                            }
                            
                        </div> /* For Sale Tab Ends Here */
                    : 
                        <div id="archive_properties_tab" className="tab-pane fade in active">
                            <center><br/><br/><h4>No Properties to Show</h4></center>
                        </div>
                    }
                        
                  
                    {/* Second Tab For Archive Stockpipeline Starts  */}
                    <div id="archive_stockpipeline_tab" className="tab-pane fade">
                        {this.props.stock_pipeline ?
                            this.props.stock_pipeline.map((data) => (
                                <div key={data.id}>
                                    <div key={data.id} id={data.id} className='row padTop15 padBottom15' style={{cursor:'pointer', marginLeft: "0px", marginRight: "0px"}} onClick={this.renderStockPipeline_modal}>
                                        <div className="col-md-10">
                                            <p className="cold_call_header_date" style={{float:'left', paddingLeft:15}}>{data.address}</p> 

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <span className="cold_call_header" style={{float:'left', paddingLeft:15}}>{data.unit_no}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <span style={{verticalAlign: 'middle'}}>
                                                <Icon icon={chatRightTextFill} style={{color: '#4eb3aa', fontSize: '20px', verticalAlign: 'middle'}} />
                                                <span className="color738196 fontSize12 ml5 mt5 fontInter">{data.notes_cnt}</span>
                                            </span>
                                        </div>
                                    </div>
                                    <hr style={{margin:"0px"}}/>
                                </div>
                            ))
                        : this.props.loader ?
                        <Loader/> : null
                    }
                    
                    {Array.isArray(this.props.stock_pipeline) && this.props.stock_pipeline.length ?
                        this.props.stock_load_more ?
                            <center>
                                <button type="button" class="btn btn-info fontWeight700" style={{backgroundColor: "#5FC8BF", marginTop: 20}} onClick={this.renderStockPipeline_modal}>Load More</button>
                            </center>
                            : null
                        :   
                            <center><br/><br/><h4>No Stock Pipeline Properties to Show</h4></center>
                    }
                    </div>
                </div>
                
                
                
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        get_stock_pipeline_data : (page, is_archived, stock_pipeline_id, token) => dispatch(actions.get_stock_pipeline_data(page, is_archived, stock_pipeline_id, token)),
        get_stock_pipeline_property_data : (stock_pipeline_id, agent_id, token, from_archiveStockPipeline) => dispatch(actions.get_stock_pipeline_property_data(stock_pipeline_id, agent_id, token, from_archiveStockPipeline)),
        get_archived_property_data: (page, is_archived, type, token) => dispatch(actions.get_archived_property_data(page, is_archived, type, token)),
        get_auth_data : (agent_id,token) => dispatch(actions.get_auth_data(agent_id,token)),

        reset_property_modal: () => dispatch(actions.reset_property_modal()),
        get_property_data_modal: (fromDate, toDate, prop_id, agent_id, token, date_sold) => dispatch(actions.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold)),
    }
}
  
const mapStateToProps = state => {
    return{
        //property tab
        archived_property: state.agent_modal.archived_property,
        archive_property_load_more: state.agent_modal.archive_property_load_more,
        archive_property_page: state.agent_modal.archive_property_page,
        html_export :state.property_modal.html_export,
        property_notes_html: state.property_modal.property_notes_html,
        office_notes_html: state.property_modal.office_notes_html,

        //stockpipline tab
        stock_pipeline: state.agent_modal.stock_pipeline,
        stock_load_more: state.agent_modal.stock_load_more,
        //agent mobile authtoken
        authtoken: state.add_property_modal.authtoken,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArchiveProperty)