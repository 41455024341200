import * as actionTypes from './actionsTypes';
import Constants from '../../constants/Constants';

var myHeaders = '';
var raw = '';
var requestOptions = '';
var url = '';

export const get_property_data_modal = (fromDate, toDate, prop_id, agent_id, token, date_sold, from_networking, property_notes_user_id, from_archiveProperty) => {
    return async dispatch =>{
        url = Constants.PROPERTY_DETAIL;
        myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token);
        if(Number(from_networking) === 1){
            raw = JSON.stringify(
            {
                "token": token,
                "agent_id": agent_id,
                "prop_id": prop_id,
                "property_notes_user_id": property_notes_user_id,
                "from": fromDate,
                "to": toDate,
            });
        }else{
            raw = JSON.stringify(
            {
                "token": token,
                "agent_id": agent_id,
                "prop_id": prop_id,
                "from": fromDate,
                "to": toDate
            });
        }
        requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
        await fetch(url, requestOptions)
        .then(response => response.json())
        .then(data => {
            dispatch(property_data_modal(data, date_sold, from_networking, from_archiveProperty));
        })
    }
}
export const property_data_modal = (data, date_sold, from_networking, from_archiveProperty) => {
    var office_text = [];
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    if(data.office_notes.length > 0){
        for(let i = 0; i < data.office_notes.length; i++){
            let d = new Date(data.office_notes[i].created_date);
            let date = d.getDate()+" "+monthNames[d.getMonth()]+" "+d.getFullYear();

            if (office_text.hasOwnProperty(date)) {
                office_text[date].push(data.office_notes[i])
            }else{
                office_text[date] = []
                office_text[date].push(data.office_notes[i])
            }
        }
    }
    
    return{
        type: actionTypes.GET_PROPERTY_DATA_MODAL,
        property_agent_data: data.agent_data,
        property_detail: data.property_detail,
        appointment_cnt: data.appointment_cnt,
        general_cnt: data.general_appointment_cnt,
        feedback: data.feedback,
        seller_message: data.feedback.seller_message,
        occupant_message: data.feedback.occupant_message,
        html_export: data.html_export,
        property_notes_html: data.property_notes_html,
        property_notes: data.property_notes,
        office_notes: data.office_notes,
        office_text: office_text,
        office_notes_html: data.office_notes_html,
        date_sold: date_sold,
        from_networking: from_networking,
        from_archiveProperty: from_archiveProperty
    }
}

export const reset_property_modal = ()=>{
    return {
        type: actionTypes.RESET_PROPERTY_MODAL
    }
}

export const submitFail = (error) => {
    return {
        type: actionTypes.MARK_AS_SOLD_SUBMIT_FAIL,
        mark_as_sold_form_error: error.msg
    };
}

export const add_mark_as_sold = (token, property_id, sold_price, commission, transfer_date) =>{
    return async dispatch =>{
        let error=[];
        if((!Number(sold_price)) || (!Number(commission)) || (!transfer_date)){
            if(!Number(sold_price)){ 
                error.msg = "Please Enter Selling Price";
                dispatch(submitFail(error));
            }else if(!Number(commission)){
                error.msg = "Please Enter Commission%";
                dispatch(submitFail(error));
            }else if(transfer_date === null){
                error.msg = "Please Select Transfer Date";
                dispatch(submitFail(error));
            }
        }else if(sold_price || commission || transfer_date){
            myHeaders = new Headers();
            myHeaders.append("authtoken", token);
            
            raw = JSON.stringify(
            {
                "property_id": property_id,
                "sold_price": sold_price,
                "commission": commission,
                "tansfer_date": transfer_date,
            });

            requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            error.msg = "";
            dispatch(submitFail(error));
            await fetch(Constants.MARK_AS_SOLD, requestOptions)
            .then(response => response.json())
            .then(data => {
                dispatch(mark_as_sold());
            })
            .catch(error => console.log('error', error));
        }
    }
}
export const mark_as_sold = ()=>{ 
    return {
        type: actionTypes.ADD_MARK_AS_SOLD,
        
    }
}

export const add_mark_as_rent = (token, property_id, commission, commission_type, rental_price, start_date) =>{
    return async dispatch =>{
        let error=[];
        if((!Number(commission)) || (commission_type === null) || (!Number(rental_price)) || (!start_date)){
            if(!Number(rental_price)){ 
                error.msg = "Please Enter Rental Price";
                dispatch(submitFail(error));
            }else if(commission_type === null){
                error.msg = "Please Select Commission Type";
                dispatch(submitFail(error));
            }else if(!Number(commission)){
                error.msg = "Please Enter Commission";
                dispatch(submitFail(error));
            }else if(start_date === null){
                error.msg = "Please Select Lease Start Date";
                dispatch(submitFail(error));
            }
        }else{
            myHeaders = new Headers();
            myHeaders.append("authtoken", token);
            
            raw = JSON.stringify(
            {
                "property_id": property_id,
                "commission": commission,
                "commission_type": commission_type,
                "rental_price": rental_price,
                "start_date": start_date,
            });

            requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            error.msg = "";
            dispatch(submitFail(error));
            await fetch(Constants.MARK_AS_RENT, requestOptions)
            .then(response => response.json())
            .then(data => {
                dispatch(mark_as_rent());
            })
            .catch(error => console.log('error', error));
        }
    }
}
export const mark_as_rent = ()=>{
    return{
        type: actionTypes.ADD_MARK_AS_RENT,
    }
}

export const add_update_rent  = (token, property_id, rental_price, type, rental_payment_id, commission, lease_end_date) =>{
    return async dispatch =>{
        let error=[];
        if((!Number(rental_price)) || (type === null) || (!Number(commission)) || (!lease_end_date)){
            if(!Number(rental_price)){ 
                error.msg = "Please Enter Rental Price";
                dispatch(submitFail(error));
            }else if(type === null){
                error.msg = "Please Select Stop / Update";
                dispatch(submitFail(error));
            }else if(!Number(commission)){
                error.msg = "Please Enter Commission";
                dispatch(submitFail(error));
            }else if(lease_end_date === null){
                error.msg = "Please Select Lease Update/End Date";
                dispatch(submitFail(error));
            }
        }else{
            myHeaders = new Headers();
            myHeaders.append("authtoken", token);
            
            raw = JSON.stringify(
            {
                "property_id": property_id,
                "rental_price": rental_price,
                "type": type,
                "rental_payment_id": null,
                "commission": commission,
                "lease_end_date": lease_end_date
            });

            requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            error.msg = "";
            dispatch(submitFail(error));
            await fetch(Constants.UPDATE_RENT, requestOptions)
            .then(response => response.json())
            .then(data => {
                dispatch(update_rent());
            })
            .catch(error => console.log('error', error));
        }
    }
}
export const update_rent = ()=>{
    return{
        type: actionTypes.ADD_UPDATE_RENT,
    }
}
export const reset_mark_as_sold_modal = ()=>{
    return{
        type: actionTypes.RESET_MARK_AS_SOLD_MODAL
    }
} 
