import React,{ Component } from "react";
import {connect} from 'react-redux';
import * as actions from './store/actions/index';
import Loader from './components/Loader';
import Helmet from "react-helmet";

import Header from './components/menu/Header';
import Footer from './components/Footer';
import Swal from "sweetalert2";  
import Moment from 'react-moment';
//icon
import { Icon} from '@iconify/react';
import handshakeIcon from '@iconify/icons-emojione-monotone/handshake';

//modal
import UpdateSubscriptionModal from  './components/modals/UpdateSubscriptionModal';
import UpdateSubQuantityModal from './components/modals/UpdateSubQuantityModal';

import IdleTimerContainer from './components/IdleTimerContainer';
import modal from './CustomFiles/js/modal';

const $ = require('jquery');
$.DataTable = require('datatables.net');
var datatable_properties_triggered = 0;
let table='';
class TransactionLog extends Component {  
    constructor(props) {
        super(props);
        this.state = {        
            isLoading : true,
            subscription_id: null,
            quantity: null,
            subscriptions: [],
            header_choice: null,
            mail_modal_header: null
        }
        this.HandleClick = this.HandleClick.bind(this);
        this.HandleUpgradeQuantity = this.HandleUpgradeQuantity.bind(this);
        this.handleManageSubscription = this.handleManageSubscription.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount = async ()=>{
        modal();
        const token = localStorage.getItem('token');
        if( token === '' || token === null ){
            localStorage.clear();
            this.props.history.push("/");
            return;
        }
        this.setState({isLoading: true});
        await this.props.get_subscription_detail(token);
        await this.props.get_transaction_log();
        
        if(this.props.transaction_log.length > 0){
            this.setState({isLoading: false,
                subscription_id: this.props.transaction_log[0].user.response[0].subscription_id,
                quantity: this.props.transaction_log[0].user.response[0].quantity
            });
        }else{
            this.setState({isLoading: false})
        }

        if(this.props.transaction_log){ 
            this.props.transaction_log.map((data, index) => (
                data.user.response[0] ?
                    this.setState({
                        subscriptions: this.state.subscriptions.concat(data.user.response[0].subscription_id)
                    })
                : null
            ))
        }
    }

    HandleClick() {  
        Swal.fire({  
            title: 'Cancel Subscription',  
            type: 'question',  
            html: '<div style=margin-top:10px>We are sad to see you go.</div> <div style=margin-top:10px> Do you really want to cancel the subscription ?</div>',
            
            allowEscapeKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            confirmButtonColor:"#27AFAF",
            cancelButtonColor:"#EA676A"
        }).then (async (result) => {
            if (result.value) {      
                if(this.props.cancel_url){
                    window.open(
                        this.props.cancel_url,
                        '_blank'
                      );
                    //window.location.href = this.props.cancel_url;
                    Swal.fire(
                        'Success!',
                        'Your subscription has been cancelled successfully :( ',
                        'success'
                    )
                }else{
                    Swal.fire(
                        'Error!',
                        'Something went wrong please try again ',
                        'error'
                    )
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Not Cancelled',
                    'Thank you for staying with us :)',
                    'error'
                )
            }
        })    
    }   
    
    HandleUpgradeQuantity(){
        window.$("#update_sub_quantity_Modal").modal('show');

        // this.setState({
        //     subscription_id: event.target.getAttribute("data-subscription_id"),
        //     quantity: event.target.getAttribute("data-quantity")
        // },()=>{
        //     window.$("#update_sub_quantity_Modal").modal('show');
        // })
    }
    
    datatableproperties = ()=>{
        if(datatable_properties_triggered === 1){
          table.destroy();  
        }
        table = $('.agentListingTable').DataTable( {
          'paging': true,
          'lengthChange': true,
          'searching': true,
        });
        datatable_properties_triggered = 1;
    }

    handleManageSubscription(event){
        this.setState({
            header_choice: event.target.getAttribute("data-header_choice"),
            mail_modal_header: event.target.getAttribute("data-modal_header")
        },() => { window.$("#update_subscription_Modal").modal('show') })
    }

    Handlecallback = async (completed, callback_data) => {
        this.props.send_payment_callback(completed, callback_data, localStorage.getItem('token'));
        await this.props.get_transaction_log();
        this.setState({
            subscription_id: this.props.transaction_log[0].user.response[0].subscription_id,
            quantity: this.props.transaction_log[0].user.response[0].quantity
        })
    }

    closeModal(){
        this.setState({
        //     subscription_id: '',
        //     quantity: '',
            mail_modal_header: '',
            header_choice:''
        })
    }

    render() {
        let footer= "footer_color";
        if (window.innerWidth > document.body.clientWidth) {
            footer = 'footer';
        }
        //const Paddle = window.Paddle;
        //var _ = this;
        // const openCheckout  = () => { 
        //     var vendor_id='';
        //     if(window.location.origin.toString() !== "https://office.roundr.app"){
        //         Paddle.Environment.set('sandbox');
        //         vendor_id = 1125;
        //     }else{
        //         vendor_id = 125307;
        //     }

        //     Paddle.Setup({ vendor: vendor_id,
        //         eventCallback: function(data) {
        //             // The data.event will specify the event type
        //             if (data.event === "Checkout.Complete") {
        //                 let completed = 1;
        //                 _.Handlecallback(completed, data.eventData);
        //             }
        //             else if (data.event === "Checkout.Close") {
        //                 //console.log(data.eventData); // Data specifics on the event
        //             }
        //             else if(data.event === "Checkout.Error"){
        //                 //console.log(data.eventData);
        //             }
        //         }
        //     });
        //     Paddle.Checkout.open({ product: this.props.subscription_plan_id,
        //         passthrough: localStorage.getItem('office_id'),
        //         allowQuantity: true
        //     });
        // }

        return (<div>
            <IdleTimerContainer></IdleTimerContainer>
            {/* <Notifications /> */}
            <Helmet>
              <title>Roundr - Transaction Log</title>
              <link rel="icon" href="https://roundr.app/wp-content/uploads/2018/03/favicon.png" type="image/png"></link>
            </Helmet>
            {<Header active="dashboard"/>}

            <div className="content-wrapper" style={{ marginLeft: '0px' }}>
                <section className="content" style={{ margin: '30px', marginTop: '60px' }}>
                    <div className="row">
                        <div className="col-xs-12 col-md-12">
                            <div className="box">
                                <div className="box-header">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <Icon className="ml10" style={{verticalAlign: 'middle', color:"#4AA49C"}} icon={handshakeIcon} width="25" height="25" /> 
                                            <h3 className="box-title fontSize20 ml15 mt15 color0F1D30 fontWeight600 fontInter">{this.props.transaction_log ? this.props.transaction_log.length > 1 ? "My subscriptions" : "My subscription" : "My subscription"}
                                                <span className="color8591A0" ><span style={{ verticalAlign: 'super' }}></span>&nbsp;({this.props.transaction_log ? this.props.transaction_log.length : 0 })</span>
                                            </h3>
                                        </div>

                                        {this.props.payment_completed ?
                                            this.props.payment_completed.length <= 0 && this.props.subscription_plan_id ?
                                                <div className="col-md-9" style={{textAlign:'center'}}>
                                                    <h4 className="fontWeight600 fontSize18 fontInter" style={{display:'inline-block', marginTop:'23px', marginBottom:"0px"}}> Select a subscription plan from the pricing page </h4>
                                                    <a href="/pricing" className="btn btn-info" style={{background: "#117777", float:'Right', borderColor: "#117777", fontWeight: "600", height: 45, width: 300, marginRight: 55, marginTop: 7, fontSize:22, display:"inline-block"}}>Pricing Page</a>
                                                </div>
                                                //<Button className="paddle_button" style={{background: "#117777", borderColor: "#117777", fontWeight: "600", height: 45, width: 300, marginRight: 15, marginTop: 7, fontSize:22}}  onClick={openCheckout} data-passthrough={localStorage.getItem('office_id')}>Subscribe</Button>
                                            :   
                                                <span style={{float:'right', marginTop:15, marginRight: 50, display: "inline-block"}}>
                                                    <div className="dropdown">
                                                        <button className="btn btn-primary dropdown-toggle" style={{backgroundColor:"#117777", fontWeight:600}} type="button" data-toggle="dropdown">Manage Subscription &nbsp;
                                                        <span className="caret"></span></button>
                                                        <ul className="dropdown-menu manage_dropdown-menu">
                                                            {this.state.quantity ?
                                                            <>
                                                            <li><button className="btn btn-link" onClick={this.HandleUpgradeQuantity} style={{color:'#333', textDecoration:'none'}} data-quantity={this.state.quantity} data-subscription_id={this.state.subscription_id} >Add users to my subscription</button></li>
                                                            <li className="divider"></li> 
                                                            <li><button className="btn btn-link" onClick={this.handleManageSubscription} style={{color:'#333', textDecoration:'none'}} data-modal_header="Remove users from my subscription" data-header_choice='1'>Remove users from my subscription</button></li>
                                                            <li className="divider"></li>
                                                            </>
                                                            : null }
                                                            <li><button className="btn btn-link" onClick={this.handleManageSubscription} style={{color:'#333', textDecoration:'none'}} data-modal_header="Upgrade to Pro Subscription/Move to Standard Subscription" data-header_choice='2'>Upgrade to Pro Subscription / <br/>Move to Standard Subscription</button></li>
                                                            {/* <li className="divider"></li>
                                                            <li><button className="btn btn-link" style={{color:'#333', fontWeight:600, textDecoration:'none'}} onClick={this.HandleClick}>Cancel Subscription</button></li> */}
                                                        </ul>
                                                    </div>
                                                </span>  
                                        : null
                                        }
                                    </div>
                                </div>
                                
                                
                                {this.props.transaction_log ?
                                    <>
                                    <br/>
                                    <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                        {this.props.transaction_log.map((data, index) => (
                                            <div className="panel panel-default" key={index}>
                                                <div className={index === 0 ? "panel-heading active": "panel-heading"} role="tab" id={"heading_"+index}>
                                                    <h4 className="panel-title">
                                                        <a className={index === 0 ? "title": "title collapsed"} role="button" data-toggle="collapse" data-parent="#accordion" href={"#collapse_"+index} aria-expanded={index === 0 ? "true": "false"} aria-controls={"collapse_"+index}>
                                                        Subscription #{index+1}
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id={"collapse_"+index} className={index === 0 ? "panel-collapse collapse in": "panel-collapse collapse"} role="tabpanel" aria-labelledby={"heading_"+index}>
                                                    <div className="panel-body">
                                                        <hr/>
                                                        {/*Payment Information*/}
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <p className="box-title fontSize20 ml15 color0F1D30 fontWeight600 fontInter"> Payment Information</p>
                                                            </div>
                                                            {data.user.response[0].next_payment ?
                                                                <div className="col-md-6">
                                                                    <p className="box-title fontSize20 ml15 color0F1D30 fontWeight600 fontInter" style={{display:"inline-block"}}> Next Payment</p>
                                                                </div>
                                                            : null }
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <span className="color0F1D30 fontSize16 fontWeight600 fontInter ml15">Payment method :<strong> {data.user.response[0].payment_information.payment_method}</strong></span>
                                                                <span className="color0F1D30 fontSize16 fontWeight600 fontInter ml15">Card type :<strong>  {data.user.response[0].payment_information.card_type}</strong> </span>
                                                                <span className="color0F1D30 fontSize16 fontWeight600 fontInter ml15">Last four digits :<strong>  {data.user.response[0].payment_information.last_four_digits}</strong> </span>
                                                                <span className="color0F1D30 fontSize16 fontWeight600 fontInter ml15">Expiry date :<strong>  {data.user.response[0].payment_information.expiry_date}</strong> </span>
                                                            </div>
                                                            {data.user.response[0].next_payment ?
                                                                <div className="col-md-6">
                                                                    <span className="color0F1D30 fontSize16 fontWeight600 fontInter ml15">Amount: {data.user.response[0].next_payment.amount}</span>
                                                                    <span className="color0F1D30 fontSize16 fontWeight600 fontInter ml15">Currency: {data.user.response[0].next_payment.currency}</span>
                                                                    <span className="color0F1D30 fontSize16 fontWeight600 fontInter ml15">Date :  <Moment format="D-MM-YYYY">{data.user.response[0].next_payment.date}</Moment> </span>
                                                                </div>
                                                            : null
                                                            }
                                                        </div>
                                                        <hr/>

                                                        <div className="table-responsive">
                                                            <table id={"agentListingTable_"+index} className={"table agentListingTable_"+index} style={{marginBottom:30, overflowX:'none'}} ref={el => this.el = el}>
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{textAlign:"center"}}>Date&nbsp;&nbsp;</th>
                                                                        <th style={{textAlign:"center"}}>Transaction Amount&nbsp;&nbsp;</th>
                                                                        <th style={{textAlign:"center"}}>Currency&nbsp;&nbsp;</th>
                                                                        <th style={{textAlign:"center"}}>Status&nbsp;&nbsp;</th>
                                                                        <th style={{textAlign:"center"}}>Receipt&nbsp;&nbsp;</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>{data.payment.response.reverse().map((info, key) => (
                                                                    <tr key={key}>
                                                                        <td className="color2D3E54 fontWeight600 userProfileTd"><center><Moment format="D MMM YYYY">{info.payout_date}</Moment></center> </td>
                                                                        <td className="color2D3E54" style={{textAlign:"center"}}>{info.amount}</td>
                                                                        <td className="color2D3E54" style={{textAlign:"center"}}>{info.currency}</td>
                                                                        <td className="color2D3E54" style={{textAlign:"center"}}>{Number(info.is_paid) === 1 ? <p style={{color:"#4AA49C",fontFamily:"Inter",fontWeight:600, fontSize:15}}>Successful</p> : <p style={{color:"#EB5757",fontFamily:"Inter",fontWeight:600, fontSize:15}}>Pending</p>}</td>
                                                                        <td className="color2D3E54 cursorPointer" style={{textAlign:"center"}}>{info.receipt_url ? <a href= {" "+ info.receipt_url +" "} target="_blank" rel="noopener noreferrer" style={{backgroundColor:"#117777", padding:7, color:"#fff", textDecoration:'none'}}> Download Receipt </a> : '-'}</td>
                                                                    </tr>
                                                                ))}
                                                                </tbody>
                                                            </table> 
                                                            {/* {this.datatableproperties()} */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    </>
                                : 
                                    this.state.isLoading ? <center><Loader /></center> : null  
                                }
                            </div>
                        </div>
                    </div>
                </section>

                {/* Update Subscription Quantity */}
                {this.state.quantity ?
                    <UpdateSubQuantityModal 
                        subscription_id = {this.props.transaction_log[0].user.response[0].subscription_id}
                        quantity = {this.props.transaction_log[0].user.response[0].quantity}
                        subscriptions = {this.state.subscriptions}
                        //closeModal = {this.closeModal}
                    />
                : null}

                { /* Change/Reduce Subscription Modal/Quantity */}
                {this.state.mail_modal_header && this.state.header_choice ?
                    <UpdateSubscriptionModal
                        modalHeader= {this.state.mail_modal_header}
                        header_choice={this.state.header_choice}
                        closeModal = {this.closeModal}
                    />
                : null}
            </div>
                <Footer footer={footer}></Footer>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        get_subscription_detail: (token) => dispatch(actions.get_subscription_detail(token)),
        send_payment_callback: (completed, paddle_response, token) => dispatch(actions.send_payment_callback(completed, paddle_response, token)),
        get_transaction_log : () => dispatch(actions.get_transaction_log()),
    }
}
  
const mapStateToProps = state => {
    return{
        //agent mobile authtoken
        authtoken: state.add_property_modal.authtoken,
        
        cancel_url: state.transaction_log.cancel_url,
       
        //transaction log
        transaction_log: state.transaction_log.transaction_log,
        
        subscription_plan_id: state.transaction_log.subscription_plan_id,
        payment_completed: state.transaction_log.payment_completed,
        callback_success: state.transaction_log.callback_success,

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TransactionLog)
