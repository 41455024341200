import React,{ Component } from "react";
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import Constants from '../../constants/Constants';
import Moment from 'react-moment';
//icons
import { Icon } from '@iconify/react';
import copyIcon from '@iconify/icons-fa-regular/copy';
import arrowRight from '@iconify/icons-carbon/arrow-right';
import radioCircle from '@iconify/icons-bx/radio-circle';
import square12Regular from '@iconify/icons-fluent/square-12-regular';
import shareIcon from '@iconify/icons-feather/share';
//import TravelTrackingMaps from './TravelTrackingMaps';
import Map from './TravelTrackingMaps';

class TravelTrackingDetail extends Component{
    constructor(props) {
        super(props);
        this.state = {
          currentLocation: { lat: 40.756795, lng: -73.954298 }
        }
        this.closeModal = this.closeModal.bind(this);
        //this.resetDateRange = this.resetDateRange.bind(this);
    }    

    closeModal = async() => {
        this.props.clear_selected_trip()
    }

    render(){ 
        var formattedBody = "Your realtor, has loaded your property onto the Roundr app for simple viewing scheduling, feedback, and record keeping. \n Learn more about how it works, and download the app - https://roundr.app/share/ \n After downloading the app, use code "+ this.props.share_code +" to link to your property";
        var mailToLink = "mailto:?subject=Property Code of Roundr Property &body="+formattedBody;
        const googleMapsApiKey = "AIzaSyDwg4e6UirDc4yc02KqWK2VJZRtdaCREts";
        
        return(
            <>
            <div className="modal fade" id="traveltrackingdetailModal" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content modalheight" style ={{borderRadius : '12px' }} >
                        <div className="modal-container">
                            {/* Header */}
                            <div style={{ background: '#EDF7F6' }}>
                                <div className="modal-user-region" style={{ paddingLeft: '20px', paddingTop: '20px' }}>
                                    <div className="row">
                                        <div className="col-md-2">
                                            <div style={{display:"block", textAlign:"center"}}>
                                                {this.props.agent_performance?
                                                    this.props.agent_performance.agent_data.image ?
                                                    <img alt="" src={Constants.IMAGE_URL + this.props.agent_performance.agent_data.image} className="img-circle" width="64" height="64" />
                                                    :
                                                    <img alt="" src="user-images/userDefault.png" className="img-circle" width="64" height="64" />
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="col-md-7">
                                            <span className="fontBold fontRoboto fontSize25" >
                                                {!this.props.agent_performance ? <center><img src="images/loader.svg" width="40" height="40" alt="" /></center> : this.props.agent_performance.agent_data.firstname}
                                                &nbsp;
                                                {this.props.agent_performance? this.props.agent_performance.agent_data.lastname : null}
                                            </span>
                                            <br/>
                                            <span className="fontRoboto fontSize13 color8591A0">{!this.props.agent_performance ? "" : this.props.agent_performance.agent_data.email} - {!this.props.agent_performance ? "" : this.props.agent_performance.agent_data.cellnumber}</span>
                                            <span style={{ float: 'right' }}>
                                            </span>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="circle" data-dismiss="modal" onClick={this.closeModal}>
                                                <img src="images/modal-close.png " width="12" height="12" alt="" />
                                            </div> 
                                        </div>
                                    </div>
                                    {this.props.agent_performance && this.props.agent_performance.agent_data.hash !== null?
                                        <div className="row">
                                            <div className="col-md-10 mb20">
                                                <br/>
                                                <div className="clipboard">
                                                    <label style={{marginRight:"10px", fontSize: "16px"}}>API-Key</label>
                                                    <input onClick={this.copyAuthKey} className="copy-input" value={this.props.agent_performance.agent_data.hash} id="copyClipboard" readOnly={true} />
                                                    <button className="copy-btn" id="copyButton" onClick={this.copyAuthKey}><Icon icon={copyIcon} style={{fontSize: '20px', verticalAlign: 'middle'}} /></button>
                                                </div>
                                            </div>
                                        </div>
                                    : null }
                                </div>
                            </div>
                            {/* End of Header */}
                            
                            <div className = "container-fluid">
                                <div className="row travel-tracking-past-trips-box">
                                    <div className="col-md-12" style={{marginTop:5}}>
                                        <p className="travel-tracking-past-trips-text mb5"> {this.props.selected_trip_data.end_address}</p>
                                        <span className="travel-tracking-past-trips-text-head"> <Moment format="D MMM YYYY, hh:mm">{this.props.selected_trip_data.start_time}</Moment></span>
                                    </div>
                                </div>
                                
                                {this.props.selected_trip_data ?
                                    <div style={{ height: "280px", width: "100%", marginBottom: 10 }}>
                                        <Map
                                            googleMapURL={
                                                'https://maps.googleapis.com/maps/api/js?key=' +
                                                googleMapsApiKey +
                                                '&libraries=geometry,drawing,places'
                                            }
                                            markers={[
                                                { latitude: parseFloat(this.props.selected_trip_data.start_lat), longitude: parseFloat(this.props.selected_trip_data.start_lon) },
                                                { latitude: parseFloat(this.props.selected_trip_data.end_lat), longitude: parseFloat(this.props.selected_trip_data.end_lon) }]}
                                            loadingElement={<div style={{height: `100%`}}/>}
                                            containerElement={<div style={{height: "100%"}}/>}
                                            mapElement={<div style={{height: `100%`}}/>}
                                            defaultZoom={50}
                                            defaultCenter={{lat: parseFloat(this.props.selected_trip_data.start_lat), lng: parseFloat(this.props.selected_trip_data.end_lon)}}     
                                        />
                                    </div>
                                : null
                                }
                                
                                {/* Trip Information details */}
                                <div className="row row-eq-height travel-tracking-past-trips-box">
                                    <div className="col-md-3" style={{width:"22%", borderRight: "1px solid #D1E5F8", marginTop:5}}>
                                        <p className="travel-tracking-past-trips-text-head mb5"> Distance</p>
                                        <p className="travel-tracking-past-trips-text"> {this.props.selected_trip_data.distance} km</p>
                                    </div>
                                    <div className="col-md-3" style={{width:"23%", borderRight: "1px solid #D1E5F8", marginTop:5}}>
                                        <p className="travel-tracking-past-trips-text-head mb5"> Time</p>
                                        <p className="travel-tracking-past-trips-text"> {this.props.selected_trip_data.est_time} min</p>
                                    </div>
                                    <div className="col-md-3" style={{width:"23%", borderRight: "1px solid #D1E5F8",  marginTop:5}}>
                                        <p className="travel-tracking-past-trips-text-head mb5"> Cost per KM</p>
                                        <p className="travel-tracking-past-trips-text"> {this.props.agent_data.currency_symbol} {this.props.selected_trip_data.cost_per_km}</p>
                                    </div>
                                    <div className="col-md-3" style={{width:"32%", marginTop:5}}>
                                        <p className="travel-tracking-past-trips-text-head mb5"> Claimable Expense</p>
                                        <p className="travel-tracking-past-trips-text"> {this.props.agent_data.currency_symbol} {Number(this.props.selected_trip_data.claimable_exp).toFixed(2)}</p>
                                    </div>
                                </div>
                                
                                {/* Trip Data */}
                                <div className="mt30 mb30 mr10 ml10">
                                    <hr/>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="trip-data-left-text">Appointment Type</p>
                                        </div>
                                        <div className="col-md-6">
                                            <p className="trip-data-right-text" style={{textAlign:'right'}}>Viewing</p>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="trip-data-left-text">Vehicle</p>
                                        </div>
                                        <div className="col-md-6">
                                            <p className="trip-data-right-text" style={{textAlign:'right'}}>{this.props.selected_trip_data.car_make} {this.props.selected_trip_data.car_model}</p>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <Icon icon={radioCircle} height="16" className="icon-border mr5" style={{verticalAlign:'sub'}}/>
                                            <span><Moment format="hh:mm">{this.props.selected_trip_data.start_time}</Moment></span>
                                            <p>{this.props.selected_trip_data.start_address}</p>
                                        </div>
                                        <div className="col-md-2">
                                            <Icon icon={arrowRight} height="25" style={{color:'#738196'}}/>
                                        </div>
                                        <div className="col-md-5" style={{textAlign:'right'}}>
                                            <span><Moment format="hh:mm">{this.props.selected_trip_data.end_time}</Moment></span>
                                            <Icon icon={square12Regular} height="16" className="icon-border ml5" style={{verticalAlign:'sub'}}/>
                                            <p style={{textAlign:'right'}}>{this.props.selected_trip_data.end_address}</p>
                                        </div>
                                    </div>
                                </div>
                                {/* Trip Data Ends here */}
                                <div style={{textAlign:'center'}}>
                                    <a href={mailToLink} className="btn btn-primary fontBold" style={{ background:'#27AFAF', borderRadius:'4px', padding:10, marginBottom:30, width:'50%'}}>
                                        <Icon icon={shareIcon} style={{fontSize:'20px', verticalAlign:'bottom'}} />&nbsp;&nbsp;
                                        Share
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        reset_property_modal: () => dispatch(actions.reset_property_modal()),
        get_property_data_modal: (fromDate, toDate, prop_id, agent_id, token, date_sold, from_networking, property_notes_user_id) => dispatch(actions.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold, from_networking, property_notes_user_id)),
        //set page for calendar
        set_page: (page, reset_date_range) => dispatch(actions.set_page(page, reset_date_range)),
    }
}
  
const mapStateToProps = state => {
    return{
        //Agent Details
        token: state.auth.token,
        agent_performance: state.agent_modal.agent_performance,
        agent_data: state.agent_modal.agent_data,
        
        //networking tab
        office_list: state.agent_modal.office_list,
        open_list: state.agent_modal.open_list,

        //agent mobile authtoken
        authtoken: state.add_property_modal.authtoken,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TravelTrackingDetail)