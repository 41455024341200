
import React, { Component } from 'react';
import Constants from '../../constants/Constants';
import { Icon } from '@iconify/react';
import clockHistory from '@iconify/icons-bi/clock-history';


class SellerLinkedMessage extends Component {

    sellerLinkeMessageFun = () => {
        var sellerLinkedMessage = null;
        if(this.props.property_detail){
            if (this.props.property_detail.length === 0) {
                return (
                    <center>
                        <img src="images/loader.svg" width="50" height="50" alt="" />
                    </center>
                );
            }else {
                if (this.props.property_detail.is_seller_occupant === '1') {
                    /* Checking Condition For Seller Not Linked and Occupant Not Linked */
                    if (this.props.property_detail.user_id_seller === null && this.props.property_detail.isAgentApproved === "0") {
                        sellerLinkedMessage =
                            <div className="linkingMessage">
                                <div className="row">
                                    <div className="col-md-12 col-lg-12 col-xs-12">
                                        <span className="color0F1D30 fontSize13 fontWeight600 fontInter">
                                            {
                                                !this.props.property_detail.agent_image ?
                                                    <img className="img-circle" src="user-images/userDefault.png" width="24" height="24" alt="" />
                                                    :
                                                    <img className="img-circle" src={Constants.IMAGE_URL + this.props.property_detail.agent_image} width="24" height="24" alt="" />
                                            }
                                            <span className="ml10">
                                                {this.props.property_detail.agent_fname}&nbsp;{this.props.property_detail.agent_lname}
                                            </span>
                                            <img className="img-circle ml10" src="images/right-sign.png" width="20" height="20" alt="" />
                                            <span className="color4AA49C fontInter fontWeight500 fontSize11 ml5">Agent Linked</span>
                                        </span>
                                    </div>
                                    <div className="col-md-4 col-lg-4 col-xs-4">
                                    </div>
                                </div>
                                <div className="row mt15">
                                    <div className="col-md-12 col-lg-12 col-xs-12">
                                        <span className="color0F1D30 fontSize13 fontWeight600 fontInter">
                                            {
                                                !this.props.property_detail.seller_image ?
                                                    <img className="img-circle" src="user-images/userDefault.png" width="24" height="24" alt="" />
                                                    :
                                                    <img className="img-circle" src={Constants.IMAGE_URL + this.props.property_detail.seller_image} width="24" height="24" alt="" />
                                            }
                                            <span className="ml10">
                                                {Number(this.props.property_detail.property_type) === Number(0)? 
                                                    !this.props.property_detail.seller_fname ? <span> No Owner</span> : this.props.property_detail.seller_fname 
                                                    :
                                                    !this.props.property_detail.seller_fname ? <span> No Seller</span> : this.props.property_detail.seller_fname
                                                }
                                                
                                            &nbsp;
                                            {this.props.property_detail.seller_lname}
                                            </span>
                                            <Icon icon={clockHistory} className="ml10" style={{color:'FF9966', verticalAlign:"middle" }} />
                                            {Number(this.props.property_detail.property_type) === Number(0)? 
                                                <span className="colorFF9966 fontInter fontWeight500 fontSize11 ml5">Owner Pending</span>
                                                :
                                                <span className="colorFF9966 fontInter fontWeight500 fontSize11 ml5">Seller Pending</span>
                                            }
                                        </span>
                                    </div>
                                </div>
                                {/* <div className="row mt15">
                                    <div className="col-md-12 col-lg-12 col-xs-12">
                                        <span className="color0F1D30 fontSize13 fontWeight600 fontInter">
                                            {
                                                !this.props.property_detail.seller_image ?
                                                    <img className="img-circle" src="user-images/userDefault.png" width="24" height="24" alt="" />
                                                    :
                                                    <img className="img-circle" src={Constants.IMAGE_URL + this.props.property_detail.seller_image} width="24" height="24" alt="" />
                                            }
                                            <span className="ml10">
                                                {!this.props.property_detail.seller_fname ? <span> No Occupant</span> : this.props.property_detail.seller_fname}
                                            &nbsp;
                                            {this.props.property_detail.seller_lname}
                                            </span>
                                            <Icon icon={clockHistory} className="ml10" style={{color:'FF9966', verticalAlign:"middle" }} />
                                            <span className="colorFF9966 fontInter fontWeight500 fontSize11 ml5">Occupant Pending</span>
                                        </span>
                                    </div>
                                </div> */}
                            </div>
                    }

                    /* Condition For Seller and Occupant Linked */

                    if (this.props.property_detail.user_id_seller !== null && this.props.property_detail.isAgentApproved === "1") 
                    {

                        sellerLinkedMessage =

                            <div className="linkingMessage">
                                <div className="row">
                                    <div className="col-md-12 col-lg-12 col-xs-12">
                                        <span className="color0F1D30 fontSize13 fontWeight600 fontInter">
                                            {
                                                !this.props.property_detail.agent_image ?
                                                <img className="img-circle" src="user-images/userDefault.png" width="24" height="24" alt="" />
                                                :
                                                <img className="img-circle" src={Constants.IMAGE_URL + this.props.property_detail.agent_image} width="24" height="24" alt="" />
                                            }
                                        <span className="ml10">
                                                { this.props.property_detail.agent_fname }&nbsp;{ this.props.property_detail.agent_lname }
                                        </span>
                                        <img className="img-circle ml10" src="images/right-sign.png" width="20" height="20" alt="" />
                                        <span className="color4AA49C fontInter fontWeight500 fontSize11 ml5">Agent Linked</span>
                                        </span>
                                    </div>
                                    <div className="col-md-4 col-lg-4 col-xs-4">
                                    </div>
                                </div>
                                <div className="row mt15">
                                    <div className="col-md-12 col-lg-12 col-xs-12">
                                        <span className="color0F1D30 fontSize13 fontWeight600 fontInter">
                                            {
                                                !this.props.property_detail.seller_image ?
                                                <img className="img-circle" src="user-images/userDefault.png" width="24" height="24" alt="" />
                                                :
                                                <img className="img-circle" src={Constants.IMAGE_URL + this.props.property_detail.seller_image} width="24" height="24" alt="" />
                                            }
                                            <span className="ml10">{ this.props.property_detail.seller_fname }&nbsp;{ this.props.property_detail.seller_lname} </span>
                                            <img className="img-circle ml10" src="images/right-sign.png" width="20" height="20" alt="" />
                                            {Number(this.props.property_detail.property_type) === Number(0)? 
                                                <span className="color4AA49C fontInter fontWeight500 fontSize11 ml5">Owner Linked</span>
                                                :
                                                <span className="color4AA49C fontInter fontWeight500 fontSize11 ml5">Seller Linked</span>
                                            }
                                        </span>
                                    </div>
                                </div>
                                {/* <div className="row mt15">
                                    <div className="col-md-12 col-lg-12 col-xs-12">
                                        <span className="color0F1D30 fontSize13 fontWeight600 fontInter">
                                            {
                                                !this.props.property_detail.seller_image ?
                                                <img className="img-circle" src="user-images/userDefault.png" width="24" height="24" alt="" />
                                                :
                                                <img className="img-circle" src={Constants.IMAGE_URL + this.props.property_detail.seller_image} width="24" height="24" alt="" />
                                            }
                                            <span className="ml10">{ this.props.property_detail.seller_fname }&nbsp;{ this.props.property_detail.seller_lname} </span>
                                            <img className="img-circle ml10" src="images/right-sign.png" width="20" height="20" alt="" />
                                            <span className="color4AA49C fontInter fontWeight500 fontSize11 ml5">Occupant Linked</span>
                                        </span>
                                    </div>
                                </div> */}
                            </div>
                    }

                    /* Checking Condition For Seller Link Pending and Occupant Not Linked */

                    if (this.props.property_detail.user_id_seller !== null && this.props.property_detail.isAgentApproved === "0") {
                        sellerLinkedMessage =
                            <div>
                                <div className="row">
                                    <div className="col-md-12 col-lg-12 col-xs-12">
                                        <span className="color0F1D30 fontSize13 fontWeight600 fontInter">
                                            {
                                                !this.props.property_detail.agent_image ?
                                                <img className="img-circle" src="user-images/userDefault.png" width="24" height="24" alt="" />
                                                :
                                                <img className="img-circle" src={Constants.IMAGE_URL + this.props.property_detail.agent_image} width="24" height="24" alt="" />
                                            }
                                        <span className="ml10">
                                                { this.props.property_detail.agent_fname }&nbsp;{ this.props.property_detail.agent_lname }
                                        </span>
                                        <img className="img-circle ml10" src="images/right-sign.png" width="20" height="20" alt="" />
                                        <span className="color4AA49C fontInter fontWeight500 fontSize11 ml5">Agent Linked</span>
                                        </span>
                                    </div>
                                    <div className="col-md-4 col-lg-4 col-xs-4">
                                    </div>
                                </div>
                                <div className="row mt15">
                                    <div className="col-md-12 col-lg-12 col-xs-12">
                                        <span className = "color0F1D30 fontSize13 fontWeight600 fontInter">
                                        {
                                        !this.props.property_detail.seller_image ?
                                            <img className="img-circle" src="user-images/userDefault.png" width="24" height="24" alt="" />
                                            :
                                            <img className="img-circle" src={Constants.IMAGE_URL + this.props.property_detail.seller_image} width="24" height="24" alt="" />
                                        }
                                        <span className="ml10">
                                            {Number(this.props.property_detail.property_type) === Number(0)? 
                                                !this.props.property_detail.seller_fname ? <span> No Owner</span> : this.props.property_detail.seller_fname
                                                :
                                                !this.props.property_detail.seller_fname ? <span> No Seller</span> : this.props.property_detail.seller_fname
                                            }
                                            &nbsp;
                                            {this.props.property_detail.seller_lname}
                                        </span>
                                        <i class="fa fa-clock-o ml10" aria-hidden="true" style={{ color: '#FFB92E', fontSize: '20px' }} width="20" height="20" ></i>
                                        {Number(this.props.property_detail.property_type) === Number(0)? 
                                            <span className="colorFFB92E fontInter fontWeight500 fontSize11 ml5">Owner Link Pending</span>
                                            :
                                            <span className="colorFFB92E fontInter fontWeight500 fontSize11 ml5">Seller Link Pending</span>
                                        }
                                        </span>
                                    </div>
                                </div>
                                {/* <div className="row mt15">
                                    <div className="col-md-12 col-lg-12 col-xs-12">
                                        <span className = "color0F1D30 fontSize13 fontWeight600 fontInter">
                                        {
                                        !this.props.property_detail.seller_image ?
                                            <img className="img-circle" src="user-images/userDefault.png" width="24" height="24" alt="" />
                                            :
                                            <img className="img-circle" src={Constants.IMAGE_URL + this.props.property_detail.seller_image} width="24" height="24" alt="" />
                                        }
                                        <span className="ml10">
                                            {!this.props.property_detail.seller_fname ? <span> No Occupant</span> : this.props.property_detail.seller_fname}
                                            &nbsp;
                                            {this.props.property_detail.seller_lname}
                                        </span>
                                        <i class="fa fa-clock-o ml10" aria-hidden="true" style={{ color: '#FFB92E', fontSize: '20px' }} aria-hidden="true" width="20" height="20" ></i>
                                        <span className="colorFFB92E fontInter fontWeight500 fontSize11 ml5">Occupant Link Pending</span>
                                        </span>
                                    </div>
                                </div> */}
                            </div>
                    }

                }  /* Condition For Seller is an occupant */

                /* Condition For Seller Is Not an Occupant */
                if (this.props.property_detail.is_seller_occupant === '0' || this.props.property_detail.is_seller_occupant === 0) 
                {
                    /* Checking Seller  Linked and Occupant  Linked */

                    if (this.props.property_detail.user_id_occupant !== null && this.props.property_detail.user_id_seller !== null && this.props.property_detail.isAgentApproved === "1" && this.props.property_detail.isAgentApprovedFromOccupant === "1") {
                        sellerLinkedMessage =

                            <div>
                                <div className="row">
                                    <div className="col-md-12 col-lg-12 col-xs-12">
                                        <span className="color0F1D30 fontSize13 fontWeight600 fontInter">
                                            {
                                                !this.props.property_detail.agent_image ?
                                                <img className="img-circle" src="user-images/userDefault.png" width="24" height="24" alt="" />
                                                :
                                                <img className="img-circle" src={Constants.IMAGE_URL + this.props.property_detail.agent_image} width="24" height="24" alt="" />
                                            }
                                        <span className="ml10">
                                                { this.props.property_detail.agent_fname }&nbsp;{ this.props.property_detail.agent_lname }
                                        </span>
                                        <img className="img-circle ml10" src="images/right-sign.png" width="20" height="20" alt="" />
                                        <span className="color4AA49C fontInter fontWeight500 fontSize11 ml5">Agent Linked</span>
                                        </span>
                                    </div>
                                    <div className="col-md-4 col-lg-4 col-xs-4">
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-lg-12 col-xs-12">
                                        <span className="color0F1D30 fontSize13 fontWeight600 fontInter">
                                            {
                                                !this.props.property_detail.seller_image ?
                                                <img className="img-circle" src="user-images/userDefault.png" width="24" height="24" alt="" />
                                                :
                                                <img className="img-circle" src={Constants.IMAGE_URL + this.props.property_detail.seller_image} width="24" height="24" alt="" />
                                            }
                                        <span className="ml10">
                                                { this.props.property_detail.seller_fname }&nbsp;{ this.props.property_detail.seller_lname }
                                        </span>
                                        <img className="img-circle ml10" src="images/right-sign.png" width="20" height="20" alt="" />
                                        {Number(this.props.property_detail.property_type) === Number(0)?
                                            <span className="color4AA49C fontInter fontWeight500 fontSize11 ml5">Owner Linked</span>
                                            :
                                            <span className="color4AA49C fontInter fontWeight500 fontSize11 ml5">Seller Linked</span>
                                        }
                                        </span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-lg-12 col-xs-12">
                                        <span className="color0F1D30 fontSize13 fontWeight600 fontInter">
                                            {
                                                !this.props.property_detail.o_image ?
                                                <img className="img-circle" src="user-images/userDefault.png" width="24" height="24" alt="" />
                                                :
                                                <img className="img-circle" src={Constants.IMAGE_URL + this.props.property_detail.o_image} width="24" height="24" alt="" />
                                            }
                                        <span className="ml10">
                                                { this.props.property_detail.o_fname }&nbsp;{ this.props.property_detail.o_lname }
                                        </span>
                                        <img className="img-circle ml10" src="images/right-sign.png" width="20" height="20" alt="" />
                                        <span className="color4AA49C fontInter fontWeight500 fontSize11 ml5">Occupant Linked</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                    }

                    /* Checking Seller Not Linked and Occupant Not Linked */

                    if (this.props.property_detail.user_id_occupant === null && this.props.property_detail.user_id_seller === null && this.props.property_detail.isAgentApproved === "0" && this.props.property_detail.isAgentApprovedFromOccupant === "0") {
                        sellerLinkedMessage =
                            <div>
                                <div className="row">
                                    <div className="col-md-12 col-lg-12 col-xs-12">
                                        <span className="color0F1D30 fontSize13 fontWeight600 fontInter">
                                            {
                                                !this.props.property_detail.agent_image ?
                                                <img className="img-circle" src="user-images/userDefault.png" width="24" height="24" alt="" />
                                                :
                                                <img className="img-circle" src={Constants.IMAGE_URL + this.props.property_detail.agent_image} width="24" height="24" alt="" />
                                            }
                                        <span className="ml10">
                                                { this.props.property_detail.agent_fname }&nbsp;{ this.props.property_detail.agent_lname }
                                        </span>
                                        <img className="img-circle ml10" src="images/right-sign.png" width="20" height="20" alt="" />
                                        <span className="color4AA49C fontInter fontWeight500 fontSize11 ml5">Agent Linked</span>
                                        </span>
                                    </div>
                                </div>

                                <div className="row mt15">
                                    <div className="col-md-12 col-lg-12 col-xs-12">
                                        <span className = "color0F1D30 fontSize13 fontWeight600 fontInter">
                                        {
                                        !this.props.property_detail.seller_image ?
                                        <img className="img-circle" src="user-images/userDefault.png" width="24" height="24" alt="" />
                                        :
                                        <img className="img-circle" src={Constants.IMAGE_URL + this.props.property_detail.seller_image} width="24" height="24" alt="" />
                                        }

                                        <span className="ml10">
                                            {Number(this.props.property_detail.property_type) === Number(0)?
                                                !this.props.property_detail.seller_fname ? <span> No Owner </span> :this.props.property_detail.seller_fname
                                                :
                                                !this.props.property_detail.seller_fname ? <span> No Seller </span> :this.props.property_detail.seller_fname
                                            }
                                            &nbsp;{this.props.seller_lname}
                                        </span>
                                        <Icon icon={clockHistory} className="ml10" style={{color:'FF9966', verticalAlign:"middle" }} />
                                        {Number(this.props.property_detail.property_type) === Number(0)?
                                            <span className="colorFF9966 fontInter fontWeight500 fontSize11 ml5">Owner Pending</span>
                                            :
                                            <span className="colorFF9966 fontInter fontWeight500 fontSize11 ml5">Seller Pending</span>
                                        }
                                        </span>
                                    </div>
                                </div>
                                <div className="row mt15">
                                    <div className="col-md-12 col-lg-12 col-xs-12">
                                        <span className = "color0F1D30 fontSize13 fontWeight600 fontInter">
                                        {
                                        !this.props.property_detail.o_image ?
                                        <img className="img-circle" src="user-images/userDefault.png" width="24" height="24" alt="" />
                                        :
                                        <img className="img-circle" src={Constants.IMAGE_URL + this.props.property_detail.o_image} width="24" height="24" alt="" />
                                        }

                                        <span className="ml10">
                                            {!this.props.property_detail.o_fname ? <span> No Occupant </span> :this.props.property_detail.o_fname}
                                            &nbsp;{this.props.o_lname}
                                        </span>
                                        <Icon icon={clockHistory} className="ml10" style={{color:'FF9966', verticalAlign:"middle" }} />
                                        <span className="colorFF9966 fontInter fontWeight500 fontSize11 ml5">Occupant Pending</span>
                                        </span>
                                    </div>
                            </div>
                            </div>
                    }

                    /* Checking Condition For Seller Link Pending and Occupant Not Linked */

                    if (this.props.property_detail.user_id_occupant === null && this.props.property_detail.user_id_seller !== null && this.props.property_detail.isAgentApproved === "0" && this.props.property_detail.isAgentApprovedFromOccupant === "0") {


                        sellerLinkedMessage =

                            <div>
                                <div className="row">
                                    <div className="col-md-12 col-lg-12 col-xs-12">
                                        <span className="color0F1D30 fontSize13 fontWeight600 fontInter">
                                            {
                                                !this.props.property_detail.agent_image ?
                                                <img className="img-circle" src="user-images/userDefault.png" width="24" height="24" alt="" />
                                                :
                                                <img className="img-circle" src={Constants.IMAGE_URL + this.props.property_detail.agent_image} width="24" height="24" alt="" />
                                            }
                                        <span className="ml10">
                                                { this.props.property_detail.agent_fname }&nbsp;{ this.props.property_detail.agent_lname }
                                        </span>
                                        <img className="img-circle ml10" src="images/right-sign.png" width="20" height="20" alt="" />
                                        <span className="color4AA49C fontInter fontWeight500 fontSize11 ml5">Agent Linked</span>
                                        </span>
                                    </div>
                                    <div className="col-md-4 col-lg-4 col-xs-4">
                                    </div>
                                </div>
                                <div className="row mt15">
                                    <div className="col-md-12 col-lg-12 col-xs-12">
                                        <span className = "color0F1D30 fontSize13 fontWeight600 fontInter">
                                        {
                                        !this.props.property_detail.seller_image ?
                                            <img className="img-circle" src="user-images/userDefault.png" width="24" height="24" alt="" />
                                            :
                                            <img className="img-circle" src={Constants.IMAGE_URL + this.props.property_detail.seller_image} width="24" height="24" alt="" />
                                        }
                                        <span className="ml10">
                                            {Number(this.props.property_detail.property_type) === Number(0)?
                                            !this.props.property_detail.seller_fname ? <span> No Owner</span> : this.props.property_detail.seller_fname
                                            :
                                            !this.props.property_detail.seller_fname ? <span> No Seller</span> : this.props.property_detail.seller_fname
                                            }
                                            &nbsp;
                                            {this.props.property_detail.seller_lname}
                                        </span>
                                        <i class="fa fa-clock-o ml10" aria-hidden="true" style={{ color: '#FFB92E', fontSize: '20px' }} width="20" height="20" ></i>
                                        {Number(this.props.property_detail.property_type) === Number(0)?
                                            <span className="colorFFB92E fontInter fontWeight500 fontSize11 ml5">Owner Link Pending</span>
                                            :
                                            <span className="colorFFB92E fontInter fontWeight500 fontSize11 ml5">Seller Link Pending</span>
                                        }
                                        </span>
                                    </div>
                                </div>
                                <div className="row mt15">
                                    <div className="col-md-12 col-lg-12 col-xs-12">
                                        <span className = "color0F1D30 fontSize13 fontWeight600 fontInter">
                                        {
                                        !this.props.property_detail.o_image ?
                                        <img className="img-circle" src="user-images/userDefault.png" width="24" height="24" alt="" />
                                        :
                                        <img className="img-circle" src={Constants.IMAGE_URL + this.props.property_detail.o_image} width="24" height="24" alt="" />
                                        }

                                        <span className="ml10">
                                            {!this.props.property_detail.o_fname ? <span> No Occupant </span> :this.props.property_detail.o_fname}
                                            &nbsp;{this.props.o_lname}
                                        </span>
                                        <Icon icon={clockHistory} className="ml10" style={{color:'FF9966', verticalAlign:"middle" }} />
                                        <span className="colorFF9966 fontInter fontWeight500 fontSize11 ml5">Occupant Pending</span>
                                        </span>
                                    </div>
                            </div>
                            </div>
                    }

                    /* Seller Linked  But Occupant Not Linked  */

                    if (this.props.property_detail.user_id_occupant === null && this.props.property_detail.user_id_seller !== null && this.props.property_detail.isAgentApproved === "1" && this.props.property_detail.isAgentApprovedFromOccupant === "0") {
                        sellerLinkedMessage =

                            <div>
                                <div className="row">
                                    <div className="col-md-12 col-lg-12 col-xs-12">
                                        <span className="color0F1D30 fontSize13 fontWeight600 fontInter">
                                            {
                                                !this.props.property_detail.agent_image ?
                                                <img className="img-circle" src="user-images/userDefault.png" width="24" height="24" alt="" />
                                                :
                                                <img className="img-circle" src={Constants.IMAGE_URL + this.props.property_detail.agent_image} width="24" height="24" alt="" />
                                            }
                                        <span className="ml10">
                                                { this.props.property_detail.agent_fname }&nbsp;{ this.props.property_detail.agent_lname }
                                        </span>
                                        <img className="img-circle ml10" src="images/right-sign.png" width="20" height="20" alt="" />
                                        <span className="color4AA49C fontInter fontWeight500 fontSize11 ml5">Agent Linked</span>
                                        </span>
                                    </div>
                                    <div className="col-md-4 col-lg-4 col-xs-4">
                                    </div>
                                </div>

                                <div className="row mt15">
                                    <div className="col-md-12 col-lg-12 col-xs-12">
                                        <span className="color0F1D30 fontSize13 fontWeight600 fontInter">
                                            {
                                                !this.props.property_detail.seller_image ?
                                                <img className="img-circle" src="user-images/userDefault.png" width="24" height="24" alt="" />
                                                :
                                                <img className="img-circle" src={Constants.IMAGE_URL + this.props.property_detail.seller_image} width="24" height="24" alt="" />
                                            }
                                            <span className="ml10">{ this.props.property_detail.seller_fname }&nbsp;{ this.props.property_detail.seller_lname} </span>
                                            <img className="img-circle ml10" src="images/right-sign.png" width="20" height="20" alt="" />
                                            {Number(this.props.property_detail.property_type) === Number(0)?
                                                <span className="color4AA49C fontInter fontWeight500 fontSize11 ml5">Owner Linked</span>
                                                :
                                                <span className="color4AA49C fontInter fontWeight500 fontSize11 ml5">Seller Linked</span>
                                            }
                                        </span>
                                    </div>
                                </div>

                                <div className="row mt15">
                                    <div className="col-md-12 col-lg-12 col-xs-12">
                                        <span className = "color0F1D30 fontSize13 fontWeight600 fontInter">
                                        {
                                        !this.props.property_detail.o_image ?
                                        <img className="img-circle" src="user-images/userDefault.png" width="24" height="24" alt="" />
                                        :
                                        <img className="img-circle" src={Constants.IMAGE_URL + this.props.property_detail.o_image} width="24" height="24" alt="" />
                                        }

                                        <span className="ml10">
                                            {!this.props.property_detail.o_fname ? <span> No Occupant </span> :this.props.property_detail.o_fname}
                                            &nbsp;{this.props.o_lname}
                                        </span>
                                        <Icon icon={clockHistory} className="ml10" style={{color:'FF9966', verticalAlign:"middle" }} />
                                        <span className="colorFF9966 fontInter fontWeight500 fontSize11 ml5">Occupant Pending</span>
                                        </span>
                                    </div>
                            </div>
                            </div>
                    }

                    /* Occupant Linked But Seller Not Linked  */

                    if (this.props.property_detail.user_id_occupant !== null && this.props.property_detail.isAgentApprovedFromOccupant === "1" && this.props.property_detail.user_id_seller === null && this.props.property_detail.isAgentApproved === "0") {

                        sellerLinkedMessage =

                            <div>
                                <div className="row">
                                    <div className="col-md-12 col-lg-12 col-xs-12">
                                        <span className="color0F1D30 fontSize13 fontWeight600 fontInter">
                                            {
                                                !this.props.property_detail.agent_image ?
                                                <img className="img-circle" src="user-images/userDefault.png" width="24" height="24" alt="" />
                                                :
                                                <img className="img-circle" src={Constants.IMAGE_URL + this.props.property_detail.agent_image} width="24" height="24" alt="" />
                                            }
                                        <span className="ml10">
                                                { this.props.property_detail.agent_fname }&nbsp;{ this.props.property_detail.agent_lname }
                                        </span>
                                        <img className="img-circle ml10" src="images/right-sign.png" width="20" height="20" alt="" />
                                        <span className="color4AA49C fontInter fontWeight500 fontSize11 ml5">Agent Linked</span>
                                        </span>
                                    </div>
                                    <div className="col-md-4 col-lg-4 col-xs-4">
                                    </div>
                                </div>
                                <div className="row mt15">
                                    <div className="col-md-12 col-lg-12 col-xs-12">
                                        <span className = "color0F1D30 fontSize13 fontWeight600 fontInter">
                                        {
                                        !this.props.property_detail.seller_image ?
                                        <img className="img-circle" src="user-images/userDefault.png" width="24" height="24" alt="" />
                                        :
                                        <img className="img-circle" src={Constants.IMAGE_URL + this.props.property_detail.seller_image} width="24" height="24" alt="" />
                                        }

                                        <span className="ml10">
                                            {Number(this.props.property_detail.property_type) === Number(0)?
                                                !this.props.property_detail.seller_fname ? <span> No Owner </span> :this.props.property_detail.seller_fname
                                            :
                                                !this.props.property_detail.seller_fname ? <span> No Seller </span> :this.props.property_detail.seller_fname
                                            }
                                            &nbsp;{this.props.seller_lname}
                                        </span>
                                        <Icon icon={clockHistory} className="ml10" style={{color:'FF9966', verticalAlign:"middle" }} />
                                        {Number(this.props.property_detail.property_type) === Number(0)?
                                            <span className="colorFF9966 fontInter fontWeight500 fontSize11 ml5">Owner Pending</span>
                                            :
                                            <span className="colorFF9966 fontInter fontWeight500 fontSize11 ml5">Seller Pending</span>
                                        }
                                        </span>
                                    </div>
                                </div>
                                <div className="row mt15">
                                    <div className="col-md-12 col-lg-12 col-xs-12">
                                        <span className="color0F1D30 fontSize13 fontWeight600 fontInter">
                                            {
                                                !this.props.property_detail.o_image ?
                                                <img className="img-circle" src="user-images/userDefault.png" width="24" height="24" alt="" />
                                                :
                                                <img className="img-circle" src={Constants.IMAGE_URL + this.props.property_detail.o_image} width="24" height="24" alt="" />
                                            }
                                            <span className="ml10">{ this.props.property_detail.o_fname }&nbsp;{ this.props.property_detail.o_lname} </span>
                                            <img className="img-circle ml10" src="images/right-sign.png" width="20" height="20" alt="" />
                                            <span className="color4AA49C fontInter fontWeight500 fontSize11 ml5">Occupant Linked</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                    }


                    /* Occupant Link Pending & Seller Not Linked  */

                    if (this.props.property_detail.user_id_occupant !== null && this.props.property_detail.isAgentApprovedFromOccupant === "0" && this.props.property_detail.user_id_seller === null && this.props.property_detail.isAgentApproved === "0") {

                        sellerLinkedMessage =
                            <div>
                                <div className="row">
                                    <div className="col-md-12 col-lg-12 col-xs-12">
                                        <span className="color0F1D30 fontSize13 fontWeight600 fontInter">
                                            {
                                                !this.props.property_detail.agent_image ?
                                                <img className="img-circle" src="user-images/userDefault.png" width="24" height="24" alt="" />
                                                :
                                                <img className="img-circle" src={Constants.IMAGE_URL + this.props.property_detail.agent_image} width="24" height="24" alt="" />
                                            }
                                        <span className="ml10">
                                                { this.props.property_detail.agent_fname }&nbsp;{ this.props.property_detail.agent_lname }
                                        </span>
                                        <img className="img-circle ml10" src="images/right-sign.png" width="20" height="20" alt="" />
                                        <span className="color4AA49C fontInter fontWeight500 fontSize11 ml5">Agent Linked</span>
                                        </span>
                                    </div>
                                    <div className="col-md-4 col-lg-4 col-xs-4">
                                    </div>
                                </div>

                                <div className="row mt15">
                                    <div className="col-md-12 col-lg-12 col-xs-12">
                                        <span className = "color0F1D30 fontSize13 fontWeight600 fontInter">
                                        {
                                        !this.props.property_detail.seller_image ?
                                        <img className="img-circle" src="user-images/userDefault.png" width="24" height="24" alt="" />
                                        :
                                        <img className="img-circle" src={Constants.IMAGE_URL + this.props.property_detail.seller_image} width="24" height="24" alt="" />
                                        }

                                        <span className="ml10">
                                            {Number(this.props.property_detail.property_type) === Number(0)?
                                                !this.props.property_detail.seller_fname ? <span> No Owner </span> : this.props.property_detail.seller_fname
                                                :
                                                !this.props.property_detail.seller_fname ? <span> No Seller </span> : this.props.property_detail.seller_fname
                                            }
                                            &nbsp;{this.props.seller_lname}
                                        </span>
                                        <Icon icon={clockHistory} className="ml10" style={{color:'FF9966', verticalAlign:"middle" }} />
                                        <span className="colorFF9966 fontInter fontWeight500 fontSize11 ml5">Seller Pending</span>
                                        </span>
                                    </div>
                                </div>

                                <div className="row mt15">
                                    <div className="col-md-12 col-lg-12 col-xs-12">
                                        <span className = "color0F1D30 fontSize13 fontWeight600 fontInter">
                                        {
                                        !this.props.property_detail.seller_image ?
                                            <img className="img-circle" src="user-images/userDefault.png" width="24" height="24" alt="" />
                                            :
                                            <img className="img-circle" src={Constants.IMAGE_URL + this.props.property_detail.seller_image} width="24" height="24" alt="" />
                                        }
                                        <span className="ml10">
                                            {!this.props.property_detail.seller_fname ? <span> No Occupant</span> : this.props.property_detail.seller_fname}
                                            &nbsp;
                                            {this.props.property_detail.seller_lname}
                                        </span>
                                        <i class="fa fa-clock-o ml10" aria-hidden="true" style={{ color: '#FFB92E', fontSize: '20px' }} width="20" height="20" ></i>
                                        <span className="colorFFB92E fontInter fontWeight500 fontSize11 ml5">Occupant Link Pending</span>
                                        </span>
                                    </div>
                                </div>

                            </div>
                    }



                }  /* If Condition For Seller Is Not an Occupant Closed Here */






            }  /* Main Else Closed Here  */
        }

        return sellerLinkedMessage;

    }

    render() {
        return (<div>
            {this.sellerLinkeMessageFun()}
        </div>)
    }
}

export default SellerLinkedMessage;


