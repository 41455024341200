import React ,  { Component } from 'react';

class Loader extends Component{

    render(){
        return(
            <div>
                <center>
                  <img src="images/loader.svg" width="50" height="50" alt="" />
                </center>
            </div>
        )
    }
}

export default Loader;