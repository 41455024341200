import React,{ Component } from "react";
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import Loader from '../../Loader';
import modal from '../../../CustomFiles/js/modal';

//import ReactTooltip from 'react-tooltip';
import {Checkbox} from 'primereact/checkbox';
import { Icon } from '@iconify/react';
import bxCalendarEvent from '@iconify/icons-bx/bx-calendar-event';

let items = [];

class PropertyList extends Component{
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            selected_property_id: null,
            selected_property_address: null,
            selected_stock_pipeline_name: null,
            selected_stockpipeline_id: null,
        }

        this.handleSelectProperty = this.handleSelectProperty.bind(this);
        this.handleSelectStockPipelineProperty = this.handleSelectStockPipelineProperty.bind(this);
        this.handleApplyProperty = this.handleApplyProperty.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }
    
    //clear modal data
    closeModal = async() => {
        window.$("#forSale_appointment_tab").trigger("click");
        await this.props.reset_stockpipeline_data()
        this.setState({
            selected_property_id: null,
            selected_property_address: null,
            selected_stock_pipeline_id: null,
            selected_stockpipeline_address: null,
            page: 1,
        });
    }
    
    componentDidMount() {
        modal();
        this.props.resetPropertySelect(this.closeModal);
    }
    
    get_userAuth = async (event) => {
        var token = localStorage.getItem('token');
        if(token && event.target.value){
            this.setState({address: ""});
            this.setState({display_card: "none"})
            await this.props.get_auth_data(event.target.value,token);
            this.props.get_all_agent_data_dd(this.props.authtoken)
            this.setState({display_card: "block"})
        }
    }
    
    createSelectItems = () => {
        items = [];
        if(this.props.agent_list){
            for (let i = 0; i < this.props.agent_list.length; i++) { 
                if(Number(this.props.agent_id) === Number(this.props.agent_list[i].userid)){
                    items.push(<option key={i} selected value={this.props.agent_list[i].userid}>{this.props.agent_list[i].firstname} {this.props.agent_list[i].lastname}</option>);   
                }else{
                    items.push(<option key={i} value={this.props.agent_list[i].userid}>{this.props.agent_list[i].firstname} {this.props.agent_list[i].lastname}</option>);   
                }
            }
           
            return items;
        }
    }    
    
    handleSelectProperty = (id, address) =>{
        if(this.state.selected_property_id === id){
            this.setState({
                selected_property_id : null,
                selected_property_address: null,
                selected_stock_pipeline_id : null,
                selected_stockpipeline_address: null
            }) 
        }else{
            this.setState({
                selected_property_id : id,
                selected_property_address: address,
                selected_stock_pipeline_id : null,
                selected_stockpipeline_address: null
            }) 
        }
    }
    
    handleSelectStockPipelineProperty = (id, address)=>{
        if(this.state.selected_stock_pipeline_id === id){
            this.setState({
                selected_property_id: null,
                selected_property_address: null,
                selected_stock_pipeline_id : null,
                selected_stockpipeline_address: null
            })
        }else{
            this.setState({
                selected_property_id: null,
                selected_property_address: null,
                selected_stock_pipeline_id : id,
                selected_stockpipeline_address: address
            })
        }
    } 

    handleApplyProperty = async () => {
        this.props.propertyCallback(this.state.selected_property_id, this.state.selected_property_address, this.state.selected_stock_pipeline_id, this.state.selected_stockpipeline_address)
        this.props.reset_stockpipeline_data()
    }

    renderMoreStockPipeline = () =>{
        let newpage = Number(this.state.page) + 1;
        this.setState({page: newpage}, () =>{
            let is_archived='0'
            this.props.get_stock_pipeline_data(this.state.page, is_archived, null, this.props.authtoken);
        })
    }

    render(){
        if(this.state.edit_address_1 === null && this.props.address){
            let len = this.props.address;
            len = len.length;
            this.setState({edit_full_address_length: len});
            this.setState({edit_address_1: this.props.address},() => {
                window.$("#edit_address_1").val(this.props.address)
            });
        }
     
        return(
            <div>
                <div className="modal fade" data-backdrop="static" data-keyboard="false" id="propertyListModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content modal_property_list modalheight" style ={{borderRadius : '12px' }} >
                            <div className="modal-header" style={{ backgroundColor: "#EDF6F6"}}>
                                <h4 className="modal-title fontWeight600"> <Icon className="message_icon color27AFAF mr5" style={{verticalAlign: 'bottom'}} icon={bxCalendarEvent} height='25' width='25' /> Select Property List</h4>
                                <div id="closeModal_property_list" className="circle" data-dismiss="modal" onClick={this.handleApplyProperty}>
                                    <img src="images/modal-close.png " width="12" height="12" alt=""/>
                                </div> 
                            </div>
                            
                            <div className="modal-body">
                                {/*Property List  */}
                                <ul className="nav nav-tabs property-list">
                                    {this.props.property_for_sale ?
                                        <li style={{ width: '33.33%' }} className="active fontInter">
                                            <a data-toggle="tab" id="forSale_appointment_tab" href="#forSale_appointment">
                                                For Sale 
                                                <br />
                                                <span className="fontSize12 fontWeightNormal fontInter"><b>{this.props.property_for_sale.length}</b>&nbsp;{this.props.property_for_sale.length === 1 ? "Property" : "Properties" }</span>
                                            </a>
                                        </li>
                                    : null
                                    }
                                    {this.props.property_for_rent ?
                                        <li style={{ width: '33.33%' }}>
                                            <a data-toggle="tab" id="forRent_appointment_tab" href="#forRent_appointment">For Rent
                                                <br />
                                                <span className="fontSize12 fontWeightNormal fontInter"><b>{this.props.property_for_rent.length}</b>&nbsp; {this.props.property_for_rent.length === 1 ? "Property" : "Properties"}</span>
                                            </a>
                                        </li>
                                    : null
                                    }
                                    {this.props.stock_pipeline ?
                                        <li style={{ width: '33.33%' }}>
                                        <a data-toggle="tab" id="stockpipelinetab" href="#stockpipeline">Stock Pipeline
                                            <br />
                                            <span className="fontSize12 fontWeightNormal fontInter"><b>{this.props.stock_pipeline.length}</b>&nbsp; {this.props.stock_pipeline.length === 1 ? "Property" : "Properties"}</span>
                                        </a>
                                        </li>
                                    : null
                                    }
                                </ul>

                                {/* Tab Content Starts */}
                                <div className="tab-content property-list" style={{ marginTop: '40px', overflow: 'auto', fontSize: '16px'}}>
                                    <div id="forSale_appointment" className="tab-pane fade in active">
                                        <div className="ml5">
                                            {/*for sale List type  */}
                                            {this.props.property_for_sale ?
                                                this.props.property_for_sale.length > 0 ?
                                                    this.props.property_for_sale.map((data, index) => (
                                                    <div key={index}>
                                                        <Checkbox className ="mt5" style={{float:'left'}} inputId="my_listing_1" name="my_listing" checked={Number(this.state.selected_property_id) === Number(data.propid) ? true: false} value={data.propid} onChange={() => this.handleSelectProperty(data.propid,data.address)}></Checkbox> &nbsp;&nbsp; 
                                                        <div style={{display: "inline-block", width: "92%"}}>
                                                            <div className="property-col property-details" style={{ border: '2p solid red' }}>
                                                                <div className="property-text fontSize13 fontInter" style={{ width: '60%', color: '#0F1D30' }}>
                                                                    <span>{data.address}</span> 
                                                                    {Number(this.props.agent_data.pro_user) === Number(1) ?
                                                                        Number(data.crm_verified) === Number(1) ? 
                                                                            <img tag="allowRowEvents" alt='' width="20" height="20" style={{verticalAlign: "text-bottom", marginLeft: "5px"}} src={"images/crm_verified.svg"}/>
                                                                        :
                                                                            <img tag="allowRowEvents" alt='' width="20" height="20" style={{verticalAlign: "text-bottom", marginLeft: "5px"}} src={"images/crm_not_verified.svg"}/>
                                                                        : null
                                                                        }
                                                                    <div>
                                                                    <div className="unit_name"> {data.unit_name !== "" ?  data.unit_name : null} </div> 
                                                                    {data.date_sold ? <span className="soldspan" style={{marginLeft:"0px"}}>Sold</span> : null} 
                                                                    {Number(data.is_office_listing) === 1 ? <span className="ml5 office_listing_span"> Office Listing </span> : null}
                                                                    {Number(data.is_open_listing) === 1 ? <span className="ml5 open_listing_span"> Other Listing </span> : null}
                                                                    </div>
                                                                </div>
                                                                <div className="fontSize13 fontInter" style={{ width: '25%', marginLeft: '13px' }}>
                                                                    {Number(data.is_seller_occupant) === Number(1) ?
                                                                        Number(data.isAgentApproved) === Number(1) ?
                                                                            <div>
                                                                            <img className="img-circle" src=" images/right-sign.png " width="13.33" height="13.33" alt="" />
                                                                            <span className="color4AA49C fontSize11 fontWeight600 fontInter ml5" >Seller Linked</span>
                                                                            </div>
                                                                        : 
                                                                            null
                                                                    : 
                                                                        <>
                                                                        {Number(data.isAgentApproved) === Number(1) ?
                                                                            <div>
                                                                            <img className="img-circle" src=" images/right-sign.png " width="13.33" height="13.33" alt="" />
                                                                            <span className="color4AA49C fontSize11 fontWeight600 fontInter ml5" >Seller Linked</span>
                                                                            </div>
                                                                        : 
                                                                            null
                                                                        }
                                                                        {Number(data.isAgentApprovedFromOccupant) === Number(1) ?  
                                                                        <div>
                                                                            <img className="img-circle" src=" images/right-sign.png " width="13.33" height="13.33" alt="" />
                                                                            <span className="color4AA49C fontSize11 fontWeight600 fontInter ml5">Occupant Linked</span>
                                                                        </div>  
                                                                        :
                                                                            null
                                                                        }
                                                                        </>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </div>
                                                    )) 
                                                : <center><h4>No Data Available To Show</h4></center>
                                            : <center><h4>No Data Available To Show</h4></center> }
                                        </div>
                                    </div>

                                    {/* Second Tab For Rent Starts  */}
                                    <div id="forRent_appointment" className="tab-pane fade">
                                        <div className="ml5">
                                            {/*for rent List type  */}
                                            {this.props.property_for_rent ?
                                                this.props.property_for_rent.length > 0 ?
                                                    this.props.property_for_rent.map((data, index) => (
                                                    <div key={index}>
                                                        <Checkbox className ="mt5" style={{float:'left'}} inputId="my_listing_1" name="my_listing" checked={Number(this.state.selected_property_id) === Number(data.propid) ? true: false} value={data.propid} onChange={() => this.handleSelectProperty(data.propid,data.address)}></Checkbox> &nbsp;&nbsp; 
                                                        <div style={{display: "inline-block", width: "92%"}}>
                                                            <div className="property-col property-details" style={{ border: '2p solid red' }}>
                                                                <div className="property-text fontSize13 fontInter" style={{ width: '60%', color: '#0F1D30' }}>
                                                                    <span>{data.address}</span> 
                                                                    {Number(this.props.agent_data.pro_user) === Number(1) ?
                                                                    Number(data.crm_verified) === Number(1) ? 
                                                                        <img tag="allowRowEvents" alt='' width="20" height="20" style={{verticalAlign: "text-bottom", marginLeft: "5px"}} src={"images/crm_verified.svg"}/>
                                                                    :
                                                                        <img tag="allowRowEvents" alt='' width="20" height="20" style={{verticalAlign: "text-bottom", marginLeft: "5px"}} src={"images/crm_not_verified.svg"}/>
                                                                    :
                                                                    null
                                                                    }
                                                                    <div>
                                                                    <div className="unit_name"> {data.unit_name !== "" ?  data.unit_name : null} </div> 
                                                                    {data.date_sold ? <span className="soldspan" style={{marginLeft:"0px"}}>Rented</span> : null}
                                                                    {Number(data.is_office_listing) === 1 ? <span className="ml5 office_listing_span"> Office Listing </span> : null}
                                                                    {Number(data.is_open_listing) === 1 ? <span className="ml5 open_listing_span"> Other Listing </span> : null}
                                                                    </div>
                                                                </div>
                                                                <div className="fontSize13 fontInter" style={{ width: '25%', marginLeft: '13px' }}>
                                                                    {Number(data.is_seller_occupant) === Number(1) ?
                                                                        Number(data.isAgentApproved) === Number(1) ?
                                                                            <div>
                                                                            <img className="img-circle" src=" images/right-sign.png " width="13.33" height="13.33" alt="" />
                                                                            <span className="color4AA49C fontSize11 fontWeight600 fontInter ml5" >Owner Linked</span>
                                                                            </div>
                                                                        : 
                                                                            null
                                                                    : 
                                                                        <>
                                                                            {Number(data.isAgentApproved) === Number(1) ?
                                                                                <div>
                                                                                <img className="img-circle" src=" images/right-sign.png " width="13.33" height="13.33" alt="" />
                                                                                <span className="color4AA49C fontSize11 fontWeight600 fontInter ml5" >Owner Linked</span>
                                                                                </div>
                                                                            : 
                                                                                null
                                                                                
                                                                            }
                                                                            {Number(data.isAgentApprovedFromOccupant) === Number(1) ?  
                                                                            <div>
                                                                                <img className="img-circle" src=" images/right-sign.png " width="13.33" height="13.33" alt="" />
                                                                                <span className="color4AA49C fontSize11 fontWeight600 fontInter ml5">Occupant Linked</span>
                                                                            </div>  
                                                                            :
                                                                            null
                                                                            
                                                                            }
                                                                        </>
                                                                    }
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </div>
                                                    )) 
                                                : <center><h4>No Data Available To Show</h4></center>
                                            : <center><h4>No Data Available To Show</h4></center> }
                                        </div>
                                    </div>

                                    {/* Third Tab For Stock Pipeline Starts  */}
                                    <div id="stockpipeline" className="tab-pane fade">
                                        <div className="ml5">
                                            {/*Stock pipeline List type  */}
                                            {this.props.stock_pipeline ?
                                                this.props.stock_pipeline.map((data) => (
                                                    <div key={data.id}>
                                                        <div id={data.id} className='row padTop15 padBottom15' style={{marginLeft: "5px", marginRight: 0}}>
                                                            <Checkbox style={{float:'left'}} inputId="my_listing_1" name="my_listing" checked={Number(this.state.selected_stock_pipeline_id) === Number(data.id) ? true: false} value={data.id} onChange={() => this.handleSelectStockPipelineProperty(data.id,data.address)}></Checkbox> &nbsp;&nbsp; 
                                                            <div className="col-md-12" style={{display:'inline-block', width:"93%"}}>
                                                                <p className="cold_call_header_date" style={{float:'left', paddingLeft:15, marginBottom:5 }}>{data.address}</p>
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <span className="cold_call_header" style={{float:'left', paddingLeft:15}}>{data.unit_no}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                        <hr style={{margin:"0px"}}/>
                                                    </div>
                                                ))
                                                : this.props.loader ?
                                                <Loader/> : null
                                            }
                
                                            {Array.isArray(this.props.stock_pipeline) && this.props.stock_pipeline.length ?
                                                this.props.stock_load_more ?
                                                    <center>
                                                        <button type="button" class="btn btn-info fontWeight700" style={{backgroundColor: "#5FC8BF", marginTop: 20}} onClick={this.renderMoreStockPipeline}>Load More</button>
                                                    </center>
                                                    : null
                                                :   
                                                    <center><br/><br/><h4>No Stock Pipeline Properties to Show</h4></center>
                                            }
                                        </div>
                                    </div>
                                </div>
                                
                                <br/><br/>
                                <center>
                                <div data-dismiss="modal" style={{textAlign: "center"}}>
                                    <button id="selectContact" type="button" className="btn btn-primary fontBold" style={{ background:'#4AA49C',borderRadius:'4px'}} onClick={this.handleApplyProperty}> Select </button>
                                </div>
                                </center>
                                <br/>
                               
                            </div>   
                        </div> 
                    </div>
                </div>                
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        reset_stockpipeline_data:() => dispatch(actions.reset_stockpipeline_data()),
        get_stock_pipeline_data : (page, is_archived, stock_pipeline_id, token) => dispatch(actions.get_stock_pipeline_data(page, is_archived, stock_pipeline_id, token)),
    }
  }
  
const mapStateToProps = state => {
    return{
        agent_data: state.agent_modal.agent_data,
        //property list
        property_for_sale: state.agent_modal.property_for_sale,
        property_for_rent: state.agent_modal.property_for_rent,
        property_for_sale_total: state.agent_modal.property_for_sale_total,
        property_for_rent_total: state.agent_modal.property_for_rent_total,
        
        //stock pipeline list
        //agent tab
        stock_pipeline: state.agent_modal.stock_pipeline,
        stock_load_more: state.agent_modal.stock_load_more,
        //agent mobile authtoken
        authtoken: state.add_property_modal.authtoken,
        
        stockpipeline_labels_by_id: state.agent_modal.stockpipeline_labels_by_id,
        stockpipeline_count_by_id: state.agent_modal.stockpipeline_count_by_id,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PropertyList);
