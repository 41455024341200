import * as actionTypes from './actionsTypes';
import Constants from '../../constants/Constants';
import axios from 'axios';

var myHeaders = '';
var requestOptions = '';

export const get_auth_data = (agent_id, token) => {
    return async dispatch =>{
        await axios.post(Constants.GET_AUTH_DATA,{
            agent_id: agent_id},
            {headers: {
                Authorization: token 
            }
        })
        .then(data =>  {
            dispatch(auth_data(data.data.data));
        })
        .catch(error => console.log('error', error));
    }
}
export const auth_data = (data)=>{
    return{
        type: actionTypes.GET_AUTH_DATA,
        authtoken: data.authtoken
    }
}

export const update_add_property_modal_state = (add_property_modal_state) => {
    return dispatch =>{
        dispatch({
            type: actionTypes.UPDATE_ADD_PROPERTY_MODAL_STATE,
            add_property_modal_state: add_property_modal_state
        })
    }
}

// function validateURL(textval) {
//     //eslint-disable-next-line
//     var pattern = new RegExp("^([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&amp;%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(\:[0-9]+)*(/($|[a-zA-Z0-9\.\,\?\'\\\+&amp;%\$#\=~_\-]+))*$");
//     return !!pattern.test(textval);
// }
export const submitFail = (error) => {
    return {
        type: actionTypes.SUBMIT_FAIL,
        property_form_error: error.msg
    };
}
export const add_property_data = (authtoken,address,lat,lng,external_link,hide_map,sale_type,price,is_office_listing,is_open_listing,reside,empty_land,crm_id,timezone) => {
    return async dispatch =>{
        let error=[];
        var decimal = /[-+][0-9]+\.[0-9]+$/;

        // if(external_link){
        //     if(validateURL(external_link.trim())) {
        //         error.msg = "External link is not Valid";
        //        return dispatch(submitFail(error));
        //     }
        // }

        if((!authtoken) || (!address) || (!lat) || (!lng) || (sale_type === null) || (reside === null) || (!price)){
            if(!authtoken){
                error.msg = "Please select the Agent";
                dispatch(submitFail(error));
            }else if(!address){
                error.msg = "Please enter the full address";
                dispatch(submitFail(error));
            }else if(!lat || !lng ){ 
                error.msg = "Please select the address from the Map";
                dispatch(submitFail(error));
            }else if(sale_type === null){
                error.msg = "Please select Property Type";
                dispatch(submitFail(error));
            }else if(reside === null){
                error.msg = "Please select Does the owner reside in this property";
                dispatch(submitFail(error));
            }else if(!price){
                error.msg = "Please enter price";
                return dispatch(submitFail(error));
            }else if(!price.match(decimal)) {
                error.msg = "Please enter price in number";
                dispatch(submitFail(error));
            }
        }else if(authtoken || address || lat || lng || sale_type || reside){
            myHeaders = new Headers();
            myHeaders.append("authtoken", authtoken);
            myHeaders.append("timezone", timezone);
            
            var raws = new FormData();
            raws.append("address", address.trim());
            raws.append("lat", lat);
            raws.append("lon", lng);
            //raws.append("unit_name", unit.trim());
            raws.append("external_url", external_link.trim());
            raws.append("is_hidePin", hide_map);
            raws.append("property_type", sale_type);
            raws.append("asking_price", price.trim());
            raws.append("is_office_listing", is_office_listing);
            raws.append("is_open_listing", is_open_listing);
            raws.append("is_seller_occupant", reside);
            raws.append("crm_id",crm_id);
    
            requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raws,
                redirect: 'follow'
            };

            error.msg = "";
            dispatch(submitFail(error));
            await fetch(Constants.ADD_PROPERTY, requestOptions)
            .then(response => response.json())
            .then(data => {
                dispatch(add_property(data, sale_type, reside, empty_land));
            })
            .catch(error => console.log('error', error));
        }
    }
}
export const add_property = (data, sale_type, reside, empty_land)=>{
    return {
        type: actionTypes.ADD_PROPERTY,
        add_property_success: data.success,
        share_code: data.data.codes[1],
        share_code_occupant: Number(reside) === 0 ? data.data.codes[2] : null,
        seller: sale_type,
        reside: reside,
        empty_land: empty_land
    }
}

export const edit_property_data = (authtoken,prop_id,address,lat,lng,external_link,hide_map,sale_type,price,is_office_listing,is_open_listing,reside,crm_id,timezone) => {
    return async dispatch =>{
        let error=[];
        if(!price){
            error.msg = "Please enter price";
           return dispatch(submitFail(error));
        }else{
            //var decimal = /[-+][0-9]+\.[0-9]+$/; 
            var decimal = /[0-9]+([,\.][0-9]+)?/; // eslint-disable-line no-useless-escape
            if(!price.match(decimal)) { 
                error.msg = "Please enter price in number";
                return dispatch(submitFail(error));
            }
        }

        // if(external_link){
        //     if(validateURL(external_link.trim())) {
        //         error.msg = "External link is not Valid";
        //        return dispatch(submitFail(error));
        //     }
        // }
        
        if((!authtoken) || (!address) || (!lat) || (!lng) || (!sale_type)){
            if(!authtoken){
                error.msg = "Please select the Agent";
                dispatch(submitFail(error));
            }else if(!address){ 
                error.msg = "Please enter the full address";
                dispatch(submitFail(error));
            }else if(!lat || !lng){
                error.msg = "Please select the address from the Map";
                dispatch(submitFail(error));
            }else if(!sale_type){
                error.msg = "Please select Property Type";
                dispatch(submitFail(error));
            }
        }else if(authtoken || address || lat || lng || sale_type || reside){
            myHeaders = new Headers();
            myHeaders.append("authtoken", authtoken);
            myHeaders.append("timezone", timezone);
            
            var raws = new FormData();
            raws.append("id", prop_id);
            raws.append("address", address.trim());
            raws.append("lat", lat);
            raws.append("lon", lng);
            raws.append("external_url", external_link.trim());
            raws.append("is_hidePin", hide_map);
            raws.append("property_type", sale_type.toString());
            raws.append("asking_price", price.trim());
            raws.append("is_office_listing", is_office_listing);
            raws.append("is_open_listing", is_open_listing);
            raws.append("is_seller_occupant", reside);
            raws.append("crm_id", crm_id);

            requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raws,
                redirect: 'follow'
            };
            await fetch(Constants.EDIT_PROPERTY, requestOptions)
            .then(response => response.json())
            .then(data => {
                dispatch(edit_property(data));
            })
            .catch(error => console.log('error', error));
        }
        
    }
}
export const edit_property = (data)=>{
    return {
        type: actionTypes.EDIT_PROPERTY,
        edit_property_success: data.success,
    }
}

export const get_edit_property_data = (prop_id, authtoken) => {
    return async dispatch =>{
        myHeaders = new Headers();
        myHeaders.append("authtoken", authtoken);
        requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        
        await fetch(Constants.GET_EDIT_PROPERTY_DATA+"&id="+prop_id, requestOptions)
        .then(response => response.json())
        .then(data => {
            dispatch(get_edit_property(data.data));
        })
        .catch(error => console.log('error', error));
    }
}
export const get_edit_property = (data) =>{
    return {
        type: actionTypes.GET_EDIT_PROPERTY_DATA,
        prop_id: data.id,
        agent_id: data.user_id_agent,
        agent_name: data.agentname,
        address: data.address,
        address_google: data.address_google,
        external_url: data.external_url,
        lat: data.lat,
        lon: data.lon,
        unit: data.unit_name,
        hide_map: data.is_hidePin,
        sale_type: data.property_type,
        price: data.asking_price,
        reside: data.is_seller_occupant,
        sold_price: data.sold_price,
        currency_symbol: data.country_currency_symbol,
        crm_id: data.crm_id,
        sync_crm: data.sync_crm,
        share_code: data.codes,
        pro_user: data.agent_pro_user,
        timezone: data.agent_current_timezone,
        is_office_listing: data.is_office_listing,
        is_open_listing: data.is_open_listing,
    }
}

export const update_add_property_success_modal_state = () =>{
    return dispatch => {
        dispatch({
            type: actionTypes.UPDATE_ADD_PROPERTY_SUCCESS_MODAL_STATE,
            add_property_success: null,
            edit_property_success: null
        })
    }
}

export const reset_all_form_state = ()=>{
    return dispatch => {
        dispatch({
            type: actionTypes.RESET_ALL_FORM_STATE,
        })
    }
}

export const reset_property_modal_error = () =>{
    return dispatch => {
        dispatch({
            type: actionTypes.RESET_PROPERTY_MODAL_ERROR,
            add_property_success: null,
            edit_property_success: null
        })
    }
}

export const get_all_agent_data_dd = (token)=>{
    return dispatch => {
        var myHeaders = new Headers();
        //myHeaders.append("authtoken", "05268f888a85274863bd36df2fe855917eb65e3b");
        myHeaders.append("authtoken", token);
        //myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(Constants.GET_ALL_AGENT_DATA, requestOptions)
        .then(response => response.json())
        .then(data => {
            dispatch((all_agent_data(data)))
        })
        .catch(error => console.log('error', error));
    }
}
export const all_agent_data=(data)=>{;
    if(Number(data.success) !==  0){
        return{
            type: actionTypes.GET_ALL_AGENT_DATA,
            country_code: data.data.user.country_code,
            currency_symbol: data.data.user.currency_symbol,
            pro_user: data.data.user.pro_user,
            timezone: data.data.user.current_timezone,
        }
    }
}

export const toggleMapCheckboxChange=(hide_map)=>{
    return dispatch => {
        dispatch({
            type: actionTypes.TOGGLE_IS_MAP_HIDE,
            hide_map: hide_map
        })
    }
}