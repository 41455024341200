import * as actionTypes from '../actions/actionsTypes'; 
import {updateObject} from '../utility';

const initialState = {
    buyer_lead_data: null,
    add_buyer_lead_form_error: null,
    buyer_lead_load_more: false,
    buyer_lead_page: 1,
    user_buyer_lead_data: null,
    edit_buyer_lead_error: null,
    buyer_lead_form_status: null,
    buyer_lead_export: null

}
const get_buyer_lead = (state,action)=>{
    if(!state.buyer_lead_data || Number(action.buyer_lead_page) === 1){
        return updateObject(state,{
            buyer_lead_data: action.buyer_lead_data,
            buyer_lead_export: action.buyer_lead_export,
            buyer_lead_load_more: action.buyer_lead_load_more,
        })
    }else{
        return {
            ...state,
            buyer_lead_load_more: action.buyer_lead_load_more,
            buyer_lead_data: [...state.buyer_lead_data, ...action.buyer_lead_data],
            buyer_lead_export: action.buyer_lead_export,
        };
    }
}

const add_buyer_lead = (state, action) => {
    //return updateObject(state, {add_buyer_lead_form_error: 'success'})
    return updateObject(state, {add_buyer_lead_form_error: action.response})
}

const submit_fail = (state, action) => {
    return updateObject(state,{
        add_buyer_lead_form_error: action.add_buyer_lead_form_error
    })
}

const reset_buyer_lead_modal = (state) => {
    return updateObject(state, {add_buyer_lead_form_error: null})
}

const reset_buyer_lead = (state)=>{
    return updateObject(state, {
        buyer_lead_data: null,
        edit_buyer_lead_error: null,
        buyer_lead_form_status: null,
        buyer_lead_export: null
    })
}

const user_buyer_lead = (state, action)=>{
    return updateObject(state, {user_buyer_lead_data: action.user_buyer_lead_data})
}

const edit_buyer_lead = (state, action)=>{
    return updateObject(state, {
        edit_buyer_lead_error: action.buyer_lead_form_error,
        buyer_lead_form_status: action.buyer_lead_form_status
    })
}

function buyer_lead_reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.GET_BUYER_LEAD: return get_buyer_lead(state, action);
        case actionTypes.ADD_BUYER_LEAD: return add_buyer_lead(state, action);
        case actionTypes.BUYER_LEAD_SUBMIT_FAIL: return submit_fail(state,action);
        case actionTypes.RESET_BUYER_LEAD_MODAL: return reset_buyer_lead_modal(state)
        case actionTypes.RESET_BUYER_LEAD: return reset_buyer_lead(state)
        case actionTypes.GET_USER_BUYER_LEAD: return user_buyer_lead(state, action)
        case actionTypes.EDIT_BUYER_LEAD: return edit_buyer_lead(state, action)

        default:
        return state;
    }
}

export default buyer_lead_reducer;