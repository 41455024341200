import React,{ Component } from "react";
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import Swal from "sweetalert2";  
// import Constants from '../../constants/Constants';
import { Icon } from '@iconify/react';
import emailSend from '@iconify/icons-mdi/email-send';

class UpdateSubscriptionModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            subject: Number(this.props.header_choice) === 1 ? "I need to remove users from my account" : "I need to upgrade to pro subscription/move to standard subscription",
            body: "Please contact me.",
            display_send: 'block'
        }
        this.wrapperRef = React.createRef();
        this.setwrapperRef = React.createRef();
        this.submitHandler = this.submitHandler.bind(this);
        
        //this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    submitHandler = async (event) =>{
        event.preventDefault(); 
        this.setState({ 
            display_send: 'none'
        })
        var email='<div>'
        +'<p style={{fontSize:"20px"}}><strong>Ofiice Name:&nbsp;  </strong>'+ localStorage.getItem("office_name")+'</p>'
        +'<p style={{fontSize:"20px"}}><strong>Office Email:&nbsp;  </strong>'+ localStorage.getItem("office_email")+'</p>'
        +'<p style={{fontSize:"20px"}}><strong>Message:&nbsp;  </strong>' +this.state.body+'</p>'
        +'</div>';
        var subject = this.state.subject;
        await this.props.send_subscription_email(email, subject);

        if(this.props.send_email === Number(1)){
            Swal.fire(
                'Success!',
                'Email Send Successfully ',
                'success'
            )
            this.closeModal();
            window.$("#update_subscription_Modal").modal('hide');
        }else{
            Swal.fire(
                'Error!',
                'Something went wrong please try again',
                'error'
            )
            this.setState({ 
                display_send: 'block'
            })
        }
    }

    closeModal(){
        this.setState({
            subject: "I need to change the amount of agents on my account",
            body:"Please contact me so this can be changed.",
            display_send: 'block'
        })
    }

    // componentDidMount() {
    //     document.addEventListener('mousedown', this.handleClickOutside);
    // }

    // componentWillUnmount() {
    //     document.removeEventListener('mousedown', this.handleClickOutside);
    // }

    render(){
        return(
            <div>
                <div className="modal fade" id="update_subscription_Modal" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={this.setwrapperRef}>
                    <div className="modal-dialog" role="document" ref={this.wrapperRef}>

                        <div className="modal-content modalheight" style ={{borderRadius : '12px' }} >
                            <div className="modal-header" style={{ backgroundColor: "#EDF6F6"}}>
                                <h4 className="modal-title fontWeight600"> 
                                    {/* <Icon icon={bxsShareAlt} style={{color: '#27afaf', fontSize: '26px', verticalAlign:'bottom'}} /> &nbsp;&nbsp; */}
                                    {this.props.modalHeader}
                                </h4>
                                <div className="circle" data-dismiss="modal" onClick={this.props.closeModal}>
                                    <img src="images/modal-close.png " width="12" height="12" alt="" />
                                </div> 
                            </div>
                            <div className="modal-body">
                                <center>
                                <div className="shadow" style={{borderRadius:8, marginTop:30, marginBottom:30, width:'92%'}}>
                                    <h3 style={{paddingTop: 25}}>Email</h3>
                                    <form id="send_emailform" onSubmit={this.submitHandler} style={{paddingTop: 25}}>
                                        <div className='form_border' style={{width:'93%'}}>
                                            <div className='full-input' style={{marginTop: 10}}>
                                                <label className="label_modal">subject</label>
                                                <input className="input_modal" type='text' name='subject' id="subject" value={this.state.subject} onChange={(e) => {this.setState({subject: e.target.value })}} placeholder="I need to change the amount of agents on my account" required pattern=".*\S+.*" title="This field is required"></input>
                                            </div>
                                            <br/>
                                            <div className='full-input' style={{border: 'none'}}>
                                                <label className="label_modal">Body</label>
                                                <textarea className="input_modal" type='text' name='body' id="body" rows="5" placeholder="Please Enter your message" value={this.state.body} onChange={(e) => {this.setState({body: e.target.value })}} style={{resize: 'none'}} required pattern=".*\S+.*" >
                                                    
                                                </textarea>
                                            </div>
                                        </div>
                                        <br/>
                                        <div style={{paddingBottom:30}}>
                                            <button type="submit" className="btn btn-primary fontBold" style={{ background:'#4AA49C',borderRadius:'4px', padding:10, display: this.state.display_send }}>
                                                <Icon icon={emailSend} style={{fontSize:'20px', verticalAlign:'bottom'}}/>&nbsp;&nbsp;
                                                Email to Roundr
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapDispatchToProps = dispatch => {
    return {
        send_subscription_email: (email, subject) => dispatch(actions.send_subscription_email(email, subject)),
    }
}
const mapStateToProps = state => {
    return{
        seller: state.add_property_modal.seller,
        add_property_success: state.add_property_modal.add_property_success,
        edit_property_success: state.add_property_modal.edit_property_success,
        share_code: state.add_property_modal.share_code,
        share_code_occupant: state.add_property_modal.share_code_occupant,
        send_email: state.transaction_log.send_email
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UpdateSubscriptionModal);
