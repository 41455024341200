import React,{ Component } from "react";
//import ReactDOM from 'react-dom';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import Loader from '../Loader';
import ErrorText from '../../auth/ErrorText';
import SuccessText from '../../auth/SuccessText';

import { Calendar } from 'primereact/calendar';
import { Icon } from '@iconify/react';
import receiptIcon from '@iconify/icons-bi/receipt';
import Swal from 'sweetalert2';

//let items = [];

class MarkAsSold extends Component{
    constructor(props) {
        super(props);
        this.state = {
            startDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
            endDate:   new Date((new Date().getFullYear()+1), new Date().getMonth(), new Date().getDate()),
            CalenderMinDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
            sold_price: null,
            commission: null,
            flat_rate: null,
            loading: false,
        }
        this.submitHandler = this.submitHandler.bind(this);
        this.changeDateFormat = this.changeDateFormat.bind(this);
        this.closeMarkAsSoldModal = this.closeMarkAsSoldModal.bind(this);
        this.onInputchange = this.onInputchange.bind(this);
        this.handleCommission = this.handleCommission.bind(this);
        this.handleFlatRate = this.handleFlatRate.bind(this);
    }

    opensweetalert(){
        Swal.fire({
            icon: 'success',
            title: 'Mark as Sold successfully ',
            showConfirmButton: true,
        })
    }

    //handle add property button
    async submitHandler(event){
        event.preventDefault(); 
        this.setState({loading: true});
        let date = null;
        if(this.state.startDate){
            date = this.changeDateFormat(this.state.startDate);
        }
        const token = localStorage.getItem('token');
        await this.props.get_auth_data(this.props.property_detail.agent_id,token);
        let sold_price = this.state.sold_price ? this.state.sold_price : this.props.property_detail.price;
        let commission = this.state.commission ? this.state.commission : 0;
        await this.props.add_mark_as_sold(this.props.authtoken, this.props.property_detail.id, sold_price, commission, date);
        
        if(this.props.mark_as_sold_form_error === 'success') {
            // var fromDate = new Date("1/1/" + (new Date()).getFullYear());
            // var toDate = new Date(new Date().getFullYear(), 11, 31, 0, 0, 0);
            // fromDate = this.changeDateFormat(fromDate);
            // toDate = this.changeDateFormat(toDate);
            const token = localStorage.getItem('token');
            var d = new Date();
            var fromDate = this.changeDateFormat(new Date(d.getFullYear(), d.getMonth() - 2, 1));
            var toDate = this.changeDateFormat(new Date(d.getFullYear(), d.getMonth(), d.getDate()));
            this.opensweetalert();
            
            if(Number(this.props.fromnetworking) === Number(1)){
                this.props.set_page("property_tab", true);
                this.props.get_networking_data(this.props.agent_id, token);
                this.props.get_property_data_modal(fromDate, toDate, this.props.property_detail.id, this.props.property_detail.agent_id, token, this.props.property_detail.date_sold);
            }else{
                this.props.get_property_data_modal(fromDate, toDate, this.props.property_detail.id, this.props.property_detail.agent_id, token, this.props.property_detail.date_sold);
                let viewings_sort = null;
                let recent_property = 'desc';
                //this.props.set_page("property_tab", true);
                this.props.set_page("property_modal", true);
                this.props.get_property_data(fromDate, toDate, this.props.property_detail.agent_id, token, viewings_sort, recent_property);
            }
            if(window.location.pathname === "/properties"){
                date = new Date();
                fromDate =  this.changeDateFormat(new Date("1/12/2018"));
                toDate = this.changeDateFormat(new Date(date.getFullYear(), date.getMonth(), date.getDate()));
                await this.props.get_property_list_date(this.props.data_table_filter_by, fromDate, toDate, token);
            }
            this.closeMarkAsSoldModal();
            window.$("#markAsSoldModal").modal('hide');
        }
        this.setState({loading: false});
    }

    changeDateFormat(fromDate) {
        var d = new Date(fromDate),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
    
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [year, month, day].join('-');
    }

    //clear modal data
    closeMarkAsSoldModal(){
        this.setState({
            sold_price: null,
            commission: null,
            flat_rate: null,
            startDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
            endDate:   new Date((new Date().getFullYear()+1), new Date().getMonth(), new Date().getDate()),
            CalenderMinDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
            loading: false
        })
        this.props.reset_mark_as_sold_modal();
    }
            
    onInputchange(event) {
        var commissionFlatRate = ((this.state.commission * event.target.value) / 100)
        this.setState({
            [event.target.name]: event.target.value,
            flat_rate: parseFloat(commissionFlatRate)
        });
    }
    
    handleCommission(event){
        let sold_price = this.state.sold_price
        if(!sold_price){
            sold_price = this.props.property_detail.price;
        }
        let commissionFlat = ((event.target.value * sold_price) / 100)
        let is_nan = isNaN(parseFloat(commissionFlat));
        if(is_nan || event.target.value === ""){
            this.setState({
                commission: parseFloat(0),
                flat_rate: parseFloat(0)
            })
        }else{
            this.setState({
                commission: event.target.value,
                flat_rate: parseFloat(commissionFlat)
            })
        }

    }
    handleFlatRate(event){
        let sold_price = this.state.sold_price
        if(!sold_price){
            sold_price = this.props.property_detail.price;
        }
        var commissionPercent = ((event.target.value * 100) / sold_price)

        let is_nan = isNaN(parseFloat(commissionPercent));
        if(is_nan || event.target.value === ""){
            this.setState({
                flat_rate: parseFloat(0),
                commission: parseFloat(0)
            })
        }else{
            this.setState({
                flat_rate: event.target.value,
                commission: parseFloat(commissionPercent)
            })
        }
    }

    render(){     
        return(
            <div>
                <div className="modal fade" data-backdrop="static" data-keyboard="false" id="markAsSoldModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={this.setwrapperRef} >
                    <div className="modal-dialog" role="document" ref={this.wrapperRef}>
                        <div className="modal-content modal_mark_as_sold modalheight" style ={{borderRadius : '12px' }} >
                            <div className="modal-header" style={{ backgroundColor: "#EDF6F6"}}>
                                <h4 className="modal-title fontWeight600"> <Icon icon={receiptIcon} className="color27AFAF" height='25' width='25'style={{verticalAlign:'bottom'}}/> Mark as Sold</h4>
                                <div id="closeModal_mark_as_sold" className="circle" data-dismiss="modal" onClick={this.closeMarkAsSoldModal}>
                                    <img src="images/modal-close.png " width="12" height="12" alt=""/>
                                </div> 
                            </div>
                            
                            <div className="modal-body">
                                <center>
                                {this.props.mark_as_sold_form_error ? 
                                    this.props.mark_as_sold_form_error === "success" ? 
                                        <SuccessText successMsg = {this.props.mark_as_sold_form_error} /> 
                                    :
                                        <ErrorText errorMsg = {this.props.mark_as_sold_form_error} />
                                : 
                                    null
                                }

                                <form id="markAsSoldform" onSubmit={this.submitHandler}>
                                    {/* Details */}
                                    <div className="shadow" ref="myinput">
                                        <p className="cardHeader">Details</p>
                                            {this.props.property_detail ? 
                                                <div className='form_border'>
                                                    <div className='full-input'>
                                                        <label className="label_modal" style={{width:'100%', textAlign:'left'}}>Selling Price</label>
                                                        <span className="priceSymbol">{this.props.property_detail.currency_symbol}</span>
                                                        <input className="input_modal fontWeight800" style={{width:'80%',marginLeft:-30}} type='text' name='sold_price' id="sold_price" pattern='^\d*(\.\d{0,2})?$' placeholder="120000" ref="selling_price" maxLength='10' value={(this.props.property_detail.price === "00.00") || (this.state.sold_price !== null) ? this.state.sold_price : this.props.property_detail.price} onChange={this.onInputchange} required title="Only numbers and two decimals are allowed"></input>
                                                    </div>
                                                    <div className="row" style={{marginRight:"-3px", marginLeft:"-3px"}}>
                                                        <div className="col-md-5">
                                                            <div className='full-input'>
                                                                <label className="label_modal" style={{width:'100%', textAlign:'left'}}>Commission %</label>
                                                                <span className="priceSymbol">% </span>
                                                                <input className="input_modal fontWeight800" style={{width:'80%',marginLeft: "0px"}} type='text' name='commission' id="commission" pattern='[0-9]+([,\.][0-9]+)?' placeholder="2" ref="commission" maxLength='5' value={this.state.commission !== null ? this.state.commission : this.props.property_detail.commission} onChange={this.handleCommission} required title="Only numbers and two decimals are allowed"></input>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="col-md-2" style={{paddingTop:"5%"}}>
                                                            <span style={{verticalAlign:"middle", fontSize: "18px", fontWeight:"700", fontFamily: "inter"}}>OR</span>
                                                        </div>

                                                        <div className="col-md-5">
                                                            <div className='full-input'>
                                                                <label className="label_modal" style={{width:'100%', textAlign:'left'}}>Flat Rate</label>
                                                                <span className="priceSymbol">{this.props.property_detail.currency_symbol}</span>
                                                                <input className="input_modal fontWeight800" style={{width:'80%',marginLeft: "0px"}} type='text' name='flat_rate' id="flat_rate" pattern='[0-9]+([,\.][0-9]+)?' placeholder="12000" ref="flat_rate" maxLength='10' value={this.state.flat_rate !== null ? this.state.flat_rate : this.props.property_detail.commission} onChange={this.handleFlatRate} required title="Only numbers and two decimals are allowed"></input>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className='full-input' style={{borderBottom: "none"}}>
                                                        <label className="label_modal" style={{margin:"0px"}}>Transfer Date</label>
                                                    </div>
                                                    <div className='full-input' style={{display:'flex'}}>
                                                        <Calendar value={this.state.startDate} maxDate={this.state.endDate} dateFormat="dd-mm-yy" name="transfer_date" id="transfer_date"
                                                            onChange={(e) => {this.setState({ startDate: e.value, CalenderMinDate:e.value })} }>
                                                        </Calendar>
                                                    </div>
                                                    <br/>
                                                </div>
                                                :
                                                <Loader/>
                                            }
                                        <br/><br/><br/>
                                    </div>
                                    <br/><br/><br/>
                                    {!this.state.loading ?
                                        this.props.mark_as_sold_form_error !== 'success'?
                                            <div style={(Number(this.props.add_property_modal_state)) === 1 ? {display: "block"} : {display: this.state.display_card} }> 
                                                <button id="markAsSoldBtn" type="submit" className="btn btn-primary fontBold" style={{ background:'#4AA49C',borderRadius:'4px'}}>
                                                <Icon icon={receiptIcon} className="" height='18' width='18'style={{verticalAlign:'bottom', marginRight:"5px"}}/>
                                                    Mark as Sold
                                                </button>
                                            </div>
                                        : null
                                        :<Loader/>
                                    }
                                    <br/><br/>
                                </form>
                                </center>
                            </div>
                            
                        </div> 
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        get_auth_data : (agent_id,token) => dispatch(actions.get_auth_data(agent_id,token)),
        add_mark_as_sold : (token, property_id, sold_price, commission, tansfer_date) => dispatch(actions.add_mark_as_sold(token, property_id, sold_price, commission, tansfer_date)),
        reset_mark_as_sold_modal: () => dispatch(actions.reset_mark_as_sold_modal()),
        reset_property_modal: () => dispatch(actions.reset_property_modal()),
        get_property_data_modal: (fromDate, toDate, prop_id, agent_id, token, date_sold, fromnetworking, property_notes_user_id) => dispatch(actions.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold, fromnetworking, property_notes_user_id)),
        get_property_list_date : (filter_by, fromDate, toDate, token) => dispatch(actions.get_property_list_date(filter_by, fromDate, toDate, token)),
        //get property list data
        get_property_data : (fromDate, toDate, agent_id, token, viewings_sort, recent_property) => dispatch(actions.get_property_data(fromDate, toDate, agent_id, token, viewings_sort, recent_property)),
        //get networking data
        get_networking_data: (agent_id, token) => dispatch(actions.get_networking_data(agent_id,token)),
        //set page for calendar
        set_page: (page, reset_date_range) => dispatch(actions.set_page(page, reset_date_range)),
    }
  }
  
const mapStateToProps = state => {
    return{
        authtoken: state.add_property_modal.authtoken,
        mark_as_sold_form_error: state.property_modal.mark_as_sold_form_error,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MarkAsSold);
