import React, { Component } from 'react'
import {connect} from 'react-redux';
import * as actions from '../store/actions/index';
import evoCalendar from 'evo-calendar'; // eslint-disable-line no-unused-vars
import $ from 'jquery';

class EvoCalendar extends Component {
    // constructor(props) {
    //     super(props)

    // }
    componentDidMount(){
        $("#evoCalendar").evoCalendar({
            format: 'mm/dd/yyyy',
           // calendarEvents: _.props.date_wise_appt
        })
        $('#evoCalendar').evoCalendar('toggleSidebar', false);
        $('#evoCalendar').evoCalendar('toggleEventList', false);
    }

    render() {
        if(this.props.date_wise_appt){
            if(Number(this.props.renderCalendar) === 1){
                $('#evoCalendar').evoCalendar('addCalendarEvent', this.props.date_wise_appt);
                this.props.parentCallback();
            }
        }

        return (
            <div className="row">
                <div className="col-md-12">
                    <div id="evoCalendar"></div>
                </div>
            </div>
        )
    }
}
    
const mapDispatchToProps = dispatch => {
    return {
        update_expire : (lastActive) => dispatch(actions.update_expire(lastActive)),
        logout : () => dispatch(actions.logout()),
    }
}

const mapStateToProps = state => {
    return{
        login: state.auth.login,
        lastActive: state.auth.lastActive,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EvoCalendar);