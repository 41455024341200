import * as actionTypes from '../actions/actionsTypes'; 
import {updateObject} from '../utility';

const initialState = {
    //auth data
    timezone: null,
    authtoken: null,
    
    //add property
    add_property_success: null,
    share_code: null,
    share_code_occupant: null,
    seller: null,
    empty_land: null,
    
    add_property_modal_state: 0,    //0 - new property from page, 1 - new property from agent card, 2 - edit property 
    //edit property
    prop_id: null,
    agent_id: null,
    agent_name: null,
    address: null,
    address_google: null,
    external_url:null,
    lat: null,
    lon: null,
    unit: null,
    hide_map: null,
    sale_type: null,
    price: null,
    is_office_listing: null,
    is_open_listing: null,
    reside: null,
    edit_property_success: null,
    property_form_error: null,
    sold_price: null,

    country_code_dd: null,
    currency_symbol_dd: null,
    pro_user: null,
    crm_id: null,
    sync_crm: null,
}

// const get_auth_data = (state, action) => {
//     console.log(action.authtoken);
//     return updateObject(state,{
//         authtoken: action.authtoken,
//     },console.log(state.authtokens))
// }

const get_auth_data = (state, action) =>{ 
    return updateObject(state,{
        authtoken: action.authtoken,
    });
}

const add_property = (state, action) => {
    return updateObject(state,{
        share_code: action.share_code,
        share_code_occupant: action.share_code_occupant,
        seller: action.seller,
        reside: action.reside,
        empty_land: action.empty_land,
        add_property_success: action.add_property_success,
        property_form_error: 'success'
    })
}

const get_edit_property_data = (state,action) => {
    return updateObject(state,{
        prop_id: action.prop_id,
        agent_id: action.agent_id,
        agent_name: action.agent_name,
        address: action.address,
        address_google: action.address_google,
        external_url: action.external_url,
        lat: action.lat,
        lon: action.lon,
        unit: action.unit,
        hide_map: action.hide_map,
        sale_type: action.sale_type,
        price: action.price,
        is_office_listing: action.is_office_listing,
        is_open_listing: action.is_open_listing,
        reside: action.reside,
        sold_price: action.sold_price,
        currency_symbol_dd: action.currency_symbol,
        crm_id: action.crm_id,
        sync_crm: action.sync_crm,
        share_code: action.share_code,
        pro_user: action.pro_user,
        timezone: action.timezone,
    })
}

const update_add_property_modal_state = (state, action) =>{
    return updateObject(state,{
        add_property_modal_state: action.add_property_modal_state,
    })
}

const update_edit_property_modal_state = (state, action) =>{
    return updateObject(state,{
        edit_property_success: action.edit_property_success,
        property_form_error: 'success'
    })
}

const update_add_property_success_modal_state = (state, action) =>{
    return updateObject(state,{
        add_property_success: action.add_property_success,
        edit_property_success: action.edit_property_success
    })
}

const submit_fail = (state, action) => {
    return updateObject(state,{
        property_form_error: action.property_form_error
    })
}

const reset_all_form_state = (state, action) => {
    return updateObject(state,{
        prop_id: null,
        agent_id: null,
        agent_name: null,
        address: null,
        address_google: null,
        external_url:null,
        lat: null,
        lon: null,
        unit: null,
        hide_map: null,
        sale_type: null,
        price: null,
        is_office_listing: null,
        is_open_listing: null,
        reside: null,
        edit_property_success: null,
        property_form_error: null,
        sold_price: null,
        crm_id: null,
        sync_crm: null,
        share_code: null,
        pro_user: null,
        timezone: null,
    })
}

const reset_property_modal_error = (state, action) => {
    return updateObject(state,{
        property_form_error: null,
        authtoken: null
    })
}

const get_all_agent_data = (state, action) =>{
    return updateObject(state,{
        country_code_dd: action.country_code,
        currency_symbol_dd: action.currency_symbol,
        pro_user: action.pro_user,
        timezone: action.timezone,
    })
}
const toggle_is_map_hide = (state, action) =>{
    return updateObject(state,{
        hide_map: action.hide_map
    })
}
function add_property_reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.GET_AUTH_DATA: return get_auth_data(state, action);
        case actionTypes.ADD_PROPERTY: return add_property(state, action);
        case actionTypes.GET_EDIT_PROPERTY_DATA: return get_edit_property_data(state, action);
        case actionTypes.UPDATE_ADD_PROPERTY_MODAL_STATE: return update_add_property_modal_state(state,action);
        case actionTypes.EDIT_PROPERTY: return update_edit_property_modal_state(state,action);
        case actionTypes.UPDATE_ADD_PROPERTY_SUCCESS_MODAL_STATE: return update_add_property_success_modal_state(state,action);
        case actionTypes.SUBMIT_FAIL: return submit_fail(state,action);
        case actionTypes.RESET_ALL_FORM_STATE: return reset_all_form_state(state,action);
        case actionTypes.RESET_PROPERTY_MODAL_ERROR: return reset_property_modal_error(state,action);
        case actionTypes.GET_ALL_AGENT_DATA: return get_all_agent_data(state,action);
        case actionTypes.TOGGLE_IS_MAP_HIDE: return toggle_is_map_hide(state,action);
        default:
            return state;
    }
}

export default add_property_reducer;