import React,{ Component } from "react";
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import Moment from 'react-moment';
import SimpleMap from './SimpleMap';

import EditStockPipeline from './Add_Edit_StockPIpeline/EditStockPipeline'
import EditGeneralAppointment from './Appointment/EditGeneralAppointment'

//icons
import { Icon } from '@iconify/react';
import notesIcon from '@iconify/icons-grommet-icons/notes';
import eyeIcon from '@iconify/icons-akar-icons/eye';
import editOutlined from '@iconify/icons-ant-design/edit-outlined';
import bxCalendarEvent from '@iconify/icons-bx/bx-calendar-event';
import editIcon from '@iconify/icons-ci/edit';

const $ = require('jquery');

class StockPipelinePropertyModal extends Component{
    constructor(props) {
    super(props);
        this.wrapperRef = React.createRef();
        this.setwrapperRef = React.createRef();       
        this.editStockPipeline = React.createRef();
        
        this.closeModal = this.closeModal.bind(this);
        this.handleAppointmentEdit = this.handleAppointmentEdit.bind(this);
    }

    closeModal() { 
        $("#notes").addClass("active in");
        $("#appointment_stock_pipeline").removeClass("active in");
        
        $("#notestab").addClass("active");
        $("#appointmenttab").removeClass("active");
        window.$("#stockpipeline_modal").modal('hide');
        this.props.reset_stock_pipeline_modal();
    }
    
    handleEditStockPipelineModal = () => {
        // console.log('clicked');
        window.$("#editStockPipelineModal").modal('show');
        this.props.set_edit_map_address(this.props.stock_pipeline_property_data[0].address_google, this.props.stock_pipeline_property_data[0].lat, this.props.stock_pipeline_property_data[0].lon);
        this.editStockPipeline.setEditAddress();
    }

    handleAddAppointment = async () =>{
        let token = localStorage.getItem('token');
        const show_agents = 1
        const show_property = 1
        const from_property_page = 0
        const from_stock_pipeline = 1

        await this.props.get_auth_data(this.props.agent_id,token);
        this.props.get_all_agent_data_dd(this.props.authtoken)
        await this.props.update_add_appointment_modal_state(show_agents,show_property,from_property_page,from_stock_pipeline)
        window.$("#addAppointmentModal").modal('show');
    }

    handleAppointmentEdit = async(appointment_id) =>{
        const token = localStorage.getItem('token')
        await this.props.get_auth_data(this.props.agent_id, token);
        await this.props.get_general_appointment(this.props.authtoken, appointment_id)
        this.props.get_all_agent_data_dd(this.props.authtoken)
        this.setGeneralData();
        window.$("#editGeneralAppointmentStockModal").modal('show');
    }

    render(){ 
        return(<>
            <div className="modal fade" id="stockpipeline_modal" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={this.setwrapperRef}>
                <div className="modal-dialog" role="document" ref={this.wrapperRef}>
                    <div className="modal-content modalheight" style ={{borderRadius : '12px' }} >
                        <div className="modal-container">
                            <div className="google-map">
                                {!this.props.stock_pipeline_property_data?
                                        <center><br /><img src="images/loader.svg" width="50" height="50" alt="" /> </center> 
                                    :  
                                    <>
                                        <SimpleMap lat= {this.props.stock_pipeline_property_data[0].lat} lon = {this.props.stock_pipeline_property_data[0].lon } /> 
                                    </>
                                }
                            </div>
                            <div className="circle" data-dismiss="modal" onClick={this.closeModal}>
                                <img src="images/modal-close.png " width="12" height="12" alt="" />
                            </div> 
                            <div className = "container-fluid mb30" style={{minHeight:550}}> 
                                {this.props.stock_pipeline_property_data ?
                                    <div className="text-block_stockpipeline" style={{marginBottom: "30px"}}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <span className="fontRoboto fontWeight400 fontSize16" >
                                                    {this.props.stock_pipeline_property_data[0].address}
                                                </span>
                                                <span className="fontRoboto fontWeight700 fontSize16" style={{display: "block"}}>{this.props.stock_pipeline_property_data[0].unit_no}</span>
                                            </div>

                                            {Number(this.props.from_archiveStockPipeline) !== 1 ?
                                                <div className="col-md-12">
                                                    <button type="submit" onClick={this.handleEditStockPipelineModal} className="btn btn-primary fontBold mr8 mt10" style={{ background:'#4AA49C',borderRadius:'4px'}} > <Icon icon={editOutlined} height='18' width='18' style={{verticalAlign:'bottom'}}/> Edit Stock Pipeline</button>
                                                    
                                                    <button type="submit" className="btn btn-primary fontBold mt10" style={{ background:'#4AA49C', borderRadius:'4px', marginLeft:20, padding:"6.3px 12px"}} onClick={this.handleAddAppointment}>
                                                        <Icon className="message_icon mr5" style={{verticalAlign:'bottom'}} icon={bxCalendarEvent} height='20' width='20' />
                                                        Add Appointment
                                                    </button>
                                                </div>
                                                : null
                                            }
                                        </div>
                                    </div>
                                : null}
                                
                                <ul className="nav nav-tabs stockpipeline-information">
                                    <li style={{ width: '50%' }} id="notestab" className="active fontInter">
                                        <a data-toggle="tab" href="#notes" style={{display:'inline-block'}}>
                                            <Icon icon={notesIcon} className="color4AA49C" style={{fontSize: '24px', marginRight:"5px", verticalAlign: 'middle', display:'inline-block'}} />
                                            Property Notes
                                        </a>
                                    </li>
                                    <li style={{ width: '50%' }} id="appointmenttab">
                                        <a data-toggle="tab" href="#appointment_stock_pipeline" style={{display:'inline-block'}}>
                                            <Icon icon={eyeIcon} className="color4AA49C" style={{fontSize: '24px', marginRight:"5px", verticalAlign: 'middle', display:'inline-block'}} />
                                            Appointments
                                        </a>
                                    </li>
                                </ul>

                                <div className="tab-content stockpipeline-information" style={{ marginTop: '10px', overflow: 'auto', fontSize: '16px', paddingBottom: '20px'}}>
                                    <div id="notes" className="tab-pane fade in active">
                                        {this.props.stock_pipeline_notes && this.props.stock_pipeline_notes.length > 0 ?
                                            this.props.stock_pipeline_notes.map((data) => (
                                                <div className="row" key={data.id} style={{margin: "0px"}}>
                                                    <div className="col-md-offset-1 col-md-10">
                                                        <div className="cold_call_shadow">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <p className="cold_call_header mt15" style={{float:'left', paddingLeft:15, marginTop:15}}><Moment format="MMM D YYYY">{data.created_date}</Moment></p>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <p className="cold_call_header mb0" style={{float:'left', paddingLeft:15, margin: 0}}><Moment format="HH:mm">{data.created_date}</Moment></p>
                                                                </div>
                                                            </div>
                                                            <hr style={{margin:"10px 0px 10px"}}/>
                                                            <div className='row'>
                                                                <div className="col-md-12">
                                                                    {Number(data.hasaudio) === 1 ?
                                                                        <audio controls controlsList="nodownload noplaybackrate" style={{marginBottom:10, marginLeft:10}}>
                                                                            <source src={data.audio_path} type="audio/mpeg" />
                                                                        </audio>
                                                                        :
                                                                        <p className="cold_call_header_date" style={{float:'left', paddingLeft:15, marginBottom:10}}>{data.note}</p> 
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                            : <center><br/><br/><h4>No Notes to Show</h4></center>
                                        }
                                    </div>
                                    
                                    <div id="appointment_stock_pipeline" className="tab-pane fade">
                                        {this.props.stock_pipeline_appointments && this.props.stock_pipeline_appointments.length > 0 ?
                                            this.props.stock_pipeline_appointments.map((data) => (
                                                <div className="row" key={data.id} style={{margin: "0px"}}>
                                                    <div className="col-md-offset-1 col-md-10 mb15">
                                                        <div className="cold_call_shadow">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="generalHeader" style={{marginRight: "0px", marginLeft: "0px"}}>
                                                                        <span>General {Number(data.cancelled) === 1 ? <span className="cancelspan floatRight" style={{verticalAlign: "top", padding: "1px 5px 1px 5px", marginTop: 0}}> Cancelled </span> : null}</span>
                                                                        {(Number(data.cancelled) === 0) && (Number(data.appointment_status_id) !== 3 || Number(data.appointment_status_id) !== 4) && (!data.date_deleted ) ?
                                                                            <button ref='edit' type="button" id={data.general_appointment_id} className="btn btn-info fontWeight500" style={{float:'right', backgroundColor: "#27afaf", border: "1px solid #27afaf", verticalAlign: 'bottom', padding: "0px 5px 0px 5px", marginRight: "24px"}} onClick={() => this.handleAppointmentEdit(data.general_appointment_id)}> <Icon icon={editIcon} style={{verticalAlign: "middle"}}/> </button> 
                                                                        : null
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 mt15">
                                                                    <p className="cold_call_header mb0" style={{textAlign:'left', marginTop:15, paddingLeft:15, color:"#0F1D30"}}>{data.meeting_with}</p>
                                                                    <p className="mb0 mt5" style={{paddingLeft:15, color:"#738196"}}><Moment format="MMM D YYYY">{data.appointment_date}</Moment></p>
                                                                    <p className="mb0" style={{paddingLeft:15, color:"#738196"}}><Moment format="HH:mm">{data.appointment_date}</Moment></p>
                                                                    <hr style={{margin:"10px 0px 10px"}}/>
                                                                    <p className="cold_call_header_date" style={{textAlign:'left', paddingLeft:15}}>
                                                                        {Number(data.has_audio) === 1 ?
                                                                            <audio controls controlsList="nodownload noplaybackrate">
                                                                                <source src={data.audio_name} type="audio/mpeg" />
                                                                            </audio>
                                                                        :
                                                                            <p className="cold_call_header_date mb15" style={{textAlign:'left'}}>{data.reason} </p> 
                                                                        }
                                                                    </p> 
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        :
                                            <center><br/><br/><h4>No Appointments Yet </h4></center>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {this.props.stock_pipeline_property_data ?
                <EditStockPipeline
                    onRef={ref => (this.editStockPipeline = ref)}
                    stock_pipeline_property_data = {this.props.stock_pipeline_property_data}
                />
            : null}
            <EditGeneralAppointment
                setGeneralData={click => this.setGeneralData = click}
                from_stock_pipeline = {1} 
            />
            </>
        )
    }
}
  
const mapDispatchToProps = dispatch => {
    return {
        reset_stock_pipeline_modal : () => dispatch(actions.reset_stock_pipeline_modal()),
        reset_stock_pipeline_data : () => dispatch(actions.reset_stock_pipeline_data()),
        set_edit_map_address : (edit_map_address, edit_lat, edit_lng) => dispatch(actions.set_edit_map_address(edit_map_address, edit_lat, edit_lng)),
        //get authtoken for add appointment
        get_auth_data : (agent_id,token) => dispatch(actions.get_auth_data(agent_id,token)),
        get_all_agent_data_dd: (token)=> dispatch(actions.get_all_agent_data_dd(token)),
        update_add_appointment_modal_state: (show_agents, show_property, from_property_page,from_stock_pipeline) => dispatch(actions.update_add_appointment_modal_state(show_agents, show_property, from_property_page,from_stock_pipeline)),
        //get general appointment
        get_general_appointment: (authtoken, appointment_id) => dispatch(actions.get_general_appointment(authtoken, appointment_id)),
    }
}
const mapStateToProps = state => {
    return{
        stock_pipeline_property_data: state.agent_modal.stock_pipeline_property_data,
        stock_pipeline_notes: state.agent_modal.stock_pipeline_notes,
        stock_pipeline_appointments: state.agent_modal.stock_pipeline_appointments,
        from_archiveStockPipeline: state.agent_modal.from_archiveStockPipeline,

        //agent tab
        stock_pipeline: state.agent_modal.stock_pipeline,
        stock_load_more: state.agent_modal.stock_load_more,
        stock_notes: state.agent_modal.stock_notes,
        //stock_pipeline_data: state.agent_modal.stock_pipeline_data,
        stock_pipeline_visit: state.agent_modal.stock_pipeline_visit,
        //agent mobile authtoken
        authtoken: state.add_property_modal.authtoken
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(StockPipelinePropertyModal)