import * as actionTypes from '../actions/actionsTypes'; 
import {updateObject} from '../utility';

const initialState = {
    property_list: null,
    agent_list: null,
    filter_by: 1,

    property_labels: null,
    property_agen_count: null,
    temp_chart_property_count: null
}

const property_list = (state, action) =>{   
    return updateObject(state,{
        property_list: action.data
    })
}

const agent_list = (state, action)=>{
    return updateObject(state,{
        agent_list: action.agent_data
    })
}

const get_property_chart = (state, action) =>{
    const property_graph_data = JSON.parse(JSON.stringify(action.property_agen_count));
    const non_active_agent_buttons = localStorage.getItem('non_active_agent_buttons') ? JSON.parse(localStorage.getItem('non_active_agent_buttons')) : [];
    if(non_active_agent_buttons){
        // eslint-disable-next-line
        non_active_agent_buttons.map((agent_id) =>{
            delete property_graph_data[0][agent_id];
        })
        return updateObject(state, {
            property_labels: action.property_labels,
            property_agen_count: property_graph_data,
            temp_chart_property_count : action.temp_chart_property_count
        })
    }else{
        return updateObject(state,{
            property_labels: action.property_labels,
            property_agen_count: action.property_agen_count,
            temp_chart_property_count : action.temp_chart_property_count
        });
    }
}

const toggle_property_page_chart = (state, action) =>{
    const property_graph_data = JSON.parse(JSON.stringify(state.property_agen_count));
    if(property_graph_data[0][action.agent_id]){
        delete property_graph_data[0][action.agent_id];
        return updateObject(state, {property_agen_count: property_graph_data});
    }else{
      const data = state.property_agen_count.map((index)=>{
        return {...index, [action.agent_id]: state.temp_chart_property_count[0][action.agent_id]};
      });
      return updateObject(state, {property_agen_count: data});
    }
}

const set_filter_by = (state, action) =>{
    return updateObject(state, {
        filter_by: action.filter_by,
    })
}

function property_reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.GET_PROPERTY_LIST: return property_list(state, action);
        case actionTypes.GET_AGENT_LIST: return agent_list(state, action);
        case actionTypes.GET_PROPERTY_CHART: return get_property_chart(state, action);
        case actionTypes.TOGGLE_PROPERTY_PAGE_CHART: return toggle_property_page_chart(state, action);
        case actionTypes.SET_FILTER_BY: return set_filter_by(state, action);
        default:
        return state;
    }
}
export default property_reducer;