import * as actionTypes from '../actions/actionsTypes'; 
import {updateObject} from '../utility';

const initialState = {
    agent_list_revenue: null,

    revenue_labels: null,
    chart_revenue_cnt: null,
    temp_chart_revenue_count: null
}

const get_agent_list_revenue = (state, action) => {
    return updateObject(state,{
        agent_list_revenue: action.agent_list_revenue
    })
}

const get_revenue_chart = (state, action) => {
    const revenue_graph_data = JSON.parse(JSON.stringify(action.chart_revenue_cnt));
    const non_active_agent_buttons = localStorage.getItem('non_active_agent_buttons') ? JSON.parse(localStorage.getItem('non_active_agent_buttons')) : null;
    if(non_active_agent_buttons){
        // eslint-disable-next-line
        non_active_agent_buttons.map((agent_id) =>{
            delete revenue_graph_data[0][agent_id];
        })
        return updateObject(state, {
            revenue_labels: action.revenue_labels,
            chart_revenue_cnt: revenue_graph_data,
            temp_chart_revenue_count : action.temp_chart_revenue_count
        })
    }else{
        return updateObject(state,{
            revenue_labels: action.revenue_labels,
            chart_revenue_cnt: action.chart_revenue_cnt,
            temp_chart_revenue_count : action.temp_chart_revenue_count
        });
    }
}

const toggle_revenue_page_chart = (state, action) =>{
    const revenue_graph_data = JSON.parse(JSON.stringify(state.chart_revenue_cnt));
    if(revenue_graph_data[0][action.agent_id]){
        delete revenue_graph_data[0][action.agent_id];
        return updateObject(state, {chart_revenue_cnt: revenue_graph_data});
    }else{
      const data = state.chart_revenue_cnt.map((index)=>{
        return {...index, [action.agent_id]: state.temp_chart_revenue_count[0][action.agent_id]};
      });
      return updateObject(state, {chart_revenue_cnt: data});
    }
}

function revenue_reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.GET_REVENUE_AGENT_LIST: return get_agent_list_revenue(state, action);
        case actionTypes.GET_REVENUE_CHART: return get_revenue_chart(state, action);
        case actionTypes.TOGGLE_REVENUE_PAGE_CHART: return toggle_revenue_page_chart(state, action);
        default:
        return state;
    }
}

export default revenue_reducer;