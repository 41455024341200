import React, {Component } from 'react';
import {connect} from 'react-redux';
import * as actions from './store/actions/index';

import modal from './CustomFiles/js/modal';
import Constants from './constants/Constants';
import Helmet from "react-helmet";
// import ReactTooltip from "react-tooltip";

import Header from './components/menu/Header';
import Footer from './components/Footer';
import Loader from './components/Loader';
import SellerLinkedMessage from './components/modals/SellerLinkedMessage';
import AgentList from './components/AgentList';
//chart and pie
import AppointmentChart from './components/charts/AppointmentChart';
import GeneralChart from './components/charts/GeneralChart';
import PropertyChart from './components/charts/PropertyChart';
import PropertySaleChart from './components/charts/PropertySaleChart';
import PropertySoldChart from './components/charts/PropertySoldChart';
import ColdCallChart from './components/charts/ColdCallsChart';
import StockPipelineChart from './components/charts/StockPipelineChart';
import ProfitabilityChart from './components/charts/ProfitabilityChart';
import RevenueChart from './components/charts/RevenueChart';
import { Pie } from 'react-chartjs-2';
//modal
import AgentInformationModal from  './components/modals/AgentInformationModal';
import PropertyDetailModal from './components/modals/PropertyDetailModal';
import AddAppointment from './components/modals/Appointment/AddAppointment';
import IdleTimerContainer from './components/IdleTimerContainer';
import DateRange from './components/DateRange';
//icons
import {Icon} from '@iconify/react';
import bxsUser from '@iconify/icons-bx/bxs-user';
import funnelDollar from '@iconify/icons-fa-solid/funnel-dollar';
import callForward from '@iconify/icons-si-glyph/call-forward';
import informationIcon from '@iconify/icons-mdi/information';
import downloadFill from '@iconify/icons-eva/download-fill';
import bxsShow from '@iconify/icons-bx/bxs-show';
import bxsHide from '@iconify/icons-bx/bxs-hide';
import copyIcon from '@iconify/icons-fa-regular/copy';
import houseUser from '@iconify/icons-fa-solid/house-user';
//import 'primereact/resources/themes/nova-light/theme.css';
//import 'primereact/resources/primereact.min.css';
//import 'primeicons/primeicons.css';
import './CustomFiles/bower_components/bootstrap/dist/css/bootstrap.min.css';
import './CustomFiles/bower_components/font-awesome/css/font-awesome.min.css';
import './CustomFiles/bower_components/Ionicons/css/ionicons.min.css';
import './CustomFiles/dist/css/AdminLTE.min.css';
import './CustomFiles/plugins/iCheck/square/blue.css';
import '../node_modules/react-linechart/dist/styles.css';
import './CustomFiles/bower_components/datatables.net-bs/css/dataTables.bootstrap.min.css';
import './CustomFiles/dist/css/skins/_all-skins.min.css';
import "react-datepicker/dist/react-datepicker.css";

import DataTable from 'react-data-table-component';

import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

var agentId;
var dataSetsChart;
var settingarray
var splitsettingarray

const $ = require('jquery');
$.DataTable = require('datatables.net');

var colorList = ['#7B8CA1','#EA676A','#63C8BA','#F8CA55','#F09A56','#6ADA8A', '#9B66E3', '#547DE5', '#B5A78D', '#DE6DA8',
  '#A368D9','#B43B1D','#229649','#2C3E50','#8c9966','#53F49B','#DC9D7D','#660E52','#39C9AE','#BCA7C4',
  '#D08E27','#A3DB7D','#606792','#11E797','#1DC1C1','#D5C175','#1DC1C1','#421B6C','#98E708','#EE2A09',  
  '#017C33','#24F080','#CC5A45','#801FD4','#04405D','#133123','#1B42B1','#7EC377','#2DE6E0','#9FCE27',
  '#F96CDD','#41197D','#4A809B','#794D61','#BA28E3','#183A8C','#D07848','#4F3B87','#3A8947','#100769',
  '#B7931E','#AA0A71','#417A92','#AC5B6C','#7E8F92','#C06EA7','#866E50','#561C56','#DB3DAF','#F03C44',
  '#90A8F4','#93AC64','#5B532B','#1CBB4B','#05A5A5','#CEC21E','#D3A8FF','#3F4D5E','#BD0B49','#2CA6A0',
  '#C7B541','#6D8D6B','#70C29B','#0B0BE2','#EE19DA','#588546','#DBB5A2','#609CBF','#38198D','#904B6C',
  '#8BD18C','#32638D','#792A4F','#7184E1','#35A50F','#4B3871','#8C8007','#4B2257','#089564','#F1A0C1',
  '#0D9168','#8CA06C','#0A7EB7','#AB53CB','#43246A','#2F0020','#06759B','#785646','#B36935','#1A7607',
];

//datatable
let data=[];
let columns = [];
let data_table_data = [];

const FilterComponent = ({ filterText, onFilter, onClear }) => (<>
  <label style={{ fontFamily: 'Roboto', fontSize: "14px", color: "#333333", fontWeight: "normal", marginTop:"7px", marginRight:"10px"}}>Search :</label>
  <input id="search" type="text" style={{border: "1px solid #daeeec", height: "28px"}} placeholder="Filter By Agent Name" aria-label="Search Input" value={filterText} onChange={onFilter} />
  </>
);

const customStyles = {
  rows: {
    style: {
      minHeight: '72px', // override the row height
    }
  },
  headCells: {
    style: {
      paddingLeft: '8px', // override the cell padding for head cells
      paddingRight: '8px',
      fontSize:'12px',
      color:"#4AA49C",
      fontFamily: 'Inter',
      fontWeight: 600,
      textAlign:'center',
      marginLeft:"20px"
    },
  },
  cells: {
    style: {
      paddingLeft: '18px', // override the cell padding for data cells
      paddingRight: '8px',
      marginBottom: '10px'
    },
  },
};

class Overview extends Component {

  constructor(props) {
    super(props);
    this.AgentInformationModal = React.createRef();
    this.myRef = React.createRef();
    this.state = {
      globalAgentList:[],
      globalAgentListName: [],
      globalAgentListImage: [],
      blocking:  false,
      startDate: new Date(new Date().getFullYear(), 0, 1),
      endDate:   new Date(new Date().getFullYear(), 11, 31),
      agent_id:  null,
      renderAgentButton: 0,
      CalenderMinDate: new Date(new Date().getFullYear(), 0, 1),
      from_date: null,
      to_date: null,
      
      togglebtn_viewing: true,
      togglebtn_general: false,
      viewing_graph_text: "Viewings",

      //sale or rental graph
      togglebtn_forsale: true,
      togglebtn_rental: false,
      property_sale_graph_text: "For Sale",
      sale_type: "sale",
      
      //Sold or rented graph
      togglebtn_sold: true,
      togglebtn_rented: false,
      property_sold_graph_text: "Sold",
      sold_type: "sold",
      
      //revenue graph
      togglebtn_sales: true,
      togglebtn_all_rental: false,
      togglebtn_monthly_rental: false,
      togglebtn_once_off_rental: false,
      revenue_type: "sales",

      show_auth_key: false,

      filterText: "",
      resetPaginationToggle: false,
    }
    this.handleShowModal = this.handleShowModal.bind(this);
  }

  getSubHeaderComponent = () => {
    return (
      <FilterComponent
        onFilter={(e) => {
          let newFilterText = e.target.value;
          this.filteredItems = data.filter(
            (item) =>
              item.agent_name &&
              item.agent_name.toLowerCase().includes(newFilterText.toLowerCase())
          );
          this.setState({ filterText: newFilterText });
        }}
        onClear={this.handleClear}
        filterText={this.state.filterText}
      />
    );
  };

  handleClear = () => {
    const { resetPaginationToggle } = this.state;

    if (this.state.filterText) {
      this.setState({
        resetPaginationToggle: !resetPaginationToggle,
        filterText: ""
      });
    }
  };
 
  handlePropertyModal = async (prop_id, date_sold, created_user_id, from_archiveProperty) => { 
    this.props.reset_property_modal();
    const show_agents = 0
    const show_property = 0
    const from_property_page = 1
    const from_stock_pipeline = 0
    await this.props.update_add_appointment_modal_state(show_agents,show_property,from_property_page,from_stock_pipeline)
    /* Creating Date From 1st of Year to Last Of Year */
    // var fromDate = new Date("1/1/" + (new Date()).getFullYear());
    // var toDate = new Date(new Date().getFullYear(), 11, 31, 0, 0, 0);

    // var d = new Date(fromDate),
    // month = '' + (d.getMonth() + 1),
    // day = '' + d.getDate(),
    // year = d.getFullYear();
  
    // if (month.length < 2) month = '0' + month;
    // if (day.length < 2) day = '0' + day;
    // fromDate = [year, month, day].join('-');

    // d = new Date(toDate);
    // month = '' + (d.getMonth() + 1);
    // day = '' + d.getDate();
    // year = d.getFullYear();
  
    // if (month.length < 2) month = '0' + month;
    // if (day.length < 2) day = '0' + day;
  
    // toDate = [year, month, day].join('-');
    var da = new Date();
    var fromDate = this.changeDateFormat(new Date(da.getFullYear(), da.getMonth() - 2, 1));
    var toDate = this.changeDateFormat(new Date(da.getFullYear(), da.getMonth(), da.getDate()));

    const token = localStorage.getItem('token');
    if(created_user_id){
      var property_notes_user_id = this.state.agent_id;
      let agent_id = created_user_id;
      let fromnetworking = 1;
      await this.props.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold, fromnetworking, property_notes_user_id);
    }else if(from_archiveProperty){
      let agent_id = this.state.agent_id;
      await this.props.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold, null, null, from_archiveProperty);
    }else{
      let agent_id = this.state.agent_id;
      await this.props.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold);
    }
    this.props.set_page("property_modal", true);
    window.$("#profileModal").modal('show');
  }
  
  async componentDidMount () {
    modal();
    const token = localStorage.getItem("token");
    if(!token){
      localStorage.clear();
      this.props.history.push("/");
      return;
    }
    
    var da = new Date();
    var fromDate = this.changeDateFormat(new Date(da.getFullYear(), da.getMonth() - 2, 1));
    var toDate = this.changeDateFormat(new Date(da.getFullYear(), da.getMonth(), da.getDate()));
    await this.props.get_overview_data(token);  
    await this.props.get_overview_data_with_date(fromDate, toDate, token);
    this.props.set_page("overview_page", true);
    this.setState({renderAgentButton: 1})

    /* clone the original setting values */
    settingarray = this.props.settings.slice(0)
    /* remove first two elements */
    if(this.props.settings.length %2 === 0){
      settingarray.splice(0,2)
    }else{
      settingarray.splice(0,1)
    }
    /* splitting the array into two segments */
    splitsettingarray = this.splitArray(this.props.settings.length, settingarray)
  }

  renderAppointmentChart = () => {
    var appointmentChart;
    if (this.props.appt_lables.length > 0 && this.props.data.length > 0 && this.state.renderAgentButton === 1) {
      appointmentChart = (<AppointmentChart
      globalAgentListName={this.state.globalAgentListName}
      globalAgentListImage={this.state.globalAgentListImage}
      globalAgentList={this.state.globalAgentList} overview="1"
      settings={this.props.settings}
      colorList={colorList} total_agent={this.props.data.length} appt_lables={this.props.appt_lables} chart_appt_count={this.props.chart_appt_count} />);
      return appointmentChart;
    } else {
      appointmentChart = (<Loader />);
      return appointmentChart;
    }
  }
  
  renderGeneralChart = () => {
    var generalChart;
    if (this.props.general_lables.length > 0 && this.props.data.length > 0 && this.state.renderAgentButton === 1) {
      generalChart = (<GeneralChart
      globalAgentListName={this.state.globalAgentListName}
      globalAgentListImage={this.state.globalAgentListImage}
      globalAgentList={this.state.globalAgentList} overview="1"
      settings={this.props.settings}
      colorList={colorList} total_agent={this.props.data.length} appt_lables={this.props.general_lables} chart_appt_count={this.props.chart_general_count} />);
      return generalChart;
    } else {
      generalChart = (<Loader />);
      return generalChart;
    }
  }

  renderPropertyChart = () => {
    var propertyChart;
    if (this.props.property_agen_count && this.props.property_labels.length > 0 && this.props.data.length > 0 && this.state.renderAgentButton === 1) {
      propertyChart = (<PropertyChart
        globalAgentListName={this.state.globalAgentListName}
        globalAgentListImage={this.state.globalAgentListImage}
        globalAgentList={this.state.globalAgentList}
        settings={this.props.settings}
        colorList={colorList} total_agent={this.props.data.length} property_labels={this.props.property_labels}
        property_agen_count={this.props.property_agen_count} oneProperty="0" />);
      return propertyChart;
    }
    else {
      propertyChart = (<Loader />);
      return propertyChart;
    }
  }

  renderPropertySaleChart = () => {
    var propertySaleChart;
    if (this.props.property_sale_agen_count && this.props.property_sale_labels.length > 0  && this.props.data.length > 0 && this.state.renderAgentButton === 1) {
      propertySaleChart = (<PropertySaleChart
        globalAgentListName={this.state.globalAgentListName}
        globalAgentListImage={this.state.globalAgentListImage}
        globalAgentList={this.state.globalAgentList}
        settings={this.props.settings}
        colorList={colorList} total_agent={this.props.data.length} property_labels={this.props.property_sale_labels}
        property_agen_count={this.props.property_sale_agen_count} />);
      return propertySaleChart;
    }
    else {
      propertySaleChart = (<Loader />);
      return propertySaleChart;
    }
  }

  renderPropertySoldChart = () => {
    var propertySoldChart;
    if (this.props.property_sold_agen_count && this.props.property_sold_labels.length > 0  && this.props.data.length > 0 && this.state.renderAgentButton === 1) {
      propertySoldChart = (<PropertySoldChart
        globalAgentListName={this.state.globalAgentListName}
        globalAgentListImage={this.state.globalAgentListImage}
        globalAgentList={this.state.globalAgentList}
        settings={this.props.settings}
        colorList={colorList} total_agent={this.props.data.length} property_labels={this.props.property_sold_labels}
        property_agen_count={this.props.property_sold_agen_count} />);
      return propertySoldChart;
    }
    else {
      propertySoldChart = (<Loader />);
      return propertySoldChart;
    }
  }

  renderRevenueChart = () => {
    var propertyChart;  
    if (this.props.revenue_labels.length > 0 && this.props.data.length > 0 && this.state.renderAgentButton === 1) {
      propertyChart = (<RevenueChart
        globalAgentList={this.state.globalAgentList}
        globalAgentListName={this.state.globalAgentListName}
        globalAgentListImage={this.state.globalAgentListImage}
        settings={this.props.settings}
        colorList={colorList}
        total_agent={this.props.data.length}
        revenue_labels={this.props.revenue_labels}
        chart_revenue_cnt={this.props.chart_revenue_cnt}
      />);
      return propertyChart;
    } else {
      propertyChart = (<Loader />);
      return propertyChart;
    }
  }

  renderStockPipelineChart = () =>{
    var stockpipelineChart;
    if (Array.isArray(this.props.stockpipeline_labels) && this.props.stockpipeline_labels.length > 0 && this.props.data.length > 0 && this.state.renderAgentButton === 1) {
      stockpipelineChart = (<StockPipelineChart
        globalAgentList={this.state.globalAgentList}
        globalAgentListName={this.state.globalAgentListName}
        globalAgentListImage={this.state.globalAgentListImage}
        settings={this.props.settings}
        colorList={colorList}
        total_agent={this.props.data.length}
        stockpipeline_labels={this.props.stockpipeline_labels}
        stockpipeline_count={this.props.stockpipeline_count}
      />);
      return stockpipelineChart;
    } else {
      stockpipelineChart = (<Loader />);
      return stockpipelineChart;
    }
  }

  renderColdCallsChart = () => {
    var coldcallChart;
    if (Array.isArray(this.props.coldcalls_labels) && this.props.coldcalls_labels.length > 0 && this.props.data.length > 0 && this.state.renderAgentButton === 1) {
      coldcallChart = (<ColdCallChart
        globalAgentList={this.state.globalAgentList}
        globalAgentListName={this.state.globalAgentListName}
        globalAgentListImage={this.state.globalAgentListImage}
        settings={this.props.settings}
        colorList={colorList}
        total_agent={this.props.data.length}
        coldcalls_labels={this.props.coldcalls_labels}
        coldcalls_count={this.props.coldcalls_count}
      />);
      return coldcallChart;
    } else {
      coldcallChart = (<Loader />);
      return coldcallChart;
    }
  }

  renderProfitability = () => {
    var propertyChart;
    if (this.props.profitability_labels.length > 0 && this.props.data.length > 0 && this.state.renderAgentButton === 1) {
      propertyChart = (<ProfitabilityChart
        globalAgentListName={this.state.globalAgentListName}
        globalAgentListImage={this.state.globalAgentListImage}
        globalAgentList={this.state.globalAgentList}
        settings={this.props.settings}
        colorList={colorList} overview="1"
        total_agent={this.props.data.length}
        profitability_labels={this.props.profitability_labels} profitability_chart={this.props.profitability_chart} />);
      return propertyChart;
    } else {
      propertyChart = (<Loader />);
      return propertyChart;
    }
  }
  
  splitArray(len, settingarr){
    const chunk = (arr, size) =>
      Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
      arr.slice(i * size, i * size + size)
    );
    let array=[]
    if(len > 6){
      array = chunk(settingarr, 3)
    } else{
      array = chunk(settingarr, 2)
    }
    return(array)
  }

  renderGraphs(setting_id){
    var agents = this.props.data;
    
    switch(setting_id) {
      case '1':
        var appt_sum = 0;
        var general_sum = 0;
        if (this.props.chart_appt_count ) {
          for (let a = 0; a < this.props.data.length; a++) {
            agentId = agents[a].id;
            dataSetsChart = (this.props.chart_appt_count[0][agentId])
            
            if(dataSetsChart){
              for (let b = 0; b < dataSetsChart.length; b++) {
                appt_sum = appt_sum + parseInt(dataSetsChart[b]);
              }
            }
          }
        }
  
        if (this.props.chart_general_count ) {
          for (let a = 0; a < this.props.data.length; a++) {
            agentId = agents[a].id;
            dataSetsChart = (this.props.chart_general_count[0][agentId])
            
            if(dataSetsChart){
              for (let b = 0; b < dataSetsChart.length; b++) {
                general_sum = general_sum + parseInt(dataSetsChart[b]);
              }
            }
          }
        }
        return (
          <div className="box">
            <div className="box-header ml20">
              <img src="images/side_app_icon.png" width="18" height="19" alt="" />
              <h3 className="box-title fontSize17 ml15 mt15 color0F1D30 fontWeight500 fontInter">{this.state.viewing_graph_text}&nbsp;</h3>
              
              <div style={{display:'inline-block', float:'right'}}>
                <button className={this.state.togglebtn_viewing ? "btn btn-primary mr10 mt5" : "btn btn-primary mr10 mt5 fadebutton"} style={{ background:'#27AFAF', border: '0px',borderRadius:'4px'}} id="togglebtn_viewing" onClick={() => this.toggle_viewing_graph("togglebtn_viewing")}> Viewings </button>
                <button className={this.state.togglebtn_general ? "btn btn-primary mr10 mt5" : "btn btn-primary mr10 mt5 fadebutton"} style={{ background:'#F09A56', border: '0px',borderRadius:'4px'}} id="togglebtn_general" onClick={() => this.toggle_viewing_graph("togglebtn_general")}>  General </button>
              
                <span className="fontSize15 fontInter color8591A0 mt10" style={{display: 'inline-block'}}>Current Total
                &nbsp;&nbsp;
                {this.state.togglebtn_viewing ?
                  <b>{appt_sum}</b>
                  :
                  <b>{general_sum}</b>
                }
                </span>
              </div>
            </div>
            <hr style={{ borderTop: '1px solid #DAEEEC !important' }} />
            <div className="box-body table-responsive agentListingTable">
            {this.state.togglebtn_viewing ?
              (this.props.data && this.props.appt_lables) ?
                this.renderAppointmentChart() : null
                :
              (this.props.data && this.props.general_lables) ?
                this.renderGeneralChart() : null
            }
            </div>
          </div>
        )
      case '2':
        var prop_sum = 0;
        if(this.props.property_agen_count ){
          if (this.props.property_agen_count.length > 0) {
            for (let a = 0; a < this.props.data.length; a++) {
              agentId = agents[a].id;
              dataSetsChart = (this.props.property_agen_count[0][agentId])
              if(dataSetsChart){
                prop_sum = Number(prop_sum) + Number(dataSetsChart.slice(-1).pop())
              }
            }
          }
        }
        return (
          <div className="box">
            <div className="box-header ml20">
              <img src="images/side_prop_icon.png" width="24" height="19" alt="" />
              <h3 className="box-title fontSize17 ml15 mt15 color0F1D30 fontWeight500 fontInter">Properties&nbsp; <Icon icon={informationIcon} className="color8591A0" data-place="top" data-for="registerTip" data-tip="Date range won't effect this graph" style={{fontSize: '20px', verticalAlign: "bottom"}}/></h3>
              <span className="fontSize15 fontInter color8591A0 mt10" style={{ float: 'right' }}>Current Total
              &nbsp;&nbsp;
              <b>{prop_sum}</b></span>
            </div>
            <hr style={{ borderTop: '1px solid #DAEEEC !important' }} />
            <div className="box-body table-responsive agentListingTable">
            { (this.props.data && this.props.property_labels) ?
              this.renderPropertyChart() : null
            }
            </div>
          </div>
        )
      case '3':
        var prop_sale_sum = 0;
        if(this.props.property_sale_agen_count ){
          if (this.props.property_sale_agen_count.length > 0) {
            for (let a = 0; a < this.props.data.length; a++) {
              agentId = agents[a].id;
              dataSetsChart = (this.props.property_sale_agen_count[0][agentId])
              if(dataSetsChart){
                for (let b = 0; b < dataSetsChart.length; b++) {
                  prop_sale_sum = Number(prop_sale_sum) + Number(dataSetsChart[b]);
                }
              }
            }
          }
        }
        return (
          <div className="box">
            <div className="box-header ml20">
              <Icon icon={houseUser} style={{color: '#4aa49c', fontSize: '20px', verticalAlign: 'middle'}} />
              <h3 className="box-title fontSize15 ml15 mt15 color0F1D30 fontWeight500 fontInter" style={{verticalAlign: 'bottom'}}>{this.state.property_sale_graph_text}&nbsp; &nbsp; <Icon icon={informationIcon} className="color8591A0" data-place="top" data-for="registerTip" data-tip="Date range won't effect this graph" style={{fontSize: '20px', verticalAlign: "bottom"}}/></h3>
              <div style={{display:'inline-block', float:'right'}}>
                <button className={this.state.togglebtn_forsale ? "btn btn-primary mr10 mt5" : "btn btn-primary mr10 mt5 fadebutton"} style={{ background:'#27AFAF', border: '0px',borderRadius:'4px'}} id="togglebtn_forsale" onClick={() => this.toggle_property_sales_graph("togglebtn_forsale")}> For Sale </button>
                <button className={this.state.togglebtn_rental ? "btn btn-primary mr10 mt5" : "btn btn-primary mr10 mt5 fadebutton"} style={{ background:'#F09A56', border: '0px',borderRadius:'4px'}} id="togglebtn_rental" onClick={() => this.toggle_property_sales_graph("togglebtn_rental")}>  Rental </button>                  
                <span className="fontSize15 fontInter color8591A0 mt10" style={{display: 'inline-block'}}>Current Total
                &nbsp;&nbsp;
                <b>{prop_sale_sum}</b></span>
              </div>
            </div>
            <hr style={{ borderTop: '1px solid #DAEEEC !important' }} />
            <div className="box-body table-responsive agentListingTable">
              {
                (this.props.data && this.props.property_sale_labels)?
                this.renderPropertySaleChart() : null
              }
            </div>
          </div>
        )
      case '4':
        var prop_sold_sum = 0;
        if(this.props.property_sold_agen_count ){
          if (this.props.property_sold_agen_count.length > 0) {
            for (let a = 0; a < this.props.data.length; a++) {
              agentId = agents[a].id;
              dataSetsChart = (this.props.property_sold_agen_count[0][agentId])
              if(dataSetsChart){
                for (let b = 0; b < dataSetsChart.length; b++) {
                  prop_sold_sum = Number(prop_sold_sum) + Number(dataSetsChart[b]);
                }
              }
            }
          }
        }
        return (
          <div className="box">
            <div className="box-header ml20" style={{paddingBottom:7}}>
              <Icon icon={houseUser} style={{color: '#4aa49c', fontSize: '20px', verticalAlign: 'middle'}} />
              <h3 className="box-title fontSize15 ml15 mt15 color0F1D30 fontWeight500 fontInter">{this.state.property_sold_graph_text}&nbsp; &nbsp; <Icon icon={informationIcon} className="color8591A0" data-place="top" data-for="registerTip" data-tip="Date range won't effect this graph" style={{fontSize: '20px', verticalAlign: "bottom"}}/></h3>
              <div className="select-wrap mb0 ml10" style={{display:"inline-block", border:"2px solid #E0EDED", padding:"6px", width:"auto"}}>
                <select className="filter_dropdown" style={{display:"inline", width: "100%", fontSize:"14px", padding:"0px 5px 0px 0px"}} id="sales_filter" name="sales_filter" ref="sales_filter" onChange = {this.toggle_property_sold_graph}>
                  <option value="sold" selected>Sold</option>
                  <option value="rented">All rented</option>
                  <option value="rented_monthly">Monthly rented</option>
                  <option value="rented_once">Once off rented</option>
                </select>
              </div>
              <div style={{display:'inline-block', float:'right'}}>
                <span className="fontSize15 fontInter color8591A0 mt10" style={{display: 'inline-block'}}>Current Total
                &nbsp;&nbsp;
                <b>{prop_sold_sum}</b></span>
              </div>
            </div>
            <hr style={{ borderTop: '1px solid #DAEEEC !important' }} />
            <div className="box-body table-responsive agentListingTable">
              {
                (this.props.data && this.props.property_sold_labels)?
                this.renderPropertySoldChart() : null
              }
            </div>
          </div>
        )
      case '5':
        var revenue_sum = 0;
        if (this.props.chart_revenue_cnt ) {
          if (this.props.chart_revenue_cnt.length > 0) {
            for (let a = 0; a < this.props.data.length; a++) {
              agentId = agents[a].id;
              dataSetsChart = (this.props.chart_revenue_cnt[0][agentId])

              if(dataSetsChart){
                for (let b = 0; b < dataSetsChart.length; b++) {
                  revenue_sum = revenue_sum + parseFloat(dataSetsChart[b]);
                }
              }
            }
          }
        }
        return (<>
          <div className="box">
            <div className="box-header ml20" style={{paddingBottom:7}}>
              <img src="images/Sale.png" width="24" height="19" alt="" />
              <h3 className="box-title fontSize15 ml15 mt15 color0F1D30 fontWeight500 fontInter">Revenue&nbsp;</h3>
              <div className="select-wrap mb0 ml20" style={{display:"inline-block", border:"2px solid #E0EDED", padding:"6px", width:"auto"}}>
                  <select className="filter_dropdown" style={{display:"inline", width: "100%", fontSize:"14px", padding:"0px 5px 0px 0px"}} id="revenue_filter" name="revenue_filter" ref="revenue_filter" onChange = {this.get_revenue_property_graph}>
                    <option value="sales" selected>Sales</option>
                    <option value="rented">All rentals</option>
                    <option value="monthly_rental">Monthly rentals</option>
                    <option value="once_off_rental">Once off rentals</option>
                  </select>
              </div>
              <div style={{display:'inline-block', float:'right'}}>                  
                <span className="fontSize15 fontInter color8591A0 mt10" style={{display: 'inline-block'}}>Current Total
                  &nbsp;&nbsp;                    
                  <b>{this.props.revenue_currency ? this.props.revenue_currency : null} {revenue_sum ? revenue_sum.toFixed(2) : revenue_sum}</b>
                </span>
              </div>
            </div>
            <hr style={{ borderTop: '1px solid #DAEEEC !important' }} />
            <div className="box-body table-responsive agentListingTable">
              {(this.props.data && this.props.revenue_labels) ? this.renderRevenueChart() : null}
            </div>
          </div>
        </>
        )
      case '6':
        var stockpipeline_sum = 0;
        if(this.props.stockpipeline_count ){
          if (this.props.stockpipeline_count.length > 0) {
            for (let a = 0; a < this.props.data.length; a++) {
              agentId = agents[a].id;
              dataSetsChart = (this.props.stockpipeline_count[0][agentId])
              if(dataSetsChart){
                stockpipeline_sum = Number(stockpipeline_sum) + Number(dataSetsChart.slice(-1).pop())
                // for (let b = 0; b < dataSetsChart.length; b++) {
                //   //stockpipeline_sum = stockpipeline_sum + parseInt(dataSetsChart[b]);
                // }
              }
            }
          }
        }
        return (
          <div className="box">
            <div className="box-header ml20">
              <Icon icon={funnelDollar} style={{color: '#4eb3aa', fontSize: '20px', verticalAlign: 'middle'}} />
              <h3 className="box-title fontSize17 ml15 mt15 color0F1D30 fontWeight500 fontInter">Stock pipeline&nbsp; <Icon icon={informationIcon} className="color8591A0" data-place="top" data-for="registerTip" data-tip="Date range won't effect this graph" style={{fontSize: '20px', verticalAlign: "bottom"}}/></h3>
              <span className="fontSize15 fontInter color8591A0 mt10" style={{ float: 'right' }}>Current Total
              &nbsp;&nbsp;
              <b>{stockpipeline_sum}</b></span>
            </div>
            <hr style={{ borderTop: '1px solid #DAEEEC !important' }} />
            <div className="box-body table-responsive agentListingTable">
              { (this.props.data && this.props.stockpipeline_labels)?
                this.renderStockPipelineChart() : null
              }
            </div>
          </div>
        )
      case '7':
        var coldcall_sum = 0;
        if(this.props.coldcalls_count ){
          if (this.props.coldcalls_count.length > 0) {
            for (let a = 0; a < this.props.data.length; a++) {
              agentId = agents[a].id;
              dataSetsChart = (this.props.coldcalls_count[0][agentId])
              if(dataSetsChart){
                for (let b = 0; b < dataSetsChart.length; b++) {
                  coldcall_sum = coldcall_sum + parseInt(dataSetsChart[b]);
                }
              }
            }
          }
        }
        return (
          <div className="box">
            <div className="box-header ml20">
              <Icon icon={callForward} style={{color: '#4eb3aa', fontSize: '20px', verticalAlign: 'middle'}} />
              <h3 className="box-title fontSize17 ml15 mt15 color0F1D30 fontWeight500 fontInter">Call / Contact&nbsp; <Icon icon={informationIcon} className="color8591A0" data-place="top" data-for="registerTip" data-tip="Date range won't effect this graph" style={{fontSize: '20px', verticalAlign: "bottom"}}/></h3>
              <span className="fontSize15 fontInter color8591A0 mt10" style={{ float: 'right' }}>Current Total
              &nbsp;&nbsp;
              <b>{coldcall_sum}</b></span>
            </div>
            <hr style={{ borderTop: '1px solid #DAEEEC !important' }} />
            <div className="box-body table-responsive agentListingTable">
              {(this.props.data && this.props.coldcalls_labels)?
                this.renderColdCallsChart() : null
              }
            </div>
          </div>
        )
      case '8':
        var profitability_sum = 0;
        if (this.props.profitability_chart ) {
          if (this.props.profitability_chart.length > 0) {
            for (let a = 0; a < this.props.data.length; a++) {
              agentId = agents[a].id;
              dataSetsChart = (this.props.profitability_chart[0][agentId])

              if(dataSetsChart){
                for (let b = 0; b < dataSetsChart.length; b++) {
                  if(dataSetsChart[b])
                  profitability_sum = profitability_sum + parseFloat(dataSetsChart[b]);
                }
              }
            }
          }
        }
        return (
          <div className="box">
            <div className="box-header ml20">
              <img src="images/Revenue_icon.png" width="24" height="19" alt="" />
              <h3 className="box-title fontSize17 ml15 mt15 color0F1D30 fontWeight500 fontInter">Profitability&nbsp;</h3>
              <span className="fontSize15 fontInter color8591A0 mt10" style={{ float: 'right' }}>Current Total
              &nbsp;&nbsp;
            <b>{profitability_sum ? profitability_sum.toFixed(2) : profitability_sum}</b></span>
            </div>
            <hr style={{ borderTop: '1px solid #DAEEEC !important' }} />
            <div className="box-body table-responsive agentListingTable">
            {(this.props.data && this.props.profitability_chart) ?
              this.renderProfitability() : null
            }
            </div>
          </div>
        )
      default:
        return false
    }
  }

  renderPieChart(settingLen){
    if(this.props.appointmentCount){
      //var confirmed_appointment = this.props.appointmentCount.confirmed_appointment;
      var cancelled_appointment = this.props.appointmentCount.cancelled_appointment;
      var completed_appointment = this.props.appointmentCount.completed_appointment;
      //var pending_appointment = this.props.appointmentCount.pending_appointment;
      var scheduled_appointment = this.props.appointmentCount.scheduled_appointment;
    }
    if(this.props.generalAppointmentCount){
      var general_cancelled_appointment = this.props.generalAppointmentCount.cancelled_appointment;
      var general_completed_appointment = this.props.generalAppointmentCount.completed_appointment;
      //var general_pending_appointment = this.props.generalAppointmentCount.pending_appointment;
      var general_scheduled_appointment = this.props.generalAppointmentCount.scheduled_appointment;
    }

    const appointmentDataSets = {
      labels: ['Scheduled Viewings', 'Completed Viewings', 'Cancelled Viewings'],
      datasets: [
        {
          label: 'Viewings',
          backgroundColor: [
            '#257770',
            '#1b958a',
            '#96ddd7'
          ],
          hoverBackgroundColor: [
            '#257770',
            '#1b958a',
            '#96ddd7'
          ],
          data: [scheduled_appointment, completed_appointment, cancelled_appointment  ]
        }
      ]
    }
    
    const generalAppointmentDataSets = {
      labels: ['Scheduled', 'Completed', 'Cancelled'],
      datasets: [
        {
          label: 'General',
          backgroundColor: [
            '#257770',
            '#1b958a',
            '#96ddd7'
          ],
          hoverBackgroundColor: [
            '#257770',
            '#1b958a',
            '#96ddd7'
          ],
          data: [general_scheduled_appointment, general_completed_appointment, general_cancelled_appointment  ]
        }
      ]
    }
    
    if(this.props.soldPropertyValues){
      var sale_prop   = this.props.soldPropertyValues.sale_prop;
      var rent_prop   = this.props.soldPropertyValues.rent_prop;
      var sold_prop   = this.props.soldPropertyValues.sold_prop;
      var rented      = this.props.soldPropertyValues.rented_prop;
      //var monthly_rented_prop = this.props.soldPropertyValues.monthly_rented_prop;
      //var once_off_rented_prop = this.props.soldPropertyValues.once_off_rented_prop;
    }
    const propertiesDataSets = {
      labels: ['For Sale', 'For Rent', 'Sold', 'Rented'],
      datasets: [
        {
          label: 'Properties',
          backgroundColor: [
            '#1F605A',
            '#257770',
            '#1B958A',
            '#5CB6AE',
          ],
          hoverBackgroundColor: [
            '#1F605A',
            '#257770',
            '#1B958A',
            '#5CB6AE',
          ],
          data: [sale_prop, rent_prop, sold_prop, rented]
        }
      ]
    }

    /* Linked User Data Set For Seller */ 
    // if(this.props.linkedUserCount){
    //   var seller_not_linked = this.props.linkedUserCount.seller_not_linked;
    //   var seller_linked = this.props.linkedUserCount.seller_linked;
    // }
    // const sellerLinkedDataSets = {
    //   labels: ['Seller / Owner linked', 'Seller / Owner link pending'],
    //   datasets: [
    //     {
    //       label: 'Properties',
    //       backgroundColor: [
    //         '#257770',
    //         '#5CB6AE'
    //       ],
    //       hoverBackgroundColor: [
    //         '#257770',
    //         '#5CB6AE'
    //       ],
    //       data: [seller_linked, seller_not_linked]
    //     }
    //   ]
    // }

    /* Linked User Data Set For Occupant */
    // if(this.props.linkedUserCount){
    //   var occupant_linked = this.props.linkedUserCount.occupant_linked;
    //   var occupant_not_linked = this.props.linkedUserCount.occupant_not_linked;
    // }

    // const occupantLinkedDataSets = {
    //   labels: ['Occupant Linked', 'Occupant Not Linked'],
    //   datasets: [
    //     {
    //       label: 'Occupant',
    //       backgroundColor: [
    //         '#257770',
    //         '#5CB6AE'
    //       ],
    //       hoverBackgroundColor: [
    //         '#257770',
    //         '#5CB6AE'
    //       ],
    //       data: [occupant_linked, occupant_not_linked]
    //     }
    //   ]
    // }

    //var labels = ['Occupant Link Pending', 'Occupant Linked', 'Occupant not Linked'];

    /* Linked User Data Set For Prop Value */ 
    //label
    // let sale_prop_value = '';
    // let rent_prop_value = '';
    // let sold_prop_value = '';
    // let rented_prop_value = '';
    
    //value
    // let sale_prop_value_raw= '';
    // let rent_prop_value_raw = '';
    // let sold_prop_value_raw = '';
    // let rented_prop_value_raw = '';

    // if(this.props.soldPropertyValues){
    //   sale_prop_value = this.props.soldPropertyValues.sale_prop_value;
    //   rent_prop_value = this.props.soldPropertyValues.rent_prop_value;
    //   sold_prop_value = this.props.soldPropertyValues.sold_prop_value;
    //   rented_prop_value = this.props.soldPropertyValues.rented_prop_value;

    //   sale_prop_value_raw = this.props.soldPropertyValues.sale_prop_value_raw;
    //   rent_prop_value_raw = this.props.soldPropertyValues.rent_prop_value_raw;
    //   sold_prop_value_raw = this.props.soldPropertyValues.sold_prop_value_raw;
    //   rented_prop_value_raw = this.props.soldPropertyValues.rented_prop_value_raw;
    // }
    
    // const propValueDataSets = {
    //   labels: ["For Sale", "For Rent", "Sold", "Rented"],
    //   datasets: [
    //     {
    //       label: [sale_prop_value, rent_prop_value, sold_prop_value, rented_prop_value],
    //       backgroundColor: [
    //         '#1F605A',
    //         '#257770',
    //         '#1B958A',
    //         '#5CB6AE'
    //       ],
    //       hoverBackgroundColor: [
    //         '#1F605A',
    //         '#257770',
    //         '#1B958A',
    //         '#5CB6AE'
    //       ],
    //       data: [sale_prop_value_raw, rent_prop_value_raw, sold_prop_value_raw, rented_prop_value_raw]
    //     }
    //   ]
    // }
    return(
      <div className="row" style={settingLen === 0 ? {marginTop: 10} : {paddingBottom:25}}>
        <div className="col-md-4 padRight5">
          <div className="box box-height">
            <div className="box-header ml20">
              <img src="images/side_prop_icon.png" width="24" height="19" alt="" />
              <h3 className="box-title fontSize17 ml15 mt15 color0F1D30 fontWeight500 fontInter">Properties&nbsp;
                <span className="color8591A0" ><span style={{ verticalAlign: 'super' }}>.</span>&nbsp;
                  {this.props.total_prop ? this.props.total_prop : 0}
                </span>
              </h3>
            </div>
            <hr style={{ borderTop: '1px solid #DAEEEC !important' }} />
            <div className="box-body">
              <div className="height45">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-8">
                        {(Number(sale_prop) === Number(0)) && (Number(rent_prop) === Number(0)) && (Number(sold_prop) === Number(0)) ?
                          <h4 style={{height:"136px", lineHeight:"5"}} className="no_data"><center>No data to display</center></h4>
                          :
                          <Pie
                            data={propertiesDataSets}
                            options={{
                              title: {
                                display: false,
                                text: 'Property Value',
                                fontSize: 10,  
                              },
                              elements: {
                                arc: {
                                  borderWidth: 0
                                }
                              },
                              legend: {
                                display: false,
                                position: 'right'
                              }
                            }}
                          />
                        }
                      </div>
                      <div className="col-md-4">
                        {this.props.soldPropertyValues ?
                          <div>
                            <div className="textAlignCenter mt10">
                              <span className="dot2" style={{ backgroundColor: '#1F605A' }}></span>
                              <span className="padLeft8"><b>
                                {this.props.soldPropertyValues.sale_prop ? this.props.soldPropertyValues.sale_prop : 0}
                              </b>
                                <span className="padLeft5">For Sale</span>
                              </span>
                            </div>

                            <div className="textAlignCenter mt10">
                              <span className="dot2" style={{ backgroundColor: '#257770' }}></span>
                              <span className="padLeft8"><b>
                                {this.props.soldPropertyValues.rent_prop ? this.props.soldPropertyValues.rent_prop : 0}
                              </b>
                                <span className="padLeft5">For Rent</span>
                              </span>
                            </div>
                            <div className="textAlignCenter mt10">
                              <span className="dot2" style={{ backgroundColor: '#1B958A' }}></span>
                              <span className="padLeft8"><b>
                                {this.props.soldPropertyValues.sold_prop ? this.props.soldPropertyValues.sold_prop : 0}
                              </b>
                                <span className="padLeft5">Sold</span>
                              </span>
                            </div>
                            <div className="textAlignCenter mt10">
                              <span className="dot2" style={{ backgroundColor: '#5CB6AE' }}></span>
                              <span className="padLeft8"><b>
                                {this.props.soldPropertyValues.rented_prop ? this.props.soldPropertyValues.rented_prop : 0}
                              </b>
                                <span className="padLeft5">Rented</span>
                              </span>
                            </div>
                          </div>
                        : null }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-8 padLeft5">
          <div className="box box-height">
            <div className="box-header ml20">
              <img src="images/side_app_icon.png" width="18" height="20" alt="" />
              <h3 className="box-title fontSize17 ml15 mt15 color0F1D30 fontWeight500 fontInter">Appointment&nbsp;
                <span className="color8591A0" ><span style={{ verticalAlign: 'super' }}>.</span>&nbsp;
                  {this.props.appointmentCount && this.props.generalAppointmentCount ? Number(this.props.appointmentCount.total_appointment+this.props.generalAppointmentCount.total_appointment) : 0}
                </span>
              </h3>
            </div>
            <hr style={{ borderTop: '1px solid #DAEEEC !important' }} />
            <div className="box-body">
              <div className="height45">
                
                <div className="row"> 
                  <div className="col-md-6 verticalLine"> 
                    <h3 className="box-title fontSize17 ml35 color0F1D30 fontWeight500 fontInter" style={{marginTop: 0}}>Viewings &nbsp;
                      <span className="color8591A0" ><span style={{ verticalAlign: 'super' }}>.</span>&nbsp;
                        {this.props.appointmentCount ? this.props.appointmentCount.total_appointment : 0}
                      </span>
                    </h3>
                    <div className="row"> 
                      <div className="col-md-7">
                        
                        {(Number(scheduled_appointment) === Number(0)) && (Number(cancelled_appointment) === Number(0)) && (Number(completed_appointment) === Number(0)) ?
                          <h4 style={{height:"136px", lineHeight:"5"}} className="no_data"><center>No data to display</center></h4>
                        :
                          <Pie
                            data={appointmentDataSets}
                            width={383}
                            options={{
                              title: {
                                display: false,
                                text: 'Appointment Value',
                                fontSize: 10
                              },
                              elements: {
                                arc: {
                                  borderWidth: 0
                                }
                              },
                              legend: {
                                display: false,
                                position: 'right'
                              }
                            }}
                          />
                        }
                      </div>
                      <div className="col-md-5">
                        {this.props.appointmentCount ?
                          <div className="mt10 textAlignCenter" style={{marginBottom: "12px"}}>
                          {/* <div  style={{ marginLeft: '-40px', marginTop: '50px' }}> */}
                            <p className="property-side-menu"><span className="dot2" style={{ backgroundColor: '#257770' }}></span>&nbsp;&nbsp;&nbsp;
                              <b>
                                {this.props.appointmentCount.scheduled_appointment ? this.props.appointmentCount.scheduled_appointment : 0}
                              </b>&nbsp;&nbsp;Scheduled</p>
                            <p className="property-side-menu"><span className="dot2" style={{ backgroundColor: '#1B958A' }}></span>&nbsp;&nbsp;&nbsp;
                              <b>
                                {this.props.appointmentCount.completed_appointment ? this.props.appointmentCount.completed_appointment : 0}
                              </b>&nbsp;&nbsp;Completed</p>
                            <p className="property-side-menu"><span className="dot2" style={{ backgroundColor: '#96DDD7' }}></span>&nbsp;&nbsp;&nbsp;
                              <b>
                                {this.props.appointmentCount.cancelled_appointment ? this.props.appointmentCount.cancelled_appointment : 0}
                              </b>&nbsp;&nbsp;Cancelled</p>
                          </div>
                        : null }
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <h3 className="box-title fontSize17 ml35 color0F1D30 fontWeight500 fontInter" style={{marginTop:"0px"}}>General Appointments&nbsp;
                      <span className="color8591A0" ><span style={{ verticalAlign: 'super' }}>.</span>&nbsp;
                        {this.props.generalAppointmentCount ? this.props.generalAppointmentCount.total_appointment : 0}
                      </span>
                    </h3>
                    <div className="row">
                      <div className="col-md-7" >
                        
                        {(Number(general_scheduled_appointment) === Number(0)) && (Number(general_cancelled_appointment) === Number(0)) && (Number(general_completed_appointment) === Number(0)) ?
                          <h4 style={{height:"136px", lineHeight:"5"}} className="no_data"><center>No data to display</center></h4>
                        :
                          <Pie
                            data={generalAppointmentDataSets}
                            width={383}
                            options={{
                              title: {
                                display: false,
                                text: 'Appointment Value',
                                fontSize: 10
                              },
                              elements: {
                                arc: {
                                  borderWidth: 0
                                }
                              },
                              legend: {
                                display: false,
                                position: 'right'
                              }
                            }}
                          />
                        }
                      </div>
                      <div className="col-md-5">
                        {this.props.generalAppointmentCount ?
                          <div className="mt10 textAlignCenter">
                          {/* <div  style={{ marginLeft: '-40px', marginTop: '50px' }}> */}
                            <p className="property-side-menu"><span className="dot2" style={{ backgroundColor: '#257770' }}></span>&nbsp;&nbsp;&nbsp;
                              <b>
                                {this.props.generalAppointmentCount.scheduled_appointment ? this.props.generalAppointmentCount.scheduled_appointment : 0}
                              </b>&nbsp;&nbsp;Scheduled</p>
                            <p className="property-side-menu"><span className="dot2" style={{ backgroundColor: '#1B958A' }}></span>&nbsp;&nbsp;&nbsp;
                              <b>
                                {this.props.generalAppointmentCount.completed_appointment ? this.props.generalAppointmentCount.completed_appointment : 0}
                              </b>&nbsp;&nbsp;Completed</p>
                            <p className="property-side-menu"><span className="dot2" style={{ backgroundColor: '#96DDD7' }}></span>&nbsp;&nbsp;&nbsp;
                              <b>
                                {this.props.generalAppointmentCount.cancelled_appointment ? this.props.generalAppointmentCount.cancelled_appointment : 0}
                              </b>&nbsp;&nbsp;Cancelled</p>
                          </div>
                        : null }
                      </div>
                    </div>
                  </div>
                </div>
              
              </div>
            </div>
          </div>
        </div>  { /* Col-md-12 closed here */}
      </div>
    )
  }

  handleShowModal = (event) => {
    let id = event.target.getAttribute("data-user_id");
    this.props.reset_agent_modal();
    var firstOfMonth = new Date("1/1/" + (new Date()).getFullYear());
    //var lastOfMonth = new Date(new Date().getFullYear(), 11, 31, 0, 0, 0);
    const agent_id = id;

    this.setState({
      agent_id: agent_id,
      startDate: firstOfMonth
    },()=> {
      window.$("#userProfileModal").modal('show');
      $(".user-modal-overview").click();
    })
  }

  changeDateFormat(fromDate){
    var d = new Date(fromDate),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year,month,day].join('-');
  }

  changeDateFormat_DMY(fromDate){
    var d = new Date(fromDate),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [day,month,year].join('-');
  }
  
  dateWisePropertyAppointment = (fromDate,toDate,prop_id,agent_id, date_sold, fromnetworking) => {
    fromDate = this.changeDateFormat(fromDate);
    toDate = this.changeDateFormat(toDate);

    this.setState({
      appt_loading:true
    })

    const token = localStorage.getItem('token');
    if(Number(fromnetworking) === 1){
      var property_notes_user_id = this.state.agent_id;
      let agent_id = this.props.property_detail.agent_id;
      let fromnetworking = 1;
      this.props.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold, fromnetworking, property_notes_user_id)
    }else{
      this.props.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold)
    }

  }

  callbackFunction = (globalAgentList, globalAgentListName, globalAgentListImage) => {      
    this.setState({
      globalAgentList: [...globalAgentList],
      globalAgentListName: [...globalAgentListName ],
      globalAgentListImage: [...globalAgentListImage]
    })
  }

  toggle_auth_key = () =>{
    if(this.state.show_auth_key){
      this.setState({show_auth_key: false})
    }else{
      this.setState({show_auth_key: true})
    }
  }

  copyAuthKey_overview = (elementId) =>{
    var aux = document.createElement("input");
    aux.setAttribute("value", document.getElementById(elementId).value);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);

    $('#copied-success_overview').fadeIn(800);
    $('#copied-success_overview').fadeOut(800);
  }

  toggle_viewing_graph = (id) =>{
    if(id === "togglebtn_viewing"){
      this.setState({
        togglebtn_viewing: true,
        togglebtn_general: false,
        viewing_graph_text: "Viewings"
      })  
    }else if(id === "togglebtn_general"){
      this.setState({
        togglebtn_viewing: false,
        togglebtn_general: true,
        viewing_graph_text: "General Appointments"
      })
    }
  }

  toggle_property_sales_graph = (id)=>{
    var d = new Date();
    var year = d.getFullYear();
    
    var fromDate_property = [year-1, 12, "01"].join('-');
    var month = '' + (d.getMonth() + 1);
    var day = '' + d.getDate();
    year = d.getFullYear();
  
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    var toDate_stockpipeline = [year, month, day].join('-');
    var agent_list = JSON.parse(localStorage.getItem('data')) ? JSON.parse(localStorage.getItem('data')) : this.props.data;
    const token = localStorage.getItem("token");
    var type='';
    if(id === "togglebtn_forsale"){
      this.setState({
        togglebtn_forsale: true,
        togglebtn_rental: false,
        property_sale_graph_text: "For Sale",
        sale_type: "sale"
      })
      type = "sale";
      this.props.get_property_sale_chart(fromDate_property, toDate_stockpipeline, type, agent_list, token);
    }else if(id === "togglebtn_rental"){
      this.setState({
        togglebtn_forsale: false,
        togglebtn_rental: true,
        property_sale_graph_text: "Rental",
        sale_type: "rent"
      })
      type = "rent";
      this.props.get_property_sale_chart(fromDate_property, toDate_stockpipeline, type, agent_list, token);
    }
  }

  toggle_property_sold_graph = (event) =>{
    var d = new Date();
    var year = d.getFullYear();
    
    var fromDate_property = [year-1, 12, "01"].join('-');
    var month = '' + (d.getMonth() + 1);
    var day = '' + d.getDate();
    year = d.getFullYear();
  
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    var toDate_stockpipeline = [year, month, day].join('-');
    var agent_list = JSON.parse(localStorage.getItem('data')) ? JSON.parse(localStorage.getItem('data')) : this.props.data;
    const token = localStorage.getItem("token");
    var type='';
    if(event.target.value === "sold"){
      this.setState({
        togglebtn_sold: true,
        togglebtn_rented: false,
        property_sold_graph_text: "Sold",
        sold_type: "sold"
      })
      type = "sold";
    }else if(event.target.value === "rented"){
      this.setState({
        togglebtn_sold: false,
        togglebtn_rented: true,
        property_sold_graph_text: "Rented",
        sold_type: "rented"
      })
      type = "rented";
    }else if(event.target.value === "rented_monthly"){
      this.setState({
        togglebtn_sold: false,
        togglebtn_rented: true,
        property_sold_graph_text: "Rented monthly",
        sold_type: "rented_monthly"
      })
      type = "rented_monthly";
    }else if(event.target.value === "rented_once"){
      this.setState({
        togglebtn_sold: false,
        togglebtn_rented: true,
        property_sold_graph_text: "Rented once",
        sold_type: "rented_once"
      })
      type = "rented_once";
    }
    this.props.get_property_sale_chart(fromDate_property, toDate_stockpipeline, type, agent_list, token);
  }

  get_revenue_property_graph = (event) =>{
    var token = localStorage.getItem('token');
    var agent_list = JSON.parse(localStorage.getItem('data')) ? JSON.parse(localStorage.getItem('data')) : this.props.data;
    
    if(token && event.target.value){      
      if(event.target.value === "sales"){
        this.setState({
          togglebtn_sales: true,
          togglebtn_all_rental: false,
          togglebtn_monthly_rental: false,
          togglebtn_once_off_rental: false,
          revenue_type: "sales"
        }) 
      }else if(event.target.value === "rented"){
        this.setState({
          togglebtn_sales: false,
          togglebtn_all_rental: true,
          togglebtn_monthly_rental: false,
          togglebtn_once_off_rental: false,
          revenue_type: "rented"
        })
      }else if(event.target.value === "monthly_rental"){
        this.setState({
          togglebtn_sales: false,
          togglebtn_all_rental: false,
          togglebtn_monthly_rental: true,
          togglebtn_once_off_rental: false,
          revenue_type: "monthly_rental"
        })
      }else if(event.target.value === "once_off_rental"){
        this.setState({
          togglebtn_sales: false,
          togglebtn_all_rental: false,
          togglebtn_monthly_rental: false,
          togglebtn_once_off_rental: true,
          revenue_type: "once_off_rental"
        })
      }
      this.props.get_revenue_chart(this.state.from_date, this.state.to_date, event.target.value, agent_list, token);
    }
  }

  callbackSetDates = (fromDate, toDate) =>{
    this.setState({
      from_date: fromDate,
      to_date: toDate
    })
  }

  render() {
    columns = [
      {
        name: 'Agent Name',
        selector: 'agent_name',
        sortable: true,
        ignoreRowClick: true,
        width: "275px",
        //width: this.state.show_auth_key ? "275px" : "420px",
        // onClick={window.handleShowModal(`${row.user_id}`)}
        style: {marginLeft: '15px', display: 'block'},
        cell: row =><> 
                    <div data-user_id={row.user_id} className={Number(row.tracking) !== 0 ? "cursorPointer": null}> 
                      <img tag="allowRowEvents" data-user_id={row.user_id} alt="" src={row.image ? Constants.IMAGE_URL+row.image : "user-images/userDefault.png"} className="img-circle floatLeft mt5" width="54" height="54" onClick={Number(row.tracking) !== 0 ? this.handleShowModal : null}/>
                      <span className="color2D3E54 fontWeight600" style={{marginLeft: 5, fontFamily: "Inter"}}>
                        <span className="overview_datatable_name" tag="allowRowEvents" data-user_id={row.user_id} style={!this.state.show_auth_key ? !row.hash ? {top: "22px", textDecoration: "underline"} : {textDecoration: "underline"} : {top: "22px", textDecoration: "underline"}} onClick={Number(row.tracking) !== 0 ? this.handleShowModal : null}>
                          {row.firstname} {row.lastname}
                          {Number(row.tracking) === 0 ? <span tag="allowRowEvents" className="trackingspan">Tracking Off</span> : null }
                        </span>
                        {!this.state.show_auth_key ?
                        row.hash?
                          <span className="mb10">
                            <div className="datatable_clipboard mb15">
                              <input type="password" onClick={() => this.copyAuthKey_overview("copyClipboard_overview_"+row.user_id)} className="copy-input" value={row.hash} id={"copyClipboard_overview_"+row.user_id} readOnly={true} />
                              {/* <input type={this.state.show_auth_key ? "password" : "text"} onClick={() => this.copyAuthKey_overview("copyClipboard_overview_"+row.user_id)} className="copy-input" value={row.hash} id={"copyClipboard_overview_"+row.user_id} readOnly={true} /> */}
                              <button className="copy-btn" id="copyButton" onClick={() => this.copyAuthKey_overview("copyClipboard_overview_"+row.user_id)}><Icon icon={copyIcon} style={{fontSize: '20px', verticalAlign: 'middle'}} /></button>
                            </div>
                          </span>
                        :null
                        :null}
                      </span>
                    </div>
                    </>
      },
      {
        name: 'Properties',
        selector: 'prop_count',
        sortable: true,
        center: true
      },
      {
        name: 'Stock Pipeline',
        selector: 'stock_count',
        sortable: true,
        center: true
      },
      {
        name: 'Call/Contact',
        selector: 'call_log_count',
        sortable: true,
        center: true
      },
      {
        name: 'Viewings',
        selector: 'appointment_count',
        sortable: true,
        center: true
      },
      {
        name: 'General appts.',
        selector: 'general_appointment_count',
        sortable: true,
        center: true
      },
      {
        name: 'Sold',
        selector: 'total_sold_properties',
        sortable: true,
        center: true
      },
      {
        name: 'Rented',
        selector: 'total_rented_properties',
        sortable: true,
        center: true
      },
      {
        name: 'Linked',
        selector: 'linked',
        sortable: true,
        center: true
      },
      {
        name: 'Unlinked',
        selector: 'unlinked',
        sortable: true,
        center: true
      },
    ];
    
    if(this.props.data){
      data =
        this.props.data.map((data, index) => (
        {
          user_id:           data.user_id,
          tracking:          data.tracking_prefrence,
          firstname:         data.firstname,
          lastname:          data.lastname,
          image:             data.image,
          agent_name:        data.firstname +" "+ data.lastname,
          hash:              data.hash,
          prop_count:        Number(data.tracking_prefrence) === 0 ? 0 : !data.prop_count ? 0 : Number(data.prop_count),
          stock_count:       Number(data.tracking_prefrence) === 0 ? 0 : !data.stock_count ? 0 : Number(data.stock_count),
          call_log_count:    Number(data.tracking_prefrence) === 0 ? 0 : !data.call_log_count ? 0 : Number(data.call_log_count),
          appointment_count: Number(data.tracking_prefrence) === 0 ? 0 : !data.appointment_count ? 0 : Number(data.appointment_count),
          total_sold_properties:     Number(data.tracking_prefrence) === 0 ? 0 : !data.total_sold_properties ? 0 : Number(data.total_sold_properties),
          total_rented_properties:   Number(data.tracking_prefrence) === 0 ? 0 : !data.total_rented_properties ? 0 : Number(data.total_rented_properties),
          general_appointment_count: Number(data.tracking_prefrence) === 0 ? 0 : !data.general_appointment_count ? 0 : Number(data.general_appointment_count),
          linked: Number(data.tracking_prefrence) === 0 ? 0 : !data.linked_count ? 0 : Number(data.linked_count),
          unlinked: Number(data.tracking_prefrence) === 0 ? 0 : !data.unlinked_count ? 0 : Number(data.unlinked_count),
        }
        
      ))  
    }
      
    this.filteredItems = data.filter(
      (item) =>
      item.agent_name && item.agent_name.toLowerCase().includes(this.state.filterText.toLowerCase())
    );
    data_table_data = this.filteredItems

    let footer= "footer_color";
    if (window.innerWidth > document.body.clientWidth) {
      footer = 'footer';
    }

    var sellerLinkedMessage = null;
    sellerLinkedMessage = (<SellerLinkedMessage property_detail={this.props.property_detail} />);
    
    let file_name  = "Overview Data From- "+ this.changeDateFormat_DMY(this.props.page_from_date) +" To- "+ this.changeDateFormat_DMY(this.props.page_to_date);
    let sheet_name = "Report From- "+ this.changeDateFormat_DMY(this.props.page_from_date) +" To- "+this.changeDateFormat_DMY(this.props.page_to_date);
    //let _=this;
    return (<div>
      <IdleTimerContainer></IdleTimerContainer>
      {/* <Notifications /> */}
      <Helmet>
        <title>Roundr - Overview</title>
        <link rel="icon" href="https://roundr.app/wp-content/uploads/2018/03/favicon.png" type="image/png"></link>
      </Helmet>

      <div className="content-wrapper" style={{ marginLeft: '0px' }}>
        {<Header active="dashboard"/>}
        
        {this.props.data && this.props.settings.length > 0 ?
          <AgentList 
            parentCallbackSetDates = {this.callbackSetDates}
            parentCallback = {this.callbackFunction}
            agent_list={this.props.data}
            sale_type={this.state.sale_type}
            sold_type={this.state.sold_type}
            revenue_type={this.state.revenue_type}>
          </AgentList>
          : null
        }

        <section  style={{margin: '10px 30px 0px 30px'}}>
          {this.props.settings ? 
          <>
            {Number(this.props.settings.length) % 2 === 0 ?
              <div className='row'>  
                <div className="col-md-6">
                  {this.renderGraphs(this.props.settings[0])}
                </div>
                <div className="col-md-6">
                  {this.renderGraphs(this.props.settings[1])}
                </div>
              </div>
            : 
              <div className='row'>  
                <div className="col-md-12">
                  {this.renderGraphs(this.props.settings[0])}
                </div>
              </div>
            }
            {/* If graphs are more than 6 */}
            {Number(this.props.settings.length) > 6 ?
              <>
                {splitsettingarray ?
                  splitsettingarray.map((data,index) => {
                    return(
                    <div className='row' key={data}>
                      {data.map((d,i) => {
                        return (
                          <div className='col-md-4' key={d}>
                            {this.renderGraphs(d)}
                          </div>
                        )
                      })}  
                    </div>
                  )
                  })
                :null}
              </>
            : Number(this.props.settings.length) <= 6 ?
              <>
                {splitsettingarray ?
                  splitsettingarray.map((data,index) => {
                    return(
                    <div className='row' key={data}>
                      {data.map((d,i) => {
                        return (
                          <div className='col-md-6' key={d}>
                            {this.renderGraphs(d)}
                          </div>
                        )
                      })}  
                    </div>
                  )
                  })
                :null}
              </>
            :null
            }            
          </>
          :
            null 
          }

          {this.props.settings && this.props.settings.length === 0 ?
            this.renderPieChart(this.props.settings.length)
          : null}

          {/* Agent List */}
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="box-header ml20">
                <div className="row">
                  <div className="col-md-4">
                    <Icon icon={bxsUser} style={{color: '#4aa49c', fontSize: '24px', verticalAlign:'middle'}} />
                    <h3 className="box-title fontSize17 ml15 mt15 color0F1D30 fontWeight500 fontInter">&nbsp;Agents
                      <span className="color8591A0" ><span style={{ verticalAlign: 'super' }}>.</span>&nbsp;
                        {this.props.data ?
                          this.props.data.length : null
                        }
                      </span>
                    </h3>
                  </div>
                  <div className="col-md-4" style={{paddingLeft: "0px"}}>
                    <DateRange page="overview_page"/>
                  </div>
                  <div className="col-md-4"style={{paddingLeft: "0px"}}>
                    {this.props.data ? 
                      Number(this.props.data[0].pro_user) === Number(1) ?
                        <button className="btn btn-primary fontBold" onClick={this.toggle_auth_key} style={{ background:'#4AA49C', borderRadius:'4px', float: "right", marginTop:"5px", marginLeft:"15px"}}> <Icon className="message_icon" icon={this.state.show_auth_key ? bxsShow : bxsHide } height='20' width='20'/> {this.state.show_auth_key ? "Show API Key" : "Hide API Key"} </button>
                      : null
                    : null}
                    <div id="excel_report" style={{float:'Right', marginTop: '5px'}}>
                      <ExcelFile filename={file_name} element={<button className="btn btn-primary fontBold" style={{ background:'#4AA49C', borderRadius:'4px'}}> <Icon className="message_icon" icon={downloadFill} height='20' width='20'/> Export to Excel</button>}>
                        <ExcelSheet data={data_table_data} name={sheet_name}>
                          <ExcelColumn label="Agent Name" value={(col) => col.firstname + " " + col.lastname }/>
                          <ExcelColumn label="Tracking" value={(col) => col.tracking ? "ON" : "OFF"}/>                          
                          <ExcelColumn label="Properties" value="prop_count"/>
                          <ExcelColumn label="Stockpipline" value="stock_count"/>
                          <ExcelColumn label="Call/Contact" value="call_log_count"/>
                          <ExcelColumn label="Viewings" value="appointment_count"/>
                          <ExcelColumn label="General Appointment" value="general_appointment_count"/>
                          <ExcelColumn label="Sold" value="total_sold_properties"/>
                          <ExcelColumn label="Rented" value="total_rented_properties"/>
                          <ExcelColumn label="Linked" value="linked"/>
                          <ExcelColumn label="Unlinked" value="unlinked"/>
                        </ExcelSheet>
                      </ExcelFile>
                    </div>
                  </div>
                </div>
                </div>
                <div>
                  <DataTable
                    noHeader={true}
                    highlightOnHover={true}
                    pagination={true}
                    // progressPending="true"
                    // progressComponent={<Loader />}
                    subHeader
                    subHeaderComponent={this.getSubHeaderComponent()}
                    columns={columns}
                    data={this.filteredItems}
                    customStyles={customStyles}
                    ignoreRowClick={true}
                    defaultSortField={'agent_name'}
                  />
                </div>
              </div>
            </div>
          </div>    

          
          {this.props.settings && this.props.settings.length > 0 ?
            this.renderPieChart()
          : <><br/><br/></>}
          
          <div id="copied-success_overview" className="copied">
            <span>Copied!</span>
          </div>
        </section>
        
        { /* Agent Modal */}
          <AgentInformationModal 
          agent_id = { this.state.agent_id }
          handlePropertyModal={this.handlePropertyModal }
          />
        <PropertyDetailModal
          dateWisePropertyAppointment = { this.dateWisePropertyAppointment }
          property_detail={this.props.property_detail}
          appointment_cnt={this.props.appointment_cnt}
          general_cnt_property_modal= {this.props.general_cnt_property_modal}
          feedback={this.props.feedback}
          seller_message={this.props.seller_message}
          occupant_message={this.props.occupant_message}
          agent_data={this.props.agent_data}
          sellerLinkedMessage={sellerLinkedMessage}
          agent_id = { this.state.agent_id }
          html_export={this.props.html_export}
          property_notes_html={this.props.property_notes_html}
          office_notes_html={this.props.office_notes_html}
          date_sold = {this.props.date_sold}
        />
        <AddAppointment
          agent_data = {this.props.agent_data}
          agent_id = {this.state.agent_id}
        />
        
      </div>
      <Footer footer={footer}></Footer>
    </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    get_overview_data : (token) => dispatch(actions.get_overview_data(token)),
    //overview_data_table
    get_overview_data_with_date: (fromDate, toDate, token) => dispatch(actions.get_overview_data_with_date(fromDate, toDate, token)),
    get_property_data_modal: (fromDate, toDate, prop_id, agent_id, token, date_sold, fromnetworking, property_notes_user_id, from_archiveProperty) => dispatch(actions.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold, fromnetworking, property_notes_user_id, from_archiveProperty)),
    reset_agent_modal: () => dispatch(actions.reset_agent_modal()),
    reset_property_modal: () => dispatch(actions.reset_property_modal()),
    //toggle_property_chart
    get_property_sale_chart : (fromDate, toDate, type, agent_list, token) => dispatch(actions.get_property_sale_chart(fromDate, toDate, type, agent_list, token)),
    //toggle_revenue_chart
    get_revenue_chart : (fromDate, toDate, type, agent_list, token) => dispatch(actions.get_revenue_chart(fromDate, toDate, type, agent_list, token)),
    //set page for calendar
    set_page: (page, reset_date_range) => dispatch(actions.set_page(page, reset_date_range)),
    
    update_add_appointment_modal_state: (show_agents,show_property,from_property_page,from_stock_pipeline) => dispatch(actions.update_add_appointment_modal_state(show_agents,show_property,from_property_page,from_stock_pipeline)),
  };
}

const mapStateToProps = state => {
  return{
    token: state.auth.token,

    //overview data
    data: state.overview.data,
    total_prop: state.overview.total_prop,
    soldPropertyValues: state.overview.soldPropertyValues,
    linkedUserCount: state.overview.linkedUserCount,
    appointmentCount: state.overview.appointmentCount,
    generalAppointmentCount: state.overview.generalAppointmentCount,
    page_from_date: state.overview.page_from_date,
    page_to_date: state.overview.page_to_date,
    settings: state.overview.settings,

    //appointment_chart
    appt_lables: state.overview.appt_lables,
    chart_appt_count: state.overview.chart_appt_count,
    //temp_chart_appt_count: state.overview.temp_chart_appt_count,
    
    //general_chart
    general_lables: state.overview.general_lables,
    chart_general_count: state.overview.chart_general_count,
    //temp_chart_general_count: state.overview.temp_chart_general_count,
    
    //stockpipeline_chart
    stockpipeline_labels: state.overview.stockpipeline_labels,
    stockpipeline_count: state.overview.stockpipeline_count,
    
    //property_chart
    property_labels: state.overview.property_labels,
    property_agen_count: state.overview.property_agen_count,
    //temp_chart_property_count : state.overview.temp_chart_property_count,
    
    //property_sale_chart
    property_sale_labels: state.overview.property_sale_labels,
    property_sale_agen_count: state.overview.property_sale_agen_count,
    //temp_chart_property_count_sale : state.overview.temp_chart_property_count_sale,

    //property_sold_chart
    property_sold_labels: state.overview.property_sold_labels,
    property_sold_agen_count: state.overview.property_sold_agen_count,
    //temp_chart_property_count_sold

    //sales_chart
    sales_labels: state.overview.sales_labels,
    chart_sales_cnt: state.overview.chart_sales_cnt,
    //temp_chart_sales_count: state.overview.temp_chart_appt_count,
    
    //cold_call_chart
    coldcalls_labels: state.overview.coldcalls_labels,
    coldcalls_count: state.overview.coldcalls_count,
    
    //revenue_chart
    revenue_labels: state.overview.revenue_labels,
    chart_revenue_cnt: state.overview.chart_revenue_cnt,
    //temp_chart_revenue_count: state.overview.temp_chart_revenue_count,
    revenue_currency: state.overview.revenue_currency,
    
    //rental_revenue_chart
    rental_revenue_labels: state.overview.rental_revenue_labels,
    chart_rental_revenue_cnt: state.overview.chart_rental_revenue_cnt,

    //profitability_chart
    profitability_labels: state.overview.profitability_labels,
    profitability_chart: state.overview.profitability_chart,
    //temp_chart_profitability_count: state.overview.temp_chart_profitability_count

    //property_modal
    property_detail: state.property_modal.property_detail,
    appointment_cnt: state.property_modal.appointment_cnt,
    general_cnt_property_modal: state.property_modal.general_cnt_property_modal,
    feedback: state.property_modal.feedback,
    seller_message: state.property_modal.seller_message,
    occupant_message: state.property_modal.occupant_message,
    html_export :state.property_modal.html_export,
    property_notes_html: state.property_modal.property_notes_html,
    office_notes_html: state.property_modal.office_notes_html,

    //agent_modal
    agent_data: state.agent_modal.agent_data,

    //add appointment
    show_property_list: state.add_property_modal.show_property_list,
    from_property_page: state.add_property_modal.from_property_page
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Overview);
