import * as actionTypes from './actionsTypes';
import Constants from '../../constants/Constants';

var url='';
var myHeaders = '';
var raw = '';
var requestOptions = '';

export const get_subscription_detail = (token) => {
    return async dispatch =>{
        myHeaders = new Headers();
        myHeaders.append("content-type", "application/json");
        myHeaders.append("Authorization", token);
        raw = JSON.stringify({token: token});
        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw
        };

        await fetch(Constants.GET_SUBCRIPTION_DETAIL, requestOptions)
        .then(response => response.json())
        .then(data => {
            dispatch(subscription_detail(data));
        })
        .catch(error => console.log('error', error));
    };
}
export const subscription_detail = (data) => {
    return {
        type: actionTypes.GET_SUBCRIPTION_DETAIL,
        payment_completed: data.payment_completed ? data.payment_completed : null ,
        subscription_plan_id : data.office_subscription ? data.office_subscription.subscription_plan_id : null
    };
}

export const send_payment_callback = (completed, paddle_response, token)=>{
    return async dispatch =>{
        myHeaders = new Headers();
        myHeaders.append("content-type", "application/json");
        myHeaders.append("Authorization", token);
        raw = JSON.stringify({completed: completed, paddle_response: paddle_response});
        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw
        };
        
        await fetch(Constants.PAYMENT_CALLBACK, requestOptions)
        .then(response => response.json())
        .then(data => {
            dispatch(payment_callback(data.response));
        })
        .catch(error => console.log('error', error));
    };
}
export const payment_callback = (data)=> {
    return {
        type:actionTypes.PAYMENT_CALLBACK,
        callback_success: data.success
    }
}

export const get_transaction_log = ()=>{
    return async dispatch =>{
        url= Constants.PAYMENT_TRANSACTION_LOG;
        myHeaders = new Headers();
        myHeaders.append("content-type", "application/json");
        myHeaders.append("Authorization", localStorage.getItem('token'));
        raw = JSON.stringify({});
        
        requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };
        await fetch(url, requestOptions)
        .then(response => response.json())
        .then(data => {      
            dispatch(transaction_log(data));
        });
    };
}
export const transaction_log = (data) => {
    return {
        type: actionTypes.GET_TRANSACTION_LOG,
        transaction_log: data,
    };
}

export const get_pricing_plan_id = (token)=>{
    return async dispatch =>{
        url= Constants.GET_PRICING_PLAN_ID;
        myHeaders = new Headers();
        myHeaders.append("content-type", "application/json");
        myHeaders.append("Authorization", token);
        raw = JSON.stringify({});
        
        requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };
        await fetch(url, requestOptions)
        .then(response => response.json())
        .then(data => {      
            dispatch(pricing_plan_id(data));
        });
    };
}
export const pricing_plan_id = (data) => {
    return {
        type: actionTypes.GET_PRICING_PLAN_ID,
        pricing_plan: data,
    };
}

export const upgrade_subscription_quantity = (subscription_id, quantity, token)=>{
    return async dispatch =>{
        url= Constants.UPGRADE_SUBSCRIPTION_QUANTITY;
        myHeaders = new Headers();
        myHeaders.append("content-type", "application/json");
        myHeaders.append("Authorization", token);
        raw = JSON.stringify({
            subscription_id: subscription_id,
            qty: quantity
        });
        
        requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };
        await fetch(url, requestOptions)
        .then(response => response.json())
        .then(data => {      
            dispatch(upgrade_quantity(data));
        });
    };
}
export const upgrade_quantity = (data) => {
    return {
        type: actionTypes.UPGRADE_SUBSCRIPTION_QUANTITY,
        upgrade_next_payment: data.response.next_payment,
    };
}


export const send_subscription_email = (email, subject) =>{
    return async dispatch => {
        const send_url = Constants.MANAGE_SUBSCRIPTION_EMAIL;
        myHeaders = new Headers();
        
        var raw = new FormData();
        raw.append("html", email);
        raw.append("subject", subject);

        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        await fetch(send_url, requestOptions)
        .then(response => response.json())
        .then(data => {
            dispatch(subscription_email(data));
        })
    }
}
export const subscription_email = (data) => {
    return {
        type: actionTypes.SEND_SUBSCRIPTION_EMAIL,
        send_email: data.success,
    };
}
