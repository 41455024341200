import React,{ Component } from "react";
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import ReactTooltip from 'react-tooltip';

//icons
import { Icon } from '@iconify/react';
import eyeIcon from '@iconify/icons-akar-icons/eye';

class Networking extends Component{

    changeDateFormat(fromDate) {
        var d = new Date(fromDate),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
    
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [year, month, day].join('-');
    }

    handleOpenListingModal = (prop_id, date_sold, agent_id) => {
        /* Creating Date From 1st of Year to Last Of Year */
        this.props.reset_property_modal();
        var fromDate = new Date("1/1/" + (new Date()).getFullYear());
        var toDate = new Date(new Date().getFullYear(), 11, 31, 0, 0, 0);
    
        var d = new Date(fromDate),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
      
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        fromDate = [year, month, day].join('-');
    
        d = new Date(toDate);
        month = '' + (d.getMonth() + 1);
        day = '' + d.getDate();
        year = d.getFullYear();
      
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
      
        toDate = [year, month, day].join('-');
    
        //var agent_id = this.props.property_creator_id;
        const token = localStorage.getItem('token');
        const fromnetworking = 1;
        const property_notes_user_id = this.props.agent_id;
        this.props.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold, fromnetworking, property_notes_user_id);

        window.$("#openlisting_modal").modal('show');
    }

    handlePropertyModal = (prop_id, date_sold, agent_id) => {
        /* Creating Date From 1st of Year to Last Of Year */
        this.props.reset_property_modal();
        // var fromDate = new Date("1/1/" + (new Date()).getFullYear());
        // var toDate = new Date(new Date().getFullYear(), 11, 31, 0, 0, 0);
    
        // var d = new Date(fromDate),
        // month = '' + (d.getMonth() + 1),
        // day = '' + d.getDate(),
        // year = d.getFullYear();
      
        // if (month.length < 2) month = '0' + month;
        // if (day.length < 2) day = '0' + day;
        // fromDate = [year, month, day].join('-');
    
        // d = new Date(toDate);
        // month = '' + (d.getMonth() + 1);
        // day = '' + d.getDate();
        // year = d.getFullYear();
      
        // if (month.length < 2) month = '0' + month;
        // if (day.length < 2) day = '0' + day;
      
        // toDate = [year, month, day].join('-');

        var da = new Date();
        var fromDate = this.changeDateFormat(new Date(da.getFullYear(), da.getMonth() - 2, 1));
        var toDate = this.changeDateFormat(new Date(da.getFullYear(), da.getMonth(), da.getDate()));
    
        //var agent_id = this.props.property_creator_id;
        const token = localStorage.getItem('token');
        const fromnetworking = 1;
        const property_notes_user_id = this.props.agent_id;
        this.props.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold, fromnetworking, property_notes_user_id);
        this.props.set_page("property_modal", true);
        window.$("#profileModal").modal('show');
    }
    
    showCRMVerifiedModal = () =>{
        window.$("#crmVerifiedModal").modal('show');
    }
      
    showCRMNotVerifiedModal = () =>{
        window.$("#crmNotVerifiedModal").modal('show');
    }
    render(){ 
        return(
            <div className = "networking" style={{minHeight:550}}>
                <ReactTooltip resizeHide = { true } backgroundColor={'#0B2C4C'}  />
                {/* Property For sale and Rent Tab */}
                <ul className="nav nav-tabs">
                    <li style={{ width: '50%' }} className="active fontInter office_listing">
                      <a data-toggle="tab" id="saletab" href="#office_listing">
                        <b>Office Listings</b>
                        <br />
                        {this.props.office_list ?
                            <span className="fontSize12 fontWeightNormal fontInter"><b>{this.props.office_list.length} &nbsp; {this.props.office_list.length > 1 ? "Properties" : "Property" }</b></span>
                        : null
                        }
                      </a>
                    </li>
                     
                    <li style={{ width: '50%' }} className="fontInter open_listing">
                      <a data-toggle="tab" href="#open_listing"><b>Other Listings</b>
                        <br />
                        {this.props.open_list ?
                            <span className="fontSize12 fontWeightNormal fontInter"><b>{this.props.open_list.length} &nbsp; {this.props.open_list.length > 1 ? "Properties" : "Property" }</b></span>
                            : null
                        }
                        </a>
                    </li>
                </ul>
                
                <div className="tab-content" style={{ marginTop: '40px', overflow: 'auto' }}>
                    {Array.isArray(this.props.office_list) && this.props.office_list.length ?
                        <div id="office_listing" className="tab-pane fade in active">
                            {this.props.office_list.map((data, index) => (
                                <div key={index}>
                                    <div className="property-col property-details" style={{ border: '2p solid red' }}>
                                        <div className="property-text fontSize13 fontInter" style={{ width: '70%', color: '#0F1D30' }}>
                                            <span onClick = { ()=> this.handlePropertyModal(data.propid, data.date_sold, data.property_creator_id)}>{data.address}</span>
                                            {Number(data.crm_verified) === Number(1) ? 
                                                <img tag="allowRowEvents" alt='' width="20" height="20" style={{verticalAlign: "text-bottom", marginLeft: "5px", cursor:"pointer"}} src={"images/crm_verified.svg"} onClick={this.showCRMVerifiedModal}/>
                                            :
                                                data.crm_id ? 
                                                    <img tag="allowRowEvents" alt='' width="20" height="20" style={{verticalAlign: "text-bottom", marginLeft: "5px", cursor:"pointer"}} src={"images/crm_not_verified.svg"} onClick={this.showCRMNotVerifiedModal}/>
                                                :
                                                    null
                                            }
                                            <span className="fontWeight700" style={{display: "block"}} onClick = { ()=> this.handlePropertyModal(data.propid, data.date_sold, data.property_creator_id)}> {data.unit_name !== "" ?  data.unit_name : null}
                                                {data.date_sold ?
                                                    Number(data.rented) === Number(1) ?
                                                        <span className="soldspan">Rented</span>
                                                    : 
                                                        <span className="soldspan">Sold</span>
                                                :
                                                    null
                                                }
                                            </span>
                                        </div>
                                        <div className="fontSize13 fontInter" style={{ width: '12%', marginLeft: '13px' }} onClick = { ()=> this.handlePropertyModal(data.propid, data.date_sold, data.property_creator_id)}></div>
                                        <div className="fontSize13 fontInter" onClick = { ()=> this.handlePropertyModal(data.propid, data.date_sold, data.property_creator_id)}>
                                            <span style={{display:"block", fontWeight: 600, fontFamily: "Inter"}}>{data.currency_symbol} {data.price}</span>
                                            <Icon icon={eyeIcon} className="color4AA49C" style={{fontSize: '24px', verticalAlign: 'bottom'}} /> <span className="viewCount">{data.viewings_count}</span>
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                            )) } 
                        </div>  /* For Sale Tab Ends Here  */
                    : 
                        <div id="office_listing" className="tab-pane fade in active">
                            <center><br/><br/><h4>No Properties to Show</h4></center>
                        </div>
                    }
                        
                  
                    {/* Second Tab For Other Listing Starts  */}
                    <div id="open_listing" className="tab-pane fade">
                        {Array.isArray(this.props.open_list) && this.props.open_list.length ?
                            <div>
                                {this.props.open_list.map((data, index) => (
                                    <div key={index}>
                                        <div className="property-col property-details" style={{ border: '2p solid red' }}>
                                            <div className="property-text fontSize13 fontInter" style={{ width: '70%', color: '#0F1D30' }}>
                                                <span onClick = { ()=> this.handleOpenListingModal(data.propid, data.date_sold, data.property_creator_id)}>{data.address}</span>
                                                {Number(data.crm_verified) === Number(1) ? 
                                                    <img tag="allowRowEvents" alt='' width="20" height="20" style={{verticalAlign: "text-bottom", marginLeft: "5px", cursor:"pointer"}} src={"images/crm_verified.svg"} onClick={this.showCRMVerifiedModal}/>
                                                :
                                                    data.crm_id ? 
                                                        <img tag="allowRowEvents" alt='' width="20" height="20" style={{verticalAlign: "text-bottom", marginLeft: "5px", cursor:"pointer"}} src={"images/crm_not_verified.svg"} onClick={this.showCRMNotVerifiedModal}/>
                                                    :
                                                        null
                                                }
                                                <span className="fontWeight700" style={{display: "block"}} onClick = { ()=> this.handleOpenListingModal(data.propid, data.date_sold, data.property_creator_id)}> {data.unit_name !== "" ?  data.unit_name : null}
                                                    {data.date_sold ?
                                                        Number(data.rented) === Number(1) ?
                                                            <span className="soldspan">Rented</span>
                                                        : 
                                                        <span className="soldspan">Sold</span>
                                                    :
                                                        null
                                                    }
                                                </span>
                                            </div>
                                            <div className="fontSize13 fontInter" style={{ width: '12%', marginLeft: '13px' }} onClick = { ()=> this.handleOpenListingModal(data.propid, data.date_sold, data.property_creator_id)}></div>
                                            <div className="fontSize13 fontInter" onClick = { ()=> this.handleOpenListingModal(data.propid, data.date_sold, data.property_creator_id)}>
                                                <span style={{display:"block", fontWeight: 600, fontFamily: "Inter"}}>{data.currency_symbol} {data.price}</span>
                                                <Icon icon={eyeIcon} className="color4AA49C" style={{fontSize: '24px', verticalAlign: 'bottom'}} /> <span className="viewCount">{data.viewings_count}</span>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                )) }
                            </div>  /* For Other Listing Tab Ends Here  */
                        : 
                            <div>
                                <center><br/><br/><h4>No Properties to Show</h4></center>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        reset_property_modal: () => dispatch(actions.reset_property_modal()),
        get_property_data_modal: (fromDate, toDate, prop_id, agent_id, token, date_sold, from_networking, property_notes_user_id) => dispatch(actions.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold, from_networking, property_notes_user_id)),
        //set page for calendar
        set_page: (page, reset_date_range) => dispatch(actions.set_page(page, reset_date_range)),
    }
}
  
const mapStateToProps = state => {
    return{
        //networking tab
        office_list: state.agent_modal.office_list,
        open_list: state.agent_modal.open_list,

        //agent mobile authtoken
        authtoken: state.add_property_modal.authtoken,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Networking)