import * as actionTypes from '../actions/actionsTypes'; 
import {updateObject} from '../utility';

const initialState = {
    //overview data
    data: null, 
    total_prop: null,
    soldPropertyValues: null,
    linkedUserCount: null,
    appointmentCount: null,
    generalAppointmentCount: null,
    totalAgentCount: null,
    settings: null,
    page_from_date: new Date((new Date().getFullYear()), (new Date().getMonth()) - 2, 1), //new Date(new Date().getFullYear(), 0, 1),
    page_to_date: new Date((new Date().getFullYear()), (new Date().getMonth()), (new Date().getDate())), //new Date(new Date().getFullYear(), 11, 31),
 
    //property chart data
    property_labels: null,
    property_agen_count: null,
    temp_chart_property_count: null,
    
    //property sale and rent chart data
    property_sale_labels: null,
    property_sale_agen_count: null,
    temp_chart_property_count_sale: null,
    
    //property sold and rented chart data
    property_sold_labels: null,
    property_sold_agen_count: null,
    temp_chart_property_count_sold: null,

    //stockpipeline chart data
    stockpipeline_labels: null,
    stockpipeline_count: null,
    temp_stockpipeline_count: null,

    //appointment chart data
    appt_lables: null,
    chart_appt_count: null,
    temp_chart_appt_count: null,
    
    //general chart data
    general_lables: null,
    chart_general_count: null,
    temp_chart_general_count: null,

    //sales chart data
    sales_labels: null,
    chart_sales_cnt: null,
    temp_chart_sales_count: null,

    //cold calls chart data
    coldcalls_labels: null,
    coldcalls_count: null,
    temp_coldcalls_count: null,

    //revenue chart data
    revenue_labels: null,
    chart_revenue_cnt: null,
    temp_chart_revenue_count: null,

    //rental revenue chart data
    rental_revenue_labels: null,
    chart_rental_revenue_cnt: null,
    temp_chart_rental_revenue_count: null,
    revenue_currency: null,

    //profitability chart data
    profitability_labels: null,
    profitability_chart: null,
    temp_chart_profitability_count: null
    
}

const overview_data = (state, action) =>{
    localStorage.setItem("totalAgentCount",action.data.length);
    return updateObject(state, {
        data: action.data,
        total_prop: action.total_prop,
        soldPropertyValues: action.soldPropertyValues,
        linkedUserCount: action.linkedUserCount,
        appointmentCount: action.appointmentCount,
        generalAppointmentCount: action.generalAppointmentCount,
        totalAgentCount: action.data.length,
        settings: action.settings
    });
}

const overview_data_with_date = (state, action) =>{
    return updateObject(state,{
        data: action.data
    })
}

const get_property_chart = (state, action) =>{
    const property_graph_data = JSON.parse(JSON.stringify(action.property_agen_count));
    const non_active_agent_buttons = localStorage.getItem('non_active_agent_buttons') ? JSON.parse(localStorage.getItem('non_active_agent_buttons')) : null;
    if(non_active_agent_buttons){
        // eslint-disable-next-line
        non_active_agent_buttons.map((agent_id) =>{
            delete property_graph_data[0][agent_id];
        })
        return updateObject(state, {
            property_labels: action.property_labels,
            property_agen_count: property_graph_data,
            temp_chart_property_count : action.temp_chart_property_count
        })
    }else{
        return updateObject(state,{
            property_labels: action.property_labels,
            property_agen_count: action.property_agen_count,
            temp_chart_property_count : action.temp_chart_property_count
        });
    }
}

const get_property_sale_chart = (state, action) =>{
    const property_sale_graph_data = JSON.parse(JSON.stringify(action.property_sale_agen_count));
    const non_active_agent_buttons = localStorage.getItem('non_active_agent_buttons') ? JSON.parse(localStorage.getItem('non_active_agent_buttons')) : null;
    if(non_active_agent_buttons){
        // eslint-disable-next-line
        non_active_agent_buttons.map((agent_id) =>{
            delete property_sale_graph_data[0][agent_id];
        })
        return updateObject(state, {
            property_sale_labels: action.property_sale_labels,
            property_sale_agen_count: property_sale_graph_data,
            temp_chart_property_count_sale : action.temp_chart_property_count_sale
        })
    }else{
        return updateObject(state,{
            property_sale_labels: action.property_sale_labels,
            property_sale_agen_count: action.property_sale_agen_count,
            temp_chart_property_count_sale : action.temp_chart_property_count_sale
        });
    }
}

const get_property_sold_chart = (state, action) =>{
    const property_sold_graph_data = JSON.parse(JSON.stringify(action.property_sold_agen_count));
    const non_active_agent_buttons = localStorage.getItem('non_active_agent_buttons') ? JSON.parse(localStorage.getItem('non_active_agent_buttons')) : null;
    if(non_active_agent_buttons){
        // eslint-disable-next-line
        non_active_agent_buttons.map((agent_id) =>{
            delete property_sold_graph_data[0][agent_id];
        })
        return updateObject(state, {
            property_sold_labels: action.property_sold_labels,
            property_sold_agen_count: property_sold_graph_data,
            temp_chart_property_count_sold : action.temp_chart_property_count_sold
        })
    }else{
        return updateObject(state,{
            property_sold_labels: action.property_sold_labels,
            property_sold_agen_count: action.property_sold_agen_count,
            temp_chart_property_count_sold : action.temp_chart_property_count_sold
        });
    }
}

const get_stockpipeline_chart = (state, action) =>{
    const stockpipeline_graph_data = JSON.parse(JSON.stringify(action.stockpipeline_count));
    const non_active_agent_buttons = localStorage.getItem('non_active_agent_buttons') ? JSON.parse(localStorage.getItem('non_active_agent_buttons')) : null;
    if(non_active_agent_buttons){
        // eslint-disable-next-line
        non_active_agent_buttons.map((agent_id) =>{
            delete stockpipeline_graph_data[0][agent_id];
        })
        return updateObject(state, {
            stockpipeline_labels: action.stockpipeline_labels,
            stockpipeline_count: stockpipeline_graph_data,
            temp_stockpipeline_count: action.temp_stockpipeline_count
        })
    }else{
        return updateObject(state,{
            stockpipeline_labels: action.stockpipeline_labels,
            stockpipeline_count: action.stockpipeline_count,
            temp_stockpipeline_count: action.temp_stockpipeline_count
        });
    }
}

const get_appointment_chart = (state, action) =>{
    const appt_graph_data = JSON.parse(JSON.stringify(action.chart_appt_count));
    const non_active_agent_buttons = localStorage.getItem('non_active_agent_buttons') ? JSON.parse(localStorage.getItem('non_active_agent_buttons')) : null;
    if(non_active_agent_buttons){
        // eslint-disable-next-line
        non_active_agent_buttons.map((agent_id) =>{
            delete appt_graph_data[0][agent_id];
        })
        return updateObject(state, {
            appt_lables: action.appt_lables,
            chart_appt_count: appt_graph_data,
            temp_chart_appt_count: action.temp_chart_appt_count
        })
    }else{
        return updateObject(state,{
            appt_lables: action.appt_lables,
            chart_appt_count: action.chart_appt_count,
            temp_chart_appt_count: action.temp_chart_appt_count
        });
    }
}

const get_general_chart = (state, action) =>{
    const general_graph_data = JSON.parse(JSON.stringify(action.chart_general_count));
    const non_active_agent_buttons = localStorage.getItem('non_active_agent_buttons') ? JSON.parse(localStorage.getItem('non_active_agent_buttons')) : null;
    if(non_active_agent_buttons){
        // eslint-disable-next-line
        non_active_agent_buttons.map((agent_id) =>{
            delete general_graph_data[0][agent_id];
        })
        return updateObject(state, {
            general_lables: action.general_lables,
            chart_general_count: general_graph_data,
            temp_chart_general_count: action.temp_chart_general_count
        })
    }else{
        return updateObject(state,{
            general_lables: action.general_lables,
            chart_general_count: action.chart_general_count,
            temp_chart_general_count: action.temp_chart_general_count
        });
    }
}

const get_sales_chart = (state, action) =>{
    const sales_graph_data = JSON.parse(JSON.stringify(action.chart_sales_cnt));
    const non_active_agent_buttons = localStorage.getItem('non_active_agent_buttons') ? JSON.parse(localStorage.getItem('non_active_agent_buttons')) : null;
    if(non_active_agent_buttons){
        // eslint-disable-next-line
        non_active_agent_buttons.map((agent_id) =>{
            delete sales_graph_data[0][agent_id];
        })
        return updateObject(state, {
            sales_labels: action.sales_labels,
            chart_sales_cnt: sales_graph_data,
            temp_chart_sales_count: action.chart_sales_cnt
        })
    }else{
        return updateObject(state,{
            sales_labels: action.sales_labels,
            chart_sales_cnt: action.chart_sales_cnt,
            temp_chart_sales_count: action.chart_sales_cnt
        });
    }
}

const get_coldcalls_chart = (state, action) =>{
    const coldcalls_graph_data = JSON.parse(JSON.stringify(action.coldcalls_count));
    const non_active_agent_buttons = localStorage.getItem('non_active_agent_buttons') ? JSON.parse(localStorage.getItem('non_active_agent_buttons')) : null;
    if(non_active_agent_buttons){
        // eslint-disable-next-line
        non_active_agent_buttons.map((agent_id) =>{
            delete coldcalls_graph_data[0][agent_id];
        })
        return updateObject(state, {
            coldcalls_labels: action.coldcalls_labels,
            coldcalls_count: coldcalls_graph_data,
            temp_coldcalls_count: action.temp_coldcalls_count
        })
    }else{
        return updateObject(state,{
            coldcalls_labels: action.coldcalls_labels,
            coldcalls_count: action.coldcalls_count,
            temp_coldcalls_count: action.temp_coldcalls_count
        });
    }
}

const get_revenue_chart = (state, action) =>{
    const revenue_graph_data = JSON.parse(JSON.stringify(action.chart_revenue_cnt));
    const non_active_agent_buttons = localStorage.getItem('non_active_agent_buttons') ? JSON.parse(localStorage.getItem('non_active_agent_buttons')) : null;
    if(non_active_agent_buttons){
        // eslint-disable-next-line
        non_active_agent_buttons.map((agent_id) =>{
            delete revenue_graph_data[0][agent_id];
        })
        return updateObject(state, {
            revenue_labels: action.revenue_labels,
            revenue_currency: action.currency,
            chart_revenue_cnt: revenue_graph_data,
            temp_chart_revenue_count: action.temp_chart_revenue_count
        })
    }else{
        return updateObject(state,{
            revenue_labels: action.revenue_labels,
            revenue_currency: action.currency,
            chart_revenue_cnt: action.chart_revenue_cnt,
            temp_chart_revenue_count: action.temp_chart_revenue_count
        });
    }
}

const get_rental_revenue_chart = (state, action) =>{
    const revenue_graph_data = JSON.parse(JSON.stringify(action.chart_rental_revenue_cnt));
    const non_active_agent_buttons = localStorage.getItem('non_active_agent_buttons') ? JSON.parse(localStorage.getItem('non_active_agent_buttons')) : null;
    if(non_active_agent_buttons){
        // eslint-disable-next-line
        non_active_agent_buttons.map((agent_id) =>{
            delete revenue_graph_data[0][agent_id];
        })
        return updateObject(state, {
            rental_revenue_labels: action.rental_revenue_labels,
            chart_rental_revenue_cnt: revenue_graph_data,
            temp_chart_rental_revenue_count: action.temp_chart_rental_revenue_count
        })
    }else{
        return updateObject(state,{
            rental_revenue_labels: action.rental_revenue_labels,
            chart_rental_revenue_cnt: action.chart_rental_revenue_cnt,
            temp_chart_rental_revenue_count: action.temp_chart_rental_revenue_count
        });
    }
}

const get_profitability_chart = (state, action) =>{    
    const profitability_graph_data = JSON.parse(JSON.stringify(action.profitability_chart));
    const non_active_agent_buttons = localStorage.getItem('non_active_agent_buttons') ? JSON.parse(localStorage.getItem('non_active_agent_buttons')) : null;
    if(non_active_agent_buttons){
        // eslint-disable-next-line
        non_active_agent_buttons.map((agent_id) =>{
            delete profitability_graph_data[0][agent_id];
        })
        return updateObject(state, {
            profitability_labels: action.profitability_labels,
            profitability_chart: profitability_graph_data,
            temp_chart_profitability_count: action.temp_chart_profitability_count
        })
    }else{
        return updateObject(state,{
            profitability_labels: action.profitability_labels,
            profitability_chart: action.profitability_chart,
            temp_chart_profitability_count: action.temp_chart_profitability_count
        });
    }
}

//toggle chart
const toggle_property_chart = (state, action) =>{
    const property_graph_data = JSON.parse(JSON.stringify(state.property_agen_count));
    if(property_graph_data[0][action.agent_id]){
        delete property_graph_data[0][action.agent_id];
        return updateObject(state, {property_agen_count: property_graph_data});
    }else{
      const data = state.property_agen_count.map((index)=>{
        return {...index, [action.agent_id]: state.temp_chart_property_count[0][action.agent_id]};
      });
      return updateObject(state, {property_agen_count: data});
    }
}
const toggle_property_sale_chart = (state, action) =>{
    const property_sale_graph_data = JSON.parse(JSON.stringify(state.property_sale_agen_count));
    if(property_sale_graph_data[0][action.agent_id]){
        delete property_sale_graph_data[0][action.agent_id];
        return updateObject(state, {property_sale_agen_count: property_sale_graph_data});
    }else{
      const data = state.property_sale_agen_count.map((index)=>{
        return {...index, [action.agent_id]: state.temp_chart_property_count_sale[0][action.agent_id]};
      });
      return updateObject(state, {property_sale_agen_count: data});
    }
}
const toggle_property_sold_chart = (state, action) =>{
    const property_sold_graph_data = JSON.parse(JSON.stringify(state.property_sold_agen_count));
    if(property_sold_graph_data[0][action.agent_id]){
        delete property_sold_graph_data[0][action.agent_id];
        return updateObject(state, {property_sold_agen_count: property_sold_graph_data});
    }else{
      const data = state.property_sold_agen_count.map((index)=>{
        return {...index, [action.agent_id]: state.temp_chart_property_count_sold[0][action.agent_id]};
      });
      return updateObject(state, {property_sold_agen_count: data});
    }
}

const toggle_stockpipeline_chart = (state, action) =>{
    const stockpipeline_graph_data = JSON.parse(JSON.stringify(state.stockpipeline_count));
    if(stockpipeline_graph_data[0][action.agent_id]){
        delete stockpipeline_graph_data[0][action.agent_id];
        return updateObject(state, {stockpipeline_count: stockpipeline_graph_data});
    }else{ 
      const data = state.stockpipeline_count.map((index) => {
        return {...index, [action.agent_id]: state.temp_stockpipeline_count[0][action.agent_id]};
      });
      return updateObject(state, {stockpipeline_count: data});
    }
}

const toggle_appointment_chart = (state, action) =>{   
    const appt_graph_data = JSON.parse(JSON.stringify(state.chart_appt_count));    
    if(appt_graph_data[0][action.agent_id]){
        delete appt_graph_data[0][action.agent_id];
        return updateObject(state, {chart_appt_count: appt_graph_data})
    }else{
        const data = state.chart_appt_count.map((index) => {
            return {...index, [action.agent_id]: state.temp_chart_appt_count[0][action.agent_id]};
        });
        return updateObject(state, {chart_appt_count: data});
    }
}

const toggle_general_chart = (state, action) =>{   
    const general_graph_data = JSON.parse(JSON.stringify(state.chart_general_count));    
    if(general_graph_data[0][action.agent_id]){
        delete general_graph_data[0][action.agent_id];
        return updateObject(state, {chart_general_count: general_graph_data})
    }else{
        const data = state.chart_general_count.map((index) => {
            return {...index, [action.agent_id]: state.temp_chart_general_count[0][action.agent_id]};
        });
        return updateObject(state, {chart_general_count: data});
    }
}

const toggle_sales_chart = (state, action) =>{
    const sales_graph_data = JSON.parse(JSON.stringify(state.chart_sales_cnt));
    if(sales_graph_data[0][action.agent_id]){
        delete sales_graph_data[0][action.agent_id];
        return updateObject(state, {chart_sales_cnt: sales_graph_data});
    }else{ 
      const data = state.chart_sales_cnt.map((index) => {
        return {...index, [action.agent_id]: state.temp_chart_sales_count[0][action.agent_id]};
      });
      return updateObject(state, {chart_sales_cnt: data});
    }
}

const toggle_coldcalls_chart = (state, action) =>{
    const coldcalls_graph_data = JSON.parse(JSON.stringify(state.coldcalls_count));
    if(coldcalls_graph_data[0][action.agent_id]){
        delete coldcalls_graph_data[0][action.agent_id];
        return updateObject(state, {coldcalls_count: coldcalls_graph_data});
    }else{ 
      const data = state.coldcalls_count.map((index) => {
        return {...index, [action.agent_id]: state.temp_coldcalls_count[0][action.agent_id]};
      });
      return updateObject(state, {coldcalls_count: data});
    }
}

const toggle_revenue_chart = (state, action) =>{
    const revenue_graph_data = JSON.parse(JSON.stringify(state.chart_revenue_cnt));
    if(revenue_graph_data[0][action.agent_id]){
        delete revenue_graph_data[0][action.agent_id];
        return updateObject(state, {chart_revenue_cnt: revenue_graph_data});
    }else{
        const data = state.chart_revenue_cnt.map((index)=>{
            return {...index, [action.agent_id]: state.temp_chart_revenue_count[0][action.agent_id]};
        });
        return updateObject(state, {chart_revenue_cnt: data});
    }
}

const toggle_rental_revenue_chart = (state, action) =>{
    const rental_revenue_graph_data = JSON.parse(JSON.stringify(state.chart_rental_revenue_cnt));
    if(rental_revenue_graph_data[0][action.agent_id]){
        delete rental_revenue_graph_data[0][action.agent_id];
        return updateObject(state, {chart_rental_revenue_cnt: rental_revenue_graph_data});
    }else{
        const data = state.chart_rental_revenue_cnt.map((index)=>{
            return {...index, [action.agent_id]: state.temp_chart_rental_revenue_count[0][action.agent_id]};
        });
        return updateObject(state, {chart_rental_revenue_cnt: data});
    }
}

const toggle_profitability_chart = (state, action) =>{
    const profitability_graph_data = JSON.parse(JSON.stringify(state.profitability_chart));
    if(profitability_graph_data[0][action.agent_id]){
        delete profitability_graph_data[0][action.agent_id];
        return updateObject(state, {profitability_chart: profitability_graph_data});
    }else{
      const data = state.profitability_chart.map((index)=>{
        return {...index,[action.agent_id]: state.temp_chart_profitability_count[0][action.agent_id]};
      });
      return updateObject(state, {profitability_chart: data});
    }
}

const change_from_to_date = (state, action)=>{
    return updateObject(state, {
        page_from_date: action.page_from_date,
        page_to_date: action.page_to_date,
    }); 
}


function overview_reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.GET_OVERVIEW_DATA: return overview_data(state, action);
        case actionTypes.GET_OVERVIEW_DATA_WITH_DATE: return overview_data_with_date(state, action);
        case actionTypes.GET_PROPERTY_CHART: return get_property_chart(state, action);
        case actionTypes.GET_PROPERTY_SALE_CHART: return get_property_sale_chart(state, action);
        case actionTypes.GET_PROPERTY_SOLD_CHART: return get_property_sold_chart(state, action);
        case actionTypes.GET_STOCKPIPELINE_CHART: return get_stockpipeline_chart(state, action);
        case actionTypes.GET_APPOINTMENT_CHART: return get_appointment_chart(state, action);
        case actionTypes.GET_GENERAL_CHART: return get_general_chart(state, action);
        case actionTypes.GET_SALES_CHART: return get_sales_chart(state, action);
        case actionTypes.GET_COLDCALLS_CHART: return get_coldcalls_chart(state, action);
        case actionTypes.GET_REVENUE_CHART: return get_revenue_chart(state, action);
        case actionTypes.GET_RENTAL_REVENUE_CHART: return get_rental_revenue_chart(state, action);
        case actionTypes.GET_PROFITABILITY_CHART : return get_profitability_chart(state, action);
        //toggle chart
        case actionTypes.TOGGLE_PROPERTY_CHART      : return toggle_property_chart(state, action);
        case actionTypes.TOGGLE_PROPERTY_SALE_CHART : return toggle_property_sale_chart(state, action);
        case actionTypes.TOGGLE_PROPERTY_SOLD_CHART : return toggle_property_sold_chart(state, action);
        case actionTypes.TOGGLE_STOCKPIPELINE_CHART : return toggle_stockpipeline_chart(state, action);
        case actionTypes.TOGGLE_APPOINTMENT_CHART   : return toggle_appointment_chart(state, action);
        case actionTypes.TOGGLE_GENERAL_CHART       : return toggle_general_chart(state, action);
        case actionTypes.TOGGLE_SALES_CHART         : return toggle_sales_chart(state, action);
        case actionTypes.TOGGLE_COLDCALLS_CHART     : return toggle_coldcalls_chart(state, action);
        case actionTypes.TOGGLE_REVENUE_CHART       : return toggle_revenue_chart(state, action);
        case actionTypes.TOGGLE_RENTAL_REVENUE_CHART: return toggle_rental_revenue_chart(state, action);
        case actionTypes.TOGGLE_PROFITABILITY_CHART : return toggle_profitability_chart(state, action);
        case actionTypes.CHANGE_FROM_TO_DATE        : return change_from_to_date(state, action);
        default:
        return state;
    }
}
  
export default overview_reducer;
