import * as actionTypes from './actionsTypes';
import Constants from '../../constants/Constants';
import axios from 'axios';

var url='';
var myHeaders = '';
var raw = '';
var requestOptions = '';

export const get_agent_list_revenue = (month, year, token, average_cost_agent) => {
    let avg_cost = '';
    if(average_cost_agent){
        avg_cost = average_cost_agent;
    }
    return async dispatch =>{
        await axios.post(Constants.AGENT_LIST,{
            month: month,
            year: year,
            average_cost_agent: avg_cost
           },
            {headers: {
                Authorization: token 
            }
        })
        .then(data => {
            dispatch(agent_list_revenue(data.data));
        })
        .catch(error => console.log('error', error)); 
    };
}
export const agent_list_revenue = (data) =>{
    return{
        type: actionTypes.GET_REVENUE_AGENT_LIST,
        agent_list_revenue: data,
    }
}

export const get_revenue_chart_revenue = (fromDate, toDate, agent_list, token) =>{
    return dispatch =>{
        url = Constants.REVENUE_PAGE_CHART;
        myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token);
        raw = JSON.stringify(
        {
            "from": fromDate,
            "to": toDate
        });
        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(url, requestOptions)
        .then(response => response.json())
        .then(data => {
            dispatch(revenue_chart(data));
            //deleting data of user whose tracking is off
            for(let i = 0; i < agent_list.length; i++){
                if(Number(agent_list[i].tracking_prefrence) === Number(0) ){ 
                delete data.data.sales_cnt[0][agent_list[i].userid];
                }
            }
        })
    }
}
export const revenue_chart = (data) =>{
    return{
        type: actionTypes.GET_REVENUE_CHART,
        revenue_labels: data.data.labels,
        chart_revenue_cnt: data.data.sales_cnt,
        temp_chart_revenue_count : data.data.sales_cnt
    }
}
export const toggle_revenue_page_chart = (agent_id) =>{
    return{
        type: actionTypes.TOGGLE_REVENUE_PAGE_CHART,
        agent_id: agent_id
    }
}