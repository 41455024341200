import * as actionTypes from '../actions/actionsTypes'; 
import {updateObject} from '../utility';

const initialState = {
    logo: null,
    firstname: null,
    office_id: null,
    totalagent: null,
    token: null,
    login: true,
    loading: false,
    redirect_link:null,
    lastActive: 5000,
}

const authStart = (state, action) =>{
    return updateObject(state, {loading: true});
} 

const authSuccess = (state, action) =>{
    return updateObject(state, {
        token: action.token,
        office_id: action.office_id,
        firstname: action.firstname,
        logo: action.logo,
        login:true,
        loading: false,
        redirect_link: "/overview",
        error: null
    });
}

const authFail = (state, action) =>{
    return updateObject(state,{
        login: false,
        error: action.error,
        loading: false
    });
}

const update_expire = (state,action) =>{
    //localStorage.setItem("lastActive",action.lastActive);
    return updateObject(state,{
        lastActive: action.lastActive,
    })
}

const authLogout = (state, action)=>{
    return updateObject(state, {token: null, office_id: null, firstname:null, logo:null, login:false});
}



function auth_reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.AUTH_START: return authStart(state, action); 
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action); 
        case actionTypes.AUTH_FAIL: return authFail(state, action); 
        case actionTypes.UPDATE_EXPIRE: return update_expire(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        default:
            return state;
    }
}
  
export default auth_reducer;
