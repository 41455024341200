import React,{ Component } from "react";

import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import Loader from '../../Loader';

import EditAddressGoogleMap from "./EditAddressGoogleMap";

import { Icon } from '@iconify/react';
import officeBuildingMarker from '@iconify/icons-mdi/office-building-marker';
import Swal from 'sweetalert2';

import ErrorText from '../../../auth/ErrorText';

class EditStockPipeline extends Component{
    constructor(props) {
        super(props);
        this.state = {
            className: null,
            edit_full_address: null,
            edit_full_address_length: 0,

            edit_map_address: null,
            edit_lat: null,
            edit_lng: null
        }
        this.submitHandler = this.submitHandler.bind(this);
        this.closeEditStockPipelineModal = this.closeEditStockPipelineModal.bind(this);
        this.onInputchange = this.onInputchange.bind(this);
        this.addressGoogleMap = React.createRef();
    }

    opensweetalert(){
        Swal.fire({
            icon: 'success',
            title: 'Stock pipeline updated successfully ',
            showConfirmButton: true,
        })
    }
    
    onInputchange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
        if(event.target.name === "edit_full_address"){
            let len = event.target.value;
            len = len.length;
            this.setState({edit_full_address_length: len})
        }
    }

    changeDateFormat(fromDate) {
        var d = new Date(fromDate),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
    
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [year, month, day].join('-');
    }

    async submitHandler(event){
        event.preventDefault(); 
        this.setState({loading: true});
        const token = localStorage.getItem('token');
        let user_id = this.props.agent_data.user_id
        await this.props.update_stock_pipeline_data(token, user_id, this.state.edit_full_address, this.props.stock_pipeline_property_data[0].unit_no, this.state.edit_lat, this.state.edit_lng, this.props.stock_pipeline_property_data[0].is_archived, this.props.stock_pipeline_property_data[0].id, this.state.edit_map_address);
        
        if(this.props.update_stock_pipeline_status === 200){
            this.opensweetalert();
            this.closeEditStockPipelineModal();

            var date = new Date();
            var fromDate = this.changeDateFormat(new Date(date.getFullYear(), date.getMonth() - 2, 1));
            var toDate = this.changeDateFormat(new Date(date.getFullYear(), date.getMonth()));
            let page = 1;
            let is_archived = 0;
            await this.props.get_stock_pipeline_property_data(this.props.stock_pipeline_property_data[0].id, user_id, token);
            this.props.get_stock_pipeline_data(page, is_archived, null, this.props.authtoken);
            this.props.get_stockpipeline_chart_by_id(fromDate, toDate, user_id, token);
            
            window.$("#editStockPipelineModal").modal('hide');
            this.props.set_page("stockpipeline_tab", true);
        }
        this.setState({loading: false});
    }

    //clear modal data
    closeEditStockPipelineModal(){
        this.setState({
            className: null,
            edit_full_address: null,
            edit_full_address_length: 0,
            
            edit_map_address: null,
            edit_lat: null,
            edit_lng: null
        },
        async () => {
            await this.props.reset_add_stockpipeline_form();
            this.props.reset_google_map();
            window.$("#edit_full_address").val("");
        })
        window.$("#editStockPipelineModal").modal('hide');
    }

    callbackFunction = (address, lat, lng) => {
        this.setState({
          edit_map_address: address,
          edit_lat: lat,
          edit_lng: lng
        })
    }

    componentDidMount() {
        this.props.onRef(this)
    }

    setEditAddress() {
        let len = this.props.stock_pipeline_property_data[0].address;
        len = len.length;
        this.setState({
            edit_full_address: this.props.stock_pipeline_property_data[0].address,
            edit_full_address_length: len,
            
            edit_map_address: this.props.stock_pipeline_property_data[0].address_google,
            edit_lat: this.props.stock_pipeline_property_data[0].lat,
            edit_lng: this.props.stock_pipeline_property_data[0].lng
        })
        window.$("#edit_full_address").val(this.props.stock_pipeline_property_data[0].address);
    }

    render(){
        return(
            this.props.stock_pipeline_property_data ?
                <div>
                    <div className="modal fade" data-backdrop="static" data-keyboard="false" id="editStockPipelineModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={this.setwrapperRef} >
                        <div className="modal-dialog" role="document" ref={this.wrapperRef}>
                            <div className="modal-content modal_edit_stockpipeline modalheight" style ={{borderRadius : '12px' }} >
                                <div className="modal-header" style={{ backgroundColor: "#EDF6F6"}}>
                                    <h4 className="modal-title fontWeight600"> <Icon className="message_icon color27AFAF" icon={officeBuildingMarker} height='25' width='25' /> Edit Stock Pipeline</h4>
                                    <div className="circle" id="closeModal_edit_stockpipeline" onClick={this.closeEditStockPipelineModal}>
                                        <img src="images/modal-close.png " width="12" height="12" alt="" />
                                    </div>
                                </div>
                                
                                {this.props.stockpipeline_form_error ? 
                                    <ErrorText errorMsg = {this.props.stockpipeline_form_error} />
                                : 
                                    null
                                }
                                
                                <div className="modal-body">
                                    <center>
                                    <form id="editStockPipelineform" onSubmit={this.submitHandler}>        
                                        {/* Full Address */}
                                        <div className="shadow" ref="myinput" style={(Number(this.props.add_property_modal_state)) === 1 ? {display: "block"} : {display: this.state.display_card} }>
                                            <p className="cardHeader">What would you like to call this property?</p>
                                            <p className="cardSubheader">Enter the property address details</p>
                                            <div className='form_border'>                                            
                                                <div className='full-input'>
                                                    <textarea className="input_modal fontWeight800" style={{resize: 'none'}} rows="4" cols="50" name='edit_full_address' id="edit_full_address" placeholder="Enter full address" ref="edit_full_address" maxLength="50" onChange={this.onInputchange} required>
                                                        {this.state.edit_full_address}
                                                    </textarea>
                                                    <p className={this.state.edit_full_address_length >= 40 ? "colorEA676A": null} style={{textAlign:"right"}}> {this.state.edit_full_address_length}/50</p>
                                                </div>
                                            </div>
                                            <br/><br/>
                                        </div>
                                        
                                        {/* Google Address */}
                                        <div className="shadow" style={(Number(this.props.add_property_modal_state)) === 1 ? {display: "block"} : {display: this.state.display_card} }>
                                            <p className="cardHeader ml10 mr10">Set this property’s location on the map for sharing directions.</p>
                                            {this.state.edit_full_address ?
                                                <EditAddressGoogleMap
                                                    parentCallback = {this.callbackFunction}
                                                />
                                                : null   
                                            }
                                            <br/><br/>
                                        </div>

                                        <br/><br/>
                                        {!this.state.loading ?
                                            <div> 
                                                <button id="addStockPipelineBtn" type="submit" className="btn btn-primary fontBold" style={{ background:'#4AA49C',borderRadius:'4px'}}>
                                                    <Icon className="message_icon" icon={officeBuildingMarker} height='20' width='20' />
                                                    Update stock Pipline
                                                </button>
                                            </div>
                                            :
                                            <Loader/>
                                        }
                                        <br/>
                                    </form>
                                    </center>
                                </div>
                            </div> 
                        </div>
                    </div> 
                </div>
            : 
              <Loader/>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        update_stock_pipeline_data : (token, user_id, full_address, unit_no, lat, lng, is_archived, stock_pipeline_id, map_address ) => dispatch(actions.update_stock_pipeline_data(token, user_id, full_address, unit_no, lat, lng, is_archived, stock_pipeline_id, map_address)),
        reset_google_map: () => dispatch(actions.reset_google_map()),
        reset_add_stockpipeline_form: () => dispatch(actions.reset_add_stockpipeline_form()),
        
        get_stock_pipeline_data : (page, is_archived, stock_pipeline_id, token) => dispatch(actions.get_stock_pipeline_data(page, is_archived, stock_pipeline_id, token)),
        get_stock_pipeline_property_data : (stock_pipeline_id, agent_id, token) => dispatch(actions.get_stock_pipeline_property_data(stock_pipeline_id, agent_id, token)),
        get_stockpipeline_chart_by_id : (fromDate, toDate, agent_id, token) => dispatch(actions.get_stockpipeline_chart_by_id(fromDate, toDate, agent_id, token)),
        //set page for calendar
        set_page: (page, reset_date_range) => dispatch(actions.set_page(page, reset_date_range)),
    }
}
  
const mapStateToProps = state => {
    return{ 
        authtoken: state.add_property_modal.authtoken,
        agent_data: state.agent_modal.agent_data,
        update_stock_pipeline_status: state.add_stock_pipeline.update_stock_pipeline_status,
        stockpipeline_form_error: state.add_stock_pipeline.stockpipeline_form_error,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditStockPipeline);
