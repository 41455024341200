import React ,  { Component } from 'react';
import {connect} from 'react-redux';
import * as actions from './store/actions/index';

import modal from './CustomFiles/js/modal';
import './CustomFiles/bower_components/bootstrap/dist/css/bootstrap.min.css';
import './CustomFiles/bower_components/font-awesome/css/font-awesome.min.css';
import './CustomFiles/bower_components/Ionicons/css/ionicons.min.css';
import './CustomFiles/dist/css/AdminLTE.min.css';
import './CustomFiles/plugins/iCheck/square/blue.css';

import './CustomFiles/bower_components/datatables.net-bs/css/dataTables.bootstrap.min.css';
import './CustomFiles/dist/css/skins/_all-skins.min.css';
import './CustomFiles/css/predefined.css';

import './CustomFiles/bower_components/bootstrap-daterangepicker/daterangepicker.css';

import Helmet from "react-helmet";
import Header from './components/menu/Header';
import PropertyDetailModal from './components/modals/PropertyDetailModal';
import Constants from './constants/Constants';
import "react-datepicker/dist/react-datepicker.css";
import SellerLinkedMessage from './components/modals/SellerLinkedMessage';
import AgentInformationModal from './components/modals/AgentInformationModal';
import IdleTimerContainer from './components/IdleTimerContainer';
import Footer from './components/Footer';
import DataTable from 'react-data-table-component';

const $ = require('jquery');
$.DataTable = require('datatables.net');

//datatable
let data=[];
let columns = [];  

const FilterComponent = ({ filterText, onFilter, onClear }) => (<>
    <label style={{ fontFamily: 'Roboto', fontSize: "14px", color: "#333333", fontWeight: "normal", marginTop:"7px", marginRight:"10px"}}>Search :</label>
    <input id="search" type="text" style={{border: "1px solid #daeeec", height: "28px"}} placeholder="Filter By Agent Name" aria-label="Search Input" value={filterText} onChange={onFilter} />
    </>
);

const customStyles = {
  rows: {
    style: {
      minHeight: '72px', // override the row height
    }
  },
  headCells: {
    style: {
      paddingLeft: '8px', // override the cell padding for head cells
      paddingRight: '8px',
      fontSize:'12px',
      color:"#4AA49C",
      fontFamily: 'Inter',
      fontWeight: 600,
      textAlign:'center',
      marginLeft:"20px",
    },
  },
  cells: {
    style: {
      paddingLeft: '18px', // override the cell padding for data cells
      paddingRight: '8px',
    },
  },
};

class CompareAgents extends Component{

  constructor(props){
    super(props);
    this.state ={
      agent_id:null,
      isLoading:false,
      month_year: null,

      filterText: "",
      resetPaginationToggle: false
    }
    this.clickFromCalender = this.clickFromCalender.bind(this);
  }

  componentDidMount = () =>{
    modal();
    const token = localStorage.getItem("token");
    if(!token){
      localStorage.clear();
      this.props.history.push("/");
      return;
    }

    var date = new Date();
    var month = date.getMonth()+1;
    var year = date.getFullYear();

    this.props.get_compare_agent_list(month, year, token);
    
    this.$el = $(this.el);
    this.$el.DataTable({
      'paging'      : false,
      'lengthChange': false,
      'searching'   : true,
    });
  }

  getRevenue = async () => {
    const token = localStorage.getItem("token");
    if(!token){
      localStorage.clear();
      this.props.history.push("/");
      return;
    }
    var month = $(".revenueMonthValue").attr('data-month');
    var year = $(".revenueMonthValue").attr("data-year");    
    if(!month){
      let d = new Date()
      month = d.getMonth()+1;
    }
    if(!year){
      let d= new Date();
      year = d.getFullYear();
    }

    this.setState({ isLoading:true })
    await this.props.get_compare_agent_list(month, year, token);
    this.setState({ isLoading:false })
  }

  handleShowModal = (event)=>{
    this.props.reset_agent_modal();
    const agent_id  =  event.target.id;

    this.setState({
      agent_id: agent_id,
    },()=> {
      window.$("#userProfileModal").modal('show');
      $(".user-modal-overview").click();
    })
  }

  handlePropertyModal = async (prop_id, date_sold, created_user_id, from_archiveProperty) =>{
    this.props.reset_property_modal();
    var fromDate = new Date("1/1/" + (new Date()).getFullYear());
    var toDate = new Date(new Date().getFullYear(), 11, 31, 0, 0, 0);

    var d = new Date(fromDate),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    fromDate = [year, month, day].join('-');

    d = new Date(toDate);
    month = '' + (d.getMonth() + 1);
    day = '' + d.getDate();
    year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    toDate = [year, month, day].join('-');

    const token     = localStorage.getItem('token');
    if(created_user_id){
      var property_notes_user_id = this.state.agent_id;
      let agent_id = created_user_id;
      let fromnetworking = 1;
      await this.props.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold, fromnetworking, property_notes_user_id);
    }else if(from_archiveProperty){
      let agent_id = this.state.agent_id;
      await this.props.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold, null, null, from_archiveProperty);
    }else{
      let agent_id = this.state.agent_id;
      await this.props.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold);
    }

    window.$("#profileModal").modal('show');
  }

  peopleLis = () => {
    var monthNames = [ "December", "November", "October", "September", "August", "July",
    "June", "May", "April", "March", "February", "January" ];
    
    let peopleToReturn = [];

    var date  = new Date();
    var current_year = date.getFullYear();
    var current_month = date.getMonth()+2;
    
    for( let year=current_year; year >= (current_year-3); year-- ){
      for (let i = 13; i > 1; i--) {
        if(year === current_year){
          if(i <= current_month){
            let month = i-1;
            peopleToReturn.push(
              <React.Fragment key={i+"_"+year}>
                <li style={i === current_month ? {background: "linear-gradient(140.65deg, #5FC8BF 19.91%, #3FA098 82.44%)"} : null }>
                  <a id={"selected_date_"+(i-1)+"_"+year} style={i === current_month ? {color: "white"} : null }
                    onClick={this.clickFromCalender} 
                    href="#mon" data-month={month} data-year={year}>{monthNames[13-i]}&nbsp;&nbsp;{year}</a>
                </li>
                <hr className="mt5 mb5" />
              </React.Fragment>
            );  
          }
        }else{
          let month = i-1;
          peopleToReturn.push(
            <React.Fragment key={i+"_"+year}>
              <li><a id={"selected_date_"+(i-1)+"_"+year} onClick={this.clickFromCalender} href="#mon" data-month={month} data-year={year}>{monthNames[13-i]}&nbsp;&nbsp;{year}</a></li>
              <hr className="mt5 mb5" />
            </React.Fragment>
          );
        }
      }
    };
    return peopleToReturn;
  }

  clickFromCalender(event) {
    const id = event.target.id;
    let all_element = document.querySelectorAll('[id^="selected_date_"]');
    // eslint-disable-next-line
    Object.keys(all_element).map(function (key) {
      let other_id = $(all_element[key]).attr('id');
      if( id !== other_id){
        document.getElementById(other_id).closest('li').style.background = "";
        document.getElementById(other_id).style.color = "#333";
      }
    });
    
    document.getElementById(id).closest('li').style.background = "linear-gradient(140.65deg, #5FC8BF 19.91%, #3FA098 82.44%)";
    document.getElementById(id).style.color = "white";
    this.setState({
      month_year : "Month: "+document.getElementById(id).text
    })
  }

  getSubHeaderComponent = () => {
    return (
      <FilterComponent
        onFilter={(e) => {
          let newFilterText = e.target.value;
          this.filteredItems = data.filter(
            (item) =>
              item.agent_name &&
              item.agent_name.toLowerCase().includes(newFilterText.toLowerCase())
          );
          this.setState({ filterText: newFilterText });
        }}
        onClear={this.handleClear}
        filterText={this.state.filterText}
      />
    );
  };
  
  changeDateFormat(fromDate){
    var d = new Date(fromDate),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year,month,day].join('-');
  }

  dateWisePropertyAppointment = (fromDate,toDate,prop_id,agent_id, date_sold, fromnetworking) => {
    fromDate = this.changeDateFormat(fromDate);
    toDate = this.changeDateFormat(toDate);

    const token     = localStorage.getItem('token');
    if(Number(fromnetworking) === 1){
      var property_notes_user_id = this.state.agent_id;
      let agent_id = this.props.property_detail.agent_id;
      let fromnetworking = 1;
      this.props.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold, fromnetworking, property_notes_user_id)
    }else{
      this.props.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold)
    }

  }

  render(){
    columns = [
      {
        name: 'Agents',
        selector: 'agent_name',
        sortable: true,
        ignoreRowClick: true,
        // onClick={window.handleShowModal(`${row.user_id}`)}
        width: "20%",
        style: {marginLeft: '15px'},
        cell: row => <div tag="allowRowEvents" id={`${row.user_id}`} onClick={Number(row.tracking) !== 0 ? this.handleShowModal : null} className={Number(row.tracking) !== 0 ? "cursorPointer": null}> 
                        <img tag="allowRowEvents" id={`${row.user_id}`} alt="" src={Constants.IMAGE_URL+row.image} className="img-circle" width="24" height="24" />
                        <span tag="allowRowEvents" id={`${row.user_id}`} className="color2D3E54 fontWeight600" style={{marginLeft: 5, fontFamily: "Inter"}}>{row.agent_name}</span> 
                        {Number(row.tracking) === 0 ? <span tag="allowRowEvents" className="trackingspan">Tracking Off</span> : null }
                      </div>
      },
      {
        name: ' ',
        selector: 'progress_bar',
        sortable: true,
        style: {marginLeft: '10px'},
        width: "60%",
        cell: row=> <div className="width100">
                    <div className="progress">
                      <div className={row.progress_bar} role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:row.profitability+"%"}} >
                      </div>
                    </div>
                    </div>
                    
      },
      {
        name: 'Profitability',
        selector: 'profitability',
        sortable: true,
        center: true,
        width: "15%",
        cell: row =>
                    <button type = "button" className={row.buttoncolor} style={{float:"right"}}>
                      {row.profitability}%
                    </button>
      }
    ];
    if(this.props.data){
      data =
        this.props.data.map((data, index) => (
        {
          user_id:          data.userid,
          tracking:         data.tracking_prefrence,
          image:            data.image,
          agent_name:       data.firstname+" "+data.lastname,
          
          profitability:    Number(data.tracking_prefrence) === 0 ? 0 : !data.profitability    ? 0 : Number(data.profitability),
          progress_bar:     data.profitability <= 0.00 ? "profitability-0" :
                            (data.profitability >= 0.00 && data.profitability <= 33.3) ? "progress-bar profitability0-33" :
                            (data.profitability >= 33.3 && data.profitability <= 66.6) ? "progress-bar profitability33-65" :
                            (data.profitability >= 66.6 && data.profitability <= 100)  ? " progress-bar profitability66-100" :
                            (data.profitability > 100.0) ? "progress-bar profitability100" :
                            "progress-bar profitability-0",
          buttoncolor:      data.profitability <= 0.00 ? "profitability profitability-0" :
                            (data.profitability >= 0.00 && data.profitability <= 33.3) ? "profitability profitability0-33" :
                            (data.profitability >= 33.3 && data.profitability <= 66.6) ? "profitability profitability33-65" :
                            (data.profitability >= 66.6 && data.profitability <= 100)  ? "profitability profitability66-100" :
                            (data.profitability > 100.0) ? "profitability profitability100" :
                            "profitability profitability-0",
        }
        
      ))  
    }
    
    this.filteredItems = data.filter(
      (item) =>
      item.agent_name && item.agent_name.toLowerCase().includes(this.state.filterText.toLowerCase())
    );

    let footer= "footer_color";
    if (window.innerWidth > document.body.clientWidth) {
      footer = 'footer';
    }
    var sellerLinkedMessage = null;
    sellerLinkedMessage = ( <SellerLinkedMessage property_detail={this.state.property_detail} /> );

    var d = new Date(),
    month = '' + (d.getMonth() + 1),
    year = d.getFullYear();
    
    let current_month = document.getElementById('selected_date_'+month+'_'+year);
    if(current_month){
      current_month = "Month: "+document.getElementById("selected_date_"+month+"_"+year).text
    }

    return(
      <div style={{fontSize:'14px'}}>
        <IdleTimerContainer></IdleTimerContainer>
        <Helmet>
          <title>Revenue</title>
          <link rel="icon" href="https://roundr.app/wp-content/uploads/2018/03/favicon.png" type="image/png"></link>
        </Helmet>

        <div className="content-wrapper" style={{marginLeft:'0px' }}>
          <Header active = "revenue"/>

          <div className ="ml50 mt70">
            <a href="/revenue"><span style={{color:'#4AA49C',fontWeight:'500',fontSize:'17px'}} className="cursorPointer">
              <img src ="images/Back.png" alt=""/>
              <span className="padLeft15">Back</span>
            </span></a>
            <h2 className = "fontInter">
              <b>Compare</b> Agents
            </h2>
            <br />
          </div>
          <section className="content" style={{margin:'25px'}}>
            <div className="row">
              <div className="col-xs-12">
                <div className="box">
                  <div className = "row">
                    <div className = "col-md-4 padTop10 padLeft45">
                      <img src= "images/revenue.png" width="18" height="18" alt=""/>
                      <span className = "fontSize22 padTop5 padLeft10"> Revenue</span>
                    </div>
                    <div className = "col-md-3"></div>
                    <div className = "col-md-3 padTop15 padRight25 dropdown" style={{float:'right',display:'flex'}}  >
                      <input id="month_year_txt" type = "text"  name ="" value={this.state.month_year ? this.state.month_year : current_month } readOnly data-toggle="dropdown" placeholder = "Month : Febuary 2020" className = "dropdown-toggle form-control revenueMonthValue" style={{borderRadius:'4px'}} />
                      <ul className="dropdown-menu revenue-month">
                        {this.peopleLis()}
                        <hr className="mt5 mb5"/>
                      </ul>
                      <button type="button"  style={{backgroundColor:'#5FC8BF'}} className="btn btn-info"  onClick={this.getRevenue}  >Go</button>
                    </div>
                  </div> {/* End Row Here  */}

                  {this.state.isLoading ? 
                    <center>
                      <img src="images/loader.svg" width="50" height="50" alt="" />
                    </center>
                  :
                    null 
                  }

                  <div>
                    <DataTable
                      noHeader={true}
                      highlightOnHover={true}
                      pagination={true}
                      // progressPending="true"
                      // progressComponent={<Loader />}
                      subHeader
                      subHeaderComponent={this.getSubHeaderComponent()}
                      columns={columns}
                      data={this.filteredItems}
                      customStyles={customStyles}
                      ignoreRowClick={true}
                      defaultSortField={'agent_name'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Agent Information Modal Start From Here */}
          <AgentInformationModal 
            agent_id = { this.state.agent_id}
            handlePropertyModal = { this.handlePropertyModal }
          />

          <PropertyDetailModal
            dateWisePropertyAppointment = { this.dateWisePropertyAppointment }
            property_detail={this.props.property_detail}
            appointment_cnt={this.props.appointment_cnt}
            general_cnt_property_modal= {this.props.general_cnt_property_modal}
            feedback={this.props.feedback}
            seller_message={this.props.seller_message}
            occupant_message={this.props.occupant_message}
            agent_data={this.props.agent_data}
            sellerLinkedMessage={sellerLinkedMessage}
            agent_id    = { this.state.agent_id }
            html_export={this.props.html_export}
            property_notes_html={this.props.property_notes_html}
            office_notes_html={this.props.office_notes_html}
          />
        </div>
        <Footer footer={footer}></Footer>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    get_compare_agent_list : (month, year, token) => dispatch(actions.get_compare_agent_list(month, year, token)),
    reset_agent_modal: () => dispatch(actions.reset_agent_modal()),
    reset_property_modal: () => dispatch(actions.reset_property_modal()),
    get_property_data_modal: (fromDate, toDate, prop_id, agent_id, token, date_sold, fromnetworking, property_notes_user_id, from_archiveProperty) => dispatch(actions.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold, fromnetworking, property_notes_user_id, from_archiveProperty)),
  };
}

const mapStateToProps = state => {
  return{
    data: state.compare_reducer.compare_agent_list,

    //property_modal
    property_detail: state.property_modal.property_detail,
    appointment_cnt: state.property_modal.appointment_cnt,
    general_cnt_property_modal: state.property_modal.general_cnt_property_modal,
    feedback: state.property_modal.feedback,
    seller_message: state.property_modal.seller_message,
    occupant_message: state.property_modal.occupant_message,
    html_export :state.property_modal.html_export,
    property_notes_html: state.property_modal.property_notes_html,
    office_notes_html: state.property_modal.office_notes_html,

    //agent_modal
    agent_data: state.agent_modal.agent_data
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompareAgents);
