import React from 'react';
import {Line} from 'react-chartjs-2';
import Constants from '../../constants/Constants';

class RevenueChart extends React.Component {

  render() {

    var dataSets = [];
    var dataSetsLength = this.props.total_agent;
    var globalAgentList =  this.props.globalAgentList;
    var globalAgentListName =  this.props.globalAgentListName;
    var globalAgentListImage = this.props.globalAgentListImage;
    var chartheight = 0
    if(this.props.settings && this.props.settings.length > 0){
      if(Number(this.props.settings[0]) === 5 && (this.props.settings.length % 2 !== 0)){
        chartheight = 75
      }else{
        chartheight = 150
      }
    }
    for (let i = 0; i < dataSetsLength; i++) {
      
      var agentId = globalAgentList[i]; 
      var dataSetsChart = (this.props.chart_revenue_cnt[0][agentId])
      let agent_image='';

      if(globalAgentListImage[i]){
        agent_image = Constants.IMAGE_URL + globalAgentListImage[i];
      }else{
        agent_image = "user-images/userDefault.png"
      }
      dataSets.push(
        {
          label:'<img style="border-radius: 50%; margin-right: 5px;" width="20" height="20" src="' + agent_image+'" alt=""/>' + globalAgentListName[i].name,
          data:dataSetsChart,
          borderColor:globalAgentListName[i].color,
          fill:false,
          lineTension: 0,
          backgroundColor: globalAgentListName[i].color,
          borderWidth: 2,
        },
      )
    }


    var state = {
      labels: this.props.revenue_labels,
      datasets: dataSets
    }


    if( this.props.overview === 0 || this.props.overview === "0" ){

      return (
        <div>
            <Line
            data={state}
            height={85}
            options={{
              legend:{
                display:false,
                position:'right'
              },
              tooltips: {
                // Disable the on-canvas tooltip
                enabled: false,

                custom: function(tooltipModel) {
                  // Tooltip Element
                  var tooltipEl = document.getElementById('chartjs-tooltip');

                  // Create element on first render
                  if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjs-tooltip';
                    tooltipEl.innerHTML = '<table></table>';
                    document.body.appendChild(tooltipEl);
                  }

                  // Hide if no tooltip
                  if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                  }

                  // Set caret Position
                  tooltipEl.classList.remove('above', 'below', 'no-transform');
                  if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                  } else {
                    tooltipEl.classList.add('no-transform');
                  }

                  function getBody(bodyItem) {
                    return bodyItem.lines;
                  }

                  // Set Text
                  if (tooltipModel.body) {
                    var titleLines = tooltipModel.title || [];
                    var bodyLines = tooltipModel.body.map(getBody);

                    var innerHtml = '<thead>';

                    titleLines.forEach(function(title) {
                        innerHtml += '<tr><th style="border:0px !important; color:#fff !important; height:0px !important; padding-left:0px !important">' + title + '</th></tr>';
                    });
                    innerHtml += '</thead><tbody>';

                    bodyLines.forEach(function(body, i) {
                        // var colors = tooltipModel.labelColors[i];
                        // var style = 'background:' + colors.backgroundColor;
                        // style += '; border-color:' + colors.borderColor;
                        var style = '; border-width: 2px';
                        var span = '<span style="' + style + '"> </span>';
                        innerHtml += '<tr style="border:0px !important"><td style="border:0px !important; height:0px !important; padding-left:0px !important">' + span + body + '</td></tr>';
                    });
                    innerHtml += '</tbody>';

                    var tableRoot = tooltipEl.querySelector('table');
                    tableRoot.innerHTML = innerHtml;
                  }

                  // `this` will be the overall tooltip
                  var position = this._chart.canvas.getBoundingClientRect();

                  // Display, position, and set styles for font
                  tooltipEl.style.opacity = 1;
                  tooltipEl.style.position = 'absolute';
                  if(tooltipModel.caretX < 700){
                    tooltipEl.style.right = (position.right - (window.pageXOffset + tooltipModel.caretX))-235 + 'px';
                    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX  + 'px';
                  }else{
                    tooltipEl.style.left = (position.left + window.pageXOffset + tooltipModel.caretX)-235  + 'px';
                    tooltipEl.style.right = position.right - (window.pageXOffset + tooltipModel.caretX) + 'px';
                  }
                  tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                  tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                  tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                  tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                  tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                  tooltipEl.style.pointerEvents = 'none';
                  tooltipEl.style.color = '#fff';
                  tooltipEl.style.background = '#333';
                }
              },
              scales: {
                yAxes: [{
                  display: true,
                  ticks: {
                    beginAtZero: true,
                    userCallback: function(label, index, labels) {
                      if (Math.floor(label) === label) {
                          return label;
                      }
                    },
                  }
                }]
              }
            }}
          />
        </div>
      );

    }
    else{
    return (
      <div>
          <Line
          height={chartheight}
          data={state}
          options={{
            legend:{
              display:false,
              position:'right'
            },
            tooltips: {
              // Disable the on-canvas tooltip
              enabled: false,

              custom: function(tooltipModel) {
                // Tooltip Element
                var tooltipEl = document.getElementById('chartjs-tooltip');

                // Create element on first render
                if (!tooltipEl) {
                  tooltipEl = document.createElement('div');
                  tooltipEl.id = 'chartjs-tooltip';
                  tooltipEl.innerHTML = '<table></table>';
                  document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                if (tooltipModel.opacity === 0) {
                  tooltipEl.style.opacity = 0;
                  return;
                }

                // Set caret Position
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                  tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                  tooltipEl.classList.add('no-transform');
                }

                function getBody(bodyItem) {
                  return bodyItem.lines;
                }

                // Set Text
                if (tooltipModel.body) {
                  var titleLines = tooltipModel.title || [];
                  var bodyLines = tooltipModel.body.map(getBody);

                  var innerHtml = '<thead>';

                  titleLines.forEach(function(title) {
                      innerHtml += '<tr><th style="border:0px !important; color:#fff !important; height:0px !important; padding-left:0px !important">' + title + '</th></tr>';
                  });
                  innerHtml += '</thead><tbody>';

                  bodyLines.forEach(function(body, i) {
                      // var colors = tooltipModel.labelColors[i];
                      // var style = 'background:' + colors.backgroundColor;
                      // style += '; border-color:' + colors.borderColor;
                      var style = '; border-width: 2px';
                      var span = '<span style="' + style + '"> </span>';
                      innerHtml += '<tr style="border:0px !important"><td style="border:0px !important; height:0px !important; padding-left:0px !important">' + span + body + '</td></tr>';
                  });
                  innerHtml += '</tbody>';

                  var tableRoot = tooltipEl.querySelector('table');
                  tableRoot.innerHTML = innerHtml;
                }

                // `this` will be the overall tooltip
                var position = this._chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                tooltipEl.style.opacity = 1;
                tooltipEl.style.position = 'absolute';
                tooltipEl.style.left = position.left + (window.pageXOffset-35) + (tooltipModel.caretX-100) + 'px';
                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                tooltipEl.style.pointerEvents = 'none';
                tooltipEl.style.color = '#fff';
                tooltipEl.style.background = '#333';
              }
            },
            scales: {
              yAxes: [{
                display: true,
                ticks: {
                  beginAtZero: true,
                  userCallback: function(label, index, labels) {
                    if (Math.floor(label) === label) {
                        return label;
                    }
                  },
                }
              }]
            }
          }}
        />
      </div>
    );
    }
  }
}

export default RevenueChart;
