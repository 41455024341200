import React , { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from './store/actions/index'; 

import './App.css';
import { Route,BrowserRouter as Router } from 'react-router-dom';

import AdminLogin     from './auth/AdminLogin';
import Login          from './auth/Login';
import ForgotPass     from './auth/ForgotPass';
import Overview       from './Overview';
import Properties     from './Properties';
import Appointment    from './Appointment';
import Revenue        from './Revenue';
import BuyerLead       from './BuyerLead';
import CompareAgents  from './CompareAgents';
import Payment        from './payment/Payment';
import PaymentSuccess from './payment/PaymentSuccess';
//import PaymentFailed from './PaymentFailed.js';
import TransactionLog from './TransactionLog.js';
import Pricing from './Pricing.js'
import Logout         from './auth/Logout';

class App extends Component {
  componentDidMount(){
    this.props.onTryAutoSignup();
  }

  render(){
    return(<div>
      <Router>
          <Route                path="/adminlogin"      component={AdminLogin}/>
          <Route exact = {true} path="/"                component={Login} />
          <Route exact = {true} path="/forgot-pass"     component={ForgotPass} />
          <Route exact = {true} path="/overview"        component={Overview}  />
          <Route exact = {true} path="/properties"      component={Properties}  />
          <Route exact = {true} path="/viewings"        component={Appointment}  />
          <Route exact = {true} path="/revenue"         component={Revenue}  />
          <Route exact = {true} path="/buyerlead"       component={BuyerLead} />
          <Route exact = {true} path ="/compare-agents" component = {CompareAgents} />
          <Route exact = {true} path="/payment"         component={Payment}  />
          <Route exact = {true} path="/payment-success" component={PaymentSuccess}  />
          <Route exact = {true} path="/transaction-log" component={TransactionLog}  />
          <Route exact = {true} path="/pricing"         component={Pricing}/>
          <Route exact = {true} path="/logout"          component={Logout} />
      </Router>
    </div>);
  }
}

const mapDispatchToProps = dispatch =>{
  return{
    onTryAutoSignup: ()=> dispatch(actions.authCheckState())
  };
};

export default connect(null,mapDispatchToProps)(App);
