import React ,  { Component } from 'react';
import {connect} from 'react-redux';
import * as actions from './store/actions/index';

import modal from './CustomFiles/js/modal';
//icons
import { Icon } from '@iconify/react';
import downloadFill from '@iconify/icons-eva/download-fill';

import './CustomFiles/bower_components/bootstrap/dist/css/bootstrap.min.css';
import './CustomFiles/bower_components/font-awesome/css/font-awesome.min.css';
import './CustomFiles/bower_components/Ionicons/css/ionicons.min.css';
import './CustomFiles/dist/css/AdminLTE.min.css';
import './CustomFiles/plugins/iCheck/square/blue.css';

import './CustomFiles/bower_components/datatables.net-bs/css/dataTables.bootstrap.min.css';
import './CustomFiles/dist/css/skins/_all-skins.min.css';
import './CustomFiles/css/predefined.css';

import Helmet from "react-helmet";
import Header from './components/menu/Header';
import Footer from './components/Footer';
import Constants from './constants/Constants';
import SellerLinkedMessage from './components/modals/SellerLinkedMessage';
import "react-datepicker/dist/react-datepicker.css";
import PropertyDetailModal from './components/modals/PropertyDetailModal';
import AddAppointment from './components/modals/Appointment/AddAppointment';

import RevenueChart from './components/charts/RevenueChart';
import Loader from './components/Loader';
import AgentInformationModal from './components/modals/AgentInformationModal';
import IdleTimerContainer from './components/IdleTimerContainer';
import AgentList from './components/AgentList';
import DataTable from 'react-data-table-component';
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

var colorList = ['#7B8CA1','#EA676A','#63C8BA','#F8CA55','#F09A56','#6ADA8A', '#9B66E3', '#547DE5', '#B5A78D', '#DE6DA8',
  '#A368D9','#B43B1D','#229649','#2C3E50','#8c9966','#53F49B','#DC9D7D','#660E52','#39C9AE','#BCA7C4',
  '#D08E27','#A3DB7D','#606792','#11E797','#1DC1C1','#D5C175','#1DC1C1','#421B6C','#98E708','#EE2A09',  
  '#017C33','#24F080','#CC5A45','#801FD4','#04405D','#133123','#1B42B1','#7EC377','#2DE6E0','#9FCE27',
  '#F96CDD','#41197D','#4A809B','#794D61','#BA28E3','#183A8C','#D07848','#4F3B87','#3A8947','#100769',
  '#B7931E','#AA0A71','#417A92','#AC5B6C','#7E8F92','#C06EA7','#866E50','#561C56','#DB3DAF','#F03C44',
  '#90A8F4','#93AC64','#5B532B','#1CBB4B','#05A5A5','#CEC21E','#D3A8FF','#3F4D5E','#BD0B49','#2CA6A0',
  '#C7B541','#6D8D6B','#70C29B','#0B0BE2','#EE19DA','#588546','#DBB5A2','#609CBF','#38198D','#904B6C',
  '#8BD18C','#32638D','#792A4F','#7184E1','#35A50F','#4B3871','#8C8007','#4B2257','#089564','#F1A0C1',
  '#0D9168','#8CA06C','#0A7EB7','#AB53CB','#43246A','#2F0020','#06759B','#785646','#B36935','#1A7607',
];

const $ = require('jquery');
$.DataTable = require('datatables.net');

var globalAgentList = [];
var globalAgentListName = [];
var globalAgentListImage = [];

//datatable
let data=[];
let columns = [];
let data_table_data = [];

const FilterComponent = ({ filterText, onFilter, onClear }) => (<>
  <label style={{ fontFamily: 'Roboto', fontSize: "14px", color: "#333333", fontWeight: "normal", marginTop:"7px", marginRight:"10px"}}>Search :</label>
  <input id="search" type="text" style={{border: "1px solid #daeeec", height: "28px"}} placeholder="Filter By Agent Name" aria-label="Search Input" value={filterText} onChange={onFilter} />
  </>
);

const customStyles = {
  rows: {
    style: {
      minHeight: '72px', // override the row height
    }
  },
  headCells: {
    style: {
      paddingLeft: '8px', // override the cell padding for head cells
      paddingRight: '8px',
      fontSize:'12px',
      color:"#4AA49C",
      fontFamily: 'Inter',
      fontWeight: 600,
      textAlign:'center',
      marginLeft:"20px"
    },
  },
  cells: {
    style: {
      paddingLeft: '18px', // override the cell padding for data cells
      paddingRight: '8px',
    },
  },
};

class Revenue extends Component{

  constructor(props){
    super(props);
    this.state ={
      agent_id:null,
      isLoading:false,
      agent_list_data: null,
      globalAgentList: null,
      globalAgentListName: null,
      globalAgentListImage: null,
      month_year: null,
      average_cost_per_agent: null,
      
      filterText: "",
      resetPaginationToggle: false,
      date_forExcel: new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    }
    this.clickFromCalender = this.clickFromCalender.bind(this);
    this.onInputchange = this.onInputchange.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.handleShowModal = this.handleShowModal.bind(this);
  }

  getSubHeaderComponent = () => {
    return (
      <FilterComponent
        onFilter={(e) => {
          let newFilterText = e.target.value;
          this.filteredItems = data.filter(
            (item) =>
              item.agent_name &&
              item.agent_name.toLowerCase().includes(newFilterText.toLowerCase())
          );
          this.setState({ filterText: newFilterText });
        }}
        onClear={this.handleClear}
        filterText={this.state.filterText}
      />
    );
  };

  handleClear = () => {
    const { resetPaginationToggle } = this.state;

    if (this.state.filterText) {
      this.setState({
        resetPaginationToggle: !resetPaginationToggle,
        filterText: ""
      });
    }
  };

  changeDateFormat(fromDate){
    var d = new Date(fromDate),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year,month,day].join('-');
  }
  
  changeDateFormat_DMY(fromDate){
    var d = new Date(fromDate),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [day,month,year].join('-');
  }

  async componentDidMount (){
    modal();
    const token = localStorage.getItem('token');
    
    if( token   === '' || token === null ){
      localStorage.clear();
      this.props.history.push("/");
      return;
    }

    var date = new Date();
    var month = date.getMonth()+1;
    var year = date.getFullYear();
    //get agent list
    await this.props.get_agent_list_revenue(month, year, token);

    // var fromDate = new Date("1/1/" + (new Date()).getFullYear());
    // var toDate = new Date(new Date().getFullYear(), 11, 31,0,0,0);
    // fromDate =  this.changeDateFormat(fromDate);
    // toDate =  this.changeDateFormat(toDate);
    var da = new Date();
    var fromDate = this.changeDateFormat(new Date(da.getFullYear(), da.getMonth() - 2, 1));
    var toDate = this.changeDateFormat(new Date(da.getFullYear(), da.getMonth(), da.getDate()));
    
    // get revenue chart 
    this.props.get_revenue_chart_revenue(fromDate, toDate, this.props.agent_list, token);
    this.setState({
      agent_list_data: [...JSON.parse(localStorage.getItem('data'))]
    })
    this.props.set_page("revenue_page", true);
  }

  getRevenue = () =>{    
    const token = localStorage.getItem("token");
    if(!token){
      localStorage.clear();
      this.props.history.push("/");
      return;
    }
    var month  = $(".revenueMonthValue").attr("data-month");
    var year = $(".revenueMonthValue").attr("data-year");

    this.setState({ isLoading:true })

    if(!month){
      let d = new Date()
      month = d.getMonth()+1;
    }
    if(!year){
      let d= new Date();
      year = d.getFullYear();
    }
    //Renveue Agent List
    this.props.get_agent_list_revenue(month, year, token);
    this.setState({ 
      isLoading:false,
      date_forExcel: this.changeDateFormat_DMY(month+"-01-"+year)
    })
  }

  handleShowModal = (event)=>{
    this.props.reset_agent_modal();
    const agent_id  =  event.target.id;

    this.setState({
      agent_id: agent_id,
    },()=> {
      window.$("#userProfileModal").modal('show');
      $(".user-modal-overview").click();
    })
  }

  handlePropertyModal = async (prop_id, date_sold, created_user_id, from_archiveProperty) =>{
    this.props.reset_property_modal();
    const show_agents = 0
    const show_property = 0
    const from_property_page = 1
    const from_stock_pipeline = 0
    await this.props.update_add_appointment_modal_state(show_agents,show_property,from_property_page,from_stock_pipeline)
    // var fromDate = new Date("1/1/" + (new Date()).getFullYear());
    // var toDate = new Date(new Date().getFullYear(), 11, 31, 0, 0, 0);

    // var d = new Date(fromDate),
    // month = '' + (d.getMonth() + 1),
    // day = '' + d.getDate(),
    // year = d.getFullYear();

    // if (month.length < 2) month = '0' + month;
    // if (day.length < 2) day = '0' + day;
    // fromDate = [year, month, day].join('-');

    // d = new Date(toDate);
    // month = '' + (d.getMonth() + 1);
    // day = '' + d.getDate();
    // year = d.getFullYear();

    // if (month.length < 2) month = '0' + month;
    // if (day.length < 2) day = '0' + day;
    // toDate = [year, month, day].join('-');

    var da = new Date();
    var fromDate = this.changeDateFormat(new Date(da.getFullYear(), da.getMonth() - 2, 1));
    var toDate = this.changeDateFormat(new Date(da.getFullYear(), da.getMonth(), da.getDate()));
    
    const token     = localStorage.getItem('token');
    if(created_user_id){
      var property_notes_user_id = this.state.agent_id;
      let agent_id = created_user_id;
      let fromnetworking = 1;
      await this.props.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold, fromnetworking, property_notes_user_id);
    }else if(from_archiveProperty){
      let agent_id = this.state.agent_id;
      await this.props.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold, null, null, from_archiveProperty);
    }else{
      let agent_id = this.state.agent_id;
      await this.props.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold);
    }
    this.props.set_page("property_modal", true);
    window.$("#profileModal").modal('show');
  }

  peopleLis = () => {
    var monthNames = [ "December", "November", "October", "September", "August", "July",
    "June", "May", "April", "March", "February", "January" ];
    
    let peopleToReturn = [];

    var date  = new Date();
    var current_year = date.getFullYear();
    var current_month = date.getMonth()+2;
    
    for( let year=current_year; year >= (current_year-3); year-- ){
      for (let i = 13; i > 1; i--) {
        if(year === current_year){
          if(i <= current_month){
            let month = i-1;
            peopleToReturn.push(
              <React.Fragment key={i+"_"+year}>
                <li style={i === current_month ? {background: "linear-gradient(140.65deg, #5FC8BF 19.91%, #3FA098 82.44%)"} : null }>
                  <a id={"selected_date_"+(i-1)+"_"+year} style={i === current_month ? {color: "white"} : null }
                    onClick={this.clickFromCalender} 
                    href="#mon" data-month={month} data-year={year}>{monthNames[13-i]}&nbsp;&nbsp;{year}</a>
                </li>
                <hr className="mt5 mb5" />
              </React.Fragment>
            );  
          }
        }else{
          let month = i-1;
          peopleToReturn.push(
            <React.Fragment key={i+"_"+year}>
              <li><a id={"selected_date_"+(i-1)+"_"+year} onClick={this.clickFromCalender} href="#mon" data-month={month} data-year={year}>{monthNames[13-i]}&nbsp;&nbsp;{year}</a></li>
              <hr className="mt5 mb5" />
            </React.Fragment>
          );
        }
      }
    };
    return peopleToReturn;
  }

  clickFromCalender(event) {
    const id = event.target.id;
    let all_element = document.querySelectorAll('[id^="selected_date_"]');
    // eslint-disable-next-line
    Object.keys(all_element).map(function (key) {
      let other_id = $(all_element[key]).attr('id');
      if( id !== other_id){
        document.getElementById(other_id).closest('li').style.background = "";
        document.getElementById(other_id).style.color = "#333";
      }
    });
    
    document.getElementById(id).closest('li').style.background = "linear-gradient(140.65deg, #5FC8BF 19.91%, #3FA098 82.44%)";
    document.getElementById(id).style.color = "white";
    this.setState({
      month_year : "Month: "+document.getElementById(id).text
    })
  }

  renderRevenueChart = () => {
    var agentList =  this.props.agent_list;
    var revenueChart = '';
    var temp_revenue_chart_data=[];
    temp_revenue_chart_data = JSON.parse(JSON.stringify(this.props.chart_revenue_cnt));
    
    if(agentList.length > 0 && (temp_revenue_chart_data[0])){
      globalAgentListName =[];
      for( let i = 0; i < agentList.length ; i++ ){
        //adding agents with tracking on
        if(Number(agentList[i].tracking_prefrence) !== Number(0) ){
          var agentName = agentList[i].firstname + ' ' + agentList[i].lastname;
          var color  =  colorList[i];
          globalAgentListName.push({
            agent_id: agentList[i].userid,
            name: agentName,
            color: color
          });
          globalAgentList.push(agentList[i].userid);
          globalAgentListImage.push(agentList[i].image);
        }else{
          //Removing graph data of agents with tracking off 
          delete temp_revenue_chart_data[0][agentList[i].userid];
        }
      }
      
      if (this.props.revenue_labels && (this.props.revenue_labels.length > 0 && this.props.agent_list.length > 0)) {
          revenueChart = (<RevenueChart
          globalAgentListName={globalAgentListName}
          globalAgentListImage={globalAgentListImage}
          globalAgentList={globalAgentList}
          total_agent={globalAgentListName.length} overview="0"
          revenue_labels={this.props.revenue_labels} chart_revenue_cnt={temp_revenue_chart_data} />);
        return revenueChart;
      }
    }
    else {
      revenueChart = (<Loader />);
      return revenueChart;
    }
  }

  dateWisePropertyAppointment = (fromDate,toDate,prop_id,agent_id, date_sold, fromnetworking) => {
    fromDate = this.changeDateFormat(fromDate);
    toDate = this.changeDateFormat(toDate);

    const token     = localStorage.getItem('token');
    if(Number(fromnetworking) === 1){
      var property_notes_user_id = this.state.agent_id;
      let agent_id = this.props.property_detail.agent_id;
      let fromnetworking = 1;
      this.props.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold, fromnetworking, property_notes_user_id)
    }else{
      this.props.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold)
    }

  }

  callbackFunction = (globalAgentList, globalAgentListName, globalAgentListImage) => {      
    this.setState({
      globalAgentList: [...globalAgentList],
      globalAgentListName: [...globalAgentListName ],
      globalAgentListImage: [...globalAgentListImage]
    })
  }

  onInputchange(event) {
    this.setState({
        [event.target.name]: event.target.value
    });
  }

  async submitHandler(event){
    event.preventDefault(); 
    
    const token = localStorage.getItem("token");
    if(!token){
      localStorage.clear();
      this.props.history.push("/");
      return;
    }
    var month  = $(".revenueMonthValue").attr("data-month");
    var year = $(".revenueMonthValue").attr("data-year");

    this.setState({ isLoading:true })

    if(!month){
      let d = new Date()
      month = d.getMonth()+1;
    }
    if(!year){
      let d= new Date();
      year = d.getFullYear();
    }

    //Renveue Agent List
    this.props.get_agent_list_revenue(month, year, token, this.state.average_cost_per_agent);
    this.setState({ isLoading:false })
  }

  render(){
    columns = [
      {
        name: 'Agents',
        selector: 'agent_name',
        sortable: true,
        ignoreRowClick: true,
        // onClick={window.handleShowModal(`${row.user_id}`)}
        style: {marginLeft: '15px'},
        cell: row => <div tag="allowRowEvents" id={`${row.user_id}`} onClick={Number(row.tracking) !== 0 ? this.handleShowModal : null} className={Number(row.tracking) !== 0 ? "cursorPointer": null}> 
                        <img tag="allowRowEvents" id={`${row.user_id}`} alt='' src={row.image ? Constants.IMAGE_URL+row.image : "user-images/userDefault.png"} className="img-circle" width="24" height="24" />
                        <span tag="allowRowEvents" id={`${row.user_id}`} className="color2D3E54 fontWeight600" style={{marginLeft: 5, fontFamily: "Inter", textDecoration: "underline"}}>{row.firstname} {row.lastname}</span> 
                        {Number(row.tracking) === 0 ? <span tag="allowRowEvents" className="trackingspan">Tracking Off</span> : null }
                      </div>
      },
      {
        name: 'Sales',
        selector: 'sales_count',
        sortable: true,
        style: {marginLeft: '-20px'},
        center: true
      },
      {
        name: 'Rentals',
        selector: 'rental_count',
        sortable: true,
        //style: {marginLeft: '-25px'},
        center: true
      },
      {
        name: 'Sale-Revenue',
        selector: 'sale_revenue',
        sortable: true,
        //style: {marginLeft: '10px'},
        center: true
      },
      {
        name: 'Rental-Revenue',
        selector: 'rental_revenue',
        sortable: true,
        //style: {marginLeft: '10px'},
        center: true
      },
      // {
      //   name: 'Viewings',
      //   selector: 'appt',
      //   sortable: true,
        
      // },
      // {
      //   name: 'CTC',
      //   selector: 'CTC',
      //   sortable: true,
        
      // },
      // {
      //   name: 'Cost/Viewing',
      //   selector: 'cost_per_appt',
      //   sortable: true,
        
      // },
      // {
      //   name: 'Revenue',
      //   selector: 'revenue',
      //   sortable: true,
        
      // },
      // {
      //   name: 'Revenue/Viewing',
      //   selector: 'revenue_per_appt',
      //   sortable: true,
        
      // },
      // {
      //   name: 'Profitability',
      //   selector: 'profitability',
      //   sortable: true,
      //   center: true,
      //   cell: row =>
      //               <button type = "button" className={row.buttoncolor}>
      //                 {row.profitability}%
      //               </button>
      // }
    ];
    if(this.props.agent_list){
      data =
        this.props.agent_list.map((data, index) => (
        {
          user_id:          data.userid,
          tracking:         data.tracking_prefrence,
          firstname:        data.firstname,
          lastname:         data.lastname,
          image:            data.image,
          agent_name:       data.firstname +" "+ data.lastname,
          rental_count:     Number(data.tracking_prefrence) === 0 ? 0 : !data.rented_count     ? 0 : Number(data.rented_count),
          sale_revenue:     Number(data.tracking_prefrence) === 0 ? 0 : !data.sale_revenue     ? 0 : Number(data.sale_revenue),
          rental_revenue:   Number(data.tracking_prefrence) === 0 ? 0 : !data.rented_revenue   ? 0 : Number(data.rented_revenue),
          sales_count:      Number(data.tracking_prefrence) === 0 ? 0 : !data.sales_count      ? 0 : Number(data.sales_count),
          appt:             Number(data.tracking_prefrence) === 0 ? 0 : !data.appt             ? 0 : Number(data.appt),
          CTC:              Number(data.tracking_prefrence) === 0 ? 0 : !data.CTC              ? 0 : Number(data.CTC),
          cost_per_appt:    Number(data.tracking_prefrence) === 0 ? 0 : !data.cost_per_appt    ? 0 : Number(data.cost_per_appt),
          revenue:          Number(data.tracking_prefrence) === 0 ? 0 : !data.revenue          ? 0 : Number(data.revenue),
          revenue_per_appt: Number(data.tracking_prefrence) === 0 ? 0 : !data.revenue_per_appt ? 0 : Number(data.revenue_per_appt),
          profitability:    Number(data.tracking_prefrence) === 0 ? 0 : !data.profitability    ? 0 : Number(data.profitability),
          buttoncolor:      data.profitability <= 0.00 ? "profitability profitability-0" :
                            (data.profitability >= 0.00 && data.profitability <= 33.3) ? "profitability profitability0-33" :
                            (data.profitability >= 33.3 && data.profitability <= 66.6) ? "profitability profitability33-65" :
                            (data.profitability >= 66.6 && data.profitability <= 100)  ? "profitability profitability66-100" :
                            (data.profitability > 100.0) ? "profitability profitability100" :
                            "profitability profitability-0",
        }
        
      ))  
    }
    
    this.filteredItems = data.filter(
      (item) =>
      item.agent_name && item.agent_name.toLowerCase().includes(this.state.filterText.toLowerCase())
    );
    data_table_data = this.filteredItems

    let footer= "footer_color";
    if (window.innerWidth > document.body.clientWidth) {
      footer = 'footer';
    }
    var sellerLinkedMessage = null;
    sellerLinkedMessage = ( <SellerLinkedMessage property_detail={this.props.property_detail} /> );
    
    var revenue_sum = 0;
    var agents =  this.props.agent_list;
    if((this.props.chart_revenue_cnt) && (this.props.chart_revenue_cnt.length > 0 && this.props.agent_list)){
      for(let a=0 ; a < this.props.agent_list.length;a++){
        var agentId = agents[a].userid;
        var dataSetsChart = (this.props.chart_revenue_cnt[0][agentId])
        if(dataSetsChart){
          for(let b = 0; b < dataSetsChart.length; b++){
            revenue_sum = revenue_sum + parseFloat(dataSetsChart[b]);
          }
        }
      }
    }
    // let average_cost_per_agent = '';
    // if(this.props.agent_list){
    //   average_cost_per_agent = agents[0].CTC;
    // }
    var d = new Date(),
    month = '' + (d.getMonth() + 1),
    year = d.getFullYear();

    let current_month = document.getElementById('selected_date_'+month+'_'+year);
    if(current_month){
      current_month = "Month: "+document.getElementById("selected_date_"+month+"_"+year).text
    }
    
    //let file_name  = "Revenue From - "+ this.changeDateFormat_DMY(this.props.page_from_date) +" To- "+ this.changeDateFormat_DMY(this.props.page_to_date);
    let file_name  = "Revenue Report - "+ this.changeDateFormat_DMY(this.state.date_forExcel);
    let sheet_name = "Revenue Report - "+ this.changeDateFormat_DMY(this.state.date_forExcel);
    //let sheet_name = "Report From- "+ this.changeDateFormat_DMY(this.props.page_from_date) +" To- "+this.changeDateFormat_DMY(this.props.page_to_date);

    return(<div style={{fontSize:'14px'}}>
      <IdleTimerContainer></IdleTimerContainer>
      <Helmet>
        <title>Roundr - Revenue</title>
        <link rel="icon" href="https://roundr.app/wp-content/uploads/2018/03/favicon.png" type="image/png"></link>
      </Helmet>
      <Header active = "revenue"/>

      <div className="content-wrapper" style={{marginLeft:'0px' }}>
        {this.state.agent_list_data ?
          <AgentList 
            parentCallback = {this.callbackFunction}
            agent_list={this.state.agent_list_data}></AgentList>
          : null
        }
        <section className="content">
          <div className = "row">
            <div className="col-xs-12 col-md-12 col-lg-12">
              <div className="box">
                <div className="box-header ml20">
                  <img src="images/Revenue_icon.png" width="24" height="19" alt="" />
                  <h3 className="box-title fontSize17 ml15 mt15 color0F1D30 fontWeight500 fontInter">Revenue&nbsp;</h3>
                  <span className="fontSize15 fontInter color8591A0 mt10" style={{ float: 'right' }}>Current Total
                    &nbsp;&nbsp;
                    <b>{ revenue_sum ? revenue_sum.toFixed(2) : revenue_sum }</b>
                  </span>
                </div>
                <hr style={{ borderTop: '1px solid #DAEEEC !important' }} />
                <div className="box-body table-responsive agentListingTable">
                  { this.props.agent_list && this.props.chart_revenue_cnt ?
                    this.renderRevenueChart() : null
                  }
                </div>
              </div>
            </div>
          </div>
          
          <div className="row">
            <div className="col-xs-12">
              <div className="box">
                <div className = "row">
                  <div className = "col-md-8 padTop10 padLeft45">
                    <img src= "images/revenue.png" width="18" height="18" alt=""/>
                    <span className = "fontSize22 padTop5 padLeft10"> Revenue</span>
                  </div>
                  {/* <div className = "col-md-4">
                    <div className="floatRight">
                      <form id="avg_cost_per_user_form" onSubmit={this.submitHandler}>
                        <div className='recal_full_input'><label className="recal_label" for='average_cost_per_agent'>Avg. cost per agent:</label>
                          <input className="recal_input" type='text' id="average_cost_per_agent" name='average_cost_per_agent' placeholder="Enter no." value={this.state.average_cost_per_agent || this.state.average_cost_per_agent === '' ? this.state.average_cost_per_agent : average_cost_per_agent} onChange={this.onInputchange} required pattern='[0-9]+([,\.][0-9]+)?' title="Only numbers are allowed"></input>
                        </div>
                        <button type="submit"  style={{backgroundColor:'#4AA49C', borderRadius:"5px"}} className="btn btn-info ml15">Re-calculate</button>
                      </form>
                    </div>
                  </div> */}
                  {/* <div className = "col-md-2 padTop20">
                    <span className = "padLeft35 fontWeight600 fontSize12 padTop5 fontInter cursorPointer color0F1D30" ><a href="/compare-agents">Compare Agents</a></span>
                    <span style={{color:'#DAEEEC',height:'31px',width:'1px',paddingLeft:'24px'}}>|</span>
                  </div> */}
                  <div className = "col-md-4 padTop15" style={{display:'inline-flex'}}>
                    <div style={{display:'inline-flex'}}>
                      <input type = "text"  name ="" value={this.state.month_year ? this.state.month_year : current_month } readOnly data-toggle="dropdown" placeholder = "Month : Febuary 2020" className = "dropdown-toggle form-control revenueMonthValue" style={{borderRadius:'4px', paddingTop: "19.5px", paddingBottom: "19.5px"}} />
                      <ul className="dropdown-menu revenue-month" id="revenue-month">
                        {this.peopleLis()}
                      </ul>
                      <button type="button" style={{backgroundColor:'#4AA49C', marginRight: "15px"}} className="btn btn-info"  onClick={this.getRevenue}  >Go</button>
                    </div>
                    
                    <div id="excel_report" style={{float:'Right', marginTop: '5px'}}>
                      <ExcelFile filename={file_name} element={<button className="btn btn-primary fontBold" style={{ background:'#4AA49C', borderRadius:'4px'}}> <Icon className="message_icon" icon={downloadFill} height='20' width='20'/> Export to Excel</button>}>
                          <ExcelSheet data={data_table_data} name={sheet_name}>
                            <ExcelColumn label="Agent Name" value={(col) => col.firstname + " " + col.lastname }/>
                            <ExcelColumn label="Tracking" value={(col) => col.tracking ? "ON" : "OFF"}/>
                            <ExcelColumn label="Sales" value="sales_count"/>
                            <ExcelColumn label="Rental" value="rental_count"/>
                            <ExcelColumn label="Sale-Revenue" value="sale_revenue"/>
                            <ExcelColumn label="Rental-Revenue" value="rental_revenue"/>
                            {/* <ExcelColumn label="Viewings" value="appt"/>
                            <ExcelColumn label="CTC" value="CTC"/>
                            <ExcelColumn label="Cost/Viewing" value="cost_per_appt"/>
                            <ExcelColumn label="Revenue" value="revenue"/>
                            <ExcelColumn label="Revenue/Viewing" value="revenue_per_appt"/>
                            <ExcelColumn label="Profitability" value={(col) => col.profitability+"%"}/> */}
                          </ExcelSheet>
                      </ExcelFile>
                    </div>
                  </div>
                </div> {/* End Row Here  */}

                {this.state.isLoading ? 
                  <center>
                    <img src="images/loader.svg" width="50" height="50" alt="" />
                  </center>
                  :
                  null
                }

                <div>
                  <DataTable
                    noHeader={true}
                    highlightOnHover={true}
                    pagination={true}
                    // progressPending="true"
                    // progressComponent={<Loader />}
                    subHeader
                    subHeaderComponent={this.getSubHeaderComponent()}
                    columns={columns}
                    data={this.filteredItems}
                    customStyles={customStyles}
                    ignoreRowClick={true}
                    defaultSortField={'agent_name'}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Agent Information Modal Start From Here */}
        <AgentInformationModal 
          agent_id = { this.state.agent_id}
          handlePropertyModal = { this.handlePropertyModal }
          />

        <PropertyDetailModal
          dateWisePropertyAppointment = { this.dateWisePropertyAppointment }
          property_detail={this.props.property_detail}
          appointment_cnt={this.props.appointment_cnt}
          general_cnt_property_modal= {this.props.general_cnt_property_modal}
          feedback={this.props.feedback}
          seller_message={this.props.seller_message}
          occupant_message={this.props.occupant_message}
          agent_data={this.props.agent_data}
          sellerLinkedMessage={sellerLinkedMessage}
          agent_id    = { this.state.agent_id }
          html_export={this.props.html_export}
          property_notes_html={this.props.property_notes_html}
          office_notes_html={this.props.office_notes_html}
        />
        <AddAppointment
          agent_data = {this.props.agent_data}
          agent_id = {this.state.agent_id}
        />
      </div>
      <Footer footer = {footer}></Footer>
    </div>)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    get_agent_list_revenue : (month, year, token, average_cost_per_agent) => dispatch(actions.get_agent_list_revenue(month, year, token, average_cost_per_agent)),
    get_revenue_chart_revenue : (fromDate, toDate, agent_list, token) => dispatch(actions.get_revenue_chart_revenue(fromDate, toDate, agent_list, token)),
    get_property_data_modal: (fromDate, toDate, prop_id, agent_id, token, date_sold, fromnetworking, property_notes_user_id, from_archiveProperty) => dispatch(actions.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold, fromnetworking, property_notes_user_id, from_archiveProperty)),
    reset_agent_modal: () => dispatch(actions.reset_agent_modal()),
    reset_property_modal: () => dispatch(actions.reset_property_modal()),
    update_add_appointment_modal_state: (show_agents,show_property,from_property_page,from_stock_pipeline) => dispatch(actions.update_add_appointment_modal_state(show_agents,show_property,from_property_page,from_stock_pipeline)),
    //set page for calendar
    set_page: (page, reset_date_range) => dispatch(actions.set_page(page, reset_date_range)),
  };
}

const mapStateToProps = state => {
  return{
    token: state.auth.token,
    
    //revenue data
    agent_list: state.revenue.agent_list_revenue,
    revenue_labels: state.revenue.revenue_labels,
    chart_revenue_cnt: state.revenue.chart_revenue_cnt,
    page_from_date: state.overview.page_from_date,
    page_to_date: state.overview.page_to_date,

    //property_modal
    property_detail: state.property_modal.property_detail,
    appointment_cnt: state.property_modal.appointment_cnt,
    general_cnt_property_modal: state.property_modal.general_cnt_property_modal,
    feedback: state.property_modal.feedback,
    seller_message: state.property_modal.seller_message,
    occupant_message: state.property_modal.occupant_message,
    html_export :state.property_modal.html_export,
    property_notes_html: state.property_modal.property_notes_html,
    office_notes_html: state.property_modal.office_notes_html,

    //agent_modal
    agent_data: state.agent_modal.agent_data
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Revenue);
