import React,{ Component } from "react";
import {connect} from 'react-redux';
import Loader from '../../Loader';

//icons
import { Icon } from '@iconify/react';
import baselineToday from '@iconify/icons-ic/baseline-today';
  
class AgentPerformance extends Component{
    constructor(props) {
        super(props);
        this.state = {
            appointment_filter: "day",
            no_of_appts: this.props.agent_performance ? this.props.agent_performance.appointments_performance['day'].appointments : null,
            appt_date: this.props.agent_performance ? this.props.agent_performance.appointments_performance['day'].date : null,
            appt_rating: this.props.agent_performance ? this.props.agent_performance.appointments_performance['day'].rating : null,
            rating_color: this.props.agent_performance ? [this.props.agent_performance.appointments_performance['day'].rating === "QUIET" ? "color20B038" : this.props.agent_performance.appointments_performance['day'].rating === "SEMI-BUSY" ? "colorF09A56" : "colorEA676A"]: null 
        }
        this.toggleAppointmentData = this.toggleAppointmentData.bind(this);
    }

    toggleAppointmentData(event){
        let no_of_appts = '';
        let rating = '';
        let appt_date = '';
        let color = '';
        if(event.target.value === "day"){
            no_of_appts = this.props.agent_performance.appointments_performance['day'].appointments
            appt_date = this.props.agent_performance.appointments_performance['day'].date
            rating = this.props.agent_performance.appointments_performance['day'].rating
        }else if(event.target.value === "week"){
            no_of_appts =this.props.agent_performance.appointments_performance['week'].appointments
            appt_date =this.props.agent_performance.appointments_performance['week'].date
            rating = this.props.agent_performance.appointments_performance['week'].rating
        }else{
            no_of_appts =this.props.agent_performance.appointments_performance['month'].appointments
            appt_date =this.props.agent_performance.appointments_performance['month'].date
            rating = this.props.agent_performance.appointments_performance['month'].rating
        }   
        if(rating === "QUIET"){
            color = "color20B038"
        }else if(rating === "SEMI-BUSY"){
            color = "colorF09A56"
        }else{
            color = "colorEA676A"
        }
        this.setState({
            appointment_filter: event.target.value,
            no_of_appts: no_of_appts,
            appt_date: appt_date,
            appt_rating: rating,
            rating_color: color
        });
    }

    render(){ 
        if(this.props.agent_performance){
            let no_of_viewings = Number(this.props.agent_performance.client_feedback.viewings)
            let no_of_feedback_shared = Number(this.props.agent_performance.client_feedback.feedback)
            var percentage_of_feedback_shared = parseFloat((no_of_feedback_shared/no_of_viewings)*100).toFixed(2)
            
            if(!this.state.no_of_appts && !this.state.appt_date && !this.state.appt_rating){
                this.setState({
                    no_of_appts : this.props.agent_performance.appointments_performance['day'].appointments,
                    appt_date : this.props.agent_performance.appointments_performance['day'].date,
                    appt_rating : this.props.agent_performance.appointments_performance['day'].rating,
                    rating_color: this.props.agent_performance.appointments_performance['day'].rating === "QUIET" ? "color20B038" : this.props.agent_performance.appointments_performance['day'].rating === "SEMI-BUSY" ? "colorF09A56" : "colorEA676A"
                })
            }
        }

        return(
            <div className = "overview-information" style={{minHeight:550}}>
                {!this.props.overview_loader && this.props.agent_performance?
                <>
                    {/* <div className="row mt20">
                        <div className="col-md-5" style={{position:"relative"}}>
                            <p style={{position:"absolute", fontSize:"20px", fontWeight:600}}>Overall Performance</p>
                        </div>
                        <div className="col-md-7" style={{position:"relative"}}>
                            <div className="progress_overview mr10" style={{display:"inline-block", width: "80%"}} >
                                {this.props.agent_performance.overall_performance ==="NOT GREAT" ?
                                    <div className="progress-bar progress_low padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:"33%", height:"30px", fontSize: "14px", fontWeight:"600"}}>Not Great</div>
                                :
                                    this.props.agent_performance.overall_performance === "OK" ?
                                        <div className="progress-bar progress_moderate padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:"66%", height:"30px", fontSize: "14px", fontWeight:"600"}}>OK</div>
                                    :
                                    <div className="progress-bar progress_high padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:"100%", height:"30px", fontSize: "14px", fontWeight:"600"}}>Great</div>
                                }
                            </div> 
                        </div>
                    </div> */}

                    <br/>

                    {/* Appointment panel*/}
                    <div className="panel-group">
                        <div className="panel panel-default" style={{borderBottom: "none"}}>
                            <div className="panel-heading with_appt_filter" style={{backgroundColor:"#CCE0DE"}}>
                                <p className="panel-title">                                    
                                    <a className="fontInter color12171D" data-toggle="collapse" href="#collapse1" style={{textTransform: 'none', display:"inline"}}>{this.props.agent_performance.agent_data.firstname}'s {this.state.appointment_filter}</a>

                                    <input className="btn_appt_filter" type="radio" id="forDay" name="appointment_filter" onClick={this.toggleAppointmentData} value="day" defaultChecked={true}/> <label htmlFor="forDay" className="btn_appt_filter" style={{borderRadius: "6px 0px 0px 6px"}}>Day</label>
                                    <input className="btn_appt_filter" type="radio" id="forWeek" name="appointment_filter" onClick={this.toggleAppointmentData} value="week"/> <label htmlFor="forWeek" className="btn_appt_filter">Week</label>
                                    <input className="btn_appt_filter" type="radio" id="forMonth" name="appointment_filter" onClick={this.toggleAppointmentData} value="month"/> <label htmlFor="forMonth" className="btn_appt_filter" style={{borderRadius: "0px 6px 6px 0px"}}>Month</label>
                                </p>
                            </div>
                            <div id="collapse1" className="panel-collapse collapse">
                                <div className="panel-body" style={{borderTop: "none"}}>
                                    <div className="row">
                                        <div className = "col-md-6">
                                            <Icon icon={baselineToday} className=" mr10" style={{color: '#27AFAF', fontSize: '24px', verticalAlign:'bottom'}} />
                                            <p className="color12171D fontSize18 fontInter fontWeight600" style={{display:"inline"}}>{this.state.no_of_appts} {Number(this.state.no_of_appts) === Number(1) ? "Appointment" : "Appointments"} for this {this.state.appointment_filter}</p>
                                        </div>
                                        <div className = "col-md-6">
                                            <p className="color12171D fontSize18 fontInter fontWeight600 floatRight"> {this.state.appt_date} </p>
                                        </div>
                                    </div>
                                    
                                    {/* <div className="row">
                                        <div className = "col-md-12">
                                            <p className="color12171D fontSize18 fontInter fontWeight600 mt10">{this.props.agent_performance.agent_data.firstname} is having a <span className={this.state.rating_color}>{this.state.appt_rating}</span>
                                            </p>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {/* Properties panel*/}
                    <div className="panel-group">
                        <div className="panel panel-default" style={{borderBottom: "none"}}>
                            <div className="panel-heading" style={{backgroundColor:"#CCE0DE"}}>
                                <p className="panel-title">                                    
                                    <a className="fontInter color12171D" data-toggle="collapse" href="#properties" style={{textTransform: 'none'}}>Properties</a>
                                </p>
                            </div>
                            <div id="properties" className="panel-collapse collapse">
                                <div className="panel-body" style={{borderTop: "none"}}>
                                    <div className="row">
                                        {/* <div className = "col-md-12">
                                            <div className="row">
                                                <div className="col-md-5" style={{position:"relative"}}>
                                                    <p style={{position:"absolute", fontSize:"20px", fontWeight:600}}>Sales Performance</p>
                                                </div>
                                                <div className="col-md-7" style={{position:"relative"}}>
                                                    <div className="progress_overview mr10" style={{display:"inline-block", width: "80%"}} >
                                                        {Number(this.props.agent_performance.properties_performance.sales_performance.replace("%","")) <= 33 ?
                                                            [Number(this.props.agent_performance.properties_performance.sales_performance.replace("%","")) <= 20 ?
                                                                [Number(this.props.agent_performance.properties_performance.sales_performance.replace("%","")) <= 8 ?
                                                                    <div className="progress-bar progress_low padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:this.props.agent_performance.properties_performance.sales_performance, height:"30px", fontSize: "10px", fontWeight:"600", color:"#12171D"}}><p>Not Great</p></div>
                                                                :
                                                                    <div className="progress-bar progress_low padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:this.props.agent_performance.properties_performance.sales_performance, height:"30px", fontSize: "10px", fontWeight:"600"}}><p style={{lineHeight:0.9}}>Not Great</p></div>
                                                                ]
                                                            :   
                                                                <div className="progress-bar progress_low padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:this.props.agent_performance.properties_performance.sales_performance, height:"30px", fontSize: "14px", fontWeight:"600"}}><p>Not Great</p></div>
                                                            ]
                                                        :
                                                            (Number(this.props.agent_performance.properties_performance.sales_performance.replace("%","")) >= 33) && (Number(this.props.agent_performance.properties_performance.sales_performance.replace("%","")) <= 66 )?
                                                                <div className="progress-bar progress_moderate padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:this.props.agent_performance.properties_performance.sales_performance, height:"30px", fontSize: "14px", fontWeight:"600"}}>OK</div>
                                                            :
                                                                (Number(this.props.agent_performance.properties_performance.sales_performance.replace("%","")) > 100) ?
                                                                    <div className="progress-bar progress_high padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:"100%", height:"30px", fontSize: "14px", fontWeight:"600"}}>Great</div>
                                                                :
                                                                    <div className="progress-bar progress_high padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:this.props.agent_performance.properties_performance.sales_performance, height:"30px", fontSize: "14px", fontWeight:"600"}}>Great</div>
                                                        }
                                                    </div> 
                                                    <span style={{fontSize: "20px", fontWeight: 700, position:"relative", bottom:"8px"}}>{this.props.agent_performance.properties_performance.sales_performance}</span>
                                                </div>
                                            </div>

                                            <div className="row mb30">
                                                <div className="col-md-5" style={{position:"relative"}}>
                                                    <p className="mt20" style={{position:"absolute", fontSize:"20px", fontWeight:600}}>Rental Performance</p>
                                                </div>
                                                <div className="col-md-7">
                                                    <div className="progress_overview mt20 mr10" style={{display:"inline-block", width: "80%"}} >
                                                        {Number(this.props.agent_performance.properties_performance.rental_performance.replace("%","")) <= 33 ?
                                                            [Number(this.props.agent_performance.properties_performance.rental_performance.replace("%","")) <= 20 ?
                                                                [Number(this.props.agent_performance.properties_performance.rental_performance.replace("%","")) <= 8 ?
                                                                    <div className="progress-bar progress_low padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:this.props.agent_performance.properties_performance.rental_performance, height:"30px", fontSize: "10px", fontWeight:"600", color:"#12171D"}}><p>Not Great</p></div>
                                                                :
                                                                    <div className="progress-bar progress_low padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:this.props.agent_performance.properties_performance.rental_performance, height:"30px", fontSize: "10px", fontWeight:"600"}}><p style={{lineHeight:0.9}}>Not Great</p></div>
                                                                ]
                                                            :   
                                                                <div className="progress-bar progress_low padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:this.props.agent_performance.properties_performance.rental_performance, height:"30px", fontSize: "14px", fontWeight:"600"}}><p>Not Great</p></div>
                                                            ]
                                                        :
                                                            (Number(this.props.agent_performance.properties_performance.rental_performance.replace("%","")) >= 33) && (Number(this.props.agent_performance.properties_performance.rental_performance.replace("%","")) <= 66 )?
                                                                <div className="progress-bar progress_moderate padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:this.props.agent_performance.properties_performance.rental_performance, height:"30px", fontSize: "14px", fontWeight:"600"}}>OK</div>
                                                            :
                                                                (Number(this.props.agent_performance.properties_performance.rental_performance.replace("%","")) > 100) ?
                                                                    <div className="progress-bar progress_high padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:"100%", height:"30px", fontSize: "14px", fontWeight:"600"}}>Great</div>
                                                                :
                                                                    <div className="progress-bar progress_high padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:this.props.agent_performance.properties_performance.rental_performance, height:"30px", fontSize: "14px", fontWeight:"600"}}>Great</div>
                                                        }
                                                    </div> 
                                                    <span style={{fontSize: "20px", fontWeight: 700, position:"relative", bottom:"8px"}}>{this.props.agent_performance.properties_performance.rental_performance}</span>
                                                </div>
                                            </div>
                                        </div> */}
                                        
                                        <div className="col-md-12">
                                            <div className="row mb30">
                                                <div className="col-md-3 col-md-offset-1">
                                                    <span className="floatLeft" style={{fontSize:"20px", fontWeight:600}}>Total</span>
                                                    <span className="color27AFAF floatRight" style={{fontSize:"20px", fontWeight:600}}>{this.props.agent_performance.properties_performance.no_of_properties_for_sale}</span>
                                                </div>
                                                <div className="col-md-3 col-md-offset-1">
                                                    <span className="floatLeft" style={{fontSize:"20px", fontWeight:600}}>For Sale</span>
                                                    <span className="color27AFAF floatRight" style={{fontSize:"20px", fontWeight:600}}>{Number(this.props.agent_performance.properties_performance.no_of_properties_for_sale) - Number(this.props.agent_performance.properties_performance.no_of_properties_sold)}</span>
                                                </div>
                                                <div className="col-md-offset-1 col-md-3">
                                                    <span className="floatLeft" style={{fontSize:"20px", fontWeight:600}}>Sold</span>
                                                    <span className="color27AFAF floatRight" style={{fontSize:"20px", fontWeight:600}}>{this.props.agent_performance.properties_performance.no_of_properties_sold}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-3 col-md-offset-1">
                                                    <span className="floatLeft" style={{fontSize:"20px", fontWeight:600}}>Total</span>
                                                    <span className="color27AFAF floatRight" style={{fontSize:"20px", fontWeight:600}}>{this.props.agent_performance.properties_performance.no_of_properties_for_rent}</span>
                                                </div>
                                                <div className="col-md-3 col-md-offset-1">
                                                    <span className="floatLeft" style={{fontSize:"20px", fontWeight:600}}>Rental</span>
                                                    <span className="color27AFAF floatRight" style={{fontSize:"20px", fontWeight:600}}>{Number(this.props.agent_performance.properties_performance.no_of_properties_for_rent) - Number(this.props.agent_performance.properties_performance.no_of_properties_rented)}</span>
                                                </div>
                                                <div className="col-md-offset-1 col-md-3">
                                                    <span className="floatLeft" style={{fontSize:"20px", fontWeight:600}}>Rented</span>
                                                    <span className="color27AFAF floatRight" style={{fontSize:"20px", fontWeight:600}}>{this.props.agent_performance.properties_performance.no_of_properties_rented}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Revenue panel*/}
                    <div className="panel-group">
                        <div className="panel panel-default" style={{borderBottom: "none"}}>
                            <div className="panel-heading" style={{backgroundColor:"#CCE0DE"}}>
                                <p className="panel-title">                                    
                                    <a className="fontInter color12171D" data-toggle="collapse" href="#revenues" style={{textTransform: 'none'}}>Revenue</a>
                                </p>
                            </div>
                            <div id="revenues" className="panel-collapse collapse">
                                <div className="panel-body" style={{borderTop: "none"}}>
                                    <div className="row">
                                        {/* <div className = "col-md-12">
                                            <div className="row">
                                                <div className="col-md-5" style={{position:"relative"}}>
                                                    <p style={{position:"absolute", fontSize:"20px", fontWeight:600}}>Average sales comm %</p>
                                                </div>
                                                <div className="col-md-7" style={{position:"relative"}}>
                                                    <div className="progress_overview mr10" style={{display:"inline-block", width: "80%"}} >
                                                        {Number(this.props.agent_performance.revenue.average_sales_commission_percentage.replace("%","")) <= 33 ?
                                                            [Number(this.props.agent_performance.revenue.average_sales_commission_percentage.replace("%","")) <= 20 ?
                                                                [Number(this.props.agent_performance.revenue.average_sales_commission_percentage.replace("%","")) <= 8 ?
                                                                    <div className="progress-bar progress_low padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:this.props.agent_performance.revenue.average_sales_commission_percentage, height:"30px", fontSize: "10px", fontWeight:"600", color:"#12171D"}}><p>Not Great</p></div>
                                                                :
                                                                    <div className="progress-bar progress_low padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:this.props.agent_performance.revenue.average_sales_commission_percentage, height:"30px", fontSize: "10px", fontWeight:"600"}}><p style={{lineHeight:0.9}}>Not Great</p></div>
                                                                ]
                                                            :   
                                                                <div className="progress-bar progress_low padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:this.props.agent_performance.revenue.average_sales_commission_percentage, height:"30px", fontSize: "14px", fontWeight:"600"}}><p>Not Great</p></div>
                                                            ]
                                                        :
                                                            (Number(this.props.agent_performance.revenue.average_sales_commission_percentage.replace("%","")) >= 33) && (Number(this.props.agent_performance.revenue.average_sales_commission_percentage.replace("%","")) <= 66 )?
                                                                <div className="progress-bar progress_moderate padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:this.props.agent_performance.revenue.average_sales_commission_percentage, height:"30px", fontSize: "14px", fontWeight:"600"}}>OK</div>
                                                            :
                                                                (Number(this.props.agent_performance.revenue.average_sales_commission_percentage.replace("%","")) > 100) ?
                                                                    <div className="progress-bar progress_high padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:"100%", height:"30px", fontSize: "14px", fontWeight:"600"}}>Great</div>
                                                                :
                                                                    <div className="progress-bar progress_high padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:this.props.agent_performance.revenue.average_sales_commission_percentage, height:"30px", fontSize: "14px", fontWeight:"600"}}>Great</div>
                                                        }
                                                    </div> 
                                                    <span style={{fontSize: "20px", fontWeight: 700, position:"relative", bottom:"8px"}}>{this.props.agent_performance.revenue.average_sales_commission_percentage}</span>
                                                </div>
                                            </div>

                                            <div className="row mb30">
                                                <div className="col-md-5" style={{position:"relative"}}>
                                                    <p className="mt20" style={{position:"absolute", fontSize:"20px", fontWeight:600}}>Average rental comm %</p>
                                                </div>
                                                <div className="col-md-7">
                                                    <div className="progress_overview mt20 mr10" style={{display:"inline-block", width: "80%"}} >
                                                        {Number(this.props.agent_performance.revenue.average_rental_commission_percentage.replace("%","")) <= 33 ?
                                                            [Number(this.props.agent_performance.revenue.average_rental_commission_percentage.replace("%","")) <= 20 ?
                                                                [Number(this.props.agent_performance.revenue.average_rental_commission_percentage.replace("%","")) <= 8 ?
                                                                    <div className="progress-bar progress_low padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:this.props.agent_performance.revenue.average_rental_commission_percentage, height:"30px", fontSize: "10px", fontWeight:"600", color:"#12171D"}}><p>Not Great</p></div>
                                                                :
                                                                    <div className="progress-bar progress_low padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:this.props.agent_performance.revenue.average_rental_commission_percentage, height:"30px", fontSize: "10px", fontWeight:"600"}}><p style={{lineHeight:0.9}}>Not Great</p></div>
                                                                ]
                                                            :   
                                                                <div className="progress-bar progress_low padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:this.props.agent_performance.revenue.average_rental_commission_percentage, height:"30px", fontSize: "14px", fontWeight:"600"}}><p>Not Great</p></div>
                                                            ]
                                                        :
                                                            (Number(this.props.agent_performance.revenue.average_rental_commission_percentage.replace("%","")) >= 33) && (Number(this.props.agent_performance.revenue.average_rental_commission_percentage.replace("%","")) <= 66 )?
                                                                <div className="progress-bar progress_moderate padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:this.props.agent_performance.revenue.average_rental_commission_percentage, height:"30px", fontSize: "14px", fontWeight:"600"}}>OK</div>
                                                            :   
                                                                (Number(this.props.agent_performance.revenue.average_rental_commission_percentage.replace("%","")) > 100)?
                                                                    <div className="progress-bar progress_high padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:"100%", height:"30px", fontSize: "14px", fontWeight:"600"}}>Great</div>
                                                                :
                                                                    <div className="progress-bar progress_high padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:this.props.agent_performance.revenue.average_rental_commission_percentage, height:"30px", fontSize: "14px", fontWeight:"600"}}>Great</div>
                                                        }
                                                    </div> 
                                                    <span style={{fontSize: "20px", fontWeight: 700, position:"relative", bottom:"8px"}}>{this.props.agent_performance.revenue.average_rental_commission_percentage}</span>
                                                </div>
                                            </div>
                                        </div> */}
                                        
                                        <div className="col-md-12">
                                            <div className="row mb30">
                                                <div className="col-md-3 col-md-offset-1">
                                                    <span className="floatLeft" style={{fontSize:"20px", fontWeight:600}}>Sold</span>
                                                    <span className="color27AFAF floatRight" style={{fontSize:"20px", fontWeight:600}}>{this.props.agent_performance.agent_data.currency_symbol} {this.props.agent_performance.revenue.sold}</span>
                                                </div>
                                                <div className="col-md-offset-1 col-md-7">
                                                    <span className="floatLeft" style={{fontSize:"20px", fontWeight:600}}>Avg. Commission from Sold</span>
                                                    <span className="color27AFAF" style={{fontSize:"20px", fontWeight:600, marginLeft:10}}>{this.props.agent_performance.agent_data.currency_symbol} {this.props.agent_performance.revenue.average_commission_sold}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-3 col-md-offset-1">
                                                    <span className="floatLeft" style={{fontSize:"20px", fontWeight:600}}>Rental</span>
                                                    <span className="color27AFAF floatRight" style={{fontSize:"20px", fontWeight:600}}>{this.props.agent_performance.agent_data.currency_symbol} {this.props.agent_performance.revenue.rented}</span>
                                                </div>
                                                <div className="col-md-offset-1 col-md-7">
                                                    <span className="floatLeft" style={{fontSize:"20px", fontWeight:600}}>Avg. Commission from Rented</span>
                                                    <span className="color27AFAF" style={{fontSize:"20px", fontWeight:600, marginLeft:10}}>{this.props.agent_performance.agent_data.currency_symbol} {this.props.agent_performance.revenue.average_commission_rented}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Mandate procurement panel*/}
                    <div className="panel-group">
                        <div className="panel panel-default" style={{borderBottom: "none"}}>
                            <div className="panel-heading" style={{backgroundColor:"#CCE0DE"}}>
                                <p className="panel-title">                                    
                                    <a className="fontInter color12171D" data-toggle="collapse" href="#mandate_procurement" style={{textTransform: 'none'}}>Stock Pipeline</a>
                                </p>
                            </div>
                            <div id="mandate_procurement" className="panel-collapse collapse">
                                <div className="panel-body" style={{borderTop: "none"}}>
                                    <div className="row">
                                        {/* <div className = "col-md-12 mb30">
                                            <div className="row">
                                                <div className="col-md-5" style={{position:"relative"}}>
                                                    <p style={{position:"absolute", fontSize:"20px", fontWeight:600}}>Stock procurement</p>
                                                </div>
                                                <div className="col-md-7" style={{position:"relative"}}>
                                                    <div className="progress_overview mr10" style={{display:"inline-block", width: "80%"}} >
                                                        {Number(this.props.agent_performance.stockpipeline_procurment.stockpipeline_procurment_rating.replace("%","")) <= 33 ?
                                                            [Number(this.props.agent_performance.stockpipeline_procurment.stockpipeline_procurment_rating.replace("%","")) <= 20 ?
                                                                [Number(this.props.agent_performance.stockpipeline_procurment.stockpipeline_procurment_rating.replace("%","")) <= 8 ?
                                                                    <div className="progress-bar progress_low padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:this.props.agent_performance.stockpipeline_procurment.stockpipeline_procurment_rating, height:"30px", fontSize: "10px", fontWeight:"600", color:"#12171D"}}><p>Not Great</p></div>
                                                                :
                                                                    <div className="progress-bar progress_low padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:this.props.agent_performance.stockpipeline_procurment.stockpipeline_procurment_rating, height:"30px", fontSize: "10px", fontWeight:"600"}}><p style={{lineHeight:0.9}}>Not Great</p></div>
                                                                ]
                                                            :   
                                                                <div className="progress-bar progress_low padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:this.props.agent_performance.stockpipeline_procurment.stockpipeline_procurment_rating, height:"30px", fontSize: "14px", fontWeight:"600"}}><p>Not Great</p></div>
                                                            ]
                                                        :
                                                            (Number(this.props.agent_performance.stockpipeline_procurment.stockpipeline_procurment_rating.replace("%","")) >= 33) && (Number(this.props.agent_performance.stockpipeline_procurment.stockpipeline_procurment_rating.replace("%","")) <= 66 )?
                                                                <div className="progress-bar progress_moderate padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:this.props.agent_performance.stockpipeline_procurment.stockpipeline_procurment_rating, height:"30px", fontSize: "14px", fontWeight:"600"}}>OK</div>
                                                            :
                                                                (Number(this.props.agent_performance.stockpipeline_procurment.stockpipeline_procurment_rating.replace("%","")) > 100) ?
                                                                    <div className="progress-bar progress_high padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:"100%", height:"30px", fontSize: "14px", fontWeight:"600"}}>Great</div>
                                                                :
                                                                    <div className="progress-bar progress_high padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:this.props.agent_performance.stockpipeline_procurment.stockpipeline_procurment_rating, height:"30px", fontSize: "14px", fontWeight:"600"}}>Great</div>
                                                        }
                                                    </div> 
                                                    <span style={{fontSize: "20px", fontWeight: 700, position:"relative", bottom:"8px"}}>{this.props.agent_performance.stockpipeline_procurment.stockpipeline_procurment_rating}</span>
                                                </div>
                                            </div>
                                        </div> */}
                                        
                                        <div className="col-md-12">
                                            <div className="row mb30">
                                                <div className="col-md-7">
                                                    <span className="floatLeft" style={{fontSize:"20px", fontWeight:600}}>Currently chasing</span>
                                                    <span className="color27AFAF floatRight" style={{fontSize:"20px", fontWeight:600}}>{this.props.agent_performance.stockpipeline_procurment.no_of_stockpipeline_current}</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-7">
                                                    <span className="floatLeft" style={{fontSize:"20px", fontWeight:600}}>Converted in last 3 months to listings</span>
                                                    <span className="color27AFAF floatRight" style={{fontSize:"20px", fontWeight:600}}>{this.props.agent_performance.stockpipeline_procurment.no_of_stockpipeline_promoted}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Client feedback panel*/}
                    <div className="panel-group">
                        <div className="panel panel-default" style={{borderBottom: "none"}}>
                            <div className="panel-heading" style={{backgroundColor:"#CCE0DE"}}>
                                <p className="panel-title">                                    
                                    <a className="fontInter color12171D" data-toggle="collapse" href="#client_feedback" style={{textTransform: 'none'}}>Client feedback</a>
                                </p>
                            </div>
                            <div id="client_feedback" className="panel-collapse collapse">
                                <div className="panel-body" style={{borderTop: "none"}}>
                                    <div className="row">
                                        <div className = "col-md-12 mb30">
                                            <div className="row">
                                                <div className="col-md-5" style={{position:"relative"}}>
                                                    <p style={{position:"absolute", fontSize:"20px", fontWeight:600}}>Feedback shared</p>
                                                </div>
                                                <div className="col-md-7" style={{position:"relative"}}>
                                                    <div className="progress_overview mr10" style={{display:"inline-block", width: "80%"}} >
                                                        {Number(this.props.agent_performance.client_feedback.feedback_send_rate.replace("%","")) <= 33 ?
                                                            [Number(this.props.agent_performance.client_feedback.feedback_send_rate.replace("%","")) <= 20 ?
                                                                [Number(this.props.agent_performance.client_feedback.feedback_send_rate.replace("%","")) <= 8 ?
                                                                    <div className="progress-bar progress_low padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:this.props.agent_performance.client_feedback.feedback_send_rate, height:"30px", fontSize: "10px", fontWeight:"600", color:"#12171D"}}>{/*<p>Rarely</p> */}</div>
                                                                :
                                                                    <div className="progress-bar progress_low padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:this.props.agent_performance.client_feedback.feedback_send_rate, height:"30px", fontSize: "10px", fontWeight:"600"}}>{/* <p style={{lineHeight:0.9}}>Rarely</p> */}</div>
                                                                ]
                                                            :   
                                                                <div className="progress-bar progress_low padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:this.props.agent_performance.client_feedback.feedback_send_rate, height:"30px", fontSize: "14px", fontWeight:"600"}}>{/* <p>Rarely</p>*/}</div>
                                                            ]
                                                        :
                                                            (Number(this.props.agent_performance.client_feedback.feedback_send_rate.replace("%","")) >= 33) && (Number(this.props.agent_performance.client_feedback.feedback_send_rate.replace("%","")) <= 66 )?
                                                                <div className="progress-bar progress_moderate padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:this.props.agent_performance.client_feedback.feedback_send_rate, height:"30px", fontSize: "14px", fontWeight:"600"}}>{/* Sometimes */}</div>
                                                            :
                                                                (Number(this.props.agent_performance.client_feedback.feedback_send_rate.replace("%","")) >= 33) && (Number(this.props.agent_performance.client_feedback.feedback_send_rate.replace("%","")) <= 66 )?
                                                                    <div className="progress-bar progress_high padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:"100%", height:"30px", fontSize: "14px", fontWeight:"600"}}>Often</div>
                                                                :
                                                                    <div className="progress-bar progress_high padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:this.props.agent_performance.client_feedback.feedback_send_rate, height:"30px", fontSize: "14px", fontWeight:"600"}}>{/* Often */}</div>
                                                        }
                                                    </div> 
                                                    <span style={{fontSize: "20px", fontWeight: 700, position:"relative", bottom:"8px"}}>{this.props.agent_performance.client_feedback.feedback_send_rate}</span>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="col-md-12 mb30">
                                            <div className="row mb30">
                                                <div className="col-md-5" style={{position:"relative"}}>
                                                    <p style={{position:"absolute", fontSize:"20px", fontWeight:600}}>No. of Viewings</p>
                                                </div>
                                                <div className="col-md-7" style={{position:"relative"}}>
                                                    
                                                    <div className="progress_overview_client mr10" style={{display:"inline-block", width: "80%"}} >
                                                        <div className="progress-bar progress_green padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:"100%", height:"15px", fontSize: "14px", fontWeight:"600"}}></div>
                                                        <span style={{fontSize: "20px", fontWeight: 700, position:"relative", bottom:"2px", display:"grid"}}>{this.props.agent_performance.client_feedback.viewings}</span>
                                                    </div> 
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-5" style={{position:"relative"}}>
                                                    <p style={{position:"absolute", fontSize:"20px", fontWeight:600}}>No. of Feedback shared</p>
                                                </div>
                                                <div className="col-md-7" style={{position:"relative"}}>
                                                    <div className="progress_overview_client mr10" style={{display:"inline-block", width: "80%"}} >
                                                        <div className="progress-bar progress_orange padTop5" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:percentage_of_feedback_shared+"%", height:"15px", fontSize: "14px", fontWeight:"600"}}></div>
                                                        <span style={{fontSize: "20px", fontWeight: 700, position:"relative", bottom:"2px", display:"grid"}}>{this.props.agent_performance.client_feedback.feedback}</span>
                                                    </div> 
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        {/* {this.props.agent_performance.client_feedback.feedback_send_text_rating ?
                                            <div className="col-md-12">
                                                <p className="fontSize16" style={{background:"#EDF6F6", padding:"20px"}}>{this.props.agent_performance.client_feedback.feedback_send_text_rating}</p>
                                            </div>
                                        : null} */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                :
                <Loader/>
                }
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}
  
const mapStateToProps = state => {
    return{
        authtoken: state.add_property_modal.authtoken,
        agent_performance: state.agent_modal.agent_performance,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentPerformance)