
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.css';

import React, { Component } from "react";
import Header from '../components/menu/Header';
import {Checkbox} from 'primereact/checkbox';
import { Icon} from '@iconify/react';
import infoCircleOutlined from '@iconify/icons-ant-design/info-circle-outlined';
import ReactTooltip from "react-tooltip";
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';



const header ={
  fontFamily: 'Inter',
  fontWeight: 600,
  textAlign:'center',
  color:"#0F1D30"
};
const subheader={ 
  fontFamily: 'Inter',
  fontWeight: 500,
  textAlign:'center',
  color:'#466380',
  fontSize:16
};

const payment_info ={
  backgroundColor:"#f8f8fa",
  fontFamily: 'Inter',
  fontWeight: 500,
  fontSize: 15,
  color:"#466380",
  borderTop: '1px solid #C2D0DE',
  borderBottom: '1px solid #C2D0DE',
  padding: "8px",
};

const cost ={
  color:'black'
};

class Payment extends Component {  
  constructor(props) {
    super(props);
    this.state = {
      checked:false
    }
  }
  render() {
    return (
      
       <div className="content-wrapper" style={{ marginLeft: '0px' }}>
        {<Header active="dashboard"/>}
        <section className="content" style={{ margin: '30px', marginTop: '60px' }}>

          <div className="row" >
            <div className="col-lg-8 col-lg-offset-2 mb30">
              <h2 style={header}>Continue Your Roundr Membership</h2>
              <p style={subheader}>Subscribe your office by Making Payment.</p>
            </div>
            <div className="col-lg-8 col-lg-offset-2 mb15">
              <div className="row" style={payment_info}>
                <div className="col-lg-4">
                  <p>Number of Agents</p>
                  <p style={cost}>16</p>
                </div>

                <div className="col-lg-4">
                  <p>Monthly Cost Per Agents</p>
                  <p style={cost}>R 98.00</p>
                </div>
                
                <div className="col-lg-2 col-lg-offset-2 col-sm-12" style={{backgroundColor:"#EDEFF2"}}>
                  <p><strong>Due Monthly</strong></p>
                  <p style={{color:"#0F1D30"}}><strong>R 1178.00</strong></p>
                </div>
              </div> 
            </div>

            <div className="col-lg-8 col-lg-offset-2 col-sm-12" style={{marginBottom:50}}>
              <div className="row">
                <div className="col-lg-8 col-sm-12">
                  <a href='https://realtorsroundr.com/contact-us/' target="_blank" rel="noopener noreferrer" style={{color:"#27AFAF", fontWeight:500,fontFamily:'Inter',fontSize:15}}>To Change the Number of Agents Contact Us</a>
                </div>
                <div className="col-lg-4 col-sm-12" style={{textAlign:'right', paddingRight:42}}>
                  <Checkbox onChange={e => this.setState({checked: e.checked})} checked={this.state.checked} inputId="renewal" value="yes"></Checkbox>
                  <label style={{color:"#466380", fontWeight:600,fontFamily:'Inter',fontSize:15}} htmlFor="renewal" className="p-checkbox-label">Auto Renewal</label><ReactTooltip/>
                  <Icon data-tip="hello world" data-background-color="#0F1D30" data-place="bottom" icon={infoCircleOutlined} width='1.5em' height='1.5em' style={{verticalAlign: 'middle', marginLeft:5}}/>
                </div>
              </div>
            </div>

            <div className="col-lg-8 col-lg-offset-2 col-sm-12">
              <div className="row">
                  <div className="col-lg-6 col-sm-12 mb15">
                    <InputText style={{borderBottomRightRadius:0,borderTopRightRadius:0}} type="text" placeholder="Enter Coupon Code" />
                    <Button style={{backgroundColor:"#0A9292",borderTopLeftRadius:0,borderBottomLeftRadius:0}} type="button" label="Apply" />
                  </div>
                  <div className="col-lg-6 col-sm-12 ">
                    <Button style={{float:'right',backgroundColor:"#117777"}} type="button" label="Proceed to Payment" icon="pi pi-chevron-right" iconPos="right"/>
                  </div>
              </div>
            </div>
          </div>
        </section>
      </div>
  
    );
  }
}
 
export default Payment;