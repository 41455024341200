import React from 'react';
import {Line} from 'react-chartjs-2';
import Constants from '../../constants/Constants';

class PropertyChart extends React.Component {

  find_duplicate_in_array = (arra1)=> {
    var object = {};
    var result = [];

    arra1.forEach(function (item) {
      if(!object[item])
          object[item] = 0;
        object[item] += 1;
    })

    for (var prop in object) {
       if(object[prop] >= 2) {
           result.push(prop);
       }
    }
    return result;
  }


  render() {

    var dataSets =  [];
    var dataSetsLength =  this.props.total_agent;
    var globalAgentList =  this.props.globalAgentList;
    var globalAgentListName =  this.props.globalAgentListName;
    var globalAgentListImage = this.props.globalAgentListImage;
    var dataSetsChart;
    var state;
    var agentId;
    var chartheight = 0
    if(this.props.settings && this.props.settings.length > 0){
      if(Number(this.props.settings[0]) === 2 && (this.props.settings.length % 2 !== 0)){
        chartheight = 75
      }else{
        chartheight = 150
      }
    }
    if(this.props.overview  === "0" || this.props.overview === 0){
      var globalAgents =  this.props.globalAgentList
      var duplicateId = this.find_duplicate_in_array(globalAgents);

      let forDeletion = duplicateId;
      globalAgents = globalAgents.filter(item => !forDeletion.includes(item))
      globalAgents = globalAgents.concat(duplicateId)
      let agent_image ='';

      for(let i = 0; i < globalAgents.length; i++){
        agentId = globalAgents[i]; 
        dataSetsChart = (this.props.property_agen_count[0][agentId])

        if(globalAgentListName[agentId].image){
          agent_image = Constants.IMAGE_URL + globalAgentListName[agentId].image;
        }else{
          agent_image = "user-images/userDefault.png"
        }
        
        dataSets.push(
          {
            label:'<img style="border-radius: 50%; margin-right: 5px;" width="20" height="20" src="' + agent_image +'" alt=""/>' + globalAgentListName[agentId].name+' Props',
            data:dataSetsChart,
            borderColor:globalAgentListName[agentId].color,
            fill:false,
            lineTension: 0,
            //backgroundColor: 'rgba(75,192,192,1)',
            backgroundColor: globalAgentListName[agentId].color,
            borderWidth: 2,
          },
        )
      }
      state = {
        labels: this.props.property_labels,
        datasets: dataSets
      }
    }else{
      dataSets =  [];
      dataSetsLength =  this.props.total_agent;
      globalAgentList =  this.props.globalAgentList;
      globalAgentListName =  this.props.globalAgentListName;
      
      for(let i = 0; i < dataSetsLength; i++){
        let agent_image = '';
        if(globalAgentListImage[i]){
          agent_image = Constants.IMAGE_URL + globalAgentListImage[i];
        }else{
          agent_image = "user-images/userDefault.png"
        }

        agentId = globalAgentList[i]; 
        dataSetsChart = (this.props.property_agen_count[0][agentId])
        
        dataSets.push(
          {
            label:'<img style="border-radius: 50%; margin-right: 5px;" width="20" height="20" src="' + agent_image +'" alt=""/>' + globalAgentListName[i].name+' Props',
            data:dataSetsChart,
            borderColor:globalAgentListName[i].color,
            fill:false,
            lineTension: 0,
            //backgroundColor: 'rgba(75,192,192,1)',
            backgroundColor: globalAgentListName[i].color,
            borderWidth: 2,
          },
        )
      }
      state = {
        labels: this.props.property_labels,
        datasets: dataSets
      }
    }  

    if(this.props.overview === "0" || this.props.overview === 0 ){
      return (
        <div>
          <Line
            height={85}
            data={state}
            options={{
              legend:{
                display:false,
                position:'right'
              },
              tooltips: {
                // Disable the on-canvas tooltip
                enabled: false,

                custom: function(tooltipModel) {
                  // Tooltip Element
                  var tooltipEl = document.getElementById('chartjs-tooltip');

                  // Create element on first render
                  if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjs-tooltip';
                    tooltipEl.innerHTML = '<table></table>';
                    document.body.appendChild(tooltipEl);
                  }

                  // Hide if no tooltip
                  if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                  }

                  // Set caret Position
                  tooltipEl.classList.remove('above', 'below', 'no-transform');
                  if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                  } else {
                    tooltipEl.classList.add('no-transform');
                  }

                  function getBody(bodyItem) {
                    return bodyItem.lines;
                  }

                  // Set Text
                  if (tooltipModel.body) {
                    var titleLines = tooltipModel.title || [];
                    var bodyLines = tooltipModel.body.map(getBody);

                    var innerHtml = '<thead>';

                    titleLines.forEach(function(title) {
                        innerHtml += '<tr><th style="border:0px !important; color:#fff !important; height:0px !important; padding-left:0px !important">' + title + '</th></tr>';
                    });
                    innerHtml += '</thead><tbody>';

                    bodyLines.forEach(function(body, i) {
                      //var colors = tooltipModel.labelColors[i];
                      // var style = 'background:' + colors.backgroundColor;
                      // style += '; border-color:' + colors.borderColor;
                      var style = '; border-width: 2px';
                      var span = '<span style="' + style + '"> </span>';
                      innerHtml += '<tr style="border:0px !important"><td style="border:0px !important; height:0px !important; padding-left:0px !important">' + span + body + '</td></tr>';
                    });
                    innerHtml += '</tbody>';

                    var tableRoot = tooltipEl.querySelector('table');
                    tableRoot.innerHTML = innerHtml;
                  }

                  // `this` will be the overall tooltip
                  var position = this._chart.canvas.getBoundingClientRect();

                  // Display, position, and set styles for font
                  tooltipEl.style.opacity = 1;
                  tooltipEl.style.position = 'absolute';
                  if(tooltipModel.caretX < 700){
                    tooltipEl.style.right = (position.right - (window.pageXOffset + tooltipModel.caretX))-235 + 'px';
                    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX  + 'px';
                  }else{
                    tooltipEl.style.left = (position.left + window.pageXOffset + tooltipModel.caretX)-235  + 'px';
                    tooltipEl.style.right = position.right - (window.pageXOffset + tooltipModel.caretX) + 'px';
                  }
                  tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                  tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                  tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                  tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                  tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                  tooltipEl.style.pointerEvents = 'none';
                  tooltipEl.style.color = '#fff';
                  tooltipEl.style.background = '#333';
                }
              },
              scales: {
                yAxes: [{
                    display: true,
                    ticks: {
                      beginAtZero: true,
                      userCallback: function(label, index, labels) {
                        if (Math.floor(label) === label) {
                            return label;
                        }
                      },
                    }
                }]
              }
            }}
          />
        </div>
      );
    }else{
      return (
        <div>
          <Line
            height={chartheight}
            data={state}
            options={{
              legend:{
                display:false,
                position:'right'
              },
              tooltips: {
                // Disable the on-canvas tooltip
                enabled: false,

                custom: function(tooltipModel) {
                  // Tooltip Element
                  var tooltipEl = document.getElementById('chartjs-tooltip');

                  // Create element on first render
                  if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjs-tooltip';
                    tooltipEl.innerHTML = '<table></table>';
                    document.body.appendChild(tooltipEl);
                  }

                  // Hide if no tooltip
                  if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                  }

                  // Set caret Position
                  tooltipEl.classList.remove('above', 'below', 'no-transform');
                  if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                  } else {
                    tooltipEl.classList.add('no-transform');
                  }

                  function getBody(bodyItem) {
                    return bodyItem.lines;
                  }

                  // Set Text
                  if (tooltipModel.body) {
                    var titleLines = tooltipModel.title || [];
                    var bodyLines = tooltipModel.body.map(getBody);

                    var innerHtml = '<thead>';

                    titleLines.forEach(function(title) {
                        innerHtml += '<tr><th style="border:0px !important; color:#fff !important; height:0px !important; padding-left:0px !important">' + title + '</th></tr>';
                    });
                    innerHtml += '</thead><tbody>';

                    bodyLines.forEach(function(body, i) {
                      //var colors = tooltipModel.labelColors[i];
                      // var style = 'background:' + colors.backgroundColor;
                      // style += '; border-color:' + colors.borderColor;
                      var style = '; border-width: 2px';
                      var span = '<span style="' + style + '"> </span>';
                      innerHtml += '<tr style="border:0px !important"><td style="border:0px !important; height:0px !important; padding-left:0px !important">' + span + body + '</td></tr>';
                    });
                    innerHtml += '</tbody>';

                    var tableRoot = tooltipEl.querySelector('table');
                    tableRoot.innerHTML = innerHtml;
                  }

                  // `this` will be the overall tooltip
                  var position = this._chart.canvas.getBoundingClientRect();

                  // Display, position, and set styles for font
                  tooltipEl.style.opacity = 1;
                  tooltipEl.style.position = 'absolute';
                  tooltipEl.style.left = position.left + (window.pageXOffset-35) + (tooltipModel.caretX-100) + 'px';
                  tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                  tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                  tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                  tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                  tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                  tooltipEl.style.pointerEvents = 'none';
                  tooltipEl.style.color = '#fff';
                  tooltipEl.style.background = '#333';
                }
              },
              scales: {
                yAxes: [{
                    display: true,
                    ticks: {
                      beginAtZero: true,
                      userCallback: function(label, index, labels) {
                        if (Math.floor(label) === label) {
                            return label;
                        }
                      },
                    }
                }]
              }
            }}
          />
        </div>
      );
    }
  }
}

export default PropertyChart;
