import React ,  { Component } from 'react';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import '../../CustomFiles/css/predefined.css';
import Constants from '../../constants/Constants';
import AgentPerformance from './AgentModalTab/AgentPerformance';
import StockPipeline from './AgentModalTab/StockPipeline';
//import ColdCallLogs from './AgentModalTab/ColdCallLogs';
import ArchiveProperty from './AgentModalTab/ArchiveProperty';
import Networking from './AgentModalTab/Networking';
import AgentPropertyChartById from '../modal_charts/AgentPropertyChartById';
import AgentAppointmentChartById from '../modal_charts/AgentAppointmentChartById';
//import ReactTooltip from 'react-tooltip';
import evoCalendar from 'evo-calendar'; // eslint-disable-line no-unused-vars
import EvoCalendar from '../EvoCalendar';
import DateRange from '../DateRange';
//import Loader from '../Loader';

import AgentRevenueChartById from  '../modal_charts/AgentRevenueChartById';

import Moment from 'react-moment';
import { Calendar } from 'primereact/calendar';
//icons
import { Icon } from '@iconify/react';
//import clipboardNotes from '@iconify/icons-foundation/clipboard-notes';
import officeBuildingMarker from '@iconify/icons-mdi/office-building-marker';
import funnelDollar from '@iconify/icons-fa-solid/funnel-dollar';
//import callForward from '@iconify/icons-si-glyph/call-forward';
import bxsArchiveIn from '@iconify/icons-bx/bxs-archive-in';
import eyeIcon from '@iconify/icons-akar-icons/eye';
import recentlyViewed from '@iconify/icons-carbon/recently-viewed';
import arrowUp from '@iconify/icons-carbon/arrow-up';
import network2 from '@iconify/icons-carbon/network-2';
import copyIcon from '@iconify/icons-fa-regular/copy';
import fileEarmarkBarGraphFill from '@iconify/icons-bi/file-earmark-bar-graph-fill';
import bxCalendarEvent from '@iconify/icons-bx/bx-calendar-event';
import carSolid from '@iconify/icons-clarity/car-solid';

//modal
import StockPipelinePropertyModal from './StockPipelinePropertyModal';
import AddProperty from './AddProperty';
import OpenListingPropertyModal from './OpenListingPropertyModal';
import CrmVerifiedModal from './CRMInfoModal/CrmVerifiedModal';
import CrmNotVerifiedModal from './CRMInfoModal/CrmNotVerifiedModal';
import TravelTracking from './AgentModalTab/TravelTracking';
import TravelTrackingDetail from './TravelTrackingDetail';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

import $ from 'jquery';

//const $ = require('jquery');
var other_listing = [];
//let firstOfMonth = new Date("1/1/" + (new Date()).getFullYear());
//let lastOfMonth = new Date(new Date().getFullYear(), 11, 31, 0, 0, 0);
class AgentInformationModal extends Component{

  constructor(props){
    super(props);
    this.state = { 
      agentCalender:null,
      loading:false,
      calenderFlag:false,
      agent_id:null,
      //overview states
      overview_loader: true,
      //property states
      agent_data:[],
      agent_linked_user_cnt:[],
      property_for_sale: [],
      property_for_rent: [],
      property_for_sale_total:null,
      property_for_rent_total:null,
      graph:[],
      linked_user_cnt:[],
      showAddPropertyButton: false,
      //stock_pipeline
      stock_loader:true,
      //appointment states
      agentAppointmentData:[],
      //revenue states
      current_sales:[],
      last_sales:[],
      reve_sales:[],
      sale_active:true,
      revenue_active:true,
      //other listing states
      other_listing_data:[],
      load_modal: true,
      
      startDate: new Date(new Date().getFullYear(), 0, 1),
      endDate:   new Date(new Date().getFullYear(), 11, 31),
      CalenderMinDate: new Date(new Date().getFullYear(), 0, 1),
      appointmentFromDate: null,
      appointmentToDate: null,
      todayDate: null,
      sort_recent: 1,
      sort_views: 0,
      filter_by: "all",
      property_type: "sale",

      current_year: new Date(new Date().getFullYear(), 0, 1),
      current_month: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      
      renderCalendar: 1,
      call_api: 0,

      //for cold call export dates
      exportMonth: ("0" + (new Date().getMonth() + 1)).slice(-2),
      exportYear: new Date().getFullYear(),

      viewings_active: true,
      general_active: true,

      viewings_sort: null,
      recent_property: "desc",
      reset_date_range: false,

      //travel logbook
      selected_trip_data : null,
    }
  }

  //Retrieve Agent Performance on click of overview tab
  async renderOverview(agent_id){
    this.setState({overview_loader: true});
    let token = localStorage.getItem('token');
    await this.props.get_agent_performance_data(agent_id, token);
    this.setState({overview_loader: false});
  }
  
  //Retrieve Appointment data on click of Appt. tab 
  renderAppointmentData(agent_id){
    
    this.setState({
      startDate: new Date(new Date().getFullYear(), 0, 1),
      endDate:   new Date(new Date().getFullYear(), 11, 31),
      CalenderMinDate: new Date(new Date().getFullYear(), 0, 1),
      showAddPropertyButton: false
    })
    
    var token = localStorage.getItem('token');
    //var d = new Date(firstOfMonth),
    var d = new Date();
    // //month = '' + (d.getMonth() + 1),
    // month = '' + (d.getMonth() - 1),
    // day = '' + d.getDate(),
    // year = d.getFullYear();

    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1; 
    var yyyy = today.getFullYear();
    // if (mm.toString().length < 2) mm = '0' + mm;
    // if (dd.toString().length < 2) dd = '0' + dd;
    var todayDate = yyyy+'-'+mm+'-'+dd;
    this.setState({todayDate: todayDate })

    // if (month.length < 2) month = '0' + month;
    // if (day.length < 2) day = '0' + day;
    
    // //var appointmentFromDate = [year, month, day].join('-');
    var appointmentFromDate = this.changeDateFormat(new Date(d.getFullYear(), (d.getMonth() - 2), 1));
    this.setState({appointmentFromDate: appointmentFromDate })
    
    // //d = new Date(lastOfMonth);
    // d = new Date();
    // month = '' + (d.getMonth() + 1);
    // day = '' + d.getDate();
    // year = d.getFullYear();
    
    // if (month.length < 2) month = '0' + month;
    // if (day.length < 2) day = '0' + day;
    //var appointmentToDate = [year, month, day].join('-');
    this.props.set_page("appts_tab", true);
    var appointmentToDate = this.changeDateFormat(new Date(d.getFullYear(), d.getMonth(), d.getDate()));
    this.setState({appointmentToDate: appointmentToDate})
    this.props.get_appointment_data_modal(appointmentFromDate, appointmentToDate, todayDate, agent_id, token);
    this.props.get_general_chart_agent_modal(appointmentFromDate, appointmentToDate, agent_id, token);
    this.setState({call_api: 0});
    
    today = new Date();
    dd = String(today.getDate()).padStart(2, '0');
    mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    yyyy = today.getFullYear();

    today = mm + '/' + dd + '/' + yyyy;
    $('#evoCalendar').evoCalendar('selectDate', today);
    
    this.setState({call_api: 1});
  }

  //Retrieve Properties data on click of Property tab 
  renderPropertyData(agent_id){
    this.setState({
      sort_recent: 1,
      sort_views: 0,
      filter_by: "all",
      property_type: "sale",
      showAddPropertyButton: true 
    })
    $("#sale").addClass("active in");
    $("#saletab").parent("li").addClass("active");
    
    $("#rent").removeClass("active in");
    $("#renttab").parent("li").removeClass("active");
    
    $("#filter_by_for_sale").val("all").change();
    $("#filter_by_for_rent").val("all").change();
    //var lastOfMonth = new Date(new Date().getFullYear(), 11, 1, 0, 0, 0);
    var d = new Date();
    // var month = '' + (d.getMonth() - 1);
    // // var d = new Date(lastOfMonth);
    // // var month = '' + (d.getMonth()+1);
    // var day = '' + d.getDate();
    // var year = d.getFullYear();
    // if (month.length < 2) month = '0' + month;
    // if (day.length < 2) day = '0' + day;
    // var fromDate = [year, month, day].join('-');
    var fromDate = this.changeDateFormat(new Date(d.getFullYear(), d.getMonth() - 2, 1));

    //lastOfMonth = new Date(new Date().getFullYear(), 11, 31, 0, 0, 0);
    // d = new Date();
    // month = '' + (d.getMonth()+1);
    // day = '' + d.getDate();
    // year = d.getFullYear();
    // if (month.length < 2) month = '0' + month;
    // if (day.length < 2) day = '0' + day;
    // var toDate = [year, month, day].join('-');
    var toDate = this.changeDateFormat(new Date(d.getFullYear(), d.getMonth(), d.getDate()));
    
    var token = localStorage.getItem('token');
    let viewings_sort = null;
    let recent_property = 'desc';
    this.setState({viewings_sort: null,recent_property: 'desc'})
    this.props.set_page("property_tab", true);
    this.props.get_property_data(fromDate, toDate, agent_id, token, viewings_sort, recent_property);
    
  }

  //Retrieve Stock pipeline data on click of Revenue tab
  async renderStockPipeline(stock_pipeline_id = null){
    await this.props.reset_stockpipeline_data()
    this.setState({
      startDate: new Date(new Date().getFullYear(), 0, 1),
      endDate:   new Date(new Date()),
      CalenderMinDate: new Date(new Date().getFullYear(), 0, 1),
      showAddPropertyButton: false
    })
    this.setState({stock_loader: true});
    let token = localStorage.getItem('token');
    await this.props.get_auth_data(this.props.agent_id,token);
    var page = 1;
    let is_archived='0'
    await this.props.get_stock_pipeline_data(page, is_archived, stock_pipeline_id, this.props.authtoken);
  
    // var startDate = this.changeDateFormat(this.state.startDate);
    // var endDate = this.changeDateFormat(this.state.endDate);
    var date = new Date();
    var startDate = this.changeDateFormat(new Date(date.getFullYear(), date.getMonth() - 2, 1));
    var endDate = this.changeDateFormat(new Date(date.getFullYear(), date.getMonth(), date.getDate()));
    this.props.set_page("stockpipeline_tab", true);
    
    this.props.get_stockpipeline_chart_by_id(startDate, endDate, this.props.agent_id, token);
    
    this.setState({stock_loader: false });
  }
  
  //Retrieve Cold Call Logs data on click of Revenue tab
  async renderColdCallLogs () {
    this.setState({
      startDate: new Date(new Date().getFullYear(), 0, 1),
      endDate:   new Date(new Date()),
      CalenderMinDate: new Date(new Date().getFullYear(), 0, 1),
      exportMonth: ("0" + (new Date().getMonth() + 1)).slice(-2),
      exportYear: new Date().getFullYear(),
      showAddPropertyButton: false
    })
    let token = localStorage.getItem('token');
    await this.props.get_auth_data(this.props.agent_id,token);
    var page = 1;
    let year = new Date().getFullYear();
    let month = ("0" + (new Date().getMonth() + 1)).slice(-2);
    var date = year+month;
    this.props.get_cold_call_logs_data(page, date, this.props.authtoken);

    // var startDate = this.changeDateFormat(this.state.startDate);
    // var endDate = this.changeDateFormat(this.state.endDate);
    this.props.set_page("call_contact_tab", true);
    date = new Date();
    var startDate = this.changeDateFormat(new Date(date.getFullYear(), date.getMonth() - 2, 1));
    var endDate = this.changeDateFormat(new Date(date.getFullYear(), date.getMonth()));
    this.props.get_coldcalls_chart_by_id(startDate, endDate, this.props.agent_id, token);
  }

  async renderArchiveProperty (){
    this.setState({showAddPropertyButton: false})
    await this.props.reset_stockpipeline_data()
    let token = localStorage.getItem('token');
    await this.props.get_auth_data(this.props.agent_id,token);
    let page = 1;
    let is_archived = 1;
    let type = 'agent';
    await this.props.get_archived_property_data(page, is_archived, type, this.props.authtoken);
    let stock_pipeline_id = ''
    await this.props.get_stock_pipeline_data(page, is_archived, stock_pipeline_id, this.props.authtoken);
    this.props.set_page("property_modal", true);
  }

  async renderNetworking (){
    this.setState({showAddPropertyButton: false})
    let token = localStorage.getItem('token');
    this.props.get_networking_data(this.props.agent_id, token);
    this.props.set_page("property_modal", true);
  }

  async renderTravelTracking(){
    this.setState({showAddPropertyButton: false})
    let token = localStorage.getItem('token');
    await this.props.get_auth_data(this.props.agent_id, token);
    
    let page = 1
    let date = new Date();
    let start_date = this.changeDateFormat(new Date(date.getFullYear(), date.getMonth() - 2, 1));
    let end_date = this.changeDateFormat(new Date(date.getFullYear(), date.getMonth(), date.getDate()));
    
    this.props.get_upcoming_trips(this.props.authtoken, page);
    this.props.get_past_trips(this.props.authtoken, start_date, end_date, page)
    this.props.get_missed_trips(this.props.authtoken, page);
    //this.props.set_page("travel_tracking_modal", true);
  }

  //Retrieve Other Listing data on click of Other Listing tab 
  renderOtherListingData(agent_id){
    this.setState({showAddPropertyButton: false})
    var token = localStorage.getItem('token')
    this.props.get_other_listing_data(agent_id, token);
  }
  
  //Retrieve property graph by date
  // retrieveAgentPropertiesByAgentId = (fromDate , toDate) =>{
  //   fromDate = this.changeDateFormat(fromDate);
  //   toDate = this.changeDateFormat(toDate);

  //   // var datearray = fromDate.split("-");
  //   // fromDate = datearray[2] + '-' + datearray[1] + '-' + datearray[0];

  //   // datearray = toDate.split("-");
  //   // toDate = datearray[2] + '-' + datearray[1] + '-' + datearray[0];
 
  //   const token = localStorage.getItem('token');
  //   const agent_id = this.props.agent_id;

  //   this.setState({
  //     agent_id: agent_id,
  //     agent_data: [],
  //   })
  //   let viewings_sort = null;
  //   let recent_property = 'desc';
  //   this.props.get_property_data(fromDate, toDate, agent_id, token, viewings_sort, recent_property);
  // }

  //Retrieve Sale Property list data
  // renderPropertyListForSale() {
  //   if (this.props.property_for_sale.length === 0) {
  //     return (
  //       <center><h4>No Data Available To Show</h4></center>
  //     );
  //   } else {
  //     var property = [];
  //     var propertyListing = this.props.property_for_sale;
      
  //     property.push(<PropertyListModal key={"property_listing"} property_for_sale={propertyListing}
  //       handlePropertyModal={this.props.handlePropertyModal } 
  //       agent="1"
  //     />)

  //     return property;
  //   }
  // }

  //Display property on appointment tab After selecting Date  
  renderAppointmentProperty = () =>{
    var  date_wise_appt_length = this.props.agentAppointmentData.date_wise_appt.length;
    var  date_wise_appt = this.props.agentAppointmentData.date_wise_appt;
    var date_wise_appt1 = [];

    for (let i = 0; i < date_wise_appt_length; i++){
      date_wise_appt1.push(
        <tr key ={i}>
          {/* <ReactTooltip resizeHide = { true } backgroundColor={'#0B2C4C'}  /> */}
          <td className="pad20">
            <span className="fontInter fontWeight600 fontSize12 color12171D fontSetting" style={{letterSpacing:'-0.04px', float:'left'}}>{date_wise_appt[i].buyer_name}</span>
            <span className="fontInter fontWeight500 fontSize12 color738196 fontSetting padLeft15">
              { date_wise_appt[i].appointment_status === "Unconfirmed" ? null :
                [date_wise_appt[i].appointment_status === "Complete" ? <img src="images/Completed_status.png" alt="completed" width="77" style={{verticalAlign: "baseline"}} /> : 
                  date_wise_appt[i].appointment_status === "Cancelled" ? <span className="cancelspan" style={{verticalAlign: "top"}}>Cancelled</span> :
                  date_wise_appt[i].appointment_status === "Requested Change" ? null : date_wise_appt[i].appointment_status
                ]
              }
            </span>
            <span className="fontInter fontWeight600 fontSize12 color12171D fontSetting" style={{letterSpacing:'-0.04px', float:'right'}}>{date_wise_appt[i].time}</span>
            <br />
            {date_wise_appt[i].user_id === this.props.agent_id ?
              <span onClick={ ()=>this.props.handlePropertyModal(date_wise_appt[i].property_id) }  className="cursorPointer fontSize12 fontInter fontSetting color12171D" data-place="top" data-tip="View Property" style={{letterSpacing:'-0.04px'}}>
                {date_wise_appt[i].location_address}
              </span>
              :
              <span className="fontSize12 fontInter fontSetting color12171D" data-place="top" style={{letterSpacing:'-0.04px'}}>
                {date_wise_appt[i].location_address}
              </span>
            }
          </td>
        </tr>
      )
    }
    return date_wise_appt1;
  }

  changeDateFormat(fromDate) {
    var d = new Date(fromDate),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  //Display Graph on appointment tab after selecting dates
  // retrieveAppointmentByDate = (fromDate,toDate) =>{
  //   fromDate = this.changeDateFormat(fromDate);
  //   this.setState({ appointmentFromDate: fromDate })
  //   toDate = this.changeDateFormat(toDate);
  //   this.setState({ appointmentToDate: toDate })
  //   this.setState({
  //     agentAppointmentData:[]
  //   })

  //   const token = localStorage.getItem('token');
  //   const agent_id = this.props.agent_id;
      
  //   this.props.get_appointment_data_modal(fromDate, toDate, this.state.todayDate, agent_id, token);
  //   this.props.get_general_chart_agent_modal(fromDate, toDate, agent_id, token);
  // }

  //Retrieve Revenue graph by date
  retrieveRevenueByDate  = (fromDate,toDate) =>{
    fromDate = this.changeDateFormat(fromDate);
    toDate = this.changeDateFormat(toDate);
    
    const token = localStorage.getItem('token');
    const agent_id = this.props.agent_id;

    // var datearray = fromDate.split("-");
    // var from_date = datearray[2] + '-' + datearray[1] + '-' + datearray[0];

    // datearray = toDate.split("-");
    // var to_date = datearray[2] + '-' + datearray[1] + '-' + datearray[0];

    this.setState({
     current_sales: [],
     last_sales: [],
     reve_sales:[]
    });

    this.props.get_revenue_data_modal(fromDate, toDate, agent_id, token);
  }

  //Toggle Revenue Graph on Revenue Tab
  toggleRevenueDatatHandler =(button_id)=>{
    if(button_id === "revenue"){
      document.getElementById(button_id).classList.toggle("fadebutton");
      this.state.revenue_active ?
        this.setState({ revenue_active :false })
      :
        this.setState({ revenue_active :true })
    }else{
      document.getElementById(button_id).classList.toggle("fadebutton");
      this.state.sale_active ?
        this.setState({ sale_active :false })
      :
        this.setState({ sale_active :true })
    }
  }

  handleAddPropertyModal = async () => {
    this.setState({agent_id: this.props.agent_id})
    var token = localStorage.getItem('token');
    await this.props.get_auth_data(this.props.agent_id, token);
    var add_property_modal_state = 1;
    this.props.get_all_agent_data(this.props.authtoken);
    this.props.update_add_property_modal_state(add_property_modal_state);
    window.$("#addPropertyModal").modal('show');
  }

  // async auth_data(){
  //   var token = localStorage.getItem('token');
  //   await this.props.get_auth_data(this.props.agent_id, token);
  //   this.props.get_edit_property_data(g_prop_id, this.props.authtoken);
  //   var add_property_modal_state = 2;
  //   this.props.update_add_property_modal_state(add_property_modal_state);
  // }
  
  sortbyDate = () => {
    this.setState({
      sort_recent: 1,
      sort_views: 0 
    })
    
    var lastOfMonth = new Date(new Date().getFullYear(), 11, 1, 0, 0, 0);
    var d = new Date(lastOfMonth);
    var month = '' + (d.getMonth()+1);
    var day = '' + d.getDate();
    var year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    var fromDate = [year-1, month, day].join('-');

    d = new Date();
    month = '' + (d.getMonth()+1);
    day = '' + d.getDate();
    year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    var toDate = [year, month, day].join('-');
    
    const token = localStorage.getItem('token');
    const agent_id = this.props.agent_id;
    let viewings_sort = null;
    let recent_property = 'desc';
    this.setState({viewings_sort: null, recent_property: 'desc'})
    if(this.state.filter_by === "all"){
      this.props.get_property_data(fromDate, toDate, agent_id, token, viewings_sort, recent_property);
    }else{
      this.props.get_filtered_property_list(fromDate, toDate, agent_id, token, viewings_sort, recent_property, this.state.filter_by, this.state.property_type);
      let property_type = this.state.property_type === "sale" ? "rent": "sale";
      this.props.get_filtered_property_list(fromDate, toDate, agent_id, token, viewings_sort, recent_property, this.state.filter_by, property_type);
    }
  }
  
  sortbyView = () => {
    var lastOfMonth = new Date(new Date().getFullYear(), 11, 1, 0, 0, 0);
    var d = new Date(lastOfMonth);
    var month = '' + (d.getMonth()+1);
    var day = '' + d.getDate();
    var year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    var fromDate = [year-1, month, day].join('-');

    d = new Date();
    month = '' + (d.getMonth()+1);
    day = '' + d.getDate();
    year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    var toDate = [year, month, day].join('-');

    const token = localStorage.getItem('token');
    const agent_id = this.props.agent_id;

    if(Number(this.state.sort_views) === 1){
      this.setState({
        sort_recent: 0,
        sort_views: 0
      })
      let viewings_sort = 'asc';
      let recent_property = null;
      this.setState({viewings_sort: 'asc', recent_property: null})
      if(this.state.filter_by === "all"){
        this.props.get_property_data(fromDate, toDate, agent_id, token, viewings_sort, recent_property);
      }else{
        this.props.get_filtered_property_list(fromDate, toDate, agent_id, token, viewings_sort, recent_property, this.state.filter_by, this.state.property_type);
        let property_type = this.state.property_type === "sale" ? "rent": "sale";
        this.props.get_filtered_property_list(fromDate, toDate, agent_id, token, viewings_sort, recent_property, this.state.filter_by, property_type);
      }
    }else{
      this.setState({
        sort_recent: 0,
        sort_views: 1 
      })
      let viewings_sort = 'desc';
      let recent_property = null;
      this.setState({viewings_sort: 'desc', recent_property: null})
      if(this.state.filter_by === "all"){
        this.props.get_property_data(fromDate, toDate, agent_id, token, viewings_sort, recent_property);
      }else{
        this.props.get_filtered_property_list(fromDate, toDate, agent_id, token, viewings_sort, recent_property, this.state.filter_by, this.state.property_type);
        let property_type = this.state.property_type === "sale" ? "rent": "sale";
        this.props.get_filtered_property_list(fromDate, toDate, agent_id, token, viewings_sort, recent_property, this.state.filter_by, property_type);
      }
    }
  }

  componentDidMount = () =>{
    var today        = new Date();
    var currentYear  = today.getFullYear();
    var currentMonth = today.getMonth()+1;

    var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];
    var daysInMonth = new Date(currentYear, currentMonth, 0).getDate();

    var dayName = [];
    var totalDaysInMonth = [];

    for (let i = 1; i <= daysInMonth; i++) {
      var d = new Date(currentYear, currentMonth - 1, i);
      dayName.push(days[d.getDay()]);
      totalDaysInMonth.push(i);
    }

    // this.setState({
    //   agentCalender :<AgentCalender clickFromCalender={this.clickFromCalender}  activate_date={null} dayName = {dayName} month={currentMonth} year = {currentYear} totalDaysInMonth = {totalDaysInMonth}/>
    // })


    $("#evoCalendar").addClass('event-hide sidebar-hide');
    
    $("#evoCalendar").on("click", "#eventListToggler", function(e) {
      if($("#eventListToggler").hasClass("clicked")){
        $("#eventListToggler").removeClass("clicked");
        $("#eventListToggler").css("z-index", "2");
        $(".calendar-events").css("z-index", "0");
        $("#evoCalendar").addClass('event-hide');
      }else{
        $("#eventListToggler").addClass("clicked");
        $("#eventListToggler").css("z-index", "1");
        $(".calendar-events").css("z-index", "1");
      }
    })
    
    $("#evoCalendar").on("click", ".day", function(e) {
      $("#evoCalendar").find("#eventListToggler").click();
      $("#eventListToggler").addClass("clicked");
      $("#eventListToggler").css("z-index", "1");
      $(".calendar-events").css("z-index", "1");
      $('#eventListToggler').attr('style', 'display: block !important');
    })
    
    var _ = this;
    
    $('#evoCalendar').on('selectEvent', function(event, activeEvent) {  
      let date_sold='';
      if(activeEvent.appointment_type === "viewing"){
        if(Number(activeEvent.is_office_listing) === 0 && Number(activeEvent.is_open_listing) === 0){
          _.props.handlePropertyModal(activeEvent.id);
        }else if(Number(activeEvent.is_office_listing) === 1 && Number(activeEvent.is_open_listing) === 0){
          _.props.handlePropertyModal(activeEvent.id, date_sold, activeEvent.user_creator_id);
        }else{
          _.handleOpenListingModal(activeEvent.id, date_sold, activeEvent.user_creator_id)
        }
      }

      // popover for general appointment
      window.$('[data-toggle="popover"]').popover({
        //toggle: true,
        html: true,
        sanitize: false,
        //trigger: 'manual'
      })
      .click(function(e){
        //window.$('.display_popover').not(this).popover('hide');
        //window.$(this).popover('show');
      })
    });
    
    $('#evoCalendar').on('selectYear', async function(event, activeYear) {
      if(_.state.call_api === 1){
        if($("#eventListToggler").hasClass("clicked")){
          $("#eventListToggler").click();
        }

        //delete old events
        if(_.props.deleteEvents && _.props.deleteEvents.length > 0 ){
          $('#evoCalendar').evoCalendar('removeCalendarEvent', _.props.deleteEvents);
        }
        _.props.reset_calendar_data();
        
        var month =  new Date(_.state.current_month).getMonth()+1
        if(Number(month) < 10 ){
          month = "0"+month;
        }

        var date = activeYear+"-"+month+"-01";
        _.setState({current_year: date, todayDate: date })
        const token = localStorage.getItem('token');     
        const agent_id = _.props.agent_id;
      
        await _.props.get_appointment_data_modal(_.state.appointmentFromDate, _.state.appointmentToDate, date, agent_id, token);      
        var appt_data = JSON.stringify(_.props.date_wise_appt);
        appt_data = JSON.parse(appt_data);

        $('#evoCalendar').evoCalendar('addCalendarEvent', appt_data);
      }
    });
    
    $('#evoCalendar').on('selectMonth', async function(event, activeMonth, monthIndex) {
      $('#eventListToggler').attr('style', 'display: none !important');
      if(_.state.call_api === 1){
        var month  = Number(monthIndex)+1;
        if(Number(month) < 10){
          month = "0"+month;
        } 
        var oldMonth = new Date(_.state.current_month).getMonth();

        if( monthIndex !== oldMonth ){

          if($("#eventListToggler").hasClass("clicked")){
            $("#eventListToggler").removeClass("clicked");
            $("#eventListToggler").css("z-index", "2");
            $(".calendar-events").css("z-index", "0");
            $("#evoCalendar").addClass('event-hide');
          }

          if(_.props.deleteEvents && _.props.deleteEvents.length > 0 ){
            $('#evoCalendar').evoCalendar('removeCalendarEvent', _.props.deleteEvents);
          }
          _.props.reset_calendar_data();

          var date = new Date(_.state.current_year).getFullYear()+"-"+month+"-01";
          _.setState({current_month: date, todayDate: date, appointment_data:[]})
        
          const token = localStorage.getItem('token');     
          const agent_id = _.props.agent_id;
          await _.props.get_appointment_data_modal(_.state.appointmentFromDate, _.state.appointmentToDate, date, agent_id, token);      
          $('#evoCalendar').evoCalendar('addCalendarEvent', _.props.date_wise_appt);
        }
      }
    });
    

    //reset calendar 
    $('#agentModalClose').on('click', function() {
      _.setState({
        current_year: new Date(new Date().getFullYear(), 0, 1),
        current_month: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        call_api: 0
      })
      //set page for calendar
      if(window.location.pathname === "/overview"){
        _.props.set_page("overview_page", true)
        window.history.pushState('page2', 'Title', '/overview');
      }else if(window.location.pathname === "/properties"){
        _.props.set_page("properties_page", true) 
        window.history.pushState('page2', 'Title', '/properties');
      }else if(window.location.pathname === "/viewing"){
        _.props.set_page("viewing_page", true) 
        window.history.pushState('page2', 'Title', '/viewing');
      }else if(window.location.pathname === "/revenue"){
        _.props.set_page("revenue_page", true);
        window.history.pushState('page2', 'Title', '/revenue');
      }
      //$('#evoCalendar').evoCalendar('selectDate', "'"+month +" "+ day +" "+year+"'");
      //$('#evoCalendar').evoCalendar('removeAllCalendarEvent');
      
      if($("#eventListToggler").hasClass("clicked")){
        $('#evoCalendar').addClass("sidebar-hide");
        $("#eventListToggler").removeClass("clicked");
        $("#eventListToggler").css("z-index", "2");
        $(".calendar-events").css("z-index", "0");
        $("#evoCalendar").addClass('event-hide');
      }else if(!($('#evoCalendar').hasClass("sidebar-hide"))){
        $('#evoCalendar').addClass("sidebar-hide");
      }
      //delete old events
      if(_.props.deleteEvents && _.props.deleteEvents.length > 0){
        $('#evoCalendar').evoCalendar('removeCalendarEvent', _.props.deleteEvents);
      }
      _.props.reset_calendar_data();
      _.setState({renderCalendar: 1})
      const show_agents = 0
      const show_property = 0
      const from_property_page = 0 
      const from_stock_pipeline = 0
      _.props.update_add_appointment_modal_state(show_agents,show_property,from_property_page,from_stock_pipeline)
    })
  }

  handleOpenListingModal = (prop_id, date_sold, agent_id) => {
    /* Creating Date From 1st of Year to Last Of Year */
    this.props.reset_property_modal();
    var fromDate = new Date("1/1/" + (new Date()).getFullYear());
    var toDate = new Date(new Date().getFullYear(), 11, 31, 0, 0, 0);

    var d = new Date(fromDate),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    fromDate = [year, month, day].join('-');

    d = new Date(toDate);
    month = '' + (d.getMonth() + 1);
    day = '' + d.getDate();
    year = d.getFullYear();
  
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
  
    toDate = [year, month, day].join('-');

    //var agent_id = this.props.property_creator_id;
    const token = localStorage.getItem('token');
    const fromnetworking = 1;
    const property_notes_user_id = this.props.agent_id;
    //const property_notes_user_id = this.props.agent_id;
    this.props.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold, fromnetworking, property_notes_user_id);

    window.$("#openlisting_modal").modal('show');
  }

  callbackFunction = () => {
    this.setState({
      renderCalendar: 0
    })
  }
  selected_trip = (trip_data) =>{
    this.setState({
      selected_trip_data: trip_data
    })
    window.$("#traveltrackingdetailModal").modal('show');
  }
  clear_trip = () =>{
    this.setState({
      selected_trip_data: null
    })
  }


  copyAuthKey = () =>{
    var copyText = document.getElementById("copyClipboard");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    
    $('#copied-success').fadeIn(800);
    $('#copied-success').fadeOut(800);
  }

  setExportDate = (month, year)=>{
    this.setState({
      exportMonth: month,
      exportYear: year,
    })
  }
  
  showCRMVerifiedModal = () =>{
    window.$("#crmVerifiedModal").modal('show');
  }
  
  showCRMNotVerifiedModal = () =>{
    window.$("#crmNotVerifiedModal").modal('show');
  }

  get_filtered_property_list = (event) =>{
    var token = localStorage.getItem('token');
    if(token && event.target.value){
      this.setState({
        filter_by: event.target.value,
        property_type: event.target.id === "filter_by_for_sale" ? "sale" : "rent"
      });
      const agent_id = this.props.agent_id;
      var property_type =  event.target.id === "filter_by_for_sale" ? "sale" : "rent";
      var client_properties = event.target.value;
      var sort_views = this.state.sort_views === 1 ? "desc" : null;
      var sort_recent = this.state.sort_recent === 1 ? "desc" : null;

      var lastOfMonth = new Date(new Date().getFullYear()-1, 11, 1, 0, 0, 0);
      var d = new Date(lastOfMonth);
      var fromDate = this.changeDateFormat(lastOfMonth);

      d = new Date();
      var toDate = this.changeDateFormat(d);
      
      if(event.target.value === "all"){
        this.props.get_property_data(fromDate, toDate, agent_id, token, sort_views, sort_recent);
      }else{
        this.props.get_filtered_property_list(fromDate, toDate, agent_id, token, sort_views, sort_recent, client_properties, property_type);
      }    
    }
  }

  //Toggle Revenue Graph on Revenue Tab
  toggleAppointmentDatatHandler =(button_id)=>{
    if(button_id === "viewings"){
      document.getElementById(button_id).classList.toggle("fadebutton");
      this.state.viewings_active ?
        this.setState({ viewings_active :false })
      :
        this.setState({ viewings_active :true })
    }else{
      document.getElementById(button_id).classList.toggle("fadebutton");
      this.state.general_active ?
        this.setState({ general_active :false })
      :
        this.setState({ general_active :true })
    }
  }

  handleAddAppointment = async () =>{
    let token = localStorage.getItem('token');
    const show_agents = 1
    const show_property = 1
    const from_property_page = 0
    const from_stock_pipeline = 0 
    await this.props.get_auth_data(this.props.agent_id,token);
    this.props.get_all_agent_data_dd(this.props.authtoken)
    await this.props.update_add_appointment_modal_state(show_agents,show_property,from_property_page,from_stock_pipeline)
    window.$("#addAppointmentModal").modal('show');
  }

  render(){
    var appointmentLabels  =  [];
    //var date_wise_appts  = 0;
    var agentAppointments = null;
    var generalAppointment = null;
    if(this.props.agentAppointmentData){
      if(this.props.agentAppointmentData.appt_graph !== 'undefined' || this.props.agentAppointmentData.appt_graph !== "undefined"){
        //date_wise_appts = this.props.agentAppointmentData.date_wise_appt.length;
        agentAppointments = this.props.agentAppointmentData.appt_graph.appt_cnt["0"];
        agentAppointments = agentAppointments[this.props.agent_id];
        appointmentLabels = this.props.agentAppointmentData.appt_graph.labels;
      }
    }
    if(this.props.chart_general_count_agent_modal){
      if(this.props.chart_general_count_agent_modal !== 'undefined'){
        generalAppointment = this.props.chart_general_count_agent_modal["0"];
        generalAppointment = generalAppointment[this.props.agent_id];
      }
    }

    var sales_value = [];
    var revenue_value = [];
    

    // for( let a =0; a < this.props.reve_sales.sales_value.length;a++ ){
    //    sales_value = sales_value[a];
    // }
    if(this.props.reve_sales){
      if(typeof this.props.reve_sales.sales_value!=="undefined"){
        for(let a=0; a < this.props.reve_sales.sales_value.length; a++)
        {
          sales_value  =  this.props.reve_sales.sales_value[a];
        }
      }

      if(typeof this.props.reve_sales.revenue!=="undefined"){
        for(let a=0; a < this.props.reve_sales.revenue.length; a++)
        {
          revenue_value  =  this.props.reve_sales.revenue[a];
        }
      }
    } 

    //var d = new Date();
 
    //var currentMonth_no = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
    //var currentYear  = d.getFullYear();
    //var monthList    = ['January','Febuary','March','April','May','June','July','August','September','October','November','December'];
    //var currentMonth     = monthList[currentMonth_no-1];    

    //other listing
    if(this.props.other_listing_data){
      other_listing = JSON.parse(JSON.stringify(this.props.other_listing_data));
    }

    return(<div>
      <div className="modal fade" id="userProfileModal" data-backdrop="static" data-keyboard="false" role="dialog">
        <div className="modal-dialog modal-lg">
          <div className="modal-content agentmodalheight" style={{ borderRadius: '5px', }} >
            <div className="modal-header" style={{ padding: '0px', }}>
            </div>
            <div className="modal-body" style={{ padding: '0px' }}>
              {/* <ReactTooltip resizeHide = { true } backgroundColor={'#0B2C4C'}  /> */}
              {/* Sidenav For User Preference */}
              <div className="sidenav">
                <div style={{display:"block", textAlign:"center"}}>
                  {this.props.agent_performance?
                    this.props.agent_performance.agent_data.image ?
                      <img alt="" src={Constants.IMAGE_URL + this.props.agent_performance.agent_data.image} className="img-circle" width="64" height="64" />
                      :
                      <img alt="" src="user-images/userDefault.png" className="img-circle" width="64" height="64" />
                    : null
                  }
                </div>
                <br />
                <a id="overview_link" style={{ textAlign: '-webkit-center', cursor: 'pointer' }} href="#overview" onClick={() => {this.renderOverview(this.props.agent_id)}}>
                  <div className="user-modal-attr user-modal-overview" style={{ backgroundColor: '#0F1D30' }}>
                    <Icon icon={fileEarmarkBarGraphFill} style={{color: '#ffffff', fontSize: '24px'}} /><br/>
                    <span style={{ color: 'white', fontSize: '13px' }} >Overview</span>
                  </div>
                </a>

                <br/>
                <a id="appt_link" style={{ textAlign: '-webkit-center', cursor: 'pointer' }} href="#appt" onClick={()=>{this.renderAppointmentData(this.props.agent_id)}}>
                  <div className="user-modal-attr user-modal-appt" style={{ backgroundColor: '#0F1D30' }}>
                    <img src=" images/appoint_white.png " width="25" height="25" alt="" /><br />
                    <span style={{ color: 'white', fontSize: '13px' }} >Appointments</span>
                  </div>
                </a>

                <br />
                <a id="prop_link" style={{ textAlign: '-webkit-center', cursor: 'pointer' }} href="#prop" onClick={()=>{this.renderPropertyData(this.props.agent_id)}}>
                  <div className="user-modal-attr user-modal-prop">
                    <div>
                      <img src="images/property_white.png" width="24" height="19" alt="" />
                      <span style={{ color: 'white', fontSize: '13px' }} >Properties</span>
                    </div>
                  </div>
                </a>
                <br />
                <a id="stock_pipeline_link" style={{ textAlign: '-webkit-center', cursor: 'pointer' }} href="#stockpipeline" onClick={() =>{this.renderStockPipeline()}}>
                  <div className="user-modal-attr user-modal-stock-pipeline" style={{ backgroundColor: '#0F1D30' }}>
                    <Icon icon={funnelDollar} style={{color: '#ffffff', fontSize: '20px'}} /><br/>
                    <span style={{ color: 'white', fontSize: '13px' }} >Stock Pipeline</span>
                  </div>
                </a>
                <br />
                {/* <a id="cold_call_logs_link" style={{ textAlign: '-webkit-center', cursor: 'pointer' }} href="#coldcalllogs" onClick={() => {this.renderColdCallLogs() }}>
                  <div className="user-modal-attr user-modal-cold-call-logs" style={{ backgroundColor: '#0F1D30' }}>
                    <Icon icon={callForward} style={{color: '#ffffff', fontSize: '23px'}} /><br/>
                    <span style={{ color: 'white', fontSize: '13px' }} >Call/Contact</span>
                  </div>
                </a>
                <br /> */}
                <a id="archive_prop_link" style={{ textAlign: '-webkit-center', cursor: 'pointer' }} href="#archive_prop" onClick={() => {this.renderArchiveProperty()}}>
                  <div className="user-modal-attr user-modal-archive_property" style={{ backgroundColor: '#0F1D30' }}>
                    <Icon icon={bxsArchiveIn} style={{color: '#ffffff', fontSize: '30px'}} /><br/>
                    <span style={{ color: 'white', fontSize: '13px' }} >Archived Prop</span>
                  </div>
                </a>
                <br />
                <a id="networking_link" style={{ textAlign: '-webkit-center', cursor: 'pointer' }} href="#networking" onClick={() => {this.renderNetworking()}}>
                  <div className="user-modal-attr user-modal-networking" style={{ backgroundColor: '#0F1D30' }}>
                    <Icon icon={network2} style={{color: '#ffffff', fontSize: '30px'}} /><br/>
                    <span style={{ color: 'white', fontSize: '13px' }} >Networking</span>
                  </div>
                </a>
                <br />
                <a id="travelTracking_link" style={{ textAlign: '-webkit-center', cursor: 'pointer' }} href="#travelTracking" onClick={() => {this.renderTravelTracking()}}>
                  <div className="user-modal-attr user-modal-travelTracking" style={{ backgroundColor: '#0F1D30' }}>
                    <Icon icon={carSolid} style={{color: '#ffffff', fontSize: '30px'}} /><br/>
                    <span style={{ color: 'white', fontSize: '12px' }} >Travel Tracking</span>
                  </div>
                </a>
                {/* <a style={{ textAlign: '-webkit-center', cursor: 'pointer' }} href="#revenue" onClick={() => {this.renderRevenueData(this.props.agent_id)}}>
                  <div className="user-modal-attr user-modal-revenue" style={{ backgroundColor: '#0F1D30' }} >
                    <img src=" images/revenue_white.png " width="25" height="25" alt="" /><br />
                    <span style={{ color: 'white', fontSize: '13px' }} >Revenue</span>
                  </div>
                </a>
                <br/>
                <a style={{ textAlign: '-webkit-center', cursor: 'pointer' }} href="#other-listing" onClick={() => {this.renderOtherListingData(this.props.agent_id)}}>
                  <div className="user-modal-attr user-modal-other_listing" style={{ backgroundColor: '#0F1D30' }} >
                    <Icon icon={clipboardNotes} width="35" height="35" color="#fff" /><br />
                    <span style={{ color: 'white', fontSize: '13px' }} >Other Listings</span>
                  </div>
                </a> */}
              </div>

              {/* Left side section */}
              {/*Agent Details and  Modal Close button */}
              <div className="main" style={{ background: '#EDF7F6' }}>
                <div className="modal-user-region" style={{ paddingLeft: '20px', paddingTop: '20px' }}>
                  <div className="row">
                    <div className="col-md-9">
                      <span className="fontBold fontRoboto fontSize25" >
                        {!this.props.agent_performance ? <center><img src="images/loader.svg" width="40" height="40" alt="" /></center> : this.props.agent_performance.agent_data.firstname}
                        &nbsp;
                        {this.props.agent_performance? this.props.agent_performance.agent_data.lastname : null}
                      </span>
                      <span style={{ float: 'right' }}>
                      </span>
                    </div>
                    <div className="col-md-2">
                      <div className="circle" id="agentModalClose" data-dismiss="modal" >
                        <img src="images/modal-close.png " width="12" height="12" alt="" />
                      </div>
                    </div>
                    <div className="col-md-1"></div>
                  </div>
                  <div className="row">
                    <div className="col-md-10">
                      <span className="fontRoboto fontSize13 color8591A0">{!this.props.agent_performance ? "" : this.props.agent_performance.agent_data.email} - {!this.props.agent_performance ? "" : this.props.agent_performance.agent_data.cellnumber}</span>
                    </div>
                  </div>
                  {this.props.agent_performance && this.props.agent_performance.agent_data.hash !== null?
                    <div className="row">
                      <div className="col-md-10">
                        <br/>
                        <div className="clipboard">
                          <label style={{marginRight:"10px", fontSize: "16px"}}>API-Key</label>
                          <input onClick={this.copyAuthKey} className="copy-input" value={this.props.agent_performance.agent_data.hash} id="copyClipboard" readOnly={true} />
                          <button className="copy-btn" id="copyButton" onClick={this.copyAuthKey}><Icon icon={copyIcon} style={{fontSize: '20px', verticalAlign: 'middle'}} /></button>
                        </div>
                      </div>
                    </div>
                  : null }
                  {/* End of Agent Details and Modal Close button */}
                  {/* <br />
                  <br /> */}

                  {/* Properties Date */}
                  <div style={{marginBottom: 7}}></div>
                  {/* <div className="row property-information" style={{ marginTop: '-15px' }}>
                    <div className="col-md-8 padTop10" style={{ display: 'flex' }}>
                      <Calendar value={this.state.startDate} dateFormat="dd-mm-yy" name="appointmentFromProp" id="appointmentFromProp" className='ml15' maxDate={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())}
                        onChange={(e) => this.setState({ startDate: e.value, renderAgentButton: 1, CalenderMinDate:e.value }) }>
                      </Calendar>
                      <Calendar value={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())} minDate={this.state.CalenderMinDate} dateFormat="dd-mm-yy" name="appointmentToProp" id="appointmentToProp"
                        onChange={(e) => this.setState({ endDate: e.value }) } disabled={true}>
                      </Calendar>
                      <button type="button" style={{ backgroundColor: '#5FC8BF', borderRadius: '0px 10px 10px 0px' }}
                        className="btn btn-info" 
                        onClick={ () => this.retrieveAgentPropertiesByAgentId(this.state.startDate, this.state.endDate)}
                      >
                        Go
                      </button>
                    </div>
                  </div> */}

                  {/* Revenue Date */}
                  <div className="row revenue-information" style={{ marginTop: '-15px' }}>
                    <div className="col-md-8 padTop10" style={{ display: 'flex' }}>
                      <Calendar value={this.state.startDate} dateFormat="dd-mm-yy" name="appointmentFromProp" id="appointmentFromProp" className='ml15'
                        onChange={(e) => this.setState({ startDate: e.value, renderAgentButton: 1, CalenderMinDate:e.value }) }>
                      </Calendar>
                      <Calendar value={this.state.endDate} minDate={this.state.CalenderMinDate} dateFormat="dd-mm-yy" name="appointmentToProp" id="appointmentToProp"
                        onChange={(e) => this.setState({ endDate: e.value }) }>
                      </Calendar>
                      {/* <input type="text" className="revenueFromProp  fromProp form-control" id="revenue_from_date" placeholder="From : 12/04/2020" style={{ borderRadius: '5px 5px 5px 5px' }}></input>
                      <input type="text" id="revenue_to_date" className="revenueToProp toProp form-control" placeholder="To : 12/04/2020" style={{ borderRadius: '5px 5px 5px 5px' }} ></input> */}
                      <button type="button" style={{ backgroundColor: '#5FC8BF', borderRadius: '0px 10px 10px 0px', padding: "4px 12px" }} className="btn btn-info"
                        onClick={() => this.retrieveRevenueByDate(this.state.startDate, this.state.endDate) }
                      >
                        Go
                      </button>
                    </div>
                  </div>
                  <br />
                </div>
              </div> {/* Main Div End */}
              {/* Left side section End*/}

              {/* Right side section */}
              <div className="mr5 padRight30 padLeft30" style={{ marginLeft: '125px', marginTop: '25px' }}>
                {/* Appointment Data */}
                {/* <div className="row appointments-information">
                  <center> {this.props.apptLoading ?
                    <img src="images/loader.svg" width="50" height="50" alt="" /> : null} </center>
                  <div className="col-md-5" style={{ marginLeft: '-40px' }}>
                  </div>
                </div>
                <br /> */}
                
                <div className = "appointments-information">
                  <div className="row">
                    <div className = "col-md-6">
                      <span className="box-title fontSize17 ml15  color12171D fontWeight600 fontInter">
                        <img src="images/date.png" width="18" height="20" alt="" />
                        <span className="ml20">Appointments</span>
                      </span>
                    </div>
                    {/* <div className = "col-md-2">
                      <span className="cursorPointer color4AA49C fontSize15 fontInter" id="btn_appointment_today" onClick={()=> this.getMonthList(currentMonth,currentYear,(11-(currentMonth_no-1)),1)}>Today</span>
                    </div> 
                    */}
                  </div>
                  <br />

                  <div className="row">
                    <div className="col-md-12">
                      <EvoCalendar
                        parentCallback = {this.callbackFunction}
                        renderCalendar = {this.state.renderCalendar}
                        date_wise_appt = {this.props.date_wise_appt}
                        deleteEvents = {this.props.deleteEvents}
                      />
                    </div>
                  </div>
                  <br/>
                </div>
                
                {/* Appointment Chart */}
                <div className="row appointments-information">
                  <button type="submit" className="btn btn-primary fontBold" style={{ background:'#4AA49C', borderRadius:'4px', marginLeft:20, padding:"6.3px 12px"}} onClick={this.handleAddAppointment}>
                    <Icon className="message_icon mr5" style={{verticalAlign:'bottom'}} icon={bxCalendarEvent} height='20' width='20' />
                      Add Appointment
                  </button>
                  <br/><br/>
                  <div style={{display:'flex'}} className="ml20">
                    <img src="images/Total_icon.png" width="24" height="19" alt="" />
                    <span className="box-title fontSize17 ml15  color0F1D30 fontWeight500 fontInter">Appointments over time&nbsp; </span>
                  </div>
                  <br />
                  <div className = "row appointments-information ml20" >
                    <div className="col-md-8" style={{ display: 'center' }}>
                      <DateRange agent_id={this.props.agent_id} />
                    </div>
                    {/* <div className="col-md-8" style={{display:'flex'}}>
                      <Calendar value={this.state.startDate} dateFormat="dd-mm-yy" name="appointmentFromProp" id="appointmentFromProp" className='ml15'
                        onChange={(e) => this.setState({ startDate: e.value, renderAgentButton: 1, CalenderMinDate:e.value }) }>
                      </Calendar>
                      <Calendar value={this.state.endDate} minDate={this.state.CalenderMinDate} dateFormat="dd-mm-yy" name="appointmentToProp" id="appointmentToProp"
                        onChange={(e) => this.setState({ endDate: e.value }) }>
                      </Calendar>
                      <button type="button" style={{ backgroundColor: '#5FC8BF', borderRadius: '0px 10px 10px 0px', padding: "4px 12px" }} 
                      className="btn btn-info" onClick={ () => this.retrieveAppointmentByDate(this.state.startDate, this.state.endDate)}>Go</button>
                    </div> */}
                  </div>
                  <br />
                  <div className = "row ml20">
                    <div className = "col-md-1">
                      <span className = "fontInter fontSize12 color12171D" style={{verticalAlign:'-webkit-baseline-middle'}}>Show:</span>
                    </div>
                    <div className = "col-md-2 ">
                      <button className = "btn fontInter" id="viewings" style={{background:'#27AFAF',color:'white',borderRadius:'4px'}} onClick={() => {this.toggleAppointmentDatatHandler("viewings")}}>Viewings</button>
                    </div>
                    <div className = "col-md-2" style={{paddingLeft: "0px"}}>
                      <button className = "btn fontInter" id="general" style={{marginLeft:'8px',background:'#2D3E54',color:'white',borderRadius:'4px'}} onClick={() => {this.toggleAppointmentDatatHandler("general")}}>General</button>
                    </div>
                  </div>
                  <br />
                  <div className="ml20">
                    {/* <AgentRevenueChartById  labels = { this.props.reve_sales.labels }  sales_value = { this.state.sale_active ? sales_value : '' } revenue = {this.state.revenue_active ? revenue_value : "" } /> */}
                    {this.props.agentAppointmentData ? 
                      <AgentAppointmentChartById agent_data = { this.props.agent_data } agent_id = {this.props.agent_id} viewing_appt={ this.state.viewings_active ? agentAppointments : '' }   general_appt={this.state.general_active ? generalAppointment : '' } labels = { appointmentLabels } />
                      : <center><img src="images/loader.svg" width="40" height="40" alt="" /></center> }
                    </div>
                  <br/><br/>
                </div>
                {/* Appointment Data Ends Here */}


                {/* Property Data */}
                {/* Property Chart */}
                <div className="row property-information">
                  <div style={{display:'flex'}} className="ml20">
                    <img src="images/side_prop_icon.png" width="24" height="19" alt="" />
                    <span className="box-title fontSize17 ml15  color0F1D30 fontWeight500 fontInter"> Properties&nbsp; </span>
                  </div>
                  <div className = "row ml20" >
                    <div className="col-md-8" style={{ display: 'center' }}>
                      <DateRange agent_id={this.props.agent_id} viewings_sort={this.state.viewings_sort} recent_property={this.state.recent_property}/>
                    </div>
                  </div>
                  <br />
                  <div className="ml20">
                    { this.props.graph ?
                      <AgentPropertyChartById  agent_id = {this.props.agent_id} labels = { this.props.graph.labels } agent_prop_cnt = { this.props.graph.agent_prop_cnt } />
                      : null
                    }
                  </div>
                </div>
                
                {/* Add Property Button */}
                {this.props.property_for_sale && this.state.showAddPropertyButton ?
                  <div className = "linkedProp mt20 property-information">
                    <button type="submit" onClick={this.handleAddPropertyModal} className="btn btn-primary fontBold" style={{ background:'#4AA49C',borderRadius:'4px'}} > <Icon className="message_icon" icon={officeBuildingMarker} height='20' width='20' /> Add Property</button>
                  </div>
                  : null
                }
                

                {/* Property For sale and Rent Tab */}
                <ul className="nav nav-tabs property-information">
                  {this.props.property_for_sale ?
                    <li style={{ width: '50%' }} className="active fontInter">
                      <a data-toggle="tab" id="saletab" href="#sale">
                        For Sale
                        <br />
                        <span className="fontSize12 fontWeightNormal fontInter"><b>{this.props.property_for_sale.length}</b>&nbsp;{this.props.property_for_sale.length === 1 ? "Property" : "Properties" }</span>&nbsp;<sup>.</sup>&nbsp;
                        <span className="fontSize12 fontWeightNormal fontInter">{this.props.property_for_sale_total}</span>
                      </a>
                    </li>
                    : null
                  }
                  {this.props.property_for_rent ?
                    <li style={{ width: '50%' }}>
                      <a data-toggle="tab" id="renttab" href="#rent">For Rent
                        <br />
                        <span className="fontSize12 fontWeightNormal fontInter"><b>{this.props.property_for_rent.length}</b>&nbsp; {this.props.property_for_rent.length === 1 ? "Property" : "Properties"}</span>&nbsp;<sup>.</sup>&nbsp;
                        <span className="fontSize12 fontWeightNormal fontInter">{this.props.property_for_rent_total}</span>
                      </a>
                    </li>
                    : null
                  }
                </ul>

                {/* Tab Content Starts */}
                <div className="tab-content property-information" style={{ marginTop: '40px', overflow: 'auto', fontSize: '16px'}}>
                  <div id="sale" className="tab-pane fade in active">
                    
                    <div style={{display: 'block'}}>
                      <div className="sortBox" style={{display: 'inline-block'}}>
                        <span style={{color:'#4AA49C', marginLeft:"10px"}}> Sort By: </span>
                        
                        <span className="cursorPointer" style={Number(this.state.sort_recent) === 1 ? {color: '#4aa49c', fontWeight: 600, marginLeft: "5px"} : {color: '#738196', margin: "0px 10px"}}  onClick={this.sortbyDate}>Date added 
                          <Icon className="ml10" icon={recentlyViewed}  style={Number(this.state.sort_recent) === 1 ? {color: '#4aa49c', fontSize: '18px', verticalAlign: 'bottom'} : {color: '#738196', fontSize: '18px', verticalAlign: 'bottom'}} />
                        </span>

                        <div className="vl"></div>
                        <span className="cursorPointer" style={Number(this.state.sort_recent) !== 1 ? {color: '#4aa49c', fontWeight: 600} : {color: '#738196', margin: "0px 10px"}} onClick={this.sortbyView}> Viewings 
                          <Icon className="ml10" icon={arrowUp} style={Number(this.state.sort_recent) !== 1 ? {color: '#4aa49c', fontSize:'18px', verticalAlign: 'bottom'} : {color: '#738196', fontSize: '18px', verticalAlign: 'bottom'}}  rotate={Number(this.state.sort_views) !== 1 ? "180deg" : "0deg"}/>
                        </span>
                      </div>

                      <div className="select-wrap" style={{display:"inline-block", border:"2px solid #E0EDED", padding:"6px", width: "auto",  marginLeft: "20px"}}>
                          <span style={{color:'#4AA49C', marginLeft:"10px", position:"absolute", marginTop: "3px"}}>Filter by:</span>
                          <select className="filter_dropdown" style={{display:"inline", marginLeft: "70px", width: "72%", fontSize:"16px"}} id="filter_by_for_sale" name="filter_by" ref="filter_by" onChange={this.get_filtered_property_list}>
                            <option value="all" selected>All Properties</option>
                            <option value="linked">Linked properties</option>
                            <option value="unlinked">Not linked properties</option>
                          </select>
                      </div>
                    </div>

                    <hr />
                    {/* {this.props.property_for_sale_total ?
                      this.renderPropertyListForSale() : <center><h4>No Data Available To Show</h4></center>
                    } */}
                    {this.props.property_for_sale ?
                      this.props.property_for_sale.length > 0 ?
                        this.props.property_for_sale.map((data, index) => (
                          <div key={index}>
                            <div className="property-col property-details" style={{ border: '2p solid red' }}>
                              <div className="property-text fontSize13 fontInter" style={{ width: '60%', color: '#0F1D30' }}>
                                <span onClick = { ()=> this.props.handlePropertyModal(data.propid, data.date_sold)}>{data.address}</span> 
                                {Number(this.props.agent_data.pro_user) === Number(1) ?
                                  Number(data.crm_verified) === Number(1) ? 
                                    <img tag="allowRowEvents" alt='' width="20" height="20" style={{verticalAlign: "text-bottom", marginLeft: "5px", cursor:"pointer"}} src={"images/crm_verified.svg"} onClick={this.showCRMVerifiedModal}/>
                                  :
                                    <img tag="allowRowEvents" alt='' width="20" height="20" style={{verticalAlign: "text-bottom", marginLeft: "5px", cursor:"pointer"}} src={"images/crm_not_verified.svg"} onClick={this.showCRMNotVerifiedModal}/>
                                : null
                                }
                                <div onClick = { ()=> this.props.handlePropertyModal(data.propid, data.date_sold)}>
                                  <div className="unit_name" onClick = { ()=> this.props.handlePropertyModal(data.propid, data.date_sold)}> {data.unit_name !== "" ?  data.unit_name : null} </div> 
                                  {data.date_sold ? <span className="soldspan" style={{marginLeft:"0px"}}>Sold</span> : null} 
                                  {Number(data.is_office_listing) === 1 ? <span className="ml5 office_listing_span"> Office Listing </span> : null}
                                  {Number(data.is_open_listing) === 1 ? <span className="ml5 open_listing_span"> Other Listing </span> : null}
                                </div>
                              </div>
                              <div className="fontSize13 fontInter" onClick = { ()=> this.props.handlePropertyModal(data.propid, data.date_sold)} style={{ width: '25%', marginLeft: '13px' }}>
                                {Number(data.is_seller_occupant) === Number(1) ?
                                  Number(data.isAgentApproved) === Number(1) ?
                                    <div onClick = { ()=> this.props.handlePropertyModal(data.propid, data.date_sold)}>
                                      <img className="img-circle" src=" images/right-sign.png " width="13.33" height="13.33" alt="" />
                                      <span className="color4AA49C fontSize11 fontWeight600 fontInter ml5" >Seller Linked</span>
                                    </div>
                                  : 
                                    null
                                    // <div>
                                    //   <Icon icon={clockHistory} style={{color:'#FF9966', verticalAlign:"middle" }} />
                                    //   <span style={{color:'#FF9966'}} className="fontInter fontWeight600 fontSize11 ml5">Seller Pending</span>
                                    // </div>  
                                : 
                                  <>
                                  {Number(data.isAgentApproved) === Number(1) ?
                                    <div onClick = { ()=> this.props.handlePropertyModal(data.propid, data.date_sold)}>
                                      <img className="img-circle" src=" images/right-sign.png " width="13.33" height="13.33" alt="" />
                                      <span className="color4AA49C fontSize11 fontWeight600 fontInter ml5" >Seller Linked</span>
                                    </div>
                                  : 
                                    null
                                    // <div>
                                    //   <Icon icon={clockHistory} style={{color:'#FF9966', verticalAlign:"middle" }} />
                                    //   <span style={{color:'#FF9966'}} className="fontInter fontWeight600 fontSize11 ml5">Seller Pending</span>
                                    // </div>
                                  }
                                  {Number(data.isAgentApprovedFromOccupant) === Number(1) ?  
                                  <div onClick = { ()=> this.props.handlePropertyModal(data.propid, data.date_sold)}>
                                    <img className="img-circle" src=" images/right-sign.png " width="13.33" height="13.33" alt="" />
                                    <span className="color4AA49C fontSize11 fontWeight600 fontInter ml5">Occupant Linked</span>
                                  </div>  
                                  :
                                    null
                                    // <div>
                                    //   <Icon icon={clockHistory} style={{color:'#FF9966', verticalAlign:"middle" }} />
                                    //   <span style={{color:'#FF9966'}} className="fontInter fontWeight600 fontSize11 ml5" >Occupant Pending</span>
                                    // </div>
                                  }
                                  </>
                                }
                                
                              </div>
                              <div className="fontSize13 fontInter" onClick = { ()=> this.props.handlePropertyModal(data.propid, data.date_sold)}>
                                <Icon icon={eyeIcon} className="color4AA49C" style={{fontSize: '24px', verticalAlign: 'bottom'}} /> <span className="viewCount">{data.viewings_count}</span>
                              </div>
                            </div>
                            <hr />
                          </div>
                        )) 
                      : <center><h4>No Data Available To Show</h4></center>
                    : <center><h4>No Data Available To Show</h4></center> }
                  </div> {/* For Sale Tab Ends Here */}
                  
                  {/* Second Tab For Rent Starts  */}
                  <div id="rent" className="tab-pane fade">
                    <div style={{display: 'block'}}>
                      <div className="sortBox" style={{display: 'inline-block'}}>
                        <span style={{color:'#4AA49C', marginLeft: "10px"}}> Sort By: </span>
                        
                        <span className="cursorPointer" style={Number(this.state.sort_recent) === 1 ? {color: '#4aa49c', margin: "0px 10px", fontWeight: 600} : {color: '#738196', margin: "0px 10px"}}  onClick={this.sortbyDate}>Date added 
                          <Icon className="ml10" icon={recentlyViewed}  style={Number(this.state.sort_recent) === 1 ? {color: '#4aa49c', fontSize: '18px', verticalAlign: 'bottom'} : {color: '#738196', fontSize: '18px', verticalAlign: 'bottom'}} />
                        </span>

                        <div className="vl"></div>
                        <span className="cursorPointer" style={Number(this.state.sort_recent) !== 1 ? {color: '#4aa49c', margin: "0px 10px", fontWeight: 600} : {color: '#738196', margin: "0px 10px"}} onClick={this.sortbyView}> Viewings 
                          <Icon className="ml10" icon={arrowUp} style={Number(this.state.sort_recent) !== 1 ? {color: '#4aa49c', fontSize:'18px', verticalAlign: 'bottom'} : {color: '#738196', fontSize: '18px', verticalAlign: 'bottom'}}  rotate={Number(this.state.sort_views) !== 1 ? "180deg" : "0deg"}/>
                        </span>
                      </div>
                        
                      <div className="select-wrap" style={{display:"inline-block", border:"2px solid #E0EDED", padding:"6px", width: "auto",  marginLeft: "20px"}}>
                        <span style={{color:'#4AA49C', marginLeft:"10px", position:"absolute", marginTop: "3px"}}>Filter by:</span>
                        <select className="filter_dropdown" style={{display:"inline", marginLeft: "70px", width: "72%", fontSize:"16px"}} id="filter_by_for_rent" name="filter_by" ref="filter_by" onChange={this.get_filtered_property_list}>
                          <option value="all" selected>All Properties</option>
                          <option value="linked">Linked properties</option>
                          <option value="unlinked">Not linked properties</option>
                        </select>
                      </div>
                    </div>

                    <hr />
                    {this.props.property_for_rent ?
                      this.props.property_for_rent.length > 0 ?
                        this.props.property_for_rent.map((data, index) => (
                          <div key={index}>
                            <div className="property-col property-details" style={{ border: '2p solid red' }}>
                              <div className="property-text fontSize13 fontInter" style={{ width: '60%', color: '#0F1D30' }}>
                                <span onClick = { ()=> this.props.handlePropertyModal(data.propid, data.date_sold)}>{data.address}</span> 
                                {Number(this.props.agent_data.pro_user) === Number(1) ?
                                  Number(data.crm_verified) === Number(1) ? 
                                    <img tag="allowRowEvents" alt='' width="20" height="20" style={{verticalAlign: "text-bottom", marginLeft: "5px", cursor:"pointer"}} src={"images/crm_verified.svg"} onClick={this.showCRMVerifiedModal}/>
                                  :
                                    <img tag="allowRowEvents" alt='' width="20" height="20" style={{verticalAlign: "text-bottom", marginLeft: "5px", cursor:"pointer"}} src={"images/crm_not_verified.svg"} onClick={this.showCRMNotVerifiedModal}/>
                                :
                                  null
                                }
                                <div onClick = { ()=> this.props.handlePropertyModal(data.propid, data.date_sold)}>
                                  <div className="unit_name"> {data.unit_name !== "" ?  data.unit_name : null} </div> 
                                  {data.date_sold ? <span className="soldspan" style={{marginLeft:"0px"}}>Rented</span> : null}
                                  {Number(data.is_office_listing) === 1 ? <span className="ml5 office_listing_span"> Office Listing </span> : null}
                                  {Number(data.is_open_listing) === 1 ? <span className="ml5 open_listing_span"> Other Listing </span> : null}
                                </div>
                              </div>
                              <div className="fontSize13 fontInter" onClick = { ()=> this.props.handlePropertyModal(data.propid, data.date_sold)} style={{ width: '25%', marginLeft: '13px' }}>
                                {Number(data.is_seller_occupant) === Number(1) ?
                                  Number(data.isAgentApproved) === Number(1) ?
                                    <div>
                                      <img className="img-circle" src=" images/right-sign.png " width="13.33" height="13.33" alt="" />
                                      <span className="color4AA49C fontSize11 fontWeight600 fontInter ml5" >Owner Linked</span>
                                    </div>
                                  : 
                                    null
                                    // <div>
                                    //   <Icon icon={clockHistory} style={{color:'#FF9966', verticalAlign:"middle" }} />
                                    //   <span style={{color:'#FF9966'}} className="fontInter fontWeight600 fontSize11 ml5">Owner Pending</span>
                                    // </div>  
                                : 
                                  <>
                                  {Number(data.isAgentApproved) === Number(1) ?
                                    <div>
                                      <img className="img-circle" src=" images/right-sign.png " width="13.33" height="13.33" alt="" />
                                      <span className="color4AA49C fontSize11 fontWeight600 fontInter ml5" >Owner Linked</span>
                                    </div>
                                  : 
                                    null
                                    // <div>
                                    //   <Icon icon={clockHistory} style={{color:'#FF9966', verticalAlign:"middle" }} />
                                    //   <span style={{color:'#FF9966'}} className="fontInter fontWeight600 fontSize11 ml5">Owner Pending</span>
                                    // </div>
                                  }
                                  {Number(data.isAgentApprovedFromOccupant) === Number(1) ?  
                                  <div>
                                    <img className="img-circle" src=" images/right-sign.png " width="13.33" height="13.33" alt="" />
                                    <span className="color4AA49C fontSize11 fontWeight600 fontInter ml5">Occupant Linked</span>
                                  </div>  
                                  :
                                  null
                                  // <div>
                                  //   <Icon icon={clockHistory} style={{color:'#FF9966', verticalAlign:"middle" }} />
                                  //   <span style={{color:'#FF9966'}} className="fontInter fontWeight600 fontSize11 ml5" >Occupant Pending</span>
                                  // </div>
                                  }
                                  </>
                                }
                                
                              </div>
                              <div className="fontSize13 fontInter" onClick = { ()=> this.props.handlePropertyModal(data.propid, data.date_sold)}>
                                <Icon icon={eyeIcon} className="color4AA49C" style={{fontSize: '24px', verticalAlign: 'bottom'}} /> <span className="viewCount">{data.viewings_count}</span>
                              </div>
                            </div>
                            <hr />
                          </div>
                        )) 
                      : <center><h4>No Data Available To Show</h4></center>
                    : <center><h4>No Data Available To Show</h4></center> }
                    
                  </div> { /* Second Tab For Rent Ends Here */}
                </div> {/* Tab Content Ends Here */}
                {/* Property tab Ends Here */}
                {/* Property Data Ends Here*/}

                {/* Overview Agent Modal Data */ }
                <AgentPerformance
                  agent_id = {this.props.agent_id}
                  overview_loader = {this.state.overview_loader}
                />
                {/* Overview Agent Modal Data Ends Here */ }
                
                {/* Stock Pipeline Data  */}
                <StockPipeline 
                  agent_id = {this.props.agent_id}
                  loader = {this.state.stock_loader}
                  authtoken = {this.props.authtoken}
                  startDate = {this.state.startDate}
                  endDate = {this.state.endDate}
                />
                {/* Stock Pipeline Data Ends Here */}
                
                {/* Cold Call Logs Data */}
                {/* <ColdCallLogs 
                  agent_id = {this.props.agent_id}
                  setExportDate= {this.setExportDate}
                  exportMonth = {this.state.exportMonth}
                  exportYear = {this.state.exportYear}
                  reset_date_range = {this.state.reset_date_range}
                /> */}
                {/* Cold Call Logs Data Ends Here */}
                
                {/* Archive Property Logs Data */}
                <ArchiveProperty
                  handlePropertyModal = {this.props.handlePropertyModal} 
                  agent_id = {this.props.agent_id}
                />
                {/* Archive Property Logs Data Ends Here */}

                {/*Networking Data*/}
                <Networking
                  handlePropertyModal = {this.props.handlePropertyModal} 
                  agent_id = {this.props.agent_id}
                />

                {/* Travel logbook Data */}
                <TravelTracking
                  setTrip = {(trip_data)=>this.selected_trip(trip_data) }
                />

                {/* Revenue Data */}
                <div className = "revenue-information" style={{minHeight:350}}>
                  <div className = "row">
                    <div className = "col-md-1">
                      <span className = "fontInter fontSize12 color12171D" style={{verticalAlign:'-webkit-baseline-middle'}}>Show:</span>
                    </div>
                    <div className = "col-md-2 ">
                      <button className = "btn fontInter" id="revenue" style={{background:'#EA676A',color:'white',borderRadius:'4px'}} onClick={() => {this.toggleRevenueDatatHandler("revenue");}}>Revenue</button>
                    </div>
                    <div className = "col-md-2">
                      <button className = "btn fontInter" id="sales" style={{marginLeft:'8px',background:'#27AFAF',color:'white',borderRadius:'4px'}} onClick={() => {this.toggleRevenueDatatHandler("sales");}}>Sales Value</button>
                    </div>
                  </div>
                  {!this.props.reve_sales ? 
                    <center><br />
                      <img src="images/loader.svg" width="50" height="50" alt="" />
                    </center>
                    : null}
                    <br />
                    <br />
                  {this.props.reve_sales ? 
                    <div className="ml10">
                      <AgentRevenueChartById  labels = { this.props.reve_sales.labels }  sales_value = { this.state.sale_active ? sales_value : '' } revenue = {this.state.revenue_active ? revenue_value : "" } />
                    </div>
                  : null}
                  <br />
                  <br />

                  <div className="row mt20">
                    <div className = "col-md-8">
                    <span className="box-title fontSize17 ml15  color12171D fontWeight600 fontInter">
                    <img src="images/Sale.png" width="18" height="20" alt="" />
                    <span className="ml20">
                        Sales & Commission&nbsp;</span>
                    </span>
                    </div>
                  </div>

                  <div className   = "revenueTab revenue-information mt30">
                    <table className = "table linkPropTable">
                      <thead>
                        <tr style={{borderTop:'2px solid white',pading:'20px'}} >
                          <th className="fontInter fontSize13" style={{color:'#738196'}}></th>
                          <th className="fontInter fontSize13" style={{color:'#738196'}}>Props</th>
                          <th className="fontInter fontSize13" style={{color:'#738196'}}>Sales Total</th>
                          <th className="fontInter fontSize13" style={{color:'#738196'}}>Ave .%</th>
                          <th className="fontInter fontSize13" style={{color:'#738196'}}>Total Comms</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.current_sales ?
                          <tr>
                            <td className="fontInter fontSize12 color12171D pad15">
                              <span>Last Month</span>
                            </td>
                            <td className="pad15">
                              <span className=" fontInter fontSize13 fontWeight600">{this.props.last_sales.propcnt}</span><br />
                            </td>
                            <td className="pad15">
                              <span className="fontInter fontSize12 fontWeight600">{this.props.last_sales.symbol}{this.props.last_sales.total}</span><br />
                            </td>
                            <td className="pad15">
                              <span className=" fontInter fontSize13 fontWeight600">{this.props.last_sales.avg_commission}%</span><br />
                            </td>
                            <td className="pad15">
                              <span className="fontInter fontSize12 fontWeight600">{this.props.last_sales.symbol}{ this.props.last_sales.commission } </span>
                            </td>
                          </tr>
                        : null}

                        {this.props.current_sales ?
                          <tr>
                            <td className="fontInter fontSize12 color12171D pad15">
                              <span>This Month</span>
                            </td>
                            <td className="pad15">
                              <span className=" fontInter fontSize13 fontWeight600">{this.props.current_sales.propcnt}</span><br />
                            </td>
                            <td className="pad15">
                              <span className="fontInter fontSize12 fontWeight600">{this.props.current_sales.symbol}{this.props.current_sales.total}</span><br />
                            </td>
                            <td className="pad15">
                              <span className=" fontInter fontSize13 fontWeight600">{this.props.current_sales.avg_commission}%</span><br />
                            </td>
                            <td className="pad15">
                              <span className="fontInter fontSize12 fontWeight600">{this.props.current_sales.symbol}{this.props.current_sales.commission_value} </span>
                            </td>
                          </tr>
                        : null}
                      </tbody>
                    </table>
                  </div>
                  <br/><br/>
                </div>
                {/* Revenue Data Ends Here */} 

                {/* Other Listing Data */}
                <div className = "other-listing scrollbar" style={{minHeight:550}}>
                  <div className = "row">
                    <div className = "col-md-12">
                      <ul className="nav nav-tabs other-listing mb20">
                        <li style={{ width: '50%' }} className="active fontInter">
                          <a  className="fontRoboto fontWeight600 fontSize17" data-toggle="tab" href="#home">Viewings</a>
                        </li>
                      </ul>
                      <div style={{overflowY:"auto", minHeight:350, maxHeight:450 , overflowX:"hidden"}}>
                        {Object.keys(other_listing).length !== 0 ?
                          <Accordion allowZeroExpanded="true">
                            {Object.keys(other_listing).map(function (outerKey) {
                              return (
                                <AccordionItem key={outerKey}>
                                  <AccordionItemHeading>
                                    <AccordionItemButton>
                                      {other_listing[outerKey][0].address} 
                                    </AccordionItemButton>
                                  </AccordionItemHeading>
                                  {other_listing[outerKey].map(function (innerKey) {
                                    return (
                                      <AccordionItemPanel key={innerKey.aapt_id}>
                                        <div className = "row">
                                          <div className="col-md-12" key={innerKey.aapt_id+'_agents'}>
                                            {innerKey.buyer_agent_image ?
                                              <img className="img-circle mb5" src={Constants.IMAGE_URL+innerKey.buyer_agent_image} alt="" width="32" height="32" />
                                            :
                                              <img className="img-circle" src="user-images/userDefault.png" alt="" width="32" height="32" />
                                            }
                                            <span className="fontSize14 ml10 color0F1D30 fontWeight500 fontInter">{innerKey.buyer_agent_name}</span>
                                            {Number(innerKey.property_type) === Number(1) ?
                                              <span className = "color738196 fontInter fontSize12 fontWeight500 ml5">Buyer's Agent</span>
                                              :
                                              <span className = "color738196 fontInter fontSize12 fontWeight500 ml5">Occupant's Agent</span>
                                            }
                                            <br/>

                                            {innerKey.seller_agent_image ?
                                              <img className="img-circle" src={Constants.IMAGE_URL+innerKey.seller_agent_image} alt="" width="32" height="32" />
                                            :
                                              <img className="img-circle" src="user-images/userDefault.png" alt="" width="32" height="32" />
                                            }
                                            <span className="fontSize14 ml10 color0F1D30 fontWeight500 fontInter">{innerKey.seller_agent_name}</span>
                                            {Number(innerKey.property_type) === Number(1) ?
                                              <span className = "color738196 fontInter fontSize12 fontWeight500 ml5">Seller's Agent</span>
                                            :
                                              <span className = "color738196 fontInter fontSize12 fontWeight500 ml5">Owner's Agent</span>
                                            }
                                            <br/>

                                            <span key={innerKey.appointment_date+'_time'} className = "color556378 fontInter fontSize12 ml40">
                                              <Moment format="D MMM YYYY HH:mm">{innerKey.appointment_date}</Moment> 
                                              {Number(innerKey.appointment_status_id) === Number(1) || Number(innerKey.appointment_status_id) === Number(5) ?
                                                <span key={innerKey.aapt_id+'_Pending'} style={{color:"#fff", backgroundColor:"#F2994A", padding:"4px 6px",borderRadius:4, fontSize:11, marginLeft:10 }}>Pending</span>
                                              : 
                                                [(Number(innerKey.appointment_status_id) === Number(2) ?
                                                  <span key={innerKey.aapt_id+'_Confirmed'} style={{color:"#fff", backgroundColor:"#4AA49C", padding:"4px 6px",borderRadius:4, fontSize:11, marginLeft:10 }}>Confirmed</span>
                                                :
                                                  [(Number(innerKey.appointment_status_id) === Number(3) ?
                                                    <span key={innerKey.aapt_id+'_Cancelled'} style={{color:"#fff", backgroundColor:"#EB5757", padding:"4px 6px",borderRadius:4, fontSize:11, marginLeft:10 }}>Cancelled</span>
                                                  :
                                                    <span key={innerKey.aapt_id+'_Completed'} style={{color:"#fff", backgroundColor:"#2D3E54", padding:"4px 6px",borderRadius:4, fontSize:11, marginLeft:10 }}>Completed</span>
                                                  )]
                                                )]
                                              }
                                            </span>

                                            <div className="mt10">
                                              <span className="fontSize12 ml10 color0F1D30  fontInter">
                                                <img src="images/buyer_arrow.png" alt="arrow_image" width="12"/>&nbsp;&nbsp;
                                                {innerKey.buyer_name}
                                              </span>
                                              <span className="fontSize12 ml10 color738196 fontWeight500  fontInter">
                                                Buyer
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </AccordionItemPanel>
                                    );
                                  })}
                                </AccordionItem>
                              );
                            })}
                          </Accordion>
                        :
                          <center><h4>No Data Available To Show</h4></center>
                        }
                      </div>
                    </div>
                  </div>
                </div>{/* Other Listing Ends Here */}               
                <br /><br />
              </div> {/* Right side section Ends Here */}

            </div>{/* Modal Body Ends Here */}
            {/* <div className="modal-footer">
            </div> */}
          </div>{/* Modal Content Ends Here */}
        </div>{/* Modal dialog Ends Here */}
      </div> {/* Modal Ends Here */}
      <div id="copied-success" className="copied">
        <span>Copied!</span>
      </div>
      {this.state.load_modal || !this.state.load_modal ?
        <AddProperty
          //agent_data = {this.props.agent_data}
          load_modal = {this.state.load_modal}
          agents_id = {this.state.agent_id}
          agent_list={this.props.agent_list}
        />
      : null}

      <StockPipelinePropertyModal agent_id = {this.props.agent_id}></StockPipelinePropertyModal>
      
      {/* Other Listing Property Modal*/}
      <OpenListingPropertyModal/>
      
      <CrmVerifiedModal/>
      <CrmNotVerifiedModal/>
      {this.state.selected_trip_data ?
        <TravelTrackingDetail
          selected_trip_data = {this.state.selected_trip_data}
          clear_selected_trip = {this.clear_trip }
        />
        : null
      }
      
    </div>
    ) 
  }
}

const mapDispatchToProps = dispatch => {
  return {
    get_agent_performance_data : (agent_id, token) => dispatch(actions.get_agent_performance_data(agent_id, token)),
    get_appointment_data_modal : (appointmentFromDate, appointmentToDate, todayDate, agent_id, token) => dispatch(actions.get_appointment_data_modal(appointmentFromDate, appointmentToDate, todayDate, agent_id, token)),
    get_general_chart_agent_modal: (appointmentFromDate, appointmentToDate, agent_id, token) => dispatch(actions.get_general_chart_agent_modal(appointmentFromDate, appointmentToDate, agent_id, token)),
    get_property_data_modal: (fromDate, toDate, prop_id, agent_id, token, date_sold, fromnetworking, property_notes_user_id) => dispatch(actions.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold, fromnetworking, property_notes_user_id)),
    get_property_data : (fromDate, toDate, agent_id, token, viewings_sort, recent_property) => dispatch(actions.get_property_data(fromDate, toDate, agent_id, token, viewings_sort, recent_property)),
    get_filtered_property_list : (fromDate, toDate, agent_id, token, viewings_sort, recent_property, client_properties, property_type)=> dispatch(actions.get_filtered_property_list(fromDate, toDate, agent_id, token, viewings_sort, recent_property, client_properties, property_type)),
    
    get_stock_pipeline_data : (page, is_archived, stock_pipeline_id, token) => dispatch(actions.get_stock_pipeline_data(page, is_archived, stock_pipeline_id, token)),
    get_stockpipeline_chart_by_id : (fromDate, toDate, agent_id, token) => dispatch(actions.get_stockpipeline_chart_by_id(fromDate, toDate, agent_id, token)),
    get_cold_call_logs_data : (page, date, token) => dispatch(actions.get_cold_call_logs_data(page, date, token)),
    get_coldcalls_chart_by_id : (fromDate, toDate, agent_id, token) => dispatch(actions.get_coldcalls_chart_by_id(fromDate, toDate, agent_id, token)),
    get_revenue_data_modal : (from_date, to_date, agent_id, token) => dispatch(actions.get_revenue_data_modal(from_date, to_date, agent_id, token)),
    get_other_listing_data : (agent_id, token) => dispatch(actions.get_other_listing_data(agent_id, token)),
    get_archived_property_data: (page, is_archived, type, token) => dispatch(actions.get_archived_property_data(page, is_archived, type, token)),
    get_networking_data: (agent_id, token) => dispatch(actions.get_networking_data(agent_id,token)),
    reset_stockpipeline_data: () => dispatch(actions.reset_stockpipeline_data()),
    //get authtoken for add property
    get_auth_data : (agent_id,token) => dispatch(actions.get_auth_data(agent_id,token)),
    get_all_agent_data_dd: (token)=> dispatch(actions.get_all_agent_data_dd(token)),
    update_add_property_modal_state: (add_property_modal_state) => dispatch(actions.update_add_property_modal_state(add_property_modal_state)),
    //get all agent data
    get_all_agent_data: (token)=> dispatch(actions.get_all_agent_data(token)),
    reset_calendar_data : () => dispatch(actions.reset_calendar_data()),
    reset_property_modal: () => dispatch(actions.reset_property_modal()),

    //set page for calendar
    set_page: (page, reset_date_range) => dispatch(actions.set_page(page, reset_date_range)),
    //set appointment state
    update_add_appointment_modal_state: (show_agents, show_property, from_property_page,from_stock_pipeline) => dispatch(actions.update_add_appointment_modal_state(show_agents, show_property, from_property_page,from_stock_pipeline)),
    
    //get_travel_log
    get_upcoming_trips:(authtoken,page) => dispatch(actions.get_upcoming_trips(authtoken,page)),
    get_past_trips:(authtoken, start_date, end_date, page) => dispatch(actions.get_past_trips(authtoken, start_date, end_date, page)),
    get_missed_trips:(authtoken,page) => dispatch(actions.get_missed_trips(authtoken,page)),
  }
}

const mapStateToProps = state => {
  return{
    token: state.auth.token,
    agent_performance: state.agent_modal.agent_performance,
    //properties tab
    agent_data: state.agent_modal.agent_data,
    agent_linked_user_cnt: state.agent_modal.linked_user_cnt,
    property_for_sale: state.agent_modal.property_for_sale,
    property_for_rent: state.agent_modal.property_for_rent,
    property_for_sale_total: state.agent_modal.property_for_sale_total,
    property_for_rent_total: state.agent_modal.property_for_rent_total,
    graph:state.agent_modal.graph,
    linked_user_cnt:state.agent_modal.linked_user_cnt,

    //appointment tab
    agentAppointmentData: state.agent_modal.agentAppointmentData,
    date_wise_appt: state.agent_modal.date_wise_appt,
    deleteEvents: state.agent_modal.deleteEvents,

    //stock pipeline 
    stock_pipeline_property_data: state.agent_modal.stock_pipeline_property_data,

    //revenue tab
    current_sales: state.agent_modal.current_sales,
    last_sales: state.agent_modal.last_sales,
    reve_sales: state.agent_modal.reve_sales,

    //other listing tab
    other_listing_data: state.agent_modal.other_listing_data,
    
    //agent mobile authtoken
    authtoken: state.add_property_modal.authtoken,

    //general appointment graph
    general_lables_agent_modal: state.agent_modal.general_lables_agent_modal,
    chart_general_count_agent_modal: state.agent_modal.chart_general_count_agent_modal,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AgentInformationModal);
