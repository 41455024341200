import React,{ Component } from "react";
class CrmVerifiedModal extends Component{
    render(){
        return(
            <div className="modal fade" data-backdrop="static" data-keyboard="false" id="crmVerifiedModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={this.setwrapperRef} >
                <div className="modal-dialog" role="document" ref={this.wrapperRef}>
                    <div className="modal-content modal_add_property modalheight" style ={{borderRadius : '12px' }} >
                        <div className="help_modal_header"> <h2>CRM Verified</h2></div>
                        <hr/>
                        <div className="help_modal_text">
                            <p>This listing is synced with your CRM system.</p>
                            <p>If both listings and contacts have been added to your Roundr app by syncing your CRM, viewing data added on Roundr will sync back to your CRM.</p>
                            <p>Pro tip: If you use a CRM system, don’t manually add contacts and properties to your Roundr app - sync them from your CRM system.</p>
                        </div>
                        <hr/>
                        <div data-dismiss="modal" style={{textAlign: "center"}}>
                            <button className="btn btn-primary fontBold" style={{ background:'#4AA49C',borderRadius:'4px', padding:'15px 30px 15px 30px'}}>
                                Dismiss
                            </button>
                        </div> 
                        <br/>
                    </div>
                </div>
            </div>
        )
    }
}
export default (CrmVerifiedModal)