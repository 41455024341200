import React ,  { Component } from 'react';
import {connect} from 'react-redux';
import * as actions from './store/actions/index';

import modal from './CustomFiles/js/modal';
import Header from  './components/menu/Header';
import Footer from './components/Footer';
import Helmet from "react-helmet";

import './CustomFiles/bower_components/bootstrap/dist/css/bootstrap.min.css';
import './CustomFiles/bower_components/font-awesome/css/font-awesome.min.css';
import './CustomFiles/bower_components/Ionicons/css/ionicons.min.css';
import './CustomFiles/dist/css/AdminLTE.min.css';
import './CustomFiles/plugins/iCheck/square/blue.css';

import './CustomFiles/bower_components/datatables.net-bs/css/dataTables.bootstrap.min.css';
import './CustomFiles/dist/css/skins/_all-skins.min.css';
import './CustomFiles/css/predefined.css';
import './CustomFiles/bower_components/bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';
import "react-datepicker/dist/react-datepicker.css";
import DataTable from 'react-data-table-component';

import Constants from './constants/Constants';
import $ from 'jquery';

import Loader from './components/Loader';
import PropertyDetailModal from './components/modals/PropertyDetailModal';
//icons
import { Icon } from '@iconify/react';
import downloadFill from '@iconify/icons-eva/download-fill';
import bxCalendarEvent from '@iconify/icons-bx/bx-calendar-event';

import AppointmentChart from './components/charts/AppointmentChart';
import GeneralChart from './components/charts/GeneralChart';
import SellerLinkedMessage from './components/modals/SellerLinkedMessage';
import AgentInformationModal from './components/modals/AgentInformationModal';
import IdleTimerContainer from './components/IdleTimerContainer';
import AgentList from './components/AgentList';
import ReactExport from "react-export-excel";
import AddAppointment from './components/modals/Appointment/AddAppointment';


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

var colorList = ['#7B8CA1','#EA676A','#63C8BA','#F8CA55','#F09A56','#6ADA8A', '#9B66E3', '#547DE5', '#B5A78D', '#DE6DA8',
  '#A368D9','#B43B1D','#229649','#2C3E50','#8c9966','#53F49B','#DC9D7D','#660E52','#39C9AE','#BCA7C4',
  '#D08E27','#A3DB7D','#606792','#11E797','#1DC1C1','#D5C175','#1DC1C1','#421B6C','#98E708','#EE2A09',  
  '#017C33','#24F080','#CC5A45','#801FD4','#04405D','#133123','#1B42B1','#7EC377','#2DE6E0','#9FCE27',
  '#F96CDD','#41197D','#4A809B','#794D61','#BA28E3','#183A8C','#D07848','#4F3B87','#3A8947','#100769',
  '#B7931E','#AA0A71','#417A92','#AC5B6C','#7E8F92','#C06EA7','#866E50','#561C56','#DB3DAF','#F03C44',
  '#90A8F4','#93AC64','#5B532B','#1CBB4B','#05A5A5','#CEC21E','#D3A8FF','#3F4D5E','#BD0B49','#2CA6A0',
  '#C7B541','#6D8D6B','#70C29B','#0B0BE2','#EE19DA','#588546','#DBB5A2','#609CBF','#38198D','#904B6C',
  '#8BD18C','#32638D','#792A4F','#7184E1','#35A50F','#4B3871','#8C8007','#4B2257','#089564','#F1A0C1',
  '#0D9168','#8CA06C','#0A7EB7','#AB53CB','#43246A','#2F0020','#06759B','#785646','#B36935','#1A7607',
];

var globalAgentList = [];
var globalAgentListName = [];
var globalAgentListImage = [];

//datatable
let data=[];
let columns = [];
let data_table_data = [];

const FilterComponent = ({ filterText, onFilter, onClear }) => (<>
  <label style={{ fontFamily: 'Roboto', fontSize: "14px", color: "#333333", fontWeight: "normal", marginTop:"7px", marginRight:"10px"}}>Search :</label>
  <input id="search" type="text" style={{border: "1px solid #daeeec", height: "28px"}} placeholder="Filter By Agent Name" aria-label="Search Input" value={filterText} onChange={onFilter} />
  </>
);

const customStyles = {
  rows: {
    style: {
      minHeight: '72px', // override the row height
    }
  },
  headCells: {
    style: {
      paddingLeft: '8px', // override the cell padding for head cells
      paddingRight: '8px',
      fontSize:'12px',
      color:"#4AA49C",
      fontFamily: 'Inter',
      fontWeight: 600,
      textAlign:'center',
      marginLeft:"20px"
    },
  },
  cells: {
    style: {
      paddingLeft: '18px', // override the cell padding for data cells
      paddingRight: '8px',
    },
  },
};

class Appointment extends Component{

  constructor(props){
    super(props);
    this.state = {      
      isLoading:false,
      agent_id:null,
      calenderStartDate : new Date(new Date().getFullYear(), 0, 1),
      calenderToDate : new Date(new Date().getFullYear(), 11, 31, 0, 0, 0),
      CalenderMinDate: new Date(new Date().getFullYear(), 0, 1),
      agent_list_data: null,
      globalAgentList: null,
      globalAgentListName: null,
      globalAgentListImage: null,

      togglebtn_viewing: true,
      togglebtn_general: false,
      viewing_graph_text: "Viewings",
      
      filterText: "",
      resetPaginationToggle: false
    }
    this.changeDateFormat = this.changeDateFormat.bind(this);
    this.handleShowModal = this.handleShowModal.bind(this);
  }

  getSubHeaderComponent = () => {
    return (
      <FilterComponent
        onFilter={(e) => {
          let newFilterText = e.target.value;
          this.filteredItems = data.filter(
            (item) =>
              item.agent_name &&
              item.agent_name.toLowerCase().includes(newFilterText.toLowerCase())
          );
          this.setState({ filterText: newFilterText });
        }}
        onClear={this.handleClear}
        filterText={this.state.filterText}
      />
    );
  };

  handleClear = () => {
    const { resetPaginationToggle } = this.state;

    if (this.state.filterText) {
      this.setState({
        resetPaginationToggle: !resetPaginationToggle,
        filterText: ""
      });
    }
  };

  renderAppointmentChart = () => {
    var appointmentChart;
    var agentList =  this.props.agent_list;
    var temp_chart_appt_count=[];
    temp_chart_appt_count = JSON.parse(JSON.stringify(this.props.chart_appt_count));
    if(agentList && temp_chart_appt_count){
      globalAgentListName =[];
      for( let i = 0; i < agentList.length ; i++ ){
        //adding agents with tracking on
        if(Number(agentList[i].tracking_prefrence) !== Number(0) ){
          var agentName = agentList[i].firstname + ' ' + agentList[i].lastname;
          //var color = "#" + ((1 << 24) * Math.random() | 0).toString(16);
          var color  =  colorList[i];
          globalAgentListName.push({
            agent_id: agentList[i].user_id,
            name: agentName,
            color: color,
            image:agentList[i].image
          });
          globalAgentList.push(agentList[i].user_id);
          globalAgentListImage.push(agentList[i].image);
        }else{
          //Removing graph data of agents with tracking off 
          delete temp_chart_appt_count[0][agentList[i].userid];
        }
      }

      if (this.props.appt_lables && this.props.agent_list) {
        appointmentChart = (<AppointmentChart
          globalAgentListName={globalAgentListName}
          globalAgentListImage={globalAgentListImage}
          globalAgentList={globalAgentList} overview="0"
          total_agent={this.props.agent_list.length} appt_lables={this.props.appt_lables} chart_appt_count={temp_chart_appt_count} />);
        return appointmentChart;
      } 
    }else {
      appointmentChart = (<Loader />);
      return appointmentChart;
    }
  }

  renderGeneralChart = () => {
    var generalChart;
    var agentList =  this.props.agent_list;
    var temp_chart_general_count=[];
    temp_chart_general_count = JSON.parse(JSON.stringify(this.props.chart_general_count));
    if(agentList && temp_chart_general_count){
      globalAgentListName =[];
      for( let i = 0; i < agentList.length ; i++ ){
        //adding agents with tracking on
        if(Number(agentList[i].tracking_prefrence) !== Number(0) ){
          var agentName = agentList[i].firstname + ' ' + agentList[i].lastname;
          //var color = "#" + ((1 << 24) * Math.random() | 0).toString(16);
          var color  =  colorList[i];
          globalAgentListName.push({
            agent_id: agentList[i].user_id,
            name: agentName,
            color: color,
            image:agentList[i].image
          });
          globalAgentList.push(agentList[i].user_id);
          globalAgentListImage.push(agentList[i].image);
        }else{
          //Removing graph data of agents with tracking off 
          delete temp_chart_general_count[0][agentList[i].userid];
        }
      }

      if (this.props.general_lables && this.props.agent_list) {
        generalChart = (<GeneralChart
          globalAgentListName={globalAgentListName}
          globalAgentListImage={globalAgentListImage}
          globalAgentList={globalAgentList} overview="0"
          total_agent={this.props.agent_list.length} appt_lables={this.props.general_lables} chart_appt_count={temp_chart_general_count} />);
        return generalChart;
      } 
    }else {
      generalChart = (<Loader />);
      return generalChart;
    }
  }

  handlePropertyModal = async (prop_id, date_sold, created_user_id, from_archiveProperty) =>{
    this.props.reset_property_modal();
    const show_agents = 0
    const show_property = 0
    const from_property_page = 1
    const from_stock_pipeline = 0
    await this.props.update_add_appointment_modal_state(show_agents,show_property,from_property_page,from_stock_pipeline)
    // var fromDate = new Date("1/1/" + (new Date()).getFullYear());
    // var toDate = new Date(new Date().getFullYear(), 11, 31, 0, 0, 0);

    // var d = new Date(fromDate),
    // month = '' + (d.getMonth() + 1),
    // day = '' + d.getDate(),
    // year = d.getFullYear();

    // if (month.length < 2) month = '0' + month;
    // if (day.length < 2) day = '0' + day;
    // fromDate = [year, month, day].join('-');

    // d = new Date(toDate);
    // month = '' + (d.getMonth() + 1);
    // day = '' + d.getDate();
    // year = d.getFullYear();

    // if (month.length < 2) month = '0' + month;
    // if (day.length < 2) day = '0' + day;
    // toDate = [year, month, day].join('-');
    var da = new Date();
    var fromDate = this.changeDateFormat(new Date(da.getFullYear(), da.getMonth() - 2, 1));
    var toDate = this.changeDateFormat(new Date(da.getFullYear(), da.getMonth(), da.getDate()));

    const token = localStorage.getItem('token');
    if(created_user_id){
      var property_notes_user_id = this.state.agent_id;
      let agent_id = created_user_id;
      let fromnetworking = 1;
      await this.props.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold, fromnetworking, property_notes_user_id);
    }else if(from_archiveProperty){
      let agent_id = this.state.agent_id;
      await this.props.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold, null, null, from_archiveProperty);
    }else{
      let agent_id = this.state.agent_id;
      await this.props.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold);
    }
    this.props.set_page("property_modal", true);
    window.$("#profileModal").modal('show');
  }

  handleShowModal = (event ) =>{
    this.props.reset_agent_modal();
    const agent_id  =  event.target.id;

    this.setState({
      agent_id: agent_id,
    },()=> {
      window.$("#userProfileModal").modal('show');
      $(".user-modal-overview").click();
    })
  }

  changeDateFormat(fromDate){
    var d = new Date(fromDate),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year,month,day].join('-');
  }

  changeDateFormat_DMY(fromDate){
    var d = new Date(fromDate),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [day,month,year].join('-');
  }

  toDateOverview = (e) =>{
    const token     = localStorage.getItem('token');

    var fromDate  = null;
    var toDate    = null;

    fromDate  =  this.changeDateFormat(this.state.calenderStartDate);
    toDate    =  this.changeDateFormat(e.value);

    this.setState({
      isLoading:true,
      calenderToDate:e.value
    })
    // Appointment Agent List
    this.props.get_filtered_agent_list_appointment(fromDate, toDate, token);

    // Appointment Chart
    let agent_list = this.props.agent_list;
    this.props.get_appointment_chart_appointment(fromDate, toDate, agent_list, token);
    this.props.get_general_chart_appointment(fromDate, toDate, agent_list, token);
      
    this.setState({
      isLoading:false
    })
  }
  
  async componentDidMount (){
    modal();
    const token = localStorage.getItem('token');
    if( token   === '' || token === null ){
      localStorage.clear();
      this.props.history.push("/");
      return;
    }
    //const office_id = localStorage.getItem('office_id');
    
    //await this.props.get_agent_list_appointment(office_id, token);

    /* Appointment chart */
    // var fromDate = new Date("1/1/" + (new Date()).getFullYear());
    // var toDate = new Date(new Date().getFullYear(), 11, 31,0,0,0);

    // fromDate =  this.changeDateFormat(fromDate);
    // toDate =  this.changeDateFormat(toDate);

    var da = new Date();
    var fromDate = this.changeDateFormat(new Date(da.getFullYear(), da.getMonth() - 2, 1));
    var toDate = this.changeDateFormat(new Date(da.getFullYear(), da.getMonth(), da.getDate()));

    
    this.setState({
      agent_list_data: [...JSON.parse(localStorage.getItem('data'))]
    },()=>{
      this.props.get_filtered_agent_list_appointment(fromDate, toDate, token);
      this.props.get_appointment_chart_appointment(fromDate, toDate, this.state.agent_list_data, token);
      this.props.get_general_chart_appointment(fromDate, toDate, this.state.agent_list_data, token);
    })
    this.props.set_page("viewings_page", true);
  }

  dateWisePropertyAppointment = (fromDate,toDate,prop_id,agent_id, date_sold, fromnetworking) => {
    fromDate = this.changeDateFormat(fromDate);
    toDate = this.changeDateFormat(toDate);

    const token = localStorage.getItem('token');
    if(Number(fromnetworking) === 1){
      var property_notes_user_id = this.state.agent_id;
      let agent_id = this.props.property_detail.agent_id;
      let fromnetworking = 1;
      this.props.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold, fromnetworking, property_notes_user_id)
    }else{
      this.props.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold)
    }

  }

  callbackFunction = (globalAgentList, globalAgentListName, globalAgentListImage) => {      
    this.setState({
      globalAgentList: [...globalAgentList],
      globalAgentListName: [...globalAgentListName ],
      globalAgentListImage: [...globalAgentListImage]
    })
  }
  
  toggle_viewing_graph = (id) =>{
    if(id === "togglebtn_viewing"){
      this.setState({
        togglebtn_viewing: true,
        togglebtn_general: false,
        viewing_graph_text: "Viewings"
      })  
    }else if(id === "togglebtn_general"){
      this.setState({
        togglebtn_viewing: false,
        togglebtn_general: true,
        viewing_graph_text: "General"
      })
    }
  }

  handleAddAppointment = async () =>{
    const show_agents = 0 //0 - new appointment from page,
    const show_property = 1
    const from_property_page = 0
    const from_stock_pipeline = 0
    this.setState({agent_id: null},
    async () => {
      await this.props.update_add_appointment_modal_state(show_agents,show_property,from_property_page,from_stock_pipeline)
      window.$("#addAppointmentModal").modal('show');
    })
  }

  render(){
    let scheduled_appointment = this.props.count? this.props.count.scheduled_appointment : null;
    let complete_appointment = this.props.count? this.props.count.complete_appointment : null;
    let cancelled_appointment = this.props.count? this.props.count.cancelled_appointment : null;
    let feedback_to_send = this.props.count? this.props.count.feedback_to_send : null;
    let feedback_send = this.props.count? this.props.count.feedback_send : null;

    columns = [
      {
        name: 'Agents',
        selector: 'agent_name',
        sortable: true,
        ignoreRowClick: true,
        // onClick={window.handleShowModal(`${row.user_id}`)}
        style: {marginLeft: '15px'},
        cell: row => <div tag="allowRowEvents" id={`${row.user_id}`} onClick={Number(row.tracking) !== 0 ? this.handleShowModal : null} className={Number(row.tracking) !== 0 ? "cursorPointer": null}> 
                        <img tag="allowRowEvents" id={`${row.user_id}`} alt='' src={row.image ? Constants.IMAGE_URL+row.image : "user-images/userDefault.png"} className="img-circle" width="24" height="24" />
                        <span tag="allowRowEvents" id={`${row.user_id}`} className="color2D3E54 fontWeight600" style={{marginLeft: 5, fontFamily: "Inter", textDecoration: "underline"}}>{row.firstname} {row.lastname}</span> 
                        {Number(row.tracking) === 0 ? <span tag="allowRowEvents" className="trackingspan">Tracking Off</span> : null }
                      </div>
      },
      {
        name: scheduled_appointment === 0 ? "Scheduled (0)" : 'Scheduled ('+scheduled_appointment+')',
        selector: 'scheduled_appointment',
        sortable: true,
        center: true
      },
      {
        name: complete_appointment === 0 ? "Completed (0)" : 'Completed ('+complete_appointment+')',
        selector: 'complete_appointment',
        sortable: true,
        center: true
      },
      {
        name: cancelled_appointment === 0 ? "Canceled (0)" : 'Canceled ('+cancelled_appointment+')',
        selector: 'cancelled_appointment',
        sortable: true,
        center: true
      },
      {
        name: feedback_to_send === 0 ? "Feedback to send (0)" : 'Feedback to send ('+feedback_to_send+')',
        selector: 'feedback_to_send',
        sortable: true,
        center: true
      },
      {
        name: feedback_send === 0 ? "Feedback sent (0)" : 'Feedback sent ('+feedback_send+')',
        selector: 'feedback_send',
        sortable: true,
        center: true
      },
    ];

    if(this.props.agent_list){
      data =
        this.props.agent_list.map((data, index) => (
        {
          user_id:                data.user_id,
          tracking:               data.tracking_prefrence,
          firstname:              data.firstname,
          lastname:               data.lastname,
          image:                  data.image,
          agent_name:             data.firstname +" "+ data.lastname,
          scheduled_appointment:  Number(data.tracking_prefrence) === 0 ? 0 : !data.scheduled_appointment ? 0 : Number(data.scheduled_appointment),
          complete_appointment:   Number(data.tracking_prefrence) === 0 ? 0 : !data.complete_appointment  ? 0 : Number(data.complete_appointment),
          cancelled_appointment:  Number(data.tracking_prefrence) === 0 ? 0 : !data.cancelled_appointment ? 0 : Number(data.cancelled_appointment),
          feedback_to_send:       Number(data.tracking_prefrence) === 0 ? 0 : !data.feedback_to_send      ? 0 : Number(data.feedback_to_send),
          feedback_send:          Number(data.tracking_prefrence) === 0 ? 0 : !data.feedback_send         ? 0 : Number(data.feedback_send),
        }
        
      ))  
    }
      
    this.filteredItems = data.filter(
      (item) =>
      item.agent_name && item.agent_name.toLowerCase().includes(this.state.filterText.toLowerCase())
    );
    data_table_data = this.filteredItems

    let footer= "footer_color";
    if (window.innerWidth > document.body.clientWidth) {
      footer = 'footer';
    }
    var sellerLinkedMessage = null;
    sellerLinkedMessage = (<SellerLinkedMessage property_detail={this.props.property_detail} />);

    var appt_sum = 0;
    var general_sum = 0;
    var agents  =  this.props.agent_list;

    if(this.props.chart_appt_count){
      if(this.props.agent_list){
        for(let a=0 ; a < this.props.agent_list.length;a++){
          var agentId = agents[a].id;
          var dataSetsChart = (this.props.chart_appt_count[0][agentId])
          if(dataSetsChart){
            for(let b = 0; b < dataSetsChart.length; b++){
              appt_sum = appt_sum + parseInt(dataSetsChart[b]);
            }
          }
        }
      }
    }

    if(this.props.chart_general_count){
      if(this.props.agent_list){
        for(let a=0 ; a < this.props.agent_list.length;a++){
          agentId = agents[a].id;
          dataSetsChart = (this.props.chart_general_count[0][agentId])
          if(dataSetsChart){
            for(let b = 0; b < dataSetsChart.length; b++){
              general_sum = general_sum + parseInt(dataSetsChart[b]);
            }
          }
        }
      }
    }

    let file_name  = "Viewings Data From- "+ this.changeDateFormat_DMY(this.props.page_from_date) +" To- "+ this.changeDateFormat_DMY(this.props.page_to_date);
    let sheet_name = "Report From- "+ this.changeDateFormat_DMY(this.props.page_from_date) +" To- "+this.changeDateFormat_DMY(this.props.page_to_date);

    return(
      <div style={{fontSize:'14px'}}>
        <IdleTimerContainer></IdleTimerContainer>
        <Helmet>
          <title>Roundr - Viewings</title>
          <link rel="icon" href="https://roundr.app/wp-content/uploads/2018/03/favicon.png" type="image/png"></link>
        </Helmet>
        <Header active = "appointment"/>
        <div className="content-wrapper" style={{marginLeft:'0px' }}>
          {this.state.agent_list_data ?
            <AgentList 
              parentCallback = {this.callbackFunction}
              agent_list={this.state.agent_list_data}></AgentList>
            : null
          }
          <section className="content">
            <div className = "row">
              <div className="col-xs-12 col-md-12 col-lg-12">
                <div className="box">
                  <div className="box-header ml20">
                    <img src="images/side_prop_icon.png" width="24" height="19" alt="" />
                    <h3 className="box-title fontSize17 ml15 mt15 color0F1D30 fontWeight500 fontInter">Viewings&nbsp;</h3>
                      <div style={{display:'inline-block', float:'right'}}>
                        <button className={this.state.togglebtn_viewing ? "btn btn-primary mr10 mt5" : "btn btn-primary mr10 mt5 fadebutton"} style={{ background:'#27AFAF', border: '0px',borderRadius:'4px'}} id="togglebtn_viewing" onClick={() => this.toggle_viewing_graph("togglebtn_viewing")}> Viewings </button>
                        <button className={this.state.togglebtn_general ? "btn btn-primary mr10 mt5" : "btn btn-primary mr10 mt5 fadebutton"} style={{ background:'#F09A56', border: '0px',borderRadius:'4px'}} id="togglebtn_general" onClick={() => this.toggle_viewing_graph("togglebtn_general")}>  General </button>
                        <span className="fontSize15 fontInter color8591A0 mt10">Current Total
                          &nbsp;&nbsp;
                          <b>{this.state.togglebtn_viewing ? appt_sum : general_sum}</b>
                        </span>
                    </div>
                  </div>
                  <hr style={{ borderTop: '1px solid #DAEEEC !important' }} />
                  <div className="box-body table-responsive agentListingTable">
                    {this.state.isLoading ? 
                      <center><img src="images/loader.svg" width="50" height="50" alt="" /></center> :null 
                    }
                    {this.state.togglebtn_viewing ?
                      this.props.agent_list ?
                        this.renderAppointmentChart() : null
                        :
                      this.props.agent_list ?
                        this.renderGeneralChart() : null
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <div className="box">
                  <div className="box-header">
                    <div className = "row">
                      <div className = "col-md-6 padTop10 padLeft45">
                        <img src= "images/side_prop_icon.png" width="24" height="19" alt=""/>
                        <span className = "fontSize22 padTop5 padLeft10"> Viewings</span>
                      </div>
                      <div className="col-md-3"></div>
                      <div className="col-md-3">
                        <button type="submit" className="btn btn-primary fontBold mt5" style={{ background:'#4AA49C', borderRadius:'4px', marginRight:30, padding:"6.3px 12px"}} onClick={() => {this.clickChild(); this.handleAddAppointment()}}> 
                          <Icon className="message_icon mr5" style={{verticalAlign:'bottom'}} icon={bxCalendarEvent} height='20' width='20' />
                            Add Appointment
                        </button>
                        <div id="excel_report" style={{float:'Right', marginTop: '5px'}}>
                          <ExcelFile filename={file_name} element={<button className="btn btn-primary fontBold" style={{ background:'#4AA49C', borderRadius:'4px'}}> <Icon className="message_icon" icon={downloadFill} height='20' width='20'/> Export to Excel</button>}>
                            <ExcelSheet data={data_table_data} name={sheet_name}>
                              <ExcelColumn label="Agent Name" value={(col) => col.firstname + " " + col.lastname }/>
                              <ExcelColumn label="Tracking" value={(col) => col.tracking ? "ON" : "OFF"}/>
                              <ExcelColumn label="Scheduled" value="scheduled_appointment"/>
                              <ExcelColumn label="completed" value="complete_appointment"/>
                              <ExcelColumn label="Canceled" value="cancelled_appointment"/>
                              <ExcelColumn label="Feedback To Send" value="feedback_to_send"/>
                              <ExcelColumn label="Feedback Sent" value="feedback_send"/>
                            </ExcelSheet>
                          </ExcelFile>
                        </div>
                      </div>
                    </div> {/* End Row Here  */}
                  </div>
                  <center>
                  {this.state.isLoading ? 
                    <img src="images/loader.svg" width="50" height="50" alt="" /> :null }
                  </center>

                  <div>
                    <DataTable
                      noHeader={true}
                      highlightOnHover={true}
                      pagination={true}
                      // progressPending="true"
                      // progressComponent={<Loader />}
                      subHeader
                      subHeaderComponent={this.getSubHeaderComponent()}
                      columns={columns}
                      data={this.filteredItems}
                      customStyles={customStyles}
                      ignoreRowClick={true}
                      defaultSortField={'agent_name'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          { /* Agent Information Modal */ }
          <AgentInformationModal 
            agent_id = { this.state.agent_id}
            handlePropertyModal = { this.handlePropertyModal }
          />

          <PropertyDetailModal
            dateWisePropertyAppointment = { this.dateWisePropertyAppointment }
            property_detail={this.props.property_detail}
            appointment_cnt={this.props.appointment_cnt}
            general_cnt_property_modal= {this.props.general_cnt_property_modal}
            feedback={this.props.feedback}
            seller_message={this.props.seller_message}
            occupant_message={this.props.occupant_message}
            agent_data={this.props.agent_data}
            sellerLinkedMessage={sellerLinkedMessage}
            agent_id    = { this.state.agent_id }
            html_export={this.props.html_export}
            property_notes_html={this.props.property_notes_html}
            office_notes_html={this.props.office_notes_html}
          />
          <AddAppointment
            agent_data = {this.props.agent_data}
            agent_id = {this.state.agent_id}
            setClick={click => this.clickChild = click}
          />

        </div>
        <Footer footer={footer}></Footer>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    get_agent_list_appointment : (office_id, token) => dispatch(actions.get_agent_list_appointment(office_id, token)),
    get_appointment_chart_appointment: (fromDate, toDate, agent_list, token) => dispatch(actions.get_appointment_chart_appointment(fromDate, toDate, agent_list, token)),
    get_general_chart_appointment: (fromDate, toDate, agent_list, token) => dispatch(actions.get_general_chart_appointment(fromDate, toDate, agent_list, token)),
    get_filtered_agent_list_appointment: (fromDate, toDate, token) => dispatch(actions.get_filtered_agent_list_appointment(fromDate, toDate, token)),
    get_property_data_modal: (fromDate, toDate, prop_id, agent_id, token, date_sold, fromnetworking, property_notes_user_id, from_archiveProperty) => dispatch(actions.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold, fromnetworking, property_notes_user_id, from_archiveProperty)),
    reset_agent_modal: () => dispatch(actions.reset_agent_modal()),
    reset_property_modal: () => dispatch(actions.reset_property_modal()),
    //set page for calendar
    set_page: (page, reset_date_range) => dispatch(actions.set_page(page, reset_date_range)),
    //set appointment state
    update_add_appointment_modal_state: (show_agents,show_property,from_property_page,from_stock_pipeline) => dispatch(actions.update_add_appointment_modal_state(show_agents,show_property,from_property_page,from_stock_pipeline)) 
  };
}

const mapStateToProps = state => {
  return{
    token: state.auth.token,

    agent_list: state.appointment.agent_list_appointment,
    count: state.appointment.count,

    page_from_date: state.overview.page_from_date,
    page_to_date: state.overview.page_to_date,

    appt_lables: state.appointment.appt_lables_appointment,
    chart_appt_count: state.appointment.chart_appt_count_appointment,

    general_lables: state.appointment.general_lables_appointment,
    chart_general_count: state.appointment.chart_general_count_appointment,

    //property_modal
    property_detail: state.property_modal.property_detail,
    appointment_cnt: state.property_modal.appointment_cnt,
    general_cnt_property_modal: state.property_modal.general_cnt_property_modal,
    feedback: state.property_modal.feedback,
    seller_message: state.property_modal.seller_message,
    occupant_message: state.property_modal.occupant_message,
    html_export :state.property_modal.html_export,
    property_notes_html: state.property_modal.property_notes_html,
    office_notes_html: state.property_modal.office_notes_html,

    //agent_modal
    agent_data: state.agent_modal.agent_data
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Appointment);
