import * as actionTypes from './actionsTypes';
import Constants from '../../constants/Constants';
import axios from 'axios';

var myHeaders = '';
var raw = '';
var requestOptions = '';
var url='';

export const get_agent_list_appointment = (office_id, token) => {
    return async dispatch =>{
        await axios.post(Constants.APPOINTMENT_LISTING_API_URL,{
            token: token,
            office_id: office_id},
            {headers: {
                Authorization: token 
            }
        })
        .then(data => {
            dispatch(agent_list_appointment(data.data));
        })
        .catch(error => console.log('error', error)); 
    };
}
export const agent_list_appointment = (response) => {
    return{
        type: actionTypes.GET_AGENT_LIST_APPOINTMENT,
        agent_list: response.data,
        count: response.count
    }
}

export const get_appointment_chart_appointment = (fromDate, toDate, agent_list, token)=>{
    return dispatch =>{
        url = Constants.APPOINTMENT_CHART;
        myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token);
        raw = JSON.stringify(
        {
            "from": fromDate,
            "to": toDate
        });
        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(url, requestOptions)
        .then(response => response.json())
        .then(data => {
            //deleting data of user whose tracking is off
            for(let i = 0; i < agent_list.length; i++){
                if(Number(agent_list[i].tracking_prefrence) === Number(0) ){ 
                    delete data.data.appt_cnt[0][agent_list[i].user_id];
                }
            }
            dispatch(appointment_chart_appointment(data));
        })
        .catch(error => console.log('error', error));
    }
}
export const appointment_chart_appointment = (data) => {
    return{
        type: actionTypes.GET_APPOINTMENT_CHART,
        appt_lables: data.data.labels,
        chart_appt_count: data.data.appt_cnt,
        temp_chart_appointment_count : data.data.appt_cnt
    }
}

export const get_general_chart_appointment = (fromDate, toDate, agent_list, token)=>{
    return dispatch =>{
        myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token);
        raw = JSON.stringify(
        {
            "from": fromDate,
            "to": toDate
        });
        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(Constants.GENERAL_CHART, requestOptions)
        .then(response => response.json())
        .then(data => {
            //deleting data of user whose tracking is off
            for(let i = 0; i < agent_list.length; i++){
                if(Number(agent_list[i].tracking_prefrence) === Number(0) ){ 
                    delete data.data.appt_cnt[0][agent_list[i].user_id];
                }
            }
            dispatch(general_chart_appointment(data));
        })
        .catch(error => console.log('error', error));
    }
}
export const general_chart_appointment = (data) => {
    return{
        type: actionTypes.GET_GENERAL_CHART,
        general_lables: data.data.labels,
        chart_general_count: data.data.appt_cnt,
        temp_chart_general_count : data.data.appt_cnt
    }
}

export const get_filtered_agent_list_appointment = (fromDate, toDate, token) => {
    return dispatch =>{
        const url= Constants.APPOINTMENT_FILTER;
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization",token);
        var raw = JSON.stringify({"token":token,'from':fromDate,"to":toDate});
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(url, requestOptions)
        .then(response => response.json())
        .then(data => {
            dispatch(filtered_agent_list_appointment(data));
        })
        .catch(error => console.log('error', error));
    }
}
export const filtered_agent_list_appointment = (response)=>{
    return{
        type: actionTypes.GET_FILTERED_AGENT_LIST_APPOINTMENT,
        agent_list  :  response.data,
        count : response.count,
    }
}

export const toggle_appointment_page_chart = (agent_id) =>{
    return{
        type: actionTypes.TOGGLE_APPOINTMENT_PAGE_CHART,
        agent_id: agent_id
    }
}
export const toggle_appointment_page_general_chart = (agent_id) =>{
    return{
        type: actionTypes.TOGGLE_APPOINTMENT_PAGE_GENERAL_CHART,
        agent_id: agent_id
    }
}