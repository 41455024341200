import React ,  { Component } from 'react';

class ErrorText extends Component {

    render(){
        return(<div>
                    <div className = "alert alert-danger text-center font d-inline-block text-truncate">
                    <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {this.props.errorMsg}
                    </div>
              </div>)
    }

}

export default ErrorText;


