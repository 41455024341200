import React from 'react';
import {Line} from 'react-chartjs-2';


class  AgentStockPipelineChartById extends React.Component {
  render() {
    var charts = null;
    if(typeof this.props.agent_prop_cnt === 'undefined'){
    }else{
      charts =  this.props.agent_prop_cnt;
    }

    var labels = null;
    if(typeof this.props.labels === 'undefined'){
    }else{
      labels =  this.props.labels;
    }

    const state = {
      labels: labels,
      datasets: [
        {
          label: 'Total Props',
          fill: false,
          lineTension: 0,
          backgroundColor: 'rgba(75,192,192,1)',
          borderColor: '#27AFAF',
          borderWidth: 2,
          data: charts
        }
      ]
    }
    


    return (
      
      <div>
        <Line
          data={state}
          options={{
            title:{
              display:false,
            },
            legend:{
              display:false,
              backgroundColor: 'rgba(75,192,192,1)',
              borderWidth: 2,
            },
            scales: {
              yAxes: [{
                display: true,
                ticks: {
                  beginAtZero: true,
                  userCallback: function(label, index, labels) {
                    if (Math.floor(label) === label) {
                        return label;
                    }
                  },
                }
              }]
            }
          }}
        />
      </div>
    );
  }
}

export default AgentStockPipelineChartById