//login
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const UPDATE_EXPIRE = 'UPDATE_EXPIRE';
export const AUTH_LOGOUT ='AUTH_LOGOUT';

//overview data
export const GET_OVERVIEW_DATA = "GET_OVERVIEW_DATA";
export const GET_OVERVIEW_DATA_WITH_DATE = "GET_OVERVIEW_DATA_WITH_DATE";
export const CHANGE_FROM_TO_DATE = "CHANGE_FROM_TO_DATE";
    //toogle chart
export const TOGGLE_PROPERTY_CHART      = "TOGGLE_PROPERTY_CHART";
export const TOGGLE_PROPERTY_SALE_CHART = "TOGGLE_PROPERTY_SALE_CHART";
export const TOGGLE_PROPERTY_SOLD_CHART = "TOGGLE_PROPERTY_SOLD_CHART";
export const TOGGLE_STOCKPIPELINE_CHART = "TOGGLE_STOCKPIPELINE_CHART";
export const TOGGLE_APPOINTMENT_CHART   = "TOGGLE_APPOINTMENT_CHART";
export const TOGGLE_GENERAL_CHART       = "TOGGLE_GENERAL_CHART";
export const TOGGLE_SALES_CHART         = "TOGGLE_SALES_CHART";
export const TOGGLE_COLDCALLS_CHART     = "TOGGLE_COLDCALLS_CHART";
export const TOGGLE_REVENUE_CHART       = "TOGGLE_REVENUE_CHART";
export const TOGGLE_RENTAL_REVENUE_CHART = "TOGGLE_RENTAL_REVENUE_CHART";
export const TOGGLE_PROFITABILITY_CHART = "TOGGLE_PROFITABILITY_CHART";

//charts
export const GET_PROPERTY_CHART = "GET_PROPERTY_CHART";
export const GET_PROPERTY_SALE_CHART = "GET_PROPERTY_SALE_CHART";
export const GET_PROPERTY_SOLD_CHART = "GET_PROPERTY_SOLD_CHART";
export const GET_STOCKPIPELINE_CHART = "GET_STOCKPIPELINE_CHART";
export const GET_APPOINTMENT_CHART = "GET_APPOINTMENT_CHART";
export const GET_GENERAL_CHART     = "GET_GENERAL_CHART";
export const GET_SALES_CHART = "GET_SALES_CHART";
export const GET_COLDCALLS_CHART = "GET_COLDCALLS_CHART";
export const GET_REVENUE_CHART = "GET_REVENUE_CHART";
export const GET_RENTAL_REVENUE_CHART = "GET_RENTAL_REVENUE_CHART";
export const GET_PROFITABILITY_CHART = "GET_PROFITABILITY_CHART";

//property page
export const GET_PROPERTY_LIST = "GET_PROPERTY_LIST";
export const GET_AGENT_LIST = "GET_AGENT_LIST";
export const SET_FILTER_BY = "SET_FILTER_BY";

//appointment page
export const GET_AGENT_LIST_APPOINTMENT = "GET_AGENT_LIST_APPOINTMENT";
export const GET_FILTERED_AGENT_LIST_APPOINTMENT = "GET_FILTERED_AGENT_LIST_APPOINTMENT";

//revenue page
export const GET_REVENUE_AGENT_LIST = "GET_REVENUE_AGENT_LIST";

//compare page
export const GET_COMPARE_AGENT = "GET_COMPARE_AGENT";

//agent modal
export const GET_AGENT_PERFORMANCE_DATA = "GET_AGENT_PERFORMANCE_DATA";
export const GET_APPOINTMENT_DATA_MODAL = "GET_APPOINTMENT_DATA_MODAL";
export const GET_PROPERTY_DATA = "GET_PROPERTY_DATA";
export const FILTER_PROPERTY_LIST = "FILTER_PROPERTY_LIST";
export const GET_STOCK_PIPELINE = "GET_STOCK_PIPELINE";
export const GET_STOCK_PIPELINE_PROPERTY_DATA = "GET_STOCK_PIPELINE_PROPERTY_DATA";
export const GET_COLD_CALL_LOGS = "GET_COLD_CALL_LOGS";
export const GET_COLD_CALL_LOGS_REPORTS = "GET_COLD_CALL_LOGS_REPORTS";
export const GET_APPOINTMENT_BY_DATE_MODAL = "GET_APPOINTMENT_BY_DATE_MODAL";
export const GET_GENERAL_CHART_AGENT_MODAL = "GET_GENERAL_CHART_AGENT_MODAL";
export const GET_REVENUE_DATA_MODAL = "GET_REVENUE_DATA_MODAL";
export const GET_OTHER_LISTING_DATA = "GET_OTHER_LISTING_DATA";
export const GET_ALL_AGENT_DATA = "GET_ALL_AGENT_DATA";
export const RESET_AGENT_MODAL = "RESET_AGENT_MODAL";
export const GET_STOCKPIPELINE_CHART_BY_ID = "GET_STOCKPIPELINE_CHART_BY_ID";
export const GET_COLDCALLS_CHART_BY_ID = "GET_COLDCALLS_CHART_BY_ID";
export const GET_ARCHIVED_PROPERTY = "GET_ARCHIVED_PROPERTY";
export const GET_NETWORKING_PROPERTY = "GET_NETWORKING_PROPERTY"
export const RESET_STOCK_PIPELINE_MODAL = "RESET_STOCK_PIPELINE_MODAL";
export const RESET_STOCKPIPELINE_DATA = "RESET_STOCKPIPELINE_DATA";
export const RESET_CALENDAR_DATA = "RESET_CALENDAR_DATA";
export const SET_PAGE = "SET_PAGE";
export const GET_UPCOMING_TRIPS = "GET_UPCOMING_TRIPS"
export const GET_PAST_TRIPS = "GET_PAST_TRIPS"
export const GET_MISSED_TRIPS = "GET_MISSED_TRIPS"
export const GET_TRAVEL_REPORT = "GET_TRAVEL_REPORT"

//property modal
export const GET_PROPERTY_DATA_MODAL = "GET_PROPERTY_DATA_MODAL";
export const RESET_PROPERTY_MODAL = "RESET_PROPERTY_MODAL";

//add property modal
export const GET_AUTH_DATA = 'GET_AUTH_DATA';
export const ADD_PROPERTY = 'ADD_PROPERTY';
export const GET_EDIT_PROPERTY_DATA = "GET_EDIT_PROPERTY_DATA";
export const UPDATE_ADD_PROPERTY_MODAL_STATE = "UPDATE_ADD_PROPERTY_MODAL_STATE";
export const EDIT_PROPERTY = 'EDIT_PROPERTY';
export const UPDATE_ADD_PROPERTY_SUCCESS_MODAL_STATE = "UPDATE_ADD_PROPERTY_SUCCESS_MODAL_STATE";
export const SUBMIT_FAIL = "SUBMIT_FAIL";
export const RESET_PROPERTY_MODAL_ERROR = "RESET_PROPERTY_MODAL_ERROR";
export const RESET_ALL_FORM_STATE = "RESET_ALL_FORM_STATE";
export const TOGGLE_PROPERTY_PAGE_CHART = "TOGGLE_PROPERTY_PAGE_CHART";
export const TOGGLE_APPOINTMENT_PAGE_CHART = "TOGGLE_APPOINTMENT_PAGE_CHART";
export const TOGGLE_APPOINTMENT_PAGE_GENERAL_CHART = "TOGGLE_APPOINTMENT_PAGE_GENERAL_CHART";
export const TOGGLE_REVENUE_PAGE_CHART = "TOGGLE_REVENUE_PAGE_CHART";
export const TOGGLE_IS_MAP_HIDE = "TOGGLE_IS_MAP_HIDE";
export const ADD_MARK_AS_SOLD = "ADD_MARK_AS_SOLD";
export const ADD_MARK_AS_RENT = "ADD_MARK_AS_RENT";
export const ADD_UPDATE_RENT  = "ADD_UPDATE_RENT";
export const MARK_AS_SOLD_SUBMIT_FAIL  = "MARK_AS_SOLD_SUBMIT_FAIL";
export const RESET_MARK_AS_SOLD_MODAL = "RESET_MARK_AS_SOLD_MODAL";

//Transaction log
export const GET_SUBCRIPTION_DETAIL = "GET_SUBCRIPTION_DETAIL";
export const PAYMENT_CALLBACK = "PAYMENT_CALLBACK";
export const GET_TRANSACTION_LOG = "GET_TRANSACTION_LOG";
export const UPGRADE_SUBSCRIPTION_QUANTITY = "UPGRADE_SUBSCRIPTION_QUANTITY";
export const SEND_SUBSCRIPTION_EMAIL = "SEND_SUBSCRIPTION_EMAIL";
export const GET_PRICING_PLAN_ID = "GET_PRICING_PLAN_ID";

//Buyer Lead
export const GET_BUYER_LEAD = "GET_BUYER_LEAD";
export const ADD_BUYER_LEAD = "ADD_BUYER_LEAD";
export const BUYER_LEAD_SUBMIT_FAIL = "BUYER_LEAD_SUBMIT_FAIL";
export const RESET_BUYER_LEAD = "RESET_BUYER_LEAD";
export const RESET_BUYER_LEAD_MODAL = "RESET_BUYER_LEAD_MODAL";
export const GET_USER_BUYER_LEAD = "GET_USER_BUYER_LEAD";
export const EDIT_BUYER_LEAD = "EDIT_BUYER_LEAD";

//add/update Stock Pipeline
export const ADD_STOCK_PIPELINE = 'ADD_STOCK_PIPELINE';
export const UPDATE_STOCK_PIPELINE = 'UPDATE_STOCK_PIPELINE';
export const RESET_GOOGLE_MAP = 'RESET_GOOGLE_MAP';
export const RESET_ADD_STOCK_PIPELINE = 'RESET_ADD_STOCK_PIPELINE';
export const SET_EDIT_MAP_ADDRESS = 'SET_EDIT_MAP_ADDRESS';

//settings
export const UPDATE_SETTINGS = "UPDATE_SETTINGS";
//add appointment
export const VIEWING_APPOINTMENT = "VIEWING_APPOINTMENT";
export const GENERAL_APPOINTMENT = "GENERAL_APPOINTMENT";
export const UPDATE_ADD_APPOINTMENT_MODAL_STATE = 'UPDATE_ADD_APPOINTMENT_MODAL_STATE';
export const APPOINTMENT_SUBMIT_FAIL = 'APPOINTMENT_SUBMIT_FAIL';
export const RESET_APPOINTMENT_MODAL_ERROR = 'RESET_APPOINTMENT_MODAL_ERROR';

//edit appointment
export const GET_VIEWING_APPOINTMENT = "GET_VIEWING_APPOINTMENT";
export const GET_GENERAL_APPOINTMENT = "GET_GENERAL_APPOINTMENT";
export const RESET_APPOINTMENT_DATA  = "RESET_APPOINTMENT_DATA";
export const EDIT_VIEWING_APPOINTMENT = "EDIT_VIEWING_APPOINTMENT";
export const EDIT_GENERAL_APPOINTMENT = "EDIT_GENERAL_APPOINTMENT";
export const UPDATE_APPOINTMENT_SUBMIT_FAIL = "UPDATE_APPOINTMENT_SUBMIT_FAIL";
