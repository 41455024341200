import * as actionTypes from '../actions/actionsTypes';     
import {updateObject} from '../utility';

const initialState = {
    subscription_plan_id: null,
    payment_completed: null,
    transaction_log: null,
    callback_success: null,
    upgrade_next_payment: null,
    send_email: null,
    pricing_plan: null,
}

const subscription_detail = (state, action) => {
    return updateObject(state, {
        payment_completed: action.payment_completed,
        subscription_plan_id: action.subscription_plan_id
    })
}

const payment_callback = (state, action) => {
    return updateObject(state, {
        callback_success: action.callback_success
    })
}

const get_transaction_log = (state, action) => {
    return updateObject(state,{
        transaction_log: action.transaction_log
    })
}

const upgrade_subscription_quantity = (state, action)=>{
    return updateObject(state, {
        upgrade_next_payment: action.upgrade_next_payment
    })
}

const send_subscription_email = (state, action) =>{
    return updateObject(state,{
        send_email: action.send_email
    })
}

const pricing_plan_id = (state, action) =>{
    return updateObject(state, {
        pricing_plan: action.pricing_plan
    })
} 

function transaction_log_reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.GET_SUBCRIPTION_DETAIL: return subscription_detail(state, action);
        case actionTypes.PAYMENT_CALLBACK: return payment_callback(state,action);
        case actionTypes.GET_TRANSACTION_LOG: return get_transaction_log(state, action);
        case actionTypes.UPGRADE_SUBSCRIPTION_QUANTITY: return upgrade_subscription_quantity(state, action)
        case actionTypes.SEND_SUBSCRIPTION_EMAIL: return send_subscription_email(state, action);
        case actionTypes.GET_PRICING_PLAN_ID: return pricing_plan_id(state, action);
        default:
        return state;
    }
}

export default transaction_log_reducer;
