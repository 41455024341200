import React,{ Component } from "react";
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import Loader from '../Loader';
import ErrorText from '../../auth/ErrorText';
import Swal from 'sweetalert2';

const $ = require('jquery');
class AddBuyerLead extends Component{
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            first_name: null,
            mobile_no: null,
            email : null,
            address:null,
            note: null,
            tag: null,
            
        }
        this.submitHandler = this.submitHandler.bind(this);
        this.onInputchange = this.onInputchange.bind(this);
        this.toggleTag = this.toggleTag.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    opensweetalert(){
        Swal.fire({
            icon: 'success',
            title: 'Contact updated successfully ',
            showConfirmButton: true,
        })
    }

    //handle add property button
    async submitHandler(event){
        event.preventDefault(); 
        this.setState({loading: true});

        let first_name = (this.state.first_name === "") || (this.state.first_name) ? this.state.first_name : this.props.user_buyer_lead_data.contact_firstname;
        let last_name  = (this.state.last_name === "") || (this.state.last_name) ? this.state.last_name : this.props.user_buyer_lead_data.contact_lastname;
        let mobile_no  = (this.state.mobile_no === "") || (this.state.mobile_no) ? this.state.mobile_no : this.props.user_buyer_lead_data.contact_mobile_no;
        let email      = (this.state.email === "") || (this.state.email) ? this.state.email : this.props.user_buyer_lead_data.contact_email;
        let address    = (this.state.address === "") || (this.state.address) ? this.state.address : this.props.user_buyer_lead_data.contact_address;
        let note       = (this.state.note === "") || (this.state.note) ? this.state.note : this.props.user_buyer_lead_data.contact_note; 
        let tag        = this.state.tag ? this.state.tag : this.props.user_buyer_lead_data.contact_tag;
        
        //console.log(this.props.user_buyer_lead_data.contact_user_id, this.props.user_buyer_lead_data.id, this.props.user_buyer_lead_data.contact_office_lead, first_name, last_name, mobile_no, email, address, note, tag)
        await this.props.edit_buyer_lead(this.props.user_buyer_lead_data.contact_user_id, this.props.user_buyer_lead_data.id, this.props.user_buyer_lead_data.contact_office_lead, first_name, last_name, mobile_no, email, address, note, tag);
        
        if(Number(this.props.buyer_lead_form_status) === 200) {
            const token = localStorage.getItem('token');
            this.props.reset_buyer_lead();
            var page = 1
            var filter = this.props.filter
            if(this.props.agent_id){
                this.props.get_buyer_lead(token, this.props.agent_id, page, filter, this.props.active_filter, this.props.tag_filter);
            }else{
                var agent_id = ""
                this.props.get_buyer_lead(token, agent_id, page, filter, this.props.active_filter, this.props.tag_filter);
            }
            this.opensweetalert();
            this.closeModal();
        }
        this.setState({loading: false});
    }
    
    //clear modal data
    closeModal(){
        $(document).find('#editBuyerLeadform').trigger('reset');
        $('select').find('option[value=""]').attr('selected','selected');
        this.setState({
            loading: false,
            user_id: null,
            tag: null,
            first_name: null,
            last_name: null,
            mobile_no: null,
            email : null,
            address:null,
            note: null
        })
        this.props.reset_buyer_lead_modal();
        window.$("#editBuyerLeadModal").modal('hide');
    }

    onInputchange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    toggleTag(event){
        this.setState({
            tag: event.target.value,
        });
    }

    componentDidMount() {
        this.props.onRef(this)
    }
    componentWillUnmount() {
        this.props.onRef(undefined)
    }

    setTag() {
        this.setState({tag: this.props.tag})
        //window.alert('do stuff')
    }

    render(){
        return(
            <div>
                <div className="modal fade" data-backdrop="static" data-keyboard="false" id="editBuyerLeadModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={this.setwrapperRef} >
                    <div className="modal-dialog" role="document" ref={this.wrapperRef}>
                        <div className="modal-content modal_edit_buyer_lead modalheight" style ={{borderRadius : '12px' }} >
                            <div className="modal-header" style={{ backgroundColor: "#EDF6F6"}}>
                                <h4 className="modal-title fontWeight600"> <img src="images/buyer_lead_green.png" width="20" height="20" alt="" style={{marginRight: "5px"}}/> Edit Contact details</h4>
                                <div id="closeModal_edit_buyer_lead" className="circle" data-dismiss="modal" onClick={this.closeModal}>
                                    <img src="images/modal-close.png " width="12" height="12" alt=""/>
                                </div> 
                            </div>
                            
                            <div className="modal-body">
                                <center>
                                {this.props.add_buyer_lead_form_error ? 
                                    <ErrorText errorMsg = {this.props.add_buyer_lead_form_error} />
                                : 
                                    null
                                }
                                {this.props.user_buyer_lead_data ?
                                    <form id="EditBuyerLeadform" onSubmit={this.submitHandler}>
                                        {/* Contact Details */}
                                        <div className="shadow" ref="myinput">
                                            <p className="cardHeader">Edit Contact Details</p>
                                            <div className='form_border'>
                                                <br/>
                                                <div className='full-input' style={{borderBottom: "none"}}>
                                                    <label className="label_modal">Select Contact Type (Current or future)</label>
                                                    <input className="btn_contact_type" type="radio" id="edit_forBuyer"    name="contact_type" onClick={this.toggleTag} value="Buyer"    checked={this.state.tag === "buyer"    || this.state.tag === "Buyer"    ? true : false}/><label htmlFor="edit_forBuyer" className="btn_contact_type">Buyer</label>
                                                    <input className="btn_contact_type" type="radio" id="edit_forSeller"   name="contact_type" onClick={this.toggleTag} value="Seller"   checked={this.state.tag === "seller"   || this.state.tag === "Seller"   ? true : false}/><label htmlFor="edit_forSeller" className="btn_contact_type">Seller</label>
                                                    <input className="btn_contact_type" type="radio" id="edit_forTenant"   name="contact_type" onClick={this.toggleTag} value="Tenant"   checked={this.state.tag === "tenant"   || this.state.tag === "Tenant"   ? true : false}/><label htmlFor="edit_forTenant" className="btn_contact_type">Tenant</label>
                                                    <input className="btn_contact_type" type="radio" id="edit_forLandlord" name="contact_type" onClick={this.toggleTag} value="Landlord" checked={this.state.tag === "Landlord" || this.state.tag === "LandLord" ? true : false}/><label htmlFor="edit_forLandlord" className="btn_contact_type">Landlord</label>
                                                </div>

                                                <div className='full-input'>
                                                    <label className="label_modal">First Name*</label>
                                                    <input className="input_modal" type='text' name='first_name' id="first_name" placeholder="John" ref="first_name" maxLength="1024" value={(this.state.first_name === "") || (this.state.first_name) ? this.state.first_name : this.props.user_buyer_lead_data.contact_firstname} onChange={this.onInputchange} required></input>
                                                </div>
                                                <div className='full-input'>
                                                    <label className="label_modal">Last Name</label>
                                                    <input className="input_modal" type='text' name='last_name' id="last_name" placeholder="Doe" ref="last_name" maxLength="1024" value={(this.state.last_name === "") || (this.state.last_name) ? this.state.last_name : this.props.user_buyer_lead_data.contact_lastname} onChange={this.onInputchange}></input>
                                                </div>
                                                <hr style={{border: "2px solid #27AFAF"}}/>
                                                <div className='full-input'>
                                                    <label className="label_modal">Phone no.*</label>
                                                    <input className="input_modal" type='text' name='mobile_no' id="mobile_no" placeholder="987654321" ref="mobile_no" value={(this.state.mobile_no === "") || (this.state.mobile_no) ? this.state.mobile_no : this.props.user_buyer_lead_data.contact_mobile_no} minLength="8" maxLength="12" onChange={this.onInputchange}></input>
                                                </div>

                                                <div className='full-input' style={{borderBottom: "none"}}>
                                                    <label className="label_modal" style={{marginLeft:"42%"}}>and/or</label>
                                                    <br/>
                                                </div>
                                                
                                                <div className='full-input'>
                                                    <label className="label_modal">Email Address*</label>  {/* <span className="optional_modal">Optional</span> */}
                                                    <input className="input_modal" type='email' name='email' id="email" placeholder="johndoe@gmail.com" ref="email" maxLength="500" value={(this.state.email === "") || (this.state.email) ? this.state.email : this.props.user_buyer_lead_data.contact_email} pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$' onChange={this.onInputchange}></input>
                                                </div>
                                                <hr style={{border: "2px solid #27AFAF"}}/>

                                                <div className='full-input'>
                                                    <label className="label_modal">Address</label>{/* <span className="optional_modal">Optional</span>*/}
                                                    <input className="input_modal" type='text' name='address' id="address" placeholder="33 Warren St, Tamboerskloof" maxLength="1024" ref="property" value={(this.state.address === "") || (this.state.address) ? this.state.address : this.props.user_buyer_lead_data.contact_address} onChange={this.onInputchange}></input>
                                                </div>

                                                <div className='full-input' style={{borderBottom: "none"}}>
                                                    <label className="label_modal">Note</label>{/*<span className="optional_modal">Optional</span>*/}
                                                    <input className="input_modal" type='text' name='note' id="note" placeholder="Write your note" maxLength="1024" ref="note" value={(this.state.note === "") || (this.state.note) ? this.state.note : this.props.user_buyer_lead_data.contact_note} onChange={this.onInputchange}></input>
                                                </div>
                                            </div>
                                            <br/><br/>
                                        </div>
                                        
                                        {/* Optional info */}
                                        {/* <div className="shadow" style={{display: "block"}}>
                                            <p className="cardHeader">Optional extra info</p>
                                            <p className="cardSubheader" style={{fontWeight:500}}>Share addtional info with the agent about this lead</p>
                                            <div className='form_border'>
                                                <div className='full-input'>
                                                    <label className="label_modal">Property of Interest</label><span className="optional_modal">Optional</span>
                                                    <input className="input_modal" type='text' name='address' id="address" placeholder="33 Warren St, Tamboerskloof" maxLength="1024" ref="property" value={(this.state.address === "") || (this.state.address !== null) ? this.state.address : this.props.user_buyer_lead_data.address} onChange={this.onInputchange}></input>
                                                </div>
                                                <div className='full-input' style={{borderBottom: "none"}}>
                                                    <label className="label_modal">Note</label><span className="optional_modal">Optional</span>
                                                    <input className="input_modal" type='text' name='note' id="note" placeholder="Write your note" maxLength="1024" ref="note" value={(this.state.note === "") || (this.state.note !== null) ? this.state.note : this.props.user_buyer_lead_data.remarks} onChange={this.onInputchange}></input>
                                                </div>
                                            </div>
                                            <br/><br/><br/>
                                        </div> */}
                                        <br/><br/>
                                        {!this.state.loading ? 
                                            this.props.add_buyer_lead_form_error !== "success"? 
                                                <div style={(Number(this.props.add_property_modal_state)) === 1 ? {display: "block"} : {display: this.state.display_card} }> 
                                                    <button id="editBuyerLeadBtn" type="submit" className="btn btn-primary fontBold" style={{ background:'#4AA49C',borderRadius:'4px'}}>
                                                        <img src="images/header-component/buyer_lead_active.png" width="15" height="15" alt="" style={{marginRight: "5px"}}/>
                                                        Save Changes
                                                    </button>
                                                </div>
                                            : null
                                        :
                                            <Loader/>
                                        }  
                                        <br/><br/>
                                    </form>
                                :
                                    <Loader/>
                                }
                                </center>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        //buyer_lead 
        edit_buyer_lead : (user_id, lead_id, is_office_lead, first_name, last_name, mobile_no, email, address, note, tag) => dispatch(actions.edit_buyer_lead(user_id, lead_id, is_office_lead, first_name, last_name, mobile_no, email, address, note, tag)),
        get_buyer_lead : (token, agent_id, page, filter, active_filter, tag_filter) => dispatch(actions.get_buyer_lead(token, agent_id, page, filter, active_filter, tag_filter)),
        reset_buyer_lead_modal: () => dispatch(actions.reset_buyer_lead_modal()),
        reset_buyer_lead : () => dispatch(actions.reset_buyer_lead()),
    }
  }
  
const mapStateToProps = state => {
    return{
        authtoken: state.add_property_modal.authtoken,
        add_buyer_lead_form_error: state.buyer_lead.add_buyer_lead_form_error,
        user_buyer_lead_data: state.buyer_lead.user_buyer_lead_data,
        
        edit_buyer_lead_error: state.buyer_lead.edit_buyer_lead,
        buyer_lead_form_status: state.buyer_lead.buyer_lead_form_status
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddBuyerLead);
