
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.css';

import React, { Component } from "react";
import Header from '../components/menu/Header';
import { Icon} from '@iconify/react';
import bxsCheckCircle from '@iconify/icons-bx/bxs-check-circle';
import {Button} from 'primereact/button';



const header ={
  fontFamily: 'Inter',
  fontWeight: 600,
  color:"#0F1D30",
  marginTop:0
};
const subheader={ 
  fontFamily: 'Inter',
  fontWeight: 500,
  color:'#466380',
  fontSize:16
};

const payment_info ={
  backgroundColor:"#f8f8fa",
  fontFamily: 'Inter',
  fontWeight: 500,
  fontSize: 15,
  color:"#466380",
  borderTop: '1px solid #C2D0DE',
  borderBottom: '1px solid #C2D0DE',
  padding: "8px",
};

const cost ={
  color:'black'
};

class PaymentSuccess extends Component {  
  constructor(props) {
    super(props);
    this.state = {
      checked:false
    }
  }
  render() {
    return (
      
        <div className="content-wrapper" style={{ marginLeft: '0px' }}>
            {<Header active="dashboard"/>}
            <section className="content" style={{ margin: '30px', marginTop: '60px' }}>
                <div className="row">
                    <div className="col-lg-8 col-lg-offset-2 mb30">
                        <Icon icon={bxsCheckCircle} style={{color:"#0A9292", marginLeft: -15}}  width='5em' height='5em'/>
                        <h2 style={header}>Payment Successful</h2>
                        <p style={subheader}>You have successfully subscribed 16 agent to Roundr Office.</p>
                    </div>

                    <div className="col-lg-8 col-lg-offset-2"  style={{marginBottom:50}}>
                        <div className="row" style={payment_info}>
                            <div className="col-lg-4">
                                <p>Number of Agents</p>
                                <p style={cost}>16</p>
                            </div>

                            <div className="col-lg-4">
                                <p>Monthly Cost Per Agents</p>
                                <p style={cost}>R 98.00</p>
                            </div>
                    
                            <div className="col-lg-2 col-lg-offset-2 col-sm-12" style={{backgroundColor:"#EDEFF2"}}>
                                <p><strong>Due Monthly</strong></p>
                                <p style={{color:"#0F1D30"}}><strong>R 1178.00</strong></p>
                            </div>
                        </div> 
                    </div>

                    <div className="col-lg-8 col-lg-offset-2 col-sm-12">
                        <div className="row">
                            <div className="col-lg-6 col-sm-12 mb15">
                                <Button style={{backgroundColor:"#0B2C4C", marginRight:20}} type="button" label="Back to Home" icon="pi pi-chevron-left" iconPos="left"/>
                                <Button style={{backgroundColor:"#117777",marginTop:20}} type="button" label="Download Statement" icon="pi pi-download" iconPos="right"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
  
    );
  }
}
 
export default PaymentSuccess;