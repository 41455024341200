import * as actionTypes from './actionsTypes';
import Constants from '../../constants/Constants';

var myHeaders = '';
var raw = '';
var requestOptions = '';

export const get_agent_performance_data = (agent_id, token)=>{
    return dispatch => {
        myHeaders = new Headers();
        myHeaders.append("Content-Type","application/json");
        myHeaders.append("Authorization", token);
        raw = JSON.stringify(
        {
            "agent_id": agent_id 
        });
        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(Constants.AGENT_PERFORMANCE, requestOptions)
        .then(response => response.json())
        .then(data => {
            dispatch(agent_performance_data(data));
        })
        .catch(error => console.log('error', error));
    }
}
export const agent_performance_data = (data) => {
    return{
        type: actionTypes.GET_AGENT_PERFORMANCE_DATA,
        agent_data: data.agent_data,
        agent_performance: data
    }
}

export const get_property_data = (fromDate, toDate, agent_id, token, viewings_sort, recent_property) => {
    return async dispatch =>{
        myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token);
        raw = JSON.stringify(
        {
            "token": token,
            "agent_id": agent_id,
            "from":fromDate,
            "to":toDate,
            "viewings_sort": viewings_sort,
            "recent_property": recent_property
        });
        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        await fetch(Constants.AGENT_CARD, requestOptions)
        .then(response => response.json())
        .then(data => {
            dispatch(property_data(data, agent_id));
        })
        .catch(error => console.log('error', error));
    }
}
export const property_data = (data, agent_id)=>{
    let agent_data = data.agent_data
    agent_data['user_id'] = agent_id
 
    return{
        type: actionTypes.GET_PROPERTY_DATA,
        agent_data: data.agent_data,
        agent_linked_user_cnt: data.linked_user_cnt,
        property_for_sale: data.propperty_for_sale,
        property_for_rent: data.propperty_for_rent,
        property_for_sale_total: data.propperty_for_sale_total,
        property_for_rent_total: data.propperty_for_rent_total,
        graph:data.graph,
        linked_user_cnt:data.linked_user_cnt
    }
}

export const get_filtered_property_list = (fromDate, toDate, agent_id, token, viewings_sort, recent_property, client_properties, property_type) => {
    return dispatch =>{
        myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token);
        raw = JSON.stringify(
        {
            "token": token,
            "agent_id": agent_id,
            "from":fromDate,
            "to":toDate,
            "viewings_sort": viewings_sort,
            "recent_property": recent_property,
            "client_properties": client_properties,
            "property_type": property_type
        });
        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(Constants.FILTER_PROPERTY_LIST, requestOptions)
        .then(response => response.json())
        .then(data => {
            dispatch(filtered_property_list(data, property_type));
        })
        .catch(error => console.log('error', error));
    }
}
export const filtered_property_list = (data, property_type)=>{
    if(property_type === "sale"){
        return{
            type: actionTypes.FILTER_PROPERTY_LIST,
            property_type: property_type,
            property_for_sale: data.properties,
        }
    }else{
        return{
            type: actionTypes.FILTER_PROPERTY_LIST,
            property_type: property_type,
            property_for_rent: data.properties,
        }
    }
}

export const get_appointment_data_modal = (appointmentFromDate, appointmentToDate, todayDate, agent_id, token) => {
    //var date = "2020-12-01"
    return async dispatch =>{
        myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token);
        
        raw = JSON.stringify(
        {
            "token": token,
            "agent_id": agent_id,
            "from": appointmentFromDate,
            "to": appointmentToDate,
            "appt_date": todayDate
        });
        
        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        await fetch(Constants.AGENT_CARD_APPT, requestOptions)
        .then(response => response.json())
        .then(data => {
            dispatch(get_appointment_data(data))    
        })
        .catch(error => console.log('error', error));
    }
}
export const get_appointment_data = (data) => { 
    var calendarData = [];
    var deleteEvents = [];
    if(data.month_wise_appt.length > 0){
        for (let i = 0; i < data.month_wise_appt.length; i++){
            let appointment_type = data.month_wise_appt[i].appointment_type === "viewing" ? 
                                    "<span class='viewingspan mr5'>"+data.month_wise_appt[i].appointment_type+"</span>"
                                : 
                                    "<span class='generalspan mr5'>"+data.month_wise_appt[i].appointment_type+"</span>";
            
            let address = null;
            let audio_controlList = 'nodownload noplaybackrate'
            if(data.month_wise_appt[i].appointment_type === "viewing"){
                address = data.month_wise_appt[i].location_address;
            }else{
                let location = data.month_wise_appt[i].address ? data.month_wise_appt[i].address : "N/A"
                Number(data.month_wise_appt[i].has_audio) === 1 ?
                    address = '<a id="'+data.month_wise_appt[i].general_appointment_id+'" class="display_popover" data-html="true" tabindex="0"' +
                              'data-placement="bottom" data-toggle="popover"'+
                              'title = "<strong>General Appointment</strong>"'+ 
                              'data-content = "<audio class=player controls src='+data.month_wise_appt[i].audio_name+' controlsList=&apos;'+audio_controlList+'&apos;> </audio>">'+
                              ''+location+'</a>'
                :
                    address = '<a id="'+data.month_wise_appt[i].general_appointment_id+'" class="display_popover" data-html="true" tabindex="0"' +
                              'data-placement="bottom" data-toggle="popover"'+
                              'title = "<strong>General Appointment</strong>"'+ 
                              'data-content = "'+data.month_wise_appt[i].reason+'">'+
                              ''+location+'</a>'
            }

            // let description = data.month_wise_appt[i].appointment_status === 'upcoming' || data.month_wise_appt[i].appointment_status === 'unconfirmed' || data.month_wise_appt[i].appointment_status === 'requested change' ? appointment_type+"<span class='upcomingspan'> "+data.month_wise_appt[i].appointment_status+" </span> <br/> <p class= 'calendar_desc mt10'>" + address +"</p>"
            //                 : data.month_wise_appt[i].appointment_status === 'cancelled' ? appointment_type+"<span class='canceledspan'> Canceled </span> <br/> <p class= 'calendar_desc mt10'>"+address+"</p>"
            //                 : data.month_wise_appt[i].appointment_status === 'complete' || data.month_wise_appt[i].appointment_status === "confirmed"  ? appointment_type+"<span class='completedgspan'> "+ data.month_wise_appt[i].appointment_status +" </span> <br/> <p class= 'calendar_desc mt10'>"+ address +"</p>" 
            //                 : "<p class= 'calendar_desc mt10'>"+ address +"</p>";
            let description = data.month_wise_appt[i].appointment_status === 'upcoming' || data.month_wise_appt[i].appointment_status === 'requested change' ? appointment_type+"<span class='upcomingspan'> "+data.month_wise_appt[i].appointment_status+" </span> <br/> <p class= 'calendar_desc mt10'>" + address +"</p>"
                            : data.month_wise_appt[i].appointment_status === 'cancelled' ? appointment_type+"<span class='canceledspan'> Canceled </span> <br/> <p class= 'calendar_desc mt10'>"+address+"</p>"
                            : data.month_wise_appt[i].appointment_status === 'complete' || data.month_wise_appt[i].appointment_status === "confirmed"  ? appointment_type+"<span class='completedgspan'> "+ data.month_wise_appt[i].appointment_status +" </span> <br/> <p class= 'calendar_desc mt10'>"+ address +"</p>" 
                            : data.month_wise_appt[i].appointment_status === 'unconfirmed' ? appointment_type+" <br/> <p class= 'calendar_desc mt10'>" + address +"</p>"
                            : "<p class= 'calendar_desc mt10'>"+ address +"</p>";
            
            let color = data.month_wise_appt[i].appointment_status === 'upcoming' || data.month_wise_appt[i].appointment_status === 'unconfirmed' || data.month_wise_appt[i].appointment_status === 'requested change' ? '#ffc107'
                        : data.month_wise_appt[i].appointment_status === 'cancelled' ? '#EA676A'
                        : data.month_wise_appt[i].appointment_status === 'complete' || data.month_wise_appt[i].appointment_status === "confirmed" ? '#63d867'
                        : '#ffc107' ;

            let type = data.month_wise_appt[i].appointment_status === 'upcoming' || data.month_wise_appt[i].appointment_status === 'unconfirmed' || data.month_wise_appt[i].appointment_status === 'requested change' ? 'upcoming'
                        : data.month_wise_appt[i].appointment_status === 'cancelled' ? 'canceled'
                        : data.month_wise_appt[i].appointment_status === 'complete' || data.month_wise_appt[i].appointment_status === "confirmed" ? 'complete'
                        : 'upcoming' ;
            
            let id = data.month_wise_appt[i].appointment_type === "viewing" ? 
                        data.month_wise_appt[i].property_id
                    :
                        Number(data.month_wise_appt[i].property_id) !== 0 && data.month_wise_appt[i].property_id ? 
                            data.month_wise_appt[i].property_id+"_property_id" 
                        :
                            Number(data.month_wise_appt[i].my_stock_pipeline_id) !== 0 && data.month_wise_appt[i].my_stock_pipeline_id ?
                                data.month_wise_appt[i].my_stock_pipeline_id+"_stockpipeline_id" 
                            : 
                                data.month_wise_appt[i].general_appointment_id+"_general_appt_id"

            calendarData.push({
                id : id,
                name: data.month_wise_appt[i].appointment_type === "viewing" ? data.month_wise_appt[i].buyer_name : data.month_wise_appt[i].meeting_with, 
                date : data.month_wise_appt[i].requested_appointment_date ? data.month_wise_appt[i].requested_appointment_date : data.month_wise_appt[i].appointment_date,
                badge : data.month_wise_appt[i].time,
                appointment_type: data.month_wise_appt[i].appointment_type,
                description : description,
                type : type,
                color: color,
                is_office_listing: data.month_wise_appt[i].is_office_listing,
                is_open_listing: data.month_wise_appt[i].is_open_listing,
                user_creator_id: data.month_wise_appt[i].user_id,
                general_appointment_id: data.month_wise_appt[i].appointment_type === "general" ? data.month_wise_appt[i].general_appointment_id : null,
                stockpipeline_id: data.month_wise_appt[i].appointment_type === "general" ? data.month_wise_appt[i].my_stock_pipeline_id : null,
                address: data.month_wise_appt[i].appointment_type === "general" ? data.month_wise_appt[i].address ? data.month_wise_appt[i].address : null : null,
                //address: address,
                has_audio: data.month_wise_appt[i].appointment_type === "general" ? data.month_wise_appt[i].has_audio : null,
                audio_name: data.month_wise_appt[i].appointment_type === "general" ? data.month_wise_appt[i].audio_name : null,
                reason: data.month_wise_appt[i].appointment_type === "general" ? data.month_wise_appt[i].reason : null,
                //description : "<span class='upcomingspan'> Upcoming </span> <br/> <p class= 'calendar_desc'> 7-50, Narayan Gajanan Acharya Marg Vaibhav Nagar, near Subhash Nagar, Shree Saraswati Society, Subhash Nagar, Chembur, Mumbai, Maharashtra 400071, India </p>",
            })
            deleteEvents.push(data.month_wise_appt[i].property_id)
            //deleting duplicate general appointments
            deleteEvents.push(data.month_wise_appt[i].property_id+"_property_id")
            deleteEvents.push(data.month_wise_appt[i].general_appointment_id+"_general_appt_id")
            deleteEvents.push(data.month_wise_appt[i].my_stock_pipeline_id+"_stockpipeline_id")
        }
    }
    return{
        type: actionTypes.GET_APPOINTMENT_DATA_MODAL,
        //agent_data: data.agent_data,
        agentAppointmentData: data,
        date_wise_appt: calendarData,
        deleteEvents: deleteEvents
    }
}

export const get_general_chart_agent_modal = (appointmentFromDate, appointmentToDate, agent_id, token) =>{
    return dispatch =>{
        const appt_api_chart = Constants.GENERAL_CHART;
        myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token);
        raw = JSON.stringify(
        {
            "from": appointmentFromDate,
            "to": appointmentToDate,
            "agent_id": agent_id
        });
        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(appt_api_chart, requestOptions)
        .then(response => response.json())
        .then(data => {
            dispatch(general_chart_agent_modal(data));
        })
    }
}
export const general_chart_agent_modal = (data) => {
    return {
        type: actionTypes.GET_GENERAL_CHART_AGENT_MODAL,
        general_lables_agent_modal: data.data.labels,
        chart_general_count_agent_modal: data.data.appt_cnt,
    }
}

export const get_stock_pipeline_data = (page, is_archived, stock_pipeline_id, token)=>{
    return async dispatch => {
        var myHeaders = new Headers();
        myHeaders.append("authtoken", token);
        var raw = JSON.stringify({"page":page, "stock_pipeline_id": stock_pipeline_id, "is_archived": is_archived});
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        //let page_no = page;
        await fetch(Constants.AGENT_STOCK_PIPELINE, requestOptions)
        .then(response => response.json())
        .then(data => {
            dispatch(stock_pipeline_data(data, page));
        })
        .catch(error => console.log('error', error));
    }
}
export const stock_pipeline_data = (data, page) => {
    let stock_pipeline_id = 1 ;
    let load_more = '';
    if( data.data.length === 10){
        load_more = true;
    }else{
        load_more = false;
    }
    return{
        type: actionTypes.GET_STOCK_PIPELINE,
        stock_pipeline: data.data,
        stock_load_more: load_more,
        stock_page: page,
        stock_notes: data.data.notes ? data.data.notes : '',
        stock_pipeline_data: stock_pipeline_id ? data.data : '',
    }
}

export const get_stock_pipeline_property_data = (stock_pipeline_id, agent_id, token, from_archiveStockPipeline)=>{
    return async dispatch => {
        myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token);

        //var raw = JSON.stringify({"page":page, "stock_pipeline_id": stock_pipeline_id, "is_archived": is_archived, "is_visit": isVisit});
        //var raw = JSON.stringify({"page":1, "my_stock_pipeline_id": stock_pipeline_id});
        var raw = JSON.stringify({"agent_id":agent_id, "prop_id": stock_pipeline_id});
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        //let page_no = page;
        //await fetch(Constants.AGENT_STOCK_PIPELINE, requestOptions)
        await fetch(Constants.STOCKPIPLINE_APPOINTMENT, requestOptions)
        .then(response => response.json())
        .then(data => {
            dispatch(stock_pipeline_property_data(data, from_archiveStockPipeline));
        })
        .catch(error => console.log('error', error));
    }
}
export const stock_pipeline_property_data = (data,from_archiveStockPipeline) => {
    return{
        type: actionTypes.GET_STOCK_PIPELINE_PROPERTY_DATA,
        stock_pipeline_property_data: data.property_details,
        stock_pipeline_notes: data.property_notes_visits,
        stock_pipeline_appointments: data.general_appointment,
        from_archiveStockPipeline: from_archiveStockPipeline
    }
}

export const reset_stock_pipeline_modal = ()=>{
    return dispatch => {
        dispatch({
            type: actionTypes.RESET_STOCK_PIPELINE_MODAL,
            stock_pipeline_property_data: null
        })
    }
}

export const reset_stock_pipeline_data = ()=>{
    return dispatch => {
        dispatch({
            type: actionTypes.GET_STOCK_PIPELINE,
            stock_notes: null,
            stock_pipeline_data: null,
            stock_page: '',
        })
    }
}

export const reset_calendar_data = () =>{
    return dispatch => {
        dispatch({
            type: actionTypes.RESET_CALENDAR_DATA,
            date_wise_appt: null,
            deleteEvents: null
        })
    }
} 

export const get_stockpipeline_chart_by_id = (fromDate, toDate, user_id, token) => {
    return dispatch => {
        const sales_api_chart = Constants.STOCKPIPELINE_CHART;
        myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token);
        raw = JSON.stringify(
        {
            "from": fromDate,
            "to": toDate,
            "userid": user_id
        });
        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(sales_api_chart, requestOptions)
        .then(response => response.json())
        .then(data => {
            dispatch(stockpipeline_chart_by_id(data, user_id));
        })
    }
}
export const stockpipeline_chart_by_id = (data, user_id) =>{
    return{
        type: actionTypes.GET_STOCKPIPELINE_CHART_BY_ID,
        stockpipeline_labels_by_id : data.data.labels,
        stockpipeline_count_by_id : data.data.stock_pipeline_cnt[0][user_id],
    }
}

export const get_cold_call_logs_data = (page, date, token)=>{
    return dispatch => {
        var myHeaders = new Headers();
        myHeaders.append("authtoken", token);
        var raw = JSON.stringify({"page":page, "date": date});
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        
        fetch(Constants.AGENT_CARD_CALL_LOGS, requestOptions)
        .then(response => response.json())
        .then(data => {
            dispatch(cold_call_logs_data(data, page));
        })
        .catch(error => console.log('error', error));
    }
}
export const cold_call_logs_data = (data, page) => {
    let load_more = '';
    if( data.data.length === 10 ){
        load_more = true;
    }else{
        load_more = false;
    }
    return{
        type: actionTypes.GET_COLD_CALL_LOGS,
        cold_calls_log: data.data,
        load_more: load_more,
        page: page,
    }
}

export const get_coldcalls_chart_by_id = (fromDate, toDate, user_id, token) => {
    return dispatch => {
        const sales_api_chart = Constants.COLDCALL_CHART;
        myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token);
        raw = JSON.stringify(
        {
            "from": fromDate,
            "to": toDate,
            "userid": user_id
        });
        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(sales_api_chart, requestOptions)
        .then(response => response.json())
        .then(data => {
            dispatch(coldcalls_chart_by_id(data, user_id));
        })
    }
}
export const coldcalls_chart_by_id = (data, user_id) =>{
    return{
        type: actionTypes.GET_COLDCALLS_CHART_BY_ID,
        coldcalls_labels_by_id : data.data.labels,
        coldcalls_count_by_id : data.data.cold_call_cnt[0][user_id],
    }
}

export const get_cold_call_logs_reports = (user_id, month, year, token) => {
    return async dispatch => {
        const sales_api_chart = Constants.EXPORT_COLD_CALL;
        myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token);
        raw = JSON.stringify(
        {
            "month": month,
            "year": year,
            "user_id": user_id
        });
        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        await fetch(sales_api_chart, requestOptions)
        .then(response => response.json())
        .then(data => {
            dispatch(cold_call_logs_reports(data));
        })
    }
    
}
export const cold_call_logs_reports = (data) => {
    return{
        type: actionTypes.GET_COLD_CALL_LOGS_REPORTS,
        cold_call_export : data.cold_call_report,
    }
}

export const get_revenue_data_modal = (from_date, to_date, agent_id, token) =>{
    return dispatch => {
        myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token);

        raw = JSON.stringify(
        {
            "agent_id": agent_id,
            "from": from_date,
            "to": to_date,
        });
        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(Constants.REVENUE_CHART_MODAL, requestOptions)
        .then(response => response.json())
        .then(data => {
            dispatch(revenue_data_modal(data));
        })
        .catch(error => console.log('error', error));
    }
}
export const revenue_data_modal = (data) => {
    return{
        type: actionTypes.GET_REVENUE_DATA_MODAL,
        current_sales: data.sales_comm.sales.current,
        last_sales: data.sales_comm.sales.last,
        reve_sales:data.reve_sales
    }
}

export const get_other_listing_data = (agent_id, token)=>{
    return dispatch => {
        myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token);

        raw = JSON.stringify(
        {
        "agent_id": agent_id,
        });
        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(Constants.OTHER_LISTING, requestOptions)
        .then(response => response.json())
        .then(data => {
            dispatch((other_listing_data(data)))
            // this.setState({
            //     other_listing_data: data
            // });
        })
        .catch(error => console.log('error', error));
    }
}
export const other_listing_data=(data)=>{
    return{
        type: actionTypes.GET_OTHER_LISTING_DATA,
        other_listing_data: data
    }
}

export const get_all_agent_data = (token)=>{
    return dispatch => {
        var myHeaders = new Headers();
        myHeaders.append("authtoken", token);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(Constants.GET_ALL_AGENT_DATA, requestOptions)
        .then(response => response.json())
        .then(data => {
            dispatch((all_agent_data(data)))
        })
        .catch(error => console.log('error', error));
    }
}
export const all_agent_data=(data)=>{
    if(Number(data.success) !==  0){
        return{
            type: actionTypes.GET_ALL_AGENT_DATA,
            country_code: data.data.user.country_code,
            currency_symbol: data.data.user.currency_symbol,
            pro_user: data.data.user.pro_user,
            timezone: data.data.user.current_timezone,
        }
    }
}

export const get_archived_property_data = (page, is_archived, type, token)=>{
    return async dispatch => {
        var myHeaders = new Headers();
        myHeaders.append("authtoken", token);
        var raw = JSON.stringify({"page":page, "is_archived": is_archived, "type":type });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        
        await fetch(Constants.AGENT_ARCHIVED_PROPERTY, requestOptions)
        .then(response => response.json())
        .then(data => {
            dispatch(archived_property_data(data, page));
        })
        .catch(error => console.log('error', error));
    }
}
export const archived_property_data = (data, page) => {
    let archive_property_load_more = '';
    if( data.data.properties.length === 10 ){
        archive_property_load_more = true;
    }else{
        archive_property_load_more = false;
    }
    return{
        type: actionTypes.GET_ARCHIVED_PROPERTY,
        archived_property: data ? data.data.properties : null,
        archive_property_load_more: archive_property_load_more,
        archive_property_page: page,
    }
}

export const get_networking_data = (agent_id, token)=>{
    return dispatch => {
        var myHeaders = new Headers();
        myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token);
        var raw = JSON.stringify({"user_id": agent_id});
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        
        fetch(Constants.AGENT_NETWORKING_PROPERTY, requestOptions)
        .then(response => response.json())
        .then(data => {
            dispatch(networking_data(data));
        })
        .catch(error => console.log('error', error));
    }
}
export const networking_data = (data) => {
    return{
        type: actionTypes.GET_NETWORKING_PROPERTY,
        office_list: data.office_list,
        open_list: data.open_list
    }
}

export const get_upcoming_trips = (token, page)=>{
    return dispatch => {
        var myHeaders = new Headers();
        myHeaders = new Headers();
        //myHeaders.append("Content-Type", "text/json");
        myHeaders.append("authtoken", token);
        var raw = JSON.stringify({"page": page});
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        
        fetch(Constants.GET_UPCOMING_TRIPS, requestOptions)
        .then(response => response.json())
        .then(data => {
            dispatch(upcoming_trips(data, page));
        })
        //.catch(error => console.log('error', error));
    }
}
export const upcoming_trips = (data,page) => {
    let load_more = '';
    if(data.data && data.data.length === 10){
        load_more = true;
    }else{
        load_more = false;
    }
    return{
        type: actionTypes.GET_UPCOMING_TRIPS,
        upcoming_trips: data.data,
        upcoming_trips_load_more: load_more,
        page: page
    }
}
export const get_past_trips = (token, start_date, end_date, page)=>{
    return dispatch => {
        var myHeaders = new Headers();
        myHeaders = new Headers();
        //myHeaders.append("Content-Type", "text/json");
        myHeaders.append("authtoken", token);
        var raw = JSON.stringify({"start_date": start_date, "end_date": end_date, "page": page});
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        
        fetch(Constants.GET_PAST_TRIPS, requestOptions)
        .then(response => response.json())
        .then(data => {
            dispatch(past_trips(data, page));
        })
        //.catch(error => console.log('error', error));
    }
}
export const past_trips = (data,page) => {
    let load_more = '';
    if(data.data && data.data.trips.length === 10){
        load_more = true;
    }else{
        load_more = false;
    }
    return{
        type: actionTypes.GET_PAST_TRIPS,
        past_trips: data.data.trips,
        total_distance: data.data.toatl_distance,
        total_claim_exp: data.data.toatl_claim_exp,
        past_trips_load_more: load_more,
        page: page
    }
}
export const get_missed_trips = (token, page)=>{
    return dispatch => {
        var myHeaders = new Headers();
        myHeaders = new Headers();
        //myHeaders.append("Content-Type", "text/json");
        myHeaders.append("authtoken", token);
        var raw = JSON.stringify({"page": page});
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        
        fetch(Constants.GET_MISSED_TRIPS, requestOptions)
        .then(response => response.json())
        .then(data => {
            dispatch(missed_trips(data,page));
        })
        //.catch(error => console.log('error', error));
    }
}
export const missed_trips = (data,page) => {
    let load_more = '';
    if(data.data && data.data.length === 10){
        load_more = true;
    }else{
        load_more = false;
    }
    return{
        type: actionTypes.GET_MISSED_TRIPS,
        missed_trips: data.data,
        missed_trips_load_more: load_more,
        page: page
    }
}

export const reset_stockpipeline_data = ()=>{
    return {
        type: actionTypes.RESET_STOCKPIPELINE_DATA
    }
}

export const reset_agent_modal = ()=>{
    return {
        type: actionTypes.RESET_AGENT_MODAL
    }
}

export const set_page = (page, reset_date_range)=>{
    return{
        type: actionTypes.SET_PAGE,
        page: page,
        reset_date_range: reset_date_range
    }
}


