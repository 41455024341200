import React ,  { Component } from 'react';

class SuccessText extends Component {

    render(){
        return(<div>
                    <div className = "alert alert-success text-center font d-inline-block text-truncate">
                    <i className="fa fa-check" aria-hidden="true"></i>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {this.props.successMsg}
                    </div>
              </div>)
    }

}

export default SuccessText;


