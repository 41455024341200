import React,{ Component } from "react";
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import modal from '../../../CustomFiles/js/modal';
import Swal from 'sweetalert2';

import { Calendar } from 'primereact/calendar';
//import ReactTooltip from 'react-tooltip';
import { Icon } from '@iconify/react';
import bxCalendarEvent from '@iconify/icons-bx/bx-calendar-event';

import ErrorText from '../../../auth/ErrorText';
import SuccessText from '../../../auth/SuccessText';

class EditGeneralAppointment extends Component{
    constructor(props) {
        super(props);
        this.state = {
            appointment_id: null,
            edit_appointment_reason: null,
            edit_appointment_reason_length: 0,
            edit_viewer_name_general: null,
            has_audio: 0,
            startDate: null,
            time: null,
            //for stock-pipeline
            edit_appointment_reason_stock: null,
            edit_appointment_reason_length_stock: 0,
            edit_viewer_name_general_stock: null,
            has_audio_stock: 0,
            startDate_stock: null,
            time_stock: null,

        }
        this.setGeneralData = this.setGeneralData.bind(this);
        this.onInputchange = this.onInputchange.bind(this);
        this.submitEditGeneralAppointmentHandler = this.submitEditGeneralAppointmentHandler.bind(this);
        this.submitEditGeneralAppointmentStockHandler = this.submitEditGeneralAppointmentStockHandler.bind(this);
        this.closeModal_Update_Appointment = this.closeModal_Update_Appointment.bind(this)
    }

    appointmentSuccess_sweetalert(){
        Swal.fire({
            icon: 'success',
            title: 'Appointment added successfully ',
            showConfirmButton: true,
        })
    }
    appointmentError_sweetalert(){
        Swal.fire({
            icon: 'error',
            title: 'Something went wrong',
            showConfirmButton: true,
        })
    }

    componentDidMount() {
        modal();
        this.props.setGeneralData(this.setGeneralData);
    }
    
    closeModal_Update_Appointment(){
        this.props.reset_appointment_data()
    }
    
    setGeneralData = () =>{
        var currentTime = this.props.general_appointment.appointment_date.split(" ")
        if(this.props.from_stock_pipeline === 0){
            let len = this.props.general_appointment.reason;
            len = len.length;
            this.setState({
                appointment_id: this.props.general_appointment.id,
                has_audio: this.props.general_appointment.hasaudio,
                audio_path: this.props.general_appointment.audio_path,
                edit_appointment_reason: this.props.general_appointment.reason,
                edit_appointment_reason_length: len,
                edit_viewer_name_general: this.props.general_appointment.meeting_with,
                startDate: new Date(currentTime[0]),
                time: new Date(this.props.general_appointment.appointment_date),
            })
            window.$("#edit_appointment_reason").val(this.props.general_appointment.reason)
        }else{
            let len = this.props.general_appointment.reason;
            len = len.length;
            this.setState({
                appointment_id: this.props.general_appointment.id,
                has_audio: this.props.general_appointment.hasaudio,
                audio_path: this.props.general_appointment.audio_path,
                edit_appointment_reason_stock: this.props.general_appointment.reason,
                edit_appointment_reason_length_stock: len,
                edit_viewer_name_general_stock: this.props.general_appointment.meeting_with,
                startDate_stock: new Date(currentTime[0]),
                time_stock: new Date(this.props.general_appointment.appointment_date),
            })
            window.$("#edit_appointment_reason_stock").val(this.props.general_appointment.reason)
        }
            
    }

    onInputchange(event) {
        this.setState({
            [event.target.name]: event.target.value,
            selected_contact_id: null,
            viewer_contact_no: null,
            viewer_email_id: null
        });
        if(event.target.name === "edit_appointment_reason"){
            let len = event.target.value;
            len = len.length; 
            this.setState({
                edit_appointment_reason_length: len,
                has_audio: 0
            })
        }
    }

    changeDateFormat(fromDate) {
        var d = new Date(fromDate),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [year, month, day].join('-');
    }

    getCalendarData = (e) => {
        if(this.props.from_stock_pipeline === 0){
            this.setState({startDate: this.changeDateFormat(e.value)})
        }else{
            this.setState({startDate_stock: this.changeDateFormat(e.value)})
        }
    }
    
    setTime = (e) => {
        if(this.props.from_stock_pipeline === 0){
            this.setState({time: e.value})
        }else{
            this.setState({time_stock: e.value})
        }

    }

    async submitEditGeneralAppointmentHandler(event){
        event.preventDefault(); 
        let authtoken = this.props.authtoken;
        let appointment_id = this.props.general_appointment.id
        let reason = this.state.edit_appointment_reason;
        let my_stock_pipeline_id = null;
        let appointment_date = (this.state.startDate && this.state.time) ? this.changeDateFormat(this.state.startDate)+" "+ this.state.time.getHours()+":"+this.state.time.getMinutes() : null;
        let meeting_with = this.state.edit_viewer_name_general;
        let property_id = Number(this.props.general_appointment.property_id);
        let audio_data = this.props.general_appointment.audio_path;
        if(Number(this.state.edit_appointment_reason_length) > 0){
            audio_data = null
        }
        let address = this.props.general_appointment.address;
        await this.props.update_general_appointment(authtoken, appointment_id, this.props.timezone, reason, my_stock_pipeline_id, appointment_date, meeting_with, property_id, audio_data, address)
        
        if(Number(this.props.update_general_appointment_success) === Number(1)){
            this.appointmentSuccess_sweetalert();
            window.$("#editGeneralAppointmentModal").modal('hide');
            let agent_id = this.props.general_appointment.user_id
            const token = localStorage.getItem('token');
            var da = new Date();
            var fromDate = this.changeDateFormat(new Date(da.getFullYear(), da.getMonth() - 2, 1));
            var toDate = this.changeDateFormat(new Date(da.getFullYear(), da.getMonth(), da.getDate()));
            let prop_id = this.props.general_appointment.property_id
            let date_sold = this.props.general_appointment.date_sold
            this.props.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold);
        
        }else if(Number(this.props.update_general_appointment_success) === Number(0)){
            this.appointmentError_sweetalert();
        }
    }

    async submitEditGeneralAppointmentStockHandler(event){
        event.preventDefault(); 
        let authtoken = this.props.authtoken;
        let appointment_id = this.props.general_appointment.id
        let reason = this.state.edit_appointment_reason_stock;
        let my_stock_pipeline_id = Number(this.props.general_appointment.my_stock_pipeline_id);
        let appointment_date = (this.state.startDate_stock && this.state.time_stock) ? this.changeDateFormat(this.state.startDate_stock)+" "+ this.state.time_stock.getHours()+":"+this.state.time_stock.getMinutes() : null;
        let meeting_with = this.state.edit_viewer_name_general_stock;
        let property_id = null;
        let audio_data = this.props.general_appointment.audio_path;
        if(Number(this.state.edit_appointment_reason_length_stock) > 0){
            audio_data = null
        }
        let address = this.props.general_appointment.address;
        await this.props.update_general_appointment(authtoken, appointment_id, this.props.timezone, reason, my_stock_pipeline_id, appointment_date, meeting_with, property_id, audio_data, address)
        
        if(Number(this.props.update_general_appointment_success) === Number(1)){
            this.appointmentSuccess_sweetalert();
            window.$("#editGeneralAppointmentStockModal").modal('hide');
            let agent_id = this.props.general_appointment.user_id
            const token = localStorage.getItem('token');
            let stock_pipeline_id = this.props.general_appointment.my_stock_pipeline_id;
            let from_archiveStockPipeline = 0
            this.props.get_stock_pipeline_property_data(stock_pipeline_id, agent_id, token, from_archiveStockPipeline);
        }else if(Number(this.props.update_general_appointment_success) === Number(0)){
            this.appointmentError_sweetalert();
        }
    }

    render(){
        return(
            <div>
                {this.props.from_stock_pipeline === 0 ?
                    //General Appointment Form 
                    <div className="modal fade" data-backdrop="static" data-keyboard="false" id="editGeneralAppointmentModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={this.setwrapperRef} >
                        <div className="modal-dialog" role="document" ref={this.wrapperRef}>
                            <div className="modal-content modal_edit_general_appointment modalheight" style ={{borderRadius : '12px' }} >
                                <div className="modal-header" style={{ backgroundColor: "#EDF6F6"}}>
                                    <h4 className="modal-title fontWeight600"> <Icon className="message_icon color27AFAF mr5" style={{verticalAlign: 'bottom'}} icon={bxCalendarEvent} height='25' width='25' /> Edit General Appointment</h4>
                                    <div id="closeModal_edit_general_appointment" className="circle" data-dismiss="modal" onClick={this.closeModal_Update_Appointment}>
                                        <img src="images/modal-close.png " width="12" height="12" alt=""/>
                                    </div> 
                                </div>
                                {this.props.update_appointment_form_error ? 
                                    this.props.update_appointment_form_error === "success" ? 
                                        <SuccessText successMsg = {this.props.update_appointment_form_error} /> 
                                    :
                                        <ErrorText errorMsg = {this.props.update_appointment_form_error} />
                                : 
                                    null
                                }
                                {
                                    //edit general appointment form
                                    <div className="modal-body">
                                        <center>
                                        <form id="editgeneralAppointmentform" onSubmit={this.submitEditGeneralAppointmentHandler}>
                                            {/* General Appointment */}
                                            <div className="shadow">
                                                <p className="cardHeader" style={{marginBottom:"15px"}} maxLength="50">What is the reason for this appointment?</p>
                                                {this.props.general_appointment && Number(this.props.general_appointment.hasaudio) === 1 ?
                                                    <>
                                                    <p>
                                                        <audio controls controlsList="nodownload noplaybackrate">
                                                            <source src={this.props.general_appointment.audio_path} type="audio/mpeg" />
                                                        </audio>
                                                    </p>
                                                    <p className="colorEB5757"><b>Note: Editing the Reason/Note for appointment will remove the Audio Note</b></p>
                                                    </>
                                                :
                                                    null
                                                }
                                                <div className='form_border'>
                                                    <div className='full-input' style={{borderBottom: "none"}}>
                                                        <label className="label_modal" style={{width:'100%', textAlign:'left'}}>Reason/Note for appointment</label>
                                                        <textarea className="input_modal fontWeight800" style={{resize: 'none'}} rows="4" cols="50" name='edit_appointment_reason' id="edit_appointment_reason" placeholder="Reason/Note For Appointment" ref="edit_appointment_reason" maxLength="500" onChange={this.onInputchange}>
                                                            {this.state.edit_appointment_reason}
                                                        </textarea>
                                                        <p className={this.state.edit_appointment_reason_length >= 470 ? "colorEA676A": null} style={{textAlign:"right"}}> {this.state.edit_appointment_reason_length}/500</p>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className='form_border'>
                                                    <div className='full-input' style={{borderBottom: "none"}}>
                                                        <label className="label_modal" style={{width:'100%', textAlign:'left'}}>Who are you meeting with? <i>(optional)</i></label>
                                                        <input className="input_modal fontWeight800" type='text' name='edit_viewer_name_general' id="edit_viewer_name_general" placeholder="John Doe" ref="edit_viewer_name_general" value={this.state.edit_viewer_name_general} onChange={this.onInputchange}></input>
                                                    </div>
                                                </div>
                                                <br/><br/>
                                            </div>
                                            <br/>

                                            {/* Date Time */}
                                            <div className="shadow">
                                                <p className="cardHeader">When would they like to view?</p>
                                                <div className="row">
                                                    <div className="col-md-4 col-md-offset-1">
                                                        <input type="text" className="from_label" value="Select Date:" style={{width:"auto", left:"40px"}} disabled/>
                                                        <Calendar value={this.state.startDate} /*maxDate={this.state.endDate}*/ dateFormat="dd-mm-yy" name="select_date" id="select_date" style={{textAlign: 'left'}}
                                                            onChange={(e) => {this.setState({ startDate: e.value }); this.getCalendarData(e)}}>
                                                        </Calendar>
                                                    </div>
                                                    <div className="col-offset-md-1 col-md-4">
                                                        <input type="text" className="from_label" value="Select Time:" style={{width:"auto", left:"40px"}} disabled/>
                                                        <Calendar timeOnly showTime hourFormat="12" value={this.state.time} onChange={(e) => this.setTime(e)} id="select_time"></Calendar>
                                                    </div>
                                                </div>
                                                <br/>

                                                {Number(this.props.show_property_list) === Number(1) && Number(this.props.from_stock_pipeline) === Number(0) ?
                                                    <div className='form_border'>
                                                        <div className='full-input' style={{borderBottom: "none"}}>
                                                            <label className="label_modal" style={{width:'100%', textAlign:'left'}}>Select Property {Number(this.state.appointment_type) === 0 ? <i>(optional)</i> : null}</label>
                                                            <input readOnly className="input_modal fontWeight800" style={{cursor:"pointer"}} type='text' name='selected_property' id="selected_property" placeholder="John Doe" ref="selected_property" value={this.state.selected_property}></input>
                                                        </div>
                                                    </div>
                                                :
                                                    null
                                                }
                                                
                                                <br/><br/>
                                            </div>
                                            <br/><br/>
                                            
                                            <div style={this.props.authtoken !== null ? {display: "block"} : {display: this.state.display_card} }> 
                                            {/* <div>  */}
                                                <button id="editGeneralAppointmentBtn" type="submit" className="btn btn-primary fontBold" style={{ background:'#4AA49C',borderRadius:'4px'}}>
                                                    <Icon className="message_icon mr5" style={{verticalAlign: 'bottom'}} icon={bxCalendarEvent} height='20' width='20' />
                                                    Edit Appointment
                                                </button>
                                            </div>
                                            <br/>
                                        </form>
                                        </center>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                :
                    //Stock-Pipeline General Appointment Form 
                    <div className="modal fade" data-backdrop="static" data-keyboard="false" id="editGeneralAppointmentStockModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={this.setwrapperRef} >
                        <div className="modal-dialog" role="document" ref={this.wrapperRef}>
                            <div className="modal-content modal_edit_general_appointment_stock modalheight" style ={{borderRadius : '12px' }} >
                                <div className="modal-header" style={{ backgroundColor: "#EDF6F6"}}>
                                    <h4 className="modal-title fontWeight600"> <Icon className="message_icon color27AFAF mr5" style={{verticalAlign: 'bottom'}} icon={bxCalendarEvent} height='25' width='25' /> Edit General Appointment</h4>
                                    <div id="closeModal_edit_general_appointment_stock" className="circle" data-dismiss="modal" onClick={this.closeModal_Update_Appointment}>
                                        <img src="images/modal-close.png " width="12" height="12" alt=""/>
                                    </div> 
                                </div>
                                {this.props.update_appointment_form_error ? 
                                    this.props.update_appointment_form_error === "success" ? 
                                        <SuccessText successMsg = {this.props.update_appointment_form_error} /> 
                                    :
                                        <ErrorText errorMsg = {this.props.update_appointment_form_error} />
                                : 
                                    null
                                }
                                {
                                    <div className="modal-body">
                                        <center>
                                        <form id="editgeneralAppointmentStockform" onSubmit={this.submitEditGeneralAppointmentStockHandler}>
                                            <div className="shadow">
                                                <p className="cardHeader" style={{marginBottom:"15px"}} maxLength="50">What is the reason for this appointment?</p>
                                                {this.props.general_appointment && Number(this.props.general_appointment.hasaudio) === 1 ?
                                                    <>
                                                    <p>
                                                        <audio controls controlsList="nodownload noplaybackrate">
                                                            <source src={this.props.general_appointment.audio_path} type="audio/mpeg" />
                                                        </audio>
                                                    </p>
                                                    <p className="colorEB5757"><b>Note: Editing the Reason/Note for appointment will remove the Audio Note</b></p>
                                                    </>
                                                :
                                                    null
                                                }
                                                <div className='form_border'>
                                                    <div className='full-input' style={{borderBottom: "none"}}>
                                                        <label className="label_modal" style={{width:'100%', textAlign:'left'}}>Reason/Note for appointment</label>
                                                        <textarea className="input_modal fontWeight800" style={{resize: 'none'}} rows="4" cols="50" name='edit_appointment_reason_stock' id="edit_appointment_reason_stock" placeholder="Reason/Note For Appointment" ref="edit_appointment_reason_stock" maxLength="500" onChange={this.onInputchange}>
                                                            {this.state.edit_appointment_reason_stock}
                                                        </textarea>
                                                        <p className={this.state.edit_appointment_reason_length_stock >= 470 ? "colorEA676A": null} style={{textAlign:"right"}}> {this.state.edit_appointment_reason_length_stock}/500</p>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className='form_border'>
                                                    <div className='full-input' style={{borderBottom: "none"}}>
                                                        <label className="label_modal" style={{width:'100%', textAlign:'left'}}>Who are you meeting with? <i>(optional)</i></label>
                                                        <input className="input_modal fontWeight800" type='text' name='edit_viewer_name_general_stock' id="edit_viewer_name_general_stock" placeholder="John Doe" ref="edit_viewer_name_general_stock" value={this.state.edit_viewer_name_general_stock} onChange={this.onInputchange}></input>
                                                    </div>
                                                </div>
                                                <br/><br/>
                                            </div>
                                            <br/>

                                            {/* Date Time */}
                                            <div className="shadow">
                                                <p className="cardHeader">When would they like to view?</p>
                                                <div className="row">
                                                    <div className="col-md-4 col-md-offset-1">
                                                        <input type="text" className="from_label" value="Select Date:" style={{width:"auto", left:"40px"}} disabled/>
                                                        <Calendar value={this.state.startDate_stock} /*maxDate={this.state.endDate}*/ dateFormat="dd-mm-yy" name="select_date" id="select_date" style={{textAlign: 'left'}}
                                                            onChange={(e) => {this.setState({ startDate_stock: e.value }); this.getCalendarData(e)}}>
                                                        </Calendar>
                                                    </div>
                                                    <div className="col-offset-md-1 col-md-4">
                                                        <input type="text" className="from_label" value="Select Time:" style={{width:"auto", left:"40px"}} disabled/>
                                                        <Calendar timeOnly showTime hourFormat="12" value={this.state.time_stock} onChange={(e) => this.setTime(e)} id="select_time"></Calendar>
                                                    </div>
                                                </div>
                                                <br/>

                                                {Number(this.props.show_property_list) === Number(1) && Number(this.props.from_stock_pipeline) === Number(0) ?
                                                    <div className='form_border'>
                                                        <div className='full-input' style={{borderBottom: "none"}}>
                                                            <label className="label_modal" style={{width:'100%', textAlign:'left'}}>Select Property {Number(this.state.appointment_type) === 0 ? <i>(optional)</i> : null}</label>
                                                            <input readOnly className="input_modal fontWeight800" style={{cursor:"pointer"}} type='text' name='selected_property' id="selected_property" placeholder="John Doe" ref="selected_property" value={this.state.selected_property} ></input>
                                                        </div>
                                                    </div>
                                                :
                                                    null
                                                }
                                                
                                                <br/><br/>
                                            </div>
                                            <br/><br/>
                                            
                                            <div style={this.props.authtoken !== null ? {display: "block"} : {display: this.state.display_card} }> 
                                            {/* <div>  */}
                                                <button id="editGeneralAppointmentBtn_stock" type="submit" className="btn btn-primary fontBold" style={{ background:'#4AA49C',borderRadius:'4px'}}>
                                                    <Icon className="message_icon mr5" style={{verticalAlign: 'bottom'}} icon={bxCalendarEvent} height='20' width='20' />
                                                    Edit Appointment
                                                </button>
                                            </div>
                                            <br/>
                                        </form>
                                        </center>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        )   
    }    
}

const mapDispatchToProps = dispatch => {
    return {
        update_general_appointment: (authtoken, appointment_id, timezone, reason, my_stock_pipeline_id, appointment_date, meeting_with, property_id, audio_data, address) => dispatch(actions.update_general_appointment(authtoken, appointment_id, timezone, reason, my_stock_pipeline_id, appointment_date, meeting_with, property_id, audio_data, address)),
        get_property_data_modal: (fromDate, toDate, prop_id, agent_id, token, date_sold) => dispatch(actions.get_property_data_modal(fromDate, toDate, prop_id, agent_id, token, date_sold)),
        reset_appointment_data: () => dispatch(actions.reset_appointment_data()),
        //get Stock pipeline details
        get_stock_pipeline_property_data: (stock_pipeline_id, agent_id, token, from_archiveStockPipeline) => dispatch(actions.get_stock_pipeline_property_data(stock_pipeline_id, agent_id, token, from_archiveStockPipeline)),
    }
}
const mapStateToProps = state => {
    return{
        authtoken: state.add_property_modal.authtoken,
        general_appointment: state.edit_appointment_modal.general_appointment,
        timezone: state.add_property_modal.timezone,
        update_appointment_form_error: state.edit_appointment_modal.update_appointment_form_error,
        update_general_appointment_success: state.edit_appointment_modal.update_general_appointment_success,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditGeneralAppointment);